import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { Editor } from "@tinymce/tinymce-react"
import { useMemo } from "react"
import staffDocuments from "../../../assets/images/staffDocuments.jpg"
import { lettersData } from "../GenData"
import JsPDF from "jspdf"

import { useTable, usePagination, useGlobalFilter } from "react-table"
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer"
// import India from "../../../assets/images/flags/india.png"
// import Uk from "../../../assets/images/flags/uk.png"
// import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import { Table, Row, Col, Card, CardBody, Modal, Form } from "reactstrap"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Contract.css"
// import AccessDenied from "../../assets/images/dribbble_1.gif"

// common component
import Pagination from "components/Common/Pagination"
import ProfileDetails from "components/Common/ProfileDetails"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
// import DownloadContract from "./DownloadContract"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import configure from "configure"
import Select from "react-select"
import swal from "sweetalert"
import Swal from "sweetalert2"

// components

function TableContainer({ columns, data, setLoading, setDatas }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [row, setRow] = useState(1)
  const [description, setDescription] = useState("")
  const [template, setTemplate] = useState([])

  const [editor, setEditor] = useState(description)
  const [template_option, setTemplateOption] = useState([])
  const [requirementType, setRequirementType] = useState(null)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const handleEditorChange = (content, editor) => {
    setEditor(editor)
  }
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  // const templateData = [
  //   {
  //     label: item.name,
  //     value: item.id,
  //     description: item.description,
  //   },
  // ]

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const [name_modal, setNameModal] = useState(false)

  const [title, setTitle] = useState("")

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Contract Module, the  <strong>Assigned</strong>column designates the specific team member or individual responsible for managing and overseeing the contract, ensuring its fulfillment and compliance.</p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong>  column is to establish accountability for the contract's execution and management. It helps streamline contract-related tasks, such as negotiations, amendments, and renewals, by identifying the team member or individual responsible. This column enhances teamwork, ensures effective contract administration within the Mars System, and facilitates timely contract execution.</p>",
    },
    {
      title: "Company",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Contract Module, the <strong>Company</strong>  column represents the name of the organization or business entity with which the contract is established.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Company</strong>  column is to provide specific identification of the contracting party. It ensures that the contract is associated with the correct company, streamlining record-keeping and facilitating clear communication and contract management within the Mars System. </p>",
    },
    {
      title: "Type",
      mean: "<p><strong>Meaning :</strong> The <strong>Type</strong> column in the Mars System's Contract Module categorizes contracts into different types, such as <strong>Website Development</strong>, <strong>Digital marketing</strong>, <strong>Dedicate resource </strong>  or other relevant categories </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Type</strong> column is to classify contracts based on their nature and purpose. It allows for easy categorization and sorting of contracts, ensuring that the Mars System users can quickly access and manage contracts based on their specific type. This column simplifies contract management and facilitates efficient contract retrieval.</p>",
    },
    {
      title: "Amount",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Contract Module, the <strong>Amount</strong> column specifies the currency in which the contract's financial terms and payments are denominated.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Amount</strong>  column is to provide transparency regarding the currency used in the contract's financial transactions. It ensures that both parties involved in the contract are aware of the currency terms, facilitating accurate financial record-keeping and transaction management within the Mars System.</p>",
    },
    {
      title: "Duration",
      mean: "<p><strong>Meaning :</strong> : In the Mars System's Contract Module, the <strong>Duration</strong>  column indicates the period or timeframe covered by the contract, including start and end dates.</p>",
      purpose:
        "<p><strong>Purpose :</strong>The purpose of the <strong>Duration</strong>   column is to specify the contract's temporal scope. It helps users understand when the contract commences and when it expires, facilitating timely contract monitoring, renewal, and compliance tracking within the Mars System.</p>",
    },
    {
      title: "Sent Date",
      mean: " <p><strong>Meaning :</strong>The <strong>Sent Date</strong> column records the date when the contract was sent to the relevant parties for review, approval, or signature.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Sent Date</strong> column is to track the initiation of the contract negotiation and approval process. It provides a reference point for measuring the time it takes for parties to respond and progress through the contract workflow within the Mars System.</p>",
    },
    {
      title: "End Date",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Contract Module, the <strong> End Date</strong> column denotes the date when the contract officially concludes or expires.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong> End Date </strong> column is to provide a clear indication of when the contract's obligations and terms come to an end. It assists in contract management by ensuring that both parties are aware of the contract's termination date within the Mars System.</p>",
    },
    {
      title: "Signature",
      mean: "<p><strong>Meaning :</strong> The <strong>Signature</strong>  column may be used to record whether the contract has been signed or executed by the relevant parties. It can include indicators like <strong>Signed </strong>, <strong>Not Signed </strong>, <strong>Pending </strong>, or <strong>Won </strong>.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Signature</strong> column is to track the status of contract execution. It helps users identify whether the contract has been legally binding and executed by all necessary parties. This column is crucial for ensuring compliance and contract validity within the Mars System.</p>",
    },
  ]

  // ..................................................................

  useEffect(() => {
    setTemplateOption(
      lettersData?.map(item => ({
        label: item.label,
        value: item.id,
        description: item.dec,
      }))
    )
  }, [])

  // api integration

  const handleSubmit = async e => {
    e.preventDefault()
    if (template.length !== 0) {
      const formData = new FormData()
      formData.append("staff_id", localStorage.getItem("staffId"))
      formData.append("requirement_type", requirementType.label)
      formData.append("template_format", editor?.getContent())
      setLoading(true)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/generateLetters`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          setLoading(false)
          console.log(response)
          Swal.fire({
            icon: "success",
            text: "Document uploaded successfully",
          }).then(() => {
            setRequirementType(null)
            setDescription("")
            setDatas(response.data.data)
            setRow(1)
          })
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select document type",
      })
    }
  }
  //
  const handleSubmit2 = async e => {
    e.preventDefault()

    if (template.length !== 0) {
      console.log("submitted")
      // generatePDF()
      const content = editor.getContent()
      const contract = new JsPDF("portrait", "pt", "a3")

      // Define margins
      const marginX = 50
      const marginY = 50
      const contentWidth = contract.internal.pageSize.getWidth() - 2 * marginX
      const contentHeight = contract.internal.pageSize.getHeight() - 2 * marginY

      // Create a temporary div to parse the HTML content
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = content

      // Use the html method to add the HTML content to the PDF within the margins
      contract.html(tempDiv, {
        x: marginX,
        y: marginY,
        width: contentWidth,
        windowWidth: contentWidth,
        callback: async function () {
          // Save the PDF
          // contract.save("zithas.pdf")
          const pdfBlob = contract.output("blob")
          const formData = new FormData()
          formData.append("staff_id", localStorage.getItem("staffId"))
          formData.append("requirement_type", requirementType.label)
          formData.append("template_formate", content)
          formData.append("file", pdfBlob, "zithas.pdf")

          setLoading(true)
          try {
            const response = await axios({
              method: "post",
              url: `${configure.apiUrl}/addStaffDocument`,
              data: formData,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            if (response) {
              setLoading(false)
              console.log(response)
              Swal.fire({
                icon: "success",
                text: "Document uploaded successfully",
              }).then(() => {
                setRequirementType(null)
                setDescription("")
                const modifyArray = response?.data?.data?.filter(item => {
                  const type = [
                    "salaryincrementletter",
                    "workexperienceletter",
                    "appointmentletter",
                  ]
                  const itemtypes = item.requirement_type
                    ?.replace(/\s+/g, "")
                    .toLowerCase()
                  console.log("itemtypes", itemtypes)
                  return type.includes(itemtypes)
                })
                console.log("modifyArray2", modifyArray)

                setDatas(modifyArray)
                setRow(1)
              })
            }
          } catch (error) {
            console.log(error)
            setLoading(false)
          }
        },
      })
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select document type",
      })
    }
  }

  return (
    <div>
      {row == 1 ? (
        <div>
          {/* fixed buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixedContent ? "d-flex" : "d-none"
            }`}
          >
            <button
              //   to={`${configure.appUrl}sales/add-contract`}
              className="btn btn-purple  fix-btn"
              type="button"
              onClick={() => setRow(2)}
            >
              <i className="fas fa-plus me-2 icon-size"></i>
              <span>Generate</span>
            </button>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn"
            >
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </button>
          </div>
          <Row className="mb-2">
            <Col>
              <select
                className=" me-4 form-select"
                style={{ width: "100px" }}
                value={pageSize}
                onChange={e => setPageSize(e.target.value)}
              >
                {[10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="d-flex justify-content-end col-md-10">
              <div className="me-4">
                <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                  <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                      <span id="search-bar-0-label" className="sr-only">
                        Search this table
                      </span>
                      <input
                        onChange={e => setGlobalFilter(e.target.value)}
                        id="search-bar-0"
                        type="text"
                        className=" form-control rounded h-100"
                        placeholder={` Search...`}
                        value={globalFilter || ""}
                      />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div className="text-md-end">
                {/* <button
              onClick={() => setFormPopup(!formPopup)}
              className="btn btn-outline-secondary  me-3"
            >
              <i className="fas fa-filter"></i>
            </button> */}
                <button
                  type="button"
                  className=" btn text-white me-2 btn-purple"
                  onClick={() => setRow(2)}
                >
                  <i className="fas fa-plus me-2 icon-size"></i>Generate
                  Document
                </button>
                <button onClick={() => navigate(-1)} className=" btn btn-red">
                  <i className="dripicons-cross me-2 icon-center"></i>CANCEL
                </button>
              </div>
            </Col>
          </Row>
          <div className="row">
            <div className="col-md-4 d-flex justify-content-center pt-4">
              <div className={`${fixedContent ? "staffDocuments" : ""}`}>
                <img
                  src={staffDocuments}
                  alt="staffDocuments"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="position-relative">
                <Table
                  className="table table-bordered mt-4"
                  {...getTableProps()}
                >
                  <thead
                    className={`fix-genrateDoc-header ${
                      fixedContent ? "fix-header-genrateDoc" : ""
                    } `}
                  >
                    {headerGroups.map((headerGroup, i) => (
                      <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th
                            key={index}
                            className={`t-col-${index + 1} ${column.className}`}
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                            {column.isInfoIcon ? (
                              <a
                                onClick={() => {
                                  setNameModal(true)
                                  setTitle(column.Header)
                                }}
                              >
                                <i
                                  title="info"
                                  className="bx bx-info-circle ms-1 info-icon"
                                ></i>
                              </a>
                            ) : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody
                    className="fix-genrateDoc-header"
                    {...getTableBodyProps()}
                  >
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr
                          className="row-hover"
                          {...row.getRowProps()}
                          key={i}
                        >
                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                key={index}
                                onClick={() =>
                                  cell.column.Header === "Company"
                                    ? navigate(
                                        `${configure.appUrl}sales/edit-contract`
                                      )
                                    : ""
                                }
                                className={`t-col-${index + 1} ${
                                  cell.column.className
                                } ${
                                  cell.column.Header === "Signature"
                                    ? cell.value === "Not-Signed"
                                      ? "text-danger"
                                      : "text-success"
                                    : ""
                                }`}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          {/* Pagination */}
          <Row className="justify-content-md-end justify-content-center align-items-center">
            <Pagination
              gotoPage={gotoPage}
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
            />
          </Row>

          <Modal
            isOpen={name_modal}
            toggle={() => {
              tog_center()
            }}
            className="bd-info-popup"
            centered
          >
            <div className="modal-header bd-head-info">
              <h5 className="modal-title mt-0">
                <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
                {title}
              </h5>
            </div>
            <div className="modal-body pb-0">
              <div className="d-flex">
                <div className="col-5 pr-30">
                  {/* <div>
                <img src={InfoGif} className="w-100" />
              </div> */}
                </div>
                <div className="mt-20 ">
                  <div>
                    {info.map((item, i) => {
                      if (item.title == title) {
                        return (
                          <div key={i}>
                            <span>
                              <span
                                dangerouslySetInnerHTML={{ __html: item.mean }}
                              ></span>
                            </span>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.purpose }}
                            ></div>
                          </div>
                        )
                      }
                    })}
                    <div></div>
                    <div className="text-center mt-70">
                      <button
                        type="button"
                        className="btn btn-success "
                        onClick={() => setNameModal(false)}
                      >
                        I Understood
                        <i className="dripicons-thumbs-up pl-10"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : row == 2 ? (
        <div>
          <form onSubmit={handleSubmit}>
            <div
              className={`flex-column fixed-buttons pt-2 ${
                fixedContent ? "d-flex" : "d-none"
              }`}
            >
              <button
                type="submit"
                className="btn btn-save  fix-btn btn-label pe-0"
              >
                <i className="fas fa-save me-2 label-icon label-icon"></i>
                <span>Save</span>
              </button>
              <button
                type="button"
                onClick={() => setRow(1)}
                className="btn btn-red  fix-btn btn-label pe-0"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                <span>Cancel</span>
              </button>
            </div>

            <div className="h-100 bg-white px-4">
              <Row className=" py-4 h-100 mb-3">
                <div className="col-4 mb-3">
                  <label htmlFor="">
                    Document Type <span>*</span>{" "}
                  </label>

                  <Select
                    value={requirementType}
                    placeholder="Select Document Type"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={template_option}
                    onChange={e => {
                      setRequirementType(e)
                      setTemplate(e)
                      setDescription(e.description)
                    }}
                  />
                </div>
                <div className="col-8 text-end mb-3">
                  <button className="btn btn-save me-2 btn-label">
                    <i className="fas fa-save me-1 label-icon"></i> save
                  </button>
                  <a
                    onClick={() => {
                      setRow(1)
                    }}
                    className=" btn btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
                <div className="col-md-12 ">
                  <Editor
                    initialValue={description}
                    apiKey="w6fz9u4rgsouz7jqcyy4lflpolp0tjwkx1bzoc70ntetxwys"
                    init={{
                      height: 800,
                      menubar: true,
                      plugins: [
                        "a11ychecker",
                        "accordion",
                        "advlist",
                        "advcode",
                        "advtable",
                        "autolink",
                        "checklist",
                        "export",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "powerpaste",
                        "fullscreen",
                        "formatpainter",
                        "insertdatetime",
                        "media",
                        "table",
                        "wordcount",
                        "code",
                        "pagebreak",
                        "quickbars",
                        "codesample",
                        "textcolor",
                      ],
                      toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | fontselect fontsizeselect |" +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table | exportpdf |",
                      fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                    }}
                    // onEditorChange={handleEditorInit}
                    onEditorChange={handleEditorChange}
                  />
                </div>
              </Row>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  text: {
    marginTop: 30,
    marginLeft: 30,
  },
})

const GenerateDoc = ({ id }) => {
  //meta title
  document.title = "Generate Document | Zithas Crm"

  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)

  // useEffect(() => {
  //   fetch()
  // }, [])

  // const fetch = async () => {
  //   setLoading(true)
  //   const formData = new FormData()
  //   formData.append("staff_id", localStorage.getItem("auth_id"))
  //   try {
  //     const response = await axios({
  //       data: formData,
  //       method: "post",
  //       url: `${configure.apiUrl}/showStaffDocument`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     if (response) {
  //       const modifyArray = response?.data?.data?.filter(item => {
  //         const type = [
  //           "salaryincrementletter",
  //           "workexperienceletter",
  //           "appointmentletter",
  //         ]
  //         const itemtypes = item.requirement_type
  //           ?.replace(/\s+/g, "")
  //           .toLowerCase()
  //         return type.includes(itemtypes)
  //       })
  //       setDatas(modifyArray)
  //       setLoading(false)
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     setLoading(false)
  //   }
  // }

  const [modal_Access, setAccessDenied] = useState(false)

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Letters Type",
        accessor: "requirement_type",
        isInfoIcon: true,
      },
      {
        Header: "Created",
        accessor: "created_at",
        isInfoIcon: true,
        className: "text-center",
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original?.created_at != null
                ? moment(cellProps.row.original?.created_at).format(
                    "DD-MM-YYYY"
                  )
                : "-"}
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        Cell: cellProps => {
          const deleteDocument = id => {
            Swal.fire({
              icon: "warning",
              title: "Are you sure ?",
              text: "You want to delete document.",
              showCancelButton: true,
            }).then(async willOkay => {
              if (willOkay.isConfirmed) {
                setLoading(true)
                const formData = new FormData()
                formData.append("staff_id", localStorage.getItem("staffId"))
                formData.append("file_id", id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/deleteStaffDocument`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  if (response) {
                    setLoading(false)
                    Swal.fire({
                      icon: "success",
                      text: "Document deleted successfully !",
                    }).then(() => {
                      const modifyArray = response?.data?.data?.filter(item => {
                        const type = [
                          "salaryincrementletter",
                          "workexperienceletter",
                          "appointmentletter",
                        ]
                        const itemtypes = item.requirement_type
                          ?.replace(/\s+/g, "")
                          .toLowerCase()
                        return type.includes(itemtypes)
                      })
                      setDatas(modifyArray)
                    })
                  }
                } catch (error) {
                  setLoading(false)
                  console.log(error)
                }
              }
            })
          }

          return (
            <div className="d-flex justify-content-center">
              <Link
                title="View"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-eye "></i>
              </Link>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <a
                    onClick={() => deleteDocument(cellProps.row.original.id)}
                    title="delete"
                    className="dropdown-item"
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Document
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    []
  )

  const fetchData = async () => {
    console.log("fetchData")
    try {
      const staffId = localStorage.getItem("staffId")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/viewGenerateLetters/${staffId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        console.log(response)
        setDatas(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setLoading={setLoading}
                      setDatas={setDatas}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Access Denied */}
    </React.Fragment>
  )
}

export default GenerateDoc
