import React, { useEffect, useRef, useState } from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"
import zithasLogo from "../../../assets/images/zithas-dark-logo.png"
import zithasSign from "../../../assets/images/zithas-signature.png"
import axios from "axios"
import moment from "moment"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { Modal, Row } from "reactstrap"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
import configure from "configure"
import { renderToString } from "react-dom/server"
import Swal from "sweetalert2"

const styles = StyleSheet.create({
  container: {
    padding: "20px",
  },
  content: {
    textAlign: "justify",
  },
  image: {
    textAlign: "center",
    height: "60px",
  },
  date: {
    fontSize: "18px",
    fontWeight: "600",
  },
})

const DownloadContract = ({ contract_id, signature }) => {
  const [description, setDescription] = useState("")
  const [date, setDate] = useState("")
  const [signature_main, setSignature] = useState("")
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")

  const FetchContractData = async () => {
    setViewData(true)
    setLoading(true)
    const formData = new FormData()
    formData.append("contract_id", contract_id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_contract_data`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDescription(response.data.data.contract_data[0]?.template_formate)
        setDate(response.data.data?.created_at)
        setSignature(response.data.data.contract[0]?.signature)
        setName(response.data.data.contract[0]?.name)
        setCompany(response.data.data.customer[0]?.company)
        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  const [viewData, setViewData] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const pdfRef = useRef(null)

  const generatePDF2 = () => {
    setLoading(true)
    const pdf = new jsPDF("p", "mm", "a4")

    // Select the content to be converted to PDF
    const element = pdfRef.current

    // Use html2canvas to capture the content as an image
    html2canvas(element).then(canvas => {
      const imgData = canvas.toDataURL("image/png")
      const width = pdf.internal.pageSize.getWidth()
      const height = (canvas.height * width) / canvas.width
      // Add the captured image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, width, height)

      // Save or open the PDF
      pdf.save(`Contract-${contract_id}.pdf`)
      setLoading(false)
    })
  }
  const generatePDF = async () => {
    try {
      setLoading(true)
      const pdf = new jsPDF("p", "mm", "a4")
      const element = pdfRef.current
      const canvas = await html2canvas(element)
      const imgData = canvas.toDataURL("image/png")
      const width = pdf.internal.pageSize.getWidth()
      const height = (canvas.height * width) / canvas.width
      const totalPages = Math.ceil(height / pdf.internal.pageSize.getHeight())
      for (let page = 0; page < totalPages; page++) {
        if (page > 0) {
          pdf.addPage() // Add a new page for subsequent content
        }
        const startY = page * pdf.internal.pageSize.getHeight()
        const endY = Math.min(
          (page + 1) * pdf.internal.pageSize.getHeight(),
          height
        )
        pdf.addImage(imgData, "PNG", 0, -startY, width, height)
        const contentY = endY + 20
      }
      pdf.save(`Contract-${contract_id}.pdf`)

      setLoading(false)
    } catch (error) {
      console.error("Error generating PDF:", error)
      setLoading(false)
    }
  }

  const generatePDF3 = (
    description,
    date,
    zithasSign,
    signature_main,
    name,
    company,
    contract_id
  ) => {
    const pdf = (
      <Document>
        <Page size="A4" style={styles.container}>
          <View>
            <Image src={zithasSign} style={styles.image} />
            <Text style={styles.content}>{description}</Text>
            <Text style={styles.date}>
              Date: {moment(date).format("DD-MM-YYYY")}
            </Text>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <View style={{ width: "50%" }}>
                <Image src={zithasSign} style={{ width: 100 }} />
                <Text style={{ fontSize: 12, marginTop: 5 }}>Julius Gohil</Text>
                <Text style={{ fontSize: 10 }}>Managing Director</Text>
                <Text style={{ fontSize: 10 }}>For Zithas Technologies</Text>
              </View>
              <View style={{ width: "50%" }}>
                <Image src={signature_main} style={{ width: 165 }} />
                <Text style={{ fontSize: 12, marginTop: 5 }}>{name}</Text>
                <Text style={{ fontSize: 10 }}>For {company}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    )

    // Convert the PDF JSX to a string
    const pdfString = renderToString(pdf)

    // Create a Blob from the PDF string
    const pdfBlob = new Blob([pdfString], { type: "application/pdf" })

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob)

    // Open the PDF in a new tab
    window.open(pdfUrl)
  }
  return (
    <>
      {isLoading && <Loader />}
      <a
        title="download pdf"
        className="btn btn-sm btn-outline-secondary me-1"
        onClick={() => {
          if (signature == "signed") {
            FetchContractData()
          } else {
            swal({
              icon: "warning",
              text: "The contract has yet to be signed",
            })
          }
        }}
      >
        <i className="fa fa-file-pdf"></i>
      </a>

      <Modal size="xl" centered isOpen={viewData} className="modal-fullscreen">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Download Contract</h5>
          <div className="d-flex align-items-center">
            <div>
              <a title="download pdf" className="" onClick={generatePDF}>
                <i className="fa fa-download text-purple pr-10"></i>
              </a>
            </div>
            <button
              type="button"
              onClick={() => {
                setViewData(false)
              }}
              className="btn-modal-close"
              style={{ marginTop: "8px" }}
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div
            ref={pdfRef}
            className="p-4"
            style={{ color: "black", marginTop: "20px" }}
          >
            <div className="pb-2 text-center">
              <img src={zithasLogo} height="60" alt="zithasLogo" />
            </div>
            <div>
              <div>
                <div
                  style={{ textAlign: "justify" }}
                  className="Features"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>

              <h5 className="fw-bold text-decoration-underline">
                Date : {moment(date).format("DD-MM-YYYY")}
              </h5>
              <Row className="mt-4 justify-space-between">
                <div className="col-4">
                  <img src={zithasSign} />
                  <h5 className="mb-0 mt-3">Julius Gohil</h5>
                  <p className="mb-0">Managing Director</p>
                  <p>For Zithas Technologies</p>
                </div>
                <div className="col-4">
                  <img
                    src={signature_main}
                    width="85"
                    style={{ height: "50px", objectFit: "contain" }}
                  />
                  <h5 className="mb-0 ">{name}</h5>
                  <p>For {company}</p>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DownloadContract
