import axios from "axios"
import configure from "configure"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Col, Card, CardBody } from "reactstrap"

const RevisionHistory = ({ verticalActiveTab }) => {
  const [fixButtons, setFixButtons] = useState(false)

  const [data, setData] = useState([])

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (verticalActiveTab == "2") {
      fetchFiles()
    }
  }, [verticalActiveTab])

  const params = useParams()

  const fetchFiles = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("proposal_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_proposal_revision`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setData(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <div>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h5>Revision History</h5>
                    <div className="text-end">
                      <a
                        onClick={() => navigate(-1)}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </div>
                  <div>
                    {data?.length != 0 ? (
                      <ul className="verti-timeline list-unstyled">
                        {/* Activities */}
                        {data.map((status, key) => (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <i
                                className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${
                                  status.id === 3 ? "bx-fade-right" : ""
                                }`}
                              />
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h5>Proposal Edited</h5>
                                    <p className="text-muted">
                                      {" "}
                                      At{" "}
                                      {moment(status.created_at).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      By {status.added_by}
                                    </p>
                                  </div>
                                  {status.new_data?.length != 0 ? (
                                    <div className="ml-30">
                                      {/* <a
                                        className="btn btn-sm activity-log-btn text-info">
                                        <i className="fas fa-eye"></i> View
                                      </a> */}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <p className="timing">
                                {moment(status.created_at)
                                  .format("DD-MM-YYYY hh:mm:s")
                                  .slice(10)}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                    {data?.length == 0 ? (
                      <div>No Change Done in Proposal</div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RevisionHistory
