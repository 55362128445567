import React, { useState } from "react"
import { Container, Row, Col, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import axios from "axios"
import configure from "configure"
import Loader from "pages/Separate/Loader"

const WorkExperience = ({ setverticalActiveTab }) => {
  // for education form
  const [inputFields_1, setInputFields1] = useState([{}])
  const [formRows, setFormRows] = useState([{ id: 1 }])
  const [fixButtons, setFixButtons] = useState(false)
  const [staffId, setStaffId] = useState(localStorage.getItem("staffId"))
  const [isLoading, setLoading] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [salary, setSalary] = useState("")
  const [position, setPosition] = useState("")
  const [reasonLeave, setReasonLeave] = useState("")
  const [jobDescription, setJobDescription] = useState("")
  const [contract, setContract] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // dynamic form add
  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
    setInputFields1([...inputFields_1, {}])
  }

  const onDeleteFormRow = id => {
    if (id !== 1) {
      var modifiedRows = [...formRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setFormRows(modifiedRows)
      setInputFields1(modifiedRows)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", staffId?.toString())
    formData.append("company_name", companyName?.toString())
    formData.append("from_date", fromDate?.toString())
    formData.append("to_date", toDate?.toString())
    formData.append("position", position?.toString())
    formData.append("salary", salary?.toString())
    formData.append("reason_for_leave", reasonLeave?.toString())
    formData.append("job_description", jobDescription?.toString())
    formData.append("contact_person", contract?.toString())
    formData.append("we_email", email?.toString())
    formData.append("we_phone_number", number?.toString())
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staffwe`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log(response)
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff Work Experience details Added Successfully",
        }).then(data => {
          // setverticalActiveTab("4")
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const onCompanyName = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setCompanyName(
      values.map(item => {
        first_item = item.companyName
        return first_item
      })
    )
  }
  const onFromDate = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setFromDate(
      values.map(item => {
        first_item = item.fromDate
        return first_item
      })
    )
  }
  const onToDate = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setToDate(
      values.map(item => {
        first_item = item.ToDate
        return first_item
      })
    )
  }
  const onSalary = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setSalary(
      values.map(item => {
        first_item = item.salary
        return first_item
      })
    )
  }
  const onPosition = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setPosition(
      values.map(item => {
        first_item = item.position
        return first_item
      })
    )
  }
  const onReasonLeave = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setReasonLeave(
      values.map(item => {
        first_item = item.reasonLeave
        return first_item
      })
    )
  }
  const onJobDescription = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setJobDescription(
      values.map(item => {
        first_item = item.jobDec
        return first_item
      })
    )
  }
  const onContactPerson = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setContract(
      values.map(item => {
        first_item = item.contact
        return first_item
      })
    )
  }
  const onEmail = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setEmail(
      values.map(item => {
        first_item = item.email
        return first_item
      })
    )
  }
  const onNumber = (e, index) => {
    const values = [...inputFields_1]
    values[index][e.target.name] = e.target.value
    let first_item
    setNumber(
      values.map(item => {
        first_item = item.number
        return first_item
      })
    )
  }

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Form className="repeater" onSubmit={handleSubmit}>
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
            <button
              onClick={e => {
                e.preventDefault()
                onAddFormRow()
              }}
              className="btn btn-purple  fix-btn"
            >
              <i className="fas fa-plus icon-size"></i>
              <span className="ps-2">Add</span>
            </button>
          </div>
          <div className="p-4 pb-0 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark">Work Experience</h4>
              </div>
              <div className="col-md-6  text-md-end">
                <button
                  onClick={e => {
                    e.preventDefault()
                    onAddFormRow()
                  }}
                  className=" btn text-white text-uppercase me-2 btn-save btn-label"
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                  More
                </button>

                <button className=" btn btn-save me-md-0 me-2 btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>SAVE
                </button>
                <button
                  onClick={() => navigate(-1)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>
          </div>
          {(formRows || []).map((formRow, index) => (
            <div key={index} className="mb-3 p-4 bg-white shadow">
              <Row className="align-items-center">
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Company Name</label>
                  <input
                    type="text"
                    placeholder="Enter Company Name"
                    className="form-control "
                    id=""
                    name="companyName"
                    onChange={e => onCompanyName(e, index)}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="">From Date</label>
                  <input
                    className="form-control"
                    type="date"
                    id="example-date-input"
                    name="fromDate"
                    onChange={e => onFromDate(e, index)}
                  />
                </div>
                <div className="col-md-2 mb-3">
                  <label htmlFor="">To Date</label>
                  <input
                    className="form-control"
                    type="date"
                    id="example-date-input"
                    name="ToDate"
                    onChange={e => onToDate(e, index)}
                  />
                </div>

                <div className="col-md-2 mb-3">
                  <label htmlFor="">Salary</label>
                  <input
                    type="text"
                    placeholder="Enter Salary"
                    className="form-control "
                    id=""
                    name="salary"
                    onChange={e => onSalary(e, index)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Position</label>
                  <input
                    type="text"
                    placeholder="Enter Position"
                    className="form-control "
                    id=""
                    name="position"
                    onChange={e => onPosition(e, index)}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="">Reason For Leave</label>
                  <input
                    type="text"
                    placeholder="Enter Reason For Leave"
                    className="form-control "
                    id=""
                    name="reasonLeave"
                    onChange={e => onReasonLeave(e, index)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Job Description</label>
                  <input
                    type="text"
                    placeholder="Enter Job Description"
                    className="form-control "
                    id=""
                    name="jobDec"
                    onChange={e => onJobDescription(e, index)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Contact Person</label>
                  <input
                    type="text"
                    placeholder="Enter Contact Person"
                    className="form-control "
                    id=""
                    name="contact"
                    onChange={e => onContactPerson(e, index)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Email Id</label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    className="form-control "
                    id=""
                    name="email"
                    onChange={e => onEmail(e, index)}
                  />
                </div>
                <div className="col-md-4 mb-3 mb-md-0">
                  <label htmlFor="">Phone Number</label>
                  <input
                    type="number"
                    placeholder="Enter Phone Number"
                    className="form-control "
                    id=""
                    name="number"
                    onChange={e => onNumber(e, index)}
                  />
                </div>
                {/* delete button */}
                <div
                  className={`col-md-4 text-end ${
                    index === 0 ? "d-none" : "d-block"
                  }`}
                >
                  <button
                    className="btn  btn-red"
                    type="button"
                    onClick={e => {
                      e.preventDefault()
                      onDeleteFormRow(formRow.id)
                    }}
                  >
                    <i className="fas fa-trash-alt "></i>
                  </button>
                </div>
              </Row>
            </div>
          ))}
        </Form>
      </div>
    </>
  )
}

export default WorkExperience
