import moment from "moment"
import React, { useState } from "react"
import { Table } from "reactstrap"
import { Row, Form } from "reactstrap"

const ViewProfile = ({ view_proposal }) => {
  // console.log(view_proposal)

  return (
    <React.Fragment>
      <div className="ps-2">
        <Row className="px-1 bd-span-input">
          <Form>
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="col-md-3 mb-md-0 mb-3">
                <label htmlFor="">Location </label>
                <div className="input-span input">
                  {view_proposal[0]?.location}
                </div>
              </div>
            </Row>

            {/* Service  info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Service Information</h5>

              <div className="col-md-6 mt-2 mb-3">
                <label htmlFor="">Proposal Title</label>
                <div className="input-span input">
                  {view_proposal[0]?.proposaltitile}
                </div>
              </div>
              <div className="col-md-6 mt-2 mb-3">
                <label htmlFor="">Proposal Subtitle</label>
                <div className="input-span input">
                  {view_proposal[0]?.proposalsubtitle}
                </div>
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Status</label>
                <div className="input-span input">
                  {view_proposal[0]?.status_name}
                </div>
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Currency</label>
                <div className="input-span input">
                  {view_proposal[0]?.currency_name}
                </div>
              </div>
              <div className="col-md-2 mb-3 mb-md-0">
                <label htmlFor="">Date</label>
                <div className="input-span input">
                  {moment(view_proposal[0]?.date).format("DD-MM-YYYY")}
                </div>
              </div>

              <div className="col-md-2 mb-3 mb-md-0">
                <label htmlFor="">Open Till</label>
                <div className="input-span input">
                  {moment(view_proposal[0]?.opentill).format("DD-MM-YYYY")}
                </div>
              </div>
              <div className="col-md-2 mb-3 mb-md-0">
                <label htmlFor="">Related </label>
                <div className="input-span input">
                  {view_proposal[0]?.related}
                </div>
              </div>
            </Row>

            {/* Client  info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Client Information</h5>

              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Customer</label>
                <div className="input-span input">
                  {view_proposal[0]?.companyname}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Company Name</label>
                <div className="input-span input">
                  {view_proposal[0]?.companyname}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Contact Person</label>
                <div className="input-span input">
                  {view_proposal[0]?.contactname}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Address</label>
                <div className="input-span input">
                  {view_proposal[0]?.address}
                </div>
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Contact</label>
                <div className="input-span input">
                  {view_proposal[0]?.cilentcontact}
                </div>
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <div className="input-span input">
                  {view_proposal[0]?.email}
                </div>
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Website</label>
                <div className="input-span input">
                  {view_proposal[0]?.website}
                </div>
              </div>
            </Row>

            {/* Provider  info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Provider Information</h5>

              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Assigned</label>
                <div className="input-span input">
                  {view_proposal[0]?.assignd}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Contact Person</label>
                <div className="input-span input">
                  {view_proposal[0]?.contactmember}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Contact</label>
                <div className="input-span input">
                  {view_proposal[0]?.contact}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <div className="input-span input">
                  {view_proposal[0]?.provideremail}
                </div>
              </div>

              <div className="col-md-3 mt-2 mt-3 ">
                <label htmlFor="">Company Name</label>
                <div className="input-span input">
                  {view_proposal[0]?.company2}
                </div>
              </div>
              <div className="col-md-3 mt-2 mt-3 ">
                <label htmlFor="">Address</label>
                <div className="input-span input">
                  {view_proposal[0]?.address1}
                </div>
              </div>
              <div className="col-md-3 mt-2 mt-3 ">
                <label htmlFor="">Website</label>
                <div className="input-span input">
                  {view_proposal[0]?.website}
                </div>
              </div>
            </Row>

            {/* Deliverables & testing info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-3">Deliverables & Testing</h5>
              </div>
              <div className="responsive-table">
                <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Milestone Description</th>
                      <th>Estimated Time (In Weeks)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(JSON.parse(view_proposal[0]?.deliverytesting) || []).map(
                      (testingRow, key) => (
                        <tr key={key}>
                          <td className="col-1">
                            <div className="input-span input">
                              {testingRow["sr.no"]}
                            </div>
                          </td>
                          <td className="col-8">
                            <div className="input-span input">
                              {testingRow.milestone}
                            </div>
                          </td>
                          <td className="col-2">
                            <div className="input-span input">
                              {testingRow.estimatedtime}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>

            {/* Terms and agreement */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-3">Terms and agreement</h5>
              </div>
              <div className="responsive-table">
                <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                  <thead className="">
                    <tr>
                      <th>Sr No.</th>
                      <th>Particular</th>
                      <th>Currency</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(JSON.parse(view_proposal[0]?.terms) || []).map(
                      (termsRow, key) => (
                        <tr key={key}>
                          <td className="col-1">
                            <div className="input-span input">
                              {termsRow.no}
                            </div>
                          </td>
                          <td className="col-7">
                            <div className="input-span input">
                              {termsRow.service}
                            </div>
                          </td>
                          <td className="col-1">
                            <div className="input-span input">
                              {termsRow.currency}
                            </div>
                          </td>
                          <td className="col-2">
                            <div className="input-span input">
                              {termsRow.total || "-"}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>

            {/* Cost Breakdown info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-3">Cost Breakdown</h5>
              </div>
              <div className="responsive-table">
                <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                  <thead className="">
                    <tr>
                      <th>Sr No.</th>
                      <th>Description</th>
                      <th>Estimated Time (In Weeks)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(JSON.parse(view_proposal[0]?.costbreakdown) || []).map(
                      (breakdownRow, key) => (
                        <tr key={key}>
                          <td className="col-1">
                            <div className="input-span input">{key + 1}</div>
                          </td>
                          <td className="col-8">
                            <div className="input-span input">
                              {breakdownRow.description}
                            </div>
                          </td>
                          <td className="col-2">
                            <div className="input-span input">
                              {breakdownRow.totalcost}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </Row>

            {/* Conclusion*/}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Conclusion</h5>

              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <div className="input-span input">
                  {view_proposal[0]?.conclusion_email}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Phone Number</label>
                <div className="input-span input">
                  {view_proposal[0]?.conclusion_contact}
                </div>
              </div>
            </Row>
          </Form>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ViewProfile
