import React from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Navbar from "components/HorizontalLayout/Navbar"
import Header from "components/HorizontalLayout/Header"
import HorizontalLayout from "components/HorizontalLayout"
import AccessDenied from "../../assets/images/dribbble_1.gif"
import "../../assets/css/notfound.css"
import { Link } from "react-router-dom"
import configure from "configure"

const NotFound = () => {
  return (
    <div>
      {/* <Header />
      <Navbar /> */}
      <HorizontalLayout />
      <div className="page-content pt-5 bg-white">
        <div className="mx-0 w-100">
          {/* <Breadcrumbs title={"404"} breadcrumbItem={"Not Found"} /> */}
          <Row>
            <Col lg={12} className="p-0">
              <Card className="m-0 shadow-none">
                <CardBody className="pb-5">
                  {/* <h1 className="text-center">404 - Not Found</h1>
                  <img src={AccessDenied} className="" height="500" />
                  <p>The page you are looking for does not exist.</p> */}
                  <section className="">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 ">
                          <div className="col-sm-12 col-sm-offset-1  text-center">
                            <div className="four_zero_four_bg">
                              <h1 className="text-center ">404</h1>
                            </div>

                            <div className="contant_box_404">
                              <h3 className="h2">Look like you're lost</h3>

                              <p>
                                The page you are looking for does not exist.
                              </p>

                              <Link
                                to={`${configure.appUrl}`}
                                className="btn btn-save btn-label"
                              >
                                <i className="bi bi-house-door-fill me-2 icon-size label-icon"></i>
                                Go to Home
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default NotFound
