import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../../components/Common/Breadcrumb"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

// components
import Profile from "./Profile"
import Contact from "./Contact"
import Notes from "./Notes"
import ActivityLog from "./ActivityLog"
import FollowUp from "./FollowUp"
import { useParams } from "react-router-dom"
import axios from "axios"
import configure from "configure"
import MoticBlocks from "./MauticLogs"

const EditForm = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)

  const { company_id } = useParams()

  const [assigned_data, setAssignData] = useState([])

  useEffect(() => {
    axios
      .get(`${configure.apiUrl}/assign_opportunity`)
      .then(res => {
        setAssignData(res?.data?.data?.assigned)
      })
      .catch(err => console.log(err))
  }, [])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Edit Opportunity | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Edit Opportunity"}
          />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Contact
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Follow Up
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Mautic Logs
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <Profile company_id={company_id} />
                  </TabPane>
                  <TabPane tabId="2">
                    <Contact company_id={company_id} />
                  </TabPane>
                  <TabPane tabId="3">
                    <Notes
                      company_id={company_id}
                      assigned_data={assigned_data}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <FollowUp
                      company_id={company_id}
                      assigned_data={assigned_data}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <ActivityLog
                      company_id={company_id}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <MoticBlocks
                      company_id={company_id}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditForm
