import React, { useState } from "react"
import {
  Table,
  Row,
  Col,
  Modal,
  Form,
  Label,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Select from "react-select"

// common 
import Pagination from "components/Common/Pagination"

function TableContainer({ columns, data }) {
  const [status, setStatus] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
      boxShadow: "none",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
      boxShadow: "none",
    }),
  }

  const Duration = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "4" },
    { label: "6", value: "4" },
    { label: "7", value: "4" },
    { label: "8", value: "4" },
    { label: "9", value: "4" },
    { label: "10", value: "4" },
    { label: "11", value: "4" },
    { label: "12", value: "4" },
  ]
  const Months = [
    { label: "Month", value: "1" },
    { label: "Year", value: "2" },
  ]

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const navigate = useNavigate()

  // Render the UI for your table
  return (
    <div className="px-2">
      <div
        className={`flex-column fixed-buttons ${fixedContent ? "d-flex" : "d-none"
          }`}
      >
        <button
          onClick={() => {
            setStatus(true)
          }}
          className="btn btn-purple border-radius fix-btn"
        >
          <i className="fas fa-plus me-2"></i>
          <span>Renewal</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </a>
      </div>

      <Row className="mb-2">
        <Col lg={2}>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className="border-radius btn btn-purple"
              onClick={() => {
                setStatus(true)
              }}
            >
              <i className="fas fa-plus me-2 icon-size"></i>Add Renewal Duration
            </a>
            <a
              className="border-radius btn text-white ms-md-2 btn-red"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center"></i>Cancel
            </a>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered mt-4" {...getTableProps()}>
        <thead className={`${fixedContent ? 'fixed-setting-header' : ''}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`col-md-${index === 0 ? "1" : index === 1 ? "9" : "2"
                    } ${column.className}`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      className={`col-md-${index === 0 ? "1" : index === 1 ? "9" : "2"
                        }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <Modal isOpen={status} size="sm" className="bd-custom-md-2" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Renewal Duration</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row">
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Duration</Label>
              <Select
                placeholder="Select "
                className="basic-multi-select border-radius"
                classNamePrefix="select"
                styles={colourStyles}
                options={Duration}
                isMulti="true"
              />
            </div>
            <div className="mb-3 col-md-6">
              <Label htmlFor="formrow-firstname-Input">Months duration</Label>
              <Select
                placeholder="Select "
                className="basic-multi-select border-radius"
                classNamePrefix="select"
                styles={colourStyles}
                options={Months}
                isMulti="true"
              />
            </div>

            <div className="mt-20">
              <button className="border-radius btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const RenewalDuration = () => {
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        className: "id-width",
      },
      {
        Header: "Renewal Duration",
        accessor: "name",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "action-width",
        Cell: cellProps => {
          return (
            <div>
              <a
                onClick={() => {
                  setEditStatus(true)
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a to="#" className="btn btn-sm  btn-danger">
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )
  const data = [
    {
      id: 1,
      name: "7 Month",
    },
    {
      id: 2,
      name: "1 Month",
    },
    {
      id: 3,
      name: "8 Month",
    },
    {
      id: 4,
      name: "10 Month",
    },
    {
      id: 5,
      name: "3 Month",
    },
    {
      id: 3,
      name: "8 Month",
    },
    {
      id: 4,
      name: "10 Month",
    },
    {
      id: 5,
      name: "3 Month",
    },
  ]

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }
  const Duration = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "4" },
    { label: "6", value: "4" },
    { label: "7", value: "4" },
    { label: "8", value: "4" },
    { label: "9", value: "4" },
    { label: "10", value: "4" },
    { label: "11", value: "4" },
    { label: "12", value: "4" },
  ]
  const Months = [
    { label: "Month", value: "1" },
    { label: "Year", value: "2" },
  ]
  const [edit_status, setEditStatus] = useState(false)

  return (
    <div>
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table">
            <TableContainer columns={columns} data={data} />
          </div>
        </Col>

        <Modal
          isOpen={edit_status}
          size="sm"
          className="bd-custom-md-2"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Package</h5>
            <button
              type="button"
              onClick={() => {
                setEditStatus(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="row">
              <div className="mb-3 col-md-6">
                <Label htmlFor="formrow-firstname-Input">Duration</Label>
                <Select
                  placeholder="Select"
                  className="basic-multi-select border-radius"
                  classNamePrefix="select"
                  styles={colourStyles}
                  options={Duration}
                  isMulti="true"
                />
              </div>
              <div className="mb-3 col-md-6">
                <Label htmlFor="formrow-firstname-Input">Months duration</Label>
                <Select
                  placeholder="Select"
                  className="basic-multi-select border-radius"
                  classNamePrefix="select"
                  styles={colourStyles}
                  options={Months}
                  isMulti="true"
                />
              </div>

              <div className="mt-20">
                <button className="border-radius btn btn-purple">Submit</button>
              </div>
            </Form>
          </div>
        </Modal>
      </Row>
    </div>
  )
}

export default RenewalDuration
