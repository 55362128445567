import React, { useState } from "react";
 
const NewPagination = ({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageOptions,
  pageSize,
  page,
  data,
}) => {
  const currentEntries = pageIndex * pageSize + 1;
  const endEntry = pageIndex * pageSize + page.length;
  const totalEntries = data.length;
 
  const [currentPageGroup, setCurrentPageGroup] = useState(0);
  const pagesToShow = 5;
  const totalPageGroups = Math.ceil(pageOptions.length / pagesToShow);
 
  const handleNextGroup = () => {
    if (currentPageGroup < totalPageGroups - 1) {
      setCurrentPageGroup(currentPageGroup + 1);
    }
  };
 
  const handlePreviousGroup = () => {
    if (currentPageGroup > 0) {
      setCurrentPageGroup(currentPageGroup - 1);
    }
  };
 
  const startPageIndex = currentPageGroup * pagesToShow;
  const endPageIndex = Math.min(
    startPageIndex + pagesToShow,
    pageOptions.length
  );
 

  return (
    <>
      <div className="my-2">
        <div
          className="d-flex justify-content-between align-items-center flex-wrap py-3 py-md-0"
          // style={{ marginTop: "10px" }}
        >
         
            <p>
              Showing {currentEntries} to {endEntry} of {totalEntries} entries
            </p>

          <div className="d-flex align-items-md-center justify-content-md-end flex-column flex-md-row">
            {/* <div className="me-3">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div> */}
            <div>
              <ul className="pagination m-0">
                <li
                  className={`paginate_button page-item previous ${
                    canPreviousPage ? "" : "disabled"
                  }`}
                  onClick={() => handlePreviousGroup()}
                >
                  <a
                    className="page-link"
                    role="link"
                    aria-disabled={!canPreviousPage}
                  >
                    Previous
                  </a>
                </li>
 
                {Array.from(
                  { length: endPageIndex - startPageIndex },
                  (_, i) => startPageIndex + i
                ).map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={` page-item ${
                      pageNumber === pageIndex ? "bg-purple" : ""
                    }`}
                    onClick={() => gotoPage(pageNumber)}
                  >
                    <a
                      className={`page-link ${
                        pageNumber === pageIndex ? "bg-purple" : ""
                      }`}
                      href="#"
                      role="link"
                      aria-current={
                        pageNumber === pageIndex ? "page" : undefined
                      }
                    >
                      {pageNumber + 1}
                    </a>
                  </li>
                ))}
 
                <li
                  className={`paginate_button page-item next ${
                    canNextPage ? "" : "disabled"
                  }`}
                  onClick={() => handleNextGroup()}
                >
                  <a
                    className="page-link"
                    href="#"
                    role="link"
                    aria-disabled={!canNextPage}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};
 
export default NewPagination;