import "devextreme/dist/css/dx.light.css"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { useSelector } from "react-redux"
import { Routes, Route, useLocation } from "react-router-dom"
import { layoutTypes } from "./constants/layout"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./App.css"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
import Authenticate from "routes/Authenticate"
import NotFound from "pages/WrongUrl/NotFound"
import configure from "configure"
import Roles from "pages/RolesRespons"
import axios from "axios"
import AccessDenied from "routes/AccessDenied"
import DataBankIndex from "pages/Marketing/Opportunity/Data Bank/DataBankIndex"
import ColdCallingIndex from "pages/Marketing/Cold Calling/ColdCallingIndex"
import Booking from "pages/Marketing/Booking/Booking"

import "devextreme/dist/css/dx.light.css"
import CalenderIndex2 from "pages/Calender/CalenderIndex2"
// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const { layoutType } = useSelector(state => ({
    layoutType: state.Layout.layoutType,
  }))
  const location = useLocation()

  const Layout = getLayout(layoutType)

  // const [roles, setRoles] = useState([])
  // function filterInnerArraysById(id) {
  //   return roles.map(innerArray => {
  //     return innerArray.filter(element => {
  //       const parts = element.split(",")
  //       const elementId = parseInt(parts[0])
  //       return elementId === id
  //     })
  //   })
  // }

  // const RolesPermission = async e => {
  //   const formData = new FormData()
  //   formData.append("user_id", localStorage.getItem("auth_id"))
  //   // setLoading(true)
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: `${configure.apiUrl}/staff_roles`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //       data: formData,
  //     }).then(response => {
  //       console.log("staff_roles", response)
  //       setRoles(response.data.data.map(item => JSON.parse(item.permission)))
  //       // setLoading(false)
  //     })
  //   } catch (err) {
  //     console.log(err)
  //     // setLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   RolesPermission()
  // }, [location])

  const privateRoutes = [
    // { id: 1, path: "roles", componentId: 17, component: <Roles /> },
    // {
    //   id: 2,
    //   path: "marketing/opportunity",
    //   componentId: 2,
    //   component: <DataBankIndex />,
    // },
    // {
    //   id: 3,
    //   path: "marketing/cold-calling",
    //   componentId: 42,
    //   component: <ColdCallingIndex />,
    // },
    // {
    //   id: 4,
    //   path: "marketing/all-bookings",
    //   componentId: 2,
    //   component: <Booking />,
    // },
  ]

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => {
          return (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  {route.module_id != undefined ? (
                    <Authenticate module={route.module_id} type={route.type}>
                      <Layout>{route.component}</Layout>
                    </Authenticate>
                  ) : (
                    <Layout>{route.component}</Layout>
                  )}
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          )
        })}

        {/* {
    path: `${configure.appUrl}calender`,
    component: <CalenderIndex2 />,
    module_id: "66",
    type: "view",
  }, */}
        {/* <Route
          path={`${configure.appUrl}calender`}
          element={<CalenderIndex2 />}
        /> */}
        {/* {privateRoutes.map(obj => {
          return (
            <Route
              key={obj.id}
              path={`${configure.appUrl}${obj.path}`}
              element={
                filterInnerArraysById(obj.componentId)
                  .map(item => item[0].split(",").map(Number)[2])
                  .includes(1) == true ? (
                  <Authmiddleware>
                    <Layout>{obj.component}</Layout>
                  </Authmiddleware>
                ) : (
                  <AccessDenied />
                )
              }
            />
          )
        })} */}

        {/* <Route
          path={`${configure.appUrl}marketing/opportunity`}
          element={
            filterInnerArraysById(2)
              .map(item => item[0].split(",").map(Number)[2])
              .includes(1) == true ? (
              <Authmiddleware>
                <Layout>
                  <DataBankIndex />
                </Layout>
              </Authmiddleware>
            ) : (
              <AccessDenied />
            )
          }
        /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
