import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Row, Form, Modal } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
import moment from "moment"
import configure from "configure"

const Profile = () => {
  const [formRows, setFormRows] = useState([{}])
  const [modal_small, setmodal_small] = useState(false)
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()
  const params = useParams()

  const [lead_belong, setLead_belong] = useState([])
  const [source, setSource] = useState([])
  const [assigned, setAssigned] = useState([])
  const [status, setStatus] = useState([])
  const [lead_type, setLead_type] = useState([])
  const [company_name, setCompany_name] = useState("")
  const [website, setWebsite] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState([])
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [pincode, setPincode] = useState("")
  const [description, setDescription] = useState("")
  const [location, setLocation] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [source_option, setSourceOption] = useState([])
  const [assign_option, setAssignOption] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])
  const [industry, setIndustry] = useState([])
  const [proposal_id, setProposalId] = useState("")
  const [contract_id, setContractId] = useState("")
  const type = [
    { label: "Hot", value: "1" },
    { label: "Cold", value: "2" },
    { label: "Warm", value: "3" },
    { label: "Normal", value: "4" },
  ]

  const [source_dependent, setSourceDependent] = useState(false)
  const [allIntroducers, setAllIntroducers] = useState([])
  const [source_name, setSourceName] = useState("")
  const [customer, setCust] = useState([])

  useEffect(() => {
    editLeadData()
  }, [])

  const locations = [
    {
      label: "India",
      value: "1",
    },
    {
      label: "United Kingdom",
      value: "2",
    },
  ]

  const [industry_option, setIndustryOption] = useState([])
  const [created_at, setCreatedAt] = useState("")

  const editLeadData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/lead/${params.id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        const data = response.data.data?.map(items => {
          setCreatedAt(items.created_at)
          setLead_type(type.filter(obj => obj.label == items.lead_type)[0])
          setCompany_name(items.company ? items.company : "")
          setWebsite(items.website ? items.website : "")
          setEmail(items.email ? items.email : "")
          setPhone(items.phonenumber ? items.phonenumber : "")
          setAddress(items.address ? items.address : "")
          setPincode(items.zip ? items.zip : "")
          setDescription(items.description)
          setFormRows(JSON.parse(items.person_details))
          setLead_belong(
            locations.filter(obj => obj.value == items.lead_belongs_to)[0]
          )
          if (items?.proposal_converted == true) {
            setProposalId(items.proposal_id)
          }
          if (items?.contract_converted == true) {
            setContractId(items.contract_id)
          }
        })
        // Fetching Another Api

        const fromData = new FormData()
        fromData.append("country_id", response.data.data[0]?.lead_belongs_to)
        await axios({
          method: "post",
          url: `${configure.apiUrl}/lead_belong`,
          data: fromData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setSourceOption(
            res.data.data.lead_source?.map(item => ({
              value: item.id,
              label: item.leadname,
            }))
          )
          setSource(
            res.data.data.lead_source
              ?.map(item => ({
                value: item.id,
                label: item.leadname,
              }))
              .filter(obj => obj.value == response.data.data[0]?.source_id)[0]
          )
          setAssignOption(
            res.data.data.assigned?.map(item => ({
              value: item.id,
              label: item.name,
            }))
          )
          setAssigned(
            res.data.data.assigned
              ?.map(item => ({
                value: item.id,
                label: item.name,
              }))
              .filter(obj => obj.value == response.data.data[0]?.asigned_id)[0]
          )
          setStatusOption(
            res.data.data.lead_status.map(item => ({
              value: item.id,
              label: item.name,
            }))
          )
          setStatus(
            res.data.data.lead_status
              ?.map(item => ({
                value: item.id,
                label: item.name,
              }))
              .filter(
                obj => obj.value == response.data.data[0]?.lead_status_id
              )[0]
          )

          // existing customers
          setCust(res.data.data.customer)
          setAllIntroducers(res.data.data.introducer)

          if (response.data.data[0]?.source == "Introducer") {
            setSourceDependent({
              label: response.data.data[0]?.source,
              list: res.data.data.introducer,
            })
            const testdata = res.data.data.introducer.filter(
              item => item.id == response.data.data[0]?.source_name
            )
            setSourceName({
              label: testdata[0]?.company,
              value: testdata[0]?.id,
            })
          } else if (response.data.data[0]?.source == "Customer") {
            setSourceDependent({
              label: response.data.data[0]?.source,
              list: res.data.data.customer,
            })
            const testdata = res.data.data.customer.filter(
              item => item.id == response.data.data[0]?.source_name
            )
            setSourceName({
              label: testdata[0]?.company,
              value: testdata[0]?.id,
            })
          } else {
            setSourceDependent(false)
          }
          // if
          setLoading(false)
        })

        // Get Country Api Fetch

        await axios({
          method: "get",
          url: `${configure.apiUrl}/country`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res2 => {
          const option = res2.data.data.country?.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
          setLocation(option)
          setCountry(
            option.filter(
              obj => obj.value == response.data.data[0]?.countryId
            )[0]
          )

          setLoading(false)
        })

        // Get Industry Api Fetch

        await axios({
          method: "get",
          url: `${configure.apiUrl}/industry`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res2 => {
          const option = res2.data.data?.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
          setIndustryOption(option)
          setIndustry(
            option?.filter(
              obj1 => obj1.value == response.data.data[0]?.industry
            )[0]
          )

          setLoading(false)
        })

        // Get State Api Fetch
        const formData = new FormData()
        formData.append("country_id", response.data.data[0]?.countryId)
        const res = await axios({
          method: "post",
          url: `${configure.apiUrl}/country_state`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          setStateOption(
            res.data.data?.map(obj => ({
              value: obj.id,
              label: obj.name,
            }))
          )
          setState(
            res.data.data
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
              .filter(item => item.value == response.data.data[0]?.stateId)[0]
          )
        })

        // Get City Api Fetch

        const formData2 = new FormData()
        formData2.append("state_id", response.data.data[0]?.stateId)
        const res2 = await axios({
          method: "post",
          url: `${configure.apiUrl}/state_city`,
          data: formData2,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          setCityOption(
            res.data.data?.map(obj => ({
              value: obj.id,
              label: obj.name,
            }))
          )
          setCity(
            res.data.data
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
              .filter(item => item.value == response.data.data[0]?.cityId)[0]
          )
        })

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
    }
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const [error, setError] = useState(null)

  const editLead = async e => {
    e.preventDefault()
    if (
      lead_belong.length != 0 &&
      source.length != 0 &&
      assigned.length != 0 &&
      status.length != 0 &&
      lead_type.length != 0 &&
      industry.length != 0 &&
      country.length != 0 &&
      state.length != 0 &&
      description.length != 0 &&
      lead_belong != undefined &&
      source != undefined &&
      assigned != undefined &&
      status != undefined &&
      lead_type != undefined &&
      industry != undefined &&
      country != undefined &&
      state != undefined &&
      description != undefined
    ) {
      setLoading(true)
      const formData = new FormData()
      const person_details = JSON.stringify(formRows)
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("lead_id", params.id)
      formData.append("lead_belongs_to", lead_belong.value)
      formData.append("source", source.value)
      formData.append("source_name", source_name.value)
      formData.append("assigned", assigned.value)
      formData.append("status", status.value)
      formData.append("lead_type", lead_type.label)
      formData.append("company_name", company_name)
      formData.append("website", website)
      formData.append("email", email)
      formData.append("phone", phone)
      formData.append("country", country.value)
      formData.append("state", state.value)
      if (city != undefined) {
        formData.append("city", city.value)
      } else {
        formData.append("city", "")
      }
      formData.append("address", address)
      formData.append("pincode", pincode)
      formData.append("description", description)
      formData.append("industry", industry.value)
      formData.append("person_details", person_details)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/edit_lead`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          swal({
            icon: "success",
            text: "Lead Edited Successfully",
          }).then(data => {
            navigate(`${configure.appUrl}sales/lead`)
          })
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      setError(true)
      // console.log("ee")
    }
  }
  //code for adding the edited data
  const onPersonName = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
    //  console.log(persondetail)
  }
  const onPersonEmail = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
    //  console.log(persondetail)
  }
  const onPersonPhone = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
    //  console.log(persondetail)
  }
  const onPersonDesignation = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
    //  console.log(persondetail)
  }
  const onPersonAuthority = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
    //  console.log(persondetail)
  }
  const onPersonPrimary = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
  }

  const onLinkedIn = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
  }
  const onFacebook = (e, key) => {
    const updatedArray = [...formRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setFormRows(updatedArray)
  }

  // small modal
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  // dynamic form add
  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const options = [
    {
      value: "true",
      label: "Yes",
    },
    {
      value: "false",
      label: "No",
    },
  ]
  const options2 = [
    {
      value: "on",
      label: "Yes",
    },
    {
      value: "off",
      label: "No",
    },
  ]

  const getState = async e => {
    setLoading(true)
    setCountry(e)
    setState([])
    setCity([])
    const formData = new FormData()
    formData.append("country_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/country_state`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setStateOption(
          res.data.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getCities = async e => {
    setLoading(true)
    setState(e)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/state_city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCityOption(
          res.data.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  // const convertProposal

  const onCreateProposal = async e => {
    swal({
      title: "Are you sure?",
      text: "You want to Create Proposal",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        setLoading(true)
        const formData = new FormData()
        formData.append("lead_id", params.id)
        formData.append("user_id", localStorage.getItem("auth_id"))
        try {
          const res = await axios({
            method: "post",
            url: `${configure.apiUrl}/convert_to_proposal`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            setLoading(false)
            swal({
              icon: "success",
              text: "Proposal Created Successfully",
            }).then(data => {
              window.open(
                `${configure.appUrl}sales/edit-proposal/${res.data.data.id}`
              )
            })
          })
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      }
    })
  }

  const onCreateContract = async e => {
    swal({
      title: "Are you sure?",
      text: "You want to Create Contract",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willDelete => {
      if (willDelete) {
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        formData.append("lead_id", params.id)
        try {
          const res = await axios({
            method: "post",
            url: `${configure.apiUrl}/convert_to_contract`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            setLoading(false)
            swal({
              icon: "success",
              text: "Contract Created Successfully",
            }).then(data => {
              window.open(
                `${configure.appUrl}sales/edit-contract/${res.data.data.id}`
              )
            })
          })
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      }
    })
  }

  const timeDifference = new Date() - new Date(created_at)
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
        <Form onSubmit={editLead}>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-purple fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <div className="col-md-9 mb-4">
              {proposal_id?.length == 0 ? (
                <a
                  type="button"
                  className="btn btn-purple   btn-label"
                  onClick={onCreateProposal}
                >
                  <i className="fab fa-rev label-icon"></i> Create Proposal
                </a>
              ) : (
                <a
                  type="button"
                  className="btn btn-purple   btn-label"
                  onClick={() => {
                    window.open(
                      `${configure.appUrl}sales/edit-proposal/${proposal_id}`,
                      "_blank"
                    )
                  }}
                >
                  <i
                    className="fas fa-eye label-icon"
                    style={{ fontSize: "14px" }}
                  ></i>{" "}
                  View Proposal
                </a>
              )}
              {contract_id?.length == 0 ? (
                <a
                  type="button"
                  className="btn btn-purple  btn-label ms-md-2 "
                  onClick={onCreateContract}
                >
                  <i className="bx bx-file label-icon"></i> Create Contract
                </a>
              ) : (
                <a
                  type="button"
                  className="btn btn-purple  btn-label ms-md-2 "
                  onClick={() => {
                    window.open(
                      `${configure.appUrl}sales/edit-contract/${contract_id}`,
                      "_blank"
                    )
                  }}
                >
                  <i
                    className="fas fa-eye label-icon"
                    style={{ fontSize: "14px" }}
                  ></i>{" "}
                  View Contract
                </a>
              )}
              {/* <span
                className="pl-10"
                style={{ fontSize: "16px", color: "black" }}
              >
                Created On : {moment(created_at).format("DD-MM-YYYY")} (
                {parseFloat(daysDifference)?.toFixed(0)} days)
              </span> */}
            </div>
            <div className="col-md-3 mb-3 text-end">
              <button
                onClick={e => editLead(e)}
                className=" btn ms-md-2 btn-save btn-label"
              >
                <i className="fas fa-save me-1 label-icon"></i> save
              </button>
              <a
                onClick={e => {
                  e.preventDefault()
                  navigate(-1)
                }}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
            <div className="row">
              <div className="col-4 mb-3 ">
                <label htmlFor="">
                  Lead Belong To <span className="text-danger">*</span>{" "}
                </label>
                <Select
                  onChange={e => setLead_belong(e)}
                  value={lead_belong}
                  placeholder="Select Location"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={location}
                />
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(lead_belong?.length == 0) == error
                    ? "Please select Location"
                    : null}
                </span>
              </div>
              <div className="col-4 mb-3 ">
                <label htmlFor="">
                  Source <span className="text-danger">*</span>{" "}
                </label>
                <Select
                  onChange={e => {
                    setSource(e)
                    if (e.label == "Introducer") {
                      setSourceDependent({
                        label: e.label,
                        list: allIntroducers,
                      })
                    } else if (e.label == "Customer") {
                      setSourceDependent({ label: e.label, list: customer })
                    } else {
                      setSourceDependent(false)
                    }
                  }}
                  value={source}
                  placeholder="Select Source"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={source_option}
                />
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(source?.length == 0) == error
                    ? "Please select Source"
                    : null}
                </span>
              </div>

              {source_dependent && (
                <div className="col-md-4 mb-3">
                  <label htmlFor="">
                    {source_dependent?.label}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    onChange={e => {
                      setSourceName(e)
                    }}
                    placeholder="Select Assigned"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    value={source_name}
                    options={source_dependent?.list?.map(cust => ({
                      label: cust.company,
                      value: cust.id,
                    }))}
                  />
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      paddingTop: "7px",
                    }}
                  >
                    {(assigned.length == 0) == error
                      ? "Please select Assigned"
                      : null}
                  </span>
                </div>
              )}

              <div className="col-4 mb-3 ">
                <label htmlFor="">
                  Assigned <span className="text-danger">*</span>
                </label>
                <Select
                  onChange={e => {
                    setAssigned(e)
                  }}
                  value={assigned}
                  placeholder="Select Assigned"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={assign_option}
                />
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(assigned?.length == 0) == error
                    ? "Please select Assigned"
                    : null}
                </span>
              </div>
              <div className="col-4 mb-3 ">
                <label htmlFor="">
                  Status <span className="text-danger">*</span>
                </label>
                <Select
                  onChange={e => {
                    setStatus(e)
                  }}
                  value={status}
                  placeholder="Select Status"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={status_option}
                />
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(status?.length == 0) == error
                    ? "Please select Lead Status"
                    : null}
                </span>
              </div>
              <div className="col-4 mb-3 ">
                <label htmlFor="">
                  Lead Type <span className="text-danger">*</span>
                </label>
                <Select
                  onChange={e => setLead_type(e)}
                  value={lead_type}
                  placeholder="Select Lead Type"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={type}
                />
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(lead_type?.length == 0) == error
                    ? "Please select Lead Type"
                    : null}
                </span>
              </div>
              <div className="col-4 mb-3 ">
                <label htmlFor="">
                  Industry <span className="text-danger">*</span>
                </label>
                <Select
                  onChange={e => setIndustry(e)}
                  value={industry}
                  placeholder="Select Industry"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={industry_option}
                />
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(industry?.length == 0) == error
                    ? "Please select Industry"
                    : null}
                </span>
              </div>
            </div>
          </Row>

          {/* Company info */}
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <h5 className="mb-3">Company Information</h5>
            <div className="col-md-6 mt-2 mb-3 mb-md-0">
              <label htmlFor="">Company Name</label>
              <input
                type="text"
                name="company"
                onChange={e => setCompany_name(e.target.value)}
                value={company_name}
                placeholder="Enter Company Name"
                className="form-control "
              />
            </div>
            <div className="col-md-6 mt-2 mb-3 mb-md-0">
              <label htmlFor="">Website</label>
              <input
                type="text"
                name="website"
                onChange={e => setWebsite(e.target.value)}
                value={website}
                placeholder="Enter Website"
                className="form-control "
              />
            </div>
            <div className="col-md-6 mt-2 mb-3 mb-md-0 mt-3">
              <label htmlFor="">Email Address</label>
              <input
                type="text"
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder="Enter Email Address"
                className="form-control "
              />
            </div>
            <div className="col-md-6 mt-2 mb-3 mb-md-0 mt-3">
              <label htmlFor="">Phone</label>
              <input
                type="text"
                onChange={e => setPhone(e.target.value)}
                defaultValue={phone}
                placeholder="Enter Phone"
                className="form-control "
              />
            </div>
          </Row>

          {/* Address */}
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <h5 className="mb-3">Address</h5>
            <div className="col-md-4 mb-3">
              <label htmlFor="">
                Country <span className="text-danger">*</span>{" "}
              </label>
              <Select
                name="country"
                onChange={getState}
                value={country}
                placeholder="Select Location"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={location}
              />
              <span
                className="text-danger"
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingTop: "7px",
                }}
              >
                {(country?.length == 0) == error
                  ? "Please select Country"
                  : null}
              </span>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">
                State <span className="text-danger">*</span>{" "}
              </label>
              <Select
                placeholder="Select State"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={state_option}
                value={state}
                onChange={getCities}
              />
              <span
                className="text-danger"
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  paddingTop: "7px",
                }}
              >
                {(state?.length == 0) == error ? "Please select State" : null}
              </span>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">City </label>
              <Select
                onChange={e => {
                  setCity(e)
                }}
                value={city}
                placeholder="Select City"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={city_option}
              />
            </div>
            <div className="col-md-8 mb-3">
              <label htmlFor="">Address</label>
              <input
                type="text"
                onChange={e => setAddress(e.target.value)}
                value={address}
                placeholder="Enter Address"
                className="form-control "
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Pin Code</label>
              <input
                type="text"
                onChange={e => setPincode(e.target.value)}
                value={pincode}
                placeholder="Enter Pin Code"
                className="form-control "
              />
            </div>

            <div className="col-12 edit-descrip">
              <div className="d-flex justify-space-between">
                <label htmlFor="">
                  Description <span className="text-danger">*</span>
                </label>
                <span
                  className="text-danger"
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    paddingTop: "7px",
                  }}
                >
                  {(description?.length == 0) == error
                    ? "Please enter description"
                    : null}
                </span>
              </div>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setDescription(data)
                }}
              />
            </div>
          </Row>

          {/* Contact */}
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <div className="d-flex justify-content-between">
              <h5> Contact</h5>
              <div className="text-end mb-3">
                <button
                  onClick={e => {
                    e.preventDefault()
                    onAddFormRow()
                  }}
                  className="btn btn-purple  "
                >
                  <i className="fas fa-plus me-2"></i>
                  Add More
                </button>
              </div>
            </div>
            {formRows?.map((person, key) => {
              return (
                <div key={key} className="mb-2">
                  <div className="d-flex">
                    <div className="contact-form d-flex">
                      <div className="contact-column edit-contact-column mb-3 mb-md-0">
                        <label htmlFor="">Name</label>
                        <input
                          type="text"
                          name="person_name"
                          defaultValue={person.person_name}
                          onChange={e => onPersonName(e, key)}
                          placeholder="Enter Name"
                          className="form-control "
                        />
                      </div>
                      <div className="contact-column edit-contact-column mb-3 mb-md-0">
                        <label htmlFor="">Email</label>
                        <input
                          type="text"
                          name="person_email"
                          defaultValue={person.person_email}
                          onChange={e => onPersonEmail(e, key)}
                          placeholder="Enter Email"
                          className="form-control "
                        />
                      </div>
                      <div className="contact-column edit-contact-column mb-3 mb-md-0">
                        <label htmlFor="">Phone</label>
                        <input
                          type="text"
                          name="person_phone"
                          defaultValue={person.person_phone}
                          onChange={e => onPersonPhone(e, key)}
                          placeholder="Enter Number"
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3 mb-md-0 d-flex align-items-center">
                        <div className="text-center me-3">
                          <label className="d-block">Authority</label>
                          <select
                            className="form-control"
                            onChange={e => onPersonAuthority(e, key)}
                            name="decision_maker"
                          >
                            {options2?.map((item, i) => {
                              return (
                                <option
                                  value={item.value}
                                  key={i}
                                  selected={
                                    item.value == person?.decision_maker
                                  }
                                >
                                  {item.label}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="text-center ">
                          <label className="d-block">Primary</label>
                          <select
                            className="form-control"
                            onChange={e => onPersonPrimary(e, key)}
                            name="chk_contact"
                          >
                            {options?.map((item, i) => {
                              return (
                                <option
                                  value={item.value}
                                  key={i}
                                  selected={item.value == person?.chk_contact}
                                >
                                  {item.label}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="contact-btns ml-10 d-flex justify-content-end align-items-end mb-3 mb-md-0">
                      <div className="text-end">
                        {key !== 0 ? (
                          <>
                            <button
                              onClick={e => {
                                e.preventDefault()
                                onDeleteFormRow(key)
                              }}
                              className="btn btn-red"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 pb-10">
                    <div className="col-4 mb-3 mb-md-0">
                      <label htmlFor="">Designation</label>
                      <input
                        type="text"
                        name="person_designation"
                        defaultValue={person.person_designation}
                        onChange={e => onPersonDesignation(e, key)}
                        placeholder="Enter Designation"
                        className="form-control "
                      />
                    </div>
                    <div className="col-4  mb-3 mb-md-0">
                      <label htmlFor="">Facebook Profile Link</label>
                      <input
                        type="text"
                        name="facebook"
                        defaultValue={person.facebook}
                        onChange={e => onFacebook(e, key)}
                        placeholder="Enter Facebook Profile Link"
                        className="form-control "
                      />
                    </div>
                    <div className="col-4 mb-3 mb-md-0">
                      <label htmlFor="">LinkedIn Profile Link</label>
                      <input
                        type="text"
                        name="linkedin"
                        defaultValue={person.linkedin}
                        onChange={e => onLinkedIn(e, key)}
                        placeholder="Enter LinkedIn Profile Link"
                        className="form-control "
                      />
                    </div>
                  </div>
                  {key !== formRows.length - 1 ? (
                    <div className="border-bottom mb-3 mt-4 "></div>
                  ) : null}
                </div>
              )
            })}
          </Row>
          
        </Form>
      </div>
    </React.Fragment>
  )
}

export default Profile
