import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import Select from "react-select"
// assets
import Loader from "pages/Separate/Loader"
import "../../../../../assets/css/Sales/Sales.css"
import moment from "moment"
import swal from "sweetalert"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"

const FollowUp = props => {
  const [fixButtons, setFixButtons] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)

  const [loading, setLoading] = useState(false)

  const [data, setDatas] = useState([])
  const navigate = useNavigate()
  const params = useParams()

  const [allFollowUps, setAllFollowUps] = useState([])

  const [purpose, setPurpose] = useState("")
  const [followdate, setFollowDate] = useState("")
  const [assigns, setAssigns] = useState([])
  const [editpurpose, seteditPurpose] = useState("")
  const [editfollowdate, seteditFollowDate] = useState("")
  const [editassigns, seteditAssigns] = useState([])
  const [followId, setFollowId] = useState("")

  const [assign_option, setAssignOption] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    setAssignOption(
      props.assigned_data.map(obj => ({
        label: obj.name,
        value: obj.id,
      }))
    )
  }, [props.assigned_data])

  // getting all followups
  useEffect(() => {
    const getAllFollowUps = async () => {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))

        const response = await axios.post(
          `${configure.apiUrl}/opportunity_followup_data`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setAllFollowUps(response.data.data.opportunity_followup_data)
        // console.log("notes", response.data.data)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (props.verticalActiveTab == "4") {
      getAllFollowUps()
    }
  }, [props.verticalActiveTab])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const handleAddFollowUp = async e => {
    e.preventDefault()
    if (assigns.length != 0) {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("purpose", purpose)
        formData.append("date", followdate)
        formData.append("assign", JSON.stringify(assigns))
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))
        const response = await axios.post(
          `${configure.apiUrl}/add_opportunity_followup`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        setNotesSidebar(false)
        setAllFollowUps(response.data.data)
        setLoading(false)
        swal("Follow Up added successfully!", {
          icon: "success",
        }).then(() => {
          setPurpose("")
          setFollowDate("")
          setAssigns("")
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
        swal("Something went wrong", "", "error")
      }
    } else {
      setError(true)
    }
  }

  const handleEditFollowUp = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("purpose", editpurpose)
      formData.append("date", editfollowdate)
      formData.append("assign", JSON.stringify(editassigns))
      formData.append("follow_id", followId)
      formData.append("opportunity_id", props.company_id)
      formData.append("user_id", localStorage.getItem("auth_id"))

      const response = await axios.post(
        `${configure.apiUrl}/edit_opportunity_followup`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setAllFollowUps(response.data.data.opportunity_followup)
      // console.log(response)
      setEditNotesSidebar(false)

      setLoading(false)

      swal("Follow Up Updated successfully!", {
        icon: "success",
      }).then(() => {
        setPurpose("")
        setFollowDate("")
        setAssigns("")
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "error")
    }
  }

  const onDeleteNote = follow_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to remove this Follow Up",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("follow_id", follow_id)
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))

        axios
          .post(`${configure.apiUrl}/delete_opportunity_followup`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            // console.log("de", response.data)

            if (response.data.data.length > 0) {
              setAllFollowUps(response.data.data)
            }
            Swal.fire({
              icon: "success",
              text: "Follow Up has been deleted successfully !",
            })
          })
      }
    })
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => setNotesSidebar(true)}
              className="btn btn-purple  fix-btn btn-label pe-0"
            >
              <i className="fas fa-plus me-2 label-icon"></i>
              <span>Note</span>
            </a>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="d-flex justify-content-between">
            <h5 className="text-dark">Add Follow Up</h5>
            <div className="text-end">
              <a
                onClick={() => setNotesSidebar(true)}
                className=" btn btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                FollowUp
              </a>
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>
          {/* Add Notes Sidebar */}
          <div
            className={`notes-sidebar ${
              notesSidebar ? "show-notes-sidebar" : ""
            }`}
          >
            <Form onSubmit={handleAddFollowUp}>
              <Row className="align-items-center ">
                <div className="col-12 d-flex justify-content-between">
                  <h5 className="text-dark">Add New FollowUp</h5>
                  <div className="text-end">
                    <button type="submit" className="btn btn-purple  me-2">
                      <i className="fas fa-plus me-2"></i>Add FollowUp
                    </button>
                    <a
                      onClick={() => setNotesSidebar(false)}
                      className=" btn ms-md-2 btn-red"
                    >
                      <i className="dripicons-cross me-2 icon-center"></i>CANCEL
                    </a>
                  </div>
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="touch">
                    FollowUp Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    required
                    value={followdate}
                    onChange={e => setFollowDate(e.target.value)}
                    className="form-control "
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <label htmlFor="">Assigned</label>{" "}
                  <span className="text-danger">*</span>
                  <Select
                    required
                    placeholder="Select"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={assign_option}
                    value={assigns}
                    isMulti
                    onChange={e => setAssigns(e)}
                  />
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      paddingTop: "7px",
                    }}
                  >
                    {(assigns.length == 0) == error
                      ? "Please assign at least one person"
                      : null}
                  </span>
                </div>

                <div className="col-12 mt-4">
                  <label htmlFor="">Purpose</label>{" "}
                  <span className="text-danger">*</span>
                  <textarea
                    required
                    className="form-control"
                    value={purpose}
                    onChange={e => setPurpose(e.target.value)}
                    placeholder="Enter Purpose"
                    style={{ height: "65px" }}
                  ></textarea>
                </div>
              </Row>
            </Form>
          </div>

          <div className="mt-4">
            <ul className="verti-timeline list-unstyled notes-box">
              {allFollowUps?.map(follow => {
                return (
                  <li key={follow.id} className="event-list position-relative">
                    <div className="event-timeline-dot">
                      <img
                        src={`https://mars.zithas.com/application/public/${follow.user_avatar}`}
                        className="userImg me-3 "
                        alt=""
                      />
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                        <div className=" d-flex justify-content-between ">
                          <div>
                            <p className="text-muted ">
                              <h6 className="d-inline me-2">
                                By {localStorage.getItem("auth_name")}
                              </h6>
                              {/* Created at{" "} */}
                              {/* {moment(follow.created_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )} */}
                              <span className="">
                                {" "}
                                Follow Up at{" "}
                                {moment(follow.datetime).format("DD-MM-YYYY")}
                              </span>
                            </p>
                          </div>
                          <div className="me-4">
                            <a
                              onClick={() => {
                                setFollowId(follow.id)

                                seteditAssigns(JSON.parse(follow.assign_user))
                                seteditFollowDate(follow.datetime)
                                seteditPurpose(follow.description)

                                setEditNotesSidebar(true)
                              }}
                              className="btn btn-sm edit-delete-btn text-success me-2"
                            >
                              <i className="fas fa-edit" title="Edit"></i>
                            </a>
                            <a
                              onClick={() => {
                                onDeleteNote(follow.id)
                              }}
                              className="btn btn-sm edit-delete-btn text-danger"
                            >
                              <i
                                className="fas fa-trash-alt"
                                title="Delete"
                              ></i>
                            </a>
                          </div>
                        </div>
                        <i className="bi bi-triangle-fill note-icon"></i>
                        {follow.description}
                        <p className="my-1">
                          <b>Assigned</b> :{" "}
                          {JSON.parse(follow.assign_user)?.map(ass => (
                            <span key={ass.value}>{ass.label}, </span>
                          ))}{" "}
                        </p>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
          <div
            className={`notes-sidebar ${
              editNotesSidebar ? "show-notes-sidebar" : ""
            }`}
          >
            <form onSubmit={handleEditFollowUp}>
              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Edit Follow Up</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-edit me-2"></i>Edit FollowUp
                    </button>
                    <a
                      onClick={() => setEditNotesSidebar(false)}
                      className="btn btn-red text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>
                      Close
                    </a>
                  </div>
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="touch">
                    FollowUp Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    value={editfollowdate}
                    onChange={e => seteditFollowDate(e.target.value)}
                    className="form-control "
                  />
                </div>
                <div className="col-md-4 mt-3">
                  <label htmlFor="">Assigned</label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    isMulti
                    styles={colourStyles}
                    options={assign_option}
                    value={editassigns}
                    onChange={e => seteditAssigns(e)}
                  />
                </div>
                <div className="col-md-4 mt-3 text-end"></div>
                <div className="col-12 mt-4">
                  <label htmlFor="">Purpose</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Purpose"
                    value={editpurpose}
                    onChange={e => seteditPurpose(e.target.value)}
                    style={{ height: "65px" }}
                  ></textarea>
                </div>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FollowUp
