import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap"
// assets
import "../../../../assets/css/Sales/Sales.css"
import moment from "moment"

const ViewActivityLogs = ({ logdata }) => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const [old_data, setOldData] = useState({})
  const [new_data, setNewData] = useState({})
  const [viewData, setViewData] = useState(false)

  const labelobj = Object.keys(old_data)
  const oldValue = Object.values(old_data)
  const newValue = Object.values(new_data)

  return (
    <React.Fragment>
      <div className="px-4">
        <div>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
           
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h5 className="text-dark"></h5>
                    <div className="text-end">
                      <a
                        onClick={() => navigate(-1)}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </div>
                  <div>
                    <ul className="verti-timeline list-unstyled">
                      {/* Activities */}
                      <li className="event-list position-relative">
                        <div className="event-timeline-dot">
                          <i
                            className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${
                              status.id === 3 ? "bx-fade-right" : ""
                            }`}
                          />
                        </div>
                        <div className="d-flex gap-1">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center">
                              <div>
                                {/* <h5>{status.description}</h5> */}
                                <h5>MileStone Updated</h5>
                                <p className="text-muted">
                                  At 06-08-2024 By Rahul Ovhal
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="project-timing">11:00:00</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewActivityLogs
