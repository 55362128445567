import React, { useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Select from "react-select"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Profile from "./Profile"
import Contacts from "./Contacts"
import Notes from "./Notes"
import Proposal from "./Proposal"
import Contract from "./Contract"
import Projects from "./Projects"
import Support from "./Support"
import Files from "./Files"
import GeneralTask from "./GeneralTask"

// components

const EditCustomer = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Edit Customer | Zithas Crm"

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const roles = [
    { label: "Key Account Manager", value: "1" },
    { label: "Project", value: "2" },
    { label: "Marketing", value: "3" },
    { label: "Developer", value: "4" },
    { label: "Finance", value: "5" },
    { label: "Managing Director", value: "6" },
    { label: "Coo Dashboard", value: "7" },
    { label: "The Staffguru", value: "8" },
    { label: "Default", value: "9" },
  ]

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const innerModules = [
    "Module 1",
    "Module 2",
    "Module 3",
    "Module 4",
    "Module 5",
  ]

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Edit Customer"} />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Contacts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Proposal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Contract
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Project
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    General Task
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleVertical("8")
                    }}
                  >
                    Support
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "9",
                    })}
                    onClick={() => {
                      toggleVertical("9")
                    }}
                  >
                    Files
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <Profile />
                  </TabPane>
                  <TabPane tabId="2">
                    <Contacts verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="3">
                    <Notes verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="4">
                    <Proposal verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="5">
                    <Contract verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="6">
                    <Projects verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="7">
                    <GeneralTask verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="8">
                    <Support verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                  <TabPane tabId="9">
                    <Files verticalActiveTab={verticalActiveTab} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditCustomer
