import React, { useEffect, useState } from "react"
import { Container, Table, Label } from "reactstrap"
// import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { json, Link, useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
// import indiaFlag from "../../../assets/images/flags/india.png"
// import UkFlag from "../../../assets/images/flags/uk.png"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// assets
// import "../../../assets/css/Sales/Sales.css"
// import "../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import ReactSelect from "react-select"
import Swal from "sweetalert2"
// import "../../../../assets/css/Work/projects.css"
import "../../../assets/css/Work/projects.css"
import { Width } from "devextreme-react/cjs/chart"
import { values } from "lodash"

const AddProject = () => {
  //   All useState
  const [isLoading, setLoading] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [custType, setCustType] = useState("")
  const [gitProject, setGitProject] = useState("New")
  const [ztplDetails, setZtplDetails] = useState("New")
  const [localServer, setLocalServer] = useState("New")
  const [fixButtons, setFixButtons] = useState(false)
  const [testingRows, setTestingRows] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])

  const [allCustomerOption, setAllCustomerOption] = useState([])
  const [allIntroducerOption, setAllIntroducerOption] = useState([])
  const [allLeadsourceOption, setAllLeadsourceOption] = useState([])
  const [allKeyIndustryOption, setAllKeyIndustryOption] = useState([])
  const [detailsFromCred, setDetailsFromCred] = useState(null)
  const [detailsFromCred2, setDetailsFromCred2] = useState(null)

  const [allZtplCredOption, setAllZtplCredOption] = useState([])
  const [all192CredOption, setAll192CredOption] = useState([])
  const [source, setSource] = useState([])
  const [isFolderCreated, setIsFolderCreated] = useState(false)
  const [isFolderCreated192, setIsFolderCreated192] = useState(false)
  const [isDbCreated, setIsDbCreated] = useState(false)
  const [isDbCreated192, setIsDbCreated192] = useState(false)

  // Api Integration
  const [projectBelong, setProjectBelong] = useState(null)
  const [projectName, setProjectName] = useState("")
  const [projectType, setProjectType] = useState(null)
  const [projectSource, setProjectSource] = useState(null)
  const [projectReference, setProjectReference] = useState(null)
  const [projectReferenceOption, setProjectReferenceOption] = useState([])
  const [projectReferenceOptions, setProjectReferenceOptions] = useState([])
  const [projectReferenceOptionsLabel, setProjectReferenceOptionsLabel] =
    useState("")
  const [projectCustomer, setProjectCustomer] = useState(null)
  const [projectResourceType, setProjectResourceType] = useState(null)
  const [projectIndustry, setProjectIndustry] = useState(null)
  const [projectDescription, setProjectDescription] = useState(null)
  const [projectKeyManager, setProjectKeyManager] = useState(null)
  const [projectKeyManagerOptions, setProjectKeyManagerOptions] = useState([])
  const [projectScrumOptions, setProjectScrumOptions] = useState([])
  const [projectScrumMaster, setProjectScrumMaster] = useState(null)
  const [projectMembersOptions, setProjectMembersOptions] = useState([])
  const [projectMembers, setProjectMembers] = useState([])
  const [projectEstSDate, setProjectEstSDate] = useState("")
  const [projectEstEDate, setProjectEstEDate] = useState(null)
  const [projectEstDBDate, setProjectEstDBDate] = useState(null)
  const [projectEstDays, setProjectEstDays] = useState(null)

  // Git Details states

  const [projectFromGitOption, setProjectFromGitOption] = useState([])
  const [projectFromGit, setProjectFromGit] = useState(null)
  const [projectFromGitName, setProjectFromGitName] = useState("")
  const [gitRepositoryLink, setGitRepositoryLink] = useState("")
  const [gitDemoLink, setGitDemoLink] = useState("")
  const [gitIcodeLink, setGitIcodeLink] = useState("")

  // erver Details states
  const [serverFolderName, setServerFolderName] = useState("")
  const [server192Link, setServer192Link] = useState("")
  const [serverZTPLLink, setServerZTPLLink] = useState("")
  const [zhost, setZhost] = useState("ftp.ztpl.net")
  const [zusername, setZusername] = useState("")
  const [zpassword, setZpassword] = useState("")
  const [zdatabasename, setZdatabasename] = useState("")
  const [zdbusername, setZdbusername] = useState("")
  const [zdbpassword, setZdbpassword] = useState("")

  // 192 Details
  const [host, setHost] = useState("192.168.0.2")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [databasename, setDatabasename] = useState("")
  const [dbusername, setDbusername] = useState("")
  const [dbpassword, setDbpassword] = useState("")

  // For Client information
  const navigate = useNavigate()

  document.title = "Add Project | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 360) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // const onFolderCreate = async e => {
  //   e.preventDefault()
  //   if (serverFolderName) {
  //     setLoading(true)

  //     const formData = new FormData()
  //     formData.append("folderName", serverFolderName)
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: `https://ftpdbtools.ztpl.net/create_folder.php`,
  //         data: formData,
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       if (response?.data?.status === "success") {
  //         setLoading(false)
  //         setIsFolderCreated(true)
  //         Swal.fire({
  //           icon: "success",
  //           text: "Folder Created successful.",
  //         })
  //       } else if (response?.data?.status === "error") {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "warning",
  //           text: `${response?.data?.message}`,
  //         })
  //       } else {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "error",
  //           text: `Something went wrong.`,
  //         })
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       text: "Please select Folder Name.",
  //     })
  //   }
  // }

  // const onFolderCreate2 = async e => {
  //   e.preventDefault()
  //   if (serverFolderName) {
  //     setLoading(true)

  //     const formData = new FormData()
  //     formData.append("folderName", serverFolderName)
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: `http://192.168.0.2:5001/jhfghffhj.php`,
  //         data: formData,
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       console.log("5001", response)
  //       if (response?.data?.status === "success") {
  //         setLoading(false)
  //         setIsFolderCreated192(true)
  //         Swal.fire({
  //           icon: "success",
  //           text: "Folder Created successful.",
  //         })
  //       } else if (response?.data?.status === "error") {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "warning",
  //           text: `${response?.data?.message}`,
  //         })
  //       } else {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "error",
  //           text: `Something went wrong.`,
  //         })
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       text: "Please select Folder Name.",
  //     })
  //   }
  // }

  // const onFtpCreated = async e => {
  //   e.preventDefault()
  //   // if (isFolderCreated) {
  //   if (zusername) {
  //     setLoading(true)
  //     const formData = new FormData()
  //     // folderName,ftpUser
  //     formData.append("ftpUser", zusername)
  //     formData.append("folderName", serverFolderName)
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: `https://ftpdbtools.ztpl.net/create_ftp.php`,
  //         data: formData,
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       if (response?.data?.success) {
  //         console.log("response", response)
  //         setLoading(false)
  //         setZpassword(response?.data?.ftpPassword)
  //         Swal.fire({
  //           icon: "success",
  //           text: "FTP Created successful.",
  //         })
  //       } else {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "warning",
  //           text: `${
  //             response?.data?.error
  //               ? response?.data?.error
  //               : "Something went wrong."
  //           }`,
  //         })
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       text: "Please select FTP User Name.",
  //     })
  //   }
  //   // } else {
  //   //   Swal.fire({
  //   //     icon: "warning",
  //   //     text: "Please Create folder first.",
  //   //   })
  //   // }
  // }

  // const onFtpCreated2 = async e => {
  //   e.preventDefault()
  //   if (host != "" && username != "" && password != "") {
  //     setLoading(true)
  //     const formData = new FormData()
  //     formData.append("host", host)
  //     formData.append("username", username)
  //     formData.append("password", password)
  //     formData.append("type", 2)
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: `${configure.apiUrl}/create_ftp`,
  //         data: formData,
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       if (response) {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "success",
  //           text: "FTP Created successful.",
  //         })
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       text: "Please select all mandatory field.",
  //     })
  //   }
  // }

  // const onDbCreated = async e => {
  //   e.preventDefault()
  //   if (zdatabasename && zdbusername) {
  //     setLoading(true)
  //     const formData = new FormData()
  //     formData.append("dbName", zdatabasename)
  //     formData.append("dbUser", zdbusername)

  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: `https://ftpdbtools.ztpl.net/create_dbs.php`,
  //         data: formData,
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       if (response) {
  //         console.log("res", response)
  //         setLoading(false)
  //         if (response?.data?.status == "true") {
  //           setZdbpassword(response?.data?.password)
  //           Swal.fire({
  //             icon: "success",
  //             text: "Database Created successful.",
  //           })
  //         } else {
  //           Swal.fire({
  //             icon: "warning",
  //             text: `${response?.data?.message}`,
  //           })
  //         }
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       text: "Please select all mandatory field.",
  //     })
  //   }
  // }

  // const onDbCreated2 = async e => {
  //   e.preventDefault()
  //   if (zdatabasename != "" && zdbusername != "" && zdbpassword != "") {
  //     setLoading(true)
  //     const formData = new FormData()
  //     formData.append("databasename", databasename)
  //     formData.append("dbusername", dbusername)
  //     formData.append("dbpassword", dbpassword)
  //     formData.append("type", 2)
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: `${configure.apiUrl}/create_db`,
  //         data: formData,
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       if (response) {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "success",
  //           text: "Database Created successful.",
  //         })
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       text: "Please select all mandatory field.",
  //     })
  //   }
  // }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  useEffect(() => {
    fetchData()
    fetchCustomerss()
  }, [])

  const fetchCustomerss = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_customer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // const modifyArr = response.data.data?.map(item => {
        //   return {
        //     label: item.company,
        //     value: item.id,
        //   }
        // })
        // setCustomerOptions(modifyArr)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const handleNew192 = e => {
    setLocalServer(e.target.value)
    setDetailsFromCred2(null)
    setHost("192.168.0.2")
    setUsername("")
    setPassword("")
    setDatabasename("")
    setDbusername("")
    setDbpassword("")
  }

  const handleNewZtpl = e => {
    setZtplDetails(e.target.value)
    setDetailsFromCred(null)
    setZhost("192.168.0.2")
    setZusername("")
    setZpassword("")
    setZdatabasename("")
    setZdbusername("")
    setZdbpassword("")
  }

  const handleZtplDfc = selectedOption => {
    setDetailsFromCred(selectedOption)
    setZusername(selectedOption.FTPUser ? selectedOption.FTPUser : "")
    setZpassword(selectedOption.FTPPassword ? selectedOption.FTPPassword : "")
    setZdatabasename(selectedOption.DBName ? selectedOption.DBName : "")
    setZdbusername(selectedOption.DBUsername ? selectedOption.DBUsername : "")
    setZdbpassword(selectedOption.DBPassword ? selectedOption.DBPassword : "")
  }

  const handle192Dfc = selectedOption => {
    setDetailsFromCred2(selectedOption)
    setUsername(selectedOption.FTPUser ? selectedOption.FTPUser : "")
    setPassword(selectedOption.FTPPassword ? selectedOption.FTPPassword : "")
    setDatabasename(selectedOption.DBName ? selectedOption.DBName : "")
    setDbusername(selectedOption.DBUsername ? selectedOption.DBUsername : "")
    setDbpassword(selectedOption.DBPassword ? selectedOption.DBPassword : "")
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectRelatedData`)
        .then(res => res.json())
        .then(data => {
          const modifyCus = data.data.customer?.map(list => {
            return {
              label: list.company,
              value: list.id,
            }
          })
          const modifyLocation = data.data.location?.map(obj => {
            return {
              label: obj.location,
              value: obj.id,
            }
          })
          const modifyKeyManager = data.data.keymanager?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyScrum = data.data.scrum?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyIntroducer = data.data.introducer?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyLeadsource = data.data.leadsource?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyIndustry = data.data.industry?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          const modifyZtpl = data.data.ztpl?.map(item => {
            return {
              label: item.Project,
              value: item.id,
              FTPHost: item.FTPHost,
              FTPUser: item.FTPUser,
              FTPPassword: item.FTPPassword,
              DBName: item.DBName,
              DBUsername: item.DBUsername,
              DBPassword: item.DBPassword,
            }
          })

          console.log("modifyZtpl", modifyZtpl)
          const modify192 = data.data.data_192?.map(item => {
            return {
              label: item.Project ? item.Project : "",
              value: item.id,
              // FTPHost: item.FTPHost ? item.FTPHost : "",
              FTPUser: item.FTPUser ? item.FTPUser : "",
              FTPPassword: item.FTPPassword ? item.FTPPassword : "",
              DBName: item.DBName ? item.DBName : "",
              DBUsername: item.DBUsername ? item.DBUsername : "",
              DBPassword: item.DBPassword ? item.DBPassword : "",
            }
          })

          const modifySourceOpt = data.data.source?.map(item => {
            return {
              label: item.leadname,
              value: item.id,
            }
          })

          const modifyArr = data.data.developers?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          setProjectMembersOptions(modifyArr)

          setSource(modifySourceOpt)

          setProjectKeyManagerOptions(modifyKeyManager)
          setProjectScrumOptions(modifyScrum)
          setLocationOption(modifyLocation)

          setCustomerOptions(modifyCus)
          setAllCustomerOption(modifyCus)

          setAllIntroducerOption(modifyIntroducer)
          setAllLeadsourceOption(modifyLeadsource)
          setAllKeyIndustryOption(modifyIndustry)

          setAllZtplCredOption(modifyZtpl)
          setAll192CredOption(modify192)
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onDescription = (e, key) => {
    let values = [...testingRows]
    values[key][e.target.name] = e.target.value
  }
  const onTime = (e, index) => {
    const inputValue = Number(e.target.value)
    if (projectEstDays) {
      if (projectEstDays >= inputValue) {
        const updatedRows = [...testingRows]
        updatedRows[index] = {
          ...updatedRows[index],
          [e.target.name]: e.target.value,
        }
        setTestingRows(updatedRows)
      } else {
        Swal.fire({
          icon: "warning",
          text: "You cannot enter a number greater than the Number Of Days",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Number Of Days is required before making changes.",
      })
    }
  }

  const projectTypes = [
    { label: "Regular Project", value: "1" },
    { label: "Dedicated Resource", value: "2" },
    { label: "Maintenance Project", value: "3" },
  ]

  const reference = [
    { label: "Select reference", value: "1" },
    { label: "Introducer", value: "2" },
    { label: "Customer", value: "3" },
  ]

  // const source = [
  //   { label: "Select Source", value: "1" },
  //   { label: "Introducer", value: "2" },
  //   { label: "Customer", value: "3" },
  //   { label: "Lead", value: "4" },
  // ]

  const ReferenceCustomer = [
    { label: "Select Customer", value: "1" },
    { label: "Zithas Tech", value: "2" },
    { label: "Global Biotech", value: "3" },
  ]

  const Customer = [
    { label: "Select Customer", value: "1" },
    { label: "Zithas Tech", value: "2" },
    { label: "Global Biotech", value: "3" },
  ]

  const ResourceType = [
    { label: "Fixed Rate", value: "1" },
    { label: "Project Hours", value: "2" },
    { label: "Dedicated Resource", value: "3" },
  ]

  const ProjectIndustry = [
    { label: "FMCG", value: "1" },
    { label: "Clininc", value: "2" },
    { label: "HealthCare", value: "3" },
  ]

  const projectStatus = [
    { label: "In Progress", value: "1" },
    { label: "Not Started", value: "2" },
    { label: "On Hold", value: "3" },
    { label: "Cancled", value: "4" },
    { label: "Finished", value: "5" },
  ]

  const KeyAccountManager = [
    { label: "Rahul Ovhal", value: "1" },
    { label: "Zain", value: "2" },
    { label: "Manav", value: "3" },
  ]

  const handleGitPeoject = selectedOption => {
    setProjectFromGit(selectedOption)
    setGitRepositoryLink(selectedOption.gitreposatirylink)
    // setGitDemoLink(selectedOption.demolink)
    // setGitIcodeLink(selectedOption.icode)
  }

  const handleSource = selectedOption => {
    setProjectSource(selectedOption)
    if (selectedOption.value == "14") {
      setProjectReferenceOptions([...allIntroducerOption])
      setProjectReferenceOptionsLabel("Introducer")
    } else if (selectedOption.value == "15") {
      setProjectReferenceOptions([...allCustomerOption])
      setProjectReferenceOptionsLabel("Customer")
    }
  }

  const getProject = async () => {
    setLoading(true)
    // role_id,user_id
    const roleId = localStorage.getItem("auth_role")
    const authId = localStorage.getItem("auth_id")
    const formData = new FormData()
    formData.append("role_id", roleId)
    formData.append("user_id", authId)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/projects`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const modifyArr = response?.data?.data?.project2?.map(item => {
          return {
            label: item.project_name,
            value: item.id,
            gitreposatirylink: item.gitreposatirylink,
            demolink: item.demolink,
            icode: item.icode,
          }
        })
        setProjectFromGitOption(modifyArr)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getProject()
  }, [])

  const getSelectedLabels = selectedOptions =>
    selectedOptions.map(option => option.value)

  const handleMembers = selectedOption => {
    setProjectMembers(selectedOption)
    const selectedLabels = getSelectedLabels(selectedOption)
    setTestingRows(prevRows => {
      const updatedRows = prevRows.filter(row =>
        selectedLabels.includes(row.id)
      )
      const newRows = selectedOption
        .filter(option => !prevRows.some(row => row.id === option.value))
        .map(option => ({
          id: option.value,
          milestone: option.label,
          estimatedtime: "",
        }))

      return [...updatedRows, ...newRows]
    })
  }

  const handleFolder = e => {
    setServerFolderName(e.target.value)
    if (e.target.value) {
      setServer192Link(`http://192.168.0.2/${e.target.value}`)
      setServerZTPLLink(`https://ztpl.net/${e.target.value}`)
    } else {
      setServer192Link("")
      setServerZTPLLink("")
    }
  }

  useEffect(() => {
    if (projectEstSDate && projectEstDays) {
      const startDate = new Date(projectEstSDate)
      const numDays = parseInt(projectEstDays, 10)
      const endDate = addBusinessDays(startDate, numDays)
      setProjectEstEDate(endDate.toISOString().split("T")[0])
    } else {
      setProjectEstEDate("")
    }
  }, [projectEstSDate, projectEstDays])

  function addBusinessDays(startDate, numDays) {
    const resultDate = new Date(startDate)
    let daysAdded = 0

    while (daysAdded < numDays) {
      resultDate.setDate(resultDate.getDate() + 1)
      const dayOfWeek = resultDate.getDay()

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++
      }
    }
    return resultDate
  }

  const handleDBDateChange = e => {
    const newDBDate = e.target.value
    if (projectEstEDate && new Date(newDBDate) < new Date(projectEstEDate)) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "The Delivery Before Date cannot be before the Estimated End Date.",
      })
    } else {
      setProjectEstDBDate(newDBDate)
    }
  }

  const handleSubmitBasicDetails = async e => {
    e.preventDefault()
    if (
      projectBelong != null &&
      projectType != null &&
      projectSource != null &&
      projectResourceType != null &&
      projectIndustry != null && 
      projectFromGit != null
    ) {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("role_id", localStorage.getItem("auth_role"))

      // Project Basic Details

      formData.append("projectbelogsto", projectBelong?.value)
      formData.append("project_name", projectName)
      formData.append("project_type", projectType?.value)
      formData.append("source", projectSource?.value)
      if (projectSource.value == "14") {
        formData.append("introducer", projectReferenceOption.value)
      }
      if (projectSource.value == "15") {
        formData.append("source_customer", projectReferenceOption?.value)
      }
      formData.append("customer", projectCustomer?.value)
      formData.append("resource_type", projectResourceType?.value)
      formData.append("industry", projectIndustry?.value)
      formData.append("description", projectDescription)

      // Members

      formData.append("keyaccountmanager", projectKeyManager?.value)
      formData.append("scrum_master", projectScrumMaster?.value)
      formData.append(
        "member",
        projectMembers?.map(list => list.value).toString()
      )
      formData.append("start_date", projectEstSDate)
      formData.append("end_date", projectEstEDate)
      formData.append("deliverydatebefore", projectEstDBDate)
      formData.append("no_of_days", projectEstDays)

      // Develolpers Details

      formData.append("dev_id", testingRows?.map(list => list.id).toString())
      formData.append(
        "dev_days",
        testingRows?.map(list => list.estimatedtime).toString()
      )

      // Server Details

      formData.append("folder_name", serverFolderName)
      formData.append("link_192", server192Link)
      formData.append("ztpl_link", serverZTPLLink)

      // git details

      // formData.append("git_status", custType == "Yes" ? 1 : 0)
      // formData.append("projectRadios", gitProject === "New" ? 1 : 0)
      formData.append("git_status", 1)
      formData.append("projectRadios", 0)
      formData.append("git_project", projectFromGitName)
      formData.append(
        "existing_git_project", projectFromGit?.value
      )

      formData.append("gitrepositorylink", gitRepositoryLink)
      formData.append("demo_link", gitDemoLink)
      formData.append("icode_link", gitIcodeLink)

      // ztpl details

      formData.append("ztpl_details", ztplDetails == "New" ? 1 : 0)
      formData.append("zhost", zhost)
      formData.append("zusername", zusername)
      formData.append("zpassword", zpassword)
      formData.append("zdatabasename", zdatabasename)
      formData.append("zdbusername", zdbusername)
      formData.append("zdbpassword", zdbpassword)

      // 192 details

      formData.append("192_details", ztplDetails == "New" ? 1 : 0)
      formData.append("host", host)
      formData.append("username", username)
      formData.append("password", password)
      formData.append("databasename", databasename)
      formData.append("dbusername", dbusername)
      formData.append("dbpassword", dbpassword)

      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/add_project`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            title: "Project Added successfully.",
          }).then(() => {
            navigate(`${configure.appUrl}work/projects`)
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          {/* <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Project"} /> */}
          <Form className="px-0" onSubmit={handleSubmitBasicDetails}>
            <Row className="">
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button
                  type="submit"
                  className="btn btn-save  fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  <span>Submit</span>
                </button>

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </button>
              </div>
              {/* Project Basic Details  */}
              <Row className="bg-white py-4 shadow mb-3 px-2">
                <div className="col-md-12 d-flex justify-content-end">
                  <div className="col-md-6 mb-3 text-end">
                    <button
                      type="submit"
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        navigate(-1)
                      }}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </button>
                  </div>
                </div>

                <h5 className="mb-3">Project Basic Details</h5>

                <div className="col-md-2 mt-2 mb-3">
                  <label htmlFor="">
                    Project Belongs To <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Project Belongs To"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location_option}
                    value={projectBelong}
                    onChange={selectedOption =>
                      setProjectBelong(selectedOption)
                    }
                  />
                </div>

                <div className="col-md-6 mt-2 mb-3">
                  <label htmlFor="">
                    Project Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Project Name"
                    className="form-control "
                    value={projectName}
                    onChange={e => setProjectName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mt-2 mb-3">
                  <label htmlFor="">
                    Project Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Project Type"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={projectTypes}
                    value={projectType}
                    onChange={selectedOption => setProjectType(selectedOption)}
                  />
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Source <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={source}
                      value={projectSource}
                      onChange={selectedOption => handleSource(selectedOption)}
                    />
                  </div>
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Select {projectReferenceOptionsLabel}
                    </label>
                    <Select
                      placeholder={`Select ${projectReferenceOptionsLabel}`}
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={projectReferenceOptions}
                      value={projectReferenceOption}
                      onChange={selectedOption =>
                        setProjectReferenceOption(selectedOption)
                      }
                    />
                  </div>
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Resource Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Resource Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={ResourceType}
                      value={projectResourceType}
                      onChange={selectedOption =>
                        setProjectResourceType(selectedOption)
                      }
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Customer <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Customer"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={customerOptions}
                      value={projectCustomer}
                      onChange={selectedOption =>
                        setProjectCustomer(selectedOption)
                      }
                    />
                  </div>

                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Project Industry <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Industry"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={allKeyIndustryOption}
                      value={projectIndustry}
                      onChange={selectedOption =>
                        setProjectIndustry(selectedOption)
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={projectDescription}
                    onReady={editor => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setProjectDescription(data)
                    }}
                  />
                </div>
              </Row>

              {/* Project Basic Details  */}
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">Members</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Key Account Manager</label>
                      <Select
                        placeholder="Select Key Account Manager"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={projectKeyManagerOptions}
                        value={projectKeyManager}
                        onChange={selectedOption =>
                          setProjectKeyManager(selectedOption)
                        }
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Scrum Master</label>
                      <Select
                        placeholder="Select Scrum Master"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={projectScrumOptions}
                        value={projectScrumMaster}
                        onChange={selectedOption =>
                          setProjectScrumMaster(selectedOption)
                        }
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3">
                      <label htmlFor="">Members</label>
                      <ReactSelect
                        placeholder="Select Members"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={projectMembersOptions}
                        isMulti="true"
                        value={projectMembers}
                        onChange={selectedOption =>
                          handleMembers(selectedOption)
                        }
                      />
                    </div>

                    <h5 className="mb-3 mt-4">Estimatation</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={projectEstSDate}
                        onChange={e => setProjectEstSDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Number OF Days<span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="number"
                        placeholder="Enter Number OF Days"
                        className="form-control "
                        value={projectEstDays}
                        onChange={e => setProjectEstDays(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 pb-5">
                      <label htmlFor="">Estimated End Date</label>
                      <input
                        disabled
                        type="date"
                        className="form-control "
                        value={projectEstEDate}
                        onChange={e => setProjectEstEDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 pb-5">
                      <label htmlFor="">
                        Delivery Before Date
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={projectEstDBDate}
                        onChange={e => {
                          handleDBDateChange(e)
                        }}
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-3">Develolpers Details</h5>
                    </div>
                    <div className="responsive-table">
                      <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                        <thead className="">
                          <tr>
                            <th>Devloper Name</th>
                            <th>
                              No. of Days{" "}
                              <span className="text-danger">
                                {testingRows.length > 0 ? "*" : ""}
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(testingRows || []).map((testingRow, index) => (
                            <tr key={testingRow.id}>
                              <td className="col-2 d-none">
                                <input
                                  type="number"
                                  placeholder=""
                                  className="form-control "
                                  name="sr.no"
                                  value={testingRow.id}
                                />
                              </td>
                              <td className="col-6">
                                <input
                                  type="text"
                                  placeholder="Enter Devloper Name"
                                  className="form-control "
                                  name="milestone"
                                  value={testingRow.milestone}
                                  onChange={e => onDescription(e, index)}
                                />
                              </td>
                              <td className="col-2">
                                <input
                                  required
                                  type="number"
                                  placeholder="Days.."
                                  className="form-control "
                                  name="estimatedtime"
                                  value={testingRow.estimatedtime}
                                  onChange={e => onTime(e, index)}
                                />
                              </td>
                            </tr>
                          ))}
                          {testingRows.length == 0 && (
                            <tr>
                              <td className="text-center" colSpan={10}>
                                No Developer Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </div>
              </Row>

              {/* // Git and Server Details Details  */}
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">
                      Git Details (if yes then it will update on git)
                    </h5>
                    <div className="col-12 mb-3 mb-md-0">
                      <span className="me-4 ">
                        <input
                          type="radio"
                          id="gitYes"
                          onInput={e => {
                            setCustType("Yes")
                            setGitProject("New")
                            setProjectFromGit(null)
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="Yes"
                        />
                        <label htmlFor="gitYes" className="ms-2">
                          Yes
                        </label>
                      </span>
                      <span className="me-4 ">
                        <input
                          type="radio"
                          id="gitNo"
                          defaultChecked={true}
                          onInput={e => {
                            setProjectFromGitName("")
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="No"
                        />
                        <label htmlFor="gitNo" className="ms-2">
                          No
                        </label>
                      </span>
                    </div> 
                    {custType === "Yes" ? (
                      <div className="col-12 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
                            type="radio"
                            id="newCheck"
                            defaultChecked={true}
                            onInput={e => {
                              setProjectFromGit(null)
                              setGitProject(e.target.value)
                            }}
                            name="type1"
                            value="New"
                          />
                          <label htmlFor="newCheck" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            onInput={e => {
                              setProjectFromGitName("")
                              setGitProject(e.target.value)
                            }}
                            id="existing"
                            name="type1"
                            value="Existing"
                          />
                          <label htmlFor="existing" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    {custType === "Yes" && gitProject === "New" ? (
                      <div className="col-md-6 mt-2 mb-3 mb-md-0">
                        <label htmlFor="">
                          Project Name (for git){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Project Name"
                          className="form-control "
                          value={projectFromGitName}
                          onChange={e => setProjectFromGitName(e.target.value)}
                          disabled={ gitProject == "New"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                     {custType === "Yes" && gitProject === "Existing" && (
                      <div className="col-md-6 mt-2 mb-3 mb-md-0">
                        <Label htmlFor="formrow-firstname-Input">
                          Project from Git{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select Project"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={projectFromGitOption}
                          value={projectFromGit}
                          onChange={selectedOption =>
                            handleGitPeoject(selectedOption)
                          }
                        />
                      </div>
                    )}

                    <div className="col-md-12 mt-4">
                      <h5 className="mb-3">Links</h5>
                      <div className="col-md-12 d-flex flex-column gap-3">
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Git Repository Link</label>
                          <input
                            type="text"
                            placeholder="Git Repository Link"
                            className="form-control "
                            value={gitRepositoryLink}
                            onChange={e => setGitRepositoryLink(e.target.value)}
                            disabled={ gitProject == "New"}
                            
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Demo Link</label>
                          <input
                            type="text"
                            placeholder="Enter Demo Link"
                            className="form-control "
                            value={gitDemoLink}
                            onChange={e => setGitDemoLink(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Icode Link</label>
                          <input
                            type="text"
                            placeholder="Enter Icode Link"
                            className="form-control "
                            value={gitIcodeLink}
                            onChange={e => setGitIcodeLink(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3 pb-4">
                  <Row className="bg-white d-flex flex-column gap-4 px-2 py-4 shadow mb-3">
                    <div className="d-flex justify-space-between align-items-end">
                      <div className="col-md-12 mt-2  mb-md-0 d-flex justify-content-start align-items-start">
                        <h5 className="mb-3">Server Details</h5>
                      </div>
                      {/* <div className="col-md-6 mt-2  mb-md-0 d-flex justify-content-end align-items-center">
                        {isFolderCreated192 === false && (
                          <button
                            className=" btn ms-md-2 btn-save btn-label"
                            type="button"
                            onClick={e => onFolderCreate2(e)}
                          >
                            <i className="bi bi-folder-fill me-1 label-icon"></i>
                            Create Folder in 192
                          </button>
                        )}

                        {isFolderCreated === false && (
                          <button
                            className=" btn ms-md-2 btn-save btn-label"
                            type="button"
                            onClick={e => onFolderCreate(e)}
                          >
                            <i className="bi bi-folder-fill me-1 label-icon"></i>
                            Create Folder in ztpl
                          </button>
                        )}
                      </div> */}
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        Enter Folder Name for creating folder on 192 & ZTPL{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="Enter Folder Name"
                        className="form-control "
                        value={serverFolderName}
                        onChange={e => handleFolder(e)}
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        192 server Link <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="192 server Link"
                        className="form-control"
                        disabled
                        value={server192Link}
                        onChange={e => setServer192Link(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        ZTPL Link <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="ZTPL Link"
                        className="form-control"
                        disabled
                        value={serverZTPLLink}
                        onChange={e => setServerZTPLLink(e.target.value)}
                      />
                    </div>{" "}
                  </Row>
                </div>
              </Row>

              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">ZTPL Details</h5>
                    <div className="col-md-12 mb-2 d-flex">
                      <div className="col-md-6 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
                            defaultChecked={true}
                            type="radio"
                            id="new"
                            onInput={e => {
                              handleNewZtpl(e)
                            }}
                            name="ztpl"
                            value="New"
                            
                          />
                          <label htmlFor="new" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            onInput={e => setZtplDetails(e.target.value)}
                            id="existing2"
                            name="ztpl"
                            value="existing"
                          />
                          <label htmlFor="existing2" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>

                      {ztplDetails === "existing" && (
                      <div className="col-md-6 mb-md-0">
                        <div className="col-md-12 mb-3">
                          <label htmlFor="">
                            Details from credentials{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <Select
                            placeholder="Select Details"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            styles={colourStyles}
                            options={allZtplCredOption}
                            value={detailsFromCred}
                            onChange={selectedOption =>
                              handleZtplDfc(selectedOption)
                            }
                          />
                        </div>
                      </div>
                      )} 
                    </div>

                    <div className="col-md-6 border-end">
                      {/* {ztplDetails == "New" ? (
                        <>
                          <div className="col-md-12 mt-3 mb-4 mb-md-0">
                            <label htmlFor="">
                              FTP Host Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              required
                              readOnly
                              type="text"
                              placeholder="Host Link"
                              className="form-control "
                              value={zhost}
                              onChange={e => setZhost(e.target.value)}
                            />
                          </div>
                        </>
                      ) : ( */}
                        <div className="col-md-12 mt-3 mb-4 mb-md-0">
                          <label htmlFor="">
                            FTP Host Name <span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            readOnly
                            type="text"
                            placeholder="Enter Host Link"
                            className="form-control "
                            value={zhost}
                            onChange={e => setZhost(e.target.value)}
                            disabled={ztplDetails == "New"}
                          />
                        </div>
                       {/* )} */}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly={ztplDetails === "existing"}
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zusername}
                          onChange={e => setZusername(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="FTP Password"
                          className="form-control "
                          value={zpassword}
                          onChange={e => setZpassword(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {/* {ztplDetails == "New" ? (
                        <div className="col-md-12 mb-3 mb-md-0 d-flex justify-content-end align-items-end">
                          <div className="">
                            <button
                              onClick={onDbCreated}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-database-fill-add label-icon"></i>{" "}
                              Create DB
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly={ztplDetails === "existing"}
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={zdatabasename}
                          onChange={e => setZdatabasename(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly={ztplDetails === "existing"}
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zdbusername}
                          onChange={e => setZdbusername(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          readOnly
                          type="text"
                          placeholder="Enter FTP Password"
                          className="form-control "
                          value={zdbpassword}
                          onChange={e => setZdbpassword(e.target.value)}
                          disabled={ztplDetails == "New"}

                        />
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">192 Details</h5>
                    <div className="col-md-12 mb-2 d-flex">
                      <div className="col-md-6 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
                            type="radio"
                            id="new1"
                            defaultChecked={true}
                            onInput={e => {
                              handleNew192(e)
                            }}
                            name="local"
                            value="New"
                          />
                          <label htmlFor="new1" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
                            type="radio"
                            onInput={e => setLocalServer(e.target.value)}
                            id="existing3"
                            name="local"
                            value="existing"
                          />
                          <label htmlFor="existing3" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>

                      {localServer === "existing" && (
                        <div className="col-md-6 mb-md-0">
                          <div className="col-md-12 mb-3">
                            <label htmlFor="">
                              Details from credentials{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <Select
                              placeholder="Select Details"
                              className="basic-multi-select"
                              classNamePrefix=" select"
                              styles={colourStyles}
                              options={all192CredOption}
                              value={detailsFromCred2}
                              onChange={selectedOption =>
                                handle192Dfc(selectedOption)
                              }
                            />
                          </div>
                        </div>
                       )} 
                    </div>

                    <div className="col-md-6 border-end">
                      {/* {localServer == "New" ? (
                        <>
                          <div className="col-md-12 mb-3 mb-md-0 d-flex justify-content-end align-items-end">
                            <div className="">
                              <button
                                onClick={onFtpCreated2}
                                className=" btn ms-md-2 btn-save btn-label"
                              >
                                <i className="bi bi-person-fill-add label-icon"></i>{" "}
                                Create FTP
                              </button>
                            </div>
                          </div>
                          <div className="col-md-12 mt-3 mb-4 mb-md-0">
                            <label htmlFor="192host">
                              FTP Host Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              required
                              readOnly
                              id="192host"
                              type="text"
                              placeholder="Host Link"
                              className="form-control "
                              value={host}
                              onChange={e => setHost(e.target.value)}
                            />
                          </div>
                        </>
                      ) : ( */}
                        <div className="col-md-12 mt-3 mb-4 mb-md-0">
                          <label htmlFor="">
                            FTP Host Name <span className="text-danger">*</span>
                          </label>
                          <input
                            required
                            readOnly
                            type="text"
                            placeholder="Enter Host Link"
                            className="form-control "
                            value={host}
                            onChange={e => setHost(e.target.value)}
                            disabled={localServer == "New"}
                            
                          />
                        </div>
                      {/* )} */}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={username}
                          onChange={e => setUsername(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter FTP Password"
                          className="form-control "
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      {/* {localServer == "New" ? (
                        <div className="col-md-12 mb-3 mb-md-0 d-flex justify-content-end align-items-end">
                          <div className="">
                            <button
                              onClick={onDbCreated2}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-database-fill-add label-icon"></i>{" "}
                              Create DB
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={databasename}
                          onChange={e => setDatabasename(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={dbusername}
                          onChange={e => setDbusername(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder="Enter FTP Password"
                          className="form-control "
                          value={dbpassword}
                          onChange={e => setDbpassword(e.target.value)}
                          disabled={localServer == "New"}

                        />
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddProject
