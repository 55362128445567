import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import configure from "configure";

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);

  const navigate = useNavigate()

  const onLogout = () => {
    localStorage.removeItem('auth_id')
    localStorage.removeItem('auth_name')
    localStorage.removeItem('auth_avt')
    localStorage.removeItem('auth_role')
    navigate(`${configure.appUrl}login`)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={`https://mars.zithas.com/application/public/${localStorage.getItem("auth_avt")}`}
            alt="Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{localStorage.getItem('auth_name')}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <div className="dropdown-divider" /> */}
          <a onClick={onLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
