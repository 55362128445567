import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import "../../../assets/css/Sales/Proposal.css"

import {
  Document,
  Page,
  Image,
  Text,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  View,
  Font,
} from "@react-pdf/renderer"
import zithasLogo from "../../../assets/images/zithas-dark-logo.png"
import JuliusSir from "../../../assets/images/julius.png"
import advardSir from "../../../assets/images/edvard.png"
import phasesImg from "../../../assets/images/development-phases.png"
import serviceImg from "../../../assets/images/service-practice.png"
import axios from "axios"
import configure from "configure"
import moment from "moment"
import Loader from "pages/Separate/Loader"


const MyProposalDocument = ({ loaction, proposalData }) => {

  const ClientInfo = [
    {
      lable: "Company Name",
      value: proposalData?.companyname && proposalData?.companyname != "null" && proposalData?.companyname
    },
    {
      lable: "Contact Person",
      value: proposalData?.contactname && proposalData?.contactname != "null" && proposalData?.contactname
    },
    {
      lable: "Address",
      value: proposalData?.address && proposalData?.address != "null" && proposalData?.address
    },
    {
      lable: "Contact",
      value: proposalData?.cilentcontact && proposalData?.cilentcontact != "null" && proposalData?.cilentcontact
    },
    {
      lable: "Email id",
      value: proposalData?.email && proposalData?.email != "null" && proposalData?.email
    },
    {
      lable: "Website",
      value: proposalData?.website && proposalData?.website != "null" && proposalData?.website
    }
  ]

  const providerInfo = [
    {
      lable: "Company Name",
      value: proposalData?.company2 && proposalData?.company2 != "null" && proposalData?.company2
    },
    {
      lable: "Contact Person",
      value: proposalData?.contactmember && proposalData?.contactmember != "null" && proposalData?.contactmember
    },
    {
      lable: "Address",
      value: proposalData?.address1 && proposalData?.address1 != "null" && proposalData?.address1
    },
    {
      lable: "Contact",
      value: proposalData?.contact && proposalData?.contact != "null" && proposalData?.contact
    },
    {
      lable: "Email id",
      value: proposalData?.provideremail && proposalData?.provideremail != "null" && proposalData?.provideremail
    },
    {
      lable: "Website",
      value: proposalData?.website1 && proposalData?.website1 != "null" && proposalData?.website1
    }
  ]

  const testingDelivery = JSON.parse(proposalData?.deliverytesting ? proposalData?.deliverytesting : "[]")

  const termsAgreement = JSON.parse(proposalData?.terms ? proposalData?.terms : "[]")

  let costBreakDown = JSON.parse(proposalData?.costbreakdown ? proposalData?.costbreakdown : "[]")

  // costBreakDown = [...costBreakDown, ...costBreakDown, ...costBreakDown, ...costBreakDown, ...costBreakDown, ...costBreakDown, ...costBreakDown, ...costBreakDown]

  // Font.register({
  //   family: 'Noto Sans',
  //   fonts: [
  //     { src: 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap' },
  //     // { src: 'https://fonts.gstatic.com' },
  //     // { src: 'https://fonts.googleapis.com' }
  //   ]
  // })

  Font.register({
    family: "Noto Sans",
    fonts: [
      {
        src: "https://fonts.boords.com/NotoSans-Regular.ttf",
        fontWeight: 'bold',
      }
    ]
  });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 45,
      paddingHorizontal: 30,
      position: "relative",
      fontFamily: 'Noto Sans'
    },
    topHeader: {
      paddingBottom: "12px",
      borderBottom: "2px solid purple",
      textAlign: "center",
      fontSize: "16px",
      fontFamily: "Noto Sans",
      fontWeight: "bold"
    },
    logo: {
      width: 380,
      marginVertical: 15,
      marginLeft: 70
    }
    ,
    header: {
      fontSize: 25,
      textAlign: 'center',
      margin: '25px 0',
      fontWeight: 'bold'
    },
    header2: {
      fontSize: 17,
      textAlign: 'center',
      margin: '30px 0 20px',
      fontWeight: 600
    },
    date: {
      fontSize: 14,
      textAlign: 'center',
      margin: '10px 0',
      color: "#444"
    },
    table: {
      display: "table",
      width: "47%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#999",
      borderRightWidth: 0,
      borderBottomWidth: 0,
      textAlign: "left !important"
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableCol: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#999",
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      margin: 5,
      fontSize: 9
    },
    tableBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "40px"
    },
    paragraph: {
      fontSize: "10px",
      marginTop: "13px",
      lineHeight: "1.5px"
    },
    footer: {
      position: "absolute",
      width: "100%",
      bottom: "5%",
      left: "5%",
      fontSize: "12px",
      textAlign: "center"
    },
    bottomLine: {
      display: 'flex',
      flexDirection: 'row',
      height: '5px',
      backgroundColor: '#999',
      marginBottom: '7px'
    }
  })

  const indexData = [
    "INTRODUCTION",
    "ABOUT ZITHAS",
    "WEB DEVELOPMENT PROJECT DESCRIPTION",
    "SERVICES PRACTICES",
    "DELIVERABLES & TESTING",
    "TERMS AND AGREEMENT",
    "CONCLUSION"
  ]

  return (
    <Document style={styles.body} >
      <Page size="A4" orientation="80%" style={styles.body}>
        <Image style={styles.logo} src={zithasLogo} />
        <Text style={styles.header}>{proposalData?.proposaltitile}</Text>
        <Text style={styles.header2}>{proposalData?.proposalsubtitle}</Text>
        <Text style={styles.date}>{moment(proposalData?.date).format("DD MMMM YYYY")}</Text>

        <View style={styles.tableBox}>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "100%", backgroundColor: "#333", color: "white", textAlign: "center" }}>
                <Text style={styles.tableCell}>Client Infomation</Text>
              </View>
            </View>
            {
              ClientInfo.map((client, i) => {
                return (
                  <View key={i} style={styles.tableRow}>
                    <View style={{ ...styles.tableCol, width: "40%", fontWeight: 'bold' }}>
                      <Text style={{ ...styles.tableCell }}>{client.lable}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "60%", color: "#666" }}>
                      <Text style={styles.tableCell}>{client.value}</Text>
                    </View>
                  </View>
                )
              })
            }
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "100%", backgroundColor: "#333", color: "white", textAlign: "center" }}>
                <Text style={styles.tableCell}>Provider Infomation</Text>
              </View>
            </View>
            {
              providerInfo.map((client, i) => {
                return (
                  <View key={i} style={styles.tableRow}>
                    <View style={{ ...styles.tableCol, width: "40%" }}>
                      <Text style={styles.tableCell}>{client.lable}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "60%", color: '#666' }}>
                      <Text style={styles.tableCell}>{client.value}</Text>
                    </View>
                  </View>
                )
              })
            }
          </View>
        </View>

        <Text
          style={{ ...styles.footer, fontSize: "10px", borderTop: "none" }}
        >
          Zithas Technologies is pleased to submit a proposal for the and {proposalData?.proposaltitile} to {proposalData?.companyname} This proposal describes the technology,services
          terms, and schedule for the project
        </Text>
      </Page>

      <Page size="A4" style={styles.body}>

        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        <View style={{ marginTop: "30px" }}>
          <View style={{ textAlign: "center", fontWeight: 900, fontSize: "13px", marginBottom: "14px" }}>
            <Text>Table of Contents</Text>
          </View>
          {/* main Index */}
          <View style={{ fontWeight: '600', textTransform: "capitalize" }} >

            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '11px', marginBottom: '10px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ marginRight: '15px' }}>1</Text>
                <Text>Introduction</Text>
              </View>
              <Text style={{ margin: "0 4px" }}>
                ..............................................................................................................................................
              </Text>
              <Text>2</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '11px', marginBottom: '10px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ marginRight: '15px' }}>2</Text>
                <Text>About Zithas</Text>
              </View>
              <Text style={{ margin: "0 4px" }}>
                ................................................................................................................................................
              </Text>
              <Text>3</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '11px', marginBottom: '10px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ marginRight: '15px' }}>3</Text>
                <Text>Web Development Project Description</Text>
              </View>
              <Text style={{ margin: "0 4px" }}>
                .................................................................................................
              </Text>
              <Text>4</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '11px', marginBottom: '10px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ marginRight: '15px' }}>4</Text>
                <Text>Services Practices</Text>
              </View>
              <Text style={{ margin: "0 4px" }}>
                .......................................................................................................................................
              </Text>
              <Text>5</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '11px', marginBottom: '10px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ marginRight: '15px' }}>5</Text>
                <Text>Deliverables & Testing</Text>
              </View>
              <Text style={{ margin: "0 4px" }}>
                .............................................................................................................................
              </Text>
              <Text>6</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '11px', marginBottom: '10px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ marginRight: '15px' }}>6</Text>
                <Text>Terms and Agreement</Text>
              </View>
              <Text style={{ margin: "0 4px" }}>
                ................................................................................................................................
              </Text>
              <Text>7</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '11px', marginBottom: '10px' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ marginRight: '15px' }}>7</Text>
                <Text>Conclusion</Text>
              </View>
              <Text style={{ margin: "0 4px" }}>
                ..................................................................................................................................................
              </Text>
              <Text
                render={({ pageNumber, totalPages }) =>
                  `${totalPages - 1}`
                }
              >

              </Text>
            </View>

          </View>
        </View>

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      <Page size="A4" style={styles.body}>
        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px"
          }}>1. Introduction</Text>

          <Text style={styles.paragraph}>
            This proposal describes the technology, services, terms, and schedule for the Website development and Management System
            project.
          </Text>

          <Text style={styles.paragraph}>
            In the digital age, the website frequently serves as your business or organization’s front where you can manage the entire
            business process and customers. Our mission is to give you a professional on-line business process that will generally enhance
            your organization productivity and management, as well as move prospects through the sales process.
          </Text>

          <Text style={{ ...styles.paragraph, marginTop: "40px", padding: 10, backgroundColor: "#cccccc" }}>
            Zithas Technologies (Hereafter known as the Provider) is pleased to submit a proposal for the Software Development project of {ClientInfo[0].value} (Hereafter known as the Client).
          </Text>
        </View>
        {/* =============== */}

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      <Page size="A4" style={styles.body}>
        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px"
          }}>2. ABOUT ZITHAS TECHNOLOGIES</Text>

          <Text style={styles.paragraph}>
            Zithas Technologies is an IT service providing company with a group of experts working under various fields to bring to you the
            most appropriate solutions available to your requirements. We specialize in the field of bespoke website design, CRM
            Development, ERP development and other web solutions and services. We hold an experience base of more than 12 years.
          </Text>

          <Text style={styles.paragraph}>
            We assure you positive and clear results along with a completely novel experience that will help your business expand its doors
            and reach wider horizons.
          </Text>

          <Text style={{ fontWeight: "900", marginTop: "20px", fontSize: "15px" }}>
            Our Vision is our Inspiration!
          </Text>

          <Text style={styles.paragraph}>
            Assured quality and effective communication are the pillars we abide to!
          </Text>

          <Text style={{ fontWeight: "900", marginTop: "20px", fontSize: "19px" }}>
            From the Founder’s Desk!
          </Text>


          <Text style={styles.paragraph}>
            Being the founders, the sole purpose under which we work is keeping our clients happy and helping their business flourish. We
            run with the motto “You Grow We Grow” and make sure that we get along our clients expectations.
          </Text>

          <Text style={styles.paragraph}>
            For us, Zithas is not just business, it is an opportunity to learn, to develop and to achieve. It keeps us motivated and inspires us
            to reach the top and be the best!
          </Text>

          <Text style={styles.paragraph}>
            Understanding what our clients want and offering them what they expect is the overriding purpose of our company. We aim to
            develop and implement optimized strategies to provide more business opportunities to our clients and help in actively engaging
            and growing our client's business.
          </Text>
        </View>

        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
          {
            loaction == 1 ?
              <View style={{ display: "flex", alignItems: 'center', marginTop: "30px" }}>
                <Image style={{ width: "150px" }} src={JuliusSir} />
                <Text style={{ fontWeight: "900", marginTop: "6px", fontSize: "10px" }}>
                  Julius Gohil
                </Text>
              </View> :
              <>
                <View style={{ display: "flex", alignItems: 'center', margin: "auto 12px", marginTop: "30px" }}>
                  <Image style={{ width: "150px" }} src={JuliusSir} />
                  <Text style={{ fontWeight: "900", marginTop: "6px", fontSize: "10px" }}>
                    Julius Gohil
                  </Text>
                </View>
                <View style={{ display: "flex", alignItems: 'center', margin: "0 12px", marginTop: "30px" }}>
                  <Image style={{ width: "150px" }} src={advardSir} />
                  <Text style={{ fontWeight: "900", marginTop: "6px", fontSize: "10px" }}>
                    Edward Ball
                  </Text>
                </View>

              </>
          }
        </View>
        {/* =============== */}

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      <Page size="A4" style={styles.body}>

        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>


        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase"
          }}>3. {proposalData?.proposaltitile} PROJECT DESCRIPTION</Text>

          <Text style={styles.paragraph}>
            The proposed {proposalData?.proposaltitile} will take place in several distinct phases: Strategy, Planning, Build, and Deploy
          </Text>

          <Text style={{ fontWeight: "900", marginTop: "10px", fontSize: "13px" }}>
            Figure : Development Phases.
          </Text>

          <View style={{ marginTop: "10px" }}>
            <Image style={{ width: "90%" }} src={phasesImg} />
          </View>


          <View>
            <Text style={styles.paragraph}>
              <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900, textDecoration: 'underline' }}>1. Strategy : </Text>
              Phase, the scope, requirements, and description of the final system will be determined and documented. It also includes
              the overarching site goals, number of screens, app hierarchy, merchandising needs, language and web tools to be used (i.e. carts),
              plus forms, animations/graphics, and other specialized content.
            </Text>
          </View>

          <View>
            <Text style={styles.paragraph}>
              <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900, textDecoration: 'underline' }}>2. Planning : </Text>
              In this phase, the sketches and/or page mock-ups that reflects the general appearance of the system shall be created
              and submitted for approval. Once approved, the system development will begin in the next phase during which the client is
              responsible for providing the content (text, images, audio files, video files, etc.).
            </Text>
            {/* inner */}
            <View style={{ marginLeft: '20px' }}>
              <View>
                <Text style={styles.paragraph}>
                  <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900, textDecoration: 'underline' }}>i. Creation and Coding : </Text>
                  Screen are created in the previously determined language (PHP, HTML, CSS) to meet all the
                  appearance, performance, and content requirements. These flow will be reviewed by the Client with appropriate feedback for
                  revision
                </Text>
              </View>

              <View>
                <Text style={styles.paragraph}>
                  <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900, textDecoration: 'underline' }}>ii. Revisions : </Text>
                  Are revised in accordance with the review/feedback but within limit of scope.
                </Text>
              </View>

              <View>
                <Text style={styles.paragraph}>
                  <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900, textDecoration: 'underline' }}>iii. Review and Testing : </Text>
                  The finished Application will be reviewed and all functional aspects tested (i.e. forms, carts).
                </Text>
              </View>
            </View>

          </View>

          <View>
            <Text style={styles.paragraph}>
              <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900, textDecoration: 'underline' }}>3. Build : </Text>
              In this phase, Creation &Coding, screen Revision, system Review and Testing will be take place.
            </Text>
          </View>

          <View>
            <Text style={styles.paragraph}>
              <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900, textDecoration: 'underline' }}>4. Deploy : </Text>
              In this phase, the system will be uploaded and go live once the review of all the revisions &modifications are completed.
              During successful one-week period, all training materials will be prepared (videos, manuals, guides, etc.). Once all the materials
              and other details are transferred, the website project will be considered complete.
            </Text>
          </View>

        </View>
        {/* =============== */}

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      <Page size="A4" style={styles.body}>

        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px"
          }}>4. SERVICES PRACTICES</Text>

          <View style={{ display: "flex", alignItems: 'center', marginTop: "20px" }}>
            <Image style={{ width: "80%" }} src={serviceImg} />
          </View>

          <Text style={{ fontWeight: "900", marginTop: "10px", fontSize: "12px", marginBottom: "20px" }}>
            The {proposalData?.proposaltitile} project will be completed in the following way:
          </Text>

          <Text style={styles.paragraph}>
            1. The Provider will assign a project manager to oversee the project and serve as a single point of for project communication.
            The provider project team will also consist of additional developers and other support staff members who will contribute as
            project requirements dictate.
          </Text>

          <Text style={styles.paragraph}>
            2. Project work will generally take place at the Provider facilities, however the Provider project manager will coordinate regular
            on-site visits on Client premises for meetings, evaluations, observations, reviews, testing, and other project purposes as needed.
          </Text>

          <Text style={styles.paragraph}>
            3. All project work will be in accordance with accepted best practices for {proposalData?.proposaltitile}.
          </Text>

          <Text style={styles.paragraph}>
            4. Key requirements for the Software Development and management system project will be established & documented in
            planning stage, and it will be added as an Addendum to the Statement of Work.
          </Text>

          <Text style={{ fontWeight: "900", marginTop: "30px", fontSize: "11px" }}>
            This proposal only covers {proposalData?.proposaltitile}. The Provider will be happy to provide an on-going maintenance and support
            proposal.
          </Text>

        </View>

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      <Page size="A4" style={styles.body}>

        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px"
          }}>5. DELIVERABLES & TESTING</Text>

          <Text style={styles.paragraph}>
            The Provider will deliver the following items during or at the conclusion of the project:
          </Text>

          <Text style={{ fontSize: '10px', marginTop: '10px' }}>
            1. Fully functional {proposalData?.proposaltitile}.
          </Text>
          <Text style={{ fontSize: '10px', marginTop: '5px' }}>
            2. After project conclusion, an Email support service will be provided.
          </Text>

          <Text style={styles.paragraph}>
            The timeline for project milestones and deliverables is listed below:
          </Text>

          {/* table */}
          <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
            <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
              <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                <Text style={styles.tableCell}>Sr. NO</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "60%" }}>
                <Text style={styles.tableCell}>Milestone Description</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                <Text style={styles.tableCell}>Estimated Time (In Weeks)</Text>
              </View>
            </View>
            {
              testingDelivery.map((test, i) => {
                return (
                  <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                    <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                      <Text style={styles.tableCell}>{i + 1}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "60%" }}>
                      <Text style={styles.tableCell}>{test.milestone}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                      <Text style={styles.tableCell}>{test.estimatedtime}</Text>
                    </View>
                  </View>
                )
              })
            }
          </View>


          <Text style={{ ...styles.paragraph, marginTop: "40px", padding: 10, backgroundColor: "#cccccc" }}>
            <Text style={{ fontFamily: 'Noto Sans', fontWeight: 900 }}>Note :</Text>
            The estimated time may vary depending on the requirements of the project.
          </Text>
        </View>
        {/* =============== */}

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      <Page size="A4" style={styles.body}>

        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        {/* main Contant */}

        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px"
          }}>6. TERMS AND AGREEMENT</Text>

          <Text style={styles.paragraph}>
            The terms for the {proposalData?.proposaltitile} project are defined below:
          </Text>

          <Text style={{ ...styles.paragraph, fontSize: '12px', fontFamily: 'Noto Sans', fontWeight: 900 }}>
            Comprehensive {proposalData?.proposaltitile} Project:
          </Text>

          {/* table */}
          <View style={{ ...styles.table, width: "100%", marginTop: "20px", borderWidth: 0 }}>

            {
              termsAgreement.map((term, i) => {
                return (
                  <View style={{ ...styles.tableRow, borderWidth: 0 }} key={i}>
                    <View style={{ ...styles.tableCol, width: "60%", borderWidth: 0 }}>
                      <Text style={styles.tableCell}>{term.service}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "20%", borderWidth: 0, textAlign: 'right' }}>
                      <Text style={styles.tableCell}> — </Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "20%", borderWidth: 0, textAlign: "center" }}>
                      <Text style={styles.tableCell}>{(i == 0) ? term.total : ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + term.total)}</Text>
                    </View>
                  </View>

                )
              })
            }


            {/* <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "70%" }}>
                <Text style={styles.tableCell}>{"Zithas Mars - Payroll Management"}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                <Text style={styles.tableCell}>{"10000"}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "70%" }}>
                <Text style={styles.tableCell}>{"Domain, Hosting, And SSL (Annual Charges)"}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                <Text style={styles.tableCell}>{"20000"}</Text>
              </View>
            </View> */}
          </View>

        </View>

        {/* =============== */}

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      {/* cost breakdown ========================================= */}
      <Page size="A4" style={styles.body}>

        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px"
          }}>PROJECT COST BREAK-DOWN:</Text>


          {/* table */}
          <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
            <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
              <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                <Text style={styles.tableCell}>Sr. NO</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "60%" }}>
                <Text style={styles.tableCell}> Description</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                <Text style={styles.tableCell}>TOTAL COST</Text>
              </View>
            </View>
            {
              costBreakDown.slice(0, 19).map((test, i) => {
                return (
                  <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                    <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                      <Text style={styles.tableCell}>{
                        test.sr_no && test.sr_no
                      }</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                      <Text style={styles.tableCell}>{test.description}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                      <Text style={styles.tableCell}>{
                        test.totalcost &&
                        ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                    </View>
                  </View>
                )
              })
            }
          </View>

        </View>
        {/* =============== */}

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

      {
        costBreakDown.length > 19 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(19, 38).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>

            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 38 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(38, 57).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>

            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 57 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(57, 76).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>

            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 76 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(76, 95).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 95 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(95, 114).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 114 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(114, 133).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 133 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(133, 152).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 152 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(152, 171).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 171 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(171, 190).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }


      {
        costBreakDown.length > 190 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(190, 209).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }

      {
        costBreakDown.length > 209 &&
        <Page size="A4" style={styles.body}>

          <View style={styles.topHeader}>
            <Text>{proposalData?.proposaltitile}</Text>
          </View>

          {/* main Contant */}
          <View style={{ marginTop: "10px" }}>

            {/* table */}
            <View style={{ ...styles.table, width: "100%", marginTop: "20px" }}>
              <View style={{ ...styles.tableRow, backgroundColor: "#d1b9d1", textTransform: "uppercase", color: "#6e276c", fontFamily: 'Noto Sans', fontWeight: 900 }}>
                <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>Sr. NO</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "60%" }}>
                  <Text style={styles.tableCell}> Description</Text>
                </View>
                <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                  <Text style={styles.tableCell}>TOTAL COST</Text>
                </View>
              </View>
              {
                costBreakDown.slice(209).map((test, i) => {
                  return (
                    <View key={i} style={{ ...styles.tableRow, backgroundColor: ((i % 2) != 0 ? "#f3e7f7" : "") }}>
                      <View style={{ ...styles.tableCol, width: "10%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{test.sr_no && test.sr_no}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "60%", color: test.sr_no ? 'black' : '#777' }}>
                        <Text style={styles.tableCell}>{test.description}</Text>
                      </View>
                      <View style={{ ...styles.tableCol, width: "30%", textAlign: "center" }}>
                        <Text style={styles.tableCell}>{
                          test.totalcost &&
                          ((proposalData?.currency == "₹" ? "Rs." : proposalData?.currency) + " " + test.totalcost)}</Text>
                      </View>
                    </View>
                  )
                })
              }
            </View>

          </View>
          {/* =============== */}

          <View style={styles.footer}>
            <View style={styles.bottomLine}>
              <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
              </View>

              <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
              </View>

              <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

              <Text>
                © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
              </Text>

              <Text
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber - 1} of ${totalPages - 1}`
                }
                fixed
              ></Text>
            </View>

          </View>
        </Page>
      }

      {/* cost breakdown ========================================= */}

      <Page size="A4" style={styles.body}>
        <View style={styles.topHeader}>
          <Text>{proposalData?.proposaltitile}</Text>
        </View>

        {/* main Contant */}
        <View style={{ marginTop: "22px" }}>
          <Text style={{
            color: "#7E5F00",
            fontSize: "12px",
            fontWeight: 800,
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            marginBottom: "10px"
          }}>7. CONCLUSION</Text>


          <Text style={styles.paragraph}>
            Again, we are happy to present this proposal for your review. Upon acceptance of this proposal, the Provider will draft a
            comprehensive statement of work for your review that will serve as the project contract between the Provider and the Client
          </Text>
          <Text style={styles.paragraph}>
            If you have any questions or need additional information amended to the proposal, please let us know. You can reach us
            directly by phone at <Text style={{ color: "purple" }}>{proposalData?.conclusion_contact}</Text> or by email at <Text style={{ color: "purple" }}>{proposalData?.conclusion_email}</Text>
          </Text>
        </View>
        {/* =============== */}

        <View style={styles.footer}>
          <View style={styles.bottomLine}>
            <View style={{ height: '100%', width: '35%', backgroundColor: "#850505" }}>
            </View>

            <View style={{ height: '100%', width: '15%', backgroundColor: "#fea200" }}>
            </View>

            <View style={{ height: '100%', width: '50%', backgroundColor: "#7cfe00" }}>
            </View>
          </View>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

            <Text>
              © {new Date().getFullYear()} {loaction == 1 ? "Zithas Technologies Pvt. Ltd." : "Zithas Technologies Ltd."}
            </Text>

            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber - 1} of ${totalPages - 1}`
              }
              fixed
            ></Text>

          </View>

        </View>
      </Page>

    </Document>
  )
}





const ProposalPdf = ({ proposal_id }) => {

  const [viewData, setViewData] = useState(false)
  const [proposalData, setProposalData] = useState([])
  const [loaction, setLocation] = useState('')
  const [loading, setLoading] = useState(false)



  const FetchProposalData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("proposal_id", proposal_id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/view_proposal`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {

        setProposalData(response.data.data[0])

        // console.log("pdf data", response)
        setLocation(response.data.data[0].location)

        setViewData(true)

        setLoading(false)

      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }


  return (
    <>

      {
        loading && <Loader />
      }

      <button
        className="btn btn-sm btn-outline-secondary me-1"
        onClick={() => {
          // fetching proposal data
          FetchProposalData()

        }}
      >
        <i className="fa fa-file-pdf"></i>
      </button>

      <Modal size="xl" centered isOpen={viewData}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Download Proposal</h5>
          <div className="d-flex align-items-center">
            <div>
              {/* <PDFDownloadLink document={<MyProposalDocument />} fileName="Proposal.pdf">
                {({ blob, url, loading, error }) => <a title="download pdf" className="">
                  <i className="fa fa-download text-purple pr-10"></i>
                </a>}
              </PDFDownloadLink> */}

            </div>
            <button
              type="button"
              onClick={() => {
                setViewData(false)
              }}
              className="btn-modal-close"
              style={{ marginTop: "8px" }}
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <PDFViewer width="100%" height="800px">
            <MyProposalDocument loaction={loaction} proposalData={proposalData} />
          </PDFViewer>
        </div>
      </Modal>
    </>
  )
}


export default ProposalPdf
