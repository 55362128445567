import React, { useState } from 'react'
import {
    Container,
    Table,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames"
import TaskMilestone from './TaskMilestone';

const AddTask = () => {

    const [verticalActiveTab, setverticalActiveTab] = useState("1");

    const toggleVertical = (tab) => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    return (
        <>
            <div className='moveUp'>
                <Row className='gx-4 justify-content-between di-flex align-items-top'>
                    <Col md="2" className=''>
                        <Nav pills className={`side-tab shadow-none border bg-light flex-column px-2 py-3 h-100`}>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab === "1",
                                    })}
                                    onClick={() => {
                                        toggleVertical("1");
                                    }}
                                >
                                    Milestone 1
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleVertical("2");
                                    }}
                                >
                                    Milestone 2
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab === "3",
                                    })}
                                    onClick={() => {
                                        toggleVertical("3");
                                    }}
                                >
                                    Milestone 3
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col md="10">
                        <div className=' bg-transparent mb-0'>
                            <TabContent
                                activeTab={verticalActiveTab}
                                className="text-muted mt-4 mt-md-0"
                            >
                                <TabPane tabId="1">
                                    <TaskMilestone />
                                </TabPane>
                                <TabPane tabId="2">
                                    <TaskMilestone />
                                </TabPane>
                                <TabPane tabId="3">
                                    <TaskMilestone />
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AddTask