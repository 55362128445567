import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody, Form, Modal } from "reactstrap"
import cleaningImg from "../../../assets/images/cleaning-service.png"
import cleaningIndustryImg from "../../../assets/images/cleaning-industry.png"
import ITLandingPage from "../../../assets/images/office.png"
import property from "../../../assets/images/property.png"
import propertyDeal from "../../../assets/images/deal.png"
import domicilary from "../../../assets/images/domicilary.png"
import residential from "../../../assets/images/residential-logo.png"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Customer.css"

import Select from "react-select"

// common component
import Pagination from "components/Common/Pagination"

import swal from "sweetalert"
import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import Pagi2 from "components/Common/Pagi2"
import NewPagination from "components/Common/Newpagination"

const statusOptions = [
  { label: "In Progress", value: "0" },
  { label: "Voicemail but not given", value: "1" },
  { label: "Not interested", value: "2" },
  { label: "No received", value: "3" },
  { label: "Some wrong number", value: "4" },
  {
    label: "Gave a specific time to talk( mention the time and date)",
    value: "5",
  },
  { label: "Call connected but some digital person was speaking", value: "6" },
  { label: "Call the head office", value: "7" },
  { label: "Voicemail given", value: "8" },
  { label: "No option to talk with anyone", value: "9" },
  { label: "Call Later", value: "10" },
  { label: "They don't need it", value: "11" },
  { label: "Owner not available", value: "12" },
  { label: "Number not in service", value: "13" },
  { label: "They will call us back", value: "14" },
  { label: "They have it", value: "15" },
  { label: "Others", value: "16" },
]
export function copyToClipBoard({ url, index }) {
  const btn = document.getElementsByClassName("copyBtn")[index]
  const icon = document.getElementsByClassName("copyBtn-i")[index]
  if (!btn || !icon) {
    console.error("Button element not found.")
    return
  }
  window.navigator.clipboard.writeText(url)
  btn.setAttribute("title", "Link Copied!")
  icon.classList.remove("text-purple")
  icon.classList.remove("fa-link")

  icon.classList.add("text-success") // Change button text to "Copied!"
  icon.classList.add("fa-check") // Change button text to "Copied!"

  setTimeout(() => {
    icon.classList.remove("text-success")
    icon.classList.remove("fa-check")
    icon.classList.add("text-purple") // Change button text back to "Link"
    icon.classList.add("fa-link")
    // btn.setAttribute("title", "Click to copy link")
  }, 1000)
  setTimeout(() => {
    btn.setAttribute("title", "Click to copy link")
  }, 3000)
}

function TableContainer({ columns, data, setAllBookingData }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  // Render the UI for your table
  return (
    <div>
      {loading && <Loader />}
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${fixedContent ? "d-flex" : "d-none"
          }`}
      >
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn btn-label"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className="border-radius me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <button
              onClick={() => navigate(-1)}
              className="border-radius btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center lab label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table
          className="table table-bordered mt-4"
          {...getTableProps()}
          id="bookings"
        >
          <thead
            className={`${fixedContent ? "fix-header" : ""} fix-calling-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-column-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-calling-header  " {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-column-${index + 1} ${cell.column.className
                          }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td colSpan={8} className="text-center">
                  {" "}
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
    </div>
  )
}

const Booking = () => {
  const [allBookingData, setAllBookingData] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  //meta title
  document.title = "Bookings | Zithas Crm"

  // get all cold-calling data
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${configure.apiUrl}/bookings`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        setAllBookingData(res.data.data)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [])

  //   const onDelete = calling_id => {
  //     swal({
  //       title: "Are you sure?",
  //       text: "Do you really want to delete this cold Calling",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then(willDelete => {
  //       if (willDelete) {
  //         const formData = new FormData()

  //         formData.append("calling_id", calling_id)

  //         axios
  //           .post(`${configure.apiUrl}/delete_coldcalling`, formData, {
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "multipart/form-data",
  //             },
  //           })
  //           .then(response => {
  //             console.log("de", response.data)

  //             if (response.data.data.length > 0) {
  //               setAllBookingData(response.data.data)
  //             }

  //             swal("Cold Calling deleted successfully!", {
  //               icon: "success",
  //             })
  //           })
  //       }
  //     })
  //   }

  // table columns
  const location = [
    { label: "Website Development", value: "1" },
    { label: "CRM Solutions", value: "2" },
    { label: "Marketing Services", value: "3" },
    { label: "Bespoke System", value: "4" },
    {
      label: "BUSINESS SOFTWARE AUDIT",
      value: "5",
    },
    {
      label: "BUSINESS AUTOMATION",
      value: "6",
    },
    {
      label: "CUSTOM SOFTWARE DEVELOPMENT",
      value: "7",
    },
    {
      label: "WEBSITE DEVELOPMENT",
      value: "8",
    },
    {
      label: "MOBILE APPS DEVELOPMENT",
      value: "9",
    },
    {
      label: "HIRE A RESOURCE",
      value: "10",
    },
    { label: "Custom Healthcare Software Development", value: "11" },
    { label: "Website Development and Management", value: "12" },
    { label: "Mobile Application Development", value: "13" },
    { label: "Digital Marketing Services", value: "14" },
    { label: "Custom cleaning Software Development", value: "15" },
    { label: "Website Development and Management", value: "16" },
    { label: "Digital Marketing Services", value: "17" },
    { label: "Mobile Application Development", value: "18" },
    { label: "Short-Term Engagements", value: "19" },
    { label: "Long-Term Engagements", value: "20" },
    { label: "Fixed-Term Contracts", value: "21" },
    { label: "Project-Based Engagements", value: "22" },
    { label: "Custom Software Development", value: "23" },
    { label: "Website Development and Management", value: "24" },
    { label: "Digital Marketing Services", value: "25" },
    { label: "Mobile Application Development", value: "26" },
    { label: "Custom Domiciliary Software Development", value: "27" },
    { label: "Website Development and Management", value: "28" },
    { label: "Mobile Application Development", value: "29" },
    { label: "Digital Marketing Services", value: "30" },
    { label: "Custom Residential Software Development", value: "31" },
    { label: "Website Development and Management", value: "32" },
    { label: "Mobile Application Development", value: "33" },
    { label: "Digital Marketing Services", value: "34" },
  ]

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: "",
        isInfoIcon: false,
        className: "px-0 text-center",
        Cell: ({ row, rows }) => {
          return <span>{rows.length - row.index}</span>
        },
      },
      {
        Header: "Type",
        accessor: "type",
        isInfoIcon: true,
        className: "px-0 text-center",
        Cell: cellProps => {
          return (
            <span>
              {cellProps.row.original.type == 0 && (
                <>
                  <i className={`bi-prescription2`}></i>&nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {cellProps.row.original.type == 1 && (
                <i
                  className={`${"bi bi-prescription2"}`}
                  title="Healthcare"
                ></i>
              )}
              {cellProps.row.original.type == 2 && (
                <i className={`${"bi bi-globe"}`} title="Booking From site"></i>
              )}

              {cellProps.row.original.type == 3 && (
                <img
                  src={cleaningImg}
                  alt="Cleaning services"
                  className="img-fluid"
                  title="cleaning services"
                />
              )}
              {cellProps.row.original.type == 4 && (
                <>
                  <img
                    src={cleaningImg}
                    alt="Cleaning services"
                    className="img-fluid"
                    title="Cleaning services"
                  />
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {cellProps.row.original.type == 5 && (
                <i
                  className={`${"bi bi-prescription"}`}
                  title="Private Healthcare"
                ></i>
              )}
              {cellProps.row.original.type == 6 && (
                <>
                  {/* This is from cleaning Industry callback */}
                  <i
                    className={`bi bi-prescription`}
                    title="Private Healthcare"
                  ></i>
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {cellProps.row.original.type == 7 && (
                <img
                  src={cleaningIndustryImg}
                  alt="Cleaning Industry"
                  className="img-fluid"
                  title="Cleaning Industry"
                />
              )}
              {cellProps.row.original.type == 8 && (
                <>
                  {/* This is from cleaning services callback */}
                  <img
                    src={cleaningIndustryImg}
                    alt="Cleaning Industry"
                    className="img-fluid"
                    title="Cleaning Industry"
                  />
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {cellProps.row.original.type == 9 && (
                <>
                  {/* This is from cleaning services callback */}
                  <img
                    src={ITLandingPage}
                    alt="Dedicated Developers - UK Landing Page"
                    className="img-fluid w-25"
                    title="Dedicated Developers"
                  />
                  {/* <i className={`bi bi-telephone-inbound`}></i>&nbsp; */}
                </>
              )}
              {cellProps.row.original.type == 10 && (
                <>
                  {/* This is from cleaning services callback */}
                  <img
                    src={ITLandingPage}
                    alt="Dedicated Developers - UK Landing Page"
                    className="img-fluid w-25"
                    title="Dedicated Developers"
                  />
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {/* propety-consultant 11 or propety-consultant callback 12  */}
              {/* propety-consultant-services 13 or propety-consultant-services callback 14  */}
              {cellProps.row.original.type == 11 && (
                <>
                  <img
                    src={property}
                    alt="property-consultant"
                    className="img-fluid"
                    title="property-consultant"
                  />
                </>
              )}
              {cellProps.row.original.type == 12 && (
                <>
                  <img
                    src={property}
                    alt="property-consultant"
                    className="img-fluid"
                    title="property-consultant"
                  />
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {cellProps.row.original.type == 13 && (
                <>
                  <img
                    src={propertyDeal}
                    alt="property-consultant-services"
                    className="img-fluid"
                    title="property-consultant-services"
                  />
                </>
              )}
              {cellProps.row.original.type == 14 && (
                <>
                  <img
                    src={propertyDeal}
                    alt="property-consultant-services"
                    className="img-fluid"
                    title="property-consultant-services"
                  />
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {/* domicilary-care 15/16 */}
              {cellProps.row.original.type == 15 && (
                <>
                  <img
                    src={domicilary}
                    alt="domicilary-care"
                    className="img-fluid"
                    title="domicilary-care"
                  />
                </>
              )}
              {cellProps.row.original.type == 16 && (
                <>
                  <img
                    src={domicilary}
                    alt="domicilary-care"
                    className="img-fluid"
                    title="domicilary-care"
                  />
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {cellProps.row.original.type == 17 && (
                <>
                  <img
                    src={residential}
                    alt="residential-care"
                    className="img-fluid"
                    title="residential-care"
                  />
                </>
              )}
              {cellProps.row.original.type == 18 && (
                <>
                  <img
                    src={residential}
                    alt="residential-care"
                    className="img-fluid"
                    title="residential-care"
                  />
                  &nbsp;
                  <i className={`bi bi-telephone-inbound`}></i>&nbsp;
                </>
              )}
              {(cellProps.row.original.type == "Other" ||
                cellProps.row.original.type == "Lead" ||
                cellProps.row.original.type == "Introducer" ||
                cellProps.row.original.type == "Customer") && (
                  <i className="bi bi-calendar2-week" title="Calender"></i>
                )}
            </span>
          )
        },
      },
      {
        Header: "Name",
        accessor: "name",
        isInfoIcon: true,
        className: "",
        Cell: cellProps => {
          let parts = cellProps.row.original.name.split(",")
          let uniqueParts = parts.filter(
            (part, index) => part !== "-" && parts.indexOf(part) === index
          )
          let result = uniqueParts.join(",")

          return <span>{result}</span>
        },
      },
      {
        Header: "Email",
        accessor: "email",
        isInfoIcon: true,
        className: "",
        Cell: cellProps => {
          return (
            <span
              title={cellProps.row.original.email}
              className="cursor-pointer"
            >
              {cellProps.row.original.email.slice(0, 17)}
              {" ..."}
            </span>
          )
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        isInfoIcon: true,
        className: "px-0 text-center t-column-4",
      },
      {
        Header: "Services",
        accessor: "services",
        className: "t-column-5",
        isInfoIcon: true,
        Cell: cellProps => {
          const { original } = cellProps.row
          const selectedLocation = location.find(
            item => item.value === original.services
          )
          return (
            <div>{selectedLocation ? selectedLocation.label : "Not found"}</div>
          )
        },
      },
      {
        Header: "Date",
        accessor: "booking_date",
        isInfoIcon: true,
        className: "t-column-6 px-0 text-center",
        Cell: cellProps => {
          return (
            <div className="">
              {cellProps.row.original.booking_date !== null
                ? moment(cellProps.row.original.booking_date).format(
                  "DD-MM-YYYY"
                )
                : ""}
              { }
            </div>
          )
        },
      },
      {
        Header: "Time Slot",
        accessor: "time_slot",
        className: "px-0 text-center",
        isInfoIcon: true,
      },
      {
        Header: "Time Zone",
        accessor: "timezone",
        className: "px-0 text-center",
        isInfoIcon: true,
      },
      // {
      //   Header: "Info",
      //   accessor: "info",
      //   className: "text-center",
      //   isInfoIcon: false,
      //   Cell: cellProps => {
      //     return (
      //       <i
      //         title={`Book at ${moment(
      //           cellProps.row.original.created_at
      //         ).format("DD-MM-YYYY")} | ${moment(
      //           cellProps.row.original.created_at
      //         ).format("hh:mm")}`}
      //         className="bx bx-info-circle ms-1 info-icon"
      //       ></i>
      //     )
      //   },
      // },
      // {
      //   Header: "Book at",
      //   accessor: "created_at",
      //   className: "",
      //   isInfoIcon: true,
      //   Cell: cellProps => {
      //     return (
      //       <div className="">
      //         {moment(cellProps.row.original.created_at).format("DD-MM-YYYY")}
      //         &nbsp;|&nbsp;
      //         {moment(cellProps.row.original.created_at).format("hh:mm")}
      //       </div>
      //     )
      //   },
      // },
      {
        Header: "Action",
        accessor: "join_url",
        className: "px-0 text-center",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              <a
                title={`Book at ${moment(
                  cellProps.row.original.created_at
                ).format("DD-MM-YYYY")} | ${moment(
                  cellProps.row.original.created_at
                ).format("hh:mm a")}`}
                className="btn btn-sm btn-outline-secondary me-1 rounded-0"
              >
                <i className="fas fa-solid fa-info"></i>
              </a>
              {/* <button className="btn btn-outline-secondary">
                <i
                  title={`Book at ${moment(
                    cellProps.row.original.created_at
                  ).format("DD-MM-YYYY")} | ${moment(
                    cellProps.row.original.created_at
                  ).format("hh:mm")}`}
                  className="fas fa-solid fa-info"
                ></i>
              </button> */}
              <a
                onClick={() =>
                  copyToClipBoard({
                    index: cellProps.row.index,
                    url: cellProps.row.original.join_url,
                  })
                }
                value={cellProps.row.original.join_url}
                title="Click to copy link"
                // btn btn-sm btn-outline-secondary me-1
                className={`copyBtn btn btn-sm btn-outline-secondary text-purple  py-1   text-lowercase ${cellProps.row.original.join_url == null ? "d-none" : ""
                  }`}
              >
                <i
                  className="fas fa-solid fa-link text-purple copyBtn-i fs-6"
                // style={{ color: "#5c0385" }}
                ></i>
              </a>
            </div>
          )
        },
      },
      //   {
      //     Header: "Action",
      //     accessor: "action",
      //     Cell: cellProps => {
      //       return (
      //         <div>
      //           <button
      //             title="View"
      //             className="btn btn-sm me-1 btn-outline-secondary"
      //             onClick={() => {
      //               navigate(`${configure.appUrl}marketing/view-coldcalling`, {
      //                 state: {
      //                   data: {
      //                     id: cellProps.row.original.id,
      //                     country: cellProps.row.original.country,
      //                     state: cellProps.row.original.state,
      //                     city: cellProps.row.original.city,
      //                     status: cellProps.row.original.status,
      //                     nocontact: cellProps.row.original.nocontact,
      //                     industry: cellProps.row.original.industry,
      //                   },
      //                 },
      //               })
      //             }}
      //           >
      //             <i className="fas fa-eye"></i>
      //           </button>
      //           <button
      //             onClick={() => onDelete(cellProps.row.original.id)}
      //             title="delete"
      //             className="btn btn-sm  btn-danger"
      //           >
      //             <i className="fas fa-trash-alt"></i>
      //           </button>
      //         </div>
      //       )
      //     },
      //   },
    ],
    []
  )

  const data = useMemo(() => allBookingData.reverse(), [allBookingData])
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Bookings"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAllBookingData={setAllBookingData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Booking
