import React, { useMemo, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Input,
    Modal,
    Form
} from "reactstrap";
import { Link } from 'react-router-dom';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';

// css
import '../../../assets/css/Settings/Setting.css';

// commom 
import Pagination from 'components/Common/Pagination';

function TableContainer({ columns, data }) {

    const [modal_center, setmodal_center] = useState(false);
    const [fixedContent, setFixedContent] = useState(false);

    const navigate = useNavigate()

    document.addEventListener('scroll', () => {
        if (window.scrollY > 165) {
            setFixedContent(true);
        }
        else {
            setFixedContent(false);
        }
    })

    // Center Modal
    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        pageCount,
        prepareRow
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, pageSize, globalFilter } = state;
    
    // Render the UI for your table
    return (
        <div >
            <div className={`flex-column fixed-buttons ${fixedContent ? 'd-flex' : 'd-none'}`}>
                <button onClick={() => {
                    tog_center()
                }} className='btn btn-purple border-radius fix-btn'>
                    <i className="fas fa-plus me-2 icon-size"></i>
                    <span>Document</span>
                </button>
                <button onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                    <i className='dripicons-cross me-2 icon-center'></i>
                    <span>Cancel</span>
                </button>
            </div>
            <Row className='mb-2'>
                <Col>
                    <select
                        className="border-radius form-select"
                        style={{ width: '100px' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(e.target.value)}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col md={8} className='d-flex justify-content-end'>
                    <div className='me-4'>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="border-radius form-control rounded h-100"
                                        placeholder={` Search...`}
                                        value={globalFilter || ''}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>

                    </div>
                    <div className=''>
                        <button onClick={() => {
                            tog_center()
                        }} className='border-radius btn btn-purple'> <i className="fas fa-plus me-2 icon-size"></i>Bidding Document</button>
                        <button onClick={() => navigate(-1)} className="border-radius btn ms-md-2 btn-red"><i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                    </div>
                </Col>
            </Row>
            <Table className='table table-bordered mt-4' {...getTableProps()}>
                <thead className={`${fixedContent ? 'fixed-setting-header' : ''}`}>
                    {
                        headerGroups.map((headerGroup, i) => (
                            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map((column, index) => (
                                        <th key={index} className={`col-md-${(index === 1 || index === 2) ? '4' : '2'}`} {...column.getHeaderProps()}>{column.render("Header")}
                                            {
                                                column.isInfoIcon ?
                                                    <i title='info' className='bx bx-info-circle ms-1 info-icon'></i> : null}
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>


                <tbody {...getTableBodyProps()}>
                    {
                        page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr className='row-hover' {...row.getRowProps()} key={i}>
                                    {
                                        row.cells.map((cell, index) => {
                                            return <td className={`col-md-${(index === 1 || index === 2) ? '4' : '2'}`} key={index} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Pagination
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                />
            </Row>

            {/* Add Department Modal */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Bidding Document</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_center(false);
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Form>
                        <Row>
                            <div className='col-3'>
                                <label htmlFor="">Number </label>
                                <input type="number" placeholder='Enter No.' className='form-control border-radius' />
                            </div>
                            <div className='col-9'>
                                <label htmlFor="">Attachment </label>
                                <input type="file" className='form-control border-radius' />
                            </div>
                            <div className='col-12 mt-3'>
                                <label htmlFor="">Name </label>
                                <input type="text" placeholder='Enter Name' className='form-control border-radius' />
                            </div>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius'>Submit</button>
                        </div>
                    </Form>

                </div>
            </Modal>
            {/* ------------ */}
        </div>
    );
}

const BiddingDocument = () => {

    const [modal_center, setmodal_center] = useState(false);

    // Center Modal
    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "Name",
                accessor: "name",
                isInfoIcon: true
            },
            {
                Header: "Attachment",
                accessor: "attachment",
                isInfoIcon: true
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: cellProps => {
                    return <div>
                        <button onClick={() => tog_center()} className='btn action-btn btn-sm me-1 btn-outline-secondary'>
                            <i className="fas fa-pencil-alt"></i>
                        </button>
                        <Link to="#" className='btn btn-sm action-btn btn-danger'>
                            <i className="fas fa-trash-alt"></i>
                        </Link>
                    </div>
                },
            }
        ],
        []
    );

    const data = [
        {
            id: 1,
            name: 'Portfolio',
            attachment: "pdf"
        },
        {
            id: 2,
            name: 'Portfolio',
            attachment: "pdf"
        },
        {
            id: 3,
            name: 'Portfolio',
            attachment: "pdf"
        }
    ]

    return (
        <>
            <div>
                <Row className='ms-0 pe-0'>
                    <Col lg={12} className='p-0'>
                        <Card>
                            <CardBody>
                                <TableContainer columns={columns} data={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* Edit Department Modal */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Category</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_center(false);
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Form>
                        <Row>
                            <div className='col-3'>
                                <label htmlFor="">Number </label>
                                <input type="number" placeholder='Enter No.' className='form-control border-radius' />
                            </div>
                            <div className='col-9'>
                                <label htmlFor="">Attachment </label>
                                <input type="file" className='form-control border-radius' />
                            </div>
                            <div className='col-12 mt-3'>
                                <label htmlFor="">Name </label>
                                <input type="text" placeholder='Enter Name' className='form-control border-radius' />
                            </div>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius'>Submit</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            {/* ------------ */}
        </>
    )
}

export default BiddingDocument