import React, { useEffect, useState } from "react"
import { Table, Row, Col, Input, Modal, Form, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Select from "react-select"
import ReactSelect from "react-select"

import editCategory from "../../../assets/images/Category/edit-category.jpg"
// import editCategory from "../../../assets/images/credential/edit-img.png"
import addCategory from "../../../assets/images/Category/add-category.jpg"
// common
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import axios from "axios"
import secureLocalStorage from "react-secure-storage"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import "../../../assets/css/KnowledgeBase/Index.css"

function TableContainer({
  columns,
  setLoading,
  edit_status,
  setEditStatus,
  editCateid,
  setEditCateid,
  setData,
  data,
  modal_Access,
  setAccessDenied,
}) {
  const [status, setStatus] = useState(false)

  const [fixedContent, setFixedContent] = useState(false)
  // add category
  const [allStaff, setAllStaff] = useState([])
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState([])
  const [allUser, setAllUser] = useState([])
  const [selectedUser, setSelectedUser] = useState([])
  const [numberCate, setNumberCate] = useState("")
  const [cateName, setCateName] = useState("")
  const [cateNameEdit, setCateNameEdit] = useState("")
  const [numberCateEdit, setNumberCateEdit] = useState("")
  const [selectedRoleEdit, setSelectedRoleEdit] = useState([])
  const [selectedUserEdit, setSelectedUserEdit] = useState([])

  useEffect(() => {
    const editData = data?.filter(item => {
      return item.id == editCateid
    })
    const roleAccess = editData[0]
    if (roleAccess) {
      setNumberCateEdit(roleAccess?.number)
      setCateNameEdit(roleAccess?.category)
      const roleAccessIds = roleAccess?.role_access
        ?.split(",")
        .map(id => parseInt(id.trim(), 10))
      const roleData = roles?.filter(item => {
        return roleAccessIds?.includes(item.value)
      })
      setSelectedRoleEdit(roleData)
      const userAccessIds = roleAccess?.user_access
        ?.split(",")
        ?.map(id => parseInt(id.trim(), 10))
      const userData = allUser?.filter(item => {
        return userAccessIds?.includes(item.value)
      })
      setSelectedUserEdit(userData)
    }
    // editData[0]?.role_access
  }, [edit_status])

  // const handleSetEditRoles2 = option => {
  //   setSelectedRoleEdit(option)
  //   const roleNames = allStaff?.map(employee => employee.role_name)
  //   const filteredOptions = option?.filter(item =>
  //     roleNames?.includes(item.label)
  //   )
  //   const selectedEmployees = allStaff?.filter(employ => {
  //     return filteredOptions?.some(opt => opt.label === employ.role_name)
  //   })
  //   const optionUser = selectedEmployees?.map(items => ({
  //     value: items.id,
  //     label: items.name,
  //   }))
  //   setSelectedUserEdit(optionUser)
  // }
  const handleSetEditRoles = option => {
    setSelectedRoleEdit(option)
    const selectedRoleLabels = option.map(opt => opt.label)
    const previousRoleLabels = selectedRoleEdit.map(opt => opt.label)
    const addedRoles = selectedRoleLabels.filter(
      role => !previousRoleLabels.includes(role)
    )
    const removedRoles = previousRoleLabels.filter(
      role => !selectedRoleLabels.includes(role)
    )
    const usersForAddedRoles = allStaff
      ?.filter(employee => addedRoles?.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
    const usersForRemovedRoles = allStaff
      ?.filter(employee => removedRoles?.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
    setSelectedUserEdit(prevOption => {
      const updatedStaff = prevOption.filter(
        option =>
          !usersForRemovedRoles.some(user => user.value === option.value)
      )
      return [...updatedStaff, ...usersForAddedRoles]
    })
  }

  const handleChangeEditUser = selectedOption => {
    setSelectedUserEdit(selectedOption)
  }

  const fetchAllStaff = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setAllStaff(response.data.data)
        const optionUser = response?.data?.data?.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setAllUser(optionUser)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchAllStaff()
  }, [])

  const fatchRoles = async () => {
    setLoading(true)
    try {
      const bearerToken = secureLocalStorage.getItem("auth_token")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staffData`,
        headers: {
          // Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const optionRole = response.data.data.roles.map(item => ({
          value: item.id,
          label: item.role_name,
        }))
        setRoles(optionRole)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fatchRoles()
  }, [])

  // const handleSetRoles2 = option => {
  //   setSelectedRole(option)
  //   const roleNames = allStaff?.map(employee => employee.role_name)
  //   const filteredOptions = option?.filter(option =>
  //     roleNames.includes(option.label)
  //   )
  //   const selectedEmployees = allStaff.filter(employee => {
  //     return filteredOptions.some(option => option.label === employee.role_name)
  //   })
  //   const optionUser = selectedEmployees?.map(item => ({
  //     value: item.id,
  //     label: item.name,
  //   }))
  //   setSelectedUser(optionUser)
  // }
  const handleSetRoles = option => {
    setSelectedRole(option)
    const selectedRoleLabels = option.map(opt => opt.label)
    const previousRoleLabels = selectedRole.map(opt => opt.label)
    const addedRoles = selectedRoleLabels.filter(
      role => !previousRoleLabels.includes(role)
    )
    const removedRoles = previousRoleLabels.filter(
      role => !selectedRoleLabels.includes(role)
    )
    const usersForAddedRoles = allStaff
      .filter(employee => addedRoles.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
    const usersForRemovedRoles = allStaff
      .filter(employee => removedRoles.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
    setSelectedUser(prevOption => {
      const updatedStaff = prevOption.filter(
        option =>
          !usersForRemovedRoles.some(user => user.value === option.value)
      )
      return [...updatedStaff, ...usersForAddedRoles]
    })
  }

  const handleChangeUser = selectedOption => {
    setSelectedUser(selectedOption)
  }

  const handleSubmitAddCate = async e => {
    e.preventDefault()
    if (selectedUser.length !== 0) {
      setLoading(true)
      const formData = new FormData()
      formData.append("category_number", numberCate)
      formData.append("name", cateName)
      formData.append("parent", 0)
      formData.append(
        "roll_name",
        selectedRole?.map(item => item.value).toString()
      )
      formData.append(
        "user_name",
        selectedUser?.map(item => item.value).toString()
      )

      try {
        const response = await axios.post(
          `${configure.apiUrl}/addKnowledgeCategory`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response.data.success) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Category Added successfully.",
          }).then(() => {
            setNumberCate("")
            setCateName("")
            setSelectedUser([])
            setSelectedRole([])
            setData(response?.data?.data.category)
            setStatus(false)
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${response.data.data}`,
          })
        }
      } catch (error) {
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select User.",
      })
    }
  }

  const handleSubmitEditCate = async e => {
    e.preventDefault()
    if (selectedRoleEdit.length !== 0 && selectedUserEdit.length !== 0) {
      setLoading(true)
      const formData = new FormData()
      formData.append("category_number", numberCateEdit)
      formData.append("name", cateNameEdit)
      formData.append("category_id", editCateid)
      formData.append("parent", 0)
      formData.append(
        "roll_name",
        selectedRoleEdit?.map(item => item.value).toString()
      )
      formData.append(
        "user_name",
        selectedUserEdit?.map(item => item.value).toString()
      )

      try {
        const response = await axios.post(
          `${configure.apiUrl}/editKnowledgeCategory`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Category Edited successfully.",
          }).then(() => {
            setNumberCateEdit("")
            setCateNameEdit("")
            setSelectedUserEdit([])
            setSelectedRoleEdit([])
            setData(response?.data?.data.category)
            setEditStatus(false)
          })
        }
      } catch (error) {
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "please select all fields",
      })
    }
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 180) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const fatchData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/knowledgeCategory`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log("response?.data?.data.category", response)
        setData(response?.data?.data.category)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    fatchData()
  }, [])

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  const navigate = useNavigate()

  const Users = [
    { label: "Rahul", value: "1" },
    { label: "Divyansh", value: "2" },
    { label: "Zain", value: "3" },
    { label: "Manav", value: "4" },
    { label: "Kunj", value: "5" },
    { label: "Vaibhav", value: "6" },
    { label: "Yasir", value: "7" },
  ]
  const Rolls = [
    { label: "Admin", value: "8" },
    { label: "Managing Director", value: "9" },
    { label: "Key Account Manage", value: "10" },
    { label: "Software Engineer", value: "11" },
    { label: "Marketing Executing", value: "12" },
    { label: "Testing Roll", value: "13" },
  ]
  // Render the UI for your table

  const onAddTopic = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 36)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          setStatus(true)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div className="px-2">
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => {
            onAddTopic()
          }}
          className="btn btn-purple  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 label-icon"></i>
          <span>Add</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </a>
      </div>

      <Row className="mb-2">
        <Col lg={2}>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className=" btn btn-save btn-label"
              onClick={() => {
                onAddTopic()
              }}
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
              Category
            </a>
            <a
              className=" btn text-white ms-md-2 btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              Cancel
            </a>
          </div>
        </Col>
      </Row>
      <Table
        className="table table-bordered mt-4 Knowledge-header"
        {...getTableProps()}
      >
        <thead className={`${fixedContent ? "fixed-setting-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`${column.className} col-md-${
                    index === 0 ? "1" : index === 1 ? "9" : "2"
                  }`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={`${cell.column.className} col-md-${
                        index === 0 ? "1" : index === 1 ? "9" : "2"
                      }`}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Add Category Modal  */}
      <Modal isOpen={status} size="xl" className="" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Category</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
              setNumberCate("")
              setCateName("")
              setSelectedRole([])
              setSelectedUser([])
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            className="row d-flex flex-row justify-space-between align-items-center py-3"
            id="editCategory-box"
            onSubmit={handleSubmitAddCate}
          >
            <div className="col-md-6 edit-category-img text-center">
              <img src={addCategory} alt="Add Image" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="mb-4 col-md-3">
                <Label htmlFor="formrow-firstname-Input">
                  Sequence <span className="text-danger">*</span>
                </Label>
                <Input
                  type="number"
                  value={numberCate}
                  onChange={e => setNumberCate(e.target.value)}
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter No. "
                  required
                />
              </div>
              <div className="mb-4 col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  {" "}
                  Category Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  value={cateName}
                  onChange={e => setCateName(e.target.value)}
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Category Name"
                  required
                />
              </div>
              <div className="row">
                <div className=" col-md-12 mb-4">
                  <Label htmlFor="formrow-firstname-Input">Role Name </Label>
                  {/* <Select
                    placeholder="Select roll"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Rolls}
                  /> */}
                  <ReactSelect
                    placeholder="Select Role"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    value={selectedRole}
                    options={roles}
                    isMulti="true"
                    onChange={option => handleSetRoles(option)}
                  />
                </div>
                <div className=" col-md-12">
                  <Label htmlFor="formrow-firstname-Input">
                    User Name <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select User"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    options={allUser}
                    value={selectedUser}
                    isMulti="true"
                    onChange={selectedOption =>
                      handleChangeUser(selectedOption)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-20 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Edit Category Modal  */}
      <Modal isOpen={edit_status} size="xl" className="" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Category</h5>
          <button
            type="button"
            onClick={() => {
              setEditStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmitEditCate}
            className="row d-flex flex-row justify-space-between align-items-center py-3"
            id="editCategory-box"
          >
            <div className="col-md-6 edit-category-img text-center">
              <img src={editCategory} alt="edit Image" />
            </div>

            <div className="col-md-6 align-self-center">
              <div className="mb-4 col-md-3">
                <Label htmlFor="formrow-firstname-Input">
                  Sequence <span className="text-danger">*</span>
                </Label>
                <Input
                  required
                  type="number"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter No. "
                  value={numberCateEdit}
                  onChange={e => setNumberCateEdit(e.target.value)}
                />
              </div>
              <div className="mb-4 col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  Category Name <span className="text-danger">*</span>
                </Label>
                <Input
                  required
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Category Name"
                  defaultValue="React Js"
                  value={cateNameEdit}
                  onChange={e => setCateNameEdit(e.target.value)}
                />
              </div>
              <div className="row">
                <div className=" col-md-12 mb-4">
                  <Label htmlFor="formrow-firstname-Input">Roll Name </Label>
                  <ReactSelect
                    placeholder="Select Roll"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    options={roles}
                    isMulti="true"
                    value={selectedRoleEdit}
                    onChange={option => handleSetEditRoles(option)}
                  />
                </div>
                <div className=" col-md-12">
                  <Label htmlFor="formrow-firstname-Input">
                    User Name <span className="text-danger">*</span>
                  </Label>
                  {/* <Select
                      placeholder="Select user"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      options={Users}
                    /> */}
                  <ReactSelect
                    placeholder="Select User"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    options={allUser}
                    isMulti="true"
                    value={selectedUserEdit}
                    onChange={selectedOption =>
                      handleChangeEditUser(selectedOption)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-20 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const KnowledgeCategory = () => {
  const [edit_status, setEditStatus] = useState(false)
  const [editCateid, setEditCateid] = useState("")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "Sequence",
        accessor: "number",
        className: "text-center px-0",
      },
      {
        Header: "Category Name",
        accessor: "category",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        Cell: cellProps => {
          const deleteCate = async () => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 36)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  Swal.fire({
                    icon: "warning",
                    title: "Are You Sure ?",
                    text: "You want to delete this category.",
                    showCancelButton: true,
                  }).then(async willDelete => {
                    if (willDelete.isConfirmed) {
                      try {
                        const response = await axios({
                          method: "get",
                          url: `${configure.apiUrl}/deleteKnowledgeCategory/${cellProps.row.original.id}`,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        })
                        if (response) {
                          Swal.fire({
                            icon: "success",
                            text: "Category deleted successfully.",
                          }).then(() => {
                            setData(response?.data?.data.category)
                            console.log("Del", response?.data?.data.category)
                          })
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onEditPermission = async ({ id }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 36)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  setEditStatus(true)
                  setEditCateid(id)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          return (
            <div style={{ textAlign: "center" }}>
              <a
                onClick={() => {
                  onEditPermission({ id: cellProps.row.original.id })
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a
                to="#"
                className="btn btn-sm  btn-outline-danger"
                onClick={() => deleteCate()}
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const Users = [
    { label: "Rahul", value: "1" },
    { label: "Divyansh", value: "2" },
    { label: "Zain", value: "3" },
    { label: "Manav", value: "4" },
    { label: "Kunj", value: "5" },
    { label: "Vaibhav", value: "6" },
    { label: "Yasir", value: "7" },
  ]

  const Rolls = [
    { label: "Admin", value: "8" },
    { label: "Managing Director", value: "9" },
    { label: "Key Account Manage", value: "10" },
    { label: "Software Engineer", value: "11" },
    { label: "Marketing Executing", value: "12" },
    { label: "Testing Roll", value: "13" },
  ]

  return (
    <div className="h-100">
      {loading && <Loader />}
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table">
            <TableContainer
              columns={columns}
              setLoading={setLoading}
              edit_status={edit_status}
              setEditStatus={setEditStatus}
              editCateid={editCateid}
              setEditCateid={setEditCateid}
              setData={setData}
              data={data}
              modal_Access={modal_Access}
              setAccessDenied={setAccessDenied}
            />
          </div>
        </Col>
      </Row>
      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </div>
  )
}

export default KnowledgeCategory
