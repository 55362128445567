import React from "react"
import { Modal } from "reactstrap"
import AccessDenied from "../../src/assets/images/dribbble_1.gif"

const AccessDeniedPopup = ({ modal_Access, setAccessDenied }) => {
  return (
    <>
      <Modal size="xl" isOpen={modal_Access} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <h5 className="col-6 modal-title mt-0">Access Denied</h5>
          </div>
          <button
            type="button"
            onClick={() => {
              setAccessDenied(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <div className="text-center">
            <img src={AccessDenied} className="" height="500" />
            <div style={{ marginTop: "-100px" }}>
              <h1>Access Denied ...</h1>
              <p>You dont have Permisions to access this page.</p>
              <div>
                {" "}
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => setAccessDenied(false)}
                >
                  I Understood
                  <i className="dripicons-thumbs-up pl-10"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AccessDeniedPopup
