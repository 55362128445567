import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import swal from "sweetalert"

const Profile = props => {
  const [fixButtons, setFixButtons] = useState(false)
  const [loading, setLoading] = useState(false)

  const [company, setCompany] = useState("")
  const [organization_number, setorganization_number] = useState("")
  const [county, setcounty] = useState("")
  const [state, setstate] = useState("")
  const [city, setcity] = useState("")
  const [zip, setzip] = useState("")
  const [address, setaddress] = useState("")
  const [phonenumber, setphonenumber] = useState("")
  const [email, setEmail] = useState("")
  const [incorporation_date, setincorporation_date] = useState("")
  const [website, setwebsite] = useState("")
  const [credit_score, setcredit_score] = useState("")
  const [common_score, setcommon_score] = useState("")
  const [credit_limit, setcredit_limit] = useState("")
  const [industry_code, setindustry_code] = useState("")
  const [no_of_employees, setno_of_employees] = useState("")
  const [turnover, setturnover] = useState("")
  const [profit, setprofit] = useState("")
  const [net_worth, setnet_worth] = useState("")

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  useEffect(() => {
    getCompanyData()
  }, [])

  const getCompanyData = async () => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("company_id", props.company_id)

      const res = await axios.post(
        `${configure.apiUrl}/opportunity_company`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setCompany(res?.data?.data[0]?.company)
      setorganization_number(res?.data?.data[0]?.organization_number)
      setcounty(res?.data?.data[0]?.country)
      setstate(res?.data?.data[0]?.state)
      setcity(res?.data?.data[0]?.city)
      setzip(res?.data?.data[0]?.zip)
      setaddress(res?.data?.data[0]?.address)
      setphonenumber(res?.data?.data[0]?.phonenumber)
      setEmail(res?.data?.data[0]?.email)
      setincorporation_date(res?.data?.data[0]?.incorporation_date)
      setwebsite(res?.data?.data[0]?.website)
      setcredit_score(res?.data?.data[0]?.credit_score)
      setcommon_score(res?.data?.data[0]?.common_score)
      setcredit_limit(res?.data?.data[0]?.credit_limit)
      setindustry_code(res?.data?.data[0]?.industry_code)
      setno_of_employees(res?.data?.data[0]?.no_of_employees)
      setturnover(res?.data?.data[0]?.turnover)
      setprofit(res?.data?.data[0]?.profit)
      setnet_worth(res?.data?.data[0]?.net_worth)

      // console.log("edit", res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const saveOpportunityData = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("company_id", props.company_id)
      formData.append("company", company)
      formData.append("organization_number", organization_number)
      formData.append("country", county)
      formData.append("state", state)
      formData.append("city", city)
      formData.append("zip", zip)
      formData.append("address", address)
      formData.append("phonenumber", phonenumber)
      formData.append("email", email)
      formData.append("incorporation_date", incorporation_date)
      formData.append("website", website)
      formData.append("credit_score", credit_score)
      formData.append("common_score", common_score)
      formData.append("credit_limit", credit_limit)
      formData.append("industry_code", industry_code)
      formData.append("no_of_employees", no_of_employees)
      formData.append("turnover", turnover)
      formData.append("profit", profit)
      formData.append("net_worth", net_worth)

      const res = await axios.post(
        `${configure.apiUrl}/edit_opportunity`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // console.log("save", res)
      if (res.data?.success == true && res.data?.message == "success") {
        swal("", "Profile Edited Successfully", "success")
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "error")
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
        <Form onSubmit={saveOpportunityData}>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-purple  fix-btn">
              <i className="fas fa-save me-2"></i>
              <span>Save</span>
            </button>
            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>

          <Row className="bg-white px-2 py-4 shadow mb-3">
            <div className="col-md-12 mb-3 text-end">
              <button type="submit" className=" btn ms-md-2 btn-save btn-label">
                <i className="fas fa-save me-1 label-icon"></i> save
              </button>
              <button
                type="button"
                onClick={e => {
                  navigate(-1)
                }}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
            <Row className="pe-0">
              <div className="col-md-3 mb-md-0 mb-3">
                <label htmlFor="">
                  Company <span className="text-danger">*</span>{" "}
                </label>
                <input
                  required
                  type="text"
                  placeholder="Enter Company"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                  className="form-control "
                />
              </div>
              <div className="col-md-3 mb-md-0 mb-3">
                <label htmlFor="">
                  Organization Number
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  type="text"
                  placeholder="Enter Org Number"
                  value={organization_number}
                  onChange={e => setorganization_number(e.target.value)}
                  className="form-control "
                />
              </div>
            </Row>
          </Row>

          {/* Address */}
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <h5 className="mb-3">Address</h5>

            <div className="col-md-4 mb-3">
              <label htmlFor="">Country</label>
              <input
                type="text"
                placeholder="Enter Country"
                value={county}
                onChange={e => setcounty(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">State</label>
              <input
                type="text"
                placeholder="Enter State"
                value={state}
                onChange={e => setstate(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">City </label>
              <input
                type="text"
                placeholder="Enter City"
                value={city}
                onChange={e => setcity(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Zip Code</label>
              <input
                type="text"
                placeholder="Enter Zip Code"
                value={zip}
                onChange={e => setzip(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-8 mb-3">
              <label htmlFor="">Address</label>
              <input
                type="text"
                placeholder="Enter Address"
                value={address}
                onChange={e => setaddress(e.target.value)}
                className="form-control "
              />
            </div>
            {/* <div className="col-12 mb-md-0 mt-2 mb-3">
                            <label htmlFor="">Description </label>
                            <CKEditor
                                editor={ClassicEditor}
                                data="<p></p>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                }}
                            />
                        </div> */}
          </Row>

          {/* Company info */}
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <h5 className="mb-3">Company Information</h5>

            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Phone</label>
              <input
                type="text"
                placeholder="Enter Phone"
                value={phonenumber}
                onChange={e => setphonenumber(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Email</label>
              <input
                type="text"
                placeholder="Enter Phone"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Incorporation Date</label>
              <input
                type="date"
                value={incorporation_date}
                onChange={e => setincorporation_date(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Website</label>
              <input
                type="text"
                placeholder="Enter Website"
                value={website}
                onChange={e => setwebsite(e.target.value)}
                className="form-control "
              />
            </div>

            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Credit Score</label>
              <input
                type="text"
                placeholder="Enter Credit Score"
                value={credit_score}
                onChange={e => setcredit_score(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Common Score</label>
              <input
                type="text"
                placeholder="Enter Common Score"
                value={common_score}
                onChange={e => setcommon_score(e.target.value)}
                className="form-control "
              />
            </div>

            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Credit Limit</label>
              <input
                type="text"
                placeholder="Enter Credit Limit"
                value={credit_limit}
                onChange={e => setcredit_limit(e.target.value)}
                className="form-control "
              />
            </div>

            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Industry Code</label>
              <input
                type="text"
                placeholder="Enter Industry Code"
                value={industry_code}
                onChange={e => setindustry_code(e.target.value)}
                className="form-control "
              />
            </div>

            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">No of Employees</label>
              <input
                type="text"
                placeholder="Enter No of Employees"
                value={no_of_employees}
                onChange={e => setno_of_employees(e.target.value)}
                className="form-control "
              />
            </div>

            <div className="col-md-3 mt-2 mb-md-0 mb-3">
              <label htmlFor="">Turnover</label>
              <input
                type="text"
                placeholder="Enter Turnover"
                value={turnover}
                onChange={e => setturnover(e.target.value)}
                className="form-control "
              />
            </div>

            <div className="col-md-3 mt-2 mb-md-0 mb-3">
              <label htmlFor="">Profit</label>
              <input
                type="text"
                placeholder="Enter Profit"
                value={profit}
                onChange={e => setprofit(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="col-md-3 mt-2 mb-md-0 mb-3">
              <label htmlFor="">Net Worth</label>
              <input
                type="text"
                placeholder="Enter Net Worth"
                value={net_worth}
                onChange={e => setnet_worth(e.target.value)}
                className="form-control "
              />
            </div>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default Profile
