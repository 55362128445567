import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import {
    Row,
    Form,
    Table
} from "reactstrap";
import Select from 'react-select';

// assets
import '../../../../assets/css/Sales/Sales.css';

const AddZithasInvoice = () => {
    const [fixButtons, setFixButtons] = useState(false);
    const [termsRows, setTermsRows] = useState([{ id: 1 }]);
    const [discountType, setDiscountType] = useState("")

    const [customer, setCustomer] = useState(
        {
            projects: [
                { label: "Nothing Selected", value: "1" }
            ]
        }
    )

    const navigate = useNavigate();

    useEffect(() => window.scrollTo(0, 0), []);

    document.addEventListener('scroll', () => {
        if (window.scrollY > 130) {
            setFixButtons(true);
        }
        else {
            setFixButtons(false);
        }
    })

    // form repeater
    const onAddTermsRow = () => {
        const modifiedRows = [...termsRows];
        modifiedRows.push({ id: modifiedRows.length + 1 });
        setTermsRows(modifiedRows);
    };
    const onDeleteTermsRow = (id) => {
        var modifiedRows = [...termsRows];
        modifiedRows = modifiedRows.filter(x => x["id"] !== id);
        setTermsRows(modifiedRows);
    };

    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
        }),
        menu: base => ({
            ...base,
            zIndex: 50
        })
    }

    const invoiceTypes = [
        { label: "Project Resource", value: "1" },
        { label: "Hosting", value: "2" },
        { label: "Maintenance", value: "3" }
    ];

    const customers = [
        {
            label: "Test", value: {
                introducer: "Zenith Tins",
                projects: [
                    { label: "Demo project 1", value: "1" },
                    { label: "Demo project 2", value: "2" },
                ]
            }
        },
        {
            label: "Manav Infotec", value: {
                introducer: "julius",
                projects: [
                    { label: "Waste Recycle", value: "1" },
                    { label: "Event Management System", value: "2" },
                ]
            }
        },
        {
            label: "Testing customer", value: {
                projects: [
                    { label: "Web development", value: "1" },
                    { label: "Event Management System", value: "2" },
                    { label: "automation 18", value: "3" }
                ]
            }
        },
        {
            label: "Silicon Limited", value: {
                projects: [
                    { label: "Web development", value: "1" },
                    { label: "Event Management System", value: "2" },
                    { label: "automation 18", value: "3" }
                ]
            }
        }
    ];

    const Terms = [
        { label: "Due on Receipt", value: "1" },
        { label: "Due in 15 days", value: "2" }
    ];


    return (
        <React.Fragment>
            <div className="page-content mb-3">
                <Container fluid>
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"New Invoice"}
                    />
                    <Row className='px-1'>
                        <Form>
                            {/* Fixed Buttons */}
                            <div className={`flex-column fixed-buttons ${fixButtons ? 'd-flex' : 'd-none'}`}>
                                <button className='btn btn-purple border-radius fix-btn'>
                                    <i className='fas fa-save me-2'></i>
                                    <span>Save</span>
                                </button>
                                <a onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                                    <i className='dripicons-cross me-2 icon-center'></i>
                                    <span>Cancel</span>
                                </a>
                            </div>
                            <Row className='bg-white px-2 pt-4 pb-5 shadow mb-3'>
                                <div className="col-md-12 mb-3 text-end">
                                    <button className="border-radius btn ms-md-2 btn-purple">
                                        <i className='fas fa-save me-1'></i> save
                                    </button>
                                    <button onClick={(e) => {
                                        e.preventDefault()
                                        navigate(-1)
                                    }} className="border-radius btn ms-md-2 btn-red"><i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                                </div>
                                <Row className='pe-0'>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="">Invoice Type <span className='text-danger'>*</span> </label>
                                        <Select
                                            placeholder="Select Invoice Type"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={invoiceTypes} />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="">Customer Name <span className='text-danger'>*</span> </label>
                                        <Select
                                            placeholder="Select Customer Name"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            onChange={(e) => setCustomer(e.value)}
                                            options={customers} />
                                    </div>
                                    {
                                        customer.introducer ?
                                            <div className='col-md-3 mb-3'>
                                                <label htmlFor="">Introducer</label>
                                                <input type="text"
                                                    value={customer.introducer}
                                                    readOnly
                                                    className='form-control border-radius' />
                                            </div> : null
                                    }
                                    <div className={`col-md-${customer.introducer ? '3' : '6'} mb-3`}>
                                        <label htmlFor="">Projects</label>
                                        <Select
                                            placeholder="Select Projects"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={customer.projects}
                                        />
                                    </div>
                                    <div className='col-md-3 mb-3'>
                                        <label htmlFor="">Invoice Number</label>
                                        <input type="text"
                                            defaultValue="PRF/2023-2024/0004"
                                            placeholder='Enter Invoice Number'
                                            className='form-control border-radius' />
                                    </div>

                                    <div className='col-md-3 mb-3'>
                                        <label htmlFor="">Invoice Date <span className='text-danger'>*</span> </label>
                                        <input type="date"
                                            className='form-control border-radius' />
                                    </div>

                                    <div className="col-md-3 mb-3 mb-md-0">
                                        <label htmlFor="">Terms <span className='text-danger'>*</span></label>
                                        <Select
                                            placeholder="Select Terms"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={Terms} />
                                    </div>

                                    <div className='col-md-3 mb-3'>
                                        <label htmlFor="">Due Date </label>
                                        <input type="date"
                                            className='form-control border-radius' readOnly />
                                    </div>

                                    <div className='col-md-3 mb-3'>
                                        <label htmlFor="">Start Date </label>
                                        <input type="date"
                                            className='form-control border-radius' />
                                    </div>

                                    <div className='col-md-3 mb-3'>
                                        <label htmlFor="">End Date </label>
                                        <input type="date"
                                            className='form-control border-radius' />
                                    </div>

                                    <div className="col-md-4" id="rate_label">
                                        <h5 style={{ textAlign: 'center', paddingTop: '2.4rem' }}>
                                            Exchange Rate :
                                            <span className="rate_set"> Please Select Invoice Date</span>
                                        </h5>
                                        {/* <input name="exchange_rate" type="hidden"></input> */}
                                    </div>
                                </Row>


                                <div className="responsive-table mt-4">
                                    <Table className='table table-bordered mb-0 table-striped mt-3 table-hover'>
                                        <thead className=''>
                                            <tr>
                                                <th>#</th>
                                                <th>SERVICE DETAILS</th>
                                                <th>HSN</th>
                                                <th>QUANTITY</th>
                                                <th className='text-center'>RATE</th>
                                                <th className='text-center'>TAX</th>
                                                <th className='text-center'>AMOUNT</th>
                                                <th className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {(termsRows || []).map((termsRow, key) => (
                                                <tr key={termsRow.id}>
                                                    <td >
                                                        {termsRow.id}
                                                    </td>
                                                    <td className='col-4'>
                                                        <input type="text" placeholder='Type Here' className='form-control border-radius' />
                                                    </td>
                                                    <td className='col-2'>
                                                        <select className="form-select border-radius">
                                                            <option value=""> -- Select -- </option>
                                                            <option value="998313">998313 - Support &amp; consulting service</option>
                                                            <option value="998314">998314 - Design &amp; Development Service</option>
                                                            <option value="998315">998315 - Hosting Services</option>
                                                        </select>
                                                    </td>
                                                    <td className='col-1'>
                                                        <input type="number" placeholder='Qty.' className='form-control border-radius' />
                                                    </td>
                                                    <td className='col-1'>
                                                        <input type="text" className='form-control border-radius' />
                                                    </td>
                                                    <td className='col-1 text-center'>
                                                        18%
                                                    </td>
                                                    <td className='col-1'>

                                                    </td>
                                                    <td className='col-1 text-center'>
                                                        {
                                                            key === 0 ?
                                                                <a onClick={() => onAddTermsRow()} className='btn btn-purple'>
                                                                    <i className="fas fa-plus"></i>
                                                                </a> :
                                                                <a onClick={() => onDeleteTermsRow(termsRow.id)} className='btn btn-danger'>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </a>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                                <Row className='mt-5 justify-content-between'>
                                    <div className="col-md-7">
                                        <label htmlFor="">Customer Notes</label>
                                        <textarea name="customer_note"
                                            defaultValue="Thank you for giving us an opportunity! Hoping work from you in future"
                                            className='form-control' id=""
                                            cols="35" rows="5" />
                                    </div>

                                    <div className="col-md-4">
                                        <div className="total-box p-4 px-5 border rounded"
                                            style={{ backgroundColor: '#f8f9fa' }}>

                                            <div className='d-flex py-2 justify-content-between'>
                                                <strong>Sub Total</strong>
                                                <span>0.00</span>
                                            </div>

                                            <div className='py-2 d-flex align-items-center justify-content-between'>
                                                <select
                                                    onChange={(e) => setDiscountType(e.target.value)}
                                                    className="form-select border-radius w-50 " id="discount">
                                                    <option value="">Select Discount</option>
                                                    <option value="Percentage">Percentage(%)</option>
                                                    <option value="Fixed">Fixed</option>
                                                </select>
                                                {
                                                    discountType !== "" ?
                                                        <input type="text" className='form-control w-25 me-md-3' /> : null
                                                }
                                                <span>0.00</span>
                                            </div>

                                            <div className='d-flex pt-2 mt-3 border-top border-secondary pb-2 justify-content-between'>
                                                <strong> Total (₹)</strong>
                                                <span>0.00</span>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </Row>

                        </Form>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddZithasInvoice