import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"
import axios from "axios"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import configure from "configure"

const ActivityLog = ({ verticalActiveTab }) => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const statuses = [
    {
      id: 1,
      stausTitle: "Followup Added",
      dateTime: "At 01-01-1970 00:00:00 By Yasir Khan",
    },
    {
      id: 2,
      stausTitle: "Note Added",
      dateTime: "At 01-01-1970 00:00:00 By Yasir Khan",
    },
    {
      id: 3,
      stausTitle: "Note Added",
      dateTime: "At 01-01-1970 00:00:00 By Yasir Khan",
    },
    {
      id: 4,
      stausTitle: "Note Added",
      dateTime: "At 01-01-1970 00:00:00 By Yasir Khan",
    },
  ]

  const params = useParams()
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (verticalActiveTab == "4") {
      fetchFiles()
    }
  }, [verticalActiveTab])

  const fetchFiles = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/introducer_activity`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setData(response.data.data["introducer-activity"])
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [old_data, setOldData] = useState({})
  const [new_data, setNewData] = useState({})
  const [viewData, setViewData] = useState(false)

  const labelobj = Object.keys(old_data)
  const oldValue = Object.values(old_data)
  const newValue = Object.values(new_data)

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <div>
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h5 className="text-dark">My Activity</h5>
                    <div className="text-end">
                      <a
                        onClick={() => navigate(-1)}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </div>
                  <div>
                    <ul className="verti-timeline list-unstyled">
                      {/* Activities */}
                      {data.map((status, key) => (
                        <li key={key} className="event-list position-relative">
                          <div className="event-timeline-dot">
                            <i
                              className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${
                                status.id === 3 ? "bx-fade-right" : ""
                              }`}
                            />
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div className="d-flex align-items-center">
                                <div>
                                  <h5>{status.description}</h5>
                                  <p className="text-muted">
                                    {" "}
                                    At{" "}
                                    {moment(status.created_at).format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    By {status.added_by}
                                  </p>
                                </div>
                                {status.new_data != null ? (
                                  <div className="ml-30">
                                    <a
                                      className="btn btn-sm activity-log-btn text-info"
                                      onClick={() => {
                                        setOldData(JSON.parse(status.old_data))
                                        setNewData(JSON.parse(status.new_data))
                                        setViewData(true)
                                      }}
                                    >
                                      <i className="fas fa-eye"></i> View
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <p className="timing">
                              {moment(status.created_at)
                                .format("DD-MM-YYYY hh:mm:s")
                                .slice(10)}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal size="md" centered isOpen={viewData} className="activity-log">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Log Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewData(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th>Fields</th>
                  <th>Old Data</th>
                  <th>New Data</th>
                </tr>
              </thead>
              <tbody>
                {labelobj.map((items, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{items}</th>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: oldValue[index] || "-",
                          }}
                        />
                      </td>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: newValue[index] || "-",
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ActivityLog
