import React, { useEffect, useState } from "react"
import { Container, Table, Label, Modal } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import ReactSelect from "react-select"
import Swal from "sweetalert2"

const BasicProjectDetails = ({ editId }) => {
  //   All useState
  const [modalPopup, setModalPopup] = useState(false)
  const [reasonOptions, setReasonOptions] = useState([])
  const [isReasonSubmitted, setIsReasonSubmitted] = useState(false)
  const [selectedReason, setSelectedReason] = useState(null)
  const [isLoading, setLoading] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [ztplDetails, setZtplDetails] = useState("New")
  const [localServer, setLocalServer] = useState("New")
  const [fixButtons, setFixButtons] = useState(false)
  const [testingRows, setTestingRows] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])

  const navigate = useNavigate()

  const [allCustomerOption, setAllCustomerOption] = useState([])
  const [allIntroducerOption, setAllIntroducerOption] = useState([])
  const [allLeadsourceOption, setAllLeadsourceOption] = useState([])
  const [allKeyIndustryOption, setAllKeyIndustryOption] = useState([])
  const [source, setSource] = useState([])
  const [deliveryBeforeDate, setDeliveryBeforeDate] = useState("")

  const togglePopup = () => {
    setModalPopup(!modalPopup)
  }

  const handleDBDateChange = e => {
    const newDBDate = e.target.value
    if (projectEstEDate && new Date(newDBDate) < new Date(projectEstEDate)) {
      setModalPopup(true)
      setDeliveryBeforeDate(newDBDate)
      // Swal.fire({
      //   title: "Warning",
      //   text: "The Delivery Before Date cannot be before the Estimated End Date.",
      //   icon: "warning",
      //   confirmButtonText: "OK",
      // }).then(() => {
      //   // Optionally, you can reset the Delivery Before Date here or leave it as it is
      //   // setProjectEstDBDate(projectEstDBDate); // uncomment this if you want to reset
      // })
    } else {
      setProjectEstDBDate(newDBDate)
    }
  }

  const handleReason = async e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("role_id", localStorage.getItem("auth_role"))
    formData.append("reason", selectedReason.label)
    formData.append("project_id", editId)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/updateReason`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setProjectEstDBDate(deliveryBeforeDate)
        Swal.fire({
          icon: "success",
          text: "Reason Submitted successfully.",
        }).then(() => {
          setIsReasonSubmitted(true)
          setModalPopup(false)
          setDeliveryBeforeDate("")
        })
        console.log(response)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectRelatedData`)
        .then(res => res.json())
        .then(data => {
          const modifyCus = data.data.customer?.map(list => {
            return {
              label: list.company,
              value: list.id,
            }
          })
          const modifyLocation = data.data.location?.map(obj => {
            return {
              label: obj.location,
              value: obj.id,
            }
          })
          const modifyKeyManager = data.data.keymanager?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyScrum = data.data.scrum?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyIntroducer = data.data.introducer?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyLeadsource = data.data.leadsource?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyIndustry = data.data.industry?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          const modifyZtpl = data.data.ztpl?.map(item => {
            return {
              label: item.Project,
              value: item.id,
              FTPHost: item.FTPHost,
              FTPUser: item.FTPUser,
              FTPPassword: item.FTPPassword,
              DBName: item.DBName,
              DBUsername: item.DBUsername,
              DBPassword: item.DBPassword,
            }
          })
          const modify192 = data.data.data_192?.map(item => {
            return {
              label: item.Project,
              value: item.id,
              FTPHost: item.FTPHost,
              FTPUser: item.FTPUser,
              FTPPassword: item.FTPPassword,
              DBName: item.DBName,
              DBUsername: item.DBUsername,
              DBPassword: item.DBPassword,
            }
          })

          const modifySourceOpt = data.data.source?.map(item => {
            return {
              label: item.leadname,
              value: item.id,
            }
          })

          const modifyArr = data.data.developers?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })

          setProjectMembersOptions(modifyArr)

          setSource(modifySourceOpt)

          setProjectKeyManagerOptions(modifyKeyManager)
          setProjectScrumOptions(modifyScrum)
          setLocationOption(modifyLocation)

          setCustomerOptions(modifyCus)
          setAllCustomerOption(modifyCus)

          setAllIntroducerOption(modifyIntroducer)
          setAllLeadsourceOption(modifyLeadsource)
          setAllKeyIndustryOption(modifyIndustry)
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const fetchAllFunc = async () => {
    try {
      await fetchData()
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchAllFunc().then(async () => {
          await fetchData5()
        })
      } catch (error) {
        console.error("Error in useEffect:", error)
      }
    }

    loadData()
  }, [])

  const fetchData5 = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectView/${editId}`)
        .then(res => res.json())
        .then(async response => {
          const modifyReason = response.data.delayReasons?.map(item => {
            return {
              label: item.reasons,
              value: item.id,
            }
          })
          console.log("modifyReason", modifyReason)
          setReasonOptions(modifyReason)
          const modifyArr = response?.data?.project2?.map(item => {
            return {
              label: item.project_name,
              value: item.id,
              gitreposatirylink: item.gitreposatirylink,
              demolink: item.demolink,
              icode: item.icode,
            }
          })
          const filterEditData = response?.data?.project2?.find(item => {
            return item.id == editId
          })

          const findProjectGit = modifyArr?.find(
            list => list.label == filterEditData?.project_name?.trim()
          )

          const findproType = projectTypes?.find(
            list => list.value == filterEditData?.project_type
          )
          const modifyleadsource = response?.data?.leadsource?.map(lisy => {
            return {
              label: lisy.leadname,
              value: lisy.id,
            }
          })
          const modifyIndustry = response?.data?.industry?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          const selectedOption = modifyleadsource?.find(
            list => list.value == filterEditData?.source
          )

          const findResourceType = ResourceType?.find(
            list => list.value == filterEditData?.billingtype
          )
          const findIndustry = modifyIndustry?.find(
            list => list.value == filterEditData?.industry
          )
          const findprojectbelogsto = filterEditData?.projectbelogsto
          const source_name = filterEditData?.source_name
          const customerId = filterEditData?.customer
          fetchLocation(findprojectbelogsto)

          setProjectName(filterEditData?.project_name)
          setProjectType(findproType)
          setProjectSource(selectedOption)
          setProjectResourceType(findResourceType)
          setProjectIndustry(findIndustry)
          setProjectDescription(filterEditData?.description)
          setProjectEstSDate(filterEditData?.startdate)
          setProjectEstDays(filterEditData?.estimatedhours)
          setProjectEstEDate(filterEditData?.enddate)
          setProjectEstDBDate(filterEditData?.deliverydatebefore)
          // // setProjectFromGitName()
          // setGitRepositoryLink(filterEditData?.gitreposatirylink)
          // setGitDemoLink(filterEditData?.demolink)
          // setGitIcodeLink(filterEditData?.icode)
          // setServerFolderName(filterEditData?.folder_name)
          // setServer192Link(filterEditData?.link_192)
          // setServerZTPLLink(filterEditData?.ztpllink)
          // setZhost(filterEditData?.zhostlink)
          // setZusername(filterEditData?.zusername)
          // setZpassword(filterEditData?.zpassword)
          // setZdatabasename(filterEditData?.zdatabasename)
          // setZdbusername(filterEditData?.zdbusername)
          // setZdbpassword(filterEditData?.zdbpassword)
          // setHost(filterEditData?.hostlink)
          // setUsername(filterEditData?.username)
          // setPassword(filterEditData?.password)
          // setDatabasename(filterEditData?.databasename)
          // setDbusername(filterEditData?.dbusername)
          // setDbpassword(filterEditData?.dbpassword)
          // setProjectFromGit(findProjectGit)

          // ................................................................
          const modifyLocation = response?.data?.location?.map(item => {
            return {
              label: item.location,
              value: item.id,
            }
          })
          const findLocation = modifyLocation?.find(
            list => list.value == findprojectbelogsto
          )
          setProjectBelong(findLocation)

          const modifyintroducer = response.data.introducer?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyCustomer = response.data.customer?.map(item => {
            return {
              label: item.company ? item.company : "",
              value: item.id,
            }
          })
          console.log("v modifyCustomer", modifyCustomer)
          console.log("v modifyintroducer", modifyintroducer)
          console.log("v source_name", source_name)

          if (selectedOption) {
            handleSource(
              selectedOption,
              modifyintroducer,
              modifyCustomer,
              source_name
            )
          }

          const findCustomer = modifyCustomer?.find(
            list => list.value == customerId
          )
          setProjectCustomer(findCustomer)

          const modifyMember = response.data?.developers?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyKeyOp = response.data?.keymanager?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyScrumOp = response.data?.scrum?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyKey = modifyKeyOp?.find(list => {
            return list.value == filterEditData?.keyaccountmanager
          })
          const modifyScrum = modifyScrumOp?.find(list => {
            return list.value == filterEditData?.scrum_master
          })

          const memberList = filterEditData?.member
          const modifyMemberList = memberList?.split(",")
          const findMembers = modifyMember?.filter(list =>
            modifyMemberList?.includes(list.value.toString())
          )

          const memberLookup = modifyMember?.reduce((acc, { value, label }) => {
            acc[value] = label
            return acc
          }, {})

          const modifyTestingRow = response.data?.estimate.map(list => {
            return {
              id: list.developer_id,
              estimatedtime: list.days,
              milestone: memberLookup[list.developer_id],
            }
          })
          setProjectMembers(findMembers)
          setProjectKeyManager(modifyKey)
          setProjectScrumMaster(modifyScrum)
          setTestingRows(modifyTestingRow)
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const fetchLocation = async findprojectbelogsto => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        const modifyArr = res.data.data.map(obj => ({
          label: obj.location,
          value: obj.id,
        }))
        // setLocationOption(modifyArr)
        const findLocation = modifyArr?.find(
          list => list.value == findprojectbelogsto
        )
        setProjectBelong(findLocation)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onDescription = (e, key) => {
    let values = [...testingRows]
    values[key][e.target.name] = e.target.value
  }
  const onTime = (e, index) => {
    // Create a new array with the current testingRows
    const updatedRows = [...testingRows]

    // Update the specific item in the array
    updatedRows[index] = {
      ...updatedRows[index],
      [e.target.name]: e.target.value,
    }

    // Update the state with the new array
    setTestingRows(updatedRows)
  }

  const projectTypes = [
    { label: "Regular Project", value: "1" },
    { label: "Dedicated Resource", value: "2" },
    { label: "Maintenance Project", value: "3" },
  ]

  const ResourceType = [
    { label: "Fixed Rate", value: "1" },
    { label: "Project Hours", value: "2" },
    { label: "Dedicated Resource", value: "3" },
  ]

  // Api Integration
  const [projectBelong, setProjectBelong] = useState(null)
  const [projectName, setProjectName] = useState("")
  const [projectType, setProjectType] = useState(null)
  const [projectSource, setProjectSource] = useState(null)
  const [projectReference, setProjectReference] = useState(null)
  const [projectReferenceOption, setProjectReferenceOption] = useState([])
  const [projectReferenceOptions, setProjectReferenceOptions] = useState([])
  const [projectReferenceOptionsLabel, setProjectReferenceOptionsLabel] =
    useState("")
  const [projectCustomer, setProjectCustomer] = useState(null)
  const [projectResourceType, setProjectResourceType] = useState(null)
  const [projectIndustry, setProjectIndustry] = useState(null)
  const [projectDescription, setProjectDescription] = useState(null)
  const [projectKeyManager, setProjectKeyManager] = useState(null)
  const [projectKeyManagerOptions, setProjectKeyManagerOptions] = useState([])
  const [projectScrumOptions, setProjectScrumOptions] = useState([])
  const [projectScrumMaster, setProjectScrumMaster] = useState(null)
  const [projectMembersOptions, setProjectMembersOptions] = useState([])
  const [projectMembers, setProjectMembers] = useState([])
  const [projectEstSDate, setProjectEstSDate] = useState("")
  const [projectEstEDate, setProjectEstEDate] = useState(null)
  const [projectEstDBDate, setProjectEstDBDate] = useState(null)
  const [projectEstDays, setProjectEstDays] = useState(null)

  const handleSource = async (
    selectedOption,
    modifyintroducer,
    modifyCustomer,
    source_name
  ) => {
    console.log("selectedOption", selectedOption)
    console.log("source_name", source_name)
    console.log("modifyintroducer", modifyintroducer)
    console.log("modifyCustomer", modifyCustomer)
    if (source_name == "" || undefined) {
      setProjectReferenceOption(null)
    }
    setProjectSource(selectedOption)

    if (selectedOption.value == "14") {
      setProjectReferenceOptions([...modifyintroducer])
      setProjectReferenceOptionsLabel("Introducer")
      if (source_name) {
        const findReferenceOption = modifyintroducer?.find(
          list => list.value == source_name
        )
        setProjectReferenceOption(findReferenceOption)
      }
    } else if (selectedOption.value == "15") {
      setProjectReferenceOptions([...modifyCustomer])
      setProjectReferenceOptionsLabel("Customer")
      if (source_name) {
        const findReferenceOption = modifyCustomer?.find(
          list => list.value == source_name
        )
        setProjectReferenceOption(findReferenceOption)
      }
    }
  }

  const getSelectedLabels = selectedOptions =>
    selectedOptions.map(option => option.value)

  const handleMembers = selectedOption => {
    setProjectMembers(selectedOption)
    const selectedLabels = getSelectedLabels(selectedOption)
    setTestingRows(prevRows => {
      const updatedRows = prevRows.filter(row =>
        selectedLabels.includes(row.id)
      )
      const newRows = selectedOption
        .filter(option => !prevRows.some(row => row.id === option.value))
        .map(option => ({
          id: option.value,
          milestone: option.label,
          estimatedtime: "",
        }))

      return [...updatedRows, ...newRows]
    })
  }

  console.log("projectSource", projectSource)
  const handleSubmitBasicDetails = async e => {
    e.preventDefault()

    if (
      projectBelong != null &&
      projectType != null &&
      projectSource != null &&
      projectResourceType != null &&
      projectIndustry != null
    ) {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("role_id", localStorage.getItem("auth_role"))
      formData.append("project_id", editId)

      // Project Basic Details

      formData.append("projectbelogsto", projectBelong?.value)
      formData.append("project_name", projectName)
      formData.append("project_type", projectType?.value)
      formData.append("source", projectSource?.value)
      if (projectSource.value == 14) {
        formData.append("introducer", projectReferenceOption.value)
      }
      if (projectSource.value == 15) {
        formData.append("source_customer", projectReferenceOption?.value)
      }
      // if (projectSource.value == "4") {
      //   formData.append("lead", projectReferenceOption?.value)
      // }
      formData.append("customer", projectCustomer?.value)
      formData.append("resource_type", projectResourceType?.value)
      formData.append("industry", projectIndustry?.value)
      formData.append("description", projectDescription)

      // Members

      formData.append("keyaccountmanager", projectKeyManager?.value)
      formData.append("scrum_master", projectScrumMaster?.value)
      formData.append(
        "member",
        projectMembers?.map(list => list.value).toString()
      )
      formData.append("start_date", projectEstSDate)
      formData.append("end_date", projectEstEDate)
      formData.append("deliverydatebefore", projectEstDBDate)
      formData.append("no_of_days", projectEstDays)

      // Develolpers Details

      formData.append("dev_id", testingRows?.map(list => list.id).toString())
      formData.append(
        "dev_days",
        testingRows?.map(list => list.estimatedtime).toString()
      )

      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/edit_project`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            title: "Project Edited successfully.",
          }).then(() => {
            navigate(`${configure.appUrl}work/projects`)
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }

  useEffect(() => {
    if (projectEstSDate && projectEstDays) {
      const startDate = new Date(projectEstSDate)
      const numDays = parseInt(projectEstDays, 10)
      const endDate = addBusinessDays(startDate, numDays)
      setProjectEstEDate(endDate.toISOString().split("T")[0])
    } else {
      setProjectEstEDate("")
    }
  }, [projectEstSDate, projectEstDays])

  function addBusinessDays(startDate, numDays) {
    const resultDate = new Date(startDate)
    let daysAdded = 0

    while (daysAdded < numDays) {
      resultDate.setDate(resultDate.getDate() + 1)
      const dayOfWeek = resultDate.getDay()

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++
      }
    }
    return resultDate
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className=" mb-3">
        <Container fluid>
          {/* <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Project"} /> */}
          <Form className="px-0" onSubmit={handleSubmitBasicDetails}>
            <Row className="">
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button
                  type="submit"
                  className="btn btn-save  fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  <span>Submit</span>
                </button>

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </button>
              </div>
              {/* Project Basic Details  */}
              <Row className="bg-white py-4 shadow mb-3 px-2">
                <div className="col-md-12 d-flex justify-content-between">
                  <div className="col-md-6">
                    <h5 className="mb-3 text-dark">Project Basic Details</h5>
                  </div>
                  <div className="col-md-6 mb-3 text-end">
                    <button
                      type="submit"
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        navigate(-1)
                      }}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </button>
                  </div>
                </div>

                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">
                    Project Belongs To <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Project Belongs To"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location_option}
                    value={projectBelong}
                    onChange={selectedOption =>
                      setProjectBelong(selectedOption)
                    }
                  />
                </div>

                <div className="col-md-5 mt-2 mb-3">
                  <label htmlFor="">
                    Project Name <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Project Name"
                    className="form-control "
                    value={projectName}
                    onChange={e => setProjectName(e.target.value)}
                  />
                </div>

                <div className="col-md-4 mt-2 mb-3">
                  <label htmlFor="">
                    Project Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    placeholder="Select Project Type"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={projectTypes}
                    value={projectType}
                    onChange={selectedOption => setProjectType(selectedOption)}
                  />
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Source <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={source}
                      value={projectSource}
                      onChange={
                        selectedOption =>
                          handleSource(
                            selectedOption,
                            allIntroducerOption,
                            allCustomerOption,
                            ""
                          )
                        // setProjectSource(selectedOption)
                      }
                    />
                  </div>
                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      Resource Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Resource Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={ResourceType}
                      value={projectResourceType}
                      onChange={selectedOption =>
                        setProjectResourceType(selectedOption)
                      }
                    />
                  </div>
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Select {projectReferenceOptionsLabel}
                    </label>
                    <Select
                      placeholder={`Select ${projectReferenceOptionsLabel}`}
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={projectReferenceOptions}
                      value={projectReferenceOption}
                      onChange={selectedOption => {
                        console.log("selectedOption", selectedOption)
                        setProjectReferenceOption(selectedOption)
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="col-md-3 mb-3 mt-2 mb-md-0">
                    <label htmlFor="">
                      Customer <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Customer"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={customerOptions}
                      value={projectCustomer}
                      onChange={selectedOption =>
                        setProjectCustomer(selectedOption)
                      }
                    />
                  </div>

                  <div className="col-md-3 mt-2 mb-3 mb-md-0">
                    <label htmlFor="">
                      {" "}
                      Project Industry <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Industry"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={allKeyIndustryOption}
                      value={projectIndustry}
                      onChange={selectedOption =>
                        setProjectIndustry(selectedOption)
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={projectDescription}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setProjectDescription(data)
                    }}
                  />
                </div>
              </Row>

              {/* Project Basic Details  */}
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">Members</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Key Account Manager</label>
                      <Select
                        placeholder="Select Key Account Manager"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        // options={KeyAccountManager}
                        options={projectKeyManagerOptions}
                        value={projectKeyManager}
                        onChange={selectedOption =>
                          setProjectKeyManager(selectedOption)
                        }
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">Scrum Master</label>
                      <Select
                        placeholder="Select Scrum Master"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={projectScrumOptions}
                        value={projectScrumMaster}
                        onChange={selectedOption =>
                          setProjectScrumMaster(selectedOption)
                        }
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3">
                      <label htmlFor="">Members</label>
                      <ReactSelect
                        placeholder="Select Members"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={projectMembersOptions}
                        isMulti="true"
                        value={projectMembers}
                        onChange={selectedOption =>
                          handleMembers(selectedOption)
                        }
                      />
                    </div>

                    <h5 className="mb-3 mt-4">Estimatation</h5>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={projectEstSDate}
                        onChange={e => setProjectEstSDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3">
                      <label htmlFor="">
                        Number OF Days<span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder="Enter Number OF Days"
                        className="form-control "
                        value={projectEstDays}
                        onChange={e => setProjectEstDays(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 ">
                      <label htmlFor="">Estimated End Date</label>
                      <input
                        disabled
                        type="date"
                        className="form-control "
                        value={projectEstEDate}
                        onChange={e => setProjectEstEDate(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2 mb-3 ">
                      <label htmlFor="">
                        Delivery Before Date
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        required
                        type="date"
                        className="form-control "
                        value={projectEstDBDate}
                        onChange={e => {
                          handleDBDateChange(e)
                        }}
                      />
                      {isReasonSubmitted && (
                        <div className="text-danger mt-2 d-flex justify-content-end">
                          {"Reason is submitted..."}
                        </div>
                      )}
                    </div>
                    {projectEstEDate > projectEstDBDate && (
                      <div className="mt-3 text-danger">
                        The Delivery Before Date must be after the Estimated End
                        Date.
                      </div>
                    )}
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-3">Develolpers Details</h5>
                      {/* <a
                        onClick={() => onAddTestingRow()}
                        className="btn btn-purple  btn-label"
                      >
                        <i className="fas fa-plus me-2 icon-size label-icon"></i>{" "}
                        Add New
                      </a> */}
                    </div>
                    <div className="responsive-table">
                      <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                        <thead className="">
                          <tr>
                            {/* <th>Sr No.</th> */}
                            <th>Devloper Name</th>
                            <th>
                              No. of Days{" "}
                              <span className="text-danger">
                                {testingRows.length > 0 ? "*" : ""}
                              </span>
                            </th>
                            {/* <th className="text-center">Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {(testingRows || []).map((testingRow, index) => (
                            <tr key={testingRow.id}>
                              <td className="col-2 d-none">
                                <input
                                  type="number"
                                  placeholder=""
                                  className="form-control "
                                  name="sr.no"
                                  value={testingRow.id}
                                />
                              </td>
                              <td className="col-6">
                                <input
                                  type="text"
                                  placeholder="Enter Devloper Name"
                                  className="form-control "
                                  name="milestone"
                                  value={testingRow.milestone}
                                  onChange={e => onDescription(e, index)}
                                />
                              </td>
                              <td className="col-2">
                                <input
                                  required
                                  type="number"
                                  placeholder="Days.."
                                  className="form-control "
                                  name="estimatedtime"
                                  value={testingRow.estimatedtime}
                                  onChange={e => onTime(e, index)}
                                />
                              </td>
                              {/* <td className="col-2 text-center">
                                <a
                                  onClick={() => onDeleteTestingRow(index)}
                                  className="btn btn-danger"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </a>
                              </td> */}
                            </tr>
                          ))}
                          {testingRows.length == 0 && (
                            <tr>
                              <td className="text-center" colSpan={10}>
                                No Developer Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </div>
              </Row>
            </Row>
          </Form>
        </Container>
      </div>
      <Modal
        size="md"
        isOpen={modalPopup}
        toggle={() => {
          togglePopup()
        }}
        centered
        className=""
      >
        <div className="modal-header mb-0">
          <div className="d-flex justify-content-between w-100">
            <div>
              <h5>Add Reason</h5>
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  setModalPopup(false)
                }}
                className="ms-4"
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                <span
                  aria-hidden="true"
                  style={{
                    color: "#bf1e30",
                    fontSize: "28px",
                    lineHeight: "1px",
                  }}
                >
                  {" "}
                  &times;{" "}
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="modal-body">
          <form onSubmit={handleReason}>
            <div className="">
              <div className="mb-4">
                <label htmlFor="">
                  Select Reason <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder="Please Select Reason"
                  className="overflow-visible"
                  classNamePrefix="select"
                  styles={colourStyles}
                  options={reasonOptions}
                  value={selectedReason}
                  onChange={selectedOption => setSelectedReason(selectedOption)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-save btn-label">
                <i className="fas fa-save me-2 icon-size label-icon"></i>
                <span>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default BasicProjectDetails
