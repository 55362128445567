import React, { useEffect, useState } from "react"
// import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Select from "react-select"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import classnames from "classnames"

import Profile from "pages/Sales/Lead/EditLead/Profile"
import Notes from "pages/Sales/Lead/EditLead/Notes"
import FollowUp from "pages/Sales/Lead/EditLead/FollowUp"
import ActivityLog from "pages/Sales/Lead/EditLead/ActivityLog"

import axios from "axios"
import Loader from "pages/Separate/Loader"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import configure from "configure"
import moment from "moment/moment"
import Swal from "sweetalert2"
import MyEditorIndex from "./MyEditorIndex"
import TinyEditor from "../TinyEditor"
import { Editor } from "@tinymce/tinymce-react"

// components

const AddTopics = ({
  cateId,
  subCateId,
  setRow,
  setData,
  isDropdown,
  verticalActiveTab,
  setTopicData,
}) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [topicTitle, setTopicTitle] = useState("")
  const [description, setDescription] = useState("")
  const [editor, setEditor] = useState(description)
  const [error, setError] = useState(false)
  //edit data
  document.title = "Add Topic  | Zithas Technologies"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const navigate = useNavigate()

  const handleSubmit = async e => {
    e.preventDefault()
    if (editor.length !== 0) {
      setLoading(true)
      const formData = new FormData()
      const authId = localStorage.getItem("auth_id")
      formData.append("user_id", authId)
      formData.append("category_id", cateId)
      formData.append("subcategory_id", subCateId)
      formData.append("topic_name", topicTitle)
      formData.append("description", editor)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/addKnowledgeTopics`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response.data.success) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Topic Added Successfully.",
          }).then(() => {
            setRow(1)
            setTopicTitle("")
            setDescription("")
            setTopicData(response.data.data.topics)
            let filterData
            if (isDropdown) {
              filterData = response.data.data.topics?.filter(item => {
                return item.subcategory == verticalActiveTab
              })
            } else {
              filterData = response.data.data.topics?.filter(item => {
                return (
                  item.category == verticalActiveTab && item.subcategory == null
                )
              })
            }
            console.log("filterData", filterData)
            setData(filterData)
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${response.data.data}`,
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      setError(true)
    }
  }

  const handleEditorChange = (content, editor) => {
    const Description = editor.getContent()
    setEditor(Description)
    setError(false)
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="">
        <Row className="gx-4 justify-content-between di-flex align-items-top">
          <Col md="12 pe-0">
            {/* {isLoading && <Loader />} */}
            <form onSubmit={handleSubmit}>
              <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
                <div
                  className={`flex-column fixed-buttons pt-2 ${
                    fixButtons ? "d-flex" : "d-none"
                  }`}
                >
                  <button className="btn btn-purple fix-btn btn-label pe-0">
                    <i className="fas fa-save me-2 label-icon"></i>
                    <span>Save</span>
                  </button>
                  <a
                    onClick={() => setRow(1)}
                    className="btn btn-red fix-btn btn-label pe-0"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    <span>Cancel</span>
                  </a>
                </div>
                <Row className="bg-white shadow mb-3">
                  <div className="col-md-12 mb-3 text-end">
                    <button
                      //   onClick={e => editLead(e)}
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> save
                    </button>
                    <a
                      onClick={() => setRow(1)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                  <div className="row d-flex flex-column gap-4 p-0">
                    <div className="col-md-12">
                      <div className=" d-flex flex-column ">
                        <label htmlFor="">
                          Add Topic <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="tex"
                          name="edittopic"
                          placeholder="Enter Topic Name"
                          className="form-control"
                          required
                          value={topicTitle}
                          onChange={e => setTopicTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 edit-descrip">
                      <div className="d-flex justify-space-between">
                        <label htmlFor="">
                          Description <span className="text-danger">*</span>
                        </label>
                        <span
                          className="text-danger"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            paddingTop: "7px",
                          }}
                        >
                          {error ? "Please enter description" : null}
                        </span>
                      </div>
                      <div className="col-md-12 ">
                        {/* <Editor
                          initialValue={description}
                          // placeholder={placeholder}
                          apiKey="w6fz9u4rgsouz7jqcyy4lflpolp0tjwkx1bzoc70ntetxwys"
                          init={{
                            height: 800,
                            menubar: true,
                            plugins: [
                              "a11ychecker",
                              "accordion",
                              "advlist",
                              "advcode",
                              "advtable",
                              "autolink",
                              "checklist",
                              "export",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "powerpaste",
                              "fullscreen",
                              "formatpainter",
                              "insertdatetime",
                              "media",
                              "table",
                              "wordcount",
                              "code",
                              "pagebreak",
                              "quickbars",
                              "codesample",
                              "textcolor",
                            ],
                            toolbar:
                              "undo redo | casechange blocks | bold italic backcolor | fontselect fontsizeselect |" +
                              "alignleft aligncenter alignright alignjustify | " +
                              "bullist numlist checklist outdent indent | removeformat | a11ycheck code table | exportpdf |",
                            fontsize_formats:
                              "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                          }}
                          // onEditorChange={handleEditorInit}
                          onEditorChange={handleEditorChange}
                        /> */}
                        <TinyEditor
                          description={description}
                          setError={setError}
                          setDescription={setDescription}
                          placeholder={"Start typings..."}
                          editor={editor}
                          setEditor={setEditor}
                          setLoading={setLoading}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default AddTopics
