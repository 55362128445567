import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Row, Col, Card, CardBody, CardTitle
} from "reactstrap";


// assets
import '../../../../assets/css/Sales/Sales.css';

const ActivityLog = () => {

    const [fixButtons, setFixButtons] = useState(false);

    const navigate = useNavigate();

    document.addEventListener('scroll', () => {
        if (window.scrollY > 160) {
            setFixButtons(true);
        }
        else {
            setFixButtons(false);
        }
    })

    const statuses = [
        {
            id: 1,
            stausTitle: "Followup Added",
            dateTime: "At 01-01-1970 00:00:00 By Yasir Khan"
        },
        {
            id: 2,
            stausTitle: "Note Added",
            dateTime: "At 01-01-1970 00:00:00 By Yasir Khan"
        },
        {
            id: 3,
            stausTitle: "Note Added",
            dateTime: "At 01-01-1970 00:00:00 By Yasir Khan"
        },
        {
            id: 4,
            stausTitle: "Note Added",
            dateTime: "At 01-01-1970 00:00:00 By Yasir Khan"
        },
    ];


    return (
        <React.Fragment>
            <div >
                <div>
                    {/* Fixed Buttons */}
                    <div className={`flex-column fixed-buttons ${fixButtons ? 'd-flex' : 'd-none'}`}>
                        <a onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                            <i className='dripicons-cross me-2 icon-center'></i>
                            <span>Cancel</span>
                        </a>
                    </div>
                    < Row >
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className='d-flex justify-content-between'>
                                        <h5>
                                            My Activity
                                        </h5>
                                        <div className='text-end'>
                                            <a onClick={() => navigate(-1)} className="border-radius btn ms-md-2 btn-red">
                                                <i className='dripicons-cross me-2 icon-center'></i>CANCEL
                                            </a>
                                        </div>
                                    </div>
                                    <div >
                                        <ul className="verti-timeline list-unstyled">
                                            {/* Activities */}
                                            {statuses.map((status, key) => (
                                                <li key={key} className="event-list position-relative">
                                                    <div className="event-timeline-dot">
                                                        <i
                                                            className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${status.id === 3
                                                                ? "bx-fade-right" : ""}`}
                                                        />
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <h5>{status.stausTitle}</h5>
                                                                <p className="text-muted">
                                                                    {status.dateTime}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p className='timing'>00:00</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row >
                </div>
            </div>
        </React.Fragment>
    )
}

export default ActivityLog