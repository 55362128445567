import React, { useEffect, useMemo, useState } from "react"
import { Table, Row, Col, Card, CardBody, Form, Modal } from "reactstrap"
import { useTable, useGlobalFilter, usePagination } from "react-table"
import { Link, useNavigate } from "react-router-dom"

// css
import "../../../assets/css/Settings/Setting.css"
import "../../../assets/css/Marketing/Marketing.css"

// commom
import Pagination from "components/Common/Pagination"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import configure from "configure"

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [biddingDocModal, setBiddingDocModal] = useState(false)
  const [addBiddingModal, setAddBiddingModal] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => setAddBiddingModal(true)}
          className="btn btn-purple border-radius fix-btn"
        >
          <i className="fas fa-plus me-2 icon-size"></i>
          <span>Bidding</span>
        </button>

        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={10} className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={() => setBiddingDocModal(true)}
              className="border-radius btn btn-purple me-2"
            >
              <i className="dripicons-document me-2 icon-center"></i>
              Bidding Documents
            </button>

            <button
              onClick={() => setAddBiddingModal(true)}
              className="border-radius btn btn-purple me-2"
            >
              <i className="fas fa-plus me-2 icon-size"></i>Bidding
            </button>

            <button
              onClick={() => navigate(-1)}
              className="border-radius btn btn-red"
            >
              <i className="dripicons-cross me-2 icon-center"></i>CANCEL
            </button>
          </div>
        </Col>
      </Row>

      <div className="position-relative">
        <Table className="table table-bordered mt-3" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fixed-setting-header" : ""
            } fix-bidding-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-bidding-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Add Payment Modal */}
      <Modal isOpen={addBiddingModal} size="lg" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Bidding</h5>
          <button
            type="button"
            onClick={() => {
              setAddBiddingModal(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <div className="col-md-6 mb-3">
                <label htmlFor="">Bidding Name </label>
                <input
                  type="text"
                  placeholder="Enter Bidding Name"
                  className="form-control border-radius"
                  required
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="">Project Title </label>
                <input
                  type="text"
                  placeholder="Enter Project Title"
                  className="form-control border-radius"
                  required
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="">Link</label>
                <input
                  type="text"
                  placeholder="Enter Link"
                  className="form-control border-radius"
                />
              </div>

              <div className="col-md-6 ">
                <label htmlFor="">Category </label>
                <input
                  type="text"
                  placeholder="Enter Category"
                  className="form-control border-radius"
                />
              </div>

              <div className="col-12 mt-3">
                <label htmlFor="">Project Description </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p></p>"
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                  }}
                />
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className="btn btn-purple border-radius">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}

      {/* View Bidding Document Modal */}
      <Modal isOpen={biddingDocModal} size="lg" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Bidding Document</h5>
          <button
            type="button"
            onClick={() => {
              setBiddingDocModal(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>File Name</th>
                <th className="col-2">Action</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>1</td>
                <td>Portfolio</td>
                <td>Portfolio.pdf</td>
                <td>
                  <a href="#" className="btn btn-sm btn-outline-primary">
                    <i className="fas fa-download"></i>
                  </a>
                </td>
              </tr>

              <tr>
                <td>2</td>
                <td>Portfoli2</td>
                <td>Portfoli2.pdf</td>
                <td>
                  <a href="#" className="btn btn-sm btn-outline-primary">
                    <i className="fas fa-download"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Modal>
      {/* ------------ */}
    </div>
  )
}

const Upwork = () => {
  const onDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete this bidding!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        return
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Bidding Name",
        accessor: "biddingName",
        isInfoIcon: true,
      },
      {
        Header: "Project Title",
        accessor: "projectTitle",
        isInfoIcon: true,
      },
      {
        Header: "Link",
        accessor: "link",
        isInfoIcon: true,
      },
      {
        Header: "Category",
        accessor: "category",
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <Link
                to={`${configure.appUrl}marketing/edit-bidding`}
                title="edit"
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <button
                onClick={onDelete}
                title="delete"
                className="btn btn-sm btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      id: 1,
      biddingName: "Bidding One",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 2,
      biddingName: "Bidding Two",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 3,
      biddingName: "Bidding Three",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 4,
      biddingName: "Bidding Four",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 5,
      biddingName: "Bidding Five",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 6,
      biddingName: "Bidding Six",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 3,
      biddingName: "Bidding Three",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 4,
      biddingName: "Bidding Four",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 5,
      biddingName: "Bidding Five",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
    {
      id: 6,
      biddingName: "Bidding Six",
      projectTitle: "Zithas Canada",
      link: "upwork.com",
      category: "work",
    },
  ]

  return (
    <>
      <div>
        <Row className="ms-0 pe-0">
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>
                <TableContainer columns={columns} data={data} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Upwork
