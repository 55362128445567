import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import moment from "moment"
import configure from "configure"

const WelcomeComp = () => {
  useEffect(() => {
    StaffDetail()
  }, [])

  const [isLoading, setLoading] = useState(false)
  const [details, setDetails] = useState({})
  const [joining_days, setJoining] = useState({})

  const StaffDetail = async e => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/show/${localStorage.getItem("auth_id")}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDetails(response.data.data?.staff)
        setJoining(response.data.data?.joining_days)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Card className="overflow-hidden mb-0">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">
                  {localStorage.getItem("auth_name")}
                </h5>
                <p>{details?.designation}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={`https://mars.zithas.com/application/public/${details?.avatar}`}
                  alt=""
                  className="img-thumbnail rounded-circle"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div className="mt-4 mb-3">
                <Link
                  to={`${configure.appUrl}profile`}
                  className="btn btn-primary  btn-sm"
                >
                  View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div>
              <p className="font-size-18 text-purple mb-20">
                <strong>
                  {" "}
                  {joining_days?.year} Years, {joining_days?.month} Months,{" "}
                  {joining_days?.day} Days in Zithas
                </strong>
              </p>
              <h3 className="bd-company-name">Zithas Technologies</h3>
              <p className=" font-size-16 mb-0 text-purple">
                <strong>Opportunities Blossom, Knowledge Follows.</strong>
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
