import React, { useState } from "react"
import { Container, Row, Col, Form, Table } from "reactstrap"
import { Link } from "react-router-dom"
import Select from "react-select"

const PermissionView = () => {
  const [showSaveBtn, setShowSaveBtn] = useState(false)
  const [Permission, setPermission] = useState(0)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 230) {
      document
        .getElementsByClassName("table-header")[0]
        .classList.add("fixed-header")
      document
        .getElementsByClassName("table-header")[1]
        .classList.add("fixed-header")
      setShowSaveBtn(true)
    } else {
      document
        .getElementsByClassName("table-header")[0]
        .classList.remove("fixed-header")
      document
        .getElementsByClassName("table-header")[1]
        .classList.remove("fixed-header")
      setShowSaveBtn(false)
    }
  })
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const modules = [
    "Dashboard",
    "Opportunity",
    "Leads",
    "Proposal",
    "Contract",
    "Customer",
    "Projects",
    "Task",
    "Support",
    "Timesheet",
    "Report Leads",
    "Report Projects",
    "Staff",
    "Setting Introducer",
    "Freelancer",
    "Consultant",
    "Roles & Permission",
    "Roles & Permission Logs",
    "Login Logs",
    "Blog",
    "Current Opening",
    "Testimonials",
    "Case Studies",
    "Introducer",
    "Setting Email Campaign",
    "Setting Lead",
    "Gallery",
    "Employee Testimonial",
    "Meeting",
    "Festival",
    "Setting Contract",
    "Setting Proposal",
    "Team Members",
    "GIT Logs",
    "Setting Icode",
    "Setting Knowledge Base",
    "Icode",
    "Knowledge Base",
    "Staffguru",
    "Hosting",
    "Setting Hosting",
    "Cold Calling",
    "Requirement",
    "Company",
    "Setting Template ",
    "Setting Mailchimp",
    "Setting Bidding",
    "Markting",
    "Bidding",
    "Meeting Report",
    "Weekly Reports",
    "List Of Holiday",
    "Setting Finance",
    "Expense",
    "Payment",
    "Department",
    "Setting Industry",
    "Zithas UK Blog",
    "Cataloguely Blog",
    "The Staffguru Blog",
    "Credentials",
    "Weekly Report",
    "Resource Plan",
    "Zithas Drive",
    "Payroll Management",
    "Estimate Calculator",
  ]

  const innerModules = [
    "Module 1",
    "Module 2",
    "Module 3",
    "Module 4",
    "Module 5",
  ]

  const countries = [
    { label: "India", value: "1" },
    { label: "United Kingdom", value: "2" },
    { label: "Algeria", value: "3" },
    { label: "American samoa", value: "4" },
  ]

  return (
    <>
      {/* <Row> */}
      {/* <div className="col-md-6">
          <h4>Permission</h4>
        </div> */}
      {/* </Row> */}
      <Row className="">
        {/* <div className="col-md-4">
          <Select
            placeholder="Select Country"
            className="basic-multi-select border-radius"
            classNamePrefix="select"
            styles={colourStyles}
            options={countries}
          />
        </div>
        <h4 className="mb-2 text-center col-md-4">
          <img
            src="https://mars.ztpl.net/assets/images/uk.png"
            alt="india flag"
            width="25px"
            height="18px"
            className="mr-10"
          />
          United Kingdom
        </h4> */}
        <div className="d-flex gap-3 pt-3">
          <div className="form-check mb-2">
            <input
              className="form-check-input input-location"
              type="radio"
              name="exampleRadios1"
              id={`exampleRadios1`}
              checked={Permission == 0 ? true : false}
              onClick={() => setPermission(0)}
            />
            <label className="form-check-label pl-10" htmlFor={`exampleRadios`}>
              <img
                src="https://mars.ztpl.net/assets/images/india.png"
                alt="no img"
                width="28"
                height="18"
                style={{ marginTop: "2px" }}
              />
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input input-location"
              type="radio"
              name="exampleRadios2"
              id={`exampleRadios2`}
              checked={Permission == 1 ? true : false}
              onClick={() => setPermission(1)}
            />
            <label className="form-check-label pl-10" htmlFor={`exampleRadios`}>
              <img
                src="https://mars.ztpl.net/assets/images/uk.png"
                alt="no img"
                width="28"
                height="18"
                style={{ marginTop: "2px" }}
              />
            </label>
          </div>
        </div>
      </Row>
      <div className="responsive-table mt-2 overflow-auto">
        <Table
          className="table table-bordered m-0 text-center mt-4 role-table"
          style={{ position: "relative", zIndex: "99" }}
        >
          <thead className="table-header">
            <tr>
              <th className="text-start ps-4">Module</th>
              <th className="cell-width">Global</th>
              <th className="cell-width">View</th>
              <th className="cell-width">Add</th>
              <th className="cell-width">Update</th>
              <th className="cell-width">Delete</th>
              <th className="cell-width">More</th>
              {showSaveBtn ? (
                <div title="Save" className="saveBtn">
                  <i className="bx bx-conversation me-1"></i>
                </div>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {modules.map((module, i) => {
              return (
                <tr key={i} className="">
                  <td className="p-0" colSpan={7}>
                    <div
                      className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item accordian-box">
                        <div
                          className="accordion-header"
                          id="panelsStayOpen-headingOne"
                        >
                          <button
                            className="accordion-button collapsed accordian-btn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#panelsStayOpen-collapse${i}`}
                            aria-expanded="true"
                            aria-controls={`panelsStayOpen-collapse${i}`}
                          >
                            {module}
                          </button>
                          <div className="outer-toggle-btns">
                            <div className="form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input first_global"
                                id="customSwitchsizemd"
                                defaultChecked="true"
                              />
                            </div>
                            <div className="form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input second_view"
                                id="customSwitchsizemd"
                                defaultChecked="true"
                              />
                            </div>
                            <div className="form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input third_add"
                                id="customSwitchsizemd"
                                defaultChecked="true"
                              />
                            </div>
                            <div className="form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input fourth_edit"
                                id="customSwitchsizemd"
                                defaultChecked="true"
                              />
                            </div>
                            <div className="form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input fifth_delete"
                                id="customSwitchsizemd"
                                defaultChecked="true"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          id={`panelsStayOpen-collapse${i}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingOne"
                        >
                          <div className="accordion-body p-0">
                            <Table className="mb-0 role-table inner-table">
                              {innerModules.map((innerModule, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="border text-start ps-4">
                                      {innerModule}
                                    </td>
                                    <td className="border cell-width ">
                                      <div className=" form-switch form-switch-md text-center">
                                        <input
                                          type="checkbox"
                                          className="form-check-input first_global"
                                          id="customSwitchsizemd"
                                          defaultChecked="true"
                                        />
                                      </div>
                                    </td>
                                    <td className="border cell-width ">
                                      <div className="form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className="form-check-input second_view"
                                          id="customSwitchsizemd"
                                          defaultChecked="true"
                                        />
                                      </div>
                                    </td>
                                    <td className="border cell-width">
                                      <div className="form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className="form-check-input third_add"
                                          id="customSwitchsizemd"
                                          defaultChecked="true"
                                        />
                                      </div>
                                    </td>
                                    <td className="border cell-width">
                                      <div className=" form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className="form-check-input fourth_edit"
                                          id="customSwitchsizemd"
                                          defaultChecked="true"
                                        />
                                      </div>
                                    </td>
                                    <td className="border cell-width">
                                      <div className=" form-switch form-switch-md">
                                        <input
                                          type="checkbox"
                                          className="form-check-input fifth_delete"
                                          id="customSwitchsizemd"
                                          defaultChecked="true"
                                        />
                                      </div>
                                    </td>
                                    <td className="cell-width"></td>
                                  </tr>
                                )
                              })}
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default PermissionView
