import React, { useEffect, useState } from "react"
import "../../assets/css/Profile/Profile.css"
import moment from "moment"

const WorkExperience = ({ profileData }) => {
  let experience = profileData?.work_experience
    ? JSON.parse(profileData?.work_experience)
    : []
  experience = experience.some(item => item.company_name === "")
    ? []
    : experience
  // const [workData, setWorkData] = useState([...experience])
  // console.log("workData", workData)
  // console.log("experience", experience)
  return (
    <div>
      <div className="card border border-2 shadow experience-section h-100">
        <div className="card-body">
          <div className="">
            <h4 className="card-title mb-4 fs-3">Work Experince</h4>
            <ul
              className={` ${
                experience?.length !== 0 &&
                "list-unstyled work-activity mb-0 pl-100"
              } `}
            >
              {experience?.map((item, i) => {
                const fromDateYear = moment(item.from_date).format("YYYY")
                const toDateYear = moment(item.to_date).format("YYYY")
                const forMatedDate = `${fromDateYear}-${toDateYear}`
                return (
                  <li
                    key={i + 1}
                    className="work-item"
                    data-date={forMatedDate}
                  >
                    <h5 className="mb-0">{item.company_name}</h5>
                    <p className="font-size-13 mb-2">{item.position} </p>
                    <p>{item.job_description}</p>
                  </li>
                )
              })}
              {experience?.length == 0 && (
                <div className="text-center py-3">No Work experience found</div>
              )}
              {/* <li className="work-item" data-date="2019-20">
                <h5 className="mb-0">XYZ Company</h5>
                <p className="font-size-13 mb-2">Graphic Designer</p>
                <p className="mb-0">
                  It will be as simple as occidental in fact, it will be
                  Occidental person, it will seem like simplified.
                </p>
              </li>
              <li className="work-item" data-date="2018-19">
                <h5 className="mb-0">BCD Company</h5>
                <p className="font-size-13 mb-2">Graphic Designer</p>
                <p className="mb-0">
                  It will be as simple as occidental in fact, it will be
                  Occidental person, it will seem like simplified.
                </p>
              </li>
              <li className="work-item" data-date="2017-18">
                <h5 className="mb-0">LMNO Company</h5>
                <p className="font-size-13 mb-2">Graphic Designer</p>
                <p className="mb-0">
                  It will be as simple as occidental in fact, it will be
                  Occidental person, it will seem like simplified.
                </p>
              </li>
              <li className="work-item" data-date="2016-17">
                <h5 className="mb-0">PQRS Company</h5>
                <p className="font-size-13 mb-2">Graphic Designer</p>
                <p className="mb-0">
                  It will be as simple as occidental in fact, it will be
                  Occidental person, it will seem like simplified.
                </p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkExperience
