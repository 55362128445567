import React, { useEffect, useState } from "react"
import { Container, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"

const ViewCredential = ({ viewId }) => {
  //   All useState
  const [isLoading, setLoading] = useState([])
  const [custType, setCustType] = useState("")
  const [gitProject, setGitProject] = useState("New")
  const [ztplDetails, setZtplDetails] = useState("New")
  const [localServer, setLocalServer] = useState("New")
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  const [detailsFromCred, setDetailsFromCred] = useState(null)
  const [detailsFromCred2, setDetailsFromCred2] = useState(null)

  const [allZtplCredOption, setAllZtplCredOption] = useState([])
  const [all192CredOption, setAll192CredOption] = useState([])

  const [projectFromGitOption, setProjectFromGitOption] = useState([])
  const [projectFromGit, setProjectFromGit] = useState(null)
  const [projectFromGitName, setProjectFromGitName] = useState("")
  const [gitRepositoryLink, setGitRepositoryLink] = useState("")
  const [gitDemoLink, setGitDemoLink] = useState("")
  const [gitIcodeLink, setGitIcodeLink] = useState("")

  // erver Details states
  const [serverFolderName, setServerFolderName] = useState("")
  const [server192Link, setServer192Link] = useState("")
  const [serverZTPLLink, setServerZTPLLink] = useState("")
  const [zhost, setZhost] = useState("ftp.ztpl.net")
  const [zusername, setZusername] = useState("")
  const [zpassword, setZpassword] = useState("")
  const [zdatabasename, setZdatabasename] = useState("")
  const [zdbusername, setZdbusername] = useState("")
  const [zdbpassword, setZdbpassword] = useState("")

  // 192 Details
  const [host, setHost] = useState("192.168.0.2")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [databasename, setDatabasename] = useState("")
  const [dbusername, setDbusername] = useState("")
  const [dbpassword, setDbpassword] = useState("")
  const [gitProjectId, setGitProjectId] = useState("")

  const [isFolderCreated, setIsFolderCreated] = useState(false)
  const [isFolderCreated192, setIsFolderCreated192] = useState(false)
  const [isDbCreated, setIsDbCreated] = useState(false)
  const [isDbCreated192, setIsDbCreated192] = useState(false)

  const handleZtplDfc = selectedOption => {
    setDetailsFromCred(selectedOption)
    setZusername(selectedOption.FTPUser ? selectedOption.FTPUser : "")
    setZpassword(selectedOption.FTPPassword ? selectedOption.FTPPassword : "")
    setZdatabasename(selectedOption.DBName ? selectedOption.DBName : "")
    setZdbusername(selectedOption.DBUsername ? selectedOption.DBUsername : "")
    setZdbpassword(selectedOption.DBPassword ? selectedOption.DBPassword : "")
  }

  const handle192Dfc = selectedOption => {
    setDetailsFromCred2(selectedOption)
    setUsername(selectedOption.FTPUser ? selectedOption.FTPUser : "")
    setPassword(selectedOption.FTPPassword ? selectedOption.FTPPassword : "")
    setDatabasename(selectedOption.DBName ? selectedOption.DBName : "")
    setDbusername(selectedOption.DBUsername ? selectedOption.DBUsername : "")
    setDbpassword(selectedOption.DBPassword ? selectedOption.DBPassword : "")
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectRelatedData`)
        .then(res => res.json())
        .then(data => {
          const modifyZtpl = data.data.ztpl?.map(item => {
            return {
              label: item.Project,
              value: item.id,
              FTPHost: item.FTPHost,
              FTPUser: item.FTPUser,
              FTPPassword: item.FTPPassword,
              DBName: item.DBName,
              DBUsername: item.DBUsername,
              DBPassword: item.DBPassword,
            }
          })
          const modify192 = data.data.data_192?.map(item => {
            return {
              label: item.Project,
              value: item.id,
              FTPHost: item.FTPHost,
              FTPUser: item.FTPUser,
              FTPPassword: item.FTPPassword,
              DBName: item.DBName,
              DBUsername: item.DBUsername,
              DBPassword: item.DBPassword,
            }
          })
          const modifyArr = data?.data?.projects?.map(item => {
            return {
              label: item.project_name,
              value: item.id,
              gitreposatirylink: item.gitreposatirylink,
              demolink: item.demolink,
              icode: item.icode,
              git_project_id: item.git_project_id,
            }
          })

          setProjectFromGitOption(modifyArr)
          setAllZtplCredOption(modifyZtpl)
          setAll192CredOption(modify192)
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onFolderCreate = async e => {
    e.preventDefault()
    if (serverFolderName) {
      setLoading(true)

      const formData = new FormData()
      formData.append("folderName", serverFolderName)
      try {
        const response = await axios({
          method: "post",
          url: `https://ftpdbtools.ztpl.net/create_folder.php`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response?.data?.status === "success") {
          setLoading(false)
          setIsFolderCreated(true)
          Swal.fire({
            icon: "success",
            text: "Folder Created successful.",
          })
        } else if (response?.data?.status === "error") {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${response?.data?.message}`,
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            text: `Something went wrong.`,
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select Folder Name.",
      })
    }
  }

  const onFolderCreate2 = async e => {
    e.preventDefault()
    if (serverFolderName) {
      setLoading(true)

      const formData = new FormData()
      formData.append("folderName", serverFolderName)
      try {
        const response = await axios({
          method: "post",
          url: `http://192.168.0.2:5001/jhfghffhj.php`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        console.log("5001", response)
        if (response?.data?.status === "true") {
          setLoading(false)
          setIsFolderCreated192(true)
          Swal.fire({
            icon: "success",
            text: "Folder Created successful.",
          })
        } else if (response?.data?.status === "false") {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${response?.data?.message}`,
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            text: `Something went wrong.`,
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select Folder Name.",
      })
    }
  }

  const onFtpCreated = async e => {
    e.preventDefault()
    if (isFolderCreated) {
      if (zusername) {
        setLoading(true)
        const formData = new FormData()
        // folderName,ftpUser
        formData.append("ftpUser", zusername)
        formData.append("folderName", serverFolderName)
        try {
          const response = await axios({
            method: "post",
            url: `https://ftpdbtools.ztpl.net/create_ftp.php`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          if (response?.data?.success) {
            console.log("response", response)
            setLoading(false)
            setZpassword(response?.data?.ftpPassword)
            Swal.fire({
              icon: "success",
              text: "FTP Created successful.",
            })
          } else {
            setLoading(false)
            Swal.fire({
              icon: "warning",
              text: `${
                response?.data?.error
                  ? response?.data?.error
                  : "Something went wrong."
              }`,
            })
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please select FTP User Name.",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please Create folder first.",
      })
    }
  }

  const onFtpCreated2 = async e => {
    e.preventDefault()
    // if (isFolderCreated192) {
    if (username) {
      setLoading(true)
      const formData = new FormData()
      // folderName,ftpUser
      formData.append("userName", username)
      formData.append("folderName", serverFolderName)
      try {
        const response = await axios({
          method: "post",
          url: `http://192.168.0.2:5001/create_ftp.php`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        console.log("response", response)
        if (response?.data?.success) {
          setLoading(false)
          setPassword(response?.data?.ftpPassword)
          Swal.fire({
            icon: "success",
            text: "FTP Created successful.",
          })
        } else {
          setLoading(false)
          Swal.fire({
            icon: "warning",
            text: `${
              response?.data?.error
                ? response?.data?.error
                : "Something went wrong."
            }`,
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select FTP User Name.",
      })
    }

    // } else {
    //   Swal.fire({
    //     icon: "warning",
    //     text: "Please Create folder first.",
    //   })
    // }
  }

  // const onFtpCreated2 = async e => {
  //   e.preventDefault()
  //   if (host != "" && username != "" && password != "") {
  //     setLoading(true)
  //     const formData = new FormData()
  //     formData.append("host", host)
  //     formData.append("username", username)
  //     formData.append("password", password)
  //     formData.append("type", 2)
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: `http://192.168.0.2:5001/create_ftp.php`,
  //         data: formData,
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       if (response) {
  //         setLoading(false)
  //         Swal.fire({
  //           icon: "success",
  //           text: "FTP Created successful.",
  //         })
  //       }
  //     } catch (error) {
  //       setLoading(false)
  //       console.log(error)
  //     }
  //   } else {
  //     Swal.fire({
  //       icon: "warning",
  //       text: "Please select all mandatory field.",
  //     })
  //   }
  // }

  const onDbCreated = async e => {
    e.preventDefault()
    if (zdatabasename && zdbusername) {
      setLoading(true)
      const formData = new FormData()
      formData.append("dbName", zdatabasename)
      formData.append("dbUser", zdbusername)

      try {
        const response = await axios({
          method: "post",
          url: `https://ftpdbtools.ztpl.net/create_dbs.php`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          console.log("res", response)
          setLoading(false)
          if (response?.data?.status == "true") {
            setZdbpassword(response?.data?.password)
            Swal.fire({
              icon: "success",
              text: "Database Created successful.",
            })
          } else {
            Swal.fire({
              icon: "warning",
              text: `${response?.data?.message}`,
            })
          }
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }
  const onDbCreated2 = async e => {
    e.preventDefault()
    if (databasename && dbusername) {
      setLoading(true)
      const formData = new FormData()
      formData.append("dbName", databasename)
      formData.append("userName", dbusername)

      try {
        const response = await axios({
          method: "post",
          url: `http://192.168.0.2:5001/create_db.php`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          console.log("res", response)
          setLoading(false)
          if (response?.data?.status == "true") {
            setDbpassword(response?.data?.password)
            Swal.fire({
              icon: "success",
              text: "Database Created successful.",
            })
          } else {
            Swal.fire({
              icon: "warning",
              text: `${response?.data?.message}`,
            })
          }
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const fetchAllFunc = async () => {
    try {
      await fetchData()
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchAllFunc().then(async () => {
          await fetchData5()
        })
      } catch (error) {
        console.error("Error in useEffect:", error)
      }
    }

    loadData()
  }, [])

  const fetchData5 = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectView/${viewId}`)
        .then(res => res.json())
        .then(async response => {
          setLoading(false)
          const modifyArr = response?.data?.project2?.map(item => {
            return {
              label: item.project_name,
              value: item.id,
              gitreposatirylink: item.gitreposatirylink,
              demolink: item.demolink,
              icode: item.icode,
              git_project_id: item.git_project_id,
            }
          })

          // setProjectFromGitOption(modifyArr)
          const filterEditData = response?.data?.project2?.find(item => {
            return item.id == viewId
          })
          setGitProjectId(filterEditData?.git_project_id)
          setGitRepositoryLink(filterEditData?.gitreposatirylink)
          setGitDemoLink(filterEditData?.demolink)
          setGitIcodeLink(filterEditData?.icode)
          setServerFolderName(filterEditData?.folder_name)
          setServer192Link(filterEditData?.link_192)
          setServerZTPLLink(filterEditData?.ztpllink)
          setZhost(filterEditData?.zhostlink)
          setZusername(filterEditData?.zusername)
          setZpassword(filterEditData?.zpassword)
          setZdatabasename(filterEditData?.zdatabasename)
          setZdbusername(filterEditData?.zdbusername)
          setZdbpassword(filterEditData?.zdbpassword)
          setHost(filterEditData?.hostlink)
          setUsername(filterEditData?.username)
          setPassword(filterEditData?.password)
          setDatabasename(filterEditData?.databasename)
          setDbusername(filterEditData?.dbusername)
          setDbpassword(filterEditData?.dbpassword)
          setLoading(false)
          // ................................................................
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  // setProjectFromGitOption

  const fetchProjectOption = async () => {}

  // Api Integration

  // Git Details states

  const handleGitPeoject = selectedOption => {
    setProjectFromGit(selectedOption)
    setGitRepositoryLink(
      selectedOption.gitreposatirylink ? selectedOption.gitreposatirylink : ""
    )
    // setGitDemoLink(selectedOption.demolink)
    // setGitIcodeLink(selectedOption.icode)
  }

  const handleFolder = e => {
    setServerFolderName(e.target.value)
    if (e.target.value) {
      setServer192Link(`http://192.168.0.2/${e.target.value}`)
      setServerZTPLLink(`https://ztpl.net/${e.target.value}`)
    } else {
      setServer192Link("")
      setServerZTPLLink("")
    }
  }

  const handleSubmitBasicDetails = async e => {
    e.preventDefault()

    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("role_id", localStorage.getItem("auth_role"))
    formData.append("project_id", viewId)
    formData.append("git_project_id", gitProjectId)

    // Server Details

    formData.append("folder_name", serverFolderName)
    formData.append("link_192", server192Link)
    formData.append("ztpl_link", serverZTPLLink)

    // git details

    // formData.append("git_status", custType == "Yes" ? 1 : 0)
    // formData.append("projectRadios", gitProject === "New" ? 1 : 0)
    // formData.append("git_project", projectFromGitName)
    // formData.append(
    //   "existing_git_project",
    //   projectFromGit?.value ? projectFromGit?.value : ""
    // )

    formData.append("gitrepositorylink", gitRepositoryLink)
    formData.append("demo_link", gitDemoLink)
    formData.append("icode_link", gitIcodeLink)

    // ztpl details

    formData.append("ztpl_details", ztplDetails == "New" ? 1 : 0)
    formData.append("zhost", zhost)
    formData.append("zusername", zusername)
    formData.append("zpassword", zpassword)
    formData.append("zdatabasename", zdatabasename)
    formData.append("zdbusername", zdbusername)
    formData.append("zdbpassword", zdbpassword)

    // 192 details

    formData.append("192_details", localServer == "New" ? 1 : 0)
    formData.append("host", host)
    formData.append("username", username)
    formData.append("password", password)
    formData.append("databasename", databasename)
    formData.append("dbusername", dbusername)
    formData.append("dbpassword", dbpassword)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/editProjectCredentials`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        Swal.fire({
          icon: "success",
          title: "Project Edited successfully.",
        }).then(() => {
          navigate(`${configure.appUrl}work/projects`)
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className=" mb-3">
        <Container fluid>
          <div >
            <Row className="">
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button
                  type="submit"
                  className="btn btn-save  fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 icon-size label-icon"></i>
                  <span>Submit</span>
                </button>

                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </button>
              </div>
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="col-md-12 d-flex justify-content-between">
                  <div className="col-md-6 d-flex align-items-center">
                    <h5 className=" text-dark mb-0">
                      Project Credential Details
                    </h5>
                  </div>
                  <div className="col-md-6 text-end">
                    <button
                      type="submit"
                      className=" btn ms-md-2 btn-save btn-label"
                    >
                      <i className="fas fa-save me-1 label-icon"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={e => {
                        e.preventDefault()
                        navigate(-1)
                      }}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </button>
                  </div>
                </div>
              </Row>
              <Row className="px-0">
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">
                      Git Details (if yes then it will update on git)
                    </h5>
                    {/* <div className="col-12 mb-3 mb-md-0">
                      <span className="me-4 ">
                        <input
disabled
                          type="radio"
                          id="gitYes"
                          onInput={e => {
                            setCustType("Yes")
                            setGitProject("New")
                            setProjectFromGit(null)
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="Yes"
                        />
                        <label htmlFor="gitYes" className="ms-2">
                          Yes
                        </label>
                      </span>
                      <span className="me-4 ">
                        <input
disabled
                          type="radio"
                          id="gitNo"
                          defaultChecked={true}
                          onInput={e => {
                            setProjectFromGitName("")
                            setCustType(e.target.value)
                          }}
                          name="type"
                          value="No"
                        />
                        <label htmlFor="gitNo" className="ms-2">
                          No
                        </label>
                      </span>
                    </div> */}
                    {/* {custType === "Yes" ? (
                      <div className="col-12 mb-3 mb-md-0">
                        <span className="me-4 ">
                          <input
disabled
                            type="radio"
                            id="newCheck"
                            defaultChecked={true}
                            onInput={e => {
                              setProjectFromGit(null)
                              setGitProject(e.target.value)
                            }}
                            name="type1"
                            value="New"
                          />
                          <label htmlFor="newCheck" className="ms-2">
                            New
                          </label>
                        </span>
                        <span>
                          <input
disabled
                            type="radio"
                            onInput={e => {
                              setProjectFromGitName("")
                              setGitProject(e.target.value)
                            }}
                            id="existing"
                            name="type1"
                            value="Existing"
                          />
                          <label htmlFor="existing" className="ms-2">
                            {" "}
                            Existing
                          </label>
                        </span>
                      </div>
                    ) : (
                      ""
                    )} */}
                    {/* {custType === "Yes" && gitProject === "New" ? (
                      <div className="col-md-12 mt-2 mb-3 mb-md-0">
                        <label htmlFor="">Project Name (for git)</label>
                        <input
disabled
                          type="text"
                          placeholder="Enter Project Name"
                          className="form-control "
                          value={projectFromGitName}
                          onChange={e => setProjectFromGitName(e.target.value)}
                        />
                      </div>
                    ) : ( */}
                    
                    {/* )} */}
                    <div className="col-md-12 mt-4">
                      <h5 className="mb-3">Links</h5>
                      <div className="col-md-12 d-flex flex-column gap-3">
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Git Repository Link</label>
                          <input
                            disabled
                            type="text"
                            placeholder="Enter Git Repository Link"
                            className="form-control "
                            value={gitRepositoryLink}
                            onChange={e => setGitRepositoryLink(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Demo Link</label>
                          <input
                            disabled
                            type="text"
                            placeholder="Enter Demo Link"
                            className="form-control "
                            value={gitDemoLink}
                            onChange={e => setGitDemoLink(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12 mt-2 mb-3 mb-md-0">
                          <label htmlFor="">Icode Link</label>
                          <input
                            disabled
                            type="text"
                            placeholder="Enter Icode Link"
                            className="form-control "
                            value={gitIcodeLink}
                            onChange={e => setGitIcodeLink(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-3 pb-4">
                  <Row className="bg-white d-flex flex-column gap-4 px-2 py-4 shadow mb-3">
                    <div className="d-flex justify-space-between align-items-end">
                      <div className="col-md-3 mt-2  mb-md-0 d-flex justify-content-start align-items-start">
                        <h5 className="mb-3">Server Details</h5>
                      </div>
                      {/* <div className="col-md-9 mt-2  mb-md-0 d-flex justify-content-end align-items-center">
                        {isFolderCreated192 === false && (
                          <button
                            className=" btn ms-md-2 btn-save btn-label"
                            type="button"
                            onClick={e => onFolderCreate2(e)}
                          >
                            <i className="bi bi-folder-fill me-1 label-icon"></i>
                            Create Folder in 192
                          </button>
                        )}

                        {isFolderCreated === false && (
                          <button
                            className=" btn ms-md-2 btn-save btn-label"
                            type="button"
                            onClick={e => onFolderCreate(e)}
                          >
                            <i className="bi bi-folder-fill me-1 label-icon"></i>
                            Create Folder in ztpl
                          </button>
                        )}
                      </div> */}
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        Enter Folder Name for creating folder on 192 & ZTPL{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        required
                        type="text"
                        placeholder="Enter Folder Name"
                        className="form-control "
                        value={serverFolderName}
                        onChange={e => handleFolder(e)}
                      />
                    </div>

                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        192 server Link <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        readOnly
                        required
                        type="text"
                        placeholder="Enter 192 server Link"
                        className="form-control"
                        value={server192Link}
                        onChange={e => setServer192Link(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        ZTPL Link <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        readOnly
                        required
                        type="text"
                        placeholder="Enter ZTPL Link"
                        className="form-control"
                        value={serverZTPLLink}
                        onChange={e => setServerZTPLLink(e.target.value)}
                      />
                    </div>
                  </Row>
                </div>
              </Row>
              {/* Database forms */}
              <Row className="px-0">
                {/* .. ZTPL Details  */}
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">ZTPL Details</h5>
                    <div className="row">
                      {/* <div className="col-md-6 mb-2 d-flex">
                        <div className="col-md-6 mb-3 mb-md-0">
                          <span className="me-4 ">
                            <input
disabled
                              defaultChecked={true}
                              type="radio"
                              id="new"
                              onInput={e => setZtplDetails(e.target.value)}
                              name="ztpl"
                              value="New"
                            />
                            <label htmlFor="new" className="ms-2">
                              New
                            </label>
                          </span>
                          <span>
                            <input
disabled
                              type="radio"
                              onInput={e => setZtplDetails(e.target.value)}
                              id="existing2"
                              name="ztpl"
                              value="existing"
                            />
                            <label htmlFor="existing2" className="ms-2">
                              {" "}
                              Existing
                            </label>
                          </span>
                        </div>
                      </div> */}
                      {/* {ztplDetails === "existing" && ( */}
                      <div className="col-md-6 mb-md-0 pe-0">
                      </div>
                      {/* )} */}
                      {/* {ztplDetails == "New" ? (
                        <div className="col-md-6 mb-3 d-flex justify-content-end align-items-end">
                          <div className="col-md-6 col-sm-4">
                            <button
                              onClick={onFtpCreated}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-person-fill-add label-icon"></i>{" "}
                              Create FTP
                            </button>
                          </div>

                          <div className="col-md-6 col-sm-4">
                            <button
                              onClick={onDbCreated}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-database-fill-add label-icon"></i>{" "}
                              Create DB
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>

                    <div className="col-md-6 border-end">
                      {/* {ztplDetails == "New" ? (
                        <>
                          <div className="col-md-12 mt-3 mb-4 mb-md-0">
                            <label htmlFor="">
                              FTP Host Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
disabled
                              required
                              readOnly
                              type="text"
                              placeholder="Host Link"
                              className="form-control "
                              value={zhost}
                              onChange={e => setZhost(e.target.value)}
                            />
                          </div>
                        </>
                      ) : ( */}
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Host Name <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          readOnly
                          type="text"
                          placeholder="Enter Host Link"
                          className="form-control "
                          value={zhost}
                          onChange={e => setZhost(e.target.value)}
                        />
                      </div>
                      {/* )} */}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zusername}
                          onChange={e => setZusername(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          readOnly
                          type="text"
                          placeholder="FTP Password"
                          className="form-control "
                          value={zpassword}
                          onChange={e => setZpassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={zdatabasename}
                          onChange={e => setZdatabasename(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={zdbusername}
                          onChange={e => setZdbusername(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          readOnly
                          type="text"
                          placeholder="Database Password"
                          className="form-control "
                          value={zdbpassword}
                          onChange={e => setZdbpassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </Row>
                </div>

                {/* 192 Details */}
                <div className="col-md-6 ps-md-3">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <h5 className="mb-3">192 Details</h5>
                    <div className="row">
                      {/* <div className="col-md-6 mb-2 d-flex">
                        <div className="col-md-6 mb-3 mb-md-0">
                          <span className="me-4 ">
                            <input
disabled
                              type="radio"
                              id="new1"
                              defaultChecked={true}
                              onInput={e => setLocalServer(e.target.value)}
                              name="local"
                              value="New"
                            />
                            <label htmlFor="new1" className="ms-2">
                              New
                            </label>
                          </span>
                          <span>
                            <input
disabled
                              // defaultChecked={true}
                              type="radio"
                              onInput={e => setLocalServer(e.target.value)}
                              id="existing5"
                              name="local"
                              value="existing"
                            />
                            <label htmlFor="existing5" className="ms-2">
                              {" "}
                              Existing
                            </label>
                          </span>
                        </div>
                      </div> */}

                      {/* {localServer === "existing" && ( */}
                 
                      {/* )} */}
                      {/* {localServer == "New" ? (
                        <div className="col-md-6 mb-3 d-flex justify-content-end align-items-end">
                          <div className="col-md-6 col-sm-4">
                            <button
                              onClick={onFtpCreated2}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-person-fill-add label-icon"></i>{" "}
                              Create FTP
                            </button>
                          </div>
                          <div className="col-md-6 col-sm-4">
                            <button
                              onClick={onDbCreated2}
                              className=" btn ms-md-2 btn-save btn-label"
                            >
                              <i className="bi bi-database-fill-add label-icon"></i>{" "}
                              Create DB
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>

                    <div className="col-md-6 border-end">
                      {localServer == "New" ? (
                        <>
                          <div className="col-md-12 mt-3 mb-4 mb-md-0">
                            <label htmlFor="192host">
                              FTP Host Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              disabled
                              required
                              readOnly
                              id="192host"
                              type="text"
                              placeholder="Host Link"
                              className="form-control "
                              value={host}
                              onChange={e => setHost(e.target.value)}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-md-12 mt-3 mb-4 mb-md-0">
                          <label htmlFor="">
                            FTP Host Name <span className="text-danger">*</span>
                          </label>
                          <input
                            disabled
                            required
                            readOnly
                            type="text"
                            placeholder="Enter Host Link"
                            className="form-control "
                            value={host}
                            onChange={e => setHost(e.target.value)}
                          />
                        </div>
                      )}

                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP User Name <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={username}
                          onChange={e => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          FTP Password <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          readOnly
                          type="text"
                          placeholder="FTP Password"
                          className="form-control "
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Name <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          type="text"
                          placeholder="Enter Database Name"
                          className="form-control "
                          value={databasename}
                          onChange={e => setDatabasename(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database User Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          type="text"
                          placeholder="Enter User Name"
                          className="form-control "
                          value={dbusername}
                          onChange={e => setDbusername(e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 mt-3 mb-4 mb-md-0">
                        <label htmlFor="">
                          Database Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          required
                          readOnly
                          type="text"
                          placeholder="Database Password"
                          className="form-control "
                          value={dbpassword}
                          onChange={e => setDbpassword(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* </div> */}
                  </Row>
                </div>
              </Row>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewCredential
