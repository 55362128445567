import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Table, Modal } from "reactstrap"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import axios from "axios"
import Swal from "sweetalert2"

const Documents = ({ setverticalActiveTab }) => {
  const [isLoading, setLoading] = useState(false)
  const [staffId, setStaffId] = useState(localStorage.getItem("staffId"))
  const [data, setData] = useState([])
  const [file, setFile] = useState("")
  const [requirementType, setRequirementType] = useState("")
  const [name, setName] = useState("")
  const [editName, setEditName] = useState("")
  const [editDocument, setEditDocument] = useState("")
  const [editDocumentData, setEditDocumentData] = useState({})

  const navigate = useNavigate()
  const docType = [
    { label: "Enrolling Documents", value: "Enrolling Documents" },
    { label: "Zithas Documents", value: "Zithas Documents" },
  ]

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", localStorage.getItem("staffId"))
    formData.append("file", file)
    formData.append("requirement_type", requirementType)
    formData.append("name", name)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addStaffDocument`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      console.log("addStaffDocument", response)
      if (response) {
        // console.log(response)
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff Documents details Added Successfully",
        }).then(() => {
          setData(response?.data?.data)
        })
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  const getDocuments = async () => {
    const formData = new FormData()
    formData.append("staff_id", localStorage.getItem("staffId"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/showStaffDocument`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log("showstaffdocument", response)
        setData(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    getDocuments()
  }, [])

  const deleteDocument = id => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You want to delete document.",
    }).then(async willOkay => {
      if (willOkay.isConfirmed) {
        const formData = new FormData()
        formData.append("staff_id", localStorage.getItem("staffId"))
        formData.append("file_id", id)

        try {
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}/deleteStaffDocument`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          if (response) {
            Swal.fire({
              icon: "success",
              text: "Document deleted successfully !",
            }).then(() => {
              setData(response?.data?.data)
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  const downloadImage = imagelink => {
    console.log("link", imagelink)
    var link = document.createElement("a")
    link.href = imagelink
    link.download = "image.jpg"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onEditDocument = ob => {
    setEditDocument(true)
    setEditName(ob.file_name)
    console.log("editDocumentData", ob)
  }
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 p-4 bg-white shadow">
            <Row>
              <div className="col-md-3">
                <h4 className="text-dark mb-3">Document Upload</h4>
              </div>
              <div className="col-md-9  text-md-end">
                <button className=" btn text-white  me-2 btn-save btn-label">
                  <i className="bi bi-cloud-arrow-up me-2 label-icon"></i>Upload
                </button>
                {/* <button
                  className="btn text-white me-2 btn-save btn-label"
                  type="button"
                >
                  <i className="bi bi-cloud-arrow-up me-2 label-icon"></i>
                  Generate Documents
                </button> */}
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className=" btn text-white  btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>

            <div className="p-4 bg-white ">
              <Row>
                <div className="col-md-3 mb-4">
                  <label htmlFor="type">Document Name</label>
                  <input
                    type="text"
                    className="form-control "
                    id="type"
                    placeholder="Enter Document Name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <label htmlFor="type">Document Type</label>
                  <Select
                    placeholder="Select Document Type"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    options={docType}
                    onChange={e => setRequirementType(e.value)}
                  />
                </div>
                <div className="col-md-4 mb-4 ">
                  <label htmlFor="">Upload Document</label>
                  <input
                    type="file"
                    className="form-control "
                    id="document"
                    onChange={e => setFile(e.target.files[0])}
                  />
                </div>
              </Row>
              <Row className="mt-4">
                <div className="col-12">
                  <Table className="table table-striped table-bordered table-hover shadow">
                    <thead>
                      <tr>
                        <th className="col-1 text-center">
                          Id{" "}
                          <i
                            title="Document Id"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-5">
                          Name{" "}
                          <i
                            title="Document Name"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-4">
                          type{" "}
                          <i
                            title="Document Type"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                        <th className="col-2">
                          Action{" "}
                          <i
                            title="Document Actions"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((ob, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">1</td>
                            <td>{ob.file_name}</td>
                            <td>{ob.requirement_type}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm me-1 btn-outline-secondary"
                                // onClick={() => deleteDocument(ob.id)}
                                onClick={() => {
                                  onEditDocument(ob)
                                }}
                                title="Edit Document"
                              >
                                <i className="fas fa-pen-alt"></i>
                              </button>
                              <a
                                // type="button"
                                href={ob.link}
                                target="_blank"
                                className="btn btn-sm me-1 btn-outline-secondary"
                                // onClick={() => downloadImage(ob.link)}
                                title="Download Document"
                                rel="noreferrer"
                              >
                                <i className="bi bi-file-earmark-arrow-down"></i>
                              </a>
                              <button
                                type="button"
                                className="btn btn-sm  btn-outline-danger"
                                onClick={() => deleteDocument(ob.id)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                      {data.length == 0 && (
                        <tr>
                          <td colSpan={8} className="text-center">
                            {" "}
                            No Document Found
                          </td>
                        </tr>
                      )}

                      {/* <tr>
                        <td className="text-center">2</td>
                        <td>Pan card</td>
                        <td>Enrolling</td>
                        <td>
                          <button className="btn btn-sm me-1 btn-outline-secondary">
                            <i className="bi bi-file-earmark-arrow-down"></i>
                          </button>
                          <button className="btn btn-sm  btn-outline-danger">
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">3</td>
                        <td>Ration card</td>
                        <td>Enrolling</td>
                        <td>
                          <button className="btn btn-sm action-btn me-1 btn-outline-secondary">
                            <i className="bi bi-file-earmark-arrow-down"></i>
                          </button>
                          <button className="btn btn-sm action-btn btn-outline-danger">
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
          </div>
        </form>

        {/* Edit document  */}
        <Modal isOpen={editDocument} size="lg" className="" centered>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Document</h5>
            <button
              type="button"
              onClick={() => {
                setEditDocument(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 bg-white shadow">
                <div className="d-flex justify-content-between">
                  <div className="col-md-3">
                    <h4 className="text-dark mb-3">Document Update</h4>
                  </div>
                  <div className="text-md-end">
                    <button className=" btn text-white  me-2 btn-save btn-label">
                      <i className="bi bi-cloud-arrow-up me-2 label-icon"></i>
                      Update Document
                    </button>
                  </div>
                </div>

                <div className="p-4 bg-white ">
                  <Row>
                    <div className="col-md-4 mb-4">
                      <label htmlFor="type">Document Name</label>
                      <input
                        type="text"
                        className="form-control "
                        id="type"
                        placeholder="Enter Document Name"
                        value={editName}
                        onChange={e => setEditName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label htmlFor="type">Document Type</label>
                      <Select
                        placeholder="Select Document Type"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={docType}
                        onChange={e => setRequirementType(e.value)}
                      />
                    </div>
                    <div className="col-md-4 mb-4 ">
                      <label htmlFor="">Upload Document</label>
                      <input
                        type="file"
                        className="form-control "
                        id="document"
                        onChange={e => setFile(e.target.files[0])}
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Documents
