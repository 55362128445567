import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Table } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"

const RolesAndpermission = ({ id }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [staffId, setStaffId] = useState(localStorage.getItem("staffId"))
  const [isLoading, setLoading] = useState(false)
  const [shortDec, setShortDec] = useState("")
  const [detailDec, setDetailDec] = useState("")
  const [areasDec, setAreasDec] = useState("")

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", id)
    formData.append("short_kpi", shortDec)
    formData.append("detail_kpi", detailDec)
    formData.append("roles_responsibility", areasDec)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/updaterolesres`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff Roles and Responsibility details Edited Successfully",
        }).then(data => {
          // setverticalActiveTab("3")
        })
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const getCtaDetails = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const item = response.data.data.filter(temp => temp.id == id)[0]
        console.log("Roles & Responsibility 2", item)
        if (item?.short_kpi !== null) {
          setShortDec(item?.short_kpi)
        }
        if (item?.detail_kpi !== null) {
          setDetailDec(item?.detail_kpi)
        }
        if (item?.roles_responsibility !== null) {
          setAreasDec(item?.roles_responsibility)
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getCtaDetails()
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Form onSubmit={handleSubmit}>
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </button>
          </div>
          <div className="mb-3 p-4 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark above">Roles & Responsibility</h4>
              </div>
              <div className="col-md-6  text-md-end">
                <button className=" btn text-white me-md-0 me-2 btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>SAVE
                </button>
                <button
                  onClick={() => navigate(-1)}
                  className=" btn text-white ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>
            {/* <h5 className="mb-4 mt-3 text-dark">Short</h5> */}
            <Row className="mt-3">
              <div className="col-md-12 mb-3">
                <label htmlFor="">
                  Key Performance Indicator - Short
                  <i
                    title="Key Performance Indicator - Short"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={shortDec}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setShortDec(data)
                  }}
                />
              </div>
            </Row>
          </div>

          <div className="mb-3 p-4 bg-white shadow">
            {/* <h5 className="mb-4 text-dark">Detail</h5> */}
            <Row>
              <div className="col-md-12 mb-3">
                <label htmlFor="">
                  Key Performance Indicator - Detail
                  <i
                    title="Employee State Insurance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={detailDec}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setDetailDec(data)
                  }}
                />
              </div>
            </Row>
          </div>

          <div className="mb-3 p-4 bg-white shadow">
            {/* <h5 className="mb-4 text-dark">Areas</h5> */}
            <Row>
              <div className="col-md-12 mb-3 mb-md-0">
                <label htmlFor="">
                  Key Responsiblity Areas
                  <i
                    title="Per Hour Rate"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data={areasDec}
                  style={{ height: "500px" }}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setAreasDec(data)
                  }}
                />
              </div>
            </Row>
          </div>
        </Form>
      </div>
    </>
  )
}

export default RolesAndpermission
