import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Row,
    Form
} from "reactstrap";

// editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// assets
import '../../../../assets/css/Sales/Sales.css';

const Profile = () => {
    const [fixButtons, setFixButtons] = useState(false);

    const navigate = useNavigate();

    document.addEventListener('scroll', () => {
        if (window.scrollY > 160) {
            setFixButtons(true);
        }
        else {
            setFixButtons(false);
        }
    })

    return (
        <React.Fragment>
            <div className="mb-3" style={{ padding: '0 11px 0 12px' }}>
                <Form>
                    {/* Fixed Buttons */}
                    <div className={`flex-column fixed-buttons ${fixButtons ? 'd-flex' : 'd-none'}`}>
                        <button className='btn btn-purple border-radius fix-btn'>
                            <i className='fas fa-save me-2'></i>
                            <span>Save</span>
                        </button>
                        <a onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                            <i className='dripicons-cross me-2 icon-center'></i>
                            <span>Cancel</span>
                        </a>
                    </div>

                    <Row className='bg-white ps-4 py-4 shadow mb-3'>
                        <div className="col-md-12 mb-3 text-end">
                            <button className="border-radius btn ms-md-2 btn-purple">
                                <i className='fas fa-save me-1'></i> save
                            </button>
                            <button onClick={(e) => {
                                e.preventDefault()
                                navigate(-1)
                            }} className="border-radius btn ms-md-2 btn-red"><i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                        </div>
                        <Row className='px-0 mt-2'>
                            <div className='col-md-3 mb-md-0 mb-3'>
                                <label htmlFor="">Bidding Name </label>
                                <input type="text" placeholder='Enter Bidding Name' className='form-control border-radius' required />
                            </div>

                            <div className='col-md-3 mb-md-0 mb-3'>
                                <label htmlFor="">Project Title </label>
                                <input type="text" placeholder='Enter Project Title' className='form-control border-radius' required />
                            </div>

                            <div className='col-md-3 mb-md-0 mb-3'>
                                <label htmlFor="">Link</label>
                                <input type="text" placeholder='Enter Link' className='form-control border-radius' />
                            </div>

                            <div className='col-md-3 mb-md-0 mb-3'>
                                <label htmlFor="">Category </label>
                                <input type="text" placeholder='Enter Category' className='form-control border-radius' />
                            </div>

                            <div className='col-12 mt-4'>
                                <label htmlFor="">Project Description </label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data="<p></p>"
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                    }}
                                />
                            </div>
                        </Row>
                    </Row>

                </Form>
            </div>
        </React.Fragment>
    )
}

export default Profile