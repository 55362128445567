import React, { useEffect, useState } from "react"
import { Container, Form } from "reactstrap"
import Breadcrumbs from "../../../../src/components/Common/Breadcrumb"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"
import Select from "react-select"
// common component
import Pagination from "components/Common/Pagination"
import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"

const statusOptions = [
  { label: "In Progress", value: "0" },
  { label: "Voicemail but not given", value: "1", isModal: true },
  { label: "Not interested", value: "2", isModal: true },
  { label: "No received", value: "3", isModal: false },
  { label: "Some wrong number", value: "4", isModal: false },
  {
    label: "Gave a specific time to talk( mention the time and date)",
    value: "5",
    isModal: true,
  },
  {
    label: "Call connected but some digital person was speaking",
    value: "6",
    isModal: false,
  },
  { label: "Call the head office", value: "7", isModal: true },
  { label: "Voicemail given", value: "8", isModal: false },
  { label: "No option to talk with anyone", value: "9", isModal: false },
  { label: "Call Later", value: "10", isModal: true },
  { label: "They don't need it", value: "11", isModal: true },
  { label: "Owner not available", value: "12", isModal: true },
  { label: "Number not in service", value: "13", isModal: false },
  { label: "They will call us back", value: "14", isModal: false },
  { label: "They have it", value: "15", isModal: true },
  { label: "Others", value: "16", isModal: true },
]

function TableContainer({ columns, data, setAllColdData }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [loading, setLoading] = useState(false)

  const [addFilterModal, setAddFilterModal] = useState(false)
  const [filterName, setFilterName] = useState("")
  const [filterEmail, setFilterEmail] = useState("")
  const [filterPhone, setFilterPhone] = useState("")

  const [allFilteredData, setAllFilteredData] = useState([])

  const location = useLocation()

  const locationData = location.state?.data

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const onFilterData = async () => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("name", filterName)
      formData.append("email", filterEmail)
      formData.append("phone", filterPhone)
      formData.append("cold_id", locationData.id)
      // formData.append("user_id", localStorage.getItem("auth_id"))

      const response = await axios.post(
        `${configure.apiUrl}/coldcalling_filter`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // console.log("filtered Data", response)
      if (response.data.data != "No Data found")
        setAllFilteredData(response.data.data)

      setLoading(false)
    } catch (error) {
      console.log(error)
      swal("Something Went Wrong", "", "error")
      setLoading(false)
    }
  }

  const onAddRow = opp_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to Add this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("cold_id", locationData.id)
        formData.append("opportunity_id", opp_id)

        axios
          .post(`${configure.apiUrl}/add_coldcalling_filter`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            // console.log("added", response.data)

            if (response.data.data.length > 0) {
              setAllColdData(response.data.data)
            }

            swal("Data Added successfully!", {
              icon: "success",
            })
          })
      }
    })
  }

  // Render the UI for your table
  return (
    <div>
      {loading && <Loader />}
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className="border-radius me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <button
              onClick={() => setAddFilterModal(true)}
              className="border-radius btn btn-purple me-2"
            >
              <i className="fas fa-plus me-2 icon-size"></i>Add
            </button>
            <button
              onClick={() => navigate(-1)}
              className="border-radius btn btn-red"
            >
              <i className="dripicons-cross me-2 icon-center"></i>CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${fixedContent ? "fix-header" : ""} fix-cold-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-cold-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td colSpan={8} className="text-center">
                  {" "}
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Add coldcalling modal */}
      <Modal isOpen={addFilterModal} size="xl" centered>
        <div className="modal-header">
          <div className="w-65">
            <h5 className="modal-title mt-0">Add More Data</h5>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                setAddFilterModal(false)
              }}
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div>
            {/* fixed buttons */}
            <div
              className={`flex-column fixed-buttons ${
                fixedContent ? "d-flex" : "d-none"
              }`}
            >
              <button
                onClick={() => navigate(-1)}
                className="btn btn-red border-radius fix-btn"
              >
                <i className="dripicons-cross me-2 icon-center"></i>
                <span>Cancel</span>
              </button>
            </div>
            <div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  onFilterData()
                }}
              >
                <Row className="mb-5 align-items-end">
                  <div className="col-md-3">
                    <label htmlFor="">
                      Name <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      className="form-control border-radius"
                      value={filterName}
                      onChange={e => setFilterName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="">
                      Email Address <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Email Address"
                      className="form-control border-radius"
                      value={filterEmail}
                      onChange={e => setFilterEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="">
                      Phone Number <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      className="form-control border-radius"
                      value={filterPhone}
                      onChange={e => setFilterPhone(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 ">
                    <button
                      type="submit"
                      className="btn btn-purple border-radius"
                    >
                      {" "}
                      <i className="fas fa-search me-1"></i> Search
                    </button>
                  </div>
                </Row>
              </Form>

              <div
                style={{
                  height: "400px",
                  overflow: "auto",
                  scrollbarWidth: "thin",
                }}
              >
                <Table className="table table-bordered">
                  <thead className="position-sticky top-0 left-0 bg-white shadow border">
                    <tr>
                      <th>Company Name</th>
                      <th>Location</th>
                      <th>Country</th>
                      <th>Website</th>
                      <th>Phone</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {allFilteredData?.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data.company}</td>
                          <td>{data.location} </td>
                          <td>{data.county}</td>
                          <td>{data.website}</td>
                          <td>{data.phonenumber}</td>
                          <td className="text-center">
                            <button
                              onClick={() => onAddRow(data.id)}
                              className="btn btn-success text-capitalize"
                            >
                              <i className="fas fa-plus me-1 icon-size "></i>{" "}
                              Add It
                            </button>
                          </td>
                        </tr>
                      )
                    })}

                    {allFilteredData.length == 0 && (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Record Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="position-relative"></div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const ViewColdCalling = () => {
  //meta title
  document.title = "View Cold Calling | Zithas Crm"
  const [addImportModal, setAddImportModal] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  const [allColdData, setAllColdData] = useState([])
  const [loading, setLoading] = useState(false)

  const [statusReason, setStatusReason] = useState("")

  const [opportunityId, setOpportunityId] = useState("")
  const [isStatus, setIsStatus] = useState("")
  const [typeStatus, setTypeStatus] = useState("")

  const location = useLocation()
  // get all cold-calling data
  const filteredData = location.state?.data

  useEffect(() => {
    // console.log(filteredData)

    if (filteredData) {
      setLoading(true)
      const formData = new FormData()
      formData.append("cold_id", filteredData.id)
      axios
        .post(`${configure.apiUrl}/view_coldcalling`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          setAllColdData(res.data.data)
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    }
  }, [])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const onChangeStatus = (opId, status) => {
    swal({
      title: "Are you sure?",
      text: "do you really want to change the status",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("status", status)
        formData.append("reason", statusReason)
        formData.append("opportunity_id", opId)
        formData.append("type", typeStatus)
        formData.append("user_id", localStorage.getItem("auth_id"))
        formData.append("cold_id", filteredData.id)

        axios
          .post(`${configure.apiUrl}/change_status`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            // console.log("de", response)
            setAllColdData(response.data.data)

            setAddImportModal(false)

            swal("Status Changed successfully!", {
              icon: "success",
            })
          })
          .catch(err => console.log(err))
      }
    })
  }

  const onDelete = opId => {
    swal({
      title: "Are you sure?",
      text: "do you really want to Remove",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("opportunity_id", opId)
        formData.append("cold_id", filteredData.id)

        axios
          .post(`${configure.apiUrl}/remove_coldcalling_filter`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            setAllColdData(response.data.data)

            swal("Record Removed successfully!", {
              icon: "success",
            })
          })
          .catch(err => console.log(err))
      }
    })
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Company Name",
        accessor: "company",
        isInfoIcon: true,
      },
      {
        Header: "Email",
        accessor: "email",
        isInfoIcon: true,
      },
      {
        Header: "Country",
        accessor: "country",
        isInfoIcon: true,
      },
      {
        Header: "Website",
        accessor: "website",
        isInfoIcon: true,
      },
      {
        Header: "Phone",
        accessor: "phonenumber",
        isInfoIcon: true,
      },
      {
        Header: "Status",
        accessor: "status",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              <Select
                onChange={e => {
                  setOpportunityId(cellProps.row.original.id)
                  setIsStatus(e.value)

                  if (e.isModal) {
                    setAddImportModal(true)
                    setTypeStatus(1)
                    return
                  }
                  setTypeStatus(0)
                  onChangeStatus(cellProps.row.original.id, e.value)
                }}
                className="basic-multi-select"
                classNamePrefix="border-radius select"
                value={
                  statusOptions.filter(
                    st => st.value == cellProps.row.original.status
                  )[0]
                }
                styles={colourStyles}
                options={statusOptions}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <Link
                title="edit"
                to={`${configure.appUrl}marketing/edit-coldcalling/${cellProps.row.original.id}`}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <Link
                to=""
                title="Convert To Lead"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-exchange-alt"></i>
              </Link>
              <button
                onClick={() => onDelete(cellProps.row.original.id)}
                title="Delete"
                className="btn btn-sm btn-red me-1"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allColdData, [allColdData])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"View Cold Calling"}
          />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAllColdData={setAllColdData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Reason Modal */}
      <Modal isOpen={addImportModal} centered>
        <div className="modal-header">
          <div className="w-65">
            <h5 className="modal-title mt-0">Can you give reason?</h5>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                setAddImportModal(false)
              }}
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            onChangeStatus(opportunityId, isStatus)
          }}
        >
          <div className="modal-body">
            <div>
              <Row className="mb-4">
                <Col>
                  <div>
                    {/* <label htmlFor="">Reason </label> */}
                    <textarea
                      value={statusReason}
                      onChange={e => setStatusReason(e.target.value)}
                      className="form-control border-radius"
                      placeholder="Add reason here.."
                      required
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <div className="ms-4">
              <button
                type="submit"
                className="btn btn-purple border-radius ms-3"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Modal>
      {/* ------------ */}
    </React.Fragment>
  )
}

export default ViewColdCalling
