import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody, Form, Modal } from "reactstrap"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Customer.css"

import Select from "react-select"

// common component
import Pagination from "components/Common/Pagination"

import swal from "sweetalert"
import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"

const statusOptions = [
  { label: "In Progress", value: "0" },
  { label: "Voicemail but not given", value: "1" },
  { label: "Not interested", value: "2" },
  { label: "No received", value: "3" },
  { label: "Some wrong number", value: "4" },
  {
    label: "Gave a specific time to talk( mention the time and date)",
    value: "5",
  },
  { label: "Call connected but some digital person was speaking", value: "6" },
  { label: "Call the head office", value: "7" },
  { label: "Voicemail given", value: "8" },
  { label: "No option to talk with anyone", value: "9" },
  { label: "Call Later", value: "10" },
  { label: "They don't need it", value: "11" },
  { label: "Owner not available", value: "12" },
  { label: "Number not in service", value: "13" },
  { label: "They will call us back", value: "14" },
  { label: "They have it", value: "15" },
  { label: "Others", value: "16" },
]

function TableContainer({ columns, data, setAllColdData }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [addCallingModal, setAddCallingModal] = useState(false)

  const [locationOptions, setLocationOptions] = useState([])
  const [industryOptions, setIndustryOption] = useState([])
  const [countryOptions, setCountryOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])

  const [location, setLocation] = useState({ label: "", value: "" })
  const [country, setCountry] = useState({ label: "", value: "" })
  const [city, setCity] = useState({ label: "", value: "" })
  const [statee, setState] = useState({ label: "", value: "" })
  const [industry, setIndustry] = useState({ label: "", value: "" })
  const [status, setStatus] = useState({ label: "", value: "" })
  const [no_of_contacts, setNoOfContacts] = useState("")

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  useEffect(() => {
    fetchLocations()
    fetchCountrys()
    fetchIndustry()
  }, [])

  const fetchIndustry = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/industry`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setIndustryOption(
          res.data.data.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const fetchLocations = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLocationOptions(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchCountrys = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/country`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setCountryOption(
          res.data.data.country.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getState = async e => {
    setLoading(true)
    setCountry(e)
    const formData = new FormData()
    formData.append("country_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/country_state`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setStateOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getCities = async e => {
    setLoading(true)
    setState(e)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/state_city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCityOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const handleAddColdCalling = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("location", location.label)
      formData.append("country", country.label)
      formData.append("state", statee.label)
      formData.append("city", city.label)

      let industries = ""
      industry?.forEach((item, i) => {
        industries += industry.length - 1 == i ? item.value : item.value + ","
      })

      formData.append("industry", industries)

      let statuses = ""
      status?.forEach((item, i) => {
        statuses += status.length - 1 == i ? item.value : item.value + ","
      })

      formData.append("status", statuses)

      formData.append("nocontact", no_of_contacts)

      const response = await axios.post(
        `${configure.apiUrl}/add_coldcalling`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      if (response.data.message == "success" && response.data.success == true) {
        setAddCallingModal(false)
        setAllColdData(response.data.data)
        swal("Cold Calling added successfully!", {
          icon: "success",
        })
        setNoOfContacts("")
      }

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "error")
    }
  }

  // Render the UI for your table
  return (
    <div>
      {loading && <Loader />}
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          to={`${configure.appUrl}design/marketing/add-coldcalling`}
          className="btn btn-purple border-radius fix-btn"
        >
          <i className="fas fa-plus me-2 icon-size"></i>
          <span>ColdCalling</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className="border-radius me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            {/* <Link
              to=""
              className="border-radius btn text-white me-2 btn-purple"
            >
              <i className="fas fa-plus me-2 icon-size"></i>Cold Calling
            </Link> */}
            <button
              onClick={() => setAddCallingModal(true)}
              className="border-radius btn text-white me-2 btn-purple"
            >
              <i className="fas fa-plus me-2 icon-size"></i>Cold Calling
            </button>
            <button
              onClick={() => navigate(-1)}
              className="border-radius btn btn-red"
            >
              <i className="dripicons-cross me-2 icon-center"></i>CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${fixedContent ? "fix-header" : ""} fix-calling-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-calling-header  " {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td colSpan={8} className="text-center">
                  {" "}
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Add coldcalling modal */}
      <Modal isOpen={addCallingModal} size="xl" centered>
        <div className="modal-header">
          <div className="w-65">
            <h5 className="modal-title mt-0">Add Cold Calling</h5>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                setAddCallingModal(false)
              }}
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div>
            {/* fixed buttons */}
            <div
              className={`flex-column fixed-buttons ${
                fixedContent ? "d-flex" : "d-none"
              }`}
            >
              <button
                onClick={() => navigate(-1)}
                className="btn btn-red border-radius fix-btn"
              >
                <i className="dripicons-cross me-2 icon-center"></i>
                <span>Cancel</span>
              </button>
            </div>
            <div>
              <Form onSubmit={handleAddColdCalling}>
                <Row className="mb-4">
                  <div className="col-4">
                    <label htmlFor="">
                      Location <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={e => setLocation(e)}
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix="border-radius select"
                      styles={colourStyles}
                      options={locationOptions}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-4 mb-4">
                    <label htmlFor="">
                      Country <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={getState}
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix="border-radius select"
                      styles={colourStyles}
                      options={countryOptions}
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label htmlFor="">
                      State <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={getCities}
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix="border-radius select"
                      styles={colourStyles}
                      options={state_option}
                    />
                  </div>
                  <div className="col-4 mb-4">
                    <label htmlFor="">
                      City <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={e => setCity(e)}
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix="border-radius select"
                      styles={colourStyles}
                      options={city_option}
                    />
                  </div>
                </Row>
                <Row className="mb-4">
                  <div className="col-4">
                    <label htmlFor="">
                      Industry <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={e => setIndustry(e)}
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix="border-radius select"
                      isMulti
                      styles={colourStyles}
                      options={industryOptions}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="">
                      Status <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={e => setStatus(e)}
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix="border-radius select"
                      isMulti
                      styles={colourStyles}
                      options={statusOptions}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="">
                      No of Contact <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter No. of Contact"
                      className="form-control border-radius"
                      value={no_of_contacts}
                      onChange={e => setNoOfContacts(e.target.value)}
                    />
                  </div>
                </Row>

                <div className="py-1 border-bottom"></div>
                <div className="text-end my-3 ">
                  <button className="btn btn-purple border-radius ">
                    Submit
                  </button>
                </div>
              </Form>
            </div>
            <div className="position-relative"></div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const ColdCallingIndex = () => {
  const [allColdData, setAllColdData] = useState([])

  const navigate = useNavigate()

  //meta title
  document.title = "Cold Calling | Zithas Crm"

  // get all cold-calling data
  useEffect(() => {
    axios
      .get(`${configure.apiUrl}/calling`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        setAllColdData(res.data.data)
        // console.log("callinb", res)
      })
      .catch(err => console.log(err))
  }, [])

  const onDelete = calling_id => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete this cold Calling",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("calling_id", calling_id)

        axios
          .post(`${configure.apiUrl}/delete_coldcalling`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            // console.log("de", response.data)

            if (response.data.data.length > 0) {
              setAllColdData(response.data.data)
            }

            swal("Cold Calling deleted successfully!", {
              icon: "success",
            })
          })
      }
    })
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Location",
        accessor: "location",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Country",
        accessor: "country",
        isInfoIcon: true,
      },
      {
        Header: "State",
        accessor: "state",
        isInfoIcon: true,
      },
      {
        Header: "City",
        accessor: "city",
        isInfoIcon: true,
      },
      {
        Header: "Status",
        accessor: "status",
        className: "text-center",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div className="">
              {
                cellProps.row.original.status.split(",").map((st, ind) => {
                  return (
                    <div key={ind}>
                      {" "}
                      {statusOptions.filter(s => s.value == st)[0]?.label}
                    </div>
                  )
                })
                // statusOptions.filter((st) => st.value == cellProps.row.original.status)[0]?.label
              }
            </div>
          )
        },
      },
      {
        Header: "No. of contact",
        accessor: "nocontact",
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <button
                title="View"
                className="btn btn-sm me-1 btn-outline-secondary"
                onClick={() => {
                  navigate(`${configure.appUrl}marketing/view-coldcalling`, {
                    state: {
                      data: {
                        id: cellProps.row.original.id,
                        country: cellProps.row.original.country,
                        state: cellProps.row.original.state,
                        city: cellProps.row.original.city,
                        status: cellProps.row.original.status,
                        nocontact: cellProps.row.original.nocontact,
                        industry: cellProps.row.original.industry,
                      },
                    },
                  })
                }}
              >
                <i className="fas fa-eye"></i>
              </button>
              <button
                onClick={() => onDelete(cellProps.row.original.id)}
                title="delete"
                className="btn btn-sm  btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allColdData, [allColdData])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Cold Calling"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAllColdData={setAllColdData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ColdCallingIndex
