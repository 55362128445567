import React, { useState, useMemo, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Row, Col, Card, CardBody, Table } from "reactstrap"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Pagination from "components/Common/Pagination"

// assets
import "../../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"

const downloadImage = imageUrl => {
  const a = document.createElement("a")
  a.href = imageUrl
  a.download = "image.jpg" // Set the filename for the download
  a.style.display = "none"
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function TableContainer({ columns, data }) {
  const [fixedHeader, setFixedHeader] = useState(false)

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  // Render the UI for your table
  return (
    <div>
      <Row className="mt-4">
        <Col>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div>
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered files-table" {...getTableProps()}>
        <thead className={`${fixedHeader ? "fix-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`col-md-${
                    index === 0 ? "1" : index === 1 ? "9" : "2"
                  }`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <i
                      title="info"
                      className="bx bx-info-circle ms-1 info-icon"
                    ></i>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={`col-md-${
                        index === 0 ? "1" : index === 1 ? "9" : "2"
                      }`}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          {data.length == 0 ? (
            <td
              className="text-center"
              colSpan="3"
              style={{ border: "none", padding: "10px" }}
            >
              No Filer Added for this Introducer
            </td>
          ) : null}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </div>
  )
}

const Files = ({ verticalActiveTab }) => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const params = useParams()
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (verticalActiveTab == "3") {
      fetchFiles()
    }
  }, [verticalActiveTab])

  const fetchFiles = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/introducer_files`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setData(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleDownload = imageUrl => {
    const link = document.createElement("a")
    link.href = imageUrl

    // Set the download attribute with a default file name
    link.download = "image.jpg"

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link)

    // Programmatically click the link
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)
  }

  // const blob = new Blob([myString], { type: "text/plain" })
  // const blobUrl = URL.createObjectURL(blob)
  // console.log("blobUrl", blobUrl)
  // console.log("blobUrl", blobUrl)
  function extractUUIDFromSharePointUrl(url) {
    // Split the URL by '/' to find the UUID part
    const parts = url.split("/")

    // Find the part containing the encoded UUID
    let uuidPart = null
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].includes("%2Ftest%2F")) {
        // Look for the part with '%2Ftest%2F'
        uuidPart = parts[i + 1] // UUID should be right after '%2Ftest%2F'
        break
      }
    }

    if (!uuidPart) {
      console.error("UUID not found in the URL.")
      return null
    }

    // Decode the UUID part and extract the UUID using regex
    const decodedUuidPart = decodeURIComponent(uuidPart)
    const regex =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
    const match = decodedUuidPart.match(regex)

    if (match) {
      return match[0] // return the matched UUID
    } else {
      console.error("UUID not found in the decoded URL part.")
      return null
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "file_name",
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          const onDelete = e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this File !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willdelete => {
              if (willdelete) {
                e.preventDefault()
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("introducer_id", params.id)
                formData.append("file_id", cellProps.row.original.id)
                try {
                  await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_introducer_file`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(res => {
                    swal({
                      icon: "success",
                      text: "File Deleted Successfully",
                    }).then(data => {
                      setLoading(false)
                      setData(res.data.data)
                    })
                  })
                } catch (error) {
                  console.log(error)
                  setLoading(false)
                }
              }
            })
          }
          const downloadImg = (e, imgUrl) => {
            e.preventDefault()
            const proxyUrl = "https://cors-anywhere.herokuapp.com/"
            fetch(proxyUrl + imgUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error("Network response was not ok")
                }
                return response.blob()
              })
              .then(blob => {
                // Now you have the blob object

                // Use the blob as needed, for example:
                const blobUrl = URL.createObjectURL(blob)

                // Use the blob URL, for example:
                const link = document.createElement("a")
                link.href = blobUrl
                link.setAttribute("download", "file.pdf") // Set desired file name here
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                // Remember to revoke the blob URL when done to free up memory
                URL.revokeObjectURL(blobUrl)
              })
              .catch(error => {
                console.error("Error fetching or converting to blob:", error)
              })
          }

          return (
            <div>
              <a
                // onClick={() => handleDownload(cellProps.row.original.link)}
                // onClick={() => downloadImage(cellProps.row.original.link)}
                href={cellProps.row.original.link}
                target="_blank"
                rel="noreferrer"
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-download "></i>
              </a>
              <a
                onClick={onDelete}
                className="btn btn-sm action-btn btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const [files, setFiles] = useState("")

  const addFiles = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("file", files)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_introducer_file`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "File Added Successfully",
        }).then(data => {
          setData(response.data.data)
          setFiles("")
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <div>
          <form onSubmit={addFiles}>
            {/* Fixed Buttons */}
            <div
              className={`flex-column fixed-buttons pt-2 ${
                fixButtons ? "d-flex" : "d-none"
              }`}
            >
              <a className="btn btn-purple  fix-btn btn-label pe-0">
                <i className="fas fa-upload me-2 label-icon"></i>
                <span>Upload</span>
              </a>
              <a
                onClick={() => navigate(-1)}
                className="btn btn-red  fix-btn btn-label pe-0"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                <span>Cancel</span>
              </a>
            </div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <h5 className="text-dark">File Upload</h5>
                      <div className="text-end">
                        <button className="btn btn-save btn-label me-2">
                          <i className="fas fa-upload me-2 label-icon"></i>{" "}
                          Upload
                        </button>
                        <a
                          onClick={() => navigate(-1)}
                          className=" btn ms-md-2 btn-red btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          CANCEL
                        </a>
                      </div>
                    </div>

                    <Row>
                      <div className="col-md-5">
                        <input
                          type="file"
                          className="form-control "
                          onChange={e => setFiles(e.target.files[0])}
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </form>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Files
