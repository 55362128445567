import React, { useEffect, useState } from "react"
import { Table, Row, Col, Input, Modal, Form, Label } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"

// common
import Pagination from "components/Common/Pagination"
import configure from "configure"
import axios from "axios"
import Swal from "sweetalert2"
import industryImg from "../../../assets/images/Inustry.jpg"

function TableContainer({
  columns,
  data,
  setdata,
  setLoading,
  setEditIndustryname,
}) {
  const [status, setStatus] = useState(false)
  const [name, setName] = useState("")

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  const navigate = useNavigate()

  // Render the UI for your table
  // Api integration
  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formdata = new FormData()
    formdata.append("name", name)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/addIndustry`,
        data: formdata,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        Swal.fire("", "Industry Added Successfully", "success").then(() => {
          setName("")
          setStatus(false)
        })
        setdata([...data, response?.data?.data[0]])
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  return (
    <div className="px-2">
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => {
            setStatus(true)
          }}
          className="btn btn-save  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 label-icon"></i>
          <span>Industry</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </a>
      </div>

      <Row className="mb-2">
        <Col lg={2}>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className=" btn btn-save btn-label"
              onClick={() => {
                setStatus(true)
              }}
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
              Industry
            </a>
            <a
              className=" btn text-white ms-md-2 btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              Cancel
            </a>
          </div>
        </Col>
      </Row>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center pt-4">
          <div className={`${fixedContent ? "oppImg" : ""}`}>
            <img src={industryImg} alt="industryImg" className="w-100" />
          </div>
        </div>
        <div className="col-md-8">
          <Table
            className="table table-bordered mt-4 fixed-setting-col-header"
            {...getTableProps()}
          >
            <thead
              className={`${
                fixedContent ? "fixed-setting-industry-header" : ""
              }`}
            >
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={index}
                      className={`col-t-${index + 1} col-md-${
                        index === 0 ? "1" : index === 1 ? "9" : "2"
                      }`}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr className="row-hover" {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className={`col-md-${
                            index === 0 ? "1" : index === 1 ? "9" : "2"
                          }`}
                          key={index}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <Modal isOpen={status} size="sm" className="bd-custom-md-3" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Industry</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={handleSubmit}>
            <div className="mb-3 col-md-12">
              <Label htmlFor="formrow-firstname-Input"> Industry Name</Label>
              <Input
                required
                value={name}
                onChange={e => setName(e.target.value)}
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Industry Name"
              />
            </div>
            <div className="">
              <button className=" btn btn-save btn-label">
                <i className="fas fa-save me-2 label-icon"></i>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const Industry = ({ setLoading }) => {
  const [data, setdata] = useState([])
  const [editIndustryname, setEditIndustryname] = useState("")
  const [editIndustryId, setEditIndustryID] = useState("")

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Industry Name",
        accessor: "name",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          const handleDelete = async () => {
            Swal.fire({
              icon: "warning",
              title: "Are you sure ?",
              text: "You want to Delete this Industry !",
              showCancelButton: true,
            }).then(async willDelete => {
              if (willDelete.isConfirmed) {
                setLoading(true)
                const formdata = new FormData()
                formdata.append("id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/deleteIndustry`,
                    data: formdata,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  if (response) {
                    setLoading(false)
                    Swal.fire({
                      icon: "success",
                      text: "Industry Deleted Successfully",
                    }).then(willOkay => {
                      if (willOkay.isConfirmed) {
                        setdata(response.data.data?.reverse())
                        setEditStatus(false)
                      }
                    })
                  }
                } catch (error) {
                  setLoading(false)
                  console.log(error)
                }
              }
            })
          }
          return (
            <div>
              <a
                onClick={() => {
                  setEditIndustryname(cellProps.row.original.name)
                  setEditIndustryID(cellProps.row.original.id)
                  setEditStatus(true)
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a
                to="#"
                onClick={() => handleDelete()}
                className="btn btn-sm  btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )
  const handleEditSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formdata = new FormData()
    formdata.append("name", editIndustryname)
    formdata.append("id", editIndustryId)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/editIndustry`,
        data: formdata,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Industry Edited Successfully",
        }).then(willOkay => {
          if (willOkay.isConfirmed) {
            setEditIndustryname("")
            setEditIndustryID("")
            setdata(response.data.data?.reverse())
            setEditStatus(false)
          }
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  // const data = [
  //   {
  //     id: 1,
  //     name: "Machine Learning",
  //   },
  //   {
  //     id: 2,
  //     name: "Artificial intelligence",
  //   },
  //   {
  //     id: 3,
  //     name: "Web Development",
  //   },
  //   {
  //     id: 4,
  //     name: "Human Resource",
  //   },
  //   {
  //     id: 5,
  //     name: "Industry",
  //   },
  //   {
  //     id: 3,
  //     name: "Web Development",
  //   },
  //   {
  //     id: 4,
  //     name: "Human Resource",
  //   },
  //   {
  //     id: 5,
  //     name: "Industry",
  //   },
  // ]

  const [edit_status, setEditStatus] = useState(false)
  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      try {
        const response = await axios({
          method: "get",
          url: `${configure.apiUrl}/industry`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          setdata(res.data.data?.reverse())
        })
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    loadData()
  }, [])

  return (
    <div>
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table">
            <TableContainer
              columns={columns}
              data={data}
              setdata={setdata}
              setLoading={setLoading}
              setEditIndustryname={setEditIndustryname}
            />
          </div>
        </Col>

        <Modal
          isOpen={edit_status}
          size="sm"
          className="bd-custom-md-3"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Industry</h5>
            <button
              type="button"
              onClick={() => {
                setEditStatus(false)
                setEditIndustryname("")
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="row" onSubmit={handleEditSubmit}>
              <div className="mb-3 col-md-12">
                <Label htmlFor="formrow-firstname-Input">Industry Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Industry Name"
                  value={editIndustryname}
                  onChange={e => setEditIndustryname(e.target.value)}
                />
              </div>
              <div className="mt-20">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </Modal>
      </Row>
    </div>
  )
}

export default Industry
