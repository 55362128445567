import React, { useState, useMemo, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Row, Col, Card, CardBody, Table, Badge } from "reactstrap"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Pagination from "components/Common/Pagination"

// assets
import "../../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"

function TableContainer({ columns, data }) {
  const [fixedHeader, setFixedHeader] = useState(false)
  const navigate = useNavigate()

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  let paidTotal = 0
  let unpaidTotal = 0
  let Total = 0

  data.forEach(item => {
    if (item.payment_status === "paid") {
      paidTotal += parseInt(item.cost)
    } else if (item.payment_status === "unpaid") {
      unpaidTotal += parseInt(item.cost)
    }

    Total += parseInt(item.cost)
  })

  // Render the UI for your table
  return (
    <div>
      <Row className="mb-3 justify-content-between align-items-center">
        <Col md={4} className="d-flex align-items-start pe-0">
          {/* counts */}
          <div className="d-flex justify-content-between w-100 text-center">
            <a
              href="#"
              className="text-primary pt-2 px-2 rounded payment-count"
            >
              <h4>{Total}</h4>
              <h4>Total</h4>
            </a>
            <a
              href="#"
              className="text-success pt-2 px-2 rounded payment-count"
            >
              <h4>{paidTotal}</h4>
              <h4>Paid</h4>
            </a>
            <a href="#" className="text-danger pt-2 px-2 rounded payment-count">
              <h4>{unpaidTotal}</h4>
              <h4>Unpaid</h4>
            </a>
          </div>
        </Col>
        <Col md={8} className="d-flex justify-content-end align-items-center">
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <div className=" mx-3">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline ">
              <div className="position-relative mt-2">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="text-end">
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="table table-bordered payment-table"
        {...getTableProps()}
      >
        <thead className={`${fixedHeader ? "fix-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <i
                      title="info"
                      className="bx bx-info-circle ms-1 info-icon"
                    ></i>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </div>
  )
}

const Payment = ({ verticalActiveTab }) => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const params = useParams()
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (verticalActiveTab == "5") {
      fetchFiles()
    }
  }, [verticalActiveTab])

  const fetchFiles = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/introducer_payments`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setData(response.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "created_at",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {moment(cellProps.row.original.created_at).format("DD-MM-YYYY")}
            </div>
          )
        },
      },
      {
        Header: "Project",
        accessor: "projectname",
        isInfoIcon: true,
      },
      {
        Header: "Payment Id",
        accessor: "id",
        isInfoIcon: true,
      },
      {
        Header: "Amount",
        accessor: "cost",
        isInfoIcon: true,
      },
      {
        Header: "TDS",
        accessor: "tds",
        isInfoIcon: true,
      },
      {
        Header: "Status",
        accessor: "payment_status",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <Badge className="bg-success">
              {cellProps.row.original.payment_status}
            </Badge>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <div>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a className="btn btn-secondary  fix-btn">
              <i className="fas fa-table me-2 "></i>
              <span>Excel</span>
            </a>
            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Payment
