import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import configure from "configure"

const Breadcrumb = props => {
  const KnowledgeBase = useLocation()
  const kPath = KnowledgeBase.pathname
  const knowledgebaseUrl = `${configure.appUrl}knowledgebase`

  return (
    <Row>
      <Col className="col-12 p-0">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">
            {kPath == knowledgebaseUrl ? props.title : props.breadcrumbItem}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {kPath == knowledgebaseUrl ? (
                <>
                <BreadcrumbItem>
                    <Link>{props.title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </>
              ) : (
                <>
                  <BreadcrumbItem>
                    <Link to={`${configure.appUrl}`}>{props.title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                </>
              )}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
