import React from "react"
import "../../assets/css/Loader.css"

const Loader = () => {
  return (
    <div className="spinner-loader">
      {/* <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}

      <div className="loader-line-3">
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
        <div className="bar5"></div>
        <div className="bar6"></div>
      </div>
    </div>
  )
}

export default Loader
