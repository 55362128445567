import React, { Fragment, useEffect, useState } from "react"
import {
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
import AccessDenied from "../../assets/images/dribbble_1.gif"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Badge,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import sideImg from "../../assets/images/profile-img.png"

// assets
import "../../assets/css/Sales/Sales.css"
import "../../assets/css/Sales/Lead.css"

// common component
import Pagination from "components/Common/Pagination"
// import ViewProfile from "./ViewLead/ViewProfile"
// import ViewActivity from "./ViewLead/ViewActivity"
// import ViewNotes from "./ViewLead/ViewNotes"
import Loader from "pages/Separate/Loader"

// Hover card
import axios from "axios"
// import ViewFollowUp from "./ViewLead/ViewFollowUp"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Dropzone from "react-dropzone"
import swal from "sweetalert"
import moment from "moment"
import configure from "configure"
import Pagi2 from "components/Common/Pagi2"
import NewPagination from "components/Common/Newpagination"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

const FavoriteLead = ({ isLeadEdit }) => {
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [modal_Access, setAccessDenied] = useState(false)

  const [data, setDatas] = useState([])
  const [displayData, setDisplayData] = useState([])
  const [viewData, setViewData] = useState()
  const [isLoading, setLoading] = useState(false)

  const [country, setCountry] = useState("")
  const [source, setSource] = useState([])
  const [status, setStatus] = useState([])
  const [assign, setAssign] = useState([])
  const [type, setType] = useState([])
  const [industry, setIndustry] = useState([])

  const [location, setLocation] = useState([])
  const [sourceIn_option, setInSource] = useState([])
  const [sourceuk_option, setUkSource] = useState([])
  const [userIn_option, setUserIn] = useState([])
  const [useruk_option, setUserUk] = useState([])
  const [alluser_option, setAllUsers] = useState([])
  const [allsource_option, setAllSource] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [industry_option, setIndustryOption] = useState([])

  const [convertBtn, setConvertBtn] = useState(false)

  useEffect(() => {
    fetch()
    FilterData()
  }, [])

  useEffect(() => {
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 3)

    axios({
      method: "post",
      url: `${configure.apiUrl}/user_permission`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then(res => {
        if (res.data.data.india[1] == 1 && res.data.data.uk[1] == 1) {
          setConvertBtn(true)
        } else {
          setConvertBtn(false)
        }
      })
      .catch(err => console.log(err))
  }, [])

  const type_option = [
    { label: "Hot", value: "1" },
    { label: "Cold", value: "2" },
    { label: "Warm", value: "3" },
    { label: "Normal", value: "4" },
  ]

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("location", "")
    formData.append("asigned", "")
    formData.append("status", "")
    formData.append("source", "")
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/lead`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const filterData = response?.data.data?.filter(
          ob => ob.is_favourite != 0
        )
        setDatas(filterData)
        setDisplayData(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [disable, setDiable] = useState(false)

  const FilterData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/filter_data`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        setStatusOption(
          response.data.data.lead_status.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
        setInSource(
          response.data.data.india_source.map(obj => ({
            value: obj.id,
            label: obj.leadname,
          }))
        )
        setUkSource(
          response.data.data.uk_source.map(obj => ({
            value: obj.id,
            label: obj.leadname,
          }))
        )
        setUserIn(
          response.data.data.india_user.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
        setUserUk(
          response.data.data.uk_user.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        const users = [
          ...response.data.data.uk_user,
          ...response.data.data.india_user,
        ]
        const source = [
          ...response.data.data.uk_source,
          ...response.data.data.india_source,
        ]
        const jsonObject = users.map(JSON.stringify)
        const uniqueSet = new Set(jsonObject)
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse)
        setAllUsers(
          uniqueArray.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        const jsonObject1 = source.map(JSON.stringify)
        const uniqueSet1 = new Set(jsonObject1)
        const uniqueArray1 = Array.from(uniqueSet1).map(JSON.parse)
        setAllSource(
          uniqueArray1.map(obj => ({
            value: obj.id,
            label: obj.leadname,
          }))
        )
        setIndustryOption(
          response.data.data.industry?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        const formData2 = new FormData()
        formData2.append("user_id", localStorage.getItem("auth_id"))
        const response2 = await axios({
          method: "post",
          url: `${configure.apiUrl}/show_lead_filter`,
          data: formData2,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          if (res.data.success == false) {
            setDiable(true)
          }
          const datas = res.data.data[0]

          if (datas.location == "1") {
            setCountry({ label: "India", value: 1 })
          } else if (datas.location == "2") {
            setCountry({ label: "United Kingdom", value: 2 })
          }
          const sourceIds = datas?.source?.split(",").map(Number)
          const statusIds = datas?.status?.split(",").map(Number)
          const assignIds = datas?.assigned?.split(",").map(Number)
          const typeIds = datas?.lead_type?.split(",")
          const industryIds = datas?.industry?.split(",").map(Number)
          setSource(
            uniqueArray1
              ?.filter(item => sourceIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.leadname,
              }))
          )
          setStatus(
            response.data.data?.lead_status
              ?.filter(item => statusIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
          )
          setAssign(
            uniqueArray
              ?.filter(item => assignIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
          )
          setType(type_option?.filter(item => typeIds?.includes(item.value)))
          setIndustry(
            response.data.data?.industry
              ?.filter(item => industryIds?.includes(item.id))
              ?.map(obj => ({
                value: obj.id,
                label: obj.name,
              }))
          )
        })

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [lead_data, setLeadData] = useState([])
  const [note_data, setNoteData] = useState([])
  const [log_data, setLogData] = useState([])
  const [follow_data, setFollowData] = useState([])
  const [isFavorite, setIsFavorite] = useState(true)
  const [isFavoriteId, setIsFavoriteId] = useState("")
  const [name_modal, setNameModal] = useState(false)
  //code for fetching lead data

  //meta title
  document.title = "Dashboard | Zithas Technologies"

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false)
  })

  const [view_assign, setViewAssign] = useState([])
  const [created_at, setCreatedAt] = useState("")

  const addToFavorite = id => {
    console.log(id)
  }
  const toggleFavorite = async ({ leadId, fav }) => {
    if (isLeadEdit) {
      Swal.fire({
        text: `${
          fav == 0
            ? "Are you sure you want to favorite this lead?"
            : "Are you sure you want to unfavorite this lead?"
        }`,
        icon: "warning",
        showCancelButton: true,
      }).then(async willOkay => {
        if (willOkay.isConfirmed) {
          setIsFavorite(!isFavorite) // Toggle favorite status
          const favValue = fav ? 0 : 1 || fav ? 1 : 0
          setIsFavoriteId(fav)
          const formData = new FormData()
          formData.append("lead_id", leadId)
          formData.append("fav", favValue)
          formData.append("user_id", localStorage.getItem("auth_id"))
          formData.append("location", "")
          formData.append("asigned", "")
          formData.append("status", "")
          formData.append("source", "")
          setLoading(true)
          try {
            const response = await axios({
              method: "post",
              data: formData,
              url: `${configure.apiUrl}/isFavourite`,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            if (response) {
              setLoading(false)
              const filterData = response?.data?.data?.filter(
                ob => ob.is_favourite != 0
              )
              setDatas(filterData)
              // swal({
              //   text: "FollowUp is completed",
              //   icon: "success",
              // }).then(() => {
              //   setFollowUp(response.data.data)
              // })
            }
          } catch (error) {
            setLoading(false)
            console.log(error)
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
        Cell: cellProps => {
          return (
            <div className="d-flex align-items-center gap-2">
              {/* <i className="bi bi-star-fill text-purple"></i> */}
              <button
                className="btn border-0 p-0"
                onClick={() =>
                  toggleFavorite({
                    leadId: cellProps.row.original.id,
                    fav: cellProps.row.original.is_favourite,
                  })
                }
              >
                <i
                  className={`bi fav-btn ${
                    cellProps.row.original.is_favourite == 1
                      ? "bi-star-fill text-star"
                      : "bi-star"
                  }`}
                  // onClick={() => addToFavorite(cellProps.row.original.id)}
                ></i>
              </button>
              <span>{cellProps.row.original.id}</span>
            </div>
          )
        },
      },
      {
        Header: "",
        accessor: "assigned",
        className: "text-center px-0",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex align-items-center justify-content-center">
              <div className="profileImg-box">
                <img
                  src={`https://mars.zithas.com/application/public/${cellProps.row.original.asigned_avatar}`}
                  alt=""
                  className="assigned-img mx-1"
                  height="20"
                  width="20"
                />
                <div className="profileImg-detail">
                  <div className="overflow-hidden mb-0 card">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-8">
                          <div className="text-primary text-start p-3">
                            <h5 className="text-primary ">
                              {cellProps.row.original.asigned}
                            </h5>
                            <p style={{ width: "200px" }}>
                              {cellProps.row.original.assign_designation}
                            </p>
                          </div>
                        </div>
                        <div className="align-self-end col-4">
                          <img src={sideImg} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 mb-0 card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="avatar-md profile-user-wid mb-2">
                            <img
                              src={`https://mars.zithas.com/application/public/${cellProps.row.original.asigned_avatar}`}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className=" text-start">
                            <div className="row">
                              <div className="pe-1 col-5">
                                <h5 className="font-size-12 mb-1">Join Date</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.join_date}
                                </p>
                              </div>
                              <div className="ps-0 col-7">
                                <h5 className="font-size-12 mb-1">Email</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.assign_email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cellProps.row.original.lead_belongs_to == 1 ? (
                <img
                  src="https://mars.ztpl.net/assets/images/india.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              ) : cellProps.row.original.lead_belongs_to == 2 ? (
                <img
                  src="https://mars.ztpl.net/assets/images/uk.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              ) : null}
            </div>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: "Company",
        accessor: "company",
        isInfoIcon: true,
        className: "text-center cursor-pointer px-1",
        Cell: cellProps => {
          return (
            <div className="d-flex justify-space-between align-items-center">
              <div title={cellProps.row.original.company}>
                {cellProps.row.original.company?.slice(0, 16)}
                {" .."}
              </div>
              <div>
                {cellProps.row.original.lead_type == "Hot" ? (
                  <i
                    className="bx bxs-hot"
                    style={{
                      fontSize: "18px",
                      lineHeight: "0",
                      color: "#FF5733",
                    }}
                  ></i>
                ) : cellProps.row.original.lead_type == "Warm" ? (
                  <i
                    className="bx bxs-flame"
                    style={{
                      fontSize: "20px",
                      lineHeight: "0",
                      color: "#fdab99",
                    }}
                  ></i>
                ) : cellProps.row.original.lead_type == "Cold" ? (
                  <i
                    className="bx bxs-sun"
                    style={{
                      fontSize: "20px",
                      lineHeight: "0",
                      color: "#4ab3ff",
                    }}
                  ></i>
                ) : null}
              </div>
            </div>
          )
        },
      },

      {
        Header: "Phone",
        accessor: "phone",
        className: "text-center cursor-pointer px-0",
        isInfoIcon: true,
        Cell: cellProps => {
          const personal = JSON.parse(cellProps.row.original.person_details)
          let PersonPhone
          if (personal != null && personal.length == 0) {
            PersonPhone = cellProps.row.original.phonenumber
          } else if (personal != null) {
            PersonPhone = personal[0].person_phone
          }
          return <div>{PersonPhone}</div>
        },
      },
    ],
    [convertBtn]
  )

  const [formPopup, setFormPopup] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  useEffect(() => {
    fetchCountry()
  }, [])

  const fetchCountry = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLocation(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onFilter = async e => {
    e.preventDefault()
    setFormPopup(false)
    setLoading(true)
    const Assign = assign.map(obj => obj.value).join(",")
    const Status = status.map(obj => obj.value).join(",")
    const Source = source.map(obj => obj.value).join(",")
    const Type = type.map(obj => obj.label).join(",")
    const Industry = industry.map(obj => obj.value).join(",")
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    if (country.value != undefined) {
      formData.append("location", country.value)
    } else {
      formData.append("location", "")
    }
    formData.append("asigned", Assign)
    formData.append("status", Status)
    formData.append("source", Source)
    formData.append("lead_type", Type)
    formData.append("industry", Industry)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/lead`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const filterData = response?.data.data?.filter(
          ob => ob.is_favourite != 0
        )
        setDatas(filterData)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [title, setTitle] = useState("")


  // Quick Note Functionality

  const [currency_option, setCurrencyOption] = useState([])
  const [lead_id, setLeadId] = useState("")

  const fetchCurrency = async () => {
    setNotesSidebar(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/currency`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setCurrencyOption(
          response.data.data.currency.map(item => ({
            label: item.currency,
            value: item.id,
          }))
        )
      })
    } catch (err) {
      console.log(err)
    }
  }

  const [selectedFiles1, setselectedFiles1] = useState([])

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [notesSidebar, setNotesSidebar] = useState(false)

  const [currency, setCurrency] = useState("")
  const [notedate, setNotedate] = useState("")
  const [amount, setAmount] = useState("")
  const [ckedata, setCkedata] = useState("")

  const onSaveFilter = async e => {
    setLoading(true)
    const Assign = assign?.map(obj => obj.value).join(",")
    const Status = status?.map(obj => obj.value).join(",")
    const Source = source?.map(obj => obj.value).join(",")
    const Type = type?.map(obj => obj.value).join(",")
    const Industry = industry?.map(obj => obj.value).join(",")
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("location", country?.value)
    formData.append("assigned", Assign)
    formData.append("status", Status)
    formData.append("source", Source)
    formData.append("lead_type", Type)
    formData.append("industry", Industry)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/save_lead_filter`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Filters Saved Successfully",
        })
        setDiable(!disable)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const onClearFilter = async e => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/clear_lead_filter`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Filters Cleared Successfully",
        }).then(data => {
          setCountry("")
          setSource([])
          setStatus([])
          setAssign([])
          setType([])
          setIndustry([])
          setDiable(!disable)
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mt-3">
        <div>
          <div>
            <div className="=">
              <Card>
                <CardBody className="px-md-1 pt-0">
                  <div>
                    <p className="text-purple fs-5 ps-md-3 pt-3 fw-bold">
                      Favorite Leads
                    </p>
                  </div>
                  <div className="responsive-table">
                    <div>
                      <div
                        className="position-relative shadow-sm table-responsive"
                        id="Fav-lead-table"
                      >
                        <Table
                          className="table table-bordered mt-md-4"
                          {...getTableProps()}
                        >
                          <thead
                            // className={`${
                            //   fixedContent ? "fix-header" : ""
                            // } fix-lead-header`}
                            className="fix-lead-header"
                          >
                            {headerGroups.map((headerGroup, i) => (
                              <tr
                                key={i}
                                {...headerGroup.getHeaderGroupProps()}
                              >
                                {headerGroup.headers.map((column, index) => (
                                  <th
                                    key={index}
                                    className={`t-col-${index + 1} ${
                                      column.className
                                    }`}
                                    {...column.getHeaderProps()}
                                  >
                                    {column.render("Header")}
                                    {column.isInfoIcon ? (
                                      <a
                                        onClick={() => {
                                          setNameModal(true)
                                          setTitle(column.Header)
                                        }}
                                      >
                                        <i
                                          title="info"
                                          className={`bx bx-info-circle ms-1 info-icon assign-${index}`}
                                        ></i>
                                      </a>
                                    ) : null}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>

                          <tbody
                            className="fix-lead-header"
                            {...getTableBodyProps()}
                          >
                            {page.map((row, i) => {
                              prepareRow(row)
                              let backColor
                              if (row.original.lead_type == "Hot") {
                                backColor = "bd-hot"
                              }
                              return (
                                <tr
                                  className={`row-hover ${backColor}`}
                                  {...row.getRowProps()}
                                  key={i}
                                >
                                  {row.cells.map((cell, index) => {
                                    return (
                                      <td
                                        key={index}
                                        onClick={() =>
                                          cell.column.Header === "Company"
                                            ? navigate(
                                                `${configure.appUrl}sales/edit-lead/${cell.row.allCells[0].value}`
                                              )
                                            : ""
                                        }
                                        className={`t-col-${index + 1} ${
                                          cell.column.className
                                        }`}
                                        {...cell.getCellProps()}
                                      >
                                        {cell.render("Cell")}
                                      </td>
                                    )
                                  })}
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                        {data.length == 0 ? (
                          <p className="text-center">No Leads Available</p>
                        ) : null}
                        {formPopup ? (
                          <div className="popup-form p-4">
                            <Form onSubmit={onFilter}>
                              <Row className="align-items-end">
                                <Row
                                  className="d-flex pe-0 me-0"
                                  style={{ width: "100%" }}
                                >
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Location</label>
                                    <Select
                                      placeholder="Select Location"
                                      className="basic-multi-select"
                                      classNamePrefix="border-radius select"
                                      onChange={e => setCountry(e)}
                                      value={country}
                                      styles={colourStyles}
                                      options={location}
                                    />
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Source</label>
                                    {country.value == "2" ? (
                                      <Select
                                        placeholder="Select Source"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={sourceuk_option}
                                        value={source}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setSource(e)}
                                      />
                                    ) : country.value == "1" ? (
                                      <Select
                                        placeholder="Select Source"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={sourceIn_option}
                                        value={source}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setSource(e)}
                                      />
                                    ) : (
                                      <Select
                                        placeholder="Select Source"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={allsource_option}
                                        value={source}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setSource(e)}
                                      />
                                    )}
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Status</label>
                                    <Select
                                      placeholder="Select Status"
                                      className="basic-multi-select"
                                      classNamePrefix="border-radius select"
                                      styles={colourStyles}
                                      options={status_option}
                                      value={status}
                                      closeMenuOnSelect={false}
                                      isMulti
                                      onChange={e => setStatus(e)}
                                    />
                                  </div>
                                  <div className="col-md-3 mb-2">
                                    <label htmlFor="">Assigned</label>
                                    {country.value == "2" ? (
                                      <Select
                                        placeholder="Select Assigned"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={useruk_option}
                                        value={assign}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setAssign(e)}
                                      />
                                    ) : country.value == "1" ? (
                                      <Select
                                        placeholder="Select Assigned"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={userIn_option}
                                        value={assign}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setAssign(e)}
                                      />
                                    ) : (
                                      <Select
                                        placeholder="Select Assigned"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={alluser_option}
                                        value={assign}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        onChange={e => setAssign(e)}
                                      />
                                    )}
                                  </div>
                                  <div className="col-md-3 mt-2">
                                    <label htmlFor="">Lead Type</label>
                                    <Select
                                      placeholder="Select Status"
                                      className="basic-multi-select"
                                      classNamePrefix="border-radius select"
                                      styles={colourStyles}
                                      options={type_option}
                                      closeMenuOnSelect={false}
                                      value={type}
                                      onChange={e => setType(e)}
                                      isMulti
                                    />
                                  </div>
                                  <div className="col-md-3 mt-2">
                                    <label htmlFor="">Industry</label>
                                    <Select
                                      placeholder="Select Status"
                                      className="basic-multi-select"
                                      classNamePrefix="border-radius select"
                                      styles={colourStyles}
                                      options={industry_option}
                                      closeMenuOnSelect={false}
                                      value={industry}
                                      onChange={e => setIndustry(e)}
                                      isMulti
                                    />
                                  </div>
                                  <div className="col-md-3 mt-2">
                                    <label htmlFor="">
                                      Save and Clear Filters
                                    </label>
                                    <div>
                                      <a
                                        className="btn border-radius btn-primary mr-10"
                                        onClick={onSaveFilter}
                                        style={{
                                          fontSize: "11px",
                                          backgroundColor: "#93a6ff",
                                          borderColor: "#93a6ff",
                                          color: "black",
                                        }}
                                      >
                                        Save Filters
                                      </a>
                                      <a
                                        className="btn border-radius btn-danger"
                                        onClick={onClearFilter}
                                        style={{
                                          fontSize: "11px",
                                          backgroundColor: "#fed36d",
                                          borderColor: "#fed36d",
                                          color: "black",
                                        }}
                                      >
                                        Clear Filters
                                      </a>
                                    </div>
                                  </div>
                                  <div className="d-flex col-md-3 align-items-end justify-content-end">
                                    <div className="mr-10">
                                      <button className="btn border-radius btn-purple">
                                        <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                                        Filter
                                      </button>
                                    </div>
                                    <div className="">
                                      <a
                                        className="btn border-radius btn-red"
                                        onClick={e => {
                                          e.preventDefault()
                                          setDatas(displayData)
                                          setFormPopup(!formPopup)
                                        }}
                                      >
                                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                                        Close
                                      </a>
                                    </div>
                                  </div>
                                </Row>
                              </Row>
                            </Form>
                            <i className="bi bi-triangle-fill pop-icon"></i>
                          </div>
                        ) : null}
                      </div>
                      <Row className="justify-content-md-end justify-content-center align-items-center">
                        <NewPagination
                          gotoPage={gotoPage}
                          canPreviousPag={canPreviousPage}
                          canNextPage={canNextPage}
                          pageIndex={pageIndex}
                          pageOptions={pageOptions}
                          pageSize={pageSize}
                          page={page}
                          data={data}
                        />
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default FavoriteLead
