import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap"
// assets
import "../../../../assets/css/Sales/Sales.css"
import moment from "moment"

const ViewActivity = ({ logData }) => {
  const [old_data, setOldData] = useState({})
  const [new_data, setNewData] = useState({})
  const [viewData, setViewData] = useState(false)

  const labelobj = Object.keys(old_data)
  const oldValue = Object.values(old_data)
  const newValue = Object.values(new_data)

  return (
    <React.Fragment>
      <div className="mb-3">
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-5">My Activity</CardTitle>
                  <div>
                    <ul className="verti-timeline list-unstyled">
                      {/* Activities */}
                      {logData.map((status, key) => {
                        return (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <i
                                className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${
                                  status.id === 3 ? "bx-fade-right" : ""
                                }`}
                              />
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div className="d-flex align-items-center">
                                  <div className="">
                                    <h5>{status.description}</h5>
                                    <p className="text-muted">
                                      At {moment(status.created_at.slice(0, 10)).format("DD-MM-YYYY")} By{" "}
                                      {status.added_by}
                                    </p>
                                  </div>
                                  {status.new_data != null ? (
                                    <div className="ml-30">
                                      <a
                                        className="btn btn-sm activity-log-btn text-info"
                                        onClick={() => {
                                          setOldData(
                                            JSON.parse(status.old_data)
                                          )
                                          setNewData(
                                            JSON.parse(status.new_data)
                                          )
                                          setViewData(true)
                                        }}
                                      >
                                        <i className="fas fa-eye"></i> View
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <p className="timing">
                                {status.created_at.slice(10)}
                              </p>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal size="md" centered isOpen={viewData} className="activity-log"  >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Log Details</h5>
            <button
              type="button"
              onClick={() => {
                setViewData(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="table-responsive">
              <table className="table mb-0 table">
                <thead className="table-light">
                  <tr>
                    <th>Fields</th>
                    <th>Old Data</th>
                    <th>New Data</th>
                  </tr>
                </thead>
                <tbody>
                  {labelobj.map((items, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{items}</th>
                        <td>
                          <div
                            className="no-padding-desc"
                            dangerouslySetInnerHTML={{
                              __html: oldValue[index],
                            }}
                          />
                        </td>
                        <td>
                          <div
                            className="no-padding-desc"
                            dangerouslySetInnerHTML={{
                              __html: newValue[index],
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default ViewActivity
