import React, { useState, useId, useEffect } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Col, Container, Input, Label, Row } from "reactstrap"
import { Form, useNavigate, useParams } from "react-router-dom"
import editTabImg from "../../../../assets/images/credential/edit-img.png"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"

const EditCredential = () => {
  const [fixedContent, setFixedContent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sequence, setSequence] = useState("")
  const [tabName, setTabName] = useState("")
  const [projectAcc, setProjectAcc] = useState(null)
  const [inputFields_1, setInputFields1] = useState([{}])
  const [formRows, setFormRows] = useState([
    // { id: 1, fieldName: "", status: 0 },
  ])
  const tabId = useParams()
  const navigate = useNavigate()
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  document.title = "Credentials | Zithas Crm"
  // tab_id,tab_name,sequence,field_name[],show_field[]

  const handleSubmit = async e => {
    e.preventDefault()

    const fieldNames = formRows.map(item => item.fieldName.trim());
    const hasDuplicates = new Set(fieldNames).size !== fieldNames.length;
    if (hasDuplicates) {
      Swal.fire({
        icon: "warning",
        text: "Duplicate field names are not allowed.",
      });
    } else { 
      setLoading(true)
      const formData = new FormData()
      const authId = localStorage.getItem("auth_id")
      formData.append("user_id", authId)
      formData.append("tab_id", tabId.id)
      formData.append("tab_name", tabName)
      formData.append("sequence", sequence)
      formData.append(
        "field_name",
        formRows
          .map(items => {
            return items.fieldName
          })
          .toString()
      )
      formData.append(
        "show_field",
        formRows
          .map(items => {
            return items.status
          })
          .toString()
      )
      formData.append("project_access", projectAcc)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/credentials/tab/edit`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          console.log("credentials/tab/edit", response)
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Tab Edited Successfully.",
          }).then(() => {
            navigate(`${configure.appUrl}setting/generalsetting`)
            // setFormRows([{ id: 1, fieldName: "", status: 0 }])
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
  }

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    modifiedRows.push({
      id: modifiedRows.length + 1,
      fieldName: "",
      status: 0,
    })
    setFormRows(modifiedRows)
    setInputFields1([...inputFields_1, {}])
  }

  const onDeleteRow = e => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You want to delete this field.",
      showCancelButton: true,
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        const { id } = e.target
        const updatedRows = formRows.filter(
          (row, index) => index.toString() !== id
        )
        setFormRows(updatedRows)
      }
    })
  }

  const onFieldName = (e, index) => {
    const { value } = e.target
    const updatedRows = [...formRows]
    updatedRows[index].fieldName = value // Assuming 'fieldName' is a property in your object
    setFormRows(updatedRows)
  }
  const onCheck = (e, index) => {
    const { checked } = e.target
    const updatedRows = [...formRows]
    updatedRows[index].status = checked ? 1 : 0 // Assuming 'status' is a property in your object
    setFormRows(updatedRows)
  }

  const getData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/credentials/tab`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const filterData = response?.data?.data?.tabs?.filter(items => {
          return items.id == tabId.id
        })
        setSequence(filterData[0]?.sequence)
        setTabName(filterData[0]?.tab_name)
        setProjectAcc(filterData[0]?.project_access)
        let modifyData
        if (filterData[0]?.fields !== null) {
          modifyData = filterData[0]?.fields?.map((items, index) => {
            return {
              id: index + 1,
              fieldName: items.field_label,
              status: items.show_field,
            }
          })
          setFormRows(modifyData)
        }
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const handleCheckboxChange = e => {
    e.target.checked = !e.target.checked
    const newValue = e.target.checked ? 1 : 0
    setProjectAcc(newValue)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Edit Credentials" breadcrumbItem="Credentials" />

          <section className="bg-white">
            <form onSubmit={handleSubmit}>
              <Row className="">
                <Col lg={4} className="img-container">
                  <div className="d-flex justify-content-center align-items-end pt-4 px-4">
                    {/* <div className={`${fixedContent ? "editTabImg" : ""}`}> */}
                    <div className="editTabImg">
                      <img
                        src={editTabImg}
                        alt="oppImg"
                        className="w-100 shadow-sm"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <Row className="pt-4 pe-4">
                    <Col md={3}>
                      <div>
                        <div>
                          <h4 className="text-dark mb-0">Tab Details</h4>
                        </div>
                      </div>
                    </Col>
                    <Col md={9}>
                      {/* fixed buttons */}
                      <div
                        className={`flex-column fixed-buttons pt-2 ${
                          fixedContent ? "d-flex" : "d-none"
                        }`}
                      >
                        <button
                          onClick={() => navigate(-1)}
                          className="btn btn-save fix-btn btn-label pe-0"
                        >
                          <i className="fas fa-save me-2 icon-center label-icon"></i>
                          <span>Save</span>
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-red  fix-btn btn-label pe-0"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          <span>Cancel</span>
                        </button>
                      </div>
                      <div className="d-flex justify-content-end">
                        <div className="text-md-end">
                          <button className="btn text-white text-uppercase  btn-save btn-label">
                            <i className="fas fa-save me-2 icon-size label-icon"></i>
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className=" btn ms-md-2 btn-red btn-label"
                          >
                            <i className="dripicons-cross me-2 icon-center label-icon"></i>
                            CANCEL
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <div>
                      <div className="row align-items-end">
                        <div className="mb-3 col-md-5">
                          <Label htmlFor="formrow-firstname-Input">
                            Sequence <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Sequence Name"
                            value={sequence}
                            required
                            onChange={e => setSequence(e.target.value)}
                          />
                        </div>
                        <div className="mb-3 col-md-5">
                          <Label htmlFor="formrow-firstname-Input">
                            Tab Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Tab Name"
                            value={tabName}
                            required
                            onChange={e => setTabName(e.target.value)}
                          />
                        </div>
                        <div className="mb-3 col-md-2">
                          <Label htmlFor="formrow-firstname-Input">
                            Project Access{" "}
                          </Label>
                          <div className="form-switch form-switch-md pt-2">
                            <input
                              type="checkbox"
                              className="form-check-input first_global"
                              checked={projectAcc == "1"}
                              onClick={e => {
                                handleCheckboxChange(e)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <h4 className="text-dark">Field Details</h4>
                      </div>
                      <div className="pe-4">
                        <button
                          type="button"
                          className="btn btn-success add_row btn-label"
                          onClick={() => onAddFormRow()}
                        >
                          <i
                            className="fa fa-plus label-icon"
                            aria-hidden="true"
                          ></i>{" "}
                          Add Field
                        </button>
                      </div>
                    </div>

                    <table className="table table-bordered mt-4 shadow table">
                      <thead>
                        <tr>
                          <th className="text-center">id</th>
                          <th>
                            Field Name <span className="text-danger">*</span>
                          </th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(formRows || []).map((obj, index) => {
                          console.log("obj",obj)
                          return (
                            <tr key={obj.id}>
                              <td className="text-center">{index + 1}</td>
                              <td>
                                <Input
                                  type="text"
                                  name="FieldName"
                                  className="form-control"
                                  id={`formrow-firstname-${index}-Input`}
                                  placeholder="Enter Field Name"
                                  value={obj.fieldName || ""}
                                  required
                                  onChange={e => onFieldName(e, index)}
                                />
                              </td>
                              <td className="text-center">
                                <div className="form-switch form-switch-md">
                                  <input
                                    type="checkbox"
                                    name="status"
                                    className="form-check-input first_global"
                                    defaultChecked={
                                      obj.status == 0 ? false : true
                                    }
                                    onChange={e => onCheck(e, index)}
                                  />
                                </div>
                              </td>
                              <td className="text-center">
                                <button
                                  type="button"
                                  id={index}
                                  onClick={onDeleteRow}
                                  className="btn btn-sm  btn-outline-danger"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                        {formRows.length === 0 && (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Field found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </form>
          </section>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditCredential
