import configure from "configure"
import React from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  if (
    !localStorage.getItem("auth_id") &&
    !localStorage.getItem("auth_name") &&
    !localStorage.getItem("auth_role") &&
    !localStorage.getItem("auth_avt")
  ) {
    return (
      <Navigate
        to={{
          pathname: `${configure.appUrl}login`,
          state: { from: props.location },
        }}
      />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
