import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Loader from "pages/Separate/Loader"
import "../../assets/css/Profile/Profile.css"
import moment from "moment"
import WorkExperience from "./WorkExperience"
import Credential from "./Credential"
import Document from "./Document"
import axios from "axios"
import configure from "configure"
import profile from "store/auth/profile/reducer"

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [profileData, setProfileData] = useState([])
  const [birthDay, setBirthDay] = useState(false)

  // const[userName, setUserName] = useState(localStorage.getItem('auth_name'));
  const [userName, setUserName] = useState("Zain Marchawala")

  document.title = "Profile | Zithas Crm"

  const fetchStaff = async () => {
    setIsLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const id = localStorage.getItem("auth_id")
        const staffData = response.data.data?.filter(obj => {
          return obj.id == id
        })
        console.log("ProfileData", staffData)
        setProfileData(staffData[0])
        setIsLoading(false)
        const currentDate = moment().format("DD-MM")
        const makebirthDate = new Date(staffData[0]?.dob)
        const birthDate = moment(makebirthDate).format("DD-MM")
        const isBirthMonth = currentDate === birthDate
        if (isBirthMonth) {
          setBirthDay(true)
        }
      }
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchStaff()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs title={"Profile"} breadcrumbItem={"Profile"} />
          <Row>
            <Col lg={12} className="">
              <Card>
                <CardBody className="">
                  <div className="responsive-table">
                    <div className="profile-section" id="page-content">
                      <div className="">
                        <div className="row d-flex mb-4">
                          <div className="col-md-6">
                            <div
                              className="shadow-sm  profile-box"
                              style={{ backgroundColor: "#f9f5fb" }}
                            >
                              <div className="d-flex">
                                <div className="col-sm-4 bg-c-lite-green user-profile px-0">
                                  <div className="py-5 text-center text-white">
                                    <div className="m-b-20 d-flex justify-content-center profile">
                                      <img
                                        // src={`https://mars.zithas.com/application/public/${localStorage.getItem(
                                        //   "auth_avt"
                                        // )}`}
                                        src={`${configure.imgUrl}${profileData?.avatar}`}
                                        width={80}
                                        height={85}
                                        className="object-fit-cover"
                                        alt="User-Profile-Image"
                                      />
                                    </div>
                                    <h6
                                      className={`f-w-600 ${
                                        profileData?.name?.length > 6
                                          ? "smallFont"
                                          : "MidumFont"
                                      } `}
                                    >
                                      {/* {userName} */}
                                      {profileData?.name}
                                    </h6>
                                    <p className="f-w-600">
                                      {profileData?.designation}
                                    </p>
                                  </div>
                                  {birthDay && (
                                    <div className="text-center text-white ">
                                      Happy Birthday {profileData?.name}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`col-sm-8 ${
                                    birthDay && "bg-birthday"
                                  } `}
                                >
                                  <div className="card-block">
                                    <h6 className="m-b-20 p-b-5 b-b-default f-w-600 fs-3">
                                      Basic Information
                                    </h6>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <h5 className="m-b-10 f-w-600">
                                          Email
                                        </h5>
                                        <h6 className="text-muted f-w-400 m-0">
                                          {profileData?.email}
                                        </h6>
                                      </div>
                                      <div className="col-sm-6">
                                        <h5 className="m-b-10 f-w-600">
                                          Phone
                                        </h5>
                                        <h6 className="text-muted f-w-400 m-0">
                                          {profileData?.phone_number}
                                        </h6>
                                      </div>
                                    </div>
                                    <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <h5 className="m-b-10 f-w-600">
                                          Employee Id
                                        </h5>
                                        <h6 className="text-muted f-w-400 m-0">
                                          {profileData?.employee_code !== null
                                            ? profileData?.employee_code
                                            : "N/A"}
                                        </h6>
                                      </div>
                                      <div className="col-sm-6">
                                        <h5 className="m-b-10 f-w-600">
                                          Punch Id
                                        </h5>
                                        <h6 className="text-muted f-w-400 m-0">
                                          {profileData?.punchid !== null
                                            ? profileData?.punchid
                                            : "N/A"}
                                        </h6>
                                      </div>
                                    </div>
                                    <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <h5 className="m-b-10 f-w-600">
                                          Date Of Joining
                                        </h5>
                                        <h6 className="text-muted f-w-400">
                                          {profileData?.date_of_joining !== null
                                            ? moment(
                                                profileData?.date_of_joining
                                              ).format("DD-MM-YYYY")
                                            : "N/A"}
                                        </h6>
                                      </div>
                                      <div className="col-sm-6">
                                        <h5 className="m-b-10 f-w-600 ">
                                          Birth Date
                                        </h5>
                                        <h6 className="text-muted f-w-400">
                                          {profileData?.dob !== null
                                            ? moment(profileData?.dob).format(
                                                "DD-MM-YYYY"
                                              )
                                            : "N/A"}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6" id="WorkExperience">
                            <Credential profileData={profileData} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <Document setIsLoading={setIsLoading} />
                          </div>
                          <div className="col-md-6">
                            <WorkExperience profileData={profileData} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile
