import React, { useEffect, useMemo, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Modal
} from "reactstrap";

import { Link, useNavigate } from 'react-router-dom';

// css
// import '../../../../assets/css/Settings/Setting.css';
// import "../../../../assets/css/Marketing/Marketing.css"
import axios from 'axios';
import configure from 'configure';
import Loader from 'pages/Separate/Loader';
import swal from 'sweetalert';


const Contact = (props) => {

    const [testingRows, setTestingRows] = useState([{ id: 1 }])
    const [inputData, setInputData] = useState([{
        id: 1,
        name: "",
        dob: "",
        position: "",
        email: "",
        phone: ""
    }])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setInputData(inputData?.map((item, index) => ({ ...item, id: index + 1 })))
        // console.log("checking", inputData)
    }, [testingRows])

    useEffect(() => {
        const getContact = async () => {
            setLoading(true)
            try {
                const formData = new FormData()
                formData.append("opportunity_id", props.company_id)

                const response = await axios.post(`${configure.apiUrl}/view_opportunity_contact`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                })

                // console.log(response)

                if (response.data.data[0]?.person_details != null) {
                    const temp = JSON.parse(response.data.data[0]?.person_details)

                    let tempRows = []
                    temp.forEach((element, i) => {
                        tempRows.push({ id: i + 1 })
                    });

                    setInputData(temp)
                    setTestingRows(tempRows)

                }
                setLoading(false)


            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        getContact()
    }, [])

    const navigate = useNavigate()

    // form repeater
    const onAddRow = () => {
        const modifiedRows = [...testingRows];
        modifiedRows.push({ id: modifiedRows.length + 1 });
        setTestingRows(modifiedRows);

        setInputData([...inputData, {
            id: modifiedRows.length,
            name: "",
            dob: "",
            position: "",
            email: "",
            phone: ""
        }])
    };

    const onDeleteRow = (id) => {
        var modifiedRows = [...testingRows];
        modifiedRows = modifiedRows.filter(x => x["id"] !== id);
        setTestingRows(modifiedRows.map((item, index) => ({ ...item, id: index + 1 })));

        setInputData(
            (prevForms) => {
                const updatedForms = [...prevForms];
                return updatedForms.filter((serv, i) => serv.id !== id);
            }
        )
    };
    //   =================================

    // collect Technologies data
    const handleFormChange = (index, field, value) => {
        // Update the value of a specific field in a form at the specified index
        setInputData((prevForms) => {
            const updatedForms = [...prevForms];
            updatedForms[index][field] = value;
            return updatedForms;
        });
    };
    // ==================================

    // add contact
    const handleSaveContact = async () => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("person_details", JSON.stringify(inputData))
            formData.append("opportunity_id", props.company_id)

            const response = await axios.post(`${configure.apiUrl}/opportunity_contact`, formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })

            const temp = JSON.parse(response.data.data[0]?.person_details)

            let tempRows = []
            temp.forEach((element, i) => {
                tempRows.push({ id: i + 1 })
            });

            setInputData(temp)
            setTestingRows(tempRows)

            setLoading(false)
            swal("Contact Saved successfully!", {
                icon: "success",
            })

        } catch (error) {
            console.log(error)
            setLoading(false)
            swal("Something went wrong", "", "error")
        }
    }

    return (
        <>
            <div>
                {
                    loading && <Loader />
                }
                <Row className='ms-0'>
                    <Col lg={12} className='ps-0'>
                        <Card>
                            <CardBody>
                                <div className='d-flex justify-content-between mb-4'>
                                    <h5>
                                        Contact
                                    </h5>
                                    <div className='text-end'>
                                        <button onClick={onAddRow} className='border-radius btn btn-purple'>
                                            <i className="fas fa-plus me-2 icon-size"></i>Add Contact
                                        </button>
                                        <button onClick={handleSaveContact} className='border-radius btn btn-purple ms-2'>
                                            <i className="fas fa-save me-2 icon-size"></i>Save
                                        </button>
                                        <button onClick={() => navigate(-1)} className="border-radius btn ms-md-2 btn-red">
                                            <i className='dripicons-cross me-2 icon-center'></i>CANCEL
                                        </button>
                                    </div>
                                </div>

                                <div className="responsive-table">
                                    <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                                        <thead>
                                            <tr>
                                                <th className="col-1 text-center">Id</th>
                                                <th className="tech-price1">Name</th>
                                                <th className="col-2">DOB</th>
                                                <th className="tech-price1">Position</th>
                                                <th className="tech-price1">Email</th>
                                                <th className="tech-price1">Phone</th>
                                                <th className="tech-price1">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(testingRows || [])?.map((testingRow, key) => (
                                                <tr key={testingRow.id}>
                                                    <td className='col-1 text-center'>{testingRow.id}</td>
                                                    <td className="">
                                                        <input
                                                            type="text"
                                                            className="form-control border-radius"
                                                            value={inputData[key]?.name}
                                                            onChange={e => handleFormChange(key, "name", e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="">
                                                        <input
                                                            type="date"
                                                            className="form-control border-radius"
                                                            value={inputData[key]?.dob}
                                                            onChange={e => handleFormChange(key, "dob", e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="">
                                                        <input
                                                            type="text"
                                                            className="form-control border-radius"
                                                            value={inputData[key]?.position}
                                                            onChange={e => handleFormChange(key, "position", e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="">
                                                        <input
                                                            type="text"
                                                            className="form-control border-radius"
                                                            value={inputData[key]?.email}
                                                            onChange={e => handleFormChange(key, "email", e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="">
                                                        <input
                                                            type="text"
                                                            className="form-control border-radius"
                                                            value={inputData[key]?.phone}
                                                            onChange={e => handleFormChange(key, "phone", e.target.value)}
                                                        />
                                                    </td>
                                                    <td className="text-center">
                                                        {key !== 0 ? (
                                                            <a
                                                                onClick={() => onDeleteRow(testingRow.id)}
                                                                className="btn btn-danger"
                                                            >
                                                                <i className="fas fa-trash-alt"></i>
                                                            </a>
                                                        ) : (
                                                            "--"
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Contact