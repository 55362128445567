import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/css/Staff/Staff.css"
import "../../assets/css/Credential/Credential.css"
import credentialImg from "../../assets/images/credentials.png"
import { Link } from "react-router-dom"
import configure from "configure"
import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
} from "reactstrap"
import { Dropdown } from "bootstrap"
import classnames from "classnames"
import HostingPanelLive from "./Tabs/HostingPanelLive"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import "../../assets/css/Scollbar.css"
import SimpleBar from "simplebar-react"

// components

const Crendential = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [credId, setCreID] = useState("")
  const [isCollapse, setIsCollapse] = useState(true)
  const [loading, setLoading] = useState(true)
  const [navItemData, setNavItemData] = useState([])
  const [allData, setAllData] = useState([])
  const [projectOption, setProjectOption] = useState([])
  const [tableData, setTableData] = useState({})
  const [collapseOpen, setCollapseOpen] = useState({})
  const [modal_Access, setAccessDenied] = useState(false)
  const [isIndex, setIsIndex] = useState(false)

  document.title = "Credentials | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [fixedSideTab, setFixedSideTab] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  const fatchAllTabData = async () => {
    // knowledgeTopics
    setLoading(true)
    // role_id,user_id
    const roleId = localStorage.getItem("auth_role")
    const authId = localStorage.getItem("auth_id")
    const formData = new FormData()
    formData.append("role_id", roleId)
    formData.append("user_id", authId)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/credentials`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        setverticalActiveTab(response?.data?.data[0]?.id)
        setCreID(response?.data?.data[0]?.id)
        setTableData(response?.data?.data[0])
        setNavItemData(response?.data?.data)
        setAllData(response?.data?.data)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchAllProjects = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/getProjects`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const createProjectOption = response.data.data?.map(items => {
          return {
            label: items.project_name,
            value: items.id,
          }
        })
        setProjectOption(createProjectOption)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchAllProjects()
  }, [])
  useEffect(() => {
    fatchAllTabData()
  }, [])

  const handleHover = () => {
    setIsIndex(true)
  }
  const handleLeave = () => {
    setIsIndex(false)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Credentials" breadcrumbItem="Credentials" />
          <div id="credentialPage">
            <Row
              className={`gx-4 justify-content-between di-flex align-items-top `}
              id="cred"
            >
              <div
                className={`px-0 sidebar-containerr ${
                  fixedSideTab && "credtabFix"
                }`}
                style={isIndex ? { zIndex: "2" } : {}}
              >
                <div className="position-relative">
                  <div>
                    <SimpleBar
                      style={{ height: "70vh" }}
                      className={`${fixedSideTab && "fix-simplebar"}`}
                    >
                      <Nav
                        pills
                        className={`${
                          fixedSideTab && "credtabFix-ul"
                        }  flex-column px-1 py-1 h-100`}
                      >
                        {navItemData?.map(items => {
                          function getInitials(text) {
                            let words = text.split(" ")
                            let initials = ""
                            words.forEach(word => {
                              let initial = word.charAt(0).toUpperCase()
                              initials += initial
                            })
                            return initials
                          }
                          let initials = getInitials(items.tab_name)
                          return (
                            <NavItem
                              // className="d-flex flex-column gap-2 mb-2"
                              key={items.id}
                              className={classnames({
                                "d-flex flex-column gap-2 mb-2": true,
                                active: verticalActiveTab == `${items.id}`,
                              })}
                              title={items.tab_name}
                            >
                              <NavLink
                                // className="text-center crendential"
                                onClick={() => {
                                  setCreID(items.id)
                                  setverticalActiveTab(items.id)
                                  setTableData(items)
                                }}
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  "text-center crendential px-0": true,
                                  active: verticalActiveTab == `${items.id}`,
                                })}
                                onMouseEnter={handleHover}
                                onMouseLeave={handleLeave}
                              >
                                {initials}
                                <span className="link_name border-start border-white border-2">
                                  {items.tab_name}
                                </span>
                              </NavLink>
                              {/* hOVER */}
                              {/* <span className="link_name">
                                {items.tab_name}
                              </span> */}
                            </NavItem>
                          )
                        })}
                      </Nav>
                    </SimpleBar>
                  </div>
                </div>
              </div>
              <Col
                md={isCollapse ? "11" : "10"}
                className={`z-1 pe-0 credential-table ${
                  isCollapse && "width-94 ps-0"
                }`}
              >
                <div className="card p-4 h-100">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId={verticalActiveTab}>
                      <HostingPanelLive
                        verticalActiveTab={verticalActiveTab}
                        tableData={tableData}
                        credId={credId}
                        setLoading={setLoading}
                        setTableData={setTableData}
                        setNavItemData={setNavItemData}
                        projectOption={projectOption}
                        setverticalActiveTab={setverticalActiveTab}
                        navItemData={navItemData}
                        allData={allData}
                        setAllData={setAllData}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Crendential
