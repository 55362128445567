import React, { useEffect, useMemo, useState } from "react"
import { Row, Col, Card, CardBody, Table } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"

function TableContainer({ columns, data }) {
  const [fixedHeader, setFixedHeader] = useState(false)

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  const params = useParams()

  // Render the UI for your table
  return (
    <div>
      <Row className="mb-3 justify-content-between">
        <Col md={2}>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <div className="me-3">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="text-end">
              <Link
                to={`${configure.appUrl}sales/add-contact/${params.id}`}
                className=" btn ms-md-2 btn-save btn-label"
              >
                <i className="fas fa-plus me-1 icon-size label-icon"></i>{" "}
                Contact
              </Link>
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="table table-bordered payment-table"
        {...getTableProps()}
      >
        <thead className={`${fixedHeader ? "fix-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <i
                      title="info"
                      className="bx bx-info-circle ms-1 info-icon"
                    ></i>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          {data.length == 0 ? (
            <td
              colSpan="5"
              className="text-center"
              style={{ padding: "10px", border: "none" }}
            >
              No Contacts Available for this Customer
            </td>
          ) : null}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </div>
  )
}

const Contacts = ({ verticalActiveTab }) => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  const [data, setDatas] = useState([])

  useEffect(() => {
    if (verticalActiveTab == "2") {
      fetchContact()
    }
  }, [verticalActiveTab])

  const fetchContact = async e => {
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/contacts`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data.contacts)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isInfoIcon: true,
      },
      {
        Header: "Email",
        accessor: "email",
        isInfoIcon: true,
      },
      {
        Header: "Position",
        accessor: "position",
        isInfoIcon: true,
      },
      {
        Header: "Phone",
        accessor: "phone",
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          const onDeleteContact = e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this Contact !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("contact_id", cellProps.row.original.id)
                formData.append("customer_id", params.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_contact`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Contact Deleted Successfully",
                    }).then(data => {
                      setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }
          return (
            <div>
              <Link
                to={`${configure.appUrl}sales/edit-contact/${cellProps.row.original.id}`}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <a
                onClick={onDeleteContact}
                className="btn btn-sm action-btn btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div>
        {isLoading && <Loader />}
        <div>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <Link to="#" className="btn btn-purple  fix-btn">
              <i className="fas fa-plus me-2 icon-center"></i>
              <span>Contact</span>
            </Link>
            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Contacts
