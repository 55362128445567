const configure = {
  apiUrl: "https://api.zithas.com/api",
  imgUrl: "https://mars.zithas.com/application/public",
  appUrl: "/",
  type: 2,

  // apiUrl: "https://mars.ztpl.net/api",
  //  apiUrl: "http://192.168.0.2/earthbms/admin/api",
}

export default configure
