export function copyToClipBoard(index) {
  const btn = document.getElementsByClassName("copyBtn")[index]
  window.navigator.clipboard.writeText(btn.value)
  
  btn.classList.remove("fa-copy")
  btn.style.fontFamily = "Poppins, sans-serif"
  btn.innerText = "Copied!"
  setTimeout(() => {
    btn.innerText = ""
    btn.classList.add("fa-copy")
    btn.style.fontFamily = ""
  }, 1000)
}
