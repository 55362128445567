import Pagi2 from "components/Common/Pagi2"
import Loader from "pages/Separate/Loader"
import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTable, useGlobalFilter, usePagination } from "react-table"
import { Card, CardBody, Col, Container, Row, Table, Form } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import configure from "configure"
// import auditRole from "../../assets/images/audit-log-roles.avif"
import auditRole from "../../assets/images/role-3.avif"
import "../../assets/css/Audit-log/roles.css"

const RolesPermission = () => {
  const [fixedContent, setFixedContent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [formPopup, setFormPopup] = useState(false)

  // FOr Filter
  const rowData = React.useMemo(
    () => [
      {
        date: "11/23/2022 02:58:12",
      },
      {
        date: "09/29/2022 07:58:12",
      },
      {
        date: "10/13/2022 19:58:12",
      },
    ],
    []
  )

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const getData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${configure.apiUrl}/rolesLog`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        setData(response.data.data)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "px-0 text-center",
      },

      {
        Header: "Name",
        accessor: "name",
        isInfoIcon: true,
        className: "",
        Cell: cellProps => {
          return (
            <span>
              {cellProps.row.original !== null
                ? cellProps.row.original.name
                : "-"}
            </span>
          )
        },
      },
      {
        Header: "Login Time",
        accessor: "login_time",
        isInfoIcon: true,
        className: "",
      },
      {
        Header: "Ip Address",
        accessor: "ip_address",
        isInfoIcon: true,
        className: "px-0 text-center t-column-4",
      },
      {
        Header: "Action",
        accessor: "join_url",
        className: "px-0 text-center",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              <div>
                <button className="btn btn-outline-secondary" title="View">
                  <i className="fas fa-eye"></i>
                </button>
              </div>
            </div>
          )
        },
      },
    ],
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Audit Log"}
            breadcrumbItem={"Roles & Permission"}
          />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <div>
                      {/* fixed buttons */}
                      <div
                        className={`flex-column fixed-buttons pt-2 ${
                          fixedContent ? "d-flex" : "d-none"
                        }`}
                      >
                        <button
                          onClick={() => navigate(-1)}
                          className="btn btn-red border-radius fix-btn btn-label"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          <span>Cancel</span>
                        </button>
                      </div>
                      <Row className="mb-2">
                        <Col>
                          <select
                            className="border-radius me-4 form-select"
                            style={{ width: "100px" }}
                            value={pageSize}
                            onChange={e => setPageSize(e.target.value)}
                          >
                            {[10, 25, 50, 100].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <div>
                            <button
                              onClick={() => setFormPopup(!formPopup)}
                              className="btn btn-outline-secondary filter-btn me-3"
                            >
                              <i className="fas fa-filter"></i>
                            </button>
                          </div>
                          <div className="me-4">
                            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                              <div className="position-relative">
                                <label
                                  htmlFor="search-bar-0"
                                  className="search-label"
                                >
                                  <span
                                    id="search-bar-0-label"
                                    className="sr-only"
                                  >
                                    Search this table
                                  </span>
                                  <input
                                    onChange={e =>
                                      setGlobalFilter(e.target.value)
                                    }
                                    id="search-bar-0"
                                    type="text"
                                    className="border-radius form-control rounded h-100"
                                    placeholder={`Search...`}
                                    value={globalFilter || ""}
                                  />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="text-md-end">
                            <button
                              onClick={() => navigate(-1)}
                              className="border-radius btn btn-red btn-label"
                            >
                              <i className="dripicons-cross me-2 icon-center lab label-icon"></i>
                              CANCEL
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-md-3 d-flex justify-content-center pt-4">
                          <div className={`${fixedContent ? "auditRole" : ""}`}>
                            <img
                              src={auditRole}
                              alt="auditRole"
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="position-relative shadow">
                            <Table
                              className="table table-bordered mt-4 table-roles-audit"
                              {...getTableProps()}
                              id="Audit-roles"
                            >
                              <thead
                                className={`${
                                  fixedContent ? "Audit-fix-header" : ""
                                }`}
                              >
                                {headerGroups.map((headerGroup, i) => (
                                  <tr
                                    key={i}
                                    {...headerGroup.getHeaderGroupProps()}
                                  >
                                    {headerGroup.headers.map(
                                      (column, index) => (
                                        <th
                                          key={index}
                                          className={`t-head-${
                                            index + 1
                                          } t-column-${index + 1} ${
                                            column.className
                                          }`}
                                          {...column.getHeaderProps()}
                                        >
                                          {column.render("Header")}
                                          {column.isInfoIcon ? (
                                            <i
                                              title="info"
                                              className="bx bx-info-circle ms-1 info-icon"
                                            ></i>
                                          ) : null}
                                        </th>
                                      )
                                    )}
                                  </tr>
                                ))}
                              </thead>

                              <tbody className="" {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                  prepareRow(row)
                                  return (
                                    <tr
                                      className="row-hover"
                                      {...row.getRowProps()}
                                      key={i}
                                    >
                                      {row.cells.map((cell, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className={`t-head-${index + 1} ${
                                              cell.column.className
                                            }`}
                                            {...cell.getCellProps()}
                                          >
                                            {cell.render("Cell")}
                                          </td>
                                        )
                                      })}
                                    </tr>
                                  )
                                })}
                                {page.length == 0 && (
                                  <tr>
                                    <td colSpan={8} className="text-center">
                                      {" "}
                                      No Record Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Row>
                      {formPopup ? (
                        <div id="formPopup">
                          <div className="popup-form p-4">
                            <Form>
                              <Row className="align-items-center">
                                <Row
                                  className="d-flex pe-0 me-0 justify-content-center"
                                  style={{ width: "100%" }}
                                >
                                  <div className="d-flex align-items-start justify-content-start mb-3 w-80 p-0 ">
                                    <div className="col-md-5  d-flex align-items-center justify-content-space-between">
                                      <label htmlFor="" className="fs-5">
                                        From
                                      </label>
                                      <input
                                        type="date"
                                        className="dateIntput"
                                      />
                                    </div>
                                    <div className="col-md-5 d-flex align-items-center justify-content-space-between">
                                      <label htmlFor="" className="fs-5">
                                        To
                                      </label>
                                      <input
                                        type="date"
                                        className="dateIntput"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-end justify-content-start p-0">
                                    <div className="mr-10">
                                      <button className="btn  btn-purple">
                                        <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                                        Filter
                                      </button>
                                    </div>
                                    <div className="">
                                      <a
                                        className="btn  btn-red"
                                        onClick={e => {
                                          e.preventDefault()
                                          // setDatas(displayData)
                                          setFormPopup(!formPopup)
                                        }}
                                      >
                                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                                        Close
                                      </a>
                                    </div>
                                  </div>
                                </Row>
                              </Row>
                            </Form>
                            <i className="bi bi-triangle-fill pop-icon"></i>
                          </div>
                        </div>
                      ) : null}
                      {/* Pagination */}
                      <Row className="justify-content-md-end justify-content-center align-items-center">
                        <Pagi2
                          gotoPage={gotoPage}
                          previousPage={previousPage}
                          canPreviousPage={canPreviousPage}
                          nextPage={nextPage}
                          canNextPage={canNextPage}
                          pageIndex={pageIndex}
                          pageOptions={pageOptions}
                        />
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RolesPermission
