import React, { useEffect, useState } from "react"
import {
  Badge,
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Document, Page, Text, StyleSheet } from "@react-pdf/renderer"
import India from "../../../assets/images/flags/india.png"
import Uk from "../../../assets/images/flags/uk.png"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import { Table, Row, Col, Card, CardBody, Modal, Form } from "reactstrap"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Contract.css"
import sideImg from "../../../assets/images/profile-img.png"
import AccessDenied from "../../../assets/images/dribbble_1.gif"

// common component
import Pagination from "components/Common/Pagination"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import DownloadContract from "./DownloadContract"
import configure from "configure"
import Select from "react-select"
import CopyToClipboard from "react-copy-to-clipboard"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

// components

function TableContainer({
  columns,
  data,
  onFilter,
  clearFilter,
  onSaveFilter,
  setAccessDenied,
  setLoading,
}) {
  const [fixedContent, setFixedContent] = useState(false)
  const [formPopup, setFormPopup] = useState(false)
  const [location, setLocation] = useState([])
  const [country, setCountry] = useState("")
  const [assign, setAssign] = useState([])
  const [userIn_option, setUserIn] = useState([])
  const [useruk_option, setUserUk] = useState([])
  const [alluser_option, setAllUsers] = useState([])

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  // getting Locations
  useEffect(() => {
    fetchCountry()
    FilterData()
  }, [])

  const fetchCountry = async () => {
    // setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        // console.log("country ",res.data)

        setLocation(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        // setLoading(false)
      })
    } catch (error) {
      console.log(error)
      // setLoading(false)
    }
  }

  const FilterData = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/filter_data`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        setUserIn(
          response.data.data.india_user.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
        setUserUk(
          response.data.data.uk_user.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        const users = [
          ...response.data.data.uk_user,
          ...response.data.data.india_user,
        ]

        const jsonObject = users.map(JSON.stringify)
        const uniqueSet = new Set(jsonObject)
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse)
        setAllUsers(
          uniqueArray.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        // ===============================================
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))

        const res = await axios.post(
          `${configure.apiUrl}/show_contract_filter`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        if (res.data.data !== "No Data found") {
          setCountry(JSON.parse(res.data.data[0].location))
          setAssign(JSON.parse(res.data.data[0].assigned))
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const [name_modal, setNameModal] = useState(false)

  const [title, setTitle] = useState("")

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Contract Module, the  <strong>Assigned</strong>column designates the specific team member or individual responsible for managing and overseeing the contract, ensuring its fulfillment and compliance.</p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong>  column is to establish accountability for the contract's execution and management. It helps streamline contract-related tasks, such as negotiations, amendments, and renewals, by identifying the team member or individual responsible. This column enhances teamwork, ensures effective contract administration within the Mars System, and facilitates timely contract execution.</p>",
    },
    {
      title: "Company",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Contract Module, the <strong>Company</strong>  column represents the name of the organization or business entity with which the contract is established.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Company</strong>  column is to provide specific identification of the contracting party. It ensures that the contract is associated with the correct company, streamlining record-keeping and facilitating clear communication and contract management within the Mars System. </p>",
    },
    {
      title: "Type",
      mean: "<p><strong>Meaning :</strong> The <strong>Type</strong> column in the Mars System's Contract Module categorizes contracts into different types, such as <strong>Website Development</strong>, <strong>Digital marketing</strong>, <strong>Dedicate resource </strong>  or other relevant categories </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Type</strong> column is to classify contracts based on their nature and purpose. It allows for easy categorization and sorting of contracts, ensuring that the Mars System users can quickly access and manage contracts based on their specific type. This column simplifies contract management and facilitates efficient contract retrieval.</p>",
    },
    {
      title: "Amount",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Contract Module, the <strong>Amount</strong> column specifies the currency in which the contract's financial terms and payments are denominated.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Amount</strong>  column is to provide transparency regarding the currency used in the contract's financial transactions. It ensures that both parties involved in the contract are aware of the currency terms, facilitating accurate financial record-keeping and transaction management within the Mars System.</p>",
    },
    {
      title: "Duration",
      mean: "<p><strong>Meaning :</strong> : In the Mars System's Contract Module, the <strong>Duration</strong>  column indicates the period or timeframe covered by the contract, including start and end dates.</p>",
      purpose:
        "<p><strong>Purpose :</strong>The purpose of the <strong>Duration</strong>   column is to specify the contract's temporal scope. It helps users understand when the contract commences and when it expires, facilitating timely contract monitoring, renewal, and compliance tracking within the Mars System.</p>",
    },
    {
      title: "Sent Date",
      mean: " <p><strong>Meaning :</strong>The <strong>Sent Date</strong> column records the date when the contract was sent to the relevant parties for review, approval, or signature.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Sent Date</strong> column is to track the initiation of the contract negotiation and approval process. It provides a reference point for measuring the time it takes for parties to respond and progress through the contract workflow within the Mars System.</p>",
    },
    {
      title: "End Date",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Contract Module, the <strong> End Date</strong> column denotes the date when the contract officially concludes or expires.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong> End Date </strong> column is to provide a clear indication of when the contract's obligations and terms come to an end. It assists in contract management by ensuring that both parties are aware of the contract's termination date within the Mars System.</p>",
    },
    {
      title: "Signature",
      mean: "<p><strong>Meaning :</strong> The <strong>Signature</strong>  column may be used to record whether the contract has been signed or executed by the relevant parties. It can include indicators like <strong>Signed </strong>, <strong>Not Signed </strong>, <strong>Pending </strong>, or <strong>Won </strong>.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Signature</strong> column is to track the status of contract execution. It helps users identify whether the contract has been legally binding and executed by all necessary parties. This column is crucial for ensuring compliance and contract validity within the Mars System.</p>",
    },
  ]

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 5)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}sales/add-contract`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          onClick={() => onAddPermission()}
          className="btn btn-save fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Contract</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end col-md-8">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <button
              onClick={() => setFormPopup(!formPopup)}
              className="btn btn-outline-secondary  me-3"
            >
              <i className="fas fa-filter"></i>
            </button>
            <Link
              onClick={() => onAddPermission()}
              // to={`${configure.appUrl}sales/add-contract`}
              className=" btn text-white me-2 btn-save btn-label"
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Contract
            </Link>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-header" : ""
            } fix-contract-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true)
                          setTitle(column.Header)
                        }}
                      >
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-contract-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        onClick={() =>
                          cell.column.Header === "Company"
                            ? navigate(`${configure.appUrl}sales/edit-contract`)
                            : ""
                        }
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        } ${
                          cell.column.Header === "Signature"
                            ? cell.value === "Not-Signed"
                              ? "text-danger"
                              : "text-success"
                            : ""
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>

        {formPopup ? (
          <div className="popup-form contract-form-filter p-4">
            <Form
              onSubmit={e => {
                e.preventDefault()

                if (assign.length > 0 && country) {
                  setFormPopup(false)
                  onFilter(assign, country)
                } else {
                  Swal.fire(
                    "",
                    "Location and Assigned Fields Required",
                    "warning"
                  )
                }
              }}
            >
              <Row className="align-items-end">
                <Row className="d-flex pe-0 me-0" style={{ width: "100%" }}>
                  <div className="col-md-3 mb-2">
                    <label htmlFor="">Location</label>
                    <Select
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      onChange={e => setCountry(e)}
                      value={country}
                      styles={colourStyles}
                      options={location}
                    />
                  </div>

                  <div className="col-md-3 mb-2">
                    <label htmlFor="">Assigned</label>
                    {country.value == "2" ? (
                      <Select
                        placeholder="Select Assigned"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={useruk_option}
                        value={assign}
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={e => setAssign(e)}
                      />
                    ) : country.value == "1" ? (
                      <Select
                        placeholder="Select Assigned"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={userIn_option}
                        value={assign}
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={e => setAssign(e)}
                      />
                    ) : (
                      <Select
                        placeholder="Select Assigned"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={alluser_option}
                        value={assign}
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={e => setAssign(e)}
                      />
                    )}
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="">Save and Clear Filters</label>
                    <div>
                      <a
                        className="btn  btn-primary mr-10"
                        onClick={() => onSaveFilter(assign, country)}
                        style={{
                          fontSize: "11px",
                          backgroundColor: "#93a6ff",
                          borderColor: "#93a6ff",
                          color: "black",
                        }}
                      >
                        Save Filters
                      </a>
                      <a
                        className="btn  btn-danger"
                        onClick={() => {
                          setAssign([])
                          setCountry("")
                          clearFilter()
                        }}
                        style={{
                          fontSize: "11px",
                          backgroundColor: "#fed36d",
                          borderColor: "#fed36d",
                          color: "black",
                        }}
                      >
                        Clear Filters
                      </a>
                    </div>
                  </div>
                  <div className="d-flex col-md-3 align-items-center justify-content-end mt-3">
                    <div className="mr-10">
                      <button className="btn  btn-purple">
                        <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                        Filter
                      </button>
                    </div>
                    <div className="">
                      <a
                        className="btn  btn-red"
                        onClick={e => {
                          e.preventDefault()
                          // setDatas(displayData)
                          setFormPopup(false)
                        }}
                      >
                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                        Close
                      </a>
                    </div>
                  </div>
                </Row>
                {/* <div
                                  className="text-end pe-0"
                                  style={{ width: "10%" }}
                                >
                                  <button className="btn  btn-purple mb-3">
                                    <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                                    Filter
                                  </button>
                                  <div>
                                    <a
                                      className="btn  btn-red"
                                      onClick={e => {
                                        e.preventDefault()
                                        setDatas(displayData)
                                        setFormPopup(!formPopup)
                                      }}
                                    >
                                      <i className="dripicons-cross me-1 icon-center"></i>{" "}
                                      Clear
                                    </a>
                                  </div>
                                </div> */}
              </Row>
            </Form>
            <i className="bi bi-triangle-fill pop-icon"></i>
          </div>
        ) : null}
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map((item, i) => {
                  if (item.title == title) {
                    return (
                      <div key={i}>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </div>
                    )
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const generateAndDownloadPDF = () => {
  const pdfDoc = (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.text}>Hello, PDF World!</Text>
      </Page>
    </Document>
  )

  // Generate a blob from the PDF content
  // pdfDoc.toBlob(blob => {
  //   // Save the blob as a PDF file
  //   saveAs(blob, "myPDF.pdf")
  // })
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  text: {
    marginTop: 30,
    marginLeft: 30,
  },
})

const Contract = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  //meta title
  document.title = "Contract | Zithas Crm"

  const [data, setDatas] = useState([])
  const [filteredData, setFilteredDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        data: formData,
        method: "post",
        url: `${configure.apiUrl}/contract`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data.contract)
        setFilteredDatas(response.data.data.contract)
        // console.log("contracts ", response.data.data.contract)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // on main filter
  const onFilter = (assign, country) => {
    const allAssigns = assign.map(ass => ass.value)

    const newData = data.filter(
      item =>
        allAssigns.includes(item.assign) && item.location === country.value
    )

    // console.log("main filter",newData)
    setFilteredDatas(newData)
  }

  const clearFilter = async () => {
    setFilteredDatas(data)
    try {
      setLoading(true)

      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))

      const res = await axios.post(
        `${configure.apiUrl}/clear_contract_filter`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      setLoading(false)

      Swal.fire("", "Filter Cleared Successfully!", "success")
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onSaveFilter = async (assign, country) => {
    try {
      setLoading(true)

      const formData = new FormData()
      formData.append("assigned", JSON.stringify(assign))
      formData.append("location", JSON.stringify(country))
      formData.append("user_id", localStorage.getItem("auth_id"))

      const res = await axios.post(
        `${configure.apiUrl}/save_contract_filter`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setLoading(false)
      Swal.fire("", "Filter Saved Successfully!", "success")
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Assigned",
        accessor: "assign",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex align-items-center">
              <div className="profileImg-box">
                <img
                  src={`https://mars.zithas.com/application/public/${cellProps.row.original.img}`}
                  alt=""
                  className="assigned-img mx-1"
                  width="20"
                  height="20"
                />
                <div className="profileImg-detail">
                  <div className="overflow-hidden mb-0 card">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-8">
                          <div className="text-primary text-start p-3">
                            <h5 className="text-primary ">
                              {cellProps.row.original.assigned_name}
                            </h5>
                            <p style={{ width: "200px" }}>
                              {cellProps.row.original.assigned_designation}
                            </p>
                          </div>
                        </div>
                        <div className="align-self-end col-4">
                          <img src={sideImg} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 mb-0 card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="avatar-md profile-user-wid mb-2">
                            <img
                              src={`https://mars.zithas.com/application/public/${cellProps.row.original.img}`}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className=" text-start">
                            <div className="row">
                              <div className="pe-1 col-5">
                                <h5 className="font-size-12 mb-1">Join Date</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {moment(
                                    cellProps.row.original?.assigned_joiningdate
                                  ).format("DD-MM-YYYY")}
                                </p>
                              </div>
                              <div className="ps-0 col-7">
                                <h5 className="font-size-12 mb-1">Email</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.assigned_email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cellProps.row.original.location == 1 ? (
                <img
                  src={India}
                  className=" mx-1 location-img"
                  alt="flag"
                  width="20"
                  height="15"
                />
              ) : (
                <img
                  src={Uk}
                  className=" mx-1 location-img"
                  alt="flag"
                  width="20"
                  height="15"
                />
              )}
            </div>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: "Company",
        accessor: "company_name",
        isInfoIcon: true,
        className: "cursor-pointer",
      },
      {
        Header: "Type",
        accessor: "type",
        isInfoIcon: true,
      },
      {
        Header: "Amount",
        accessor: "value",
        isInfoIcon: true,
        className: "text-center",
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original.value != null ? (
                <>
                  {cellProps.row.original.currency_symbol || "-"}{" "}
                  {cellProps.row.original.value}
                </>
              ) : (
                "-"
              )}
            </div>
          )
        },
      },
      {
        Header: "Duration",
        accessor: "duration",
        isInfoIcon: true,
        Cell: cellProps => {
          const startDate = new Date(cellProps.row.original.startdate)
          const endDate = new Date(cellProps.row.original.enddate)
          const timeDifference = endDate - startDate
          const daysDifference = Math.floor(
            timeDifference / (1000 * 60 * 60 * 24)
          )
          return <div>{daysDifference} days</div>
        },
      },
      {
        Header: "Sent Date",
        accessor: "startdate",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original?.startdate != null
                ? moment(cellProps.row.original?.startdate).format("DD-MM-YYYY")
                : "-"}
            </div>
          )
        },
      },
      {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original?.enddate != null
                ? moment(cellProps.row.original?.enddate).format("DD-MM-YYYY")
                : "-"}
            </div>
          )
        },
      },
      {
        Header: "Signature",
        accessor: "signature",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              <Badge
                className={
                  cellProps.row.original.signature == "signed"
                    ? "bg-success text-capitalize"
                    : "bg-danger text-capitalize"
                }
              >
                {cellProps.row.original.signature}
              </Badge>
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          const onPermission = async (e, id) => {
            e.preventDefault()
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 5)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  onDeleteLead()
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onDeleteLead = e => {
            Swal.fire({
              title: "Are you sure?",
              text: "You want to Delete this Contract !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
              showCancelButton: true,
            }).then(async willDelete => {
              if (willDelete.isConfirmed) {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("contract_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_contract`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    Swal.fire({
                      icon: "success",
                      text: "Contract Deleted Successfully",
                    }).then(data => {
                      setFilteredDatas(response.data.data.contract)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  console.log(err)
                  Swal.fire({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const onGenerateLink = e => {
            Swal.fire({
              title: "Are you sure?",
              text: "You want to Generate Link !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete.isConfirmed) {
                setLoading(true)
                const formData = new FormData()
                formData.append("contract_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/contract_link`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    Swal.fire({
                      icon: "success",
                      text: "Link Generated Successfully",
                    }).then(data => {
                      window.open(
                        `${configure.appUrl}sales/contract/${response.data.data}`,
                        "_blank"
                      )
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  Swal.fire({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const [copy, setCopy] = useState("")
          function copyToClipBoard(e) {
            setCopy("Copied ! ...")
            setTimeout(() => {
              setCopy("")
            }, 1000)
          }

          const onEditPermission = async ({ id }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 4)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  navigate(
                    `${configure.appUrl}sales/edit-contract/${cellProps.row.original.id}`
                  )
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          return (
            <div className="d-flex">
              <Link
                to={`${configure.appUrl}sales/view-contract/${cellProps.row.original.id}`}
                title="View"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-eye "></i>
              </Link>
              {cellProps.row.original.approve == 0 ||
              cellProps.row.original.signature == null ? null : (
                <DownloadContract
                  contract_id={cellProps.row.original.id}
                  signature={cellProps.row.original.signature}
                />
              )}

              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link
                    title="edit"
                    onClick={() =>
                      onEditPermission({ id: cellProps.row.original.id })
                    }
                    // to={`${configure.appUrl}sales/edit-contract/${cellProps.row.original.id}`}
                    className="dropdown-item"
                  >
                    <i className="fas fa-pencil-alt pr-10"></i> Edit Contract
                  </Link>

                  {cellProps.row.original.approve == 0 ||
                  cellProps.row.original.signature == null ? null : cellProps
                      .row.original.junk_link == null ? (
                    <a
                      href="#"
                      title="link"
                      className="dropdown-item"
                      onClick={onGenerateLink}
                    >
                      <i className="fas fa-link pr-10"></i> Generate Link
                    </a>
                  ) : (
                    <CopyToClipboard
                      text={`https://earth.zithas.com/sales/contract/${cellProps.row.original.junk_link}`}
                      onCopy={copyToClipBoard}
                    >
                      <button
                        className="dropdown-item copyBtn"
                        // value={`https://earth.zithas.com/sales/contract/${cellProps.row.original.junk_link}`}
                      >
                        <i className="fas fa-copy pr-10"></i>
                        {copy?.length == 0 ? "Copy Link" : copy}
                      </button>
                    </CopyToClipboard>
                  )}

                  <a
                    onClick={onPermission}
                    title="delete"
                    className="dropdown-item"
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Contract
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Contract"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={filteredData}
                      onFilter={onFilter}
                      clearFilter={clearFilter}
                      onSaveFilter={onSaveFilter}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default Contract
