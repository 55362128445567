import React, { useMemo, useEffect, useState } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Link, useNavigate } from "react-router-dom"
import { Table, Row, Col, Modal } from "reactstrap"

import Pagination from "components/Common/Pagination"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import swal from "sweetalert"
import imgMilestone from "../../../../assets/images/Project/AddMilestone.jpg"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import NewPagination from "components/Common/Newpagination"

const Milestones = () => {
  const [viewModal, setViewModal] = useState(false)
  const [milestoneSidebar, setMilestoneSidebar] = useState(false)
  const [editMilestoneSidebar, setEditMilestoneSidebar] = useState(false)

  const [notesSidebar, setNotesSidebar] = useState(false)

  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setMilestoneSidebar(false), setEditMilestoneSidebar(false)
  })

  const navigate = useNavigate()

  const onDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete this Milestone",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        return
      }
    })
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Milestone",
        accessor: "milestone",
        isInfoIcon: false,
        Cell: cellProps => {
          // console.log()
          return <a>{cellProps.cell.value}</a>
        },
      },
      // {
      //     Header: "Hours",
      //     accessor: "hours",
      //     isInfoIcon: false,
      //     className: "col-1 text-center"
      // },
      {
        Header: "Description",
        accessor: "desc",
        isInfoIcon: false,
        className: "col-7",
        Cell: cellProps => {
          return <a>{cellProps.cell.value}</a>
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setEditMilestoneSidebar(true)}
                title="Edit Milestone"
              >
                <i className="fas fa-pen "></i>
              </button>

              <button
                onClick={onDelete}
                className="btn btn-sm btn-danger ms-2"
                title="Delete"
              >
                <i className="fas fa-trash-alt "></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(
    () => [
      {
        id: 1,
        milestone: "Milestone 1",
        hours: "2",
        desc: "Task Description",
      },
      {
        id: 2,
        milestone: "Milestone 2",
        hours: "2",
        desc: "Task Description",
      },
      {
        id: 3,
        milestone: "Milestone 3",
        hours: "2",
        desc: "Task Description",
      },
      {
        id: 4,
        milestone: "Milestone 4",
        hours: "2",
        desc: "Task Description",
      },
    ],
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])
  return (
    <>
      <div className="moveUp mt-4">
        <Row>
          <Col>
            <select
              className="border-radius me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <Col className="d-flex justify-content-end align-items-start">
            <div>
              <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <input
                      onChange={e => setGlobalFilter(e.target.value)}
                      id="search-bar-0"
                      type="text"
                      className="border-radius form-control rounded h-100"
                      placeholder={`Search...`}
                      value={globalFilter || ""}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>

            <button
              type="button"
              onClick={() => setMilestoneSidebar(true)}
              className="btn btn-purple border-radius ms-3"
            >
              <i className="fas fa-plus me-2"></i>
              Add Milestone
            </button>

            {/* <button className=" btn ms-md-2 btn-save btn-label"
                        onClick={() => setMilestoneSidebar(true)}>
                            <i className="fas fa-plus me-1 label-icon"></i> Add Milestone
                        </button> */}

            <a
              onClick={() => navigate(-1)}
              className="border-radius btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </a>
          </Col>
        </Row>

        <Row>
          <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
            <div className="text-center">
              <img src={imgMilestone} alt="Addepe" style={{ width: "90%" }} />
            </div>
          </div>
          <div className="position-relative col-md-8 d-flex justify-content-end align-items-center ps-3">
            <Table className="table table-bordered mt-3" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        className={`t-col-${index + 1} ${column.className}`}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                        {column.isInfoIcon ? (
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr className="row-hover" {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td
                            key={index}
                            className={`t-col-${index + 1} ${
                              cell.column.className
                            }`}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
                {page.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Row>
      </div>
      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>
      {/* Add Milestone */}
      {/* <div className={`notes-sidebar miles-sidebar ${milestoneSidebar ? 'show-notes-sidebar' : ''}`} >
                <Row className='align-items-center justify-content-between'>
                    <div className='col-12 d-flex justify-content-between'>
                        <h5>Add Milestone</h5>
                      
                    </div>
                    <div className='col-12 for-task'>
                        <label htmlFor="">Description <span className='text-danger'>*</span></label>
                        <CKEditor
                            editor={ClassicEditor}
                            data="<p></p>"
                            onReady={editor => {
          
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                            }}
                        />
                    </div>
                    <div className='col-md-4 mt-3 notes'>
                        <label htmlFor=''>
                            Milestone Hours <span className="text-danger">*</span>
                        </label>
                        <input type="text" placeholder='Enter Milestone Hours' className='form-control border-radius' />
                    </div>
                    
                    <div className='text-end'>
                        <button className='btn btn-purple border-radius me-2'>
                            <i className='fas fa-save me-2'></i>Save
                        </button>
                        <a onClick={() => setMilestoneSidebar(false)} className='btn btn-secondary text-uppercase border-radius'>
                            <i className='dripicons-cross me-1 icon-center'></i>Close
                        </a>
                    </div>       
                 
                </Row>
            </div> */}

      {/* Add Milestone */}
      <div
        className={`note-sticky-form ${
          milestoneSidebar ? "d-block" : "d-none"
        }`}
      >
        <Draggable>
          <ResizableBox
            width={700}
            style={{
              position: "fixed",
              top: "20%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <Row className="align-items-center justify-content-between">
              <div className="col-12 d-flex justify-content-between">
                <h5>Add Milestone</h5>
              </div>
              <div className="col-12 for-task">
                <label htmlFor="">
                  Description <span className="text-danger">*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p></p>"
                  onReady={editor => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                  }}
                />
              </div>
              <div className="col-md-4 mt-3 notes">
                <label htmlFor="">
                  Milestone Hours <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Milestone Hours"
                  className="form-control border-radius"
                />
              </div>

              <div className="text-end">
                <button className="btn btn-purple border-radius me-2">
                  <i className="fas fa-save me-2"></i>Save
                </button>
                <a
                  onClick={() => setMilestoneSidebar(false)}
                  className="btn btn-secondary text-uppercase border-radius"
                >
                  <i className="dripicons-cross me-1 icon-center"></i>Close
                </a>
              </div>
            </Row>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Edit MieStone  */}
      {/* <div
        className={`notes-sidebar miles-sidebar ${
          editMilestoneSidebar ? "show-notes-sidebar" : ""
        }`}
      >
        <Row className="align-items-center justify-content-between">
          <div className="col-12 d-flex justify-content-between">
            <h5>Edit Milestone</h5>
         
          </div>
          <div className="col-12 for-task">
            <label htmlFor="">
              Description <span className="text-danger">*</span>
            </label>
            <CKEditor
              editor={ClassicEditor}
              data="<p></p>"
              onReady={editor => {}}
              onChange={(event, editor) => {
                const data = editor.getData()
              }}
            />
          </div>
          <div className="col-md-4 mt-3 notes">
            <label htmlFor="">
              Milestone Hours <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Milestone Hours"
              className="form-control border-radius"
            />
          </div>

          <div className="text-end">
            <button className="btn btn-purple border-radius me-2">
              <i className="fas fa-save me-2"></i>Save
            </button>
            <a
              onClick={() => setEditMilestoneSidebar(false)}
              className="btn btn-secondary text-uppercase border-radius"
            >
              <i className="dripicons-cross me-1 icon-center"></i>Close
            </a>
          </div>
  
        </Row>
      </div> */}

      {/* Edit MieStone  */}
      <div
        className={`note-sticky-form ${
          editMilestoneSidebar ? "d-block" : "d-none"
        }`}
      >
        <Draggable>
          <ResizableBox
            width={700}
            style={{
              position: "fixed",
              top: "20%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <Row className="align-items-center justify-content-between">
              <div className="col-12 d-flex justify-content-between">
                <h5>Edit Milestone</h5>
              </div>
              <div className="col-12 for-task">
                <label htmlFor="">
                  Description <span className="text-danger">*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  data="<p></p>"
                  onReady={editor => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                  }}
                />
              </div>
              <div className="col-md-4 mt-3 notes">
                <label htmlFor="">
                  Milestone Hours <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Milestone Hours"
                  className="form-control border-radius"
                />
              </div>

              <div className="text-end">
                <button className="btn btn-purple border-radius me-2">
                  <i className="fas fa-save me-2"></i>Save
                </button>
                <a
                  onClick={() => setEditMilestoneSidebar(false)}
                  className="btn btn-secondary text-uppercase border-radius"
                >
                  <i className="dripicons-cross me-1 icon-center"></i>Close
                </a>
              </div>
            </Row>
          </ResizableBox>
        </Draggable>
      </div>
      {/* Edit Milestone */}
      {/* <div className={`notes-sidebar miles-sidebar ${editMilestoneSidebar ? 'show-notes-sidebar' : ''}`} >
                <Row className='align-items-center justify-content-between'>
                    <div className='col-12 d-flex justify-content-between'>
                        <h5>Edit Milestone</h5>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius me-2'>
                                <i className='fas fa-plus me-2'></i>Edit Milestone
                            </button>
                            <a onClick={() => setEditMilestoneSidebar(false)} className='btn btn-secondary text-uppercase border-radius'>
                                <i className='dripicons-cross me-1 icon-center'></i>Close
                            </a>
                        </div>
                    </div>
                    <div className='col-12 for-task'>
                        <label htmlFor="">Description <span className='text-danger'>*</span></label>
                        <CKEditor
                            editor={ClassicEditor}
                            data="
                            <h4>What is an ERP system? </h4>

                            <p>
                            How can these solutions manage organizations day-to-day business activities, such as accounting, finance, procurement, project management, supply chain, and manufacturing.

                            Enterprise resource planning systems are complete, integrated platforms, either on-premises or in the cloud, managing all aspects of a production-based or distribution business. Furthermore, ERP systems support all aspects of financial management, human resources, supply chain management, and manufacturing with your core accounting function.
                            
                            ERP systems will also provide transparency into your complete business process by tracking all aspects of production, logistics, and financials. These integrated systems act as a business's central hub for end-to-end workflow and data, allowing a variety of departments to access.
                            
                            ERP Systems and software support multiple functions across the enterprise, mid-sized, or small businesses, including customizations for your industry.
                            </p>

                            "
                            onReady={editor => {
                          
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                            }}
                        />
                    </div>
                    <div className='col-md-4 mt-3 notes'>
                        <label htmlFor=''>
                            Milestone Hours <span className="text-danger">*</span>
                        </label>
                        <input type="text" placeholder='Enter Milestone Hours' className='form-control border-radius' />
                    </div>

                    <div className='col-md-2 mt-3 text-end'>
                   
                    </div>
                </Row>
            </div> */}
      {/* 
            <Modal
                size="lg"
                isOpen={viewModal}
                toggle={() => {
                    tog_small();
                }}
                centered
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="mySmallModalLabel"
                    >
                        Information
                    </h5>
                    <button
                        onClick={() => {
                            setViewModal(false);
                        }}
                        type="button"
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body bd-span-input">
                    <Row>
                        <div className="col-md-4">
                            <label htmlFor="">Milestone Hours</label>
                
                            <input type="text" className='form-control border-radius'
                                defaultValue="Any Title"
                                disabled placeholder='Enter Title' />
                        </div>

                        <div className="col-12 mt-4 for-task">
                            <label htmlFor="">Description</label>
                            <CKEditor
                                editor={ClassicEditor}
                                data="<div>
                                <h4>What is an ERP system? </h4>

                                <p>
                                How can these solutions manage organizations day-to-day business activities, such as accounting, finance, procurement, project management, supply chain, and manufacturing.
    
                                Enterprise resource planning systems are complete, integrated platforms, either on-premises or in the cloud, managing all aspects of a production-based or distribution business. Furthermore, ERP systems support all aspects of financial management, human resources, supply chain management, and manufacturing with your core accounting function.
                                
                                ERP systems will also provide transparency into your complete business process by tracking all aspects of production, logistics, and financials. These integrated systems act as a business's central hub for end-to-end workflow and data, allowing a variety of departments to access.
                                
                                ERP Systems and software support multiple functions across the enterprise, mid-sized, or small businesses, including customizations for your industry.
                                </p>
                                    </div>"
                                onReady={editor => {
                             
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                }}
                            />
                        </div>
                    </Row>
                 
                </div>
            </Modal> */}
    </>
  )
}

export default Milestones
