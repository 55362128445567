import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Row, Form, Modal } from "reactstrap"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// editor
// assets
import "../../../../assets/css/Sales/Sales.css"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import swal from "sweetalert"
import configure from "configure"

const Contract = ({ verticalActiveTab }) => {
  const [modal_small, setmodal_small] = useState(false)
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // small modal
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const commissionStructure = [
    { label: "Percentage", value: "percentage" },
    { label: "Fixed Fee", value: "fixed fee" },
  ]

  const params = useParams()

  const [isLoading, setLoading] = useState(false)
  const [commission, setCommissionStructure] = useState([])
  const [percentage, setPercentage] = useState([])
  const [template_option, setTemplate_option] = useState([])
  const [template, setTemplate] = useState([])
  const [description, setDescription] = useState("")
  const [link, setLink] = useState("")
  const [contract_id, setContractId] = useState("")
  const [link_enable, setLinkEnable] = useState(false)

  useEffect(() => {
    if (verticalActiveTab == "6") {
      fetchTemplate()
    }
  }, [verticalActiveTab])

  const fetchTemplate = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/templated`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async res => {
        setTemplate_option(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
            description: obj.template,
          }))
        )

        // console.log("Generate link",res.data.data)

        const formData = new FormData()
        formData.append("introducer_id", params.id)
        const response2 = await axios({
          method: "post",
          data: formData,
          url: `${configure.apiUrl}/check_introducer_contract`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
          .then(response => {
            if (response.data.success == true) {
              setDescription(response.data.data[0]?.description)

              // console.log("Link : ", response.data.data)

              setLink(
                response.data.data[0]?.junk_link != null
                  ? response.data.data[0]?.junk_link
                  : ""
              )
              setContractId(response.data.data[0]?.id)
              const option = res.data.data.map(obj => ({
                value: obj.id,
                label: obj.name,
                description: obj.template,
              }))
              setTemplate(
                option.filter(
                  obj => obj.value == response.data.data[0]?.template
                )[0]
              )
              setPercentage(response.data.data[0]?.percentage)
              setCommissionStructure(
                commissionStructure.filter(
                  obj => obj.value == response.data.data[0]?.commission
                )[0]
              )
            } else {
              setLinkEnable(true)
            }
          })
          .catch(err => console.log(err))
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const generateLink = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    formData.append("contract_id", contract_id)
    formData.append("template", template.value)
    formData.append("description", description)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/save_introducer_contract`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLink(response.data.data)
        swal({
          icon: "success",
          text: "Link Generated Successfully",
        }).then(data => {})
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [copy, setCopy] = useState("")
  function copyToClipBoard() {
    const btn = document.getElementsByClassName("copyBtn")
    navigator.clipboard.writeText(btn[0].attributes[1].value)
    setCopy("Copied!")
    setTimeout(() => {
      setCopy("")
    }, 1000)
  }

  const onSavecontract = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    formData.append("template", template?.value)
    formData.append("description", description)
    formData.append("commission", commission?.value)
    formData.append("percentage", percentage)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/store_introducer_contract`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Contract Saved Successfully",
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
        <Form onSubmit={generateLink}>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save btn-label pe-0  fix-btn">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <div className="col-md-12 mb-3 text-end">
              {link?.length != 0 ? (
                <a
                  className=" btn ms-md-2 btn-purple copyBtn"
                  // value={`https://earth.zithas.com/introducer/${link}`}
                  value={`https://earth.zithas.com/introducer/${link}`}
                  onClick={copyToClipBoard}
                >
                  <i className="fas fa-copy me-1"></i>{" "}
                  {copy?.length == 0 ? "Copy Link" : copy}
                </a>
              ) : null}

              <>
                {link?.length == 0 ? (
                  <button className=" btn ms-md-2 btn-save btn-label ">
                    <i className="fas fa-cogs me-1 label-icon"></i> Generate
                    Link
                  </button>
                ) : null}

                <button
                  onClick={onSavecontract}
                  className=" btn ms-md-2 btn-save btn-label"
                >
                  <i className="fas fa-save me-1 label-icon"></i> Save
                </button>
              </>

              <button
                onClick={e => {
                  e.preventDefault()
                  navigate(-1)
                }}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>

            {/* <div className="bd-span-input">
                <label>Description</label>
                <div className="input-span">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  ></div>
                </div>
              </div> */}

            <div className="row">
              <div className="col-md-4 mb-3">
                <label htmlFor="">Select Template</label>
                <Select
                  placeholder="Select Template"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={template_option}
                  value={template}
                  onChange={e => {
                    setTemplate(e)
                    setDescription(e.description)
                  }}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Commission</label>
                <Select
                  placeholder="Select Commission"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={commissionStructure}
                  value={commission}
                  onChange={e => setCommissionStructure(e)}
                />
              </div>
              {commission?.value == "percentage" ? (
                <div className="col-md-1 mb-3">
                  <label htmlFor="">(%)</label>
                  <input
                    type="text"
                    placeholder="%"
                    className="form-control "
                    value={percentage}
                    onChange={e => setPercentage(e.target.value)}
                  />
                </div>
              ) : null}
              {description?.length != 0 ? (
                <div className="col-md-12 mb-3 bd-template">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description ? description : "<p></p>"}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                    }}
                  />
                </div>
              ) : null}
            </div>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default Contract
