import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import {
    Row
} from "reactstrap";

const ViewZithasInvoice = () => {

    const navigate = useNavigate();

    useEffect(() => window.scrollTo(0, 0), [])

    document.title = "New Invoice | Zithas Crm";

    return (
        <React.Fragment>
            <div className="page-content mb-3">
                <Container fluid>
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"New Invoice"}
                    />
                    <Row className='px-1 justify-content-center mb-5'>
                        <div className="col-8 text-end mb-4">
                            <button onClick={(e) => {
                                e.preventDefault()
                                navigate(-1)
                            }} className="border-radius btn ms-md-2 btn-red">
                                <i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                        </div>

                        {/* invoice page */}
                        <div className="col-md-8">

                            <div className='invoice-page p-4 pb-5 bg-white '>
                                <div className='row justify-content-between'>
                                    <div className='col-4'>
                                        <img src="https://mars.ztpl.net/assets/images/zithas-p.png" className='w-100 mt-2' alt="" />
                                    </div>

                                    <div className='col-4 text-end'>
                                        <span >FF-16 Kalpvrux Complex, Opp. GEB Substation, Gotri Road Vadodara Gujarat 390021 India</span>
                                    </div>
                                </div>

                                <div className="row ">
                                    <div className="col-4 pe-0">
                                        <div className="invoice-line"></div>
                                    </div>
                                    <div className="col-4">
                                        <p className='fs-4 py-3 text-center bg-white mb-0'>PROFORMA INVOICE</p>
                                    </div>
                                    <div className="col-4 ps-0">
                                        <div className="invoice-line"></div>
                                    </div>
                                </div>

                                <div className="row justify-content-between">
                                    <div className="col-3 ">

                                        <p className='mb-1'>ABC Company ,</p>
                                        <p className='mb-1'>hfhrfh ,</p>
                                        <p className='mb-1'>Delhi, Delhi ,</p>
                                        <p className='mb-1'>India - 1234</p>

                                    </div>
                                    <div className="col-5 ps-0">
                                        <table className='table '>
                                            <tbody>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Invoice#</th>
                                                    <td className='border border-secondary py-1'>PRF/2023-2024/0003</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Invoice Date</th>
                                                    <td className='border border-secondary py-1'>01-09-2023</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Terms</th>
                                                    <td className='border border-secondary py-1'>Due on Receipt</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Due Date</th>
                                                    <td className='border border-secondary py-1'>01-09-2023</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <table className='table '>
                                        <thead>
                                            <tr>
                                                <th className='border border-secondary'>#</th>
                                                <th className='border border-secondary'>Item & Description</th>
                                                <th className='border border-secondary'>Qty</th>
                                                <th className='border border-secondary'>Rate</th>
                                                <th className='border border-secondary'>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className='border border-secondary'>1</td>
                                                <td className='border border-secondary'>feferfe</td>
                                                <td className='border border-secondary'>6</td>
                                                <td className='border border-secondary'>660</td>
                                                <td className='border border-secondary'>₹ 3960</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>Thank you for giving us an opportunity! Hoping work from you in future</p>
                                </div>

                                <div className='row justify-content-end'>
                                    <div className="col-5">
                                        <div className='d-flex py-2 mt-3 justify-content-between'>
                                            <span>Sub Total</span>
                                            <span>₹ 3960</span>
                                        </div>
                                        <div className='d-flex py-2 justify-content-between'>
                                            <span>Discount</span>
                                            <span>0.00</span>
                                        </div>
                                        <div className='d-flex pt-2 border-top border-secondary pb-2 justify-content-between'>
                                            <strong> Total (₹)</strong>
                                            <span>₹ 3960</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-4 row pb-5'>
                                    <p>Total in Words : <strong>Rupees Seventeen Thousand and Seven Hundred</strong></p>

                                    <h4 className='fw-bold'>Bank Details</h4>

                                    <div className="col-5">
                                        <table className='table'>
                                            <tr>
                                                <th>Bank Name : </th>
                                                <td>HDFC Bank Ltd.</td>
                                            </tr>
                                            <tr>
                                                <th>Branch Name :</th>
                                                <td>KARELIBAUG </td>
                                            </tr>
                                            <tr>
                                                <th>Account Name :</th>
                                                <td>Zithas</td>
                                            </tr>
                                            <tr>
                                                <th>Account No. :</th>
                                                <td>50200006048052</td>
                                            </tr>
                                            <tr>
                                                <th>IFSC Code : </th>
                                                <td>HDFC0000147  </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className='mt-4'>
                                        <h4 className='fw-bold'>Terms &amp; Conditions</h4>
                                        <p className='mb-0'>1. If payment made by crossed cheque in favour of Zithas Technologies Pvt. Ltd.</p>
                                        <p className='mb-0'>2. If NEFT / RTGS / Online Transfer should be made to given bank details.</p>
                                        <p className='mb-0'>3. Late payment charges will be applicable.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ViewZithasInvoice