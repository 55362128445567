import React, { useEffect, useState } from "react"
import { Container, Row, Col, Form, Table } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"

const ZithasCtc = ({ id }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [staffId, setStaffId] = useState(localStorage.getItem("staffId"))
  const [isLoading, setLoading] = useState(false)
  const [bp, setBp] = useState("")
  const [da, setDa] = useState("")
  const [ma, setMa] = useState("")
  const [hra, setHra] = useState("")
  const [ca, setCa] = useState("")
  const [esi, setEsi] = useState("")
  const [pf, setPf] = useState("")
  const [pt, setPt] = useState("")
  const [cod, setCod] = useState("")
  const [phr, setPhr] = useState("")

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", id)
    formData.append("basic_pay", bp)
    formData.append("dearness_allowance", da)
    formData.append("medical_allowance", ma)
    formData.append("house_rent_allowance", hra)
    formData.append("conveyance_allowance", ca)
    formData.append("employee_state_insurance", esi)
    formData.append("provident_fund", pf)
    formData.append("professional_tax", pt)
    formData.append("other_deduction", cod)
    formData.append("per_rate_hour", phr)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staffctc`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff Zithas CTC details edited Successfully",
        }).then(data => {
          // setverticalActiveTab("8")
        })
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const getCtaDetails = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const item = response.data.data.filter(temp => temp.id == id)[0]
        setBp(item.basic_pay)
        setDa(item.dearness_allowance)
        setMa(item.medical_allowance)
        setHra(item.house_rent_allowance)
        setCa(item.conveyance_allowance)
        setEsi(item.employee_state_insurance)
        setPf(item.provident_fund)
        setPt(item.professional_tax)
        setCod(item.other_deduction)
        setPhr(item.per_rate_hour)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    getCtaDetails()
  }, [])
  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Form onSubmit={handleSubmit}>
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-red fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </button>
          </div>
          <div className="mb-3 p-4 bg-white shadow">
            <Row>
              <div className="col-md-6">
                <h4 className="text-dark above">Zithas CTC</h4>
              </div>
              <div className="col-md-6  text-md-end">
                <button className=" btn text-white me-md-0 me-2 btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>SAVE
                </button>
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className=" btn text-white ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>
            <h5 className="mb-4 text-dark">Earnings</h5>
            <Row>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  B.P{" "}
                  <i
                    title="Basic Pay"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Basic Pay"
                  className="form-control "
                  id=""
                  value={bp}
                  onChange={e => setBp(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  D.A{" "}
                  <i
                    title="Dearness Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Dearness Allowance"
                  className="form-control "
                  id=""
                  value={da}
                  onChange={e => setDa(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  M.A{" "}
                  <i
                    title="Medical Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Medical Allowance"
                  className="form-control "
                  id=""
                  value={ma}
                  onChange={e => setMa(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  H.R.A{" "}
                  <i
                    title="House Rent Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter House Rent Allowance"
                  className="form-control "
                  id=""
                  value={hra}
                  onChange={e => setHra(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  C.A
                  <i
                    title="Conveyance Allowance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Conveyance Allowance"
                  className="form-control "
                  id=""
                  value={ca}
                  onChange={e => setCa(e.target.value)}
                />
              </div>
            </Row>
          </div>

          <div className="mb-3 p-4 bg-white shadow">
            <h5 className="mb-4 text-dark">Deductions</h5>
            <Row>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  E.S.I{" "}
                  <i
                    title="Employee State Insurance"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Employee State Insurance"
                  className="form-control "
                  id=""
                  value={esi}
                  onChange={e => setEsi(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  P.F{" "}
                  <i
                    title="Provident Fund"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Provident Fund"
                  className="form-control "
                  id=""
                  value={pf}
                  onChange={e => setPf(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  P.T{" "}
                  <i
                    title="Professional Tax"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Professional Tax"
                  className="form-control "
                  id=""
                  value={pt}
                  onChange={e => setPt(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  C/O.D{" "}
                  <i
                    title="Canteen/Other Deduction"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Canteen/Other Deduction"
                  className="form-control "
                  id=""
                  value={cod}
                  onChange={e => setCod(e.target.value)}
                />
              </div>
            </Row>
          </div>

          <div className="mb-3 p-4 bg-white shadow">
            <h5 className="mb-4 text-dark">Per Hour Rate</h5>
            <Row>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">
                  P.H.R{" "}
                  <i
                    title="Per Hour Rate"
                    className="bx bx-info-circle ms-1 info-icon"
                  ></i>
                </label>
                <input
                  type="text"
                  placeholder="Enter Per Hour Rate"
                  className="form-control "
                  id=""
                  value={phr}
                  onChange={e => setPhr(e.target.value)}
                />
              </div>

              <div className="col-12 mt-4">
                <Table className="table table-bordered">
                  <thead>
                    <tr>
                      <td>Id</td>
                      <td>Year</td>
                      <td>Date</td>
                      <td>Salary</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="row-hover">
                      <td>1</td>
                      <td>2023</td>
                      <td>02-01-2023</td>
                      <td>1000</td>
                    </tr>
                    <tr className="row-hover">
                      <td>2</td>
                      <td>2023</td>
                      <td>05-01-2023</td>
                      <td>1200</td>
                    </tr>
                    <tr className="row-hover">
                      <td>3</td>
                      <td>2023</td>
                      <td>08-01-2023</td>
                      <td>1700</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Row>
          </div>
        </Form>
      </div>
    </>
  )
}

export default ZithasCtc
