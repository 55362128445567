import React, { useEffect, useState } from "react"
import { Container, Table } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../assets/css/Sales/Sales.css"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"

const EditContact = () => {
  const [fixButtons, setFixButtons] = useState(false)
  const [createLogin, setCreateLogin] = useState(false)

  const navigate = useNavigate()

  document.title = "Add Contact | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }
  const params = useParams()

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [position, setPosition] = useState("")
  const [email, setEmail] = useState("")
  const [dob, setDoB] = useState("")
  const [aniversary_date, setAniversaryDate] = useState("")
  const [login, setLogin] = useState("")
  const [primary_contact, setPrimaryContact] = useState("")
  const [password, setPassword] = useState("")
  const [per_proposal, setPerProposal] = useState("")
  const [per_contract, setPerContract] = useState("")
  const [per_signature, setPerSignature] = useState("")
  const [per_support, setPerSuport] = useState("")
  const [per_project, setPerProject] = useState("")

  const [not_proposal, setNotProposal] = useState("")
  const [note_contract, setNoteContract] = useState("")
  const [note_signature, setNoteSignature] = useState("")
  const [note_support, setNoteSuport] = useState("")
  const [note_project, setNoteProject] = useState("")
  const [isLoading, setLoading] = useState(false)

  const [data, setDatas] = useState([])

  useEffect(() => {
    fetchContact()
  }, [])

  const fetchContact = async e => {
    setLoading(true)
    const formData = new FormData()
    formData.append("contact_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/view_contact`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setName(response.data.data[0].name)
        setPhone(response.data.data[0].phone)
        setPosition(response.data.data[0].position)
        setEmail(response.data.data[0].email)
        setDoB(
          response.data.data[0].birthdate == "1970-01-01"
            ? ""
            : response.data.data[0].birthdate
        )
        setAniversaryDate(
          response.data.data[0].annivarysarydate == "1970-01-01"
            ? ""
            : response.data.data[0].annivarysarydate
        )
        setLogin(response.data.data[0].login == "yes")
        setPrimaryContact(response.data.data[0].primarycontact == "on")
        setPerProposal(response.data.data.contact_permission[0].proposal == "1")
        setPerContract(response.data.data.contact_permission[0].contract == "1")
        setPerSignature(
          response.data.data.contact_permission[0].contact_signature == "1"
        )
        setPerSuport(response.data.data.contact_permission[0].support == "1")
        setPerProject(response.data.data.contact_permission[0].project == "1")
        const notification = JSON.parse(
          response.data.data.contact_permission[0].notification
        )
        setNotProposal(notification[0] == "1")
        setNoteContract(notification[1] == "1")
        setNoteSignature(notification[2] == "1")
        setNoteSuport(notification[3] == "1")
        setNoteProject(notification[4] == "1")
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  console.log("phone", phone)
  const onEditContact = async e => {
    e.preventDefault()
    let primarycontact
    if (primary_contact == true) {
      primarycontact = "on"
    } else {
      primarycontact = "off"
    }
    const notification = [
      not_proposal == true ? 1 : 0,
      note_contract == true ? 1 : 0,
      note_signature == true ? 1 : 0,
      note_support == true ? 1 : 0,
      note_project == true ? 1 : 0,
    ]
    setLoading(true)
    const formData = new FormData()
    formData.append("contact_id", params.id)
    formData.append("name", name)
    formData.append("phone", phone ? phone : "")
    formData.append("position", position)
    formData.append("email", email)
    formData.append("birthdate", dob ? dob : "1970-01-01")
    formData.append(
      "anniversary_date",
      aniversary_date ? aniversary_date : "1970-01-01"
    )
    formData.append("login", login == true ? "yes" : "no")
    formData.append("primarycontact", primarycontact)
    formData.append("password", password)
    formData.append("proposal", per_proposal == true ? 1 : 0)
    formData.append("contract", per_contract == true ? 1 : 0)
    formData.append("contact_signature", per_signature == true ? 1 : 0)
    formData.append("support", per_support == true ? 1 : 0)
    formData.append("project", per_contract == true ? 1 : 0)
    formData.append("notification", JSON.stringify(notification))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_contact`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Contact Edited Succesfully",
        }).then(data => {
          navigate(`${configure.appUrl}sales/customer`)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Contact"} />
          <Row className="px-1">
            <Form onSubmit={onEditContact}>
              {/* Fixed Buttons */}
              <div
                className={`flex-column fixed-buttons ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button className="btn btn-purple  fix-btn">
                  <i className="fas fa-save me-2"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn"
                >
                  <i className="dripicons-cross me-2 icon-center"></i>
                  <span>Cancel</span>
                </a>
              </div>

              <Row>
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <div className="col-md-12 mb-3 text-end">
                      <button className=" btn ms-md-2 btn-purple">
                        <i className="fas fa-save me-1"></i> save
                      </button>
                      <button
                        onClick={e => {
                          e.preventDefault()
                          navigate(-1)
                        }}
                        className=" btn ms-md-2 btn-red"
                      >
                        <i className="dripicons-cross me-2 icon-center"></i>
                        CANCEL
                      </button>
                    </div>
                    <Row className="pe-0">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">
                          Name <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          className="form-control "
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 pe-md-0">
                        <label htmlFor="">Phone </label>
                        <input
                          type="number"
                          placeholder="Enter Phone no."
                          className="form-control "
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">
                          Position <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Position"
                          className="form-control "
                          value={position}
                          onChange={e => setPosition(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 pe-md-0">
                        <label htmlFor="">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Email"
                          className="form-control "
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">Birth Date</label>
                        <input
                          type="date"
                          className="form-control "
                          value={dob}
                          onChange={e => setDoB(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 pe-md-0">
                        <label htmlFor="">Anniversary Date </label>
                        <input
                          type="date"
                          className="form-control "
                          value={aniversary_date}
                          onChange={e => setAniversaryDate(e.target.value)}
                        />
                      </div>
                      <div className="create-login-btn mb-md-0 mb-3">
                        <label htmlFor="">Create Login?</label>
                        <div className="form-switch form-switch-md me-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            checked={login}
                            onClick={e => {
                              setLogin(!login)
                            }}
                          />
                        </div>
                      </div>
                      {login == true ? (
                        <div className="password-field ps-md-0 mb-md-0 mb-3">
                          <label htmlFor="">
                            Password <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control "
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                      ) : null}
                      <div className="col-md-4 ps-md-3 d-flex align-items-center mt-md-3 notes">
                        <input
                          type="checkbox"
                          id="primary-contact"
                          className="form-check-input mt-0 big-checkbox"
                          checked={primary_contact}
                          onClick={e => setPrimaryContact(!primary_contact)}
                        />
                        <label htmlFor="primary-contact" className="mb-0 ms-2">
                          Primary Contact
                        </label>
                      </div>
                    </Row>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-4">
                  <Row className="bg-white px-3 align-items-start pt-4 shadow mb-3">
                    <p className="ps-0 pb-0 text-danger">
                      {
                        "[Make sure to set appropriate permissions for this contact]"
                      }
                    </p>
                    <Table className="table-bordered">
                      <thead>
                        <tr>
                          <th className="col-6" colSpan={2}>
                            Permission
                          </th>
                          <th className="col-6" colSpan={2}>
                            Email Notifications
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Proposal</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={per_proposal}
                                onClick={() => setPerProposal(!per_proposal)}
                              />
                            </div>
                          </td>
                          <td>Proposal</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={not_proposal}
                                onClick={() => {
                                  setNotProposal(!not_proposal)
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Contract</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={per_contract}
                                onClick={() => {
                                  setPerContract(!per_contract)
                                }}
                              />
                            </div>
                          </td>
                          <td>Contract</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={note_contract}
                                onClick={() => setNoteContract(!note_contract)}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Contact Signature</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={per_signature}
                                onClick={() => {
                                  setPerSignature(!per_signature)
                                }}
                              />
                            </div>
                          </td>
                          <td>Contact Signature</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={note_signature}
                                onClick={() => {
                                  setNoteSignature(!note_signature)
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Support</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={per_support}
                                onClick={() => {
                                  setPerSuport(!per_support)
                                }}
                              />
                            </div>
                          </td>
                          <td>Support</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={note_support}
                                onClick={() => {
                                  setNoteSuport(!note_support)
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Project</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={per_project}
                                onClick={() => {
                                  setPerProject(!per_project)
                                }}
                              />
                            </div>
                          </td>
                          <td>Project</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={note_project}
                                onClick={() => {
                                  setNoteProject(!note_project)
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditContact
