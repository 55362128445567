import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

// common component
import Pagination from "components/Common/Pagination"
import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import Pagi2 from "../../../../components/Common/Pagi2"

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <button
              onClick={() => navigate(-1)}
              className=" btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-header" : ""
            } fix-viewdatabank-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-viewdatabank-header" {...getTableBodyProps()}>
            {page.length === 0 ? (
              <tr>
                <td colSpan={8} className="text-center">
                  No data in table
                </td>
              </tr>
            ) : (
              page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr className="row-hover" {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          className={`t-col-${index + 1} ${
                            cell.column.className
                          }`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagi2
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
        {/* <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        /> */}
      </Row>
    </div>
  )
}

const ViewDataBank = () => {
  const [allIndustries, setAllIndustries] = useState([])
  const [loading, setLoading] = useState(false)

  //meta title
  document.title = "View Opportunity | Zithas Crm"

  const { industry_id } = useParams()

  useEffect(() => {
    fetchAllImportData()
  }, [])

  const statusOptions = [
    { label: "In Progress", value: "0" },
    { label: "Voicemail but not given", value: "1", isModal: true },
    { label: "Not interested", value: "2", isModal: true },
    { label: "No received", value: "3", isModal: false },
    { label: "Some wrong number", value: "4", isModal: false },
    {
      label: "Gave a specific time to talk( mention the time and date)",
      value: "5",
      isModal: true,
    },
    {
      label: "Call connected but some digital person was speaking",
      value: "6",
      isModal: false,
    },
    { label: "Call the head office", value: "7", isModal: true },
    { label: "Voicemail given", value: "8", isModal: false },
    { label: "No option to talk with anyone", value: "9", isModal: false },
    { label: "Call Later", value: "10", isModal: true },
    { label: "They don't need it", value: "11", isModal: true },
    { label: "Owner not available", value: "12", isModal: true },
    { label: "Number not in service", value: "13", isModal: false },
    { label: "They will call us back", value: "14", isModal: false },
    { label: "They have it", value: "15", isModal: true },
    { label: "Others", value: "16", isModal: true },
  ]

  const fetchAllImportData = async () => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("industry", industry_id)

      const res = await axios.post(
        `${configure.apiUrl}/view_industries`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setAllIndustries(res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
        Cell: cellProps => <span>{cellProps.row.index + 1}</span>,
      },
      {
        Header: "Email",
        accessor: "email",
        isInfoIcon: true,
      },
      {
        Header: "Company Name",
        accessor: "company",
        isInfoIcon: true,
      },
      {
        Header: "Country",
        accessor: "country",
        isInfoIcon: true,
      },
      {
        Header: "Website",
        accessor: "website",
        isInfoIcon: true,
        className: "text-start",
        Cell: cellProps => {
          return (
            <span>
              {cellProps.row.original.website !== null &&
                (cellProps.row.original.website.length > 30
                  ? cellProps.row.original.website.slice(0, 30) + "..."
                  : cellProps.row.original.website)}
            </span>
          )
        },
      },
      {
        Header: "Phone",
        accessor: "phonenumber",
        isInfoIcon: true,
      },
      {
        Header: "Status",
        accessor: "status",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div className="">
              {
                statusOptions.filter(
                  st => st.value == cellProps.row.original.status
                )[0]?.label
              }
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center px-0",
        Cell: cellProps => {
          return (
            <div>
              <Link
                title="edit"
                to={`${configure.appUrl}marketing/Formshow/${cellProps.row.original.id}`}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <Link
                to=""
                title="Convert To Lead"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-exchange-alt"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allIndustries, [allIndustries])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"View Opportunity"}
          />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewDataBank
