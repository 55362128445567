import React, { useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Staff/Staff.css"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Home from "./Home"
import Profile from "./Profile"
// import Source from "./Source"
// import Status from "./Status"

// components

const IntroducerIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  document.title = "Introducer | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [fixedSideTab, setFixedSideTab] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Introducer" />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white  ${
                  fixedSideTab ? "fixed-side-tab" : ""
                } h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="pe-0">
              <div className="card p-4 h-100">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <Home />
                  </TabPane>
                  <TabPane tabId="2">
                    <Profile />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IntroducerIndex
