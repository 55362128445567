import React from "react"
import zithasLogo from "../../assets/images/zithas-dark-logo.png"
import microfsoftLogo from "../../assets/images/microfsoft.png"
import skypelogo from "../../assets/images/newskypelogo.jpg"
import gitlogo from "../../assets/images/git.png"
import "../../assets/css/Profile/Profile.css"
import { useState } from "react"
import { Button } from "reactstrap"
// import { copyToClipBoard } from "pages/Separate/copyText"

export function copyToClipBoard({ e, setCopy, id }) {
  console.log("data", e, setCopy, id)
  if (e !== null) {
    window.navigator.clipboard.writeText(e)
    window.navigator.clipboard.writeText(e)
    setCopy({ type: id })
    setTimeout(() => {
      setCopy({
        value: "",
        type: null,
      })
    }, 1000)
  }
}

const Credential = ({ profileData }) => {
  const [copy, setCopy] = useState({
    type: null,
  })
  // const handleCopy = (e, id) => {
  //   copyToClipBoard({ e, setCopy: setCopy, id });
  // };
  const [type1, setType1] = useState("password")
  const [type2, setType2] = useState("password")

  // Copy Function
  // const [copyMessage, setCopyMessage] = useState("");

  // const handleCopy = (text, id) => {
  //   copyToClipBoard({ text, setCopy: setCopyMessage });
  // };

  const handleToggle1 = () => {
    if (type1 === "password") {
      setType1("text")
    } else {
      setType1("password")
    }
  }
  const handleToggle2 = () => {
    if (type2 === "password") {
      setType2("text")
    } else {
      setType2("password")
    }
  }
  console.log("copy", copy)

  return (
    <React.Fragment>
      <div className="mb-2 shadow border border-2 common-card Crediential-section">
        <div className="mb-2 bg-white p-4">
          <h5 className="mb-3 text-dark">ZTPL Credential</h5>
          <div className="row">
            <div className="col-md-2 d-flex align-item-center pt-4">
              <img src={zithasLogo} alt="zithasLogo" className="img-fluid" />
            </div>
            <div className="col-md-4 mb-3 mb-md-0 border-end border-2">
              {copy.type == 1 && (
                <div className="position-absolute copyMsg">Copied</div>
              )}
              <label htmlFor="">Email Id</label>
              <div className="input-wrapper">
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control formInput"
                  id=""
                  value={profileData?.email}
                  disabled
                />

                <button
                  onClick={e =>
                    copyToClipBoard({
                      e: e.target.value,
                      setCopy: setCopy,
                      id: 1,
                    })
                  }
                  value={profileData?.email}
                  className="p-2 fas fa-copy copyBtn col-2 btn btn-primary btn-m copy-btn"
                ></button>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              {copy.type == 2 && (
                <div className="position-absolute copyMsg">Copied</div>
              )}
              <label htmlFor="">Password</label>
              <div className="input-wrapper">
                <input
                  type={type1}
                  className="form-control formInput"
                  id=""
                  disabled
                  value={profileData?.crm_password}
                  //   onChange={e => setZohoPass(e.target.value)}
                />
                <button
                  onClick={e =>
                    copyToClipBoard({
                      e: e.target.value,
                      setCopy: setCopy,
                      id: 2,
                    })
                  }
                  value={profileData?.crm_password}
                  className="p-2 fas fa-copy copyBtn col-2 btn btn-primary btn-m copy-btn"
                ></button>
              </div>
            </div>

            <div className="col-md-1 mb-1 mb-md-0">
              <Button
                className="bg-white password-btn"
                onClick={() => handleToggle1()}
              >
                {type1 === "password" ? (
                  <i className="fa fa-eye text-purple pr-2"></i>
                ) : (
                  <i className="fa fa-eye-slash text-purple"></i>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 shadow border border-2 common-card">
        <div className="mb-2 bg-white p-4  ">
          <h5 className="mb-3 text-dark">Microsoft 365</h5>
          <div className="row">
            <div className="col-md-2 d-flex align-item-center pt-4">
              <img
                src={microfsoftLogo}
                alt="zithasLogo"
                className="img-fluid"
              />
            </div>
            <div className="col-md-4 mb-3 mb-md-0 border-end border-2">
              {copy.type == 3 && (
                <div className="position-absolute copyMsg">Copied</div>
              )}
              <label htmlFor="">Email Id</label>
              <div className="input-wrapper">
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control formInput"
                  id=""
                  value={profileData?.teams_email}
                  disabled
                  //   value={zohoEmail}
                  //   onChange={e => setZohoEmail(e.target.value)}
                />
                <button
                  onClick={e =>
                    copyToClipBoard({
                      e: e.target.value,
                      setCopy: setCopy,
                      id: 3,
                    })
                  }
                  value={profileData?.teams_email}
                  className="p-2 fas fa-copy copyBtn col-2 btn btn-primary btn-m copy-btn"
                ></button>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              {copy.type == 4 && (
                <div className="position-absolute copyMsg">Copied</div>
              )}
              <label htmlFor="">Password</label>
              <div className="input-wrapper">
                <input
                  type={type2}
                  placeholder="Enter Password"
                  className="form-control formInput"
                  id="1"
                  value={profileData?.teams_password}
                  disabled
                  //   value={zohoPass}
                  //   onChange={e => setZohoPass(e.target.value)}
                />
                <button
                  onClick={e =>
                    copyToClipBoard({
                      e: e.target.value,
                      setCopy: setCopy,
                      id: 4,
                    })
                  }
                  value={profileData?.teams_password}
                  className="p-2 fas fa-copy copyBtn col-2 btn btn-primary btn-m copy-btn"
                ></button>
              </div>
            </div>

            <div className="col-md-1 mb-1 mb-md-0">
              <Button
                className="bg-white password-btn"
                onClick={() => handleToggle2()}
              >
                {type2 === "password" ? (
                  <i className="fa fa-eye text-purple pr-2"></i>
                ) : (
                  <i className="fa fa-eye-slash text-purple"></i>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mb-3 shadow border border-2 common-card">
        <div className="mb-3 bg-white p-4  ">
          <h5 className="mb-4 text-dark">Microsoft 365</h5>
          <div className="row">
            <div className="col-md-2 d-flex align-item-center pt-4">
              <img src={skypelogo} alt="zithasLogo" className="img-fluid" />
            </div>
            <div className="col-md-5 mb-3 mb-md-0 border-end border-2">
              <label htmlFor="">Email Id</label>
              <input
                type="email"
                placeholder="Enter Email Id"
                className="form-control formInput"
                id=""
                //   value={zohoEmail}
                //   onChange={e => setZohoEmail(e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3 mb-md-0">
              <label htmlFor="">Password</label>
              <input
                type="password"
                placeholder="Enter Password"
                className="form-control formInput"
                id=""
                //   value={zohoPass}
                //   onChange={e => setZohoPass(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="mb-3 shadow border border-2 common-card">
        <div className="mb-3 bg-white p-4">
          <h5 className="mb-4 text-dark">Git Credentials</h5>
          <div className="row">
            <div className="col-md-2 d-flex align-item-center pt-4">
              <img src={gitlogo} alt="zithasLogo" className="img-fluid" />
            </div>
            <div className="col-md-5 mb-3 mb-md-0 border-end border-2">
              <label htmlFor="" className="mb-2">Email Id</label>
              <input
                type="email"
                placeholder="Enter Email Id"
                className="form-control formInput"
                id=""
                //   value={zohoEmail}
                //   onChange={e => setZohoEmail(e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3 mb-md-0">
              <label htmlFor="">Password</label>
              <input
                type="password"
                placeholder="Enter Password"
                className="form-control formInput"
                id=""
                //   value={zohoPass}
                //   onChange={e => setZohoPass(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  )
}

export default Credential
