import React, { useState } from "react"
import { Row, Form, Modal } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"
import { json } from "react-router-dom"

const ViewProfile = ({ viewData }) => {
  const [modal_small, setmodal_small] = useState(false)
  const [linkedin_link, setLinkedin_link] = useState()
  const [facebook_link, setFacebook_link] = useState()

  let persondata
  if (viewData?.person_details != undefined) {
    persondata = JSON.parse(viewData?.person_details) || []
  }

  function tog_small() {
    setmodal_small(!modal_small)
    // removeBodyCss()
  }

  return (
    <React.Fragment>
      <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
        <Form>
          <div className="bd-span-input">
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex">
                <div className="contact-column mb-3">
                  <label htmlFor="">
                    Lead Belong To <span className="text-danger">*</span>{" "}
                  </label>
                  <div className="input-span input">
                    {viewData?.lead_belongs_to == "1"
                      ? "India"
                      : "United Kingdom"}
                  </div>
                </div>
                <div className="contact-column mb-3">
                  <label htmlFor="">
                    Source <span className="text-danger">*</span>{" "}
                  </label>
                  <div className="input-span input">
                    {viewData?.source || "NA"}
                  </div>
                </div>
                <div className="contact-column mb-3">
                  <label htmlFor="">
                    Assigned <span className="text-danger">*</span>
                  </label>
                  <div className="input-span input">
                    {viewData?.asigned || "NA"}
                  </div>
                </div>
                <div className="contact-column mb-3 mb-md-0">
                  <label htmlFor="">
                    Status <span className="text-danger">*</span>
                  </label>
                  <div className="input-span input">
                    {viewData?.status || "NA"}
                  </div>
                </div>
                <div className="contact-column mb-3 mb-md-0">
                  <label htmlFor="">
                    Lead Type <span className="text-danger">*</span>
                  </label>
                  <div className="input-span input">
                    {viewData?.lead_type || "NA"}
                  </div>
                </div>
                <div className="contact-column mb-3 mb-md-0">
                  <label htmlFor="">
                    Industry <span className="text-danger">*</span>
                  </label>
                  <div className="input-span input">
                    {viewData?.industry_name || "NA"}
                  </div>
                </div>
              </div>
            </Row>

            {/* Company info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Company Information</h5>
              <div className="col-md-6 mt-2 mb-3 mb-md-0 ">
                <label htmlFor="">Company Name</label>
                <div className="input-span input">
                  {viewData?.company || "NA"}
                </div>
              </div>
              <div className="col-md-6 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Website</label>
                <div className="input-span input">
                  {viewData?.website || "NA"}
                </div>
              </div>
              <div className="col-md-6 mt-2 mb-3 mb-md-0 mt-3">
                <label htmlFor="">Email Address</label>
                <div className="input-span input">
                  {viewData?.email || "NA"}
                </div>
              </div>
              <div className="col-md-6 mt-2 mb-3 mb-md-0 mt-3">
                <label htmlFor="">Phone</label>
                <div className="input-span input">
                  {viewData?.phonenumber || "NA"}
                </div>
              </div>
            </Row>

            {/* Address */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Address</h5>
              <div className="col-md-4 mb-3">
                <label htmlFor="">
                  Country <span className="text-danger">*</span>{" "}
                </label>
                <div className="input-span input">
                  {viewData?.country || "NA"}
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="">
                  State <span className="text-danger">*</span>{" "}
                </label>
                <div className="input-span input">
                  {viewData?.state || "NA"}
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="">City </label>
                <div className="input-span input">{viewData?.city || "NA"}</div>
              </div>
              <div className="col-md-8 mb-3">
                <label htmlFor="">Address</label>
                <div className="input-span input">
                  {viewData?.address || "NA"}
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="">Pin Code</label>
                <div className="input-span input">{viewData?.zip || "NA"}</div>
              </div>
              <div className="col-12">
                <label htmlFor="">
                  Description <span className="text-danger">*</span>
                </label>
                <div className="input-span input">
                  <div
                    className="Features"
                    dangerouslySetInnerHTML={{
                      __html: viewData?.description,
                    }}
                  />
                </div>
              </div>
            </Row>

            {(persondata || []).map((person, index) => {
              return (
                <Row
                  className="bg-white px-2 py-4 shadow mb-3"
                  key={persondata?.name}
                >
                  <div className="mb-2">
                    <div className="d-flex">
                      <div className="contact-form d-flex">
                        <div className="contact-column edit-contact-column mb-3 mb-md-0">
                          <label htmlFor="">Name</label>
                          <div className="input-span input">
                            {person?.person_name || "NA"}
                          </div>
                        </div>
                        <div className="contact-column edit-contact-column px-2 mb-3 mb-md-0">
                          <label htmlFor="">Email</label>
                          <div className="input-span input">
                            {person?.person_email || "NA"}
                          </div>
                        </div>
                        <div className="contact-column edit-contact-column mb-3 mb-md-0">
                          <label htmlFor="">Phone</label>
                          <div className="input-span input">
                            {person?.person_phone || "NA"}
                          </div>
                        </div>
                        <div className="mb-3 mb-md-0 d-flex">
                          <div className="text-center me-3">
                            <label className="d-block">Authority</label>
                            <div className="input-span input">
                              {(person?.decision_maker === "on" && "yes") ||
                                "no"}
                            </div>
                          </div>
                          <div className="text-center ">
                            <label className="d-block">Primary</label>
                            <div className="input-span input">
                              {(person?.chk_contact === "on" && "yes") || "no"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-4 mb-3 mb-md-0">
                        <label htmlFor="">Designation</label>
                        <div className="input-span input">
                          {person?.person_designation || "NA"}
                        </div>
                      </div>
                      <div className="col-4 mb-3 mb-md-0">
                        <label htmlFor="">Facebook</label>
                        <div className="input-span input">
                          {person?.facebook || "NA"}
                        </div>
                      </div>
                      <div className="col-4 mb-3 mb-md-0">
                        <label htmlFor="">LinkedIn</label>
                        <div className="input-span input">
                          {person?.linkedin || "NA"}
                        </div>
                      </div>
                    </div>

                    <div className="border-bottom mb-3 mt-4 "></div>
                  </div>
                </Row>
              )
            })}
          </div>
        </Form>

        {/* Add Link Modal */}
        <Modal
          size="sm"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Link
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <label htmlFor="">Link</label>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  placeholder="LinkedIn Link"
                  className="form-control "
                  defaultValue={linkedin_link || "---"}
                />
              </div>
              <div className="col-12 mb-3">
                <input
                  defaultValue={facebook_link || "---"}
                  type="text"
                  placeholder="Facebook Link"
                  className="form-control "
                />
              </div>
            </Row>
            <div className="border-bottom mb-3"></div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default ViewProfile
