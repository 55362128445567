import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { Row, Form, Modal } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"

const AddLead = () => {
  const [formRows, setFormRows] = useState([{}])
  const [modal_small, setmodal_small] = useState(false)
  const [custType, setCustType] = useState("new")
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  const [lead_belong, setLead_belong] = useState([])
  const [source, setSource] = useState([])
  const [assigned, setAssigned] = useState([])
  const [status, setStatus] = useState([])
  const [lead_type, setLead_type] = useState("")
  const [company_name, setCompany_name] = useState("")
  const [website, setWebsite] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [pincode, setPincode] = useState("")
  const [description, setDescription] = useState("")
  const [countrycode, setCountrycode] = useState("")
  const [citycode, setCitycode] = useState("")
  const [statecode, setStatecode] = useState("")
  const [industry, setIndustry] = useState([])

  //data for contact
  const [modalKey, setModalKey] = useState("")

  const [persondetail, setPersondetail] = useState([])
  const [customer, setCust] = useState([])
  useEffect(() => {
    fetchCustomer()
    fetchCountry()
    fetchCountrys()
    fetchIndustry()
  }, [])

  const [location, setLocation] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [country_option, setCountryOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])
  const [source_option, setSourceOption] = useState([])
  const [assign_option, setAssignOption] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [industry_option, setIndustryOption] = useState([])
  const [source_name, setSourceName] = useState("")

  // changes lead ============================
  const [source_dependent, setSourceDependent] = useState(false)
  const [allIntroducers, setAllIntroducers] = useState([])

  const fetchIndustry = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/industry`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setIndustryOption(
          res.data.data.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const fetchCountry = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLocation(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchCountrys = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/country`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setCountryOption(
          res.data.data.country.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchCustomer = async () => {
    // try {
    //   await axios({
    //     method: "get",
    //     url: `${configure.apiUrl}/customer`,
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }).then(res => {
    //
    //     console.log("cust",res.data.data.customer)
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const onChangeCountry = async e => {
    setLoading(true)
    setLead_belong(e)
    const fromData = new FormData()
    fromData.append("country_id", e.value)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/lead_belong`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setSourceOption(
          res.data.data.lead_source?.map(item => ({
            value: item.id,
            label: item.leadname,
          }))
        )
        setAssignOption(
          res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setStatusOption(
          res.data.data.lead_status.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )

        // existing customers
        setCust(res.data.data.customer)
        setAllIntroducers(res.data.data.introducer)

        setLoading(false)

        // console.log("location", res.data)
      })

      // await axios({
      //   method: "get",
      //   url: `${configure.apiUrl}/customer`,

      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "multipart/form-data",
      //   },
      // }).then(res => {

      // })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getState = async e => {
    setLoading(true)
    setCountrycode(e.value)
    const formData = new FormData()
    formData.append("country_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/country_state`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setStateOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getCities = async e => {
    setLoading(true)
    setStatecode(e.value)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/state_city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCityOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })
  const [error, setError] = useState(null)

  const saveLead = async e => {
    e.preventDefault()
    if (
      lead_belong.length != 0 &&
      source.length != 0 &&
      assigned.length != 0 &&
      status.length != 0 &&
      lead_type.length != 0 &&
      industry.length != 0 &&
      countrycode.length != 0 &&
      statecode.length != 0 &&
      description.length != 0
    ) {
      setLoading(true)
      const person_details = JSON.stringify(persondetail)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("lead_belongs_to", lead_belong.value)
      formData.append("source", source.value)
      formData.append("source_name", source_name.value)
      formData.append("assigned", assigned.value)
      formData.append("status", status.value)
      formData.append("lead_type", lead_type)
      formData.append("customer_type", custType)
      formData.append("company_name", company_name)
      formData.append("website", website)
      formData.append("email", email)
      formData.append("phone", phone)
      formData.append("country", countrycode)
      formData.append("state", statecode)
      formData.append("city", citycode)
      formData.append("address", address)
      formData.append("pincode", pincode)
      formData.append("description", description)
      formData.append("industry", industry.value)
      formData.append("person_details", person_details)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/add_lead`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          console.log("lead data", res)
          Swal.fire({
            icon: "success",
            text: "Lead Added Succesfully",
          }).then(data => {
            navigate(`${configure.appUrl}sales/lead`)
          })
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      setError(true)
    }
  }

  // small modal
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  // dynamic form add
  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const onPersonName = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.value
    setPersondetail(values)
  }
  const onPersonEmail = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.value
    setPersondetail(values)
  }
  const onPersonPhone = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.value
    setPersondetail(values)
  }
  const onPersonDesignation = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.value
    setPersondetail(values)
  }
  const onPersonAuthority = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.value
    setPersondetail(values)
  }
  const onPersonPrimary = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.checked
    setPersondetail(values)
  }
  const onLinkedIn = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.value
    setPersondetail(values)
  }
  const onFacebook = (e, key) => {
    let values = [...formRows]
    values[key][e.target.name] = e.target.value
    setPersondetail(values)
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const onComapanyChg = e => {
    const obj = customer.find(x => x.id === e.value)

    setCompany_name(obj.company)
    setWebsite(obj.website)
    setEmail(obj.email)
    setCity(obj.city)
    setCitycode(obj.ctid)
    setStatecode(obj.stateid)
    setCountrycode(obj.cntryid)
    setCountry(obj.country)
    setState(obj.state)
    setAddress(obj.address)
    setPincode(obj.zipcode)
    setPhone(obj.phone)
  }

  const type = [
    { label: "Hot", value: "1" },
    { label: "Cold", value: "2" },
    { label: "Warm", value: "3" },
    { label: "Normal", value: "4" },
  ]

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Lead"} />
          <Row className="px-1">
            <Form onSubmit={saveLead}>
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button
                  type="submit"
                  className="btn btn-save fix-btn btn-label pe-0"
                >
                  <i className="fas fa-save me-2 label-icon"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn  btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </a>
              </div>
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="col-md-6 mb-3">
                  <h5 className="mb-3">Lead Information</h5>
                </div>
                <div className="col-md-6 mb-3 text-end">
                  <button
                    type="submit"
                    onClick={e => saveLead(e)}
                    className=" btn ms-md-2 btn-save  btn-label"
                  >
                    <i className="fas fa-save me-1 label-icon"></i> save
                  </button>
                  <a
                    onClick={e => {
                      navigate(-1)
                    }}
                    className=" btn ms-md-2 btn-red  btn-label "
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="">
                      Lead Belong To <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={onChangeCountry}
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={location}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(lead_belong.length == 0) == error
                        ? "Please select Location"
                        : null}
                    </span>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="">
                      Source <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      onChange={e => {
                        setSource(e)

                        if (e.label == "Introducer") {
                          setSourceDependent({
                            label: e.label,
                            list: allIntroducers,
                          })
                        } else if (e.label == "Customer") {
                          setSourceDependent({ label: e.label, list: customer })
                        } else {
                          setSourceDependent(false)
                        }
                      }}
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={source_option}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(source.length == 0) == error
                        ? "Please select Source"
                        : null}
                    </span>
                  </div>
                  {source_dependent && (
                    <div className="col-md-3 mb-3">
                      <label htmlFor="">
                        {source_dependent?.label}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        onChange={e => {
                          setSourceName(e)
                        }}
                        placeholder="Select Assigned"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={source_dependent?.list.map(cust => ({
                          label: cust.company,
                          value: cust.id,
                        }))}
                      />
                      <span
                        className="text-danger"
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "7px",
                        }}
                      >
                        {(assigned.length == 0) == error
                          ? "Please select Assigned"
                          : null}
                      </span>
                    </div>
                  )}

                  {source_dependent ? (
                    <div className="col-md-3 mb-3"></div>
                  ) : (
                    <div className="col-md-6 mb-3"></div>
                  )}
                  <div className="col-md-3 mb-3">
                    <label htmlFor="">
                      Assigned <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={e => {
                        setAssigned(e)
                      }}
                      placeholder="Select Assigned"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={assign_option}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(assigned.length == 0) == error
                        ? "Please select Assigned"
                        : null}
                    </span>
                  </div>
                  <div className="col-md-3 mb-3 mb-md-0">
                    <label htmlFor="">
                      Status <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={e => {
                        setStatus(e)
                      }}
                      placeholder="Select Status"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={status_option}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(status.length == 0) == error
                        ? "Please select Lead Status"
                        : null}
                    </span>
                  </div>
                  <div className="col-md-3 mb-3 mb-md-0">
                    <label htmlFor="">
                      Lead Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      onChange={e => setLead_type(e.label)}
                      placeholder="Select Lead Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={type}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(lead_type.length == 0) == error
                        ? "Please select Lead Type"
                        : null}
                    </span>
                  </div>
                  <div className="col-md-3 mb-3 mb-md-0">
                    <label htmlFor="">
                      Industry <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Industry "
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={industry_option}
                      onChange={e => setIndustry(e)}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(industry.length == 0) == error
                        ? "Please select Industry"
                        : null}
                    </span>
                  </div>
                </div>
              </Row>

              {/* Company info */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <h5 className="mb-3">Company Information</h5>
                <div className="col-12 mb-3 mb-md-0">
                  <label htmlFor="" className="d-block">
                    Customer Type
                  </label>
                  <span className="me-4 ">
                    <input
                      type="radio"
                      id="new"
                      defaultChecked={true}
                      onInput={e => setCustType(e.target.value)}
                      name="type"
                      value="new"
                    />
                    <label htmlFor="new" className="ms-2">
                      New
                    </label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      onInput={e => setCustType(e.target.value)}
                      id="existing"
                      name="type"
                      value="existing"
                    />
                    <label htmlFor="existing" className="ms-2">
                      {" "}
                      Existing
                    </label>
                  </span>
                </div>

                <div className="col-md-4 mt-2 mb-3 mb-md-0">
                  <label htmlFor="">Company Name</label>
                  {custType === "new" ? (
                    <input
                      type="text"
                      onChange={e => setCompany_name(e.target.value)}
                      placeholder="Enter Company Name"
                      className="form-control "
                    />
                  ) : (
                    <Select
                      onChange={e => onComapanyChg(e)}
                      placeholder="Select Company"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={customer?.map(cust => ({
                        label: cust.company,
                        value: cust.id,
                      }))}
                    />
                  )}
                </div>
                <div className="col-md-4 mt-2 mb-3 mb-md-0">
                  <label htmlFor="">Website</label>
                  <input
                    type="text"
                    onChange={e => setWebsite(e.target.value)}
                    defaultValue={website}
                    placeholder="Enter Website"
                    className="form-control "
                  />
                </div>
                <Row>
                  <div className="col-md-4 mt-2 mb-3 mb-md-0 mt-3">
                    <label htmlFor="">Email Address</label>
                    <input
                      type="email"
                      onChange={e => setEmail(e.target.value)}
                      defaultValue={email}
                      placeholder="Enter Email Address"
                      className="form-control "
                    />
                  </div>
                  <div className="col-md-4 mt-2 mb-3 mb-md-0 mt-3">
                    <label htmlFor="">Phone</label>
                    <input
                      type="text"
                      placeholder="Enter Phone"
                      value={phone}
                      onChange={e => {
                        const enteredValue = e.target.value
                        if (/^[0-9+]*$/.test(enteredValue)) {
                          setPhone(enteredValue) // Update state if valid
                        } 
                      }}
                      className="form-control "
                    />
                  </div>
                </Row>
              </Row>

              {/* Address */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <h5 className="mb-3">Address</h5>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">
                    Country <span className="text-danger">*</span>{" "}
                  </label>
                  {custType === "existing" ? (
                    <div className="bd-span-input">
                      <div className="input-span">
                        {country || "Nothing selected"}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Select
                        onChange={getState}
                        placeholder="Select Location"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={country_option}
                      />
                      <span
                        className="text-danger"
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "7px",
                        }}
                      >
                        {(countrycode.length == 0) == error
                          ? "Please select Country"
                          : null}
                      </span>
                    </>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">
                    State <span className="text-danger">*</span>{" "}
                  </label>
                  {custType === "existing" ? (
                    <div className="bd-span-input">
                      <div className="input-span">
                        {state || "Nothing selected"}
                      </div>
                    </div>
                  ) : (
                    <>
                      <Select
                        onChange={getCities}
                        placeholder="Select State"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={state_option}
                      />
                      <span
                        className="text-danger"
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "7px",
                        }}
                      >
                        {(statecode.length == 0) == error
                          ? "Please select State"
                          : null}
                      </span>
                    </>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">City </label>
                  {custType === "existing" ? (
                    <div className="bd-span-input">
                      <div className="input-span">
                        {city || "Nothing selected"}
                      </div>
                    </div>
                  ) : (
                    <Select
                      onChange={e => setCitycode(e.value)}
                      placeholder="Select City"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={city_option}
                    />
                  )}
                </div>
                <div className="col-md-8 mb-3">
                  <label htmlFor="">Address</label>
                  {custType === "existing" ? (
                    <div className="bd-span-input">
                      <div className="input-span">
                        {address || "Nothing selected"}
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      defaultValue={address}
                      onChange={e => setAddress(e.target.value)}
                      placeholder="Enter Address"
                      className="form-control "
                    />
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="">Pin Code</label>
                  {custType === "existing" ? (
                    <div className="bd-span-input">
                      <div className="input-span">
                        {pincode || "Nothing selected"}
                      </div>
                    </div>
                  ) : (
                    <input
                      type="text"
                      defaultValue={pincode}
                      onChange={e => setPincode(e.target.value)}
                      placeholder="Enter Pin Code"
                      className="form-control "
                    />
                  )}
                </div>

                <div className="col-12 lead-desc">
                  <div className="d-flex justify-space-between">
                    <label htmlFor="">
                      Description <span className="text-danger">*</span>
                    </label>
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(description.length == 0) == error
                        ? "Please enter description"
                        : null}
                    </span>
                  </div>

                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                    }}
                  />
                </div>
              </Row>

              {/* Contact */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="d-flex justify-content-between">
                  <h5 className="text-dark"> Contact</h5>
                  <div className="text-end mb-3">
                    <a
                      onClick={e => {
                        e.preventDefault()
                        onAddFormRow()
                      }}
                      className="btn btn-purple btn-label"
                    >
                      <i className="fas fa-plus me-2 label-icon"></i>
                      Add More
                    </a>
                  </div>
                </div>
                {(formRows || []).map((formRow, key) => (
                  <div key={key} className="mb-2">
                    <div className="d-flex">
                      <div className="contact-form d-flex align-items-center">
                        <div className="contact-column mb-3 mb-md-0">
                          <label htmlFor="">Name</label>
                          <input
                            type="text"
                            name="person_name"
                            onChange={e => onPersonName(e, key)}
                            placeholder="Enter Name"
                            className="form-control "
                          />
                        </div>
                        <div className="contact-column mb-3 mb-md-0">
                          <label htmlFor="">Email</label>
                          <input
                            type="text"
                            name="person_email"
                            onChange={e => onPersonEmail(e, key)}
                            placeholder="Enter Email"
                            className="form-control "
                          />
                        </div>
                        <div className="contact-column mb-3 mb-md-0">
                          <label htmlFor="">Phone</label>
                          <input
                            type="text"
                            name="person_phone"
                            onChange={e => onPersonPhone(e, key)}
                            placeholder="Enter Number"
                            className="form-control "
                          />
                        </div>
                        <div className="contact-column-2 mb-3 mb-md-0 d-flex">
                          <div className="text-center px-1 ">
                            <label className="d-block">Authority</label>
                            <select
                              className="form-control"
                              onChange={e => onPersonAuthority(e, key)}
                              name="decision_maker"
                            >
                              <option value="off">No</option>
                              <option value="on">Yes</option>
                            </select>
                          </div>
                          <div className="text-center px-3">
                            <label className="d-block">Primary</label>
                            <select
                              className="form-control"
                              onChange={e => onPersonPrimary(e, key)}
                              name="chk_contact"
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="contact-btns pe-0 d-flex justify-content-end align-items-end mb-3 mb-md-0">
                        <div className="text-end">
                          {key !== 0 ? (
                            <>
                              <a
                                onClick={e => {
                                  e.preventDefault()
                                  onDeleteFormRow(key)
                                }}
                                className="btn btn-red"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </a>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 pb-10">
                      <div className="col-4 mb-3 mb-md-0">
                        <label htmlFor="">Designation</label>
                        <input
                          type="text"
                          name="person_designation"
                          onChange={e => onPersonDesignation(e, key)}
                          placeholder=" Designation"
                          className="form-control "
                        />
                      </div>
                      <div className="col-4 mb-3 mb-md-0">
                        <label htmlFor="">Facebook Profile Link </label>
                        <input
                          type="text"
                          name="facebook"
                          onChange={e => onPersonDesignation(e, key)}
                          placeholder="Enter Facebook Profile Link"
                          className="form-control "
                        />
                      </div>
                      <div className="col-4 mb-3 mb-md-0">
                        <label htmlFor="">LinkedIn Profile Link</label>
                        <input
                          type="text"
                          name="linkedin"
                          onChange={e => onPersonDesignation(e, key)}
                          placeholder="Enter LinkedIn Profile Link"
                          className="form-control "
                        />
                      </div>
                    </div>
                    {key !== formRows.length - 1 ? (
                      <div className="border-bottom mb-3 mt-4 "></div>
                    ) : null}
                  </div>
                ))}
              </Row>
            </Form>
          </Row>
        </Container>
        <Modal
          size="sm"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Link
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <label htmlFor="">Link</label>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  name="linkedin_link"
                  onChange={e => onLinkedIn(e, modalKey)}
                  placeholder="LinkedIn Link"
                  className="form-control "
                />
              </div>
              <div className="col-12 mb-3">
                <input
                  type="text"
                  name="facebook_link"
                  onChange={e => onFacebook(e, modalKey)}
                  placeholder="Facebook Link"
                  className="form-control "
                />
              </div>
            </Row>
            <div className="border-bottom mb-3"></div>
            <div className="text-end">
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                className="btn btn-purple "
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default AddLead
