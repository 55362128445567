import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody, Modal } from "reactstrap"
import InfoGif from "../../../../assets/images/GIF/question-mark.gif"
// assets
import "../../../../assets/css/Sales/Sales.css"
import "../../../../assets/css/Sales/Contract.css"
import sideImg from "../../../../assets/images/profile-img.png"
// common component
import Pagination from "components/Common/Pagination"
import ProfileDetails from "components/Common/ProfileDetails"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"

// components

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [name_modal, setNameModal] = useState(false)

  const [title, setTitle] = useState("")

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Contract Module, the  <strong>Assigned</strong>column designates the specific team member or individual responsible for managing and overseeing the contract, ensuring its fulfillment and compliance.</p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong>  column is to establish accountability for the contract's execution and management. It helps streamline contract-related tasks, such as negotiations, amendments, and renewals, by identifying the team member or individual responsible. This column enhances teamwork, ensures effective contract administration within the Mars System, and facilitates timely contract execution.</p>",
    },
    {
      title: "Company",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Contract Module, the <strong>Company</strong>  column represents the name of the organization or business entity with which the contract is established.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Company</strong>  column is to provide specific identification of the contracting party. It ensures that the contract is associated with the correct company, streamlining record-keeping and facilitating clear communication and contract management within the Mars System. </p>",
    },
    {
      title: "Type",
      mean: "<p><strong>Meaning :</strong> The <strong>Type</strong> column in the Mars System's Contract Module categorizes contracts into different types, such as <strong>Website Development</strong>, <strong>Digital marketing</strong>, <strong>Dedicate resource </strong>  or other relevant categories </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Type</strong> column is to classify contracts based on their nature and purpose. It allows for easy categorization and sorting of contracts, ensuring that the Mars System users can quickly access and manage contracts based on their specific type. This column simplifies contract management and facilitates efficient contract retrieval.</p>",
    },
    {
      title: "Currency",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Contract Module, the <strong>Currency</strong> column specifies the currency in which the contract's financial terms and payments are denominated.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Currency</strong>  column is to provide transparency regarding the currency used in the contract's financial transactions. It ensures that both parties involved in the contract are aware of the currency terms, facilitating accurate financial record-keeping and transaction management within the Mars System.</p>",
    },
    {
      title: "Duration",
      mean: "<p><strong>Meaning :</strong> : In the Mars System's Contract Module, the <strong>Duration</strong>  column indicates the period or timeframe covered by the contract, including start and end dates.</p>",
      purpose:
        "<p><strong>Purpose :</strong>The purpose of the <strong>Duration</strong>   column is to specify the contract's temporal scope. It helps users understand when the contract commences and when it expires, facilitating timely contract monitoring, renewal, and compliance tracking within the Mars System.</p>",
    },
    {
      title: "Sent Date",
      mean: " <p><strong>Meaning :</strong>The <strong>Sent Date</strong> column records the date when the contract was sent to the relevant parties for review, approval, or signature.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Sent Date</strong> column is to track the initiation of the contract negotiation and approval process. It provides a reference point for measuring the time it takes for parties to respond and progress through the contract workflow within the Mars System.</p>",
    },
    {
      title: "End Date",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Contract Module, the <strong> End Date</strong> column denotes the date when the contract officially concludes or expires.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong> End Date </strong> column is to provide a clear indication of when the contract's obligations and terms come to an end. It assists in contract management by ensuring that both parties are aware of the contract's termination date within the Mars System.</p>",
    },
    {
      title: "Signature",
      mean: "<p><strong>Meaning :</strong> The <strong>Signature</strong>  column may be used to record whether the contract has been signed or executed by the relevant parties. It can include indicators like <strong>Signed </strong>, <strong>Not Signed </strong>, <strong>Pending </strong>, or <strong>Won </strong>.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Signature</strong> column is to track the status of contract execution. It helps users identify whether the contract has been legally binding and executed by all necessary parties. This column is crucial for ensuring compliance and contract validity within the Mars System.</p>",
    },
  ]

  // Render the UI for your table
  return (
    <div>
      <Row className="mb-2">
        <Col md={3}>
          <select
            className="border-radius me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={9} className="d-flex justify-content-end">
          <div className="">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${fixedContent ? "fixed-header" : ""} contract-colums`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true)
                          setTitle(column.Header)
                        }}
                      >
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
            {data.length == 0 ? (
              <td
                colSpan="10"
                className="text-center"
                style={{ padding: "10px", border: "none" }}
              >
                No Contracts Available for this Customer
              </td>
            ) : null}
          </thead>

          <tbody className="contract-colums" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        onClick={() =>
                          cell.column.Header === "Company"
                            ? navigate(`${configure.appUrl}sales/edit-contract`)
                            : ""
                        }
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        } ${
                          cell.column.Header === "Signature"
                            ? cell.value === "Not-Signed"
                              ? "text-danger"
                              : "text-success"
                            : ""
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map((item, i) => {
                  if (item.title == title) {
                    return (
                      <div key={i}>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </div>
                    )
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const ViewContracts = ({ customer_contract }) => {
  //meta title
  document.title = "Contract | Zithas Crm"

  useEffect(() => {
    // fetchPropsal()
  }, [])

  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "",
        accessor: "assign",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex align-items-center">
              <div className="profileImg-box">
                <img
                  src={`https://mars.zithas.com/application/public/${cellProps.row.original.assigned_avatar}`}
                  alt=""
                  className="assigned-img mx-1"
                  height="20"
                  width="20"
                />
                <div className="profileImg-detail">
                  <div className="overflow-hidden mb-0 card">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-8">
                          <div className="text-primary text-start p-3">
                            <h5 className="text-primary ">
                              {cellProps.row.original.assinged_name}
                            </h5>
                            <p style={{ width: "200px" }}>
                              {cellProps.row.original.assigned_designation}
                            </p>
                          </div>
                        </div>
                        <div className="align-self-end col-4">
                          <img src={sideImg} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 mb-0 card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="avatar-md profile-user-wid mb-2">
                            <img
                              src={`https://mars.zithas.com/application/public/${cellProps.row.original.assigned_avatar}`}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className=" text-start">
                            <div className="row">
                              <div className="pe-1 col-5">
                                <h5 className="font-size-12 mb-1">Join Date</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {moment(cellProps.row.original?.assigned_joiningdate).format('DD-MM-YYYY')}
                                </p>
                              </div>
                              <div className="ps-0 col-7">
                                <h5 className="font-size-12 mb-1">Email</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.assigned_email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cellProps.row.original.country == "1" ? (
                <img
                  src="https://mars.ztpl.net/assets/images/india.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              ) : (
                <img
                  src="https://mars.ztpl.net/assets/images/uk.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              )}
            </div>
          )
        },
        isInfoIcon: false,
      },
      {
        Header: "Company",
        accessor: "company",
        isInfoIcon: true,
        className: "cursor-pointer",
      },
      {
        Header: "Type",
        accessor: "contract_type",
        isInfoIcon: true,
      },
      {
        Header: <i className="fas fa-coins"></i>,
        accessor: "currency_symbol",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Duration",
        accessor: "duration",
        isInfoIcon: true,
        Cell: cellProps => {
          const start = new Date(cellProps.row.original.startdate)
          const end = new Date(cellProps.row.original.enddate)
          var Difference_In_Time = end.getTime() - start.getTime()
          var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
          return <div>{Difference_In_Days} days</div>
        },
      },
      {
        Header: "Sent Date",
        accessor: "startdate",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {moment(cellProps.row.original.startdate.slice(0, 10)).format(
                "DD-MM-YYYY"
              )}
            </div>
          )
        },
      },
      {
        Header: "End Date",
        accessor: "enddate",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {moment(cellProps.row.original.enddate.slice(0, 10)).format(
                "DD-MM-YYYY"
              )}
            </div>
          )
        },
      },
      {
        Header: "Signature",
        accessor: "signature",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              <span
                className={
                  cellProps.row.original.signature == 'signed'
                    ? "bg-success badge "
                    : "bg-danger badge "
                }
              >
                {cellProps.row.original.signature}
              </span>
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <a
                className="btn btn-sm btn-outline-secondary me-1"
                title="View Proposal"
              >
                <i className="fas fa-eye "></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Row className="mx-1">
        <Col lg={12} className="p-0">
          <Card>
            <CardBody>
              <div className="responsive-table">
                <TableContainer columns={columns} data={customer_contract} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ViewContracts
