import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col, CardBody } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import moment from "moment"
import configure from "configure"

const Notes = ({ verticalActiveTab }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)
  const [followUp, setFollowUp] = useState(false)
  const [viewFollowUp, setViewFollowUp] = useState(false)
  const [addFollowUp, setAddFollowUp] = useState(false)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [selectedFiles2, setselectedFiles2] = useState([])

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function handleAcceptedFiles2(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles2(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [description, setDescription] = useState("")
  const [date, setDate] = useState("")

  const params = useParams()

  useEffect(() => {
    if (verticalActiveTab == "3") {
      fetchNotes()
    }
  }, [verticalActiveTab])

  const [assign_option, setAssignOption] = useState([])

  const fetchNotes = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/customer_notes`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setData(response.data.data)
        setAssignOption(
          response.data.assinged.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const addNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("date", date)
    formData.append("description", description)
    for (let i = 0; i < selectedFiles1.length; i++) {
      formData.append("files[]", selectedFiles1[i])
    }
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_customer_note`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Note Added Successfully",
        }).then(data => {
          setData(response.data.data)
          setNotesSidebar(false)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [edit_description, setEditDescription] = useState("")
  const [edit_date, setEditDate] = useState("")
  const [note_id, setNoteId] = useState("")

  const EditNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("customer_id", params.id)
    formData.append("note_id", note_id)
    formData.append("date", edit_date)
    formData.append("description", edit_description)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_customer_note`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Note Edited Successfully",
        }).then(data => {
          setData(response.data.data)
          setEditNotesSidebar(false)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const deleteNote = async (e, note_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete this note !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willdelete => {
      if (willdelete) {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        formData.append("customer_id", params.id)
        formData.append("note_id", note_id)
        try {
          await axios({
            method: "post",
            url: `${configure.apiUrl}/delete_customer_note`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            swal({
              icon: "success",
              text: "Note Deleted Successfully",
            }).then(data => {
              setLoading(false)
              setData(res.data.data)
            })
          })
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }
    })
  }

  const [follow_date, setFollowUpDate] = useState("")
  const [purpose, setPurpose] = useState("")
  const [assign, setAssign] = useState("")
  const [note_id_follow, setNoteIdFollow] = useState("")

  const onAddFollowUp = async e => {
    e.preventDefault()
    const result = assign.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("note_id", note_id_follow)
    formData.append("customer_id", params.id)
    formData.append("purpose", purpose)
    formData.append("assigned", result)
    formData.append("date", follow_date)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_customer_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Follow Up Added Successfully",
        }).then(data => {
          setAddFollowUp(false)
          setData(res.data.data)
        })
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const [edit_follow_date, setEditFollowUpDate] = useState("")
  const [edit_purpose, setEditPurpose] = useState("")
  const [edit_assign, setEditAssign] = useState([])
  const [follow_id, setFollowId] = useState("")

  const onEditFollowUp = async e => {
    e.preventDefault()
    const result = edit_assign.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("followup_id", follow_id)
    formData.append("customer_id", params.id)
    formData.append("purpose", edit_purpose)
    formData.append("assigned", result)
    formData.append("date", edit_follow_date)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_customer_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Follow Up Added Successfully",
        }).then(data => {
          setViewFollowUp(false)
          setData(res.data.data)
        })
      })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <div>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* Fixed Buttons */}
                  <div
                    className={`flex-column fixed-buttons pt-2 ${
                      fixButtons ? "d-flex" : "d-none"
                    }`}
                  >
                    <button className="btn btn-save btn-label pe-0  fix-btn">
                      <i className="fas fa-plus me-2"></i>
                      <span>Note</span>
                    </button>
                    <a
                      onClick={() => navigate(-1)}
                      className="btn btn-red  fix-btn label-icon"
                    >
                      <i className="dripicons-cross me-2 icon-center"></i>
                      <span>Cancel</span>
                    </a>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <h5 className="text-dark">Notes</h5>
                    <div className="text-end">
                      <a
                        onClick={() => setNotesSidebar(true)}
                        className=" btn btn-save btn-label"
                      >
                        <i className="fas fa-plus me-2 icon-size label-icon"></i>
                        Add Note
                      </a>
                      <a
                        onClick={() => navigate(-1)}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </div>

                  {/* Add Note sidebar */}
                  <div
                    className={`notes-sidebar ${
                      notesSidebar ? "show-notes-sidebar" : ""
                    }`}
                  >
                    <form onSubmit={addNotes}>
                      <Row className="justify-content-between">
                        <div className="col-12 d-flex justify-content-between">
                          <h5>Add New Note</h5>
                          <div className="text-end">
                            <button className="btn btn-purple  me-2">
                              <i className="fas fa-plus me-2"></i>Add Note
                            </button>
                            <a
                              onClick={() => setNotesSidebar(false)}
                              className="btn btn-red text-uppercase "
                            >
                              <i className="dripicons-cross me-1 icon-center"></i>
                              Close
                            </a>
                          </div>
                        </div>
                        <div className="col-12 ">
                          <label htmlFor="">Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              setDescription(data)
                            }}
                          />
                        </div>
                      </Row>
                      <Row className="">
                        <div className="col-md-3 mt-3 notes">
                          <label htmlFor="touch">
                            Got in touch with this lead{" "}
                            <span className="text-danger"> *</span>
                          </label>
                          <input
                            type="date"
                            className="form-control "
                            required
                            value={date}
                            onChange={e => setDate(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label htmlFor="">Attachment</label>
                          {/* custom file upload */}
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles1(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className="dz-message needsclick p-0"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="d-flex px-2 custom-file-upload align-items-center">
                                  <div className="">
                                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h5 className="mb-0 ms-2">Upload Files</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {/* file display here */}
                        <div
                          className={`col-md-3 mt-3 dropzone-previews ${
                            selectedFiles1.length > 0 ? "d-block" : "d-none"
                          }`}
                          id="file-previews"
                        >
                          <label className="mb-1">Uploaded Files</label>
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-1">
                              <Row className="align-items-center justify-content-evenly">
                                {selectedFiles1.map((f, i) => {
                                  return (
                                    <Col key={i} className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                  )
                                })}
                              </Row>
                            </div>
                          </Card>
                        </div>
                        {/* <div className="col-md-3 text-end mt-3">
                            <a
                              onClick={() => {
                                setFollowUp(true)
                              }}
                              className="btn btn-success  mt-4"
                            >
                              <i className="fas fa-plus me-2"></i>Follow Up
                            </a>
                          </div> */}
                      </Row>
                    </form>
                  </div>

                  <div>
                    <ul className="verti-timeline list-unstyled notes-box">
                      {/* Activities */}
                      {data.map((note, key) => (
                        <li key={key} className="event-list position-relative">
                          <div className="event-timeline-dot">
                            <img
                              src={`https://mars.zithas.com/application/public/${note.avatar}`}
                              className="userImg me-3 "
                              alt=""
                            />
                          </div>
                          <div className="d-flex">
                            <div className="note flex-grow-1 ms-4 py-2 rounded ps-4">
                              <div className=" d-flex justify-content-between ">
                                <div>
                                  <p className="text-muted mb-4">
                                    <h6 className="d-inline me-2">
                                      By {note.added_by}
                                    </h6>{" "}
                                    {moment(note.created_at).format(
                                      "DD-MM-YYYY hh:mm:ss"
                                    )}
                                    <span className="pl-15">
                                      Got in touch with this lead at{" "}
                                      {moment(note.date).format("DD-MM-YYYY")}
                                    </span>
                                  </p>
                                  <i className="bi bi-triangle-fill note-icon"></i>
                                </div>
                                <div className="me-4">
                                  {note.followup?.length == 0 ? (
                                    <span
                                      onClick={() => {
                                        setAddFollowUp(true)
                                        setNoteIdFollow(note.id)
                                      }}
                                      className="me-2 cursor-pointer badge-soft-success badge"
                                    >
                                      Add FollowUp
                                    </span>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        setEditFollowUpDate(
                                          note.followup[0]?.date
                                        )
                                        setEditPurpose(
                                          note.followup[0]?.purpose
                                        )
                                        setFollowId(note.followup[0]?.id)
                                        setViewFollowUp(true)
                                        const idArray =
                                          note?.followup[0]?.assigned
                                            .split(",")
                                            .map(Number)
                                        setEditAssign(
                                          assign_option.filter(obj =>
                                            idArray.includes(obj.value)
                                          )
                                        )
                                      }}
                                      className="me-2 cursor-pointer badge-soft-success badge"
                                    >
                                      FollowUp
                                    </span>
                                  )}

                                  <button
                                    onClick={e => {
                                      e.preventDefault()
                                      setEditDescription(note.description)
                                      setEditDate(note.date)
                                      setNoteId(note.id)
                                      setEditNotesSidebar(true)
                                    }}
                                    className="btn btn-sm edit-delete-btn text-success me-2"
                                  >
                                    <i className="fas fa-edit" title="Edit"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm edit-delete-btn text-danger"
                                    onClick={e => deleteNote(e, note.id)}
                                  >
                                    <i
                                      className="fas fa-trash-alt"
                                      title="Delete"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                              <div
                                className="Features"
                                dangerouslySetInnerHTML={{
                                  __html: note.description,
                                }}
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {data.length == 0 ? (
                      <p>No Notes is Added for this Customer</p>
                    ) : null}

                    {/* Edit Note Sidebar */}
                    <div
                      className={`notes-sidebar ${
                        editNotesSidebar ? "show-notes-sidebar" : ""
                      }`}
                    >
                      <form onSubmit={EditNotes}>
                        <Row>
                          <div className="col-12 d-flex justify-content-between">
                            <h5>Edit Note</h5>
                            <div className="text-end">
                              <button className="btn btn-purple  me-2">
                                <i className="fas fa-edit me-2"></i>Edit Note
                              </button>
                              <a
                                onClick={() => setEditNotesSidebar(false)}
                                className="btn btn-red text-uppercase "
                              >
                                <i className="dripicons-cross me-1 icon-center"></i>
                                Close
                              </a>
                            </div>
                          </div>
                          <div className="col-12 ">
                            <label htmlFor="">Description</label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={edit_description}
                              onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                setEditDescription(data)
                              }}
                            />
                          </div>
                        </Row>
                        <Row className="justify-content-between">
                          <div className="col-md-3 mt-3 notes">
                            <label htmlFor="touch">
                              Got in touch with this lead
                            </label>
                            <input
                              type="date"
                              className="form-control "
                              value={edit_date}
                              onChange={e => setEditDate(e.target.value)}
                            />
                          </div>
                        </Row>
                      </form>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Follow Up Modal */}
      <Modal isOpen={addFollowUp} size="sm" className="bd-custom-md-3" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setAddFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={onAddFollowUp}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date</label>
              <input
                type="date"
                className="form-control "
                onChange={e => setFollowUpDate(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose</label>
              <input
                type="Purpose"
                className="form-control "
                placeholder="Enter Purpose"
                onChange={e => setPurpose(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Assigned</label>
              <Select
                placeholder="Select Assigned"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={assign_option}
                isMulti
                onChange={e => setAssign(e)}
              />
            </div>
            <div className="mt-2 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* View Follow Up Modal */}
      <Modal
        isOpen={viewFollowUp}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setViewFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={onEditFollowUp}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date</label>
              <input
                type="date"
                className="form-control "
                value={edit_follow_date}
                onChange={e => setEditFollowUpDate(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose</label>
              <input
                type="Purpose"
                className="form-control "
                placeholder="Enter Purpose"
                value={edit_purpose}
                onChange={e => setEditPurpose(e.target.value)}
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Assigned</label>
              <Select
                placeholder="Select Assigned"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={assign_option}
                isMulti
                defaultValue={edit_assign}
                onChange={e => setEditAssign(e)}
              />
            </div>
            <div className="mt-2 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default Notes
