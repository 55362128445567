import React, { useEffect, useMemo, useState } from "react"
import { Row, Col, Card, CardBody, Table } from "reactstrap"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Pagination from "components/Common/Pagination"
import { Link, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import moment from "moment"
import configure from "configure"

function TableContainer({ columns, datas, project_option }) {
  const [data, setData] = useState([])

  useEffect(() => {
    setData(datas)
  }, [datas])

  const [fixedHeader, setFixedHeader] = useState(false)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [notesSidebar, setNotesSidebar] = useState(false)

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
    menu: (provided, state) => ({
      ...provided,
      top: "auto", // Reset the top position
      bottom: "100%", // Position the menu above the select input
    }),
  }

  const [description, setDescription] = useState("")
  const [file_name, setFileName] = useState("")
  const [project, setProject] = useState([])
  const [show_file, setShow_file] = useState("0")
  const [attachment, setAttachment] = useState("")
  const [isLoading, setLoading] = useState(false)
  const params = useParams()

  const addFiles = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("description", description)
    formData.append("file_name", file_name)
    formData.append("project_id", project.value)
    formData.append("show_files", show_file)
    formData.append("file", attachment)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_customer_file`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Files Added Successfully",
        }).then(data => {
          setData(response.data.data.customer_files)
          setNotesSidebar(false)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // Render the UI for your table
  return (
    <div>
      {isLoading && <Loader />}
      <Row className="mb-3 justify-content-between">
        <Col md={2}>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <div className="me-3">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="text-end">
              <a
                className=" btn ms-md-2 btn-save btn-label"
                onClick={() => setNotesSidebar(true)}
              >
                <i className="fas fa-plus me-1 icon-size label-icon"></i> Add
                Files
              </a>
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="table table-bordered payment-table"
        {...getTableProps()}
      >
        <thead className={`${fixedHeader ? "fix-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps()}
                  className={column.className}
                >
                  {column.render("Header")}
                  {/* {column.isInfoIcon ? (
                    <i
                      title="info"
                      className="bx bx-info-circle ms-1 info-icon"
                    ></i>
                  ) : null} */}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          {data.length == 0 ? (
            <td
              colSpan="5"
              className="text-center"
              style={{ padding: "10px", border: "none" }}
            >
              No Files Available for this Customer
            </td>
          ) : null}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <div
        className={`notes-sidebar ${notesSidebar ? "show-notes-sidebar" : ""}`}
      >
        <form onSubmit={addFiles}>
          <Row className="justify-content-between">
            <div className="col-12 d-flex justify-content-between">
              <h5>Add New Files</h5>
              <div className="text-end">
                <button className="btn btn-purple  me-2">
                  <i className="fas fa-plus me-2"></i>Add Files
                </button>
                <a
                  onClick={() => setNotesSidebar(false)}
                  className="btn btn-red text-uppercase "
                >
                  <i className="dripicons-cross me-1 icon-center"></i>
                  Close
                </a>
              </div>
            </div>
            <div className="col-12 ">
              <label htmlFor="">Description</label>
              <CKEditor
                editor={ClassicEditor}
                data=""
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setDescription(data)
                }}
              />
            </div>
          </Row>
          <Row className="">
            <div className="col-md-3 mt-3 notes">
              <label htmlFor="touch">
                File Name
                <span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className="form-control "
                placeholder="Enter File Name"
                required
                onChange={e => setFileName(e.target.value)}
              />
            </div>
            <div className="col-md-2 mt-3 text-center">
              <label htmlFor="customSwitchsizemd" className="mb-3">
                Show Files <span className="text-danger">*</span>
              </label>
              <div className="form-switch form-switch-md">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  onChange={e => {
                    if (e.target.checked == true) {
                      setShow_file("1")
                    } else {
                      setShow_file("0")
                    }
                  }}
                />
              </div>
            </div>
            {show_file == "1" ? (
              <div className="col-md-3 mt-3 notes">
                <label htmlFor="touch">
                  Select Project <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder="Select Assigned"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={project_option}
                  onChange={e => setProject(e)}
                />
              </div>
            ) : null}

            <div className="col-md-4 mt-3">
              <label htmlFor="">Attachment</label>
              {/* <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedFiles1(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="dz-message needsclick p-0"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="d-flex px-2 custom-file-upload align-items-center">
                      <div className="">
                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h5 className="mb-0 ms-2">Upload Files</h5>
                    </div>
                  </div>
                )}
              </Dropzone> */}
              <input
                type="file"
                className="form-control "
                onChange={e => setAttachment(e.target.files[0])}
              />
            </div>
            {/* <div
              className={`col-md-3 mt-3 dropzone-previews ${
                selectedFiles1.length > 0 ? "d-block" : "d-none"
              }`}
              id="file-previews"
            >
              <label className="mb-1">Uploaded Files</label>
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-1">
                  <Row className="align-items-center justify-content-evenly">
                    {selectedFiles1.map((f, i) => {
                      return (
                        <Col key={i} className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </Card>
            </div> */}
          </Row>
        </form>
      </div>
    </div>
  )
}

const Files = ({ verticalActiveTab }) => {
  useEffect(() => {
    if (verticalActiveTab == "9") {
      fetchPropsal()
    }
  }, [verticalActiveTab])

  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  const [datas, setData] = useState([])
  const [project_option, setProjectOption] = useState([])
  const [edit_description, setEditDescription] = useState("")
  const [edit_file_name, setEditFileName] = useState("")
  const [edit_project, setEditProject] = useState([])
  const [edit_show_file, setEditShow_file] = useState("0")
  const [edit_attachment, setEditAttachment] = useState("")
  const [file_id, setFileId] = useState("")

  const fetchPropsal = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/customer_files`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setData(response.data.data.customer_files)
        setProjectOption(
          response.data.data.projects.map(obj => ({
            value: obj.id,
            label: obj.project_name,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onEditFile = async cellProps => {
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/customer_files`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const option = response.data.data.projects.map(obj => ({
          value: obj.id,
          label: obj.project_name,
        }))
        setEditProject(
          option.filter(obj => obj.value == cellProps.row.original.project_id)
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    setEditDescription(cellProps.row.original.description)
    setEditFileName(cellProps.row.original.filename)
    setEditShow_file(cellProps.row.original.show_files)
    setFileId(cellProps.row.original.id)
    setNotesSidebar(true)
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        isInfoIcon: true,
        className: "id-width-file",
      },
      {
        Header: "File Name",
        accessor: "filename",
        isInfoIcon: true,
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        isInfoIcon: true,
      },
      {
        Header: "Created At",
        accessor: "created_at",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {moment(cellProps.row.original.created_at).format("DD-MM-YYYY")}
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "phones",
        isInfoIcon: true,
        Cell: cellProps => {
          const deleteFiles = async e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this File !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willdelete => {
              if (willdelete) {
                e.preventDefault()
                setLoading(true)
                const formData = new FormData()
                formData.append("customer_id", params.id)
                formData.append("file_id", cellProps.row.original.id)
                try {
                  await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_customer_file`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(res => {
                    swal({
                      icon: "success",
                      text: "File Deleted Successfully",
                    }).then(data => {
                      setLoading(false)
                      setData(res.data.data.customer_files)
                    })
                  })
                } catch (error) {
                  console.log(error)
                  setLoading(false)
                }
              }
            })
          }

          const onChangeFiles = async e => {
            const status = cellProps.row.original.show_files == "0" ? "1" : "0"
            swal({
              title: "Are you sure?",
              text: "You want to Change status !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willdelete => {
              if (willdelete) {
                e.preventDefault()
                setLoading(true)
                const formData = new FormData()
                formData.append("file_id", cellProps.row.original.id)
                formData.append("show_files", status)
                try {
                  await axios({
                    method: "post",
                    url: `${configure.apiUrl}/change_customer_file`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(res => {
                    swal({
                      icon: "success",
                      text: "Status Changes Successfully",
                    }).then(data => {
                      setLoading(false)
                    })
                  })
                } catch (error) {
                  console.log(error)
                  setLoading(false)
                }
              }
            })
          }

          return (
            <div>
              <span className="form-switch form-switch-md me-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  defaultChecked={cellProps.row.original.show_files == "1"}
                  onChange={onChangeFiles}
                />
              </span>
              <a
                className="btn btn-sm action-btn me-1 btn-purple"
                href={cellProps.row.original.link}
                rel="noreferrer"
                target="_blank"
              >
                <i className="fas fa-download"></i>
              </a>
              <a
                onClick={() => onEditFile(cellProps)}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a
                className="btn btn-sm action-btn btn-danger"
                onClick={deleteFiles}
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const [selectedFiles1, setselectedFiles1] = useState([])
  const [notesSidebar, setNotesSidebar] = useState(false)

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
    menu: (provided, state) => ({
      ...provided,
      top: "auto", // Reset the top position
      bottom: "100%", // Position the menu above the select input
    }),
  }

  const EditFiles = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("file_id", file_id)
    formData.append("description", edit_description)
    formData.append("file_name", edit_file_name)
    formData.append("project_id", edit_project.value)
    formData.append("show_files", edit_show_file)
    formData.append("file", edit_attachment)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_customer_file`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Files Edited Successfully",
        }).then(data => {
          setData(response.data.data.customer_files)
          setNotesSidebar(false)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      datas={datas}
                      project_option={project_option}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <div
        className={`notes-sidebar ${notesSidebar ? "show-notes-sidebar" : ""}`}
      >
        <form onSubmit={EditFiles}>
          <Row className="justify-content-between">
            <div className="col-12 d-flex justify-content-between">
              <h5>Edit Files</h5>
              <div className="text-end">
                <button className="btn btn-purple  me-2">
                  <i className="fas fa-plus me-2"></i>Edit Files
                </button>
                <a
                  onClick={() => setNotesSidebar(false)}
                  className="btn btn-red text-uppercase "
                >
                  <i className="dripicons-cross me-1 icon-center"></i>
                  Close
                </a>
              </div>
            </div>
            <div className="col-12 ">
              <label htmlFor="">Description</label>
              <CKEditor
                editor={ClassicEditor}
                data={edit_description}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setEditDescription(data)
                }}
              />
            </div>
          </Row>
          <Row className="">
            <div className="col-md-3 mt-3 notes">
              <label htmlFor="touch">
                File Name
                <span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className="form-control "
                placeholder="Enter File Name"
                value={edit_file_name}
                onChange={e => setEditFileName(e.target.value)}
                required
              />
            </div>
            <div className="col-md-2 mt-3 ">
              <label htmlFor="customSwitchsizemd" className="mb-3">
                Show Files <span className="text-danger">*</span>
              </label>
              <div className="form-switch form-switch-md">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  defaultChecked={edit_show_file == "1"}
                  onChange={e => {
                    if (e.target.checked == true) {
                      setEditShow_file("1")
                    } else {
                      setEditShow_file("0")
                    }
                  }}
                />
              </div>
            </div>
            {edit_show_file == "1" ? (
              <div className="col-md-3 mt-3 notes">
                <label htmlFor="touch">
                  Select Project <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder="Select Assigned"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={project_option}
                  value={edit_project}
                  onChange={e => setEditProject(e)}
                />
              </div>
            ) : null}

            <div className="col-md-4 mt-3">
              <label htmlFor="">Attachment</label>
              <input
                type="file"
                className="form-control "
                onChange={e => setEditAttachment(e.target.files[0])}
              />
            </div>
          </Row>
        </form>
      </div>
    </React.Fragment>
  )
}

export default Files
