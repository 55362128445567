import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Modal,
  Form,
  Input
} from "reactstrap"
import classnames from "classnames"

// import Profile from "./Profile"
// import Notes from "./Notes"
// import FollowUp from "./FollowUp"
// import ActivityLog from "./ActivityLog"

import BasicProjectDetails from "./ViewBasicProjectInfo"
import Milestones from "./ViewMileStones"
import Tasks from "./ViewTasks"
import Notes from "./ViewNotes"
import ActivityLog from "./ViewActivityLogs"


import axios from "axios"
import Loader from "pages/Separate/Loader"
import { useParams } from "react-router-dom"
import configure from "configure"
import moment from "moment/moment"
import Select from "react-select"
import ReactSelect from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import ViewBasicProjectInfo from "./ViewBasicProjectInfo"
import ViewMileStones from "./ViewMileStones"
import ViewTasks from "./ViewTasks"
import ViewNotes from "./ViewNotes"
import ViewActivityLogs from "./ViewActivityLogs"
// components

const ViewProject = ({ editId }) => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)

  // Created at
  const [created_at, setCreatedAt] = useState("")

  //edit data
  const [data, setDatas] = useState([])
  const [editData, setEditData] = useState()
  const [loading, setLoading] = useState(false)
  let url = window.location.toString()

  useEffect(() => {
    fetch2()
    fetchCreatedOn()
  }, [])

  const params = useParams()

  const [lead_data, setLeadData] = useState([])
  const [note_data, setNoteData] = useState([])
  const [log_data, setLogData] = useState([])
  const [follow_data, setFollowData] = useState([])
  const [assigned_data, setAssignData] = useState([])
  const [industry_option, setIndustryOption] = useState([])
  const [editTask, setEditTask] = useState()
  const [addTask, setAddTask] = useState(false)
  const [formRows, setFormRows] = useState([{}])


  const milestone = [
    { label: "Milestone 1", value: "Milestone 1" },
    { label: "Milestone 2", value: "Milestone 2" },
    { label: "Milestone 3", value: "Milestone 3" },
    { label: "Milestone 4", value: "Milestone 4" },
    { label: "Milestone 5", value: "Milestone 5" },
  ]
  const Assigned = [
    { label: "Rahul", value: "Rahul" },
    { label: "Divyansh", value: "Divyansh" },
    { label: "Manav", value: "Manav" },
  ]
  const Priority = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ]
  const Estimation_Hours = [
    { label: "1 hour", value: "1 hour" },
    { label: "2 Hours", value: "2 Hours" },
    { label: "3 Hours", value: "3 Hours" },
  ]

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const fetch2 = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/all_lead_data`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLeadData(response.data.data?.lead)
        setNoteData(response.data.data?.lead_note)
        setLogData(response.data.data.lead_activity?.reverse())
        setFollowData(response.data.data?.lead_followup_data)
        setAssignData(response.data.data?.assigned)
        setIndustryOption(
          response.data.data?.industry?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // Fetching created on Date and time
  const fetchCreatedOn = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/lead/${params.id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        const data = response.data.data?.map(items => {
          setCreatedAt(items.created_at)
        })
      })
    } catch (err) {
      console.log(err)
    }
  }

  // console.log("setLeadData", lead_data[0].created_at)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "View Project  | Zithas Technologies"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const timeDifference = new Date() - new Date(lead_data[0]?.created_at)
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  // console.log("lead_data", lead_data)

  const onAddTask = () => {
    console.log("Add Task")
  }

  const onAddMember = () => {
    console.log("Add Memebr")
  }
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Work"} breadcrumbItem={"View Project"} />
          <Row className="py-4 shadow mb-3 bg-white me-0 d-flex justify-space-between align-items-center">
            <div className="col-md-8">
              <h4>
                <b>Project Title :- </b> GIT-WEBHOOK-INTEGRATION
              </h4>
            </div>

            {/* <div className="col-md-4 text-end">
              <a
                // to={`${configure.appUrl}sales/add-proposal`}
                onClick={() => setAddTask(true)}
                className="s btn text-white me-2 btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Task
              </a>
          
            </div> */}
          </Row>
          <Row className="justify-content-between d-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Basic Project Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Milestones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Tasks
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      fetch2()
                      toggleVertical("4")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      fetch2()
                      toggleVertical("5")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0" id="edit-lead">
                {/* <Row className="px-2 py-4 shadow mb-3 bg-white me-0 d-flex justify-space-between align-items-center">
                  <div className="col-md-8">
                    <h4><b>Project Title :- </b>  GIT-WEBHOOK-INTEGRATION</h4>
                  </div>
                  <div  className="col-md-4 text-end">
                    <a
                        // to={`${configure.appUrl}sales/add-proposal`}
                        onClick={() => onAddTask()}
                        className="s btn text-white me-2 btn-save btn-label"
                      >
                        <i className="fas fa-plus me-2 icon-size label-icon"></i>Task
                    </a>
                    <a
                          // to={`${configure.appUrl}sales/add-proposal`}
                          onClick={() => onAddMember()}
                          className="s btn text-white me-2 btn-save btn-label"
                        >
                          <i className="fas fa-plus me-2 icon-size label-icon"></i>Member
                    </a>
                  </div>
                </Row> */}
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <ViewBasicProjectInfo editId={editId} />
                  </TabPane>

                  <TabPane tabId="2">
                    <ViewMileStones />
                  </TabPane>

                  <TabPane tabId="3">
                    <ViewTasks />
                  </TabPane>

                  <TabPane tabId="4">
                    <ViewNotes />
                  </TabPane>

                  <TabPane tabId="5">
                    <ViewActivityLogs />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    
    </React.Fragment>
  )
}

export default ViewProject
