import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody, Form } from "reactstrap"

// assets
import "../../../../assets/css/Finance/Finance.css"

// common component
import Pagination from "components/Common/Pagination"
import swal from "sweetalert"
import configure from "configure"

function TableContainer({ columns, data }) {
  const [formPopup, setFormPopup] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 245) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  // Render the UI for your table
  return (
    <>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          to={`${configure.appUrl}finance/zithas-add-invoice`}
          className="border-radius btn text-white btn-purple fix-btn"
        >
          <i className="fas fa-plus me-2 icon-size"></i>
          <span>Invoice</span>
        </Link>

        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="align-items-center">
        <Col md={12} className="mb-3">
          <div className="d-flex justify-content-between ">
            {/* Amounts Records */}
            <div className="d-flex">
              <a href="#" className="rounded payment-amounts mb-0">
                <p className="text-dark mb-2">Current Year </p>
                <h4 className="text-secondary">2023-24</h4>
              </a>

              <a href="#" className="rounded payment-amounts mb-0">
                <p className="text-dark mb-2"> Total Invoice Amount </p>
                <h4 className="text-secondary">₹ 0.00</h4>
              </a>

              <a href="#" className="rounded payment-amounts mb-0">
                <p className="text-dark mb-2">Total Received Amount </p>
                <h4 className="text-secondary">₹ 0.00</h4>
              </a>

              <a href="#" className="rounded payment-amounts mb-0">
                <p className="text-dark mb-2">Total Due Amount </p>
                <h4 className="text-secondary">₹ 0.00</h4>
              </a>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <select
            className="border-radius form-select me-3"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={9} className="d-flex align-items-center justify-content-end">
          <div className="me-3 mt-1">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label mb-0">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <button
              onClick={() => setFormPopup(!formPopup)}
              className="btn btn-outline-secondary border-radius me-3"
            >
              <i className="fas fa-filter"></i>
            </button>

            <Link
              to={`${configure.appUrl}finance/zithas-add-invoice`}
              className="border-radius btn text-white me-2 btn-purple"
            >
              <i className="fas fa-plus me-2 icon-size"></i>Add Invoice
            </Link>

            <button
              onClick={() => navigate(-1)}
              className="border-radius btn btn-red"
            >
              <i className="dripicons-cross me-2 icon-center"></i>CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative mt-4">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-header" : ""
            } fix-zithas-tech-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-zithas-tech-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
        {formPopup ? (
          <div
            className="credit-popup-form popup-form p-4"
            style={{ right: "19%" }}
          >
            <Form>
              <Row className="align-items-end">
                <div className="col-md-4">
                  <label htmlFor="">Start Date</label>
                  <input type="date" className="form-control border-radius" />
                </div>

                <div className="col-md-4">
                  <label htmlFor="">End Date</label>
                  <input type="date" className="form-control border-radius" />
                </div>

                <div className="text-center col-md-4">
                  <button className="btn border-radius btn-purple me-3">
                    <i className="fas fa-search me-2"></i>
                    Search
                  </button>
                  <button
                    className="btn border-radius btn-red"
                    onClick={e => {
                      e.preventDefault()
                      setFormPopup(!formPopup)
                    }}
                  >
                    <i className="dripicons-cross me-1 icon-center"></i> Clear
                  </button>
                </div>
              </Row>
            </Form>
            <i className="bi bi-triangle-fill pop-icon"></i>
          </div>
        ) : null}
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </>
  )
}

const ZithasPInvoiceIndex = () => {
  const navigate = useNavigate()

  useEffect(() => window.scrollTo(0, 0), [])

  //meta title
  document.title = "Zithas Invoice | Zithas Crm"

  const onDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete this Invoice",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        return
      }
    })
  }

  const cloneInvoice = () => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to clone of this invoice?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        navigate(`${configure.appUrl}finance/zithas-clone-invoice`)
      }
    })
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Sr. No",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Date",
        accessor: "date",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Invoice#",
        accessor: "invoice",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
        isInfoIcon: true,
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Amount",
        accessor: "amount",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Balance Due",
        accessor: "balDue",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <Link
                to={`${configure.appUrl}finance/zithas-view-invoice`}
                title="view"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-eye "></i>
              </Link>
              <button
                onClick={cloneInvoice}
                title="TAX Invoice"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-clone"></i>
              </button>
              <Link
                to={`${configure.appUrl}finance/zithas-edit-invoice`}
                title="edit"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <button
                onClick={onDelete}
                title="delete"
                className="btn btn-sm btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      id: 1,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 2,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 3,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 4,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 5,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 6,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 7,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 8,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
    {
      id: 9,
      invoice: "INV-123-001",
      customerName: "Zithas Technologies123",
      dueDate: "25-05-2023",
      balDue: 7060,
      date: "28-08-2023",
      amount: 3000,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Zithas Invoice"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ZithasPInvoiceIndex
