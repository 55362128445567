import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import JsPDF from "jspdf"
import { Row, Modal, Form, Canvas } from "reactstrap"
// canvas
import SignatureCanvas from "react-signature-canvas"

// images
import zithasLogo from "../../../../assets/images/zithas-dark-logo.png"
import sign from "../../../../assets/images/sign.png"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import swal from "sweetalert"
import configure from "configure"

const GeneratedContract = () => {
  const [fixedRightSec, setFixedRightSec] = useState(false)
  const [modal_center, setmodal_center] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 48) {
      setFixedRightSec(true)
    } else {
      setFixedRightSec(false)
    }
  })

  const printContract = () => {
    let printContents = document.getElementById("zithasInvoicePrint").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }

  const generatePDF = () => {
    const contract = new JsPDF("portrait", "pt", "a3")
    contract.html(document.querySelector("#zithasInvoicePrint")).then(() => {
      contract.save("zithasContract.pdf")
    })
  }
  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  const [description, setDescription] = useState("")
  const [date, setDate] = useState("")
  const [template_name, setTemplateName] = useState("")
  const [expire, setExpire] = useState()

  useEffect(() => {
    fetchContract()
  }, [])

  const fetchContract = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("junk_link", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_introducer_contract`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.success == true) {
          setExpire(false)
          setDescription(response.data.data[0]?.description)
          setDate(response.data.data[0]?.created_at)
          setTemplateName(response.data.data[0]?.template_name)
          setContractId(response.data.data[0]?.id)
        } else {
          setExpire(true)
        }
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [contract_id, setContractId] = useState("")

  const signatureRef = useRef(null)
  const [signature, setSignature] = useState("")
  const [sign_name, setSignName] = useState("")

  const handleSave = async e => {
    e.preventDefault()
    const signatureImage = signatureRef.current.toDataURL()
    setLoading(true)
    const formData = new FormData()
    formData.append("contract_id", contract_id)
    formData.append("name", name)
    formData.append("email", email)
    formData.append("signature", signatureImage)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/save_introducer_signature`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Thanks For signining Contract",
        }).then(data => {
          setmodal_center(false)
          setSignature(response.data.data[0].signature)
          setSignName(response.data.data[0].name)
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const handleClear = () => {
    signatureRef.current.clear()
  }

  if (expire == false) {
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="page-content mb-2" style={{ paddingTop: "60px" }}>
          <Container fluid>
            <Row>
              <div className="col-md-9 ps-0">
                <div
                  id="zithasInvoicePrint"
                  className="bg-white p-3 shadow document"
                >
                  <div className="pb-2 text-center">
                    <img src={zithasLogo} height="60" alt="zithasLogo" />
                  </div>
                  <div>
                    <h5 className="text-center mt-4 mb-4 fw-bold"></h5>

                    <div>
                      <div
                        style={{ textAlign: "justify" }}
                        className="Features"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </div>

                    <h5 className="fw-bold text-decoration-underline">
                      Date : {moment(date).format("DD-MM-YYYY")}
                    </h5>

                    <Row className="mt-4 justify-space-between">
                      <div className="col-4">
                        {sign_name?.length != 0 ? (
                          <img src={sign} height="50" className="mb-2" />
                        ) : (
                          <div className="sign-box">Signature</div>
                        )}

                        <h5>Zithas Signature</h5>
                      </div>
                      <div className="col-4">
                        <img src={signature} height="50" className="mb-2" />
                        <h5>{sign_name}</h5>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>

              <div className="col-md-3 pe-0 ">
                <div
                  className={`${
                    fixedRightSec ? "fixed-right-section top-0 " : ""
                  }`}
                >
                  <div className="col-12 text-end ">
                    <button
                      onClick={() => printContract()}
                      className="btn btn-sm btn-success border-radius me-1"
                    >
                      <i className="fas fa-print me-1"></i> Print
                    </button>
                    <button
                      onClick={() => setmodal_center(true)}
                      className="btn btn-sm btn-primary border-radius me-1"
                    >
                      <i className="fas fa-file-signature me-1"></i> Signature
                    </button>

                    <button
                      onClick={generatePDF}
                      className="btn btn-sm btn-secondary border-radius"
                    >
                      <i className="fas fa-file-download me-1"></i> Downloads
                    </button>
                  </div>

                  <div className="bg-white p-3 mt-4 shadow ">
                    <p className="text-danger">
                      * If you encounter any issues while signing the contract,
                      please upload your signature from here.
                    </p>

                    <div>
                      <label htmlFor="">Attachment</label>
                      <Row className="align-items-center">
                        <div className="col-10 pe-0">
                          <input
                            type="file"
                            className="form-control border-radius"
                          />
                        </div>
                        <div className="col-2 ps-1">
                          <button
                            title="upload"
                            className="btn btn-secondary border-radius"
                          >
                            <i className="fas fa-upload"></i>
                          </button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>

        {/* Signature Modal */}
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center()
          }}
          centered
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Signature & Confirmation Of Identity
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSave}>
              <Row>
                <div className="col-5 mb-3">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="form-control border-radius"
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                <div className="col-5 mb-3">
                  <label htmlFor="">Email Address </label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control border-radius"
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="">Signature</label>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 760,
                      height: 300,
                      className: "sigCanvas border ",
                    }}
                    ref={signatureRef}
                  />
                </div>
              </Row>
              <div className="border-bottom my-3"></div>
              <div className="text-end">
                <a
                  className="btn btn-red border-radius me-2"
                  onClick={handleClear}
                >
                  Clear Signature
                </a>
                <button className="btn btn-purple border-radius">Submit</button>
              </div>
            </Form>
          </div>
        </Modal>
        {/* ------------ */}
      </React.Fragment>
    )
  } else if (expire == true) {
    return (
      <section className="my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center col">
              <div className="home-wrapper">
                <div className="mb-5">
                  <a className="d-block auth-logo" href="/dashboard"></a>
                </div>
                <div className="justify-content-center row">
                  <div className="col-sm-4">
                    <div className="maintenance-img">
                      <img
                        src="/static/media/maintenance.938d28ce46a03edf5d9303e67f1d2028.svg"
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                </div>

                <h1 className="mt-5 pt-5">Thanks For signining Contract</h1>
                <h5 className="mt-2">We'll get in contact with you shortly </h5>

                <div className="mt-5">
                  <button
                    className="border-radius btn text-white me-2 btn-purple"
                    onClick={() => window.close()}
                  >
                    <i className="dripicons-cross me-2 icon-center"></i>Close
                    Browser Tab
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return <Loader />
  }
}

export default GeneratedContract
