import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import Select from "react-select"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// assets
import Loader from "pages/Separate/Loader"
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import axios from "axios"
import swal from "sweetalert"
import moment from "moment"
import configure from "configure"
import Swal from "sweetalert2"
// import AddMeeting from "../AddMeeting"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import viewNotes from "../../../../assets/images/Project/viewNotes.jpg"
import profile from "../../../../assets/images/profile.png"

const Notes = ({ noteData, assigned }) => {
  const [fixButtons, setFixButtons] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [edit_status, setEditStatus] = useState(false)
  const [viewFollowUp, setViewFollowUp] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)
  const [viewNote, setViewNote] = useState(false)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setDatas] = useState([])
  const navigate = useNavigate()
  const params = useParams()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setNotesSidebar(false), setEditNotesSidebar(false), setViewNote(false)
  })

  const [assign_option, setAssignOption] = useState([])

  useEffect(() => {
    setDatas(noteData)
    setAssignOption(
      assigned?.map(obj => ({
        label: obj.name,
        value: obj.id,
      }))
    )
  }, [noteData])

  const [currency_option, setCurrencyOption] = useState([])

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `${configure.apiUrl}/currency`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          setCurrencyOption(
            response.data.data.currency?.map(item => ({
              label: item.currency,
              value: item.id,
            }))
          )
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchCurrency()
  }, [])

  const [currency, setCurrency] = useState("")
  const [notedate, setNotedate] = useState("")
  const [amount, setAmount] = useState("")
  const [ckedata, setCkedata] = useState("")

  const [addNotes, setAddNotes] = useState(false)
  const [notesData, setNotesData] = useState(true)

  // const addNotes = async e => {
  //   e.preventDefault()
  //   setLoading(true)
  //   const formData = new FormData()
  //   formData.append("lead_id", params.id)
  //   formData.append("user_id", localStorage.getItem("auth_id"))
  //   formData.append("currency", currency)
  //   formData.append("date", notedate)
  //   formData.append("amount", amount)
  //   formData.append("description", ckedata)
  //   for (let i = 0; i < selectedFiles1.length; i++) {
  //     formData.append("files[]", selectedFiles1[i])
  //   }
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: `${configure.apiUrl}/add_lead_note`,
  //       data: formData,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(response => {
  //       setLoading(false)
  //       Swal.fire({
  //         icon: "success",
  //         text: "Note Added Successfully",
  //       }).then(data => {
  //         setDatas(response.data.data)
  //         console.log("res",response.data.data)
  //         setNotesSidebar(false)
  //       })
  //     })
  //   } catch (error) {
  //     console.log(error)
  //     setLoading(false)
  //   }
  // }

  const [tempid, setTempid] = useState("")

  const deleteNote = async (e, note_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to Delete this note !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async willdelete => {
      if (willdelete) {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("user_id", localStorage.getItem("auth_id"))
        formData.append("lead_id", params.id)
        formData.append("note_id", note_id)
        try {
          await axios({
            method: "post",
            url: `${configure.apiUrl}/delete_lead_note`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            setDatas(res.data.data)
            swal({
              icon: "success",
              text: "Note Deleted Successfully",
            }).then(data => {
              setLoading(false)
            })
          })
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }
    })
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // code for follow up
  const [followUpAssigned, setFollowUpAssigned] = useState()
  const [followUpPurpose, setFollowUpPurpose] = useState()
  const [followUpDate, setFollowUpDate] = useState()

  const addFollowUp = async e => {
    e.preventDefault()
    const result = followUpAssigned?.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("purpose", followUpPurpose)
    formData.append("assigned", result)
    formData.append("follow_date", followUpDate)
    formData.append("lead_id", params.id)
    formData.append("note_id", tempid)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_lead_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setDatas(res.data.data)
        swal({
          icon: "success",
          text: "Follow Up Added Successfully",
        }).then(data => {
          setEditStatus(false)
        })
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
    menu: (provided, state) => ({
      ...provided,
      top: "auto", // Reset the top position
      bottom: "100%", // Position the menu above the select input
    }),
  }

  const [edit_description, setEditDescription] = useState("")
  const [edit_currency, setEditCurrency] = useState([])
  const [edit_amount, setEditAmount] = useState("")
  const [edit_date, setEditDate] = useState("")
  const [note_id, setNoteId] = useState("")
  // console.log(edit_date)

  const editNotes = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("note_id", note_id)
    if (edit_currency != undefined || edit_currency?.value != undefined) {
      formData.append("currency", edit_currency.value)
    } else {
      formData.append("currency", "")
    }
    formData.append("date", edit_date)
    formData.append("amount", edit_amount)
    formData.append("description", edit_description)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_lead_note`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Note Edited Successfully",
        }).then(data => {
          setEditNotesSidebar(true)
          setDatas(response.data.data)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [editFollowdate, setEditFollowDate] = useState("")
  const [editFollowdesc, setEditFollowDesc] = useState("")
  const [editFollowassign, setEditFollowAssign] = useState([])

  // const [data, setData] = useState(true)

  const onEditFollowup = async e => {
    e.preventDefault()
    const result = editFollowassign?.map(obj => obj.value).join(",")
    setLoading(true)
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("note_id", note_id)
    formData.append("follow_date", editFollowdate)
    formData.append("purpose", editFollowdesc)
    formData.append("assigned", result)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_lead_followup`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLoading(false)
        swal({
          icon: "success",
          text: "FollowUp Edited Successfully",
        }).then(data => {
          setViewFollowUp(false)
          setDatas(response.data.data)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const downloadImage = imagelink => {
    console.log("link", imagelink)
    var link = document.createElement("a")
    link.href = imagelink
    link.download = "image.jpg"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => setNotesSidebar(true)}
              className="btn btn-purple  fix-btn"
            >
              <i className="fas fa-plus me-2"></i>
              <span>Note</span>
            </a>

            <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <div className="d-flex justify-content-between">
            <h5 className="text-dark">Add Note</h5>
            <div className="text-end">
              <a
                onClick={() => setNotesSidebar(true)}
                className=" btn btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                Note
              </a>
              <a
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </a>
            </div>
          </div>

          {/* Add Notes Sidebar */}
          {/* <div
            className={`notes-sidebar ${
              notesSidebar ? "show-notes-sidebar" : ""
            }`}
          >
            <form onSubmit={notesSidebar}>
              <Row className="align-items-center ">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Add New Note</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-plus me-2"></i>Add Note
                    </button>
                    <a
                      onClick={() => setNotesSidebar(false)}
                      className="btn btn-red text-uppercase"
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>Close
                    </a>
                  </div>
                </div>
                <div className="col-12 ">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={editor => {}}
                   
                  />
                </div>
              
             
                <div className="col-md-5 mt-3 notes">
                  <label htmlFor="touch">
                    Got in touch with this lead{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={e => setNotedate(e.target.value)}
                    className="form-control "
                    required
                  />
                </div>

                <div className="col-md-3 mt-3">
                  <label htmlFor="">Attachment</label>
                
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles1(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="dz-message needsclick p-0"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="d-flex px-2 custom-file-upload align-items-center">
                          <div className="">
                            <i className="display-6 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h5 className="mb-0 ms-2">Upload Files</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
               
                <div
                  className={`col-md-4 mt-3 dropzone-previews ${
                    selectedFiles1.length > 0 ? "d-block" : "d-none"
                  }`}
                  id="file-previews"
                >
                  <label className="mb-1">Uploaded Files</label>
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-1">
                      <Row className="align-items-center justify-content-evenly">
                        {selectedFiles1?.map((f, i) => {
                          return (
                            <Col key={i} className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </Card>
                </div>
              </Row>
            </form>
          </div> */}

          {/* Add Notes  */}
          <div
            className={`note-sticky-form ${
              notesSidebar ? "d-block" : "d-none"
            }`}
          >
            <Draggable>
              <ResizableBox
                width={700}
                style={{
                  position: "fixed",
                  top: "20%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
                <form onSubmit={notesSidebar}>
                  <Row className="align-items-center ">
                    <div className="col-12 d-flex justify-content-between">
                      <h5>Add New Note</h5>
                      <div className="text-end">
                        <button className="btn btn-purple  me-2">
                          <i className="fas fa-plus me-2"></i>Add Note
                        </button>
                        <a
                          onClick={() => setNotesSidebar(false)}
                          className="btn btn-red text-uppercase"
                        >
                          <i className="dripicons-cross me-1 icon-center"></i>
                          Close
                        </a>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <label htmlFor="">Description</label>
                      <CKEditor editor={ClassicEditor} onReady={editor => {}} />
                    </div>

                    <div className="col-md-5 mt-3 notes">
                      <label htmlFor="touch">
                        Got in touch with this lead{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="datetime-local"
                        onChange={e => setNotedate(e.target.value)}
                        className="form-control "
                        required
                      />
                    </div>

                    <div className="col-md-3 mt-3">
                      <label htmlFor="">Attachment</label>

                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles1(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dz-message needsclick p-0"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="d-flex px-2 custom-file-upload align-items-center">
                              <div className="">
                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h5 className="mb-0 ms-2">File</h5>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>

                    <div
                      className={`col-md-4 mt-3 dropzone-previews ${
                        selectedFiles1.length > 0 ? "d-block" : "d-none"
                      }`}
                      id="file-previews"
                    >
                      <label className="mb-1">File Name</label>
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-1">
                          <Row className="align-items-center justify-content-evenly">
                            {selectedFiles1?.map((f, i) => {
                              return (
                                <Col key={i} className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                              )
                            })}
                          </Row>
                        </div>
                      </Card>
                    </div>
                  </Row>
                </form>
              </ResizableBox>
            </Draggable>
          </div>

          <div className="mt-4">
            <ul className="verti-timeline list-unstyled notes-box">
              {notesData ? (
                <li className="event-list position-relative">
                  <div className="event-timeline-dot">
                    <img
                      src={profile}
                      className="userImg me-3 "
                      alt=""
                    />
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                      <div className=" d-flex justify-content-between ">
                        <div>
                          <p className="text-muted ">
                            <h6 className="d-inline me-2">By Admin</h6> At
                            05-08-2024 13:24:53
                            <span className="pl-15">
                              Got in touch with this lead at 06-08-2024 13:24:53
                            </span>
                          </p>
                        </div>
                        <div className="me-4">
                          {/* {note.type == 1 ? (
                              <span
                                onClick={() => {
                                  setViewFollowUp(true)
                                  setNoteId(note.id)
                                  setEditFollowDate(
                                    note?.lead_followup[0]?.date_time
                                  )
                                  setEditFollowDesc(
                                    note?.lead_followup[0]?.description
                                  )
                                  const idArray =
                                    note?.lead_followup[0]?.assign_user
                                      .split(",")
                                      .map(Number)
                                  setEditFollowAssign(
                                    assign_option.filter(obj =>
                                      idArray.includes(obj.value)
                                    )
                                  )
                                }}
                                className="me-2 cursor-pointer badge-soft-success badge"
                              >
                                follow up
                              </span>
                            ) : null} */}
                          {/* 
                            {note.type == 0 ? (
                              <span
                                onClick={e => {
                                  setEditStatus(true)
                                  setTempid(note.id)
                                }}
                                className="me-2 cursor-pointer badge-soft-success badge"
                              >
                                Add follow up
                              </span>
                            ) : null} */}
                          <a
                            className="btn btn-sm edit-delete-btn text-success me-2"
                            onClick={() => setEditNotesSidebar(true)}
                          >
                            <i className="fas fa-edit" title="Edit Note"></i>
                          </a>
                          <a
                            className="btn btn-sm edit-delete-btn text-success me-2"
                            onClick={() => setViewNote(true)}
                          >
                            <i className="fas fa-eye" title="View Note"></i>
                          </a>
                          <button className="btn btn-sm edit-delete-btn text-danger"
                          onClick={deleteNote}>
                            <i
                              className="fas fa-trash-alt"
                              title="Delete Note"
                            ></i>
                          </button>
                        </div>
                      </div>
                      <i className="bi bi-triangle-fill note-icon"></i>

                      <div className="Features" />
                      <p className="mb-2">Notes content goes here..</p>
                      {/* {note.link != null ? (
                          <p className="mb-1">
                            Attachment :{" "}
                            <a
                              target="_blank "
                              className="btn btn-sm edit-delete-btn text-secondary ml-10"
                              rel="noreferrer"
                              type="button"
                              href={note.link}
                              // onClick={() =>
                              //   downloadImage({ imagelink: note.link })
                              // }
                            >
                              <i className="fas fa-eye"></i> View
                            </a>
                          </p>
                        ) : null} */}
                    </div>
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>
            {/* {data.length == 0 ? <p>Note is Not Addded</p> : null} */}
          </div>

          {/* Edit Notes Sidebar */}
          {/* <div
            className={`notes-sidebar ${
              editNotesSidebar ? "show-notes-sidebar" : ""
            }`}
          >
            <form onSubmit={editNotesSidebar}>
              <Row className="align-items-center ">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Edit Note</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-plus me-2"></i>Edit Note
                    </button>
                    <a
                      onClick={() => setEditNotesSidebar(false)}
                      className="btn btn-red text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>Close
                    </a>
                  </div>
                </div>
                <div className="col-12 ">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={editor => {}}
               
                  />
                </div>

                <div className="col-md-5 mt-3 notes">
                  <label htmlFor="touch">
                    Got in touch with this lead{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={e => setNotedate(e.target.value)}
                    className="form-control "
                    required
                  />
                </div>

                <div className="col-md-3 mt-3">
                  <label htmlFor="">Attachment</label>
                 
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles1(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="dz-message needsclick p-0"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="d-flex px-2 custom-file-upload align-items-center">
                          <div className="">
                            <i className="display-6 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h5 className="mb-0 ms-2">Upload Files</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
             
                <div
                  className={`col-md-4 mt-3 dropzone-previews ${
                    selectedFiles1.length > 0 ? "d-block" : "d-none"
                  }`}
                  id="file-previews"
                >
                  <label className="mb-1">Uploaded Files</label>
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-1">
                      <Row className="align-items-center justify-content-evenly">
                        {selectedFiles1?.map((f, i) => {
                          return (
                            <Col key={i} className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </Card>
                </div>
              </Row>
            </form>
          </div> */}

          {/* Edit Notes  */}
          <div
            className={`note-sticky-form ${
              editNotesSidebar ? "d-block" : "d-none"
            }`}
          >
            <Draggable>
              <ResizableBox
                width={700}
                style={{
                  position: "fixed",
                  top: "20%",
                  right: "2%",
                  zIndex: "1055",
                  backgroundColor: "#fff",
                }}
              >
               <form onSubmit={editNotesSidebar}>
              <Row className="align-items-center ">
                <div className="col-12 d-flex justify-content-between">
                  <h5>Edit Note</h5>
                  <div className="text-end">
                    <button className="btn btn-purple  me-2">
                      <i className="fas fa-plus me-2"></i>Edit Note
                    </button>
                    <a
                      onClick={() => setEditNotesSidebar(false)}
                      className="btn btn-red text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>Close
                    </a>
                  </div>
                </div>
                <div className="col-12 ">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={editor => {}}
                    // onChange={(event, editor) => {
                    //   const data = editor.getData()
                    //   setCkedata(data)
                    // }}
                  />
                </div>

                <div className="col-md-5 mt-3 notes">
                  <label htmlFor="touch">
                    Got in touch with this lead{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    onChange={e => setNotedate(e.target.value)}
                    className="form-control "
                    required
                  />
                </div>

                <div className="col-md-3 mt-3">
                  <label htmlFor="">Attachment</label>
                  {/* custom file upload */}
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles1(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="dz-message needsclick p-0"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="d-flex px-2 custom-file-upload align-items-center">
                          <div className="">
                            <i className="display-6 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h5 className="mb-0 ms-2">File</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                {/* file display here */}
                <div
                  className={`col-md-4 mt-3 dropzone-previews ${
                    selectedFiles1.length > 0 ? "d-block" : "d-none"
                  }`}
                  id="file-previews"
                >
                  <label className="mb-1">File Name</label>
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-1">
                      <Row className="align-items-center justify-content-evenly">
                        {selectedFiles1?.map((f, i) => {
                          return (
                            <Col key={i} className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </Card>
                </div>
              </Row>
            </form>
              </ResizableBox>
            </Draggable>
          </div>
          
        </div>
      </div>

      {/* View Notes Modal  */}
      <Modal Modal isOpen={viewNote} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">View Notes</h5>
          <button
            type="button"
            onClick={() => {
              setViewNote(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            // onSubmit={handleEditSubmit}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                <img src={viewNotes} alt="Addepe" style={{ width: "90%" }} />
              </div>
            </div>

            <div className="col-md-7 d-flex flex-column ">
              <div className="col-12">
                <label htmlFor="">Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    isReadOnly: true, // Set editor to read-only
                  }}
                  onReady={editor => {
                    // You can store the "editor" and use it when needed
                    console.log("Editor is ready to use!", editor)
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    console.log({ event, data })
                  }}
                />
              </div>

              <div
                className={`col-md-6 mt-3 dropzone-previews
                   
                `}
                id="file-previews"
              >
                <label className="mb-1">Uploaded Files</label>
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-1">
                    <Row className="align-items-center justify-content-evenly">
                      <Col className="col-md-6">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded"
                          alt="filename.pdf"
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>

              <div className="my-4 text-center">
                <a
                  onClick={() => setViewNote(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* View Follow Up Modal */}
      <Modal
        isOpen={viewFollowUp}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setViewFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row bd-span-input" onSubmit={onEditFollowup}>
            <>
              <div className="mb-3 col-12">
                <label htmlFor="formrow-firstname-Input">Date</label>
                <input
                  onChange={e => setEditFollowDate(e.target.value)}
                  type="date"
                  className="form-control "
                  value={editFollowdate}
                />
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Purpose</label>
                <input
                  onChange={e => setEditFollowDesc(e.target.value)}
                  type="text"
                  className="form-control "
                  value={editFollowdesc}
                />
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Assigned</label>
                <Select
                  onChange={e => setEditFollowAssign(e)}
                  placeholder="Select Assigned"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  isMulti
                  styles={colourStyles}
                  options={assign_option}
                  value={editFollowassign}
                />
              </div>
              <div className="mt-2 text-end">
                <button className=" btn btn-purple">Submit</button>
              </div>
            </>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default Notes
