import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"
import AccessDenied from "../../../assets/images/dribbble_1.gif"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Customer.css"

// common component
import Pagination from "components/Common/Pagination"

// components
import ViewProfile from "./ViewCustomer/ViewProfile"
import ViewNotes from "./ViewCustomer/ViewNotes"
import ViewContacts from "./ViewCustomer/ViewContacts"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import ViewProposal from "./ViewCustomer/ViewProposal"
import ViewContracts from "./ViewCustomer/ViewContracts"
import ViewProject from "./ViewCustomer/ViewProject"
import ViewTask from "./ViewCustomer/ViewTask"
import ViewSupport from "./ViewCustomer/ViewSupport"
import ViewFiles from "./ViewCustomer/ViewFiles"
import configure from "configure"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

function TableContainer({ columns, data, setAccessDenied, setLoading }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [name_modal, setNameModal] = useState(false)

  const [title, setTitle] = useState("")

  const info = [
    {
      title: "Company",
      mean: " <p> <strong>Meaning :</strong>  In the Mars System's Customer Module, the<strong>Company</strong>  column represents the name of the customer's organization or business entity. </p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Company</strong> column is to provide specific identification of the customer's company. It ensures that customer records are associated with the correct organization, facilitating clear communication and accurate customer management within the Mars System.</p>",
    },
    {
      title: "Primary Contact",
      mean: "<p><strong>Meaning :</strong> The <strong>Primary Contact</strong> column designates the main individual within the customer's organization who serves as the primary point of contact for communications and interactions.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Primary Contact</strong>  column is to identify and record the key point of contact for the customer. It streamlines communication and ensures that your team knows who to reach out to within the customer's organization for inquiries, updates, or discussions within the Mars System.</p>",
    },
    {
      title: "Primary Email",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Customer Module, the  <strong>Primary Email</strong> column stores the primary email address of the customer's primary contact. </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Primary Email</strong> column is to provide a direct and reliable means of communication with the primary contact. Email addresses enable efficient correspondence and information sharing. This column ensures that your team can easily contact the primary contact within the customer's organization within the Mars System.</p>",
    },
    {
      title: "Phone",
      mean: "<p><strong>Meaning :</strong> The <strong>Phone</strong> column records the contact phone number of the customer's primary contact within the organization.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Phone</strong> column is to offer an additional communication channel for contacting the primary contact. Phone numbers enable voice calls and real-time conversations, which can be valuable for discussing important matters or addressing urgent inquiries within the Mars System. This column ensures versatile means of communication with customers.</p>",
    },
  ]

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 6)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}sales/add-customer`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          onClick={() => onAddPermission()}
          className="btn btn-save fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Customer</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <Link
              onClick={() => onAddPermission()}
              className=" btn text-white me-2 btn-save btn-label"
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Customer
            </Link>
            <button
              onClick={() => navigate(-1)}
              className=" btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-header" : ""
            } fix-customers-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true)
                          setTitle(column.Header)
                        }}
                      >
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-customers-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        onClick={() =>
                          cell.column.Header === "Company"
                            ? navigate(`${configure.appUrl}sales/edit-customer`)
                            : ""
                        }
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map(item => {
                  if (item.title == title) {
                    return (
                      <>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </>
                    )
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Customers = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  //meta title
  document.title = "Customers | Zithas Crm"

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false)
  })

  const [data, setDatas] = useState([])
  const [view_customer, setVeiwCustomer] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        data: formData,
        url: `${configure.apiUrl}/show_customer`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [customer_contact, setCustomerContact] = useState([])
  const [customer_Note, setCustomerNote] = useState([])
  const [customer_proposal, setCustomerProposal] = useState([])
  const [customer_contract, setCustomerContract] = useState([])
  const [customer_project, setCustomerProject] = useState([])
  const [customer_task, setCustomerTask] = useState([])
  const [customer_support, setCustomerSupport] = useState([])
  const [customer_file, setCustomerFile] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const navigate = useNavigate()

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Company",
        accessor: "company",
        isInfoIcon: true,
        className: "cursor-pointer",
      },
      {
        Header: "Primary Contact",
        accessor: "primary",
        isInfoIcon: true,
        Cell: cellProps => {
          const data = cellProps.row.original["primary-contact"]?.filter(
            obj => obj.primarycontact == "on"
          )
          return <div>{data[0]?.name || "-"}</div>
        },
      },
      {
        Header: "Primary Email",
        accessor: "email",
        isInfoIcon: true,
        Cell: cellProps => {
          const data = cellProps.row.original["primary-contact"]?.filter(
            obj => obj.primarycontact == "on"
          )
          return <div>{data[0]?.email || "-"}</div>
        },
      },
      {
        Header: "Phone",
        accessor: "phone",
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        Cell: cellProps => {
          const onPermission = async (e, id) => {
            e.preventDefault()
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 6)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  onDeleteCustomer()
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onDeleteCustomer = e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this Customer !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("customer_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_customer`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Customer Deleted Successfully",
                    }).then(data => {
                      setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const viewData = async e => {
            setVeiwCustomer([cellProps.row.original])
            setmodal_fullscreen(true)
            setLoading(true)
            const formData = new FormData()
            formData.append("customer_id", cellProps.row.original.id)
            try {
              const response = await axios({
                method: "post",
                url: `${configure.apiUrl}/customer_data`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(response => {
                setCustomerProposal(response.data.data.proposal)
                setCustomerContact(response.data.data.contacts)
                setCustomerNote(response.data.data.customer_notes)
                setCustomerContract(response.data.data.contract)
                setCustomerProject(response.data.data.projects)
                setCustomerTask(response.data.data.task)
                setCustomerSupport(response.data.data.support)
                setCustomerFile(response.data.data.customer_files)
                setLoading(false)
              })
            } catch (err) {
              swal({
                icon: "error",
                text: "SomeThing Went Wrong",
              })
              setLoading(false)
            }
          }
          const onEditPermission = async ({ id }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 6)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  navigate(
                    `${configure.appUrl}sales/edit-customer/${cellProps.row.original.id}`
                  )
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          return (
            <div>
              <span className="form-switch form-switch-md me-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  defaultChecked={
                    cellProps.row.original["primary-contact"][0]
                      ?.primarycontact == "on"
                  }
                  id="customSwitchsizemd"
                  onChange={() => {}}
                />
              </span>
              <a
                onClick={viewData}
                title="View"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-eye "></i>
              </a>
              <Link
                title="edit"
                onClick={() =>
                  onEditPermission({
                    id: cellProps.row.original.id,
                  })
                }
                to={`${configure.appUrl}sales/edit-customer/${cellProps.row.original.id}`}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <a
                onClick={onPermission}
                title="delete"
                className="btn btn-sm  btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Customers"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Customer */}
      <Modal size="xl" isOpen={modal_fullscreen} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <h5 className="col-6 modal-title mt-0">Customer Details</h5>
          </div>

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab h-100 py-3 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Contacts
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Proposal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Contract
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Project
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    General Task
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleVertical("8")
                    }}
                  >
                    Support
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "9",
                    })}
                    onClick={() => {
                      toggleVertical("9")
                    }}
                  >
                    Files
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="px-4 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <ViewProfile view_customer={view_customer} />
                  </TabPane>
                  <TabPane tabId="2">
                    <ViewContacts customer_contact={customer_contact} />
                  </TabPane>
                  <TabPane tabId="3">
                    <ViewNotes customer_Note={customer_Note} />
                  </TabPane>
                  <TabPane tabId="4">
                    <ViewProposal customer_proposal={customer_proposal} />
                  </TabPane>
                  <TabPane tabId="5">
                    <ViewContracts customer_contract={customer_contract} />
                  </TabPane>
                  <TabPane tabId="6">
                    <ViewProject customer_project={customer_project} />
                  </TabPane>
                  <TabPane tabId="7">
                    <ViewTask customer_task={customer_task} />
                  </TabPane>
                  <TabPane tabId="8">
                    <ViewSupport customer_support={customer_support} />
                  </TabPane>
                  <TabPane tabId="9">
                    <ViewFiles customer_file={customer_file} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default Customers
