import React, { useState } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Modal,
  Form,
  Label,
  NavLink,
  CardTitle,
  NavItem,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useMemo } from "react"
import classnames from "classnames"
import { useTable, usePagination, useGlobalFilter } from "react-table"

// common component
import Pagination from "components/Common/Pagination"
import configure from "configure"

// Table 1
function TableContainer({ columns, data }) {
  const [status, setStatus] = useState(false)

  const [fixButtons, setFixButtons] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Render the UI for your table
  return (
    <div>
      <div
        className={`flex-column fixed-buttons ${
          fixButtons ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => {
            setStatus(true)
          }}
          className="btn btn-purple border-radius fix-btn"
        >
          <i className="fas fa-plus me-2"></i>
          <span>Source</span>
        </button>
        <Link
          to={`${configure.appUrl}staff`}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </Link>
      </div>

      <Row className="mb-2">
        <CardTitle className="h4 mb-25">Indian Source</CardTitle>
        <Col lg={2}>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className="border-radius btn btn-purple"
              onClick={() => {
                setStatus(true)
              }}
            >
              <i className="fas fa-plus me-2 icon-size"></i>Add Source
            </a>
            <a className="border-radius btn text-white ms-md-2 btn-red">
              <i className="dripicons-cross me-2 icon-center"></i>Cancel
            </a>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered mt-4" {...getTableProps()}>
        <thead className={`${fixedContent ? "fixed-setting-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`col-md-${
                    index === 0 ? "1" : index === 1 ? "9" : "2"
                  }`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={`col-md-${
                        index === 0 ? "1" : index === 1 ? "9" : "2"
                      }`}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
      <Modal isOpen={status} size="sm" className="bd-custom-md-2" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Source</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row">
            <div className="mb-3 col-md-3">
              <Label htmlFor="formrow-firstname-Input">Number</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter No."
              />
            </div>
            <div className="mb-3 col-md-9">
              <Label htmlFor="formrow-firstname-Input">Name </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name "
              />
            </div>
            <div className="mt-20">
              <button className="border-radius btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

// Table 2
function TableContainer2({ columns, data }) {
  const [status, setStatus] = useState(false)

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const [fixButtons, setFixButtons] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // Render the UI for your table
  return (
    <div>
      <Row className="mb-2">
        <div
          className={`flex-column fixed-buttons ${
            fixButtons ? "d-flex" : "d-none"
          }`}
        >
          <button
            onClick={() => {
              setStatus(true)
            }}
            className="btn btn-purple border-radius fix-btn"
          >
            <i className="fas fa-plus me-2"></i>
            <span>Status</span>
          </button>
          <Link
            to={`${configure.appUrl}staff`}
            className="btn btn-red border-radius fix-btn"
          >
            <i className="dripicons-cross me-2 icon-center"></i>
            <span>Cancel</span>
          </Link>
        </div>
        <CardTitle className="h4 mb-25">United Kingdom Source</CardTitle>
        <Col lg={2}>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className="border-radius btn btn-purple"
              onClick={() => {
                setStatus(true)
              }}
            >
              <i className="fas fa-plus me-2 icon-size"></i>Add Source
            </a>
            <a className="border-radius btn text-white ms-md-2 btn-red">
              <i className="dripicons-cross me-2 icon-center"></i>Cancel
            </a>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered mt-4" {...getTableProps()}>
        <thead className={`${fixedContent ? "fixed-setting-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`col-md-${
                    index === 0 ? "1" : index === 1 ? "9" : "2"
                  }`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={`col-md-${
                        index === 0 ? "1" : index === 1 ? "9" : "2"
                      }`}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      <Modal isOpen={status} size="sm" className="bd-custom-md-2" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Source</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row">
            <div className="mb-3 col-md-3">
              <Label htmlFor="formrow-firstname-Input">Number</Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter No."
              />
            </div>
            <div className="mb-3 col-md-9">
              <Label htmlFor="formrow-firstname-Input">Name </Label>
              <Input
                type="text"
                className="form-control"
                id="formrow-firstname-Input"
                placeholder="Enter Name "
              />
            </div>
            <div className="mt-20">
              <button className="border-radius btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const Source = () => {
  const columns1 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Source Name",
        accessor: "name",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <a
                onClick={() => {
                  setEditStatus(true)
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a to="#" className="btn btn-sm  btn-danger">
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )
  const columns2 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Source Name",
        accessor: "name",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <a
                onClick={() => {
                  setEditStatus(true)
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a to="#" className="btn btn-sm  btn-danger">
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )
  const data1 = [
    {
      id: 1,
      name: "Introducer",
    },
    {
      id: 2,
      name: "Customer",
    },
    {
      id: 3,
      name: "Existing",
    },
    {
      id: 4,
      name: "Reference",
    },
    {
      id: 5,
      name: "Facebook",
    },
  ]
  const data2 = [
    {
      id: 101,
      name: "Introducer",
    },
    {
      id: 102,
      name: "Customer",
    },
    {
      id: 103,
      name: "Uk -test",
    },
  ]

  const [edit_status, setEditStatus] = useState(false)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  return (
    <div>
      <Row>
        <Col lg={12} className="p-0">
          <CardBody className="pl-0 pt-0">
            <Row>
              <Col md="2" className="bd-source-tab">
                <Nav pills className="flex-column">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleVertical("1")
                      }}
                    >
                      India
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleVertical("2")
                      }}
                    >
                      United Kingdom
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="10" style={{ paddingLeft: "30px" }}>
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1" className="pt-30">
                    <div className="responsive-table">
                      <TableContainer columns={columns1} data={data1} />
                    </div>
                  </TabPane>
                  <TabPane tabId="2" className="pt-30">
                    <div className="responsive-table">
                      <TableContainer2 columns={columns2} data={data2} />
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Col>

        <Modal
          isOpen={edit_status}
          size="sm"
          className="bd-custom-md-2"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Edit Status</h5>
            <button
              type="button"
              onClick={() => {
                setEditStatus(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="row">
              <div className="mb-3 col-md-3">
                <Label htmlFor="formrow-firstname-Input">Number</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter No."
                  defaultValue="1"
                />
              </div>
              <div className="mb-3 col-md-9">
                <Label htmlFor="formrow-firstname-Input">Name </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Name "
                  defaultValue="Meeting"
                />
              </div>
              <div className="mt-20">
                <button className="border-radius btn btn-purple">Submit</button>
              </div>
            </Form>
          </div>
        </Modal>
      </Row>
    </div>
  )
}

export default Source
