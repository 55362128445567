import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dropzone from "react-dropzone"
import {
    Row,
    Form,
    Modal,
    Card,
    Col
} from "reactstrap";
import Select from 'react-select';

// editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// assets
import '../../../../assets/css/Sales/Sales.css';
import userImg from "../../../../assets/images/users/avatar-1.jpg";

const Notes = () => {

    const [fixButtons, setFixButtons] = useState(false);
    const [followUp, setFollowUp] = useState(false)
    const [notesSidebar, setNotesSidebar] = useState(false);
    const [viewFollowUp, setViewFollowUp] = useState(false);
    const [editNotesSidebar, setEditNotesSidebar] = useState(false)

    const navigate = useNavigate();

    document.addEventListener('scroll', () => {
        if (window.scrollY > 160) {
            setFixButtons(true);
        }
        else {
            setFixButtons(false);
        }
    })

    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
        }),
        menu: base => ({
            ...base,
            zIndex: 50
        })
    }

    const currency = [
        { label: "GBP", value: "1" },
        { label: "INR", value: "2" },
        { label: "CAD", value: "3" }
    ];


    const notes = [
        {
            name: 'Yasir Khan',
            userImg: userImg,
            dateTime: '01-06-2023 15:36:15'
        },
        {
            name: 'Yasir Khan',
            userImg: userImg,
            dateTime: '01-06-2023 15:36:15'
        },
        {
            name: 'Yasir Khan',
            userImg: userImg,
            dateTime: '01-06-2023 15:36:15'
        },
        {
            name: 'Yasir Khan',
            userImg: userImg,
            dateTime: '01-06-2023 15:36:15'
        },
        {
            name: 'Yasir Khan',
            userImg: userImg,
            dateTime: '01-06-2023 15:36:15'
        },
        {
            name: 'Yasir Khan',
            userImg: userImg,
            dateTime: '01-06-2023 15:36:15'
        }
    ]

    return (
        <React.Fragment>
            <div className="mb-3" >
                <div className='px-3 py-4 bg-white'>
                    <Form>
                        {/* Fixed Buttons */}
                        <div className={`flex-column fixed-buttons ${fixButtons ? 'd-flex' : 'd-none'}`}>
                            <a onClick={() => setNotesSidebar(true)} className='btn btn-purple border-radius fix-btn'>
                                <i className='fas fa-plus me-2'></i>
                                <span>Note</span>
                            </a>
                            <a onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                                <i className='dripicons-cross me-2 icon-center'></i>
                                <span>Cancel</span>
                            </a>
                        </div>
                        <div className='d-flex justify-content-between mb-4'>
                            <h5>
                                Notes
                            </h5>
                            <div className='text-end'>
                                <a onClick={() => setNotesSidebar(true)} className='border-radius btn btn-purple'>
                                    <i className="fas fa-plus me-2 icon-size"></i>Add Note
                                </a>
                                <a onClick={() => navigate(-1)} className="border-radius btn ms-md-2 btn-red">
                                    <i className='dripicons-cross me-2 icon-center'></i>CANCEL
                                </a>
                            </div>
                        </div>
                        {/* Add Notes */}
                        <div className={`notes-sidebar ${notesSidebar ? 'show-notes-sidebar' : ''}`} >
                            <Row className='align-items-center justify-content-between'>
                                <div className='col-12 d-flex justify-content-between'>
                                    <h5>Add New Note</h5>
                                    <div className='text-end'>
                                        <button className='btn btn-purple border-radius me-2'>
                                            <i className='fas fa-plus me-2'></i>Add Note
                                        </button>
                                        <a onClick={() => setNotesSidebar(false)} className='btn btn-secondary text-uppercase border-radius'>
                                            <i className='dripicons-cross me-1 icon-center'></i>Close
                                        </a>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <label htmlFor="">Description</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data="<p>This is a dummy note you can change it if you want</p>"
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                        }}
                                    />
                                </div>
                                <div className='col-md-4 mt-3 notes'>
                                    <label htmlFor=''>
                                        Got in touch with this lead
                                    </label>
                                    <input type="date" className='form-control border-radius' />
                                </div>

                                <div className='col-md-2 mt-3 text-end'>
                                    <a onClick={() => {
                                        setFollowUp(true)
                                    }} className='btn btn-success border-radius mt-4'>
                                        <i className='fas fa-plus me-2'></i>Follow Up
                                    </a>
                                </div>
                            </Row>
                        </div>

                        <div >
                            <ul className="verti-timeline list-unstyled notes-box">
                                {/* Activities */}
                                {notes.map((note, key) => (
                                    <li key={key} className="event-list position-relative">
                                        <div className="event-timeline-dot">
                                            <img src={note.userImg} className='userImg me-3 ' alt="" />
                                        </div>
                                        <div className="d-flex">
                                            <div className="flex-grow-1 ms-4">
                                                <div className='note d-flex justify-content-between py-2 rounded ps-4'>
                                                    <div>
                                                        <p className="text-muted mb-4">
                                                            <h6 className='d-inline me-2'>By {note.name}</h6> {note.dateTime}
                                                        </p>
                                                        <i className="bi bi-triangle-fill note-icon"></i>
                                                    </div>
                                                    <div className='me-4'>
                                                        <span onClick={() => setViewFollowUp(true)} className='me-2 cursor-pointer badge-soft-success badge'>
                                                            follow up
                                                        </span>
                                                        <button onClick={(e) => {
                                                            e.preventDefault()
                                                            setEditNotesSidebar(true)
                                                        }} className='btn btn-sm edit-delete-btn text-success me-2'>
                                                            <i className="fas fa-edit" title="Edit"></i>
                                                        </button>
                                                        <button className='btn btn-sm edit-delete-btn text-danger'>
                                                            <i className="fas fa-trash-alt" title="Delete"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            {/* Edit Notes Sidebar */}
                            <div className={`notes-sidebar ${editNotesSidebar ? 'show-notes-sidebar' : ''}`} >
                                <Row className='align-items-center justify-content-between'>
                                    <div className='col-12 d-flex justify-content-between'>
                                        <h5>Edit Note</h5>
                                        <div className='text-end'>
                                            <button className='btn btn-purple border-radius me-2'>
                                                <i className='fas fa-edit me-2'></i>Edit Note
                                            </button>
                                            <a onClick={() => setEditNotesSidebar(false)} className='btn btn-secondary text-uppercase border-radius'>
                                                <i className='dripicons-cross me-1 icon-center'></i>Close
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label htmlFor="">Description</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data="<p>This is a dummy note you can change it if you want</p>"
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-4 mt-3 notes'>
                                        <label htmlFor=''>
                                            Got in touch with this lead
                                        </label>
                                        <input type="date" className='form-control border-radius' />
                                    </div>

                                    <div className='col-md-2 mt-3 text-end'>
                                        <a onClick={() => {
                                            setFollowUp(true)
                                        }} className='btn btn-success border-radius mt-4'>
                                            <i className='fas fa-plus me-2'></i>Follow Up
                                        </a>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            {/* Follow Up Modal */}
            <Modal
                isOpen={followUp}
                size="sm"
                className="bd-custom-md-3"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Follow Up</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setFollowUp(false)
                        }}
                        className="btn-modal-close"
                    >
                        <span aria-hidden="true" className="btn-modal-span">
                            &times;
                        </span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form className="row">
                        <div className="mb-3 col-12">
                            <label htmlFor="formrow-firstname-Input">Date</label>
                            <input
                                type="date"
                                className="form-control border-radius"
                            />
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="">Purpose</label>
                            <input
                                type="Purpose"
                                className="form-control border-radius"
                                placeholder="Enter Purpose"
                            />
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="">Assigned</label>
                            <Select
                                placeholder="Select Assigned"
                                className="basic-multi-select"
                                classNamePrefix="border-radius select"
                                styles={colourStyles}
                                options={currency} />
                        </div>
                        <div className="mt-2 text-end">
                            <button className="border-radius btn btn-purple">Submit</button>
                        </div>
                    </Form>
                </div>
            </Modal>
            {/* -------------- */}

            {/* View Follow Up Modal */}
            <Modal
                isOpen={viewFollowUp}
                size="sm"
                className="bd-custom-md-3"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">View Follow Up</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setViewFollowUp(false)
                        }}
                        className="btn-modal-close"
                    >
                        <span aria-hidden="true" className="btn-modal-span">
                            &times;
                        </span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form className="row bd-span-input">
                        <div className="mb-3 col-12">
                            <label htmlFor="formrow-firstname-Input">Date</label>
                            <div className="input-span">
                                02/25/2023
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="">Purpose</label>
                            <div className='input-span'>
                                Any Purpose
                            </div>
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="">Assigned</label>
                            <div className="input-span">
                                Admin
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
            {/* -------------- */}
        </React.Fragment>
    )
}

export default Notes