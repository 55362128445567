import axios from "axios"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Col, Card, CardBody } from "reactstrap"

const ViewRevision = ({ proposal_revision }) => {
  return (
    <div>
      <div>
        <div>
          {/* <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red s fix-btn"
            >
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h5>Revision History</h5>
                    <div className="text-end">
                      <a
                        onClick={() => navigate(-1)}
                        className="s btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </div>
                  <div>
                    {proposal_revision?.length != 0 ? (
                      <ul className="verti-timeline list-unstyled">
                        {/* Activities */}
                        {proposal_revision?.map((status, key) => (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <i
                                className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${
                                  status.id === 3 ? "bx-fade-right" : ""
                                }`}
                              />
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <h5>Proposal Edited</h5>
                                    <p className="text-muted">
                                      {" "}
                                      At{" "}
                                      {moment(status.created_at).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      By {status.added_by}
                                    </p>
                                  </div>
                                  {status.new_data?.length != 0 ? (
                                    <div className="ml-30">
                                      <a
                                        className="btn btn-sm activity-log-btn text-info"
                                        // onClick={() => {
                                        //   setOldData(
                                        //     JSON.parse(status.old_data)
                                        //   )
                                        //   setNewData(
                                        //     JSON.parse(status.new_data)
                                        //   )
                                        //   setViewData(true)
                                        // }}
                                      >
                                        <i className="fas fa-eye"></i> View
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <p className="timing">
                                {moment(status.created_at)
                                  .format("DD-MM-YYYY hh:mm:s")
                                  .slice(10)}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                    {proposal_revision?.length == 0 ? (
                      <div>No Change Done in Proposal</div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ViewRevision
