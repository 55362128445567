import React from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import {
    Row,
    Form
} from "reactstrap";
import staffguruLogo from '../../../../assets/images/staffguruLogo.png'

const ViewTsgInvoice = () => {

    const navigate = useNavigate();

    document.title = "View Invoice | Zithas Crm";

    return (
        <React.Fragment>
            <div className="page-content mb-3">
                <Container fluid>
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"New Invoice"}
                    />
                    <Row className='px-1 justify-content-center mb-5'>

                        <div className="col-8 text-end mb-4">
                            <button onClick={(e) => {
                                e.preventDefault()
                                navigate(-1)
                            }} className="border-radius btn ms-md-2 btn-red">
                                <i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                        </div>

                        {/* invoice page */}
                        <div className="col-md-8">

                            <div className='invoice-page p-4 pb-5 bg-white '>
                                <div className='row justify-content-between'>
                                    <div className='col-4'>
                                        <img src={staffguruLogo} className='img-fluid mt-2' alt="" />
                                    </div>

                                    <div className='col-4 text-end'>
                                        <span >Zithas Technologies Pvt. Ltd. FF-16 Kalpvrux Complex, Opp. GEB Substation, Gotri Road Vadodara Gujarat 390021 India <br /> GSTIN 24AABCZ2004K1ZL</span>
                                    </div>
                                </div>

                                <div className="row ">
                                    <div className="col-4 pe-0">
                                        <div className="invoice-line"></div>
                                    </div>
                                    <div className="col-4">
                                        <p className='fs-4 py-3 text-center bg-white mb-0'>PROFORMA INVOICE</p>
                                    </div>
                                    <div className="col-4 ps-0">
                                        <div className="invoice-line"></div>
                                    </div>
                                </div>

                                <div className="row justify-content-between">
                                    <div className="col-3 ">

                                        <p className='mb-1'>Bill To</p>
                                        <p className='mb-1'>Manav Infotech ,</p>
                                        <p className='mb-1'>Naskik,</p>
                                        <p className='mb-1'>Nashik, Maharashtra,</p>
                                        <p className='mb-1'>India - 423204 ,</p>
                                        <p className='mb-1'>GST : KLGH659823GFH</p>

                                    </div>
                                    <div className="col-5 ps-0">
                                        <table className='table '>
                                            <tbody>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Invoice#</th>
                                                    <td className='border border-secondary py-1'>PRF/2023-2024/0003</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Invoice Date</th>
                                                    <td className='border border-secondary py-1'>01-09-2023</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Terms</th>
                                                    <td className='border border-secondary py-1'>Due on Receipt</td>
                                                </tr>
                                                <tr>
                                                    <th className='border border-secondary py-1'>Due Date</th>
                                                    <td className='border border-secondary py-1'>01-09-2023</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <table className='table '>
                                        <thead>
                                            <tr>
                                                <th className='border border-secondary'>#</th>
                                                <th className='border border-secondary'>Item & Description</th>
                                                <th className='border border-secondary'>HSN/SAC</th>
                                                <th className='border border-secondary'>Qty</th>
                                                <th className='border border-secondary'>Rate</th>
                                                <th className='border border-secondary'>Amount</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className='border border-secondary'>1</td>
                                                <td className='border border-secondary'>Tssy</td>
                                                <td className='border border-secondary'>998314</td>
                                                <td className='border border-secondary'>1</td>
                                                <td className='border border-secondary'>15000</td>
                                                <td className='border border-secondary'>₹ 15000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p>Thank you for giving us an opportunity! Hoping work from you in future</p>
                                </div>

                                <div className='row justify-content-end'>
                                    <div className="col-5">
                                        <div className='d-flex py-2 mt-3 justify-content-between'>
                                            <span>Sub Total</span>
                                            <span>₹ 15000</span>
                                        </div>
                                        <div className='d-flex py-2 justify-content-between'>
                                            <span>Discount</span>
                                            <span>0.00</span>
                                        </div>
                                        <div className='d-flex pt-2 pb-3 justify-content-between'>
                                            <span>IGST (18%)</span>
                                            <span>₹ 2700</span>
                                        </div>
                                        <div className='d-flex pt-2 border-top border-secondary pb-2 justify-content-between'>
                                            <strong> Total (₹)</strong>
                                            <span>₹ 17700</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-4 row pb-5'>
                                    <p>Total in Words : <strong>Rupees Seventeen Thousand and Seven Hundred</strong></p>

                                    <h4 className='fw-bold'>Bank Details</h4>

                                    <div className="col-5">
                                        <table className='table'>
                                            <tr>
                                                <th>Bank Name : </th>
                                                <td>Indusind Bank Ltd. </td>
                                            </tr>
                                            <tr>
                                                <th>Branch Name :</th>
                                                <td>Jetulpur Rd. Branch </td>
                                            </tr>
                                            <tr>
                                                <th>Account Name :</th>
                                                <td>Zithas Technologies Pvt. Ltd. </td>
                                            </tr>
                                            <tr>
                                                <th>Account No. :</th>
                                                <td>258141122619 </td>
                                            </tr>
                                            <tr>
                                                <th>IFSC Code : </th>
                                                <td>INDB0000017 </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className='mt-4'>
                                        <h4 className='fw-bold'>Terms &amp; Conditions</h4>
                                        <p className='mb-0'>1. If payment made by crossed cheque in favour of Zithas Technologies Pvt. Ltd.</p>
                                        <p className='mb-0'>2. If NEFT / RTGS / Online Transfer should be made to given bank details.</p>
                                        <p className='mb-0'>3. Late payment charges will be applicable.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ViewTsgInvoice