import React, { useEffect, useState } from "react"
import { Table, Row, Col, Input, Modal, Form, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Select from "react-select"
import editCategory from "../../../assets/images/Category/edit-category.jpg"
import addCategory from "../../../assets/images/Category/add-category.jpg"
import ReactSelect from "react-select"

// common
import Pagination from "components/Common/Pagination"
import Swal from "sweetalert2"
import axios from "axios"
import configure from "configure"
import secureLocalStorage from "react-secure-storage"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import "../../../assets/css/KnowledgeBase/Index.css"

function TableContainer({
  columns,
  setLoading,
  edit_status,
  setEditStatus,
  editSubCateid,
  setEditSubCateid,
  setData,
  data,
  verticalActiveTab,
  modal_Access,
  setAccessDenied,
}) {
  const [status, setStatus] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [allStaff, setAllStaff] = useState([])
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState([])
  const [allUser, setAllUser] = useState([])
  const [selectedUser, setSelectedUser] = useState([])
  const [numberCate, setNumberCate] = useState("")
  const [cateName, setCateName] = useState("")
  const [subCateNameEdit, setSubsubCateNameEdit] = useState("")
  const [numberCateEdit, setNumberCateEdit] = useState("")
  const [selectedRoleEdit, setSelectedRoleEdit] = useState([])
  const [selectedUserEdit, setSelectedUserEdit] = useState([])
  const [parentCateid, setParentCateid] = useState(null)
  const [parentEditCateid, setParentEditCateid] = useState(null)
  const [parentCateOption, setParentCateOption] = useState([])

  useEffect(() => {
    const editData = data?.filter(item => {
      return item.id == editSubCateid
    })
    const subCateAccess = editData[0]
    if (subCateAccess) {
      const optionparentCate = {
        value: subCateAccess.parent,
        label: subCateAccess.category,
      }
      setParentEditCateid(optionparentCate)
      setNumberCateEdit(subCateAccess?.number)
      setSubsubCateNameEdit(subCateAccess?.subcategory)
      const subCateAccessIds = subCateAccess?.role_access
        ?.split(",")
        .map(id => parseInt(id.trim(), 10))
      const roleData = roles?.filter(item => {
        return subCateAccessIds?.includes(item.value)
      })
      setSelectedRoleEdit(roleData)
      const userAccessIds = subCateAccess?.user_access
        ?.split(",")
        ?.map(id => parseInt(id.trim(), 10))
      const userData = allUser?.filter(item => {
        console.log(item)
        return userAccessIds?.includes(item.value)
      })
      setSelectedUserEdit(userData)
    }
    // editData[0]?.role_access
  }, [edit_status])

  // const handleSetEditRoles = option => {
  //   setSelectedRoleEdit(option)
  //   const roleNames = allStaff?.map(employee => employee.role_name)
  //   const filteredOptions = option?.filter(item =>
  //     roleNames.includes(item.label)
  //   )
  //   const selectedEmployees = allStaff.filter(employ => {
  //     return filteredOptions.some(opt => opt.label === employ.role_name)
  //   })
  //   const optionUser = selectedEmployees?.map(items => ({
  //     value: items.id,
  //     label: items.name,
  //   }))
  //   setSelectedUserEdit(optionUser)
  // }

  const handleSetEditRoles = option => {
    setSelectedRoleEdit(option)
    const selectedRoleLabels = option.map(opt => opt.label)
    const previousRoleLabels = selectedRoleEdit.map(opt => opt.label)
    const addedRoles = selectedRoleLabels.filter(
      role => !previousRoleLabels.includes(role)
    )
    const removedRoles = previousRoleLabels.filter(
      role => !selectedRoleLabels.includes(role)
    )
    const usersForAddedRoles = allStaff
      ?.filter(employee => addedRoles?.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
    const usersForRemovedRoles = allStaff
      ?.filter(employee => removedRoles?.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
      setSelectedUserEdit(prevOption => {
      const updatedStaff = prevOption.filter(
        option =>
          !usersForRemovedRoles.some(user => user.value === option.value)
      )
      return [...updatedStaff, ...usersForAddedRoles]
    })
  }

  const handleChangeEditUser = selectedOption => {
    setSelectedUserEdit(selectedOption)
  }

  const fetchAllStaff = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log("staff", response)
        setAllStaff(response.data.data)
        const optionUser = response?.data?.data?.map(item => ({
          value: item.id,
          label: item.name,
        }))
        setAllUser(optionUser)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllStaff()
  }, [])

  const fatchRoles = async () => {
    setLoading(true)
    try {
      const bearerToken = secureLocalStorage.getItem("auth_token")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staffData`,
        headers: {
          Authorization: "Bearer " + bearerToken,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        console.log("roles", response)
        const optionRole = response.data.data.roles.map(item => ({
          value: item.id,
          label: item.role_name,
        }))
        setRoles(optionRole)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fatchRoles()
  }, [])

  // const handleSetRoles = option => {
  //   setSelectedRole(option)
  //   const roleNames = allStaff?.map(employee => employee.role_name)
  //   const filteredOptions = option?.filter(option =>
  //     roleNames.includes(option.label)
  //   )
  //   const selectedEmployees = allStaff.filter(employee => {
  //     return filteredOptions.some(option => option.label === employee.role_name)
  //   })
  //   const optionUser = selectedEmployees?.map(item => ({
  //     value: item.id,
  //     label: item.name,
  //   }))
  //   setSelectedUser(optionUser)
  // }
  const handleSetRoles = option => {
    setSelectedRole(option)
    const selectedRoleLabels = option.map(opt => opt.label)
    const previousRoleLabels = selectedRole.map(opt => opt.label)
    const addedRoles = selectedRoleLabels.filter(
      role => !previousRoleLabels.includes(role)
    )
    const removedRoles = previousRoleLabels.filter(
      role => !selectedRoleLabels.includes(role)
    )
    const usersForAddedRoles = allStaff
      .filter(employee => addedRoles.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
    const usersForRemovedRoles = allStaff
      .filter(employee => removedRoles.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))
      setSelectedUser(prevOption => {
      const updatedStaff = prevOption.filter(
        option =>
          !usersForRemovedRoles.some(user => user.value === option.value)
      )
      return [...updatedStaff, ...usersForAddedRoles]
    })
  }

  const handleChangeUser = selectedOption => {
    setSelectedUser(selectedOption)
  }

  const handleSubmitAddSubCate = async e => {
    e.preventDefault()
    if (parentCateid != null && cateName != "") {
      console.log("enterInner")
      if (selectedRole.length !== 0 || selectedUser.length !== 0) {
        setLoading(true)
        const formData = new FormData()
        formData.append("category_number", numberCate)
        formData.append("name", cateName)
        formData.append("parent", parentCateid.value)
        formData.append(
          "roll_name",
          selectedRole?.map(item => item.value).toString()
        )
        formData.append(
          "user_name",
          selectedUser?.map(item => item.value).toString()
        )

        try {
          const response = await axios.post(
            `${configure.apiUrl}/addKnowledgeCategory`,
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          if (response.data.success) {
            console.log("addKnowledgeCategory", response)
            setLoading(false)
            Swal.fire({
              icon: "success",
              text: "Sub Category Added successfully.",
            }).then(() => {
              setParentCateid(null)
              setNumberCate("")
              setCateName("")
              setSelectedUser([])
              setSelectedRole([])
              setData(response?.data?.data.subcategory)
              setStatus(false)
            })
          } else {
            setLoading(false)
            Swal.fire({
              icon: "warning",
              text: `${response.data.data}`,
            })
          }
        } catch (error) {
          setLoading(false)
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Please select User.",
        })
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select all mandatory field.",
      })
    }
  }

  const handleSubmitEditCate = async e => {
    e.preventDefault()
    if (selectedUserEdit.length !== 0) {
      setLoading(true)
      const formData = new FormData()
      formData.append("Category_number", numberCateEdit)
      formData.append("name", subCateNameEdit)
      formData.append("category_id", editSubCateid)
      formData.append("parent", parentEditCateid.value)
      formData.append(
        "roll_name",
        selectedRoleEdit?.map(item => item.value).toString()
      )
      formData.append(
        "user_name",
        selectedUserEdit?.map(item => item.value).toString()
      )

      try {
        const response = await axios.post(
          `${configure.apiUrl}/editKnowledgeCategory`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response) {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Sub Category Edited successfully.",
          }).then(() => {
            setParentCateid(null)
            setNumberCateEdit("")
            setSubsubCateNameEdit("")
            setSelectedUserEdit([])
            setSelectedRoleEdit([])
            setData(response?.data?.data.subcategory)
            setEditStatus(false)
          })
        }
      } catch (error) {
        setLoading(false)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select User.",
      })
    }
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const fatchData = async () => {
    setLoading(true)
    console.log("knowledgeCategory")
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/knowledgeCategory`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log("knowledgeCategory", response)
        const optionparentCate = response.data.data.category?.map(item => ({
          value: item.id,
          label: item.category,
        }))
        setParentCateOption(optionparentCate)
        setData(response?.data?.data.subcategory)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (verticalActiveTab == "2") {
      fatchData()
    }
  }, [verticalActiveTab])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  
  useEffect(() => setPageSize(50), [])

  const navigate = useNavigate()

  // Render the UI for your table
  const onAddTopic = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 36)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          setStatus(true)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div className="px-2">
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => {
            onAddTopic()
          }}
          className="btn btn-save btn-label pe-0  fix-btn"
        >
          <i className="fas fa-plus me-2 label-icon"></i>
          <span>Add</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-red btn-label pe-0 fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </a>
      </div>

      <Row className="mb-2">
        <Col lg={2}>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <a
              className=" btn btn-save btn-label"
              onClick={() => {
                onAddTopic()
              }}
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Sub
              Category
            </a>
            <a
              className=" btn text-white ms-md-2 btn-red btn-label"
              onClick={() => navigate(-1)}
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              Cancel
            </a>
          </div>
        </Col>
      </Row>
      <Table
        className="table table-bordered mt-4 Knowledge-header"
        {...getTableProps()}
      >
        <thead className={`${fixedContent ? "fixed-setting-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`col-md-${index === 1 || index === 2 ? "4" : "2"}`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      className={`col-md-${
                        index === 1 || index === 2 ? "4" : "2"
                      }`}
                      key={index}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Add Sub Category  */}
      <Modal isOpen={status} size="xl" className="" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Sub Category</h5>
          <button
            type="button"
            onClick={() => {
              setStatus(false)
              setParentCateid(null)
              setCateName("")
              setSelectedRole([])
              setSelectedUser([])
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmitAddSubCate}
            className="row d-flex flex-row justify-space-between align-items-center py-3"
            id="editCategory-box"
          >
            <div className="col-md-6 edit-category-img text-center">
              <img src={addCategory} alt="Add Image" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="mb-4 col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  Category <span className="text-danger">*</span>
                </Label>
                <Select
                  placeholder="Select Category"
                  className="basic-multi-select "
                  classNamePrefix="select"
                  styles={colourStyles}
                  options={parentCateOption}
                  value={parentCateid}
                  onChange={selectedOption => setParentCateid(selectedOption)}
                />
              </div>
              <div className="mb-4 col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  Sub Category Name <span className="text-danger">*</span>
                </Label>
                <Input
                  value={cateName}
                  onChange={e => setCateName(e.target.value)}
                  type="text"
                  required
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Sub Category Name"
                />
              </div>
              <div className="row">
                <div className=" col-md-12 mb-4">
                  <Label htmlFor="formrow-firstname-Input">Roll Name </Label>
                  {/* <Select
                    placeholder="Select roll"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Rolls}
                  /> */}
                  <ReactSelect
                    placeholder="Select Roll"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    options={roles}
                    isMulti="true"
                    value={selectedRole}
                    onChange={option => handleSetRoles(option)}
                  />
                </div>
                <div className=" col-md-12">
                  <Label htmlFor="formrow-firstname-Input">User Name</Label>
                  {/* <Select
                    placeholder="Select user"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Users}
                  /> */}
                  <ReactSelect
                    placeholder="Select User"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    options={allUser}
                    value={selectedUser}
                    isMulti="true"
                    onChange={selectedOption =>
                      handleChangeUser(selectedOption)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-20 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* Edit Sub Category Modal  */}
      <Modal isOpen={edit_status} size="xl" className="" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Sub Category</h5>
          <button
            type="button"
            onClick={() => {
              setEditStatus(false)
              setEditSubCateid("")
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmitEditCate}
            className="row d-flex flex-row justify-space-between align-items-center py-3"
            id="editCategory-box"
          >
            <div className="col-md-6 edit-category-img text-center">
              <img src={editCategory} alt="edit Image" />
            </div>
            <div className="col-md-6 align-self-center">
              <div className="mb-4 col-md-12">
                <Label htmlFor="formrow-firstname-Input">Category</Label>
                <Select
                  placeholder="Select Category"
                  className="basic-multi-select "
                  classNamePrefix="select"
                  styles={colourStyles}
                  options={parentCateOption}
                  value={parentEditCateid}
                  onChange={selectedOption =>
                    setParentEditCateid(selectedOption)
                  }
                />
              </div>
              <div className="mb-4 col-md-12">
                <Label htmlFor="formrow-firstname-Input">
                  Sub Category Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="formrow-firstname-Input"
                  placeholder="Enter Sub Category Name"
                  defaultValue="Wordpress"
                  value={subCateNameEdit}
                  onChange={e => setSubsubCateNameEdit(e.target.value)}
                />
              </div>
              <div className="row">
                <div className=" col-md-12 mb-4">
                  <Label htmlFor="formrow-firstname-Input">Roll Name </Label>
                  {/* <Select
                  placeholder="Select roll"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  options={Rolls}
                /> */}
                  <ReactSelect
                    placeholder="Select roll"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    options={roles}
                    isMulti="true"
                    value={selectedRoleEdit}
                    onChange={option => handleSetEditRoles(option)}
                  />
                </div>
                <div className=" col-md-12 mb-4">
                  <Label htmlFor="formrow-firstname-Input">User Name</Label>
                  {/* <Select
                  placeholder="Select user"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  options={Users}
                /> */}
                  <ReactSelect
                    placeholder="Select user"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    options={allUser}
                    isMulti="true"
                    value={selectedUserEdit}
                    onChange={selectedOption =>
                      handleChangeEditUser(selectedOption)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-20 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

const KnowledgeSubCategory = ({ verticalActiveTab }) => {
  const [modal_Access, setAccessDenied] = useState(false)

  const [edit_status, setEditStatus] = useState(false)
  const [editSubCateid, setEditSubCateid] = useState("")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const columns = useMemo(
    () => [
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>ID</div>
        },
        accessor: "id",
        className: "id-width",
        Cell: cellProps => {
          return (
            <div style={{ textAlign: "center" }}>
              {cellProps.row.original.id}
            </div>
          )
        },
      },
      {
        Header: "Category Name",
        accessor: "category",
      },
      {
        Header: "Sub Category Name",
        accessor: "subcategory",
      },
      {
        Header: () => {
          return <div style={{ textAlign: "center" }}>Action</div>
        },
        accessor: "action",
        className: "action-width",
        Cell: cellProps => {
          const deleteCate = async () => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 36)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  Swal.fire({
                    icon: "warning",
                    title: "Are You Sure ?",
                    text: "You want to delete this Sub Category.",
                    showCancelButton: true,
                  }).then(async willDelete => {
                    if (willDelete.isConfirmed) {
                      try {
                        const response = await axios({
                          method: "get",
                          url: `${configure.apiUrl}/deleteKnowledgeCategory/${cellProps.row.original.id}`,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        })
                        if (response) {
                          Swal.fire({
                            icon: "success",
                            text: "Sub Category deleted successfully.",
                          }).then(() => {
                            setData(response?.data?.data.subcategory)
                          })
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onEditPermission = async ({ id }) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 36)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  setEditStatus(true)
                  setEditSubCateid(id)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          return (
            <div style={{ textAlign: "center" }}>
              <a
                onClick={() => {
                  onEditPermission({ id: cellProps.row.original.id })
                }}
                className="btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a
                to="#"
                onClick={() => deleteCate()}
                className="btn btn-sm  btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }
  const countries = [
    { label: "WordPress", value: "1" },
    { label: "Html", value: "2" },
    { label: "Laravel", value: "3" },
    { label: "Video", value: "4" },
  ]

  const Users = [
    { label: "Rahul", value: "1" },
    { label: "Divyansh", value: "2" },
    { label: "Zain", value: "3" },
    { label: "Manav", value: "4" },
    { label: "Kunj", value: "5" },
    { label: "Vaibhav", value: "6" },
    { label: "Yasir", value: "7" },
  ]

  const Rolls = [
    { label: "Admin", value: "8" },
    { label: "Managing Director", value: "9" },
    { label: "Key Account Manage", value: "10" },
    { label: "Software Engineer", value: "11" },
    { label: "Marketing Executing", value: "12" },
    { label: "Testing Roll", value: "13" },
  ]

  return (
    <div>
      <Row>
        <Col lg={12} className="p-0">
          <div className="responsive-table">
            <TableContainer
              columns={columns}
              setLoading={setLoading}
              edit_status={edit_status}
              setEditStatus={setEditStatus}
              editSubCateid={editSubCateid}
              setEditSubCateid={setEditSubCateid}
              setData={setData}
              data={data}
              verticalActiveTab={verticalActiveTab}
              modal_Access={modal_Access}
              setAccessDenied={setAccessDenied}
            />
          </div>
        </Col>
      </Row>
      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </div>
  )
}

export default KnowledgeSubCategory
