import React, { useState, useEffect } from "react"
import { Table } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import indiaFlag from "../../../../assets/images/flags/india.png"
import UkFlag from "../../../../assets/images/flags/uk.png"
import configure from "configure"

const Profile = () => {
  const [fixButtons, setFixButtons] = useState(false)
  const [testingRows, setTestingRows] = useState([{}])
  const [breakdownRows, setBreakdownRows] = useState([{}])
  const [termsRows, setTermsRows] = useState([{}])

  // For Client information
  const [client, setClient] = useState([])

  // for Provider Informtaion
  const [providerInfo, setProviderInfo] = useState({})
  const [relatedClient, setRelatedClient] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    setLocation("India")
    setProviderInfo(ukProvider)

    setClient(introducer)
  }, [])

  // document.title = "Add General Proposal | Zithas Crm"

  // dynamic Testing form add-remove
  const onAddTestingRow = () => {
    setTestingRows([...testingRows, {}])
  }
  const onDeleteTestingRow = index => {
    const newInputs = [...testingRows]
    newInputs.splice(index, 1)
    setTestingRows(newInputs)
  }

  // dynamic breakdownRows Form add-remove
  const onAddBreakdownRow = () => {
    setBreakdownRows([...breakdownRows, {}])
  }
  const onDeleteBreakdownRow = index => {
    const newInputs = [...breakdownRows]
    newInputs.splice(index, 1)
    setBreakdownRows(newInputs)
  }

  // dynamic Terms and agreement Form add-remove
  const onAddTermsRow = () => {
    setTermsRows([...termsRows, {}])
  }
  const onDeleteTermsRow = index => {
    const newInputs = [...termsRows]
    newInputs.splice(index, 1)
    setTermsRows(newInputs)
  }

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  // for select controls

  const related_option = [
    { label: "Lead", value: "lead" },
    { label: "Customer", value: "customer" },
    { label: "Introducer", value: "introducer" },
  ]
  // ------------------------

  // Clients
  const introducer = [
    { label: "Nothing Selected", value: "1" },
    { label: "Jack Smith", value: "2" },
    { label: "Test company", value: "3" },
    { label: "Zenith Tins", value: "4" },
    { label: "Julius Gohil", value: "5" },
    { label: "Test Introducer", value: "6" },
  ]

  const ukProvider = {
    companyName: "Zithas Technologies",
    address: "Bristol, United Kingdom",
    website: "https://www.zithas.co.uk",
  }

  //   All Use States
  const [isLoading, setLoading] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [assign_option, setAssignOption] = useState([])
  const [currency_option, setCurrencyOption] = useState([])
  const [location, setLocation] = useState([])
  const [proposal_title, setProposalTitle] = useState("")
  const [proposal_subtitle, setProposalSubTitle] = useState("")
  const [status, setStatus] = useState([])
  const [currency, setCurrency] = useState([])
  const [date, setDate] = useState("")
  const [till_date, setTillDate] = useState("")
  const [related, setRelated] = useState([])
  const [lead_option, setLeadOption] = useState([])
  const [company_name, setCompany_name] = useState("")
  const [address, setAddress] = useState("")
  const [website, setWebsite] = useState("")
  const [person_name, setPersonName] = useState("")
  const [person_email, setPersonEmail] = useState("")
  const [person_phone, setPersonPhone] = useState("")
  const [provider_company, setProviderCompany] = useState("")
  const [provider_address, setProviderAddress] = useState("")
  const [provider_website, setProviderWebsite] = useState("")
  const [provider_person, setProviderPerson] = useState("")
  const [provider_phone, setProviderPhone] = useState("")
  const [provider_email, setProviderEmail] = useState("")
  const [assign, setAssign] = useState([])
  const [conclusion_email, setConclusionEmail] = useState("")
  const [conclusion_phone, setConclusionPhone] = useState("")
  const [source_id, setSourceId] = useState("")
  const [contact_name_options, setContactPersionOptions] = useState([])

  //   show Proposal Data api Fetch

  const params = useParams()

  useEffect(() => {
    FetchProposalData()
  }, [])

  const FetchProposalData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("proposal_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/view_proposal`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        if (
          response.data.data[0].proposaltitile != "undefined" &&
          response.data.data[0].proposaltitile != "null"
        )
          setProposalTitle(response.data.data[0].proposaltitile)

        if (
          response.data.data[0].proposalsubtitle != "undefined" &&
          response.data.data[0].proposalsubtitle != "null"
        )
          setProposalSubTitle(response.data.data[0].proposalsubtitle)
        setDate(response.data.data[0].date)
        setTillDate(response.data.data[0].opentill)
        const currRelated = related_option.filter(
          obj => obj.value == response.data.data[0].related
        )[0]
        setRelated(currRelated)

        setCompany_name(response.data.data[0].companyname)

        if (
          response.data.data[0].address != "undefined" &&
          response.data.data[0].address != "null"
        )
          setAddress(response.data.data[0].address)

        if (
          response.data.data[0].cilentcontact != "undefined" &&
          response.data.data[0].cilentcontact != "null"
        )
          setPersonPhone(response.data.data[0].cilentcontact)

        if (
          response.data.data[0].email != "undefined" &&
          response.data.data[0].email != "null"
        )
          setPersonEmail(response.data.data[0].email)

        if (
          response.data.data[0].website != "undefined" &&
          response.data.data[0].website != "null"
        )
          setWebsite(response.data.data[0].website)
        setProviderPerson(response.data.data[0].contactmember)
        setProviderPhone(response.data.data[0].contact)
        setProviderEmail(response.data.data[0].provideremail)
        setProviderCompany(response.data.data[0].company2)
        setProviderAddress(response.data.data[0].address1)
        if (
          response.data.data[0].website != "undefined" &&
          response.data.data[0].website != "null"
        )
          setProviderWebsite(response.data.data[0].website1)

        setTestingRows(JSON?.parse(response.data.data[0].deliverytesting))
        setTermsRows(JSON?.parse(response.data.data[0].terms))

        setBreakdownRows(JSON?.parse(response.data.data[0].costbreakdown))
        if (
          response.data.data[0].conclusion_email != "undefined" &&
          response.data.data[0].conclusion_email != "null"
        )
          setConclusionEmail(response.data.data[0].conclusion_email)
        if (
          response.data.data[0].conclusion_contact != "undefined" &&
          response.data.data[0].conclusion_contact != "null"
        )
          setConclusionPhone(response.data.data[0].conclusion_contact)

        await axios({
          method: "get",
          url: `${configure.apiUrl}/locations`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
          setLocationOption(option)
          setLocation(response.data.data[0].location)
          setLoading(false)
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/currency`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data.currency.map(item => ({
            label: item.currency,
            value: item.id,
            symbol: item.symbol,
          }))
          setCurrencyOption(option)
          setCurrency(
            option.filter(
              obj => obj.value == response.data.data[0].currency_id
            )[0]
          )
          setLoading(false)
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/proposal_status`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res3 => {
          const option = res3.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
          setStatusOption(option)
          setStatus(
            option.filter(obj => obj.value == response.data.data[0].status)[0]
          )
        })

        const fromData1 = new FormData()
        fromData1.append("related", response.data.data[0].related)
        fromData1.append("location", response.data.data[0].location)

        await axios({
          method: "post",
          url: `${configure.apiUrl}/related`,
          data: fromData1,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res2 => {
          setLoading(false)

          let options

          if (response.data.data[0].related == "introducer") {
            options = res2.data.data.map(obj => ({
              value: obj.id,
              label: obj.company,
              address: obj.address,
              details: obj.person_details,
              website: obj.website,
            }))
          } else {
            options = res2.data.data.map(obj => ({
              value: obj.id,
              label:
                obj.company +
                " - " +
                (obj.person_details[0]
                  ? obj.person_details[0]?.person_name
                  : "NA"),
              companyName: obj.company,
              address: obj.address,
              details: obj.person_details,
              website: obj.website,
            }))
          }

          setLeadOption(options)

          const temp = options.filter(
            obj => obj.value == response.data.data[0].source_name
          )[0]

          // setTimeout(() => onClientInfo(temp), 2000)

          setSourceId(temp)
          setCompany_name(temp?.companyName)
          if (temp?.details) {
            const contact_person_options = temp?.details?.map(person => ({
              label: person.person_name,
              value: person,
            }))
            setContactPersionOptions(contact_person_options)
            setPersonName(
              contact_person_options.find(
                item => item.label == response.data.data[0].contactname
              )
            )
          } else {
            setPersonName(response.data.data[0].contactname)
          }

          // if (currRelated?.value == "lead") {
          //   setPersonName(contact_person_options.find(item => item.label == response.data.data[0].contactname))
          //   setAddress(temp.address)
          //   setWebsite(temp.website)
          // } else if (currRelated?.value == "customer") {
          //   setPersonEmail(temp.details[0]?.email)
          //   // setPersonName(temp.details[0]?.person_name)
          //   setPersonPhone(temp.details[0]?.phone)
          //   setAddress(temp.address)
          //   setWebsite(temp.website)
          // }

          // onClientInfo(temp)
        })

        const fromData = new FormData()
        fromData.append("country_id", response.data.data[0].location)
        await axios({
          method: "post",
          url: `${configure.apiUrl}/lead_belong`,
          data: fromData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setAssignOption(
            res.data.data.assigned?.map(item => ({
              value: item.id,
              label: item.name,
            }))
          )
          setAssign(
            res.data.data.assigned
              ?.map(item => ({
                value: item.id,
                label: item.name,
              }))
              .filter(obj => obj.value == response.data.data[0].assignd)[0]
          )
          setLoading(false)
        })

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const onChangeCountry = async e => {
    if (e.value == 1) {
      setProviderCompany("Zithas Technologies.pvt.ltd")
      // setProviderAddress("Gotri, Vadodara, Gujarat, India")
      setProviderWebsite("www.zithas.com")
    } else if (e.value == 2) {
      setProviderCompany("Zithas Technologies")
      // setProviderAddress("Bristol, United Kingdom")
      setProviderWebsite("www.zithas.co.uk")
    }

    setLoading(true)
    setLocation(e.target.value)
    const fromData = new FormData()
    fromData.append("country_id", e.target.value)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/lead_belong`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setAssignOption(
          res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setStatusOption(
          res.data.data.lead_status.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onChangeRelated = async e => {
    console.log(e)
    setRelated(e)
    setRelatedClient(e)
    setLoading(true)
    setPersonEmail("")
    setPersonName("")
    setPersonPhone("")
    setSourceId([])
    setCompany_name("")
    setWebsite("")
    setAddress("")
    const fromData = new FormData()
    fromData.append("related", e.value)
    fromData.append("location", location)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/related`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)

        // console.log("Related", res)

        if (res.data.success === true) {
          if (e.value == "introducer") {
            setLeadOption(
              res.data.data.map(obj => ({
                value: obj.id,
                label: obj.company,
                address: obj.address,
                details: obj.person_details,
                website: obj.website,
              }))
            )
          } else {
            setLeadOption(
              res.data.data.map(obj => ({
                value: obj.id,
                label:
                  obj.company +
                  " - " +
                  (obj.person_details[0]
                    ? obj.person_details[0]?.person_name
                    : "NA"),
                companyName: obj.company,
                address: obj.address,
                details: obj.person_details,
                website: obj.website,
              }))
            )
          }
        }
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onClientInfo = e => {
    setSourceId(e)
    setCompany_name(e?.companyName)

    if (related?.value == "lead") {
      setContactPersionOptions(
        e.details.map(person => ({ label: person.person_name, value: person }))
      )
      // setPersonEmail(e.details[0]?.person_email)
      // setPersonName(e.details[0]?.person_name)
      // setPersonPhone(e.person_phone)
      setAddress(e.address)
      setWebsite(e.website)
    } else if (related?.value == "customer") {
      setContactPersionOptions(
        e.details.map(person => ({ label: person.person_name, value: person }))
      )

      setPersonEmail(e.details[0]?.email)
      // setPersonName(e.details[0]?.person_name)
      setPersonPhone(e.details[0]?.phone)
      setAddress(e.address)
      setWebsite(e.website)
    }
  }

  const onStaffDetail = async e => {
    setProviderPerson(e.label)
    setAssign(e)
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/show/${e.value}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setProviderPhone(response.data.data.staff.contact_number)
        setProviderEmail(response.data.data.staff.email)

        let city, state, country

        Object.keys(response.data.data.staff).forEach(obj => {
          switch (obj) {
            case "ctname":
              city =
                response.data.data.staff[obj] != null
                  ? response.data.data.staff[obj]
                  : "-"
              break
            case "sname":
              state =
                response.data.data.staff[obj] != null
                  ? response.data.data.staff[obj]
                  : "-"
              break
            case "cname":
              country =
                response.data.data.staff[obj] != null
                  ? response.data.data.staff[obj]
                  : "-"
              break
          }
        })

        setProviderAddress(`${city}, ${state}, ${country}`)

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // Testing Rows on Change Event
  const testingSr = (e, key) => {
    const updatedArray = [...testingRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setTestingRows(updatedArray)
  }
  const testingMilestone = (e, key) => {
    const updatedArray = [...testingRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setTestingRows(updatedArray)
  }
  const testingTime = (e, key) => {
    const updatedArray = [...testingRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setTestingRows(updatedArray)
  }

  // Terms and Aggrement on Change Event

  const termsSr = (e, key) => {
    const updatedArray = [...termsRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setTermsRows(updatedArray)
  }
  const termsService = (e, key) => {
    const updatedArray = [...termsRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setTermsRows(updatedArray)
  }
  const termsCurrency = (e, key) => {
    const updatedArray = [...termsRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setTermsRows(updatedArray)
  }
  const termsValue = (e, key) => {
    const updatedArray = [...termsRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setTermsRows(updatedArray)
  }

  // Cost BreakDown on Change Event

  const breakDescription = (e, key) => {
    const updatedArray = [...breakdownRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setBreakdownRows(updatedArray)
  }
  const breakSr = (e, key) => {
    const updatedArray = [...breakdownRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setBreakdownRows(updatedArray)
  }
  const breakTotal = (e, key) => {
    const updatedArray = [...breakdownRows]
    updatedArray[key] = {
      ...updatedArray[key],
      [e.target.name]: e.target.value,
    }
    setBreakdownRows(updatedArray)
  }

  const onEditProposal = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("proposal_id", params.id)
    formData.append("proposaltitle", proposal_title)
    formData.append("proposalsubtitle", proposal_subtitle)
    formData.append("currency", currency?.value)
    formData.append("date", date)
    formData.append("opentill", till_date)
    formData.append("related", related?.value)
    formData.append("companyname", company_name)
    formData.append(
      "contactname",
      person_name?.label ? person_name?.label : person_name
    )
    formData.append("address", address)
    formData.append("clientcontact", person_phone)
    formData.append("email", person_email)
    formData.append("website", website)
    formData.append("assigned", assign?.value)
    formData.append("company2", provider_company)
    formData.append("contactnumber", provider_phone)
    formData.append("address1", provider_address)
    formData.append("providermail", provider_email)
    formData.append("contact_member", provider_person)
    formData.append("website1", provider_website)
    formData.append("deliverytesting", JSON.stringify(testingRows))
    formData.append("terms", JSON.stringify(termsRows))
    formData.append("costbreakdown", JSON.stringify(breakdownRows))
    formData.append("location", location)
    formData.append("status", status?.value)
    formData.append("conclusion_email", conclusion_email)
    formData.append("conclusion_contact", conclusion_phone)
    formData.append("source_name", source_id?.value)
    formData.append("type", "General")
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_proposal`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Proposal Edited Succesfully",
        }).then(data => {
          navigate(`${configure.appUrl}sales/proposal`)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onCurrency = (e, key) => {
    let values = [...termsRows]
    values[key][e.target.name] = e.target.value
    setTermsRow(values)
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="ps-2">
        <Row className="px-1">
          <Form onSubmit={onEditProposal}>
            {/* Fixed Buttons */}
            <div
              className={`flex-column fixed-buttons pt-2 ${
                fixButtons ? "d-flex" : "d-none"
              }`}
            >
              <button className="btn btn-save btn-label pe-0  fix-btn">
                <i className="fas fa-save me-2 label-icon"></i>
                <span>Save</span>
              </button>
              <a
                onClick={() => navigate(-1)}
                className="btn btn-red  fix-btn btn-label pe-0"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                <span>Cancel</span>
              </a>
            </div>
            <Row className="bg-white px-2 justify-content-between py-4 shadow mb-3">
              <div className="col-md-3 mb-md-0 mb-3">
                <label htmlFor="">Location </label>
                <div className="row " style={{ marginLeft: "0px" }}>
                  {location_option.map((item, i) => {
                    return (
                      <div className="form-check mb-2 col-md-4" key={i}>
                        <input
                          className="form-check-input input-location"
                          type="radio"
                          name="exampleRadios"
                          id={`exampleRadios1${i}`}
                          value={item.value}
                          defaultChecked={location == item.value}
                          onChange={onChangeCountry}
                        />
                        <label
                          className="form-check-label pl-10"
                          htmlFor={`exampleRadios1${i}`}
                        >
                          {item.value == 1 ? (
                            <img
                              src={indiaFlag}
                              alt="no img"
                              width="28"
                              height="18"
                              style={{ marginTop: "2px" }}
                            />
                          ) : (
                            <img
                              src={UkFlag}
                              alt="no img"
                              width="28"
                              height="18"
                              style={{ marginTop: "2px" }}
                            />
                          )}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-md-6 mb-3 text-end">
                <button className=" btn ms-md-2 btn-save btn-label">
                  <i className="fas fa-save me-1 label-icon"></i> save
                </button>
                <button
                  onClick={e => {
                    e.preventDefault()
                    navigate(-1)
                  }}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </button>
              </div>
            </Row>

            {/* Service  info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Service Information</h5>

              <div className="col-md-6 mt-2 mb-3">
                <label htmlFor="">Proposal Title</label>
                <input
                  type="text"
                  defaultValue="Software Development"
                  placeholder="Enter Proposal Title"
                  className="form-control "
                  value={proposal_title}
                  onChange={e => {
                    setProposalTitle(e.target.value)
                    let values = [...termsRows]
                    values[1]["service"] = e.target.value
                    setTermsRows(values)
                  }}
                />
              </div>
              <div className="col-md-6 mt-2 mb-3">
                <label htmlFor="">Proposal Subtitle</label>
                <input
                  type="text"
                  defaultValue="Zithas Mars - Payroll Management"
                  placeholder="Enter Proposal Subtitle"
                  className="form-control "
                  value={proposal_subtitle}
                  onChange={e => setProposalSubTitle(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Status</label>
                <Select
                  placeholder="Select Status"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={status_option}
                  value={status}
                  onChange={e => setStatus(e)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Currency</label>
                <Select
                  placeholder="Select Currency"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={currency_option}
                  value={currency}
                  onChange={e => setCurrency(e)}
                />
              </div>
              <div className="col-md-2 mb-3 mb-md-0">
                <label htmlFor="">Date</label>
                <input
                  type="date"
                  className="form-control "
                  value={date}
                  onChange={e => setDate(e.target.value)}
                />
              </div>

              <div className="col-md-2 mb-3 mb-md-0">
                <label htmlFor="">Open Till</label>
                <input
                  type="date"
                  className="form-control "
                  value={till_date}
                  onChange={e => setTillDate(e.target.value)}
                />
              </div>
              <div className="col-md-2 mb-3 mb-md-0">
                <label htmlFor="">Related </label>
                <Select
                  placeholder="Select"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={related_option}
                  value={related}
                  onChange={e => onChangeRelated(e)}
                />
              </div>
            </Row>

            {/* Client  info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Client Information</h5>

              {related?.label !== "Nothing Selected" ? (
                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">{related?.label}</label>
                  <Select
                    placeholder="Select"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={lead_option}
                    value={source_id}
                    onChange={e => onClientInfo(e)}
                  />
                </div>
              ) : null}

              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Company Name</label>
                <input
                  type="text"
                  placeholder="Enter Company Name"
                  className="form-control "
                  value={company_name}
                  onChange={e => setCompany_name(e.target.value)}
                />
              </div>
              {/* <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Contact Person</label>
                <input
                  type="text"
                  placeholder="Enter Contact Person"
                  className="form-control "
                  value={person_name}
                  onChange={e => setPersonName(e.target.value)}
                />
              </div> */}

              {related?.label == "Introducer" ? (
                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">Contact Person</label>
                  <input
                    type="text"
                    placeholder="Enter Contact Person"
                    className="form-control "
                    value={person_name}
                    onChange={e => setPersonName(e.target.value)}
                  />
                </div>
              ) : (
                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">Contact Person</label>
                  <Select
                    placeholder="Select"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={contact_name_options}
                    value={person_name}
                    onChange={e => {
                      setPersonName(e)
                      console.log(e)
                      setPersonEmail(e?.value.person_email)
                      setPersonPhone(e?.value.person_phone)
                    }}
                  />
                </div>
              )}

              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Address</label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="form-control "
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Contact</label>
                <input
                  type="number"
                  placeholder="Enter Contact"
                  className="form-control "
                  value={person_phone}
                  onChange={e => setPersonPhone(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control "
                  value={person_email}
                  onChange={e => setPersonEmail(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3 mb-md-0">
                <label htmlFor="">Website</label>

                <input
                  type="text"
                  placeholder="Enter URL"
                  className="form-control "
                  value={website}
                  onChange={e => setWebsite(e.target.value)}
                />
              </div>
            </Row>

            {/* Provider  info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Provider Information</h5>

              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Assigned</label>
                <Select
                  placeholder="Select Status"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={assign_option}
                  value={assign}
                  onChange={e => onStaffDetail(e)}
                />
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Contact Person</label>
                <input
                  type="text"
                  placeholder="Enter Contact Person"
                  className="form-control "
                  value={provider_person}
                  onChange={e => setProviderPerson(e.target.value)}
                />
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Contact</label>
                <input
                  type="text"
                  placeholder="Enter Contact"
                  className="form-control "
                  value={provider_phone}
                  onChange={e => setProviderPhone(e.target.value)}
                />
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  defaultValue="gia@zithas.com"
                  placeholder="Enter Email Id"
                  className="form-control "
                  value={provider_email}
                  onChange={e => setProviderEmail(e.target.value)}
                />
              </div>

              <div className="col-md-4 mt-2 mt-3 ">
                <label htmlFor="">Company Name</label>
                <input
                  type="text"
                  placeholder="Enter Company Name"
                  className="form-control "
                  value={provider_company}
                  onChange={e => setProviderCompany(e.target.value)}
                />
              </div>
              <div className="col-md-4 mt-2 mt-3 ">
                <label htmlFor="">Address</label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="form-control "
                  value={provider_address}
                  onChange={e => setProviderAddress(e.target.value)}
                />
              </div>
              <div className="col-md-4 mt-2 mt-3 ">
                <label htmlFor="">Website</label>
                <input
                  type="text"
                  placeholder="Enter Website"
                  className="form-control "
                  value={provider_website}
                  onChange={e => setProviderWebsite(e.target.value)}
                />
              </div>
            </Row>

            {/* Deliverables & testing info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-3">Deliverables & testing</h5>
                <a
                  onClick={() => onAddTestingRow()}
                  className="btn btn-purple btn-label"
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i> Add
                  New Row
                </a>
              </div>
              <div className="responsive-table">
                <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                  <thead className="">
                    <tr>
                      <th>Sr No.</th>
                      <th>Milestone Description</th>
                      <th>Estimated Time (In Weeks)</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(testingRows || []).map((testingRow, key) => {
                      return (
                        <tr key={key}>
                          <td className="col-1">
                            <input
                              type="number"
                              value={testingRow["sr.no"]}
                              className="form-control "
                              onChange={e => testingSr(e, key)}
                              name="sr.no"
                            />
                          </td>
                          <td className="col-7">
                            <input
                              type="text"
                              value={testingRow.milestone}
                              placeholder="Enter Description"
                              className="form-control "
                              onChange={e => testingMilestone(e, key)}
                              name="milestone"
                            />
                          </td>
                          <td className="col-3">
                            <input
                              type="text"
                              value={testingRow.estimatedtime}
                              placeholder="Enter Estimated Time"
                              className="form-control "
                              onChange={e => testingTime(e, key)}
                              name="estimatedtime"
                            />
                          </td>
                          <td className="col-1 text-center">
                            {key !== 0 ? (
                              <a
                                onClick={() => onDeleteTestingRow(key)}
                                className="btn btn-outline-danger"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </a>
                            ) : (
                              "--"
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>

            {/* Terms and agreement */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-3">Terms and agreement</h5>
                <a
                  onClick={() => onAddTermsRow()}
                  className="btn btn-purple btn-label"
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i> Add
                  New Row
                </a>
              </div>
              <div className="responsive-table">
                <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                  <thead className="">
                    <tr>
                      <th>Sr No.</th>
                      <th>Particular</th>
                      <th>Currency</th>
                      <th>Total</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(termsRows || []).map((termsRow, key) => {
                      return (
                        <tr key={key}>
                          <td className="col-1">
                            <input
                              type="number"
                              className="form-control "
                              value={termsRow.no}
                              onChange={e => termsSr(e, key)}
                              name="no"
                            />
                          </td>
                          <td className="col-7">
                            <input
                              type="text"
                              placeholder="Enter Description"
                              className="form-control "
                              value={termsRow.service}
                              onChange={e => termsService(e, key)}
                              name="service"
                            />
                          </td>
                          <td className="col-1">
                            <input
                              type="text"
                              className="form-control "
                              value={currency?.symbol}
                              // onChange={e => onCurrency(e, key)}
                              // value={termsRow.currency}
                              // onChange={e => termsCurrency(e, key)}
                              name="currency"
                              readOnly
                            />
                          </td>
                          <td className="col-2">
                            <input
                              type="text"
                              placeholder="Enter Total"
                              className="form-control "
                              value={termsRow.total}
                              onChange={e => termsValue(e, key)}
                              name="total"
                            />
                          </td>
                          <td className="col-1 text-center">
                            <a
                              onClick={() => onDeleteTermsRow(key)}
                              className="btn btn-outline-danger"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>

            {/* Cost Breakdown info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex justify-content-between">
                <h5 className="mb-3">Cost Breakdown</h5>
                <a
                  onClick={() => onAddBreakdownRow()}
                  className="btn btn-purple btn-label"
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i> Add
                  New Row
                </a>
              </div>
              <div className="responsive-table">
                <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                  <thead className="">
                    <tr>
                      <th>Sr No.</th>
                      <th>Description</th>
                      <th>Estimated Time (In Weeks)</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(breakdownRows || []).map((breakdownRow, key) => {
                      return (
                        <tr key={key}>
                          <td className="col-1">
                            <input
                              type="number"
                              className="form-control "
                              value={breakdownRow.sr_no}
                              onChange={e => breakSr(e, key)}
                              name="sr_no"
                            />
                          </td>
                          <td className="col-7">
                            <input
                              type="text"
                              placeholder="Enter Description"
                              className="form-control "
                              value={breakdownRow.description}
                              onChange={e => breakDescription(e, key)}
                              name="description"
                            />
                          </td>
                          <td className="col-3">
                            <input
                              type="text"
                              placeholder="Enter Estimated Time"
                              className="form-control "
                              value={breakdownRow.totalcost}
                              onChange={e => breakTotal(e, key)}
                              name="totalcost"
                            />
                          </td>
                          <td className="col-1 text-center">
                            {key !== 0 ? (
                              <a
                                onClick={() => onDeleteBreakdownRow(key)}
                                className="btn btn-outline-danger"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </a>
                            ) : (
                              "--"
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Row>

            {/* Conclusion*/}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Conclusion</h5>

              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Email Id</label>
                <input
                  type="email"
                  placeholder="Enter Email Id"
                  className="form-control "
                  value={conclusion_email}
                  onChange={e => setConclusionEmail(e.target.value)}
                />
              </div>
              <div className="col-md-3 mt-2 mb-3 mb-md-0">
                <label htmlFor="">Phone Number</label>
                <input
                  type="text"
                  value={conclusion_phone}
                  onChange={e => setConclusionPhone(e.target.value)}
                  placeholder="Enter Phone no."
                  className="form-control "
                />
              </div>
            </Row>
          </Form>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Profile
