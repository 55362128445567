import React, { useEffect, useMemo, useState } from "react"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Form,
  Label,
  Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import { useTable, useGlobalFilter, usePagination } from "react-table"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
import cedentialsImg from "../../../assets/images/settingImg/Credentials.avif"
import tabImg from "../../../assets/images/credential/img-1.jpg"
import "../../../assets/css/Scollbar.css"
import "../../../assets/css/Settings/generalSetting.css"
// css
import "../../../assets/css/Settings/Setting.css"

// common component
import Pagination from "components/Common/Pagination"
import ReactSelect from "react-select"
import Swal from "sweetalert2"
import configure from "configure"
import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import { icon } from "leaflet"
import { warning } from "toastr"

import "../../../assets/css/Credential/Credential.css"
import { clippingParents } from "@popperjs/core"
import NewPagination from "components/Common/Newpagination"
import secureLocalStorage from "react-secure-storage"
import { includes } from "lodash"

function TableContainer({ columns, data, setAddCredentials }) {
  const [modal_center, setmodal_center] = useState(false)
  const [formRows, setFormRows] = useState([])
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Center Modal
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  // dynamic field add in modal
  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
  }

  const onDeleteFormRow = id => {
    var modifiedRows = [...formRows]
    modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    setFormRows(modifiedRows)
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])
  // Render the UI for your table

  const allSeq = data.map(item => item.sequence)
  const handleAddTab = () => {
    navigate(`${configure.appUrl}setting/generalsetting/addtab`, {
      state: {
        allseq: allSeq,
      },
    })
  }

  return (
    <div>
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          // to={`${configure.appUrl}setting/generalsetting/addtab`}
          onClick={handleAddTab}
          className="btn btn-save btn-label pe-0  fix-btn"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Add</span>
        </button>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <button
              // to={`${configure.appUrl}setting/generalsetting/addtab`}
              onClick={handleAddTab}
              className=" btn btn-save btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Tab
            </button>
            <a
              onClick={() => navigate(-1)}
              className=" btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </a>
          </div>
        </Col>
      </Row>
      <div className="row">
        <div
          className={`col-md-4 d-flex justify-content-center pt-4`}
          style={{ transition: "all 5s ease" }}
        >
          {/* <div className={`${fixedContent ? "oppImg" : ""}`}> */}
          <div className={`${fixedContent && "credIndexImg"}`}>
            <img src={tabImg} alt="oppImg" className="w-100" />
          </div>
        </div>
        <div className="col-md-8 Credentials-table">
          <Table className="table table-bordered mt-4" {...getTableProps()}>
            <thead className={`${fixedContent ? "fixed-setting-header" : ""}`}>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={index}
                      //   className={`col-md-${
                      //     index === 0 ? "1" : index === 1 ? "9" : "2"
                      //   }`}
                      className={`t-col-${index + 1} t-head-${index + 1} ${
                        column.className
                      }`}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                      {column.isInfoIcon ? (
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr className="row-hover" {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          //   className={`col-md-${
                          //     index === 0 ? "1" : index === 1 ? "9" : "2"
                          //   }`}
                          className={`t-col-${index + 1} t-head-${index + 1} ${
                            cell.className
                          }`}
                          key={index}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      {/* Add Tab Modal */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Tab</h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <Row>
              <div className="col-3">
                <label htmlFor="">
                  Sequence <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="text"
                  placeholder="Add Sequence"
                  className="form-control "
                />
              </div>
              <div className="col-9">
                <label htmlFor="">
                  Tab Name <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                />
              </div>
            </Row>
            <div className="my-4 d-flex justify-content-between">
              <h5>Field Details</h5>
              <button
                onClick={e => {
                  e.preventDefault()
                  onAddFormRow()
                }}
                className="btn btn-purple "
              >
                <i className="fas fa-plus me-2 icon-size"></i>Add Field
              </button>
            </div>

            <div
              className={`${
                formRows.length > 0 ? "dynamic-fields" : ""
              } py-1 border-bottom`}
            >
              {(formRows || []).map((formRow, key) => (
                <Row key={key} className="align-items-center mt-3">
                  <div className="col-1">
                    <input type="checkbox" />
                  </div>
                  <div className="col-9">
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter Field Name"
                    />
                  </div>
                  <div className="col-2 text-center ">
                    <button
                      onClick={e => {
                        e.preventDefault()
                        onDeleteFormRow(formRow.id)
                      }}
                      className="btn btn-danger "
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </Row>
              ))}
            </div>
            <div className="text-end my-3 ">
              <button className="btn btn-purple  ">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}
    </div>
  )
}

const Credentials = () => {
  const [loading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)
  const [shareCredentials, setShareCredentials] = useState(false)
  const [shareCredentialsId, setShareCredentialsID] = useState("")
  const [tabData, setTabData] = useState([])
  const [staffOption, setStaffOption] = useState([])
  const [filterShareUser, setFilterShareUser] = useState(null)
  const [staffSelectedOption, setStaffSelectedOption] = useState([])
  const [assignData, setAssignData] = useState([])

  const [data, setData] = useState([{ id: 1, FieldName: "", status: true }])

  // Tab Value => SaveTabe
  const [sequenceValue, setSequenceValue] = useState()
  const [tabNameValue, setTabNameValue] = useState()
  const [fieldNameValue, setFieldNameValue] = useState([])
  const [allStaff, setAllStaff] = useState([])

  // ...................................................................
  const [roles, setRoles] = useState([])
  const [selectedRoleEdit, setSelectedRoleEdit] = useState([])
  const [allUser, setAllUser] = useState([])
  // ...................................................................

  const navigate = useNavigate()
  const [addCredentials, setAddCredentials] = useState(false)

  useEffect(() => {
    fatchRoles()
  }, [])

  const fatchRoles = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staffData`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const optionRole = response.data.data.roles.map(item => ({
          value: item.id,
          label: item.role_name,
        }))
        setRoles(optionRole)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleSetEditRoles = option => {
    // Set the new selected roles
    setSelectedRoleEdit(option)

    // Convert the options into an array of selected role labels
    const selectedRoleLabels = option.map(opt => opt.label)

    // Convert the previous selection into an array of previous role labels
    const previousRoleLabels = selectedRoleEdit.map(opt => opt.label)

    // Find roles that were added
    const addedRoles = selectedRoleLabels.filter(
      role => !previousRoleLabels.includes(role)
    )

    // Find roles that were removed
    const removedRoles = previousRoleLabels.filter(
      role => !selectedRoleLabels.includes(role)
    )

    // Find users associated with the added roles
    const usersForAddedRoles = allStaff
      .filter(employee => addedRoles.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))

    // Find users associated with the removed roles
    const usersForRemovedRoles = allStaff
      .filter(employee => removedRoles.includes(employee.role_name))
      .map(employee => ({
        value: employee.id,
        label: employee.name,
      }))

    // Update staffSelectedOption by removing users for removed roles
    // and adding users for newly selected roles
    setStaffSelectedOption(prevOption => {
      // Remove users for removed roles
      const updatedStaff = prevOption.filter(
        option =>
          !usersForRemovedRoles.some(user => user.value === option.value)
      )
      // Add users for added roles
      return [...updatedStaff, ...usersForAddedRoles]
    })
  }
  // Add Credentials
  const saveCredentials = async e => {
    e.preventDefault()
    if (
      sequenceValue.length != 0 &&
      tabNameValue.length != 0 &&
      fieldNameValue != 0
    ) {
      const formData = new FormData()
      formData.append("sequence", localStorage.getItem("sequence"))
      formData.append("sequence", sequenceValue.value)
      formData.append("tab_name", tabNameValue.value)
      formData.append("field_name", fieldNameValue.value)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/credentials/tab/add`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Tab Added Succesfully",
          }).then(data => {
            navigate(`${configure.appUrl}/credentials/tab/add`)
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ textAlign: "center" }}>Sequence</div>,
        accessor: "sequence",
        Cell: cellProps => (
          <div style={{ textAlign: "center" }}>
            {cellProps.row.original.sequence}
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "tab_name",
        isInfoIcon: true,
      },
      {
        Header: () => <div style={{ textAlign: "center" }}>Actions</div>,
        accessor: "action",
        Cell: cellProps => {
          const deleteTab = () => {
            Swal.fire({
              icon: "warning",
              text: "Are You Sure ?",
              showCancelButton: true,
              confirmButtonText: "Yes delete it!",
            }).then(async willOkay => {
              if (willOkay.isConfirmed) {
                setLoading(true)
                const formData = new FormData()
                formData.append("tab_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/credentials/tab/delete`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  if (response) {
                    setLoading(false)
                    Swal.fire({
                      icon: "success",
                      text: "Tab deleted successfully",
                    }).then(() => {
                      setTabData(response?.data?.data.tabs)
                    })
                  }
                } catch (error) {
                  setLoading(false)
                  console.log(error)
                }
              }
            })
          }
          const isShare = () => {
            const list = cellProps.row.original.assign?.split(",")
            const userAccessIds = cellProps.row.original.role_id

            const roleAccessIds = cellProps.row.original.assign
              ?.split(",")
              ?.map(id => parseInt(id.trim(), 10))

            const userData = allUser?.filter(item => {
              return userAccessIds?.includes(item.value)
            })
            const roleData = roles?.filter(item => {
              return roleAccessIds?.includes(item.value)
            })
            // setSelectedUserEdit(userData)
            setStaffSelectedOption(userData)
            setSelectedRoleEdit(roleData)
            // }

            setShareCredentialsID(cellProps.row.original.id)
            getTabAssignee(cellProps.row.original.id)
            const filterUser = staffOption?.filter(item => {
              if (list) {
                return !list.includes(item.value.toString())
              } else {
                return item
              }
            })
            setFilterShareUser(filterUser)
          }
          return (
            <div style={{ textAlign: "center" }}>
              <Link
                to={`${configure.appUrl}setting/generalsetting/${cellProps.row.original.id}`}
                className="btn btn-sm action-btn me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <button
                onClick={() => {
                  // tog_small()
                  isShare()
                }}
                className="btn btn-sm me-1 action-btn btn-outline-secondary"
              >
                <i className="fa fa-share-alt"></i>
              </button>
              <Link
                onClick={() => deleteTab()}
                className="btn btn-sm action-btn btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    [filterShareUser, staffOption, roles]
  )

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const addField = () => {
    const lastId = data.length > 0 ? data[data.length - 1].id : 0
    const newItem = { id: lastId + 1, FieldName: "", status: true }
    setData([...data, newItem])
  }
  const deleteField = idToDelete => {
    setData(data.filter(item => item.id !== idToDelete))
  }

  const fatchData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/credentials/tab`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setTabData(response?.data?.data.tabs)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const staffData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        const options = response.data.data?.map(items => {
          return {
            id: items.id,
            value: items.id,
            label: items.name,
          }
        })
        setAllStaff(response.data.data)
        setAllUser(options)
        setStaffOption(options)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fatchData()
    staffData()
  }, [])
  const handleShareCre = async e => {
    e.preventDefault()
    // tab_id,assign[]
    if (staffSelectedOption.length !== 0) {
      setLoading(true)
      const formData = new FormData()
      formData.append("tab_id", shareCredentialsId)
      formData.append(
        "assign",
        staffSelectedOption
          ?.map(items => {
            return items.value
          })
          .toString()
      )
      formData.append(
        "role_id",
        selectedRoleEdit
          ?.map(items => {
            return items.value
          })
          .toString()
      )
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/credentials/tab/share`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        if (response) {
          setLoading(false)
          await getTabAssignee(shareCredentialsId)
          Swal.fire({
            icon: "success",
            text: "tab shared successfully.",
          }).then(() => {
            setTabData(response?.data?.data.tabs)
            setShareCredentials(false)
            setStaffSelectedOption([])
            setSelectedRoleEdit([])
          })
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "Please select staff.",
      })
    }
  }
  const getTabAssignee = async tabId => {
    // getTabAssignee
    setLoading(true)
    setShareCredentials(true)
    try {
      const formData = new FormData()
      formData.append("tab_id", tabId)
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/credentials/tab/assignee`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        setAssignData(response.data.data)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const deleteAssign = async id => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure ?",
      text: "You want to remove assign.",
      showCancelButton: true,
      confirmButtonText: "yes remove",
    }).then(async willOkay => {
      if (willOkay.isConfirmed) {
        try {
          setLoading(true)
          const formData = new FormData()
          formData.append("tab_id", shareCredentialsId)
          formData.append("user_id", id)
          const response = await axios({
            method: "post",
            url: `${configure.apiUrl}/credentials/tab/reject`,
            data: formData,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          if (response) {
            setLoading(false)
            Swal.fire({
              icon: "success",
              text: "Tab assign deleted successfully",
              showCancelButton: true,
              confirmButtonText: "yes remove",
            }).then(() => {
              setAssignData(response?.data?.data)
              setShareCredentials(false)
            })
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      }
    })
  }
  return (
    <>
      <div>
        {loading && <Loader />}
        <Row className="ms-0 pe-0">
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={tabData}
                  setAddCredentials={setAddCredentials}
                  setAccessDenied={setAccessDenied}
                  modal_Access={modal_Access}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Share credential modal */}
      {/* <Modal
        size="sm"
        isOpen={modal_small}
        toggle={() => {
          tog_small()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Share Credentials
          </h5>
          <button
            onClick={() => {
              setmodal_small(false)
            }}
            type="button"
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <div className="col-10">
              <Select
                placeholder="Select Department"
                className="basic-multi-select "
                classNamePrefix="select"
                styles={colourStyles}
                options={staffOption}
                value={staffSelectedOption}
                onChange={selectedOption =>
                  setStaffSelectedOption(selectedOption)
                }
              />
            </div>
            <div className="col-2 ps-0">
              <button className="btn btn-purple">
                <i className="fa fa-share-alt"></i>
              </button>
            </div>
          </Row>
          <div className="mt-4 border-bottom mb-4">
            <Table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Staff Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Admin</td>
                  <td>
                    <button className="btn btn-sm btn-outline-secondary">
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Software Engineer</td>
                  <td>
                    <button className="btn btn-sm btn-outline-secondary">
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Modal> */}

      {/* / Share Modal  */}
      <Modal
        isOpen={shareCredentials}
        size="xl"
        className=""
        style={{ maxWidth: "80vw", width: "100%" }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Share Tab</h5>
          <button
            type="button"
            onClick={() => {
              setShareCredentials(false)
              setShareCredentialsID("")
              setStaffSelectedOption([])
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div
          className="modal-body"
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <form onSubmit={handleShareCre}>
            <div className="d-flex justify-space-between">
              <Col className="col-md-5 pe-4">
                <div className=" d-flex justify-content-center align-items-end pt-4">
                  <div className="">
                    <img src={tabImg} alt="oppImg" className="w-100" />
                  </div>
                </div>
              </Col>
              <Col className="col-md-6 pe-2">
                <div className="row d-flex align-items-end">
                  <div className=" col-md-12 mb-4">
                    <Label htmlFor="formrow-firstname-Input">Role Name </Label>
                    <ReactSelect
                      placeholder="Select Role"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti="true"
                      options={roles}
                      value={selectedRoleEdit}
                      onChange={option => handleSetEditRoles(option)}
                    />
                  </div>
                  <div className="col-md-12">
                    <Label htmlFor="formrow-firstname-Input">
                      Select Staff <span className="text-danger">*</span>
                    </Label>
                    <ReactSelect
                      placeholder="Select Staff"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      styles={colourStyles}
                      options={filterShareUser}
                      value={staffSelectedOption}
                      onChange={selectedOption =>
                        setStaffSelectedOption(selectedOption)
                      }
                      isMulti="true"
                    />
                  </div>
                </div>
                <div className="position-relative mt-4 mb-3 overflowy-scroll">
                  <table className="table table-bordered ">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="">Staff Name</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {assignData?.map((items, index) => {
                        return (
                          <tr key={items.id}>
                            <td
                              className="text-center"
                              style={{ width: "10%" }}
                            >
                              {index + 1}
                            </td>
                            <td className="" style={{ width: "50%" }}>
                              {items.name}
                            </td>
                            <td
                              className="text-center"
                              style={{ width: "10%" }}
                            >
                              <button
                                type="button"
                                onClick={() => deleteAssign(items.id)}
                                className="btn btn-sm  btn-outline-danger"
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </div>

            {/* Save Button  */}
            <Col className="col-md-12 text-end mt-3">
              <div>
                <button type="submit" className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
              </div>
            </Col>
          </form>
        </div>
      </Modal>

      {/* add credential  */}
      <Modal isOpen={addCredentials} size="lg" className="" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Credentials</h5>
          <button
            type="button"
            onClick={() => {
              setAddCredentials(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body" style={{ paddingBottom: "40px" }}>
          <Row className="pt-4 pe-4 mb-5">
            <Col md={3}>
              <div>
                <div>
                  <h4 className="text-dark mb-0">Tab Details</h4>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <div className="d-flex justify-content-end">
                <div className="text-md-end">
                  <button
                    className="btn text-white text-uppercase  btn-save btn-label"
                    onClick={e => saveCredentials(e)}
                  >
                    <i className="fas fa-save me-2 icon-size label-icon"></i>
                    Save
                  </button>
                  <button
                    onClick={() => setAddCredentials(false)}
                    // onClick={() => navigate(-1)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-space-between align-center my-4">
            <Row className="col-md-6">
              <div className="row d-flex align-items-start flex-column gap-4">
                <div className="mb-3 col-md-12">
                  <Label htmlFor="formrow-firstname-Input">
                    Sequence <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="formrow-firstname-Input"
                    placeholder="Enter Sequence Name"
                    onChange={e => {
                      setSequenceValue(e.target.value)
                    }}
                  />
                </div>
                <div className=" d-flex justify-space-between align-center col-md-12">
                  <div className="mb-3 col-md-10">
                    <Label htmlFor="formrow-firstname-Input">
                      Tab Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Tab Name"
                      onChange={e => {
                        setTabNameValue(e.target.value)
                      }}
                    />
                  </div>
                  <div className="mb-3 col-md-2 text-end">
                    <Label htmlFor="formrow-firstname-Input">
                      Project Access <span className="text-danger">*</span>
                    </Label>
                    <div className="form-switch form-switch-md pt-2">
                      <input
                        type="checkbox"
                        className="form-check-input first_global"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <div className="col-md-6">
              {/* Field Container  */}
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <h4 className="text-dark">Field Details</h4>
                </div>
                <div className="pe-4">
                  <button
                    type="button"
                    className="btn btn-success add_row btn-label"
                    onClick={() => addField()}
                  >
                    <i className="fa fa-plus label-icon" aria-hidden="true"></i>{" "}
                    Add Field
                  </button>
                </div>
              </div>

              {/* Table  */}
              <div className="mt-1">
                <table className="table table-bordered mt-4 shadow table">
                  <thead>
                    <tr>
                      <th className="text-center">id</th>
                      <th>Field Name</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((obj, index) => {
                      return (
                        <tr key={obj.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-firstname-Input"
                              placeholder="Enter Field Name"
                              onChange={e => {
                                setFieldNameValue(e.target.value)
                              }}
                            />
                          </td>
                          <td className="text-center">
                            <div className="form-switch form-switch-md">
                              <input
                                type="checkbox"
                                className="form-check-input first_global"
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <button
                              onClick={() => deleteField(obj.id)}
                              className="btn btn-sm  btn-outline-danger"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                    {data.length === 0 && (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No Field found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </>
  )
}

export default Credentials
