import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, Modal, Form } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import moment from "moment"

const ViewNotes = ({ introducer_note, introducer_assign }) => {
  const [viewFollowUp, setViewFollowUp] = useState(false)

  const [notes, setNotes] = useState([])
  const [assign, setAssigned] = useState("")

  const onGetAssign = note => {
    setViewFollowUp(true)
    setNotes(note?.introducer_followup[0])
    const idArrays = note?.introducer_followup[0].assign_user
      ?.split(",")
      .map(Number)
    const filteredArray = introducer_assign.filter(item =>
      idArrays.includes(item.value)
    )
    const resultString = filteredArray.map(item => item.label).join(", ")
    setAssigned(resultString)
  }

  return (
    <React.Fragment>
      <div className="mb-3">
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-5 text-dark">Notes</CardTitle>
                <div>
                  <ul className="verti-timeline list-unstyled notes-box">
                    {/* Activities */}
                    {introducer_note.map((note, key) => (
                      <li key={key} className="event-list position-relative">
                        <div className="event-timeline-dot">
                          <img
                            src={`https://mars.zithas.com/application/public/${note.addedby_avatar}`}
                            className="userImg me-3 "
                            alt=""
                          />
                        </div>
                        <div className="d-flex">
                          <div className="note flex-grow-1 ms-4 py-2 rounded ps-4">
                            <div className=" d-flex justify-content-between ">
                              <div>
                                <p className="text-muted mb-4">
                                  <h6 className="d-inline me-2">
                                    By {note.added_by}
                                  </h6>{" "}
                                  {moment(note.created_at).format(
                                    "DD-MM-YYYY hh:mm:ss"
                                  )}
                                  <span className="pl-15">
                                    Got in touch with this lead at{" "}
                                    {moment(note.date).format("DD-MM-YYYY")}
                                  </span>
                                </p>
                                <i className="bi bi-triangle-fill note-icon"></i>
                              </div>
                              <div className="me-4">
                                <div>
                                  {note.introducer_followup?.length != 0 ? (
                                    <span
                                      onClick={() => onGetAssign(note)}
                                      className="me-2 cursor-pointer badge-soft-success badge"
                                    >
                                      FollowUp
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div
                              className="Features"
                              dangerouslySetInnerHTML={{
                                __html: note.description,
                              }}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* View Follow Up Modal */}
      <Modal
        isOpen={viewFollowUp}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setViewFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row bd-span-input">
            <>
              <div className="mb-3 col-12">
                <label htmlFor="formrow-firstname-Input">Date</label>
                <div className="input-span">{notes.date_time}</div>
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Purpose</label>
                <div className="input-span">{notes.description}</div>
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Assigned</label>
                <div className="input-span">{assign}</div>
              </div>
            </>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default ViewNotes
