import React, { useEffect, useState } from "react"
import { Container, Table, Label } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import ReactSelect from "react-select"

const ViewBasicProjectinfo = ({ viewId }) => {
  const [isLoading, setLoading] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [custType, setCustType] = useState("")
  const [gitProject, setGitProject] = useState("New")
  const [ztplDetails, setZtplDetails] = useState("New")
  const [localServer, setLocalServer] = useState("New")
  const [fixButtons, setFixButtons] = useState(false)
  const [testingRows, setTestingRows] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])

  const navigate = useNavigate()

  const [allCustomerOption, setAllCustomerOption] = useState([])
  const [allIntroducerOption, setAllIntroducerOption] = useState([])
  const [allLeadsourceOption, setAllLeadsourceOption] = useState([])
  const [allKeyIndustryOption, setAllKeyIndustryOption] = useState([])

  const [detailsFromCred, setDetailsFromCred] = useState(null)
  const [detailsFromCred2, setDetailsFromCred2] = useState(null)

  const [allZtplCredOption, setAllZtplCredOption] = useState([])
  const [all192CredOption, setAll192CredOption] = useState([])
  const [source, setSource] = useState([])

  const fetchData = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectRelatedData`)
        .then(res => res.json())
        .then(data => {
          const modifyCus = data.data.customer?.map(list => {
            return {
              label: list.company,
              value: list.id,
            }
          })
          const modifyLocation = data.data.location?.map(obj => {
            return {
              label: obj.location,
              value: obj.id,
            }
          })
          const modifyKeyManager = data.data.keymanager?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyScrum = data.data.scrum?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyIntroducer = data.data.introducer?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyLeadsource = data.data.leadsource?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyIndustry = data.data.industry?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          const modifyZtpl = data.data.ztpl?.map(item => {
            return {
              label: item.Project,
              value: item.id,
              FTPHost: item.FTPHost,
              FTPUser: item.FTPUser,
              FTPPassword: item.FTPPassword,
              DBName: item.DBName,
              DBUsername: item.DBUsername,
              DBPassword: item.DBPassword,
            }
          })
          const modify192 = data.data.data_192?.map(item => {
            return {
              label: item.Project,
              value: item.id,
              FTPHost: item.FTPHost,
              FTPUser: item.FTPUser,
              FTPPassword: item.FTPPassword,
              DBName: item.DBName,
              DBUsername: item.DBUsername,
              DBPassword: item.DBPassword,
            }
          })

          const modifySourceOpt = data.data.source?.map(item => {
            return {
              label: item.leadname,
              value: item.id,
            }
          })

          const modifyArr = data.data.developers?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          setProjectMembersOptions(modifyArr)

          setSource(modifySourceOpt)

          setProjectKeyManagerOptions(modifyKeyManager)
          setProjectScrumOptions(modifyScrum)
          setLocationOption(modifyLocation)

          setCustomerOptions(modifyCus)
          setAllCustomerOption(modifyCus)

          setAllIntroducerOption(modifyIntroducer)
          setAllLeadsourceOption(modifyLeadsource)
          setAllKeyIndustryOption(modifyIndustry)

          setAllZtplCredOption(modifyZtpl)
          setAll192CredOption(modify192)
        })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const fetchAllFunc = async () => {
    try {
      await fetchData()
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        await fetchAllFunc().then(async () => {
          await fetchData5()
        })
      } catch (error) {
        console.error("Error in useEffect:", error)
      }
    }

    loadData()
  }, [])

  const fetchData5 = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectView/${viewId}`)
        .then(res => res.json())
        .then(async response => {
          const modifyArr = response?.data?.project2?.map(item => {
            return {
              label: item.project_name,
              value: item.id,
              gitreposatirylink: item.gitreposatirylink,
              demolink: item.demolink,
              icode: item.icode,
            }
          })
          setProjectFromGitOption(modifyArr)
          const filterEditData = response?.data?.project2?.find(item => {
            return item.id == viewId
          })

          const findProjectGit = modifyArr?.find(
            list => list.label == filterEditData?.project_name?.trim()
          )

          const findproType = projectTypes?.find(
            list => list.value == filterEditData?.project_type
          )
          const modifyleadsource = response?.data?.leadsource?.map(lisy => {
            return {
              label: lisy.leadname,
              value: lisy.id,
            }
          })
          const modifyIndustry = response?.data?.industry?.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          const selectedOption = modifyleadsource?.find(
            list => list.value == filterEditData?.source
          )

          const findResourceType = ResourceType?.find(
            list => list.value == filterEditData?.billingtype
          )
          const findIndustry = modifyIndustry?.find(
            list => list.value == filterEditData?.industry
          )
          const findprojectbelogsto = filterEditData?.projectbelogsto
          const source_name = filterEditData?.source_name
          const customerId = filterEditData?.customer
          fetchLocation(findprojectbelogsto)

          setProjectName(filterEditData?.project_name)
          setProjectType(findproType)
          setProjectSource(selectedOption)
          setProjectResourceType(findResourceType)
          setProjectIndustry(findIndustry)
          setProjectDescription(filterEditData?.description)
          setProjectEstSDate(filterEditData?.startdate)
          setProjectEstDays(filterEditData?.estimatedhours)
          setProjectEstEDate(filterEditData?.enddate)
          setProjectEstDBDate(filterEditData?.deliverydatebefore)
          // setProjectFromGitName()
          setGitRepositoryLink(filterEditData?.gitreposatirylink)
          setGitDemoLink(filterEditData?.demolink)
          setGitIcodeLink(filterEditData?.icode)
          setServerFolderName(filterEditData?.folder_name)
          setServer192Link(filterEditData?.link_192)
          setServerZTPLLink(filterEditData?.ztpllink)
          setZhost(filterEditData?.zhostlink)
          setZusername(filterEditData?.zusername)
          setZpassword(filterEditData?.zpassword)
          setZdatabasename(filterEditData?.zdatabasename)
          setZdbusername(filterEditData?.zdbusername)
          setZdbpassword(filterEditData?.zdbpassword)
          setHost(filterEditData?.hostlink)
          setUsername(filterEditData?.username)
          setPassword(filterEditData?.password)
          setDatabasename(filterEditData?.databasename)
          setDbusername(filterEditData?.dbusername)
          setDbpassword(filterEditData?.dbpassword)
          setProjectFromGit(findProjectGit)

          // ................................................................
          const modifyLocation = response?.data?.location?.map(item => {
            return {
              label: item.location,
              value: item.id,
            }
          })
          const findLocation = modifyLocation?.find(
            list => list.value == findprojectbelogsto
          )
          setProjectBelong(findLocation)

          const modifyintroducer = response.data.introducer?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })
          const modifyCustomer = response.data.customer?.map(item => {
            return {
              label: item.company,
              value: item.id,
            }
          })

          if (selectedOption) {
            handleSource(
              selectedOption,
              source_name,
              modifyintroducer,
              modifyCustomer
            )
          }

          const findCustomer = modifyCustomer?.find(
            list => list.value == customerId
          )
          setProjectCustomer(findCustomer)
          const modifyMember = response.data?.developers?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyKeyOp = response.data?.keymanager?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyScrumOp = response.data?.scrum?.map(list => {
            return {
              label: list.name,
              value: list.id,
            }
          })
          const modifyKey = modifyKeyOp?.find(list => {
            return list.value == filterEditData?.keyaccountmanager
          })
          const modifyScrum = modifyScrumOp?.find(list => {
            return list.value == filterEditData?.scrum_master
          })

          const memberList = filterEditData?.member
          const modifyMemberList = memberList?.split(",")
          const findMembers = modifyMember?.filter(list =>
            modifyMemberList?.includes(list.value.toString())
          )

          const memberLookup = modifyMember?.reduce((acc, { value, label }) => {
            acc[value] = label
            return acc
          }, {})

          const modifyTestingRow = response.data?.estimate.map(list => {
            return {
              id: list.developer_id,
              estimatedtime: list.days,
              milestone: memberLookup[list.developer_id],
            }
          })
          setProjectMembers(findMembers)
          setProjectKeyManager(modifyKey)
          setProjectScrumMaster(modifyScrum)
          setTestingRows(modifyTestingRow)
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const fetchLocation = async findprojectbelogsto => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        const modifyArr = res.data.data.map(obj => ({
          label: obj.location,
          value: obj.id,
        }))
        // setLocationOption(modifyArr)
        const findLocation = modifyArr?.find(
          list => list.value == findprojectbelogsto
        )
        setProjectBelong(findLocation)
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const projectTypes = [
    { label: "Regular Project", value: "1" },
    { label: "Dedicated Resource", value: "2" },
    { label: "Maintenance Project", value: "3" },
  ]

  const ResourceType = [
    { label: "Fixed Rate", value: "1" },
    { label: "Project Hours", value: "2" },
    { label: "Dedicated Resource", value: "3" },
  ]

  // Api Integration
  const [projectBelong, setProjectBelong] = useState(null)
  const [projectName, setProjectName] = useState("")
  const [projectType, setProjectType] = useState(null)
  const [projectSource, setProjectSource] = useState(null)
  const [projectReferenceOption, setProjectReferenceOption] = useState([])
  const [projectReferenceOptions, setProjectReferenceOptions] = useState([])
  const [projectReferenceOptionsLabel, setProjectReferenceOptionsLabel] =
    useState("")
  const [projectCustomer, setProjectCustomer] = useState(null)
  const [projectResourceType, setProjectResourceType] = useState(null)
  const [projectIndustry, setProjectIndustry] = useState(null)
  const [projectDescription, setProjectDescription] = useState(null)
  const [projectKeyManager, setProjectKeyManager] = useState(null)
  const [projectKeyManagerOptions, setProjectKeyManagerOptions] = useState([])
  const [projectScrumOptions, setProjectScrumOptions] = useState([])
  const [projectScrumMaster, setProjectScrumMaster] = useState(null)
  const [projectMembersOptions, setProjectMembersOptions] = useState([])
  const [projectMembers, setProjectMembers] = useState([])
  const [projectEstSDate, setProjectEstSDate] = useState("")
  const [projectEstEDate, setProjectEstEDate] = useState(null)
  const [projectEstDBDate, setProjectEstDBDate] = useState(null)
  const [projectEstDays, setProjectEstDays] = useState(null)

  // Git Details states

  const [projectFromGitOption, setProjectFromGitOption] = useState([])
  const [projectFromGit, setProjectFromGit] = useState(null)
  const [projectFromGitName, setProjectFromGitName] = useState("")
  const [gitRepositoryLink, setGitRepositoryLink] = useState("")
  const [gitDemoLink, setGitDemoLink] = useState("")
  const [gitIcodeLink, setGitIcodeLink] = useState("")

  // erver Details states
  const [serverFolderName, setServerFolderName] = useState("")
  const [server192Link, setServer192Link] = useState("")
  const [serverZTPLLink, setServerZTPLLink] = useState("")
  const [zhost, setZhost] = useState("ftp.ztpl.net")
  const [zusername, setZusername] = useState("")
  const [zpassword, setZpassword] = useState("")
  const [zdatabasename, setZdatabasename] = useState("")
  const [zdbusername, setZdbusername] = useState("")
  const [zdbpassword, setZdbpassword] = useState("")

  // 192 Details
  const [host, setHost] = useState("192.168.0.2")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [databasename, setDatabasename] = useState("")
  const [dbusername, setDbusername] = useState("")
  const [dbpassword, setDbpassword] = useState("")

  const handleSource = async (
    selectedOption,
    source_name,
    modifyintroducer,
    modifyCustomer
  ) => {
    if (source_name == "" || undefined) {
      setProjectReferenceOption(null)
    }
    setProjectSource(selectedOption)

    if (selectedOption.value == "14") {
      setProjectReferenceOptions([...modifyintroducer])
      setProjectReferenceOptionsLabel("Introducer")
      if (source_name) {
        const findReferenceOption = modifyintroducer?.find(
          list => list.value == source_name
        )
        setProjectReferenceOption(findReferenceOption)
      }
    } else if (selectedOption.value == "15") {
      setProjectReferenceOptions([...modifyCustomer])
      setProjectReferenceOptionsLabel("Customer")
      if (source_name) {
        const findReferenceOption = modifyCustomer?.find(
          list => list.value == source_name
        )
        setProjectReferenceOption(findReferenceOption)
      }
    }
  }

  useEffect(() => {
    if (projectEstSDate && projectEstDays) {
      const startDate = new Date(projectEstSDate)
      const numDays = parseInt(projectEstDays, 10)
      const endDate = addBusinessDays(startDate, numDays)
      setProjectEstEDate(endDate.toISOString().split("T")[0])
    } else {
      setProjectEstEDate("")
    }
  }, [projectEstSDate, projectEstDays])

  function addBusinessDays(startDate, numDays) {
    const resultDate = new Date(startDate)
    let daysAdded = 0

    while (daysAdded < numDays) {
      resultDate.setDate(resultDate.getDate() + 1)
      const dayOfWeek = resultDate.getDay()

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        daysAdded++
      }
    }
    return resultDate
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="px-0">
        <Container fluid>
          {/* <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Project"} /> */}
          <Row className="" >
            <Form className="px-0" id="ViewProjectDetails">
              <Row className="">
                {/* Project Basic Details  */}
                <Row className="bg-white py-4 shadow mb-3 px-2">
                  <div className="col-sm-12 d-flex justify-content-between">
                    <div className="col-sm-6">
                      <h5 className="mb-3 text-dark">Project Basic Details</h5>
                    </div>
                  </div>
                  <div className="col-sm-3 mt-2 mb-3">
                    <label htmlFor="">
                      Project Belongs To <span className="text-danger">*</span>
                    </label>
                    <Select
                      isDisabled={true}
                      placeholder="Project Belongs To"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={location_option}
                      value={projectBelong}
                    />
                  </div>

                  <div className="col-sm-5 mt-2 mb-3">
                    <label htmlFor="">
                      Project Name <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled
                      required
                      type="text"
                      placeholder="Enter Project Name"
                      className="form-control "
                      value={projectName}
                    />
                  </div>

                  <div className="col-sm-4 mt-2 mb-3">
                    <label htmlFor="">
                      Project Type <span className="text-danger">*</span>
                    </label>
                    <Select
                      isDisabled={true}
                      placeholder="Select Project Type"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={projectTypes}
                      value={projectType}
                    />
                  </div>

                  <div className="col-sm-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                    <div className="col-sm-3 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        {" "}
                        Source <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled={true}
                        placeholder="Select Source"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={source}
                        value={projectSource}
                      />
                    </div>
                    <div className="col-sm-3 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        Resource Type <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled={true}
                        placeholder="Select Resource Type"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={ResourceType}
                        value={projectResourceType}
                      />
                    </div>
                    <div className="col-sm-3 mb-3 mt-2 mb-md-0">
                      <label htmlFor="">
                        Select {projectReferenceOptionsLabel}
                      </label>
                      <Select
                        isDisabled={true}
                        placeholder={`Select ${projectReferenceOptionsLabel}`}
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={projectReferenceOptions}
                        value={projectReferenceOption}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mb-3 d-flex justify-content-start align-items-center gap-4">
                    <div className="col-sm-3 mb-3 mt-2 mb-md-0">
                      <label htmlFor="">
                        Customer <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled={true}
                        placeholder="Select Customer"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={customerOptions}
                        value={projectCustomer}
                      />
                    </div>

                    <div className="col-sm-3 mt-2 mb-3 mb-md-0">
                      <label htmlFor="">
                        {" "}
                        Project Industry <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled={true}
                        placeholder="Select Industry"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={allKeyIndustryOption}
                        value={projectIndustry}
                      />
                    </div>
                  </div>

                  <div className="col-12 disabled-editor">
                    <label htmlFor="">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={projectDescription}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setProjectDescription(data)
                      }}
                    />
                  </div>
                </Row>

                {/* Project Basic Details  */}
                <Row className="px-0">
                  <div className="col-sm-6 pe-md-4">
                    <Row className="bg-white px-2 py-4 shadow mb-3">
                      <h5 className="mb-3">Members</h5>
                      <div className="col-sm-6 mt-2 mb-3">
                        <label htmlFor="">Key Account Manager</label>
                        <Select
                          isDisabled={true}
                          placeholder="Select Key Account Manager"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          // options={KeyAccountManager}
                          options={projectKeyManagerOptions}
                          value={projectKeyManager}
                        />
                      </div>
                      <div className="col-sm-6 mt-2 mb-3">
                        <label htmlFor="">Scrum Master</label>
                        <Select
                          isDisabled={true}
                          placeholder="Select Scrum Master"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={projectScrumOptions}
                          value={projectScrumMaster}
                          
                        />
                      </div>
                      <div className="col-sm-12 mt-2 mb-3">
                        <label htmlFor="">Members</label>
                        <ReactSelect
                          isDisabled={true}
                          placeholder="Select Members"
                          className="basic-multi-select "
                          classNamePrefix="select"
                          options={projectMembersOptions}
                          isMulti="true"
                          value={projectMembers}
                        />
                      </div>

                      <h5 className="mb-3 mt-4">Estimatation</h5>
                      <div className="col-sm-6 mt-2 mb-3">
                        <label htmlFor="">
                          Start Date <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          type="date"
                          className="form-control "
                          value={projectEstSDate}
                        />
                      </div>
                      <div className="col-sm-6 mt-2 mb-3">
                        <label htmlFor="">
                          Number OF Days<span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          type="text"
                          placeholder="Enter Number OF Days"
                          className="form-control "
                          value={projectEstDays}
                        />
                      </div>
                      <div className="col-sm-6 mt-2 mb-3 pb-5">
                        <label htmlFor="">Estimated End Date</label>
                        <input
                          disabled
                          type="date"
                          className="form-control "
                          value={projectEstEDate}
                        />
                      </div>
                      <div className="col-sm-6 mt-2 mb-3 pb-5">
                        <label htmlFor="">
                          Delivery Before Date
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          disabled
                          type="date"
                          className="form-control "
                          value={projectEstDBDate}
                        />
                      </div>
                    </Row>
                  </div>

                  <div className="col-sm-6 ps-md-3">
                    <Row className="bg-white px-2 py-4 shadow mb-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-3">Develolpers Details</h5>
                      </div>
                      <div className="responsive-table">
                        <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                          <thead className="">
                            <tr>
                              {/* <th>Sr No.</th> */}
                              <th>Devloper Name</th>
                              <th>
                                No. of Days{" "}
                                <span className="text-danger">*</span>
                              </th>
                              {/* <th className="text-center">Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {(testingRows || []).map((testingRow, index) => (
                              <tr key={testingRow.id}>
                                <td className="col-2 d-none">
                                  <input
                                    disabled
                                    type="number"
                                    placeholder=""
                                    className="form-control "
                                    name="sr.no"
                                    value={testingRow.id}
                                  />
                                </td>
                                <td className="col-6">
                                  <input
                                    disabled
                                    type="text"
                                    placeholder="Enter Devloper Name"
                                    className="form-control "
                                    name="milestone"
                                    value={testingRow.milestone}
                                  />
                                </td>
                                <td className="col-2">
                                  <input
                                    disabled
                                    required
                                    type="number"
                                    placeholder="Days.."
                                    className="form-control "
                                    name="estimatedtime"
                                    value={testingRow.estimatedtime}
                                  />
                                </td>
                                {/* <td className="col-2 text-center">
                                  <a className="btn btn-danger">
                                    <i className="fas fa-trash-alt"></i>
                                  </a>
                                </td> */}
                              </tr>
                            ))}
                            {testingRows.length == 0 && (
                              <tr>
                                <td className="text-center" colSpan={10}>
                                  No Developer Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </div>
                </Row>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewBasicProjectinfo
