import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody, Form, Modal } from "reactstrap"
import oppImg from "../../../../assets/images/opportunity1.jpg"

// common component
import Pagination from "components/Common/Pagination"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import configure from "configure"
import axios from "axios"
import swal from "sweetalert"
import Loader from "pages/Separate/Loader"

import SampleFile from "../../../../assets/Sample-file.xlsx"
import Swal from "sweetalert2"
import ReactSelect from "react-select"

function TableContainer({ columns, data, setAllImportData }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [addImportModal, setAddImportModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [industriesOptions, setIndustriesOptions] = useState([])
  const [debounceBalance, setDebounceBalance] = useState([])
  const [industry, setIndustry] = useState({})
  const [importFile, setImportFile] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  useEffect(() => {
    getIndustries()
  }, [])

  const getIndustries = async () => {
    try {
      const res = await axios.get(`${configure.apiUrl}/show_industries`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      const options = res.data.data.map(item => ({
        label: item.name,
        value: item.id,
      }))
      setIndustriesOptions(options)
    } catch (error) {
      console.log(error)
    }
  }
  const getDbounceBalance = async () => {
    try {
      const response = await axios.get(`${configure.apiUrl}/getBalance`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      if (response) {
        setDebounceBalance(response?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getDbounceBalance()
  }, [])

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const { pageIndex, pageSize, globalFilter, importFilter } = state
  useEffect(() => setPageSize(50), [])

  const saveImportSheet = async e => {
    e.preventDefault()
    if (industry.value == null || industry.value == undefined) {
      setError(true)
    } else {
      try {
        setLoading(true)
        const formData = new FormData()

        formData.append("industry", industry.value)
        formData.append("file", importFile)
        formData.append("file_name", importFile?.name)
        formData.append("user_id", localStorage.getItem("auth_id"))

        const response = await axios.post(
          `${configure.apiUrl}/import_excel`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        // data is importing in background
        if (response.data.success == true) {
          // setAllImportData(response.data.data)
          // setAllImportData()
          setLoading(false)
          Swal.fire({
            icon: "success",
            title: "Please Wait,",
            text: "Data is importing in background.",
          }).then(() => {
            setAddImportModal(false)
          })
        }
        if (response.data.success == false) {
          Swal.fire({
            icon: "error",
            text: "Insufficient Debounce balance for upload sheet",
          })
        }

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
        swal("Something Went Wrong", "", "error")
      }
    }
  }

  const refreshToken = async () => {
    const res = await axios.get(`${configure.apiUrl}/refreshToken`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
  }
  useEffect(() => {
    refreshToken()
  }, [])

  // Render the UI for your table
  return (
    <div>
      {loading && <Loader />}
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end me-2">
            {/* <button
              onClick={() => setAddImportModal(true)}
              className=" btn btn-purple"
            >
              <i className="fas fa-plus fs-6 me-2"></i>
              IMPORT
            </button> */}
            <button onClick={toggleDropdown} className="btn btn-save btn-label">
              <i className="fas fa-plus fs-6 me-2 label-icon"></i>
              IMPORT
            </button>
            {isOpen && (
              <div>
                <ul
                  className="dropdown-menu mt-1 d-flex flex-column"
                  style={{ right: "8%" }}
                >
                  <div className="">
                    <li
                      className="mb-1 dropdown-item cursor-pointer"
                      onClick={() => setAddImportModal(true)}
                    >
                      Import Sheet{" "}
                      <span className="ps-1">
                        <i className="bi bi-file-earmark-spreadsheet"></i>
                      </span>
                    </li>
                    <li className="mb-1 dropdown-item cursor-pointer">
                      <Link
                        to={`${configure.appUrl}marketing/opportunity/logs`}
                        className="nav-link"
                      >
                        Logs
                        <span className="ps-2">
                          <i className="bi bi-box-arrow-in-right"></i>
                        </span>
                      </Link>
                    </li>
                  </div>
                  {/* Add more items as needed */}
                </ul>
              </div>
            )}
          </div>
          <div className="text-md-end">
            <button
              onClick={() => navigate(-1)}
              className=" btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>

      <div className="row">
        <div className="col-md-4 d-flex justify-content-center pt-4">
          <div className={`${fixedContent ? "oppImg" : ""}`}>
            <img src={oppImg} alt="oppImg" className="w-100" />
          </div>
        </div>
        <div className="col-md-8">
          <div className="position-relative opportunity-table">
            <Table className="table table-bordered mt-4" {...getTableProps()}>
              <thead
                className={`${
                  fixedContent ? "fix-header-opportunity" : ""
                } fix-databank-header`}
              >
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        className={`t-col-${index + 1} t-head-${index + 1} ${
                          column.className
                        }`}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                        {column.isInfoIcon ? (
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="fix-databank-header" {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No data in table
                    </td>
                  </tr>
                ) : (
                  page
                    .map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr
                          className="row-hover"
                          {...row.getRowProps()}
                          key={i}
                        >
                          {row.cells.map((cell, index) => {
                            return (
                              <td
                                key={index}
                                className={`t-col-${index + 1} ${
                                  cell.column.className
                                }`}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })
                    .reverse()
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Add Payment Modal */}
      <Modal isOpen={addImportModal} size="lg" centered>
        <div className="modal-header">
          <div className="w-65">
            <h5 className="modal-title mt-0">Import Data Bank</h5>
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                setAddImportModal(false)
              }}
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
        </div>
        <form onSubmit={saveImportSheet}>
          <div className="modal-body">
            <div>
              {/* fixed buttons */}
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixedContent ? "d-flex" : "d-none"
                }`}
              >
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </button>
              </div>
              <Row className="mb-4">
                <Col>
                  <div className="">
                    <label htmlFor=""> Select Industry</label>{" "}
                    <span className="text-danger">*</span>
                    <Select
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={industriesOptions}
                      onChange={e => setIndustry(e)}
                      required
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {industry.value == null && error
                        ? "Please select Industry"
                        : null}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className="">
                    <label htmlFor=""> Upload File</label>{" "}
                    <span className="text-danger">*</span>
                    <input
                      type="file"
                      required
                      onChange={e => setImportFile(e.target.files[0])}
                      className="form-control "
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center px-3 py-2">
            <div
              className={`d-flex align-items-center justify-content-center ${
                debounceBalance < 4000 ? "bg-danger" : "bg-success"
              } p-2 ps-0  text-white px-3`}
            >
              <h5 className="my-1 btn-label">
                <i className="bi bi-wallet2 label-icon py-0 fs-4"></i> Debounce
                Balance :
              </h5>
              <div className="fs-5 ps-2">{debounceBalance}</div>
            </div>
            <div className="d-flex">
              <div className="ms-4">
                <a
                  href={SampleFile}
                  download
                  className="btn btn-secondary  ms-3 btn-label"
                >
                  <i className="fas fa-download fs-6 me-1 label-icon"></i>{" "}
                  Sample File
                </a>
              </div>

              <div className="ms-2">
                <button type="submit" className="btn btn-purple btn-label">
                  <i className="bi bi-file-earmark-spreadsheet label-icon"></i>
                  Import Sheet
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      {/* ------------ */}
    </div>
  )
}

const DataBankIndex = () => {
  const [allImportData, setAllImportData] = useState([])
  const [loading, setLoading] = useState(false)

  //meta title
  document.title = "Opportunity | Zithas Crm"

  useEffect(() => {
    fetchAllImportData()
  }, [])

  const fetchAllImportData = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`${configure.apiUrl}/show_opportunity`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setLoading(false)
      setAllImportData(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
        Cell: cellProps => <span>{cellProps.row.index + 1}</span>,
      },

      // {
      //   Header: "Phone Number",
      //   accessor: "phone",
      //   isInfoIcon: true,
      // },
      {
        Header: "Industry Name",
        accessor: "name",
        isInfoIcon: true,
      },
      {
        Header: "Emails",
        accessor: "ecount",
        isInfoIcon: true,
        className: "text-center",
      },
      // {
      //   Header: "Valid Emails",
      //   accessor: "is_valid",
      //   isInfoIcon: true,
      //   className: "text-center px-0",
      // },
      // {
      //   Header: "Invalid Email",
      //   accessor: "not_valid",
      //   isInfoIcon: true,
      //   className: "text-center px-0",
      // },
      {
        Header: "Count",
        accessor: "dcount",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center",
        Cell: cellProps => {
          return (
            <div>
              <Link
                to={`${configure.appUrl}marketing/Getindustrydetails/${cellProps.row.original.id}`}
                title="View"
                className="btn btn-sm btn-outline-secondary"
              >
                <i className="fas fa-eye"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allImportData, [allImportData])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Opportunity"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAllImportData={setAllImportData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DataBankIndex
