import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import { useNavigate, useParams } from "react-router-dom"
import JsPDF, { jsPDF } from "jspdf"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row, Modal, Form, Canvas } from "reactstrap"

// canvas
import SignatureCanvas from "react-signature-canvas"

// images
import zithasLogo from "../../assets/images/zithas-dark-logo.png"
import sign from "../../assets/images/zithas-signature.png"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import html2canvas from "html2canvas"
import configure from "configure"
import swal from "sweetalert"
import html2pdf from "html2pdf.js"
import Swal from "sweetalert2"

const ViewDocument = () => {
  const [fixedRightSec, setFixedRightSec] = useState(false)
  const [modal_center, setmodal_center] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 48) {
      setFixedRightSec(true)
    } else {
      setFixedRightSec(false)
    }
  })

  const printContract = () => {
    let printContents = document.getElementById("zithasInvoicePrint").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }

  const [isLoading, setLoading] = useState(false)
  const [contract_type, setContractType] = useState([])
  const [contract_value, setContractValue] = useState("")
  const [description, setDescription] = useState("")
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [note, setNote] = useState("")
  const [date, setDate] = useState("")
  const [sign_date, setSignDate] = useState("")
  const [downLink, setDownLink] = useState("")
  const [signature, setSignature] = useState("")
  const [contact, setContact] = useState("")
  const [approve, setApprove] = useState("")
  const [docData, setDocData] = useState([])

  const params = useParams()
  console.log(params.id)

  const navigate = useNavigate()

  const pdfRef = useRef()

  const generatePDF = async () => {
    if (signature == null || approve == 0) {
      Swal.fire({
        icon: "warning",
        text: "Cannot Download Unsigned OR Unapproved Document!",
      })
    } else {
      setLoading(true)

      // Select the content to be converted to PDF
      const content = pdfRef.current

      const opt = {
        margin: 0.38,
        filename: "Contract-Document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      }

      setTimeout(() => setLoading(false), 2000)
      html2pdf().from(content).set(opt).save()
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        data: formData,
        method: "post",
        url: `${configure.apiUrl}/showStaffDocument`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        const modifyArray = response?.data?.data?.filter(item => {
          const type = [
            "salaryincrementletter",
            "workexperienceletter",
            "appointmentletter",
          ]
          const itemtypes = item.requirement_type
            ?.replace(/\s+/g, "")
            .toLowerCase()
          return type.includes(itemtypes) && item.id == params.id
        })
        console.log(modifyArray)
        setDocData(modifyArray)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const signatureRef = useRef(null)
  const handleClear = () => {
    signatureRef.current.clear()
  }
  const handleSave = async e => {
    e.preventDefault()
    const signatureImage = signatureRef.current.toDataURL()
    setLoading(true)
    const formData = new FormData()
    formData.append("docId", "docID")
    formData.append("name", "name")
    formData.append("email", "email")
    formData.append("signature", signatureImage)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/store_signature`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log("response",response)
        if (response.data.success == true) {
          Swal.fire({
            icon: "success",
            text: "Thanks For signining Contract",
          }).then(data => {
            setmodal_center(false)
            // setSignature(response.data.data[0].signature)
            // setSignName(response.data.data[0].name)
            window.location.reload()
          })
        } else {
          if (response.data.message == "error") {
            Swal.fire({
              icon: "warning",
              text: "Please Enter Same Name and Email Address",
            }).then(data => {})
          } else if (response.data.message == "authorized") {
            Swal.fire({
              icon: "warning",
              text: "You are not authorized to sign contracts.",
            }).then(data => {})
          }

          // if (response.data.message == "matched") {
          //   swal({
          //     icon: "warning",
          //     text: "Please Enter Same Name and Email Address",
          //   }).then(data => { })
          // } else if (response.data.message == "authorized") {
          //   swal({
          //     icon: "warning",
          //     text: "You are not authorized to sign contracts.",
          //   }).then(data => { })
          // }
        }

        setLoading(false)
      })
    } catch (err) {
      console.log("error", err)
      setLoading(false)
    }
  }
  const fetchData = async () => {
    setLoading(true)
    try {
      const staffId = localStorage.getItem("auth_id")
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/viewGenerateLetters/${staffId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        console.log(response)
        const filterData = response.data.data.filter(item => {
          return item.id == params.id
        })
        const templateData = filterData[0]?.new_template
        setDownLink(filterData[0]?.signature)
        setDescription(templateData)
        setSignDate(filterData[0]?.sign_date)
        setDate(filterData[0]?.created_at)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"View Document"} />
          <Row>
            <div className="col-md-9 ps-0">
              <div
                id="zithasInvoicePrint"
                ref={pdfRef}
                className="bg-white p-3 shadow document"
              >
                <div className="pb-2 text-center">
                  <img src={zithasLogo} height="60" alt="zithasLogo" />
                </div>
                <div>
                  <div>
                    <div
                      className="Features document-content"
                      dangerouslySetInnerHTML={{
                        __html: description
                          ?.replaceAll("{", "")
                          .replaceAll("}", ""),
                      }}
                    />
                  </div>

                  <h5 className="fw-bold text-decoration-underline">
                    Date : {moment(date).format("DD-MM-YYYY")}
                  </h5>
                  <Row className="mt-4 justify-space-between">
                    <div className="col-4">
                      {signature == null ? (
                        <div className="sign-box">Signature</div>
                      ) : (
                        <img src={sign} height="50" className="mb-2" />
                      )}
                      <h5>Zithas Signature</h5>
                    </div>

                    <div className="col-4">
                      {signature != null ? (
                        <>
                          <img src={signature} height="50" className="mb-2" />
                          <h5>{contact != "undefined" && contact} Signature</h5>
                        </>
                      ) : null}
                    </div>
                  </Row>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-12 pe-md-0 order-md-2 order-1 mb-md-0 mb-3">
              <div
                className={`${
                  fixedRightSec ? "fixed-right-section top-0 " : ""
                }`}
              >
                {/* buttons */}
                <div className="col-12 text-md-end text-center">
                  <button
                    onClick={() => printContract()}
                    className="btn btn-sm btn-success border-radius me-1"
                  >
                    <i className="fas fa-print me-1"></i> Print
                  </button>
                  <a
                    href={downLink}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-secondary border-radius me-1"
                  >
                    <i className="fas fa-file-download me-1"></i> Downloads
                  </a>
                  <a
                    onClick={() => navigate(-1)}
                    className="btn btn-sm btn-red border-radius "
                  >
                    <i className="dripicons-cross me-1"></i> Cancel
                  </a>
                </div>

                {/* contract data */}
                <div className="bg-white p-3 mt-4 shadow">
                  <h5 className="mb-4 text-dark">Document Name :</h5>
                  <h6 className="mb-3">
                    Created at:{" "}
                    {date ? moment(date).format("DD-MM-YYYY") : " -"}
                  </h6>
                  <h6 className="mb-3">
                    Date Signed:{" "}
                    {sign_date ? moment(sign_date).format("DD-MM-YYYY") : " -"}
                  </h6>
                  <h6>Note: {note != "null" ? note : " -"}</h6>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      {/* Signature Modal */}
      <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center()
        }}
        centered
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Signature & Confirmation Of Identity
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_center(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleSave}>
            <Row>
              <div className="col-md-5 mb-3">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control "
                  onChange={e => setName(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-5 mb-3">
                <label htmlFor="">Email Address </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control"
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="">Signature</label>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    // width: 760,
                    // height: 300,
                    className: "sigCanvas border w-100 signatureHeight",
                  }}
                  ref={signatureRef}
                />
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <a className="btn btn-red btn-label me-2" onClick={handleClear}>
                <i className="bi bi-x-circle label-icon"></i>
                Clear Signature
              </a>
              <button className="btn btn-save btn-label">
                <i className="fas fa-save me-2 label-icon"></i>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}
    </React.Fragment>
  )
}

export default ViewDocument
