import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../../assets/images/users/avatar-1.jpg"
import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
import moment from "moment"

const Notes = props => {
  const [fixButtons, setFixButtons] = useState(false)
  const [followUp, setFollowUp] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [viewFollowUp, setViewFollowUp] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)

  const [addNoteDesc, setAddNoteDesc] = useState("")
  const [addNoteDate, setAddNoteDate] = useState("")
  const [editNoteDesc, setEditNoteDesc] = useState("")
  const [editNoteDate, setEditNoteDate] = useState("")

  const [purpose, setPurpose] = useState("")
  const [followdate, setFollowDate] = useState("")
  const [assigns, setAssigns] = useState([])

  const [noteId, setNoteId] = useState("")
  const [followId, setFollowId] = useState("")
  const [allNotes, setAllNotes] = useState([])
  const [loading, setLoading] = useState(false)

  const [assign_option, setAssignOption] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    setAssignOption(
      props.assigned_data?.map(obj => ({
        label: obj.name,
        value: obj.id,
      }))
    )
  }, [props.assigned_data])

  // getting all notes
  useEffect(() => {
    const getAllNotes = async () => {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))

        const response = await axios.post(
          `${configure.apiUrl}/opportunity_note_data`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setAllNotes(response.data?.data?.opportunity_note)
        // console.log("notes", response.data.data.opportunity_note)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (props.verticalActiveTab == "3") {
      getAllNotes()
    }
  }, [props.verticalActiveTab])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  // add note
  const handleAddNote = async () => {
    if (addNoteDesc !== "") {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("description", addNoteDesc)
        formData.append("date", addNoteDate)
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))

        const response = await axios.post(
          `${configure.apiUrl}/add_opportunity_note`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setAllNotes(response?.data?.data)
        // console.log(response)

        setLoading(false)
        setNotesSidebar(false)
        swal("Note added successfully!", {
          icon: "success",
        }).then(() => {
          setAddNoteDesc("")
          setAddNoteDate("")
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
        swal("Something went wrong", "", "error")
      }
    } else {
      swal("Please Add New Note Description", "", "warning")
    }
  }

  // add note
  const handleEditNote = async () => {
    if (editNoteDesc !== "") {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("description", editNoteDesc)
        formData.append("date", editNoteDate)

        formData.append("note_id", noteId)
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))

        const response = await axios.post(
          `${configure.apiUrl}/edit_opportunity_note`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setAllNotes(response?.data?.data)

        setLoading(false)
        setEditNotesSidebar(false)
        swal("Note Updated successfully!", {
          icon: "success",
        }).then(() => {
          setEditNoteDesc("")
          setEditNoteDate("")
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
        swal("Something went wrong", "", "error")
      }
    } else {
      swal("Please Add Note Description", "", "warning")
    }
  }

  const onDeleteNote = note_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to remove this Note",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("note_id", note_id)
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))

        axios
          .post(`${configure.apiUrl}/delete_opportunity_note`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            // console.log("de", response.data)

            if (response.data.data.length > 0) {
              setAllNotes(response?.data?.data)
            }

            swal("Note has been deleted successfully!", {
              icon: "success",
            })
          })
      }
    })
  }

  const handleAddFollowUp = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("purpose", purpose)
      formData.append("date", followdate)
      formData.append("assign", JSON.stringify(assigns))
      formData.append("note_id", noteId)
      formData.append("opportunity_id", props.company_id)
      formData.append("user_id", localStorage.getItem("auth_id"))

      const response = await axios.post(
        `${configure.apiUrl}/add_note_followup`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setAllNotes(response?.data?.data)

      setFollowUp(false)

      setLoading(false)

      swal("Follow Up added successfully!", {
        icon: "success",
      }).then(() => {
        setPurpose("")
        setFollowDate("")
        setAssigns("")
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "error")
    }
  }

  const handleEditFollowUp = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("purpose", purpose)
      formData.append("date", followdate)
      formData.append("assign", JSON.stringify(assigns))
      formData.append("note_id", noteId)
      formData.append("follow_id", followId)
      formData.append("opportunity_id", props.company_id)
      formData.append("user_id", localStorage.getItem("auth_id"))

      const response = await axios.post(
        `${configure.apiUrl}/edit_note_followup`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setAllNotes(response?.data?.data)

      setViewFollowUp(false)

      setLoading(false)

      swal("Follow Up Updated successfully!", {
        icon: "success",
      }).then(() => {
        setPurpose("")
        setFollowDate("")
        setAssigns("")
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "error")
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          <Form>
            {/* Fixed Buttons */}
            <div
              className={`flex-column fixed-buttons ${
                fixButtons ? "d-flex" : "d-none"
              }`}
            >
              <a
                onClick={() => setNotesSidebar(true)}
                className="btn btn-purple  fix-btn"
              >
                <i className="fas fa-plus me-2"></i>
                <span>Note</span>
              </a>
              <a onClick={() => navigate(-1)} className="btn btn-red  fix-btn">
                <i className="dripicons-cross me-2 icon-center"></i>
                <span>Cancel</span>
              </a>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <h5 className="text-dark">Notes</h5>
              <div className="text-end">
                <a
                  onClick={() => setNotesSidebar(true)}
                  className=" btn btn-save btn-label"
                >
                  <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
                  Note
                </a>
                <a
                  onClick={() => navigate(-1)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
            {/* Add Notes */}
            <div
              className={`notes-sidebar ${
                notesSidebar ? "show-notes-sidebar" : ""
              }`}
            >
              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5 className="text-dark">Add New Note</h5>
                  <div className="text-end">
                    <button
                      type="button"
                      onClick={handleAddNote}
                      className="btn btn-purple  me-2"
                    >
                      <i className="fas fa-plus me-2"></i>Add Note
                    </button>
                    <a
                      onClick={() => setNotesSidebar(false)}
                      className="btn btn-secondary text-uppercase "
                    >
                      <i className="dripicons-cross me-1 icon-center"></i>Close
                    </a>
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="">
                    Description <span className="text-danger">*</span>
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={addNoteDesc}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setAddNoteDesc(data)
                    }}
                  />
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="">
                    Got in touch with this lead{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type="date"
                    value={addNoteDate}
                    onChange={e => setAddNoteDate(e.target.value)}
                    className="form-control "
                  />
                </div>

                <div className="col-md-2 mt-3 text-end">
                  {/* <a onClick={() => {
                                        setFollowUp(true)
                                    }} className='btn btn-success  mt-4'>
                                        <i className='fas fa-plus me-2'></i>Follow Up
                                    </a> */}
                </div>
              </Row>
            </div>

            <div>
              <ul className="verti-timeline list-unstyled notes-box">
                {/* Activities */}
                {allNotes?.map((note, key) => (
                  <li key={key} className="event-list position-relative">
                    <div className="event-timeline-dot">
                      <img
                        src={`https://mars.zithas.com/application/public/${note.addedby_avatar}`}
                        className="userImg me-3 "
                        alt=""
                      />
                    </div>
                    <div className="d-flex">
                      <div className="flex-grow-1 ms-4 note pt-1">
                        <div className=" d-flex justify-content-between py-2 rounded ps-4">
                          <div>
                            <p className="text-muted mb-4">
                              <h6 className="d-inline me-2">
                                By {localStorage.getItem("auth_name")}
                              </h6>{" "}
                              {moment(note.created_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              )}
                            </p>
                            <i className="bi bi-triangle-fill note-icon"></i>
                          </div>
                          <div className="me-4">
                            {note.type == 0 ? (
                              <span
                                onClick={() => {
                                  setPurpose("")
                                  setFollowDate("")
                                  setAssigns("")

                                  setFollowUp(true)
                                  setNoteId(note.id)
                                }}
                                className="me-2 cursor-pointer badge-soft-success badge"
                              >
                                Add follow up
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  setPurpose(
                                    note.opportunity_followup[0]?.description
                                  )
                                  setFollowDate(
                                    note.opportunity_followup[0]?.datetime
                                  )
                                  setAssigns(
                                    JSON.parse(
                                      note.opportunity_followup[0]?.assign_user
                                    )
                                  )

                                  setNoteId(note.id)
                                  setFollowId(note.opportunity_followup[0]?.id)

                                  setViewFollowUp(true)
                                }}
                                className="me-2 cursor-pointer badge-soft-success badge"
                              >
                                follow up
                              </span>
                            )}
                            <button
                              onClick={e => {
                                e.preventDefault()

                                setEditNoteDesc(
                                  note.description !== null
                                    ? note.description
                                    : ""
                                )
                                setEditNoteDate(note.date)
                                setNoteId(note.id)

                                setEditNotesSidebar(true)
                              }}
                              className="btn btn-sm edit-delete-btn text-success me-2"
                            >
                              <i className="fas fa-edit" title="Edit"></i>
                            </button>
                            <button
                              type="button"
                              onClick={() => onDeleteNote(note.id)}
                              className="btn btn-sm edit-delete-btn text-danger"
                            >
                              <i
                                className="fas fa-trash-alt"
                                title="Delete"
                              ></i>
                            </button>
                          </div>
                        </div>
                        <div className="px-4">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: note.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>

              {/* Edit Notes Sidebar */}
              <div
                className={`notes-sidebar ${
                  editNotesSidebar ? "show-notes-sidebar" : ""
                }`}
              >
                <Row className="align-items-center justify-content-between">
                  <div className="col-12 d-flex justify-content-between">
                    <h5 className="text-dark">Edit Note</h5>
                    <div className="text-end">
                      <button
                        type="button"
                        onClick={handleEditNote}
                        className="btn btn-purple  me-2"
                      >
                        <i className="fas fa-edit me-2"></i>Edit Note
                      </button>
                      <a
                        onClick={() => setEditNotesSidebar(false)}
                        className="btn btn-secondary text-uppercase "
                      >
                        <i className="dripicons-cross me-1 icon-center"></i>
                        Close
                      </a>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={editNoteDesc}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setEditNoteDesc(data)
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3 notes">
                    <label htmlFor="">Got in touch with this lead</label>
                    <input
                      type="date"
                      value={editNoteDate}
                      onChange={e => setEditNoteDate(e.target.value)}
                      className="form-control "
                    />
                  </div>

                  <div className="col-md-2 mt-3 text-end">
                    {/* <a onClick={() => {
                                            setFollowUp(true)
                                        }} className='btn btn-success  mt-4'>
                                            <i className='fas fa-plus me-2'></i>Follow Up
                                        </a> */}
                  </div>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>

      {/* Follow Up Modal */}
      <Modal isOpen={followUp} size="sm" className="bd-custom-md-3" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={handleAddFollowUp}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date</label>
              <input
                type="date"
                value={followdate}
                onChange={e => setFollowDate(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose</label>
              <input
                type="Purpose"
                value={purpose}
                onChange={e => setPurpose(e.target.value)}
                className="form-control "
                placeholder="Enter Purpose"
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Assigned</label>
              <Select
                placeholder="Select Assigned"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                isMulti
                options={assign_option}
                value={assigns}
                onChange={e => setAssigns(e)}
              />
            </div>
            <div className="mt-2 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}

      {/* View Follow Up Modal */}
      <Modal
        isOpen={viewFollowUp}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setViewFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={handleEditFollowUp}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date</label>
              <input
                type="date"
                value={followdate}
                onChange={e => setFollowDate(e.target.value)}
                className="form-control "
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose</label>
              <input
                type="Purpose"
                value={purpose}
                onChange={e => setPurpose(e.target.value)}
                className="form-control "
                placeholder="Enter Purpose"
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Assigned</label>
              <Select
                placeholder="Select Assigned"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                isMulti
                options={assign_option}
                value={assigns}
                onChange={e => setAssigns(e)}
              />
            </div>
            <div className="mt-2 text-end">
              <button className=" btn btn-purple">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default Notes
