import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Loader from "pages/Separate/Loader"
import { Link, useNavigate } from "react-router-dom"
import configure from "configure"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const EditCurrentOpening = () => {
  document.title = "Edit Current Opening | Zithas Crm"
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()

  return (
    <div>
      <div className="page-content">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Edit current Opening"}
          />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <Col className="d-flex justify-content-end">
                        <div className="me-4"></div>
                        <div>
                          <button
                            className="border-radius btn btn-purple"
                            type="submit"
                          >
                            {" "}
                            <i className="fas fa-plus me-2 icon-size"></i>
                            Publish
                          </button>
                          <button
                            onClick={() => navigate(-1)}
                            className="border-radius btn ms-md-2 btn-red"
                          >
                            <i className="dripicons-cross me-2 icon-center"></i>
                            CANCEL
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <div className="pt-4">
                      <div>
                        <Row>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Position Name</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Position Name"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Vacancies</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Vacancies"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Location</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Location"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Experience</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Experience"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Preffered Skills</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Preffered Skills"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Permalink</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Permalink"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                        </Row>
                        <Row className="pt-3">
                          <Col md={4}>
                            <div className=" mb-3 pr-row ">
                              <label htmlFor="">Job Description:</label>
                              <div className="">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data="<p></p>"
                                  config={{
                                    height: "50px", // Adjust the height as needed
                                  }}
                                  // onReady={editor => {
                                  //     // You can store the "editor" and use when it is needed.
                                  // }}
                                  // onChange={(event, editor) => {
                                  //     const data = editor.getData();
                                  // }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className=" mb-3 pr-row ">
                              <label htmlFor="">
                                Roles & Responsibilities:
                              </label>
                              <div className="">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data="<p></p>"
                                  config={{
                                    height: "50px", // Adjust the height as needed
                                  }}
                                  // onReady={editor => {
                                  //     // You can store the "editor" and use when it is needed.
                                  // }}
                                  // onChange={(event, editor) => {
                                  //     const data = editor.getData();
                                  // }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className=" mb-3 pr-row ">
                              <label htmlFor="">
                                Soft Skills Requirements:
                              </label>
                              <div className="">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data="<p></p>"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="mb-3">
                          <input
                            required
                            type="checkbox"
                            id="featuredCheckbox"
                            name="featured"
                            //   value="1"
                            //   checked={featured}
                            //   onChange={handleCheckboxChange}
                          />
                          <label
                            htmlFor="featuredCheckbox"
                            className="form-label ps-2"
                          >
                            Featured
                          </label>
                        </div>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default EditCurrentOpening
