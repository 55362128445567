import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Profile from "./Profile"
import Notes from "./Notes"
import FollowUp from "./FollowUp"
import ActivityLog from "./ActivityLog"

import axios from "axios"
import Loader from "pages/Separate/Loader"
import { useParams } from "react-router-dom"
import configure from "configure"
import moment from "moment/moment"
// components

const EditLead = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)

  // Created at
  const [created_at, setCreatedAt] = useState("")

  //edit data
  const [data, setDatas] = useState([])
  const [editData, setEditData] = useState()
  const [loading, setLoading] = useState(false)
  let url = window.location.toString()

  useEffect(() => {
    fetch2()
    fetchCreatedOn()
  }, [])

  const params = useParams()

  const [lead_data, setLeadData] = useState([])
  const [note_data, setNoteData] = useState([])
  const [log_data, setLogData] = useState([])
  const [follow_data, setFollowData] = useState([])
  const [assigned_data, setAssignData] = useState([])
  const [industry_option, setIndustryOption] = useState([])

  const fetch2 = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("lead_id", params.id)
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/all_lead_data`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setLeadData(response.data.data?.lead)
        setNoteData(response.data.data?.lead_note)
        setLogData(response.data.data.lead_activity?.reverse())
        setFollowData(response.data.data?.lead_followup_data)
        setAssignData(response.data.data?.assigned)
        setIndustryOption(
          response.data.data?.industry?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // Fetching created on Date and time
  const fetchCreatedOn = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/lead/${params.id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        const data = response.data.data?.map(items => {
          setCreatedAt(items.created_at)
        })
      })
    } catch (err) {
      console.log(err)
    }
  }

  // console.log("setLeadData", lead_data[0].created_at)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Edit Lead  | Zithas Technologies"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const timeDifference = new Date() - new Date(lead_data[0]?.created_at)
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  // console.log("lead_data", lead_data)

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Edit Lead"} />

          <Row className="align-items-start justify-content-between h-100">
            <Col md="2" className="px-0 h-100">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Follow Up
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      fetch2()
                      toggleVertical("3")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
              </Nav>
              {/* <div>Test</div> */}
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0" id="edit-lead">
                <Col className="company-info px-2 shadow mb-3 me-0 d-flex justify-space-between align-items-center">
                  <div className="d-flex justify-content-start align-items-center py-2 pt-3 gap-4">
                    <div className="">
                      <h5>
                        <i className="bi bi-buildings-fill "></i>{" "}
                        <span className="border-end border-3 border-dark pe-3">
                          {" "}
                          {lead_data[0]?.company ? lead_data[0]?.company : "-"}
                        </span>{" "}
                      </h5>
                    </div>
                    <div>
                      <h5>
                        <i className="bi bi-person-fill"></i>{" "}
                        <span className="border-end border-3 border-dark pe-3">
                          {" "}
                          {lead_data[0]?.person_details
                            ? JSON.parse(lead_data[0].person_details)[0]
                                ?.person_name
                              ? JSON.parse(lead_data[0].person_details)[0]
                                  .person_name
                              : "-"
                            : "-"}
                        </span>{" "}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <i className="bi bi-telephone-fill"></i>{" "}
                        <span className="border-end border-3 border-dark pe-3">
                          {" "}
                          {/* {lead_data[0]?.person_details
                            ? JSON.parse(lead_data[0].person_details)[0]
                                ?.person_phone
                              ? JSON.parse(lead_data[0].person_details)[0]
                                  .person_phone
                              : "-"
                            : "-"} */}
                          {lead_data[0]?.person_details
                            ? JSON.parse(lead_data[0].person_details)[0]
                                ?.person_phone
                              ? JSON.parse(lead_data[0].person_details)[0]
                                  .person_phone
                              : lead_data[0]?.phonenumber != null
                              ? lead_data[0]?.phonenumber
                              : "-"
                            : lead_data[0]?.phonenumber != null
                            ? lead_data[0]?.phonenumber
                            : "-"}
                        </span>{" "}
                      </h5>
                    </div>
                    <div>
                      <h5>
                        <i className="bi bi-envelope-fill"></i>{" "}
                        <span className="border-end border-3 border-dark pe-3">
                          {" "}
                          {/* {lead_data[0]?.person_details
                            ? JSON.parse(lead_data[0].person_details)[0]
                                ?.person_email
                              ? JSON.parse(lead_data[0].person_details)[0]
                                  .person_email
                              : "-"
                            : "-"} */}
                          {lead_data[0]?.person_details
                            ? JSON.parse(lead_data[0].person_details)[0]
                                ?.person_email
                              ? JSON.parse(lead_data[0].person_details)[0]
                                  .person_email
                              : lead_data[0]?.email != null
                              ? lead_data[0]?.email
                              : "-"
                            : lead_data[0]?.email != null
                            ? lead_data[0]?.email
                            : "-"}
                        </span>{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start align-items-center py-2 pt-3">
                    <h5>
                      Created On :{" "}
                      {moment(lead_data[0]?.created_at).format("DD-MM-YYYY")} (
                      {parseFloat(daysDifference)?.toFixed(0)} days)
                    </h5>
                  </div>
                </Col>
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <Profile
                      editData={lead_data[0] || []}
                      industry_option={industry_option}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <Notes
                      noteData={note_data || []}
                      assigned={assigned_data || []}
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <ActivityLog logdata={log_data || []} />
                  </TabPane>
                  <TabPane tabId="4">
                    <FollowUp
                      followData={follow_data || []}
                      assigned={assigned_data || []}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditLead
