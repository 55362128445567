import React, { useEffect, useMemo, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Form
} from "reactstrap";
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';

// css
import '../../../assets/css/Settings/Setting.css';

// commom 
import Pagination from 'components/Common/Pagination';
import { CSVLink } from "react-csv";

function TableContainer({ columns, data }) {

    const [fixedContent, setFixedContent] = useState(false);
    const [formPopup, setFormPopup] = useState(false);

    const navigate = useNavigate()

    document.addEventListener('scroll', () => {
        if (window.scrollY > 256) {
            setFixedContent(true);
        }
        else {
            setFixedContent(false);
        }
    })

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        prepareRow
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, pageSize, globalFilter } = state;


    // Functionality to export table data to CSV //
    const excelColumns = []
    columns.forEach(column => {
        excelColumns.push(column.Header)
    });

    const csvData = [
        excelColumns,
        ...data.map(({ id, date, title, invoiceNo, customer, category, debit, credit }) => [
            id, date, title, invoiceNo, customer, category, debit, credit
        ]),
    ];
    // ======================================================= //

    // Render the UI for your table
    return (
        <div >
            {/* fixed buttons */}
            <div className={`flex-column fixed-buttons ${fixedContent ? 'd-flex' : 'd-none'}`}>
                <CSVLink
                    className='btn btn-success border-radius fix-btn'
                    filename="gst_staffguru.csv"
                    data={csvData}>
                    <i className='fas fa-download me-2 icon-center'></i>
                    <span>Export</span>
                </CSVLink>

                <button onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                    <i className='dripicons-cross me-2 icon-center'></i>
                    <span>Cancel</span>
                </button>
            </div>
            <Row className='mb-2'>
                {/* Amounts Records */}
                <div className='d-flex mb-4'>
                    <a href="#" className='rounded payment-amounts mb-0'>
                        <p className='text-dark mb-2'>Current Month GST Balance </p>
                        <h4 className='text-secondary'>₹ 0.00</h4>
                    </a>

                    <a href="#" className='rounded payment-amounts mb-0'>
                        <p className='text-dark mb-2'> Last Month GST Balance </p>
                        <h4 className='text-secondary'>₹ 0.00</h4>
                    </a>
                </div>
                <Col>
                    <select
                        className="border-radius form-select"
                        style={{ width: '100px' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(e.target.value)}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col md={8} className='d-flex justify-content-end'>
                    <div className='me-4'>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="border-radius form-control rounded h-100"
                                        placeholder={` Search...`}
                                        value={globalFilter || ''}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>

                    </div>
                    <div >
                        <button onClick={() => setFormPopup(!formPopup)} className='btn btn-outline-secondary border-radius me-2'><i className='fas fa-filter'></i></button>
                        {/* CSV download btn */}
                        <CSVLink
                            className='border-radius btn text-white me-2 btn-success'
                            filename="gst_staffguru.csv"
                            data={csvData}>
                            <i className="fas fa-download me-2 icon-size"></i> Export CSV
                        </CSVLink>
                        <button onClick={() => navigate(-1)} className="border-radius btn btn-red"><i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                    </div>
                </Col>
            </Row>

            <div className='position-relative'>
                <Table className='table table-bordered mt-3' {...getTableProps()}>
                    <thead className={`${fixedContent ? 'fixed-setting-header' : ''} fix-gst-header`}>
                        {
                            headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column, index) => (
                                            <th key={index} className={`t-col-${index + 1} ${column.className}`} {...column.getHeaderProps()}>{column.render("Header")}
                                                {
                                                    column.isInfoIcon ?
                                                        <i title='info' className='bx bx-info-circle ms-1 info-icon'></i> : null}
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody className="fix-gst-header" {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='row-hover' {...row.getRowProps()} key={i}>
                                        {
                                            row.cells.map((cell, index) => {
                                                return <td key={index} className={`t-col-${index + 1} ${cell.column.className}`} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                {
                    formPopup ?
                        <div className='ztpl-popup-form popup-form p-4'>
                            <Form>
                                <Row className='align-items-end'>
                                    <div className="col-md-3">
                                        <label htmlFor="">Start Date</label>
                                        <input type="date" className='form-control border-radius' />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="">End Date</label>
                                        <input type="date" className='form-control border-radius' />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="">By</label>
                                        <select type="date" className='form-select border-radius'>
                                            <option value="Nothing Selected">Nothing Selected</option>
                                            <option value="Debit">Debit</option>
                                            <option value="Credit">Credit</option>
                                        </select>
                                    </div>

                                    <div className='text-center col-md-3'>
                                        <button className='btn border-radius btn-purple me-3'>
                                            <i className="fas fa-search me-2"></i>
                                            Search
                                        </button>
                                        <button className='btn border-radius btn-red'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setFormPopup(!formPopup)
                                            }}
                                        ><i className="dripicons-cross me-1 icon-center"></i> Clear</button>
                                    </div>
                                </Row>
                            </Form>
                            <i className='bi bi-triangle-fill pop-icon'></i>
                        </div> : null
                }
            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Pagination
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                />
            </Row>
        </div>
    );
}

const StaffGuru = () => {

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                isInfoIcon: false,
                className: 'text-center'
            },
            {
                Header: "Date",
                accessor: "date",
                isInfoIcon: true,
            },
            {
                Header: "Title",
                accessor: "title",
                isInfoIcon: true,
            },
            {
                Header: "Invoice No",
                accessor: "invoiceNo",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Customer",
                accessor: "customer",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Category",
                accessor: "category",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Debit (-)",
                accessor: "debit",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Credit (+)",
                accessor: "credit",
                isInfoIcon: true,
                className: 'text-center'
            }
        ],
        []
    );

    const data = [
        {
            id: 1,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '20-03-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 2,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '28-08-2023 11:17:05',
            title: 'PGVCL',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 3,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '20-03-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 4,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '28-08-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 5,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '28-08-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 6,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '28-08-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 4,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '28-08-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 5,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '28-08-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },
        {
            id: 6,
            category: 'Phone Bill',
            invoiceNo: "-",
            customer: "-",
            date: '28-08-2023 11:17:05',
            title: 'test the general task',
            debit: 1200,
            credit: 3000,
        },

    ]

    return (
        <>
            <div>
                <Row className='ms-0 pe-0'>
                    <Col lg={12} className='p-0'>
                        <Card>
                            <CardBody>
                                <TableContainer columns={columns} data={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default StaffGuru