import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form } from "reactstrap"
import Select from "react-select"
import { Link, useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import indiaFlag from "../../../../assets/images/flags/india.png"
import UkFlag from "../../../../assets/images/flags/uk.png"
import configure from "configure"

const Profile = () => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const source_option = [
    { label: "Customer", value: "0" },
    { label: "Introducer", value: "1" },
  ]

  const groups_option = [
    { label: "Regular", value: "1" },
    { label: "Monthly", value: "2" },
    { label: "Quarterly", value: "3" },
  ]

  const [isLoading, setLoading] = useState(false)
  const [country_option, setCountryOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [company_name, setCompany_name] = useState("")
  const [vat_number, setVatNumber] = useState("")
  const [location, setLocation] = useState([])
  const [source, setSource] = useState([])
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [country, setCountry] = useState([])
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [address, setAddress] = useState("")
  const [zip_code, setZipCode] = useState("")
  const [groups, setGroups] = useState("")
  const [currency_option, setCurrencyOption] = useState([])
  const [currency, setCurrency] = useState([])
  const [incorporation, setIncorporation] = useState("")
  const [customer_option, setCustomerOption] = useState([])
  const [customer_name, setCustomerName] = useState([])
  const [save, setSave] = useState("")

  const params = useParams()

  useEffect(() => {
    FetchProposalData()
  }, [])

  const FetchProposalData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/view_customer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        setCompany_name(response.data.data[0].company)
        setVatNumber(response.data.data[0].vatnumber)
        setPhone(response.data.data[0].phone)
        setEmail(response.data.data[0].email)
        setWebsite(response.data.data[0].website)
        setAddress(response.data.data[0].address)
        setIncorporation(response.data.data[0].corporationdate)
        const sources = source_option.filter(
          objs => objs.label == response.data.data[0]?.reference
        )[0]
        setSource(sources)
        setGroups(
          groups_option.filter(
            obj => obj.value == response.data.data[0].group_id
          )[0]
        )
        setZipCode(response.data.data[0].zipcode)
        const formDatas = new FormData()
        formDatas.append("location", response.data.data[0]?.location_id)

        formDatas.append("source_name", sources?.value)

        if (response.data.data[0]?.reference) {
          const res3 = await axios({
            method: "post",
            url: `${configure.apiUrl}/show_source`,
            data: formDatas,
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }).then(res => {
            setCustomerOption(
              res?.data?.data?.map(items => ({
                value: items.id,
                label: items.company,
              }))
            )

            setCustomerName(
              res?.data?.data
                ?.map(items => ({
                  value: items.id,
                  label: items.company,
                }))
                .filter(
                  obj => obj.value == response.data.data[0].source_name
                )[0]
            )
          })
        }

        await axios({
          method: "get",
          url: `${configure.apiUrl}/locations`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res?.data?.data?.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
          setLocationOption(option)
          // setLocation(
          //   option.filter(
          //     obj => obj.value == response.data.data[0].location_id
          //   )[0]?.value
          // )
          setLocation(response.data.data[0].location_id)
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/currency`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res?.data?.data?.currency?.map(item => ({
            label: item.currency,
            value: item.id,
          }))
          setCurrencyOption(option)
          setCurrency(
            option.filter(
              obj => obj.value == response.data.data[0].currency_id
            )[0]
          )
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/country`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res?.data?.data?.country?.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
          setCountryOption(option)
          setCountry(
            option.filter(
              obj => obj.value == response.data.data[0].country_id
            )[0]
          )
        })

        const formData = new FormData()
        formData.append("country_id", response.data.data[0].country_id)
        const res = await axios({
          method: "post",
          url: `${configure.apiUrl}/country_state`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const options = res?.data?.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
          setStateOption(options)
          setState(
            options.filter(
              obj => obj.value == response.data.data[0].state_id
            )[0]
          )
        })

        const formData2 = new FormData()
        formData2.append("state_id", response.data.data[0].state_id)
        const res2 = await axios({
          method: "post",
          url: `${configure.apiUrl}/state_city`,
          data: formData2,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const options = res?.data?.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
          setCityOption(options)
          setCity(
            options.filter(obj => obj.value == response.data.data[0].city_id)[0]
          )
        })

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const onChangeCountry = async e => {
    setState([])
    setCity([])
    setLoading(true)
    setCountry(e)
    const formData = new FormData()
    formData.append("country_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/country_state`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setStateOption(
          res?.data?.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  const onChangeState = async e => {
    setLoading(true)
    setState(e)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/state_city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCityOption(
          res?.data?.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onGetSource = async e => {
    setCustomerOption([])
    setSource(e)
    setLoading(true)
    const formData = new FormData()
    formData.append("location", location)
    formData.append("source_name", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_source`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCustomerOption(
          res?.data?.data?.map(items => ({
            value: items.id,
            label: items.company,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onEditCustomer = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("customer_id", params.id)
    formData.append("company", company_name)
    formData.append("vatnumber", vat_number)
    formData.append("phone", phone)
    formData.append("website", website)
    formData.append("address", address)
    formData.append("zipcode", zip_code)
    formData.append("country", country?.value)
    formData.append("state", state?.value)
    formData.append("city", city?.value)
    formData.append("groups", groups?.value)
    formData.append("currency", currency?.value)
    formData.append("location", location)
    formData.append("email", email)
    formData.append("corporationdate", incorporation)
    formData.append("source_name", customer_name.value)
    formData.append("source", source.value)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_customer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Customer Edited Succesfully",
        }).then(data => {
          if (save != 0) {
            navigate(`${configure.appUrl}sales/add-contact/${params.id}`)
          } else {
            navigate(`${configure.appUrl}sales/customer`)
          }
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div>
        <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
          <form onSubmit={onEditCustomer}>
            <div
              className={`flex-column fixed-buttons pt-2 ${
                fixButtons ? "d-flex" : "d-none"
              }`}
            >
              <button
                className="btn btn-purple  fix-btn btn-label pe-0"
                onClick={() => setSave("0")}
              >
                <i className="fas fa-save me-2 label-icon"></i>
                <span>Save</span>
              </button>
              <a
                onClick={() => navigate(-1)}
                className="btn btn-red  fix-btn btn-label pe-0"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                <span>Cancel</span>
              </a>
            </div>
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="col-md-6 ">
                <div className="">
                  <h5 className="font-size-14 mb-4">Location</h5>
                  <div className="row " style={{ marginLeft: "0px" }}>
                    {location_option?.map((item, i) => {
                      return (
                        <div className="form-check mb-2 col-md-2" key={i}>
                          <input
                            className="form-check-input input-location"
                            type="radio"
                            name="exampleRadios"
                            id={`radios${i}`}
                            value={item.value}
                            defaultChecked={location == item.value}
                            onChange={e => setLocation(e.target.value)}
                          />
                          <label
                            className="form-check-label pl-10"
                            htmlFor={`radios${i}`}
                          >
                            {item.value == 1 ? (
                              <img
                                src={indiaFlag}
                                alt="no img"
                                width="28"
                                height="18"
                                style={{ marginTop: "2px" }}
                              />
                            ) : (
                              <img
                                src={UkFlag}
                                alt="no img"
                                width="28"
                                height="18"
                                style={{ marginTop: "2px" }}
                              />
                            )}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-6  text-end">
                <button
                  onClick={() => setSave("0")}
                  className=" btn ms-md-2 btn-save btn-label"
                >
                  <i className="fas fa-save me-1 label-icon"></i> save
                </button>
                <a
                  onClick={e => {
                    e.preventDefault()
                    navigate(-1)
                  }}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </Row>

            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Company Information</h5>
              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  Company <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Company"
                  className="form-control "
                  value={company_name}
                  onChange={e => setCompany_name(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">GST/VAT Number</label>
                <input
                  type="text"
                  placeholder="Enter GST/VAT Number"
                  className="form-control "
                  value={vat_number}
                  onChange={e => setVatNumber(e.target.value)}
                />
              </div>
              {/* <div className="col-md-3 mb-3">
                            <label htmlFor="">
                              Location <span className="text-danger">*</span>{" "}
                            </label>
                            <Select
                              placeholder="Select Location"
                              className="basic-multi-select"
                              classNamePrefix=" select"
                              styles={colourStyles}
                              options={location_option}
                              value={location}
                              onChange={e => setLocation(e)}
                            />
                          </div> */}

              <div className="col-md-3 mb-3">
                <label htmlFor="">
                  Source <span className="text-danger">*</span>{" "}
                </label>
                <Select
                  placeholder="Select Source"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={source_option}
                  value={source}
                  onChange={e => onGetSource(e)}
                />
              </div>
              <div className="col-md-3">
                {source?.length != 0 ? (
                  <>
                    <label htmlFor="">
                      Select {source?.label}{" "}
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      placeholder={`Select ${source?.label}`}
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={customer_option}
                      value={customer_name}
                      onChange={e => setCustomerName(e)}
                    />
                  </>
                ) : null}
              </div>

              <div className="col-md-3 mb-3">
                <label htmlFor="">Phone</label>
                <input
                  type="text"
                  placeholder="Enter Phone"
                  className="form-control "
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Email Address</label>
                <input
                  type="text"
                  placeholder="Enter Email Address"
                  className="form-control "
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Website</label>
                <input
                  type="text"
                  placeholder="Enter Website"
                  className="form-control "
                  value={website}
                  onChange={e => setWebsite(e.target.value)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Incorporation Date</label>
                <input
                  type="date"
                  className="form-control "
                  value={incorporation}
                  onChange={e => setIncorporation(e.target.value)}
                />
              </div>
            </Row>

            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Address</h5>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Country</label>
                <Select
                  placeholder="Select Location"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={country_option}
                  value={country}
                  onChange={e => onChangeCountry(e)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">State</label>
                <Select
                  placeholder="Select State"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={state_option}
                  value={state}
                  onChange={e => onChangeState(e)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">City </label>
                <Select
                  placeholder="Select City"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={city_option}
                  value={city}
                  onChange={e => setCity(e)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Zip Code</label>
                <input
                  type="text"
                  placeholder="Enter Zip Code"
                  className="form-control "
                  value={zip_code}
                  onChange={e => setZipCode(e.target.value)}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="">Address</label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="form-control "
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label htmlFor="">Groups </label>
                <Select
                  placeholder="Select Groups"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={groups_option}
                  value={groups}
                  onChange={e => setGroups(e)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Currency </label>
                <Select
                  placeholder="Select Currency"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={currency_option}
                  value={currency}
                  onChange={e => setCurrency(e)}
                />
              </div>
            </Row>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Profile
