import React, { useState } from "react"
import { Row, Col, Card, CardBody, Form } from "reactstrap"
import Select from "react-select"
import { Link, useNavigate } from "react-router-dom"

const ViewProfile = ({ view_customer }) => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  return (
    <React.Fragment>
      <div>
        <div>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red border-radius fix-btn"
            >
              <i className="dripicons-cross me-2 icon-center"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="px-2 py-0">
                  <Row className="px-1">
                    <Form>
                      <Row className="bg-white px-2 py-4 shadow mb-3 bd-span-input">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">
                            Location <span className="text-danger">*</span>{" "}
                          </label>
                          <div className="input-span input">
                            {view_customer[0].location || "-"}
                          </div>
                        </div>
                      </Row>
                      <Row className="bg-white px-2 py-4 mb-3 shadow bd-span-input">
                        <h5 className="mb-3">Company Information</h5>

                        <div className="col-md-4 mb-3">
                          <label htmlFor="">
                            Company <span className="text-danger">*</span>
                          </label>
                          <div className="input-span input">
                            {view_customer[0].company}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">GST/VAT Number</label>
                          <div className="input-span input">
                            {view_customer[0].vatnumber || "-"}
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label htmlFor="">
                            Source <span className="text-danger">*</span>{" "}
                          </label>
                          <div className="input-span input">
                            {view_customer[0].reference || "-"}
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label htmlFor="">
                            {view_customer[0].reference}{" "}
                            <span className="text-danger">*</span>{" "}
                          </label>
                          <div className="input-span input">
                            {view_customer[0].reference_name || "-"}
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Phone</label>
                          <div className="input-span input">
                            {view_customer[0].phone || "-"}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Email Address</label>
                          <div className="input-span input">
                            {view_customer[0].email || "-"}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Website</label>
                          <div className="input-span input">
                            {view_customer[0].website || "-"}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Incorporation Date</label>
                          <div className="input-span input">
                            {view_customer[0].corporationdate || "-"}
                          </div>
                        </div>
                      </Row>

                      <Row className="bg-white px-2 py-4  shadow bd-span-input">
                        <h5 className="mb-3">Address</h5>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Country</label>
                          <div className="input-span input">
                            {view_customer[0].country || "-"}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">State</label>
                          <div className="input-span input">
                            {view_customer[0].state || "-"}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">City </label>
                          <div className="input-span input">
                            {view_customer[0].city || "-"}
                          </div>
                        </div>

                        <div className="col-md-8 mb-3 ">
                          <label htmlFor="">Address</label>
                          <div className="input-span input">
                            {view_customer[0].address || "-"}
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Zip Code</label>
                          <div className="input-span input">
                            {view_customer[0].zipcode || "-"}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Groups </label>
                          <div className="input-span input">
                            {view_customer[0].groups || "-"}
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Currency </label>
                          <div className="input-span input">
                            {view_customer[0].currency || "-"}
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewProfile
