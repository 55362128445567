import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Row, Col, Card, CardBody, CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";

import classnames from "classnames"

// assets
import '../../../assets/css/Sales/Sales.css';
import AddMilestone from './AddMilestone';
import DaysEstimation from './DaysEstimation';
import AddTask from './AddTask';
import ApprovalProcess from './ApprovalProcess';
import CompleteProcess from './CompleteProcess';

const TaskDetails = () => {

    const [fixButtons, setFixButtons] = useState(false);
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 5) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    const navigate = useNavigate();

    document.addEventListener('scroll', () => {
        if (window.scrollY > 160) {
            setFixButtons(true);
        }
        else {
            setFixButtons(false);
        }
    })


    return (
        <React.Fragment>
            <div >
                <div>
                    {/* Fixed Buttons */}
                    <div className={`flex-column fixed-buttons ${fixButtons ? 'd-flex' : 'd-none'}`}>
                        <a onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                            <i className='dripicons-cross me-2 icon-center'></i>
                            <span>Cancel</span>
                        </a>
                    </div>
                    < Row >
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className='d-flex justify-content-between align-items-center wizard'>
                                        <h5 className='mb-0 fw-bold col-6'>
                                            <span className='text-purple'>Total : 50</span>
                                            <span className='px-4'>|</span>
                                            <span className='text-success'>Used : 10</span>
                                            <span className='px-4'>|</span>
                                            <span className='text-primary'>Balanced : 50.0</span>
                                        </h5>

                                        <div className="actions clearfix mt-3">
                                            <ul>
                                                <li
                                                    className={
                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1)
                                                        }}
                                                        className='bg-purple'
                                                    >
                                                        <i className='fas fa-arrow-left me-2'></i>
                                                        Previous
                                                    </Link>
                                                </li>
                                                <li
                                                    className={activeTab === 5 ? "next disabled" : "next"}
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1)
                                                        }}
                                                        className='bg-purple'
                                                    >
                                                        Next
                                                        <i className='fas fa-arrow-right ms-2'></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='text-end col-2'>
                                            <a onClick={() => navigate(-1)} className="border-radius btn ms-md-2 btn-red">
                                                <i className='dripicons-cross me-2 icon-center'></i>CANCEL
                                            </a>
                                        </div>
                                    </div>

                                    <Row className='mt-4'>

                                        <Col lg="12" >
                                            <div className="wizard clearfix ">
                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={`${activeTab === 1 && "active-plan-tab"} plan-tab`}
                                                        >
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                                onClick={() => {
                                                                    setactiveTab(1)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(1)}
                                                            >
                                                                <span className={`number ${activeTab === 1 && "active-plan-bg"} tab-circle`}>1</span> Add Milestone
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={`${activeTab === 2 && "active-plan-tab"} plan-tab`}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                                onClick={() => {
                                                                    setactiveTab(2)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(2)}
                                                            >
                                                                <span className={`number ${activeTab === 2 && "active-plan-bg"} tab-circle`}>2</span> Add Task
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={`${activeTab === 3 && "active-plan-tab"} plan-tab`}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 3 })}
                                                                onClick={() => {
                                                                    setactiveTab(3)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(3)}
                                                            >
                                                                <span className={`number ${activeTab === 3 && "active-plan-bg"} tab-circle`}>3</span> Days Estimation
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={`${activeTab === 4 && "active-plan-tab"} plan-tab`}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 4 })}
                                                                onClick={() => {
                                                                    setactiveTab(4)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(4)}
                                                            >
                                                                <span className={`number ${activeTab === 4 && "active-plan-bg"} tab-circle`}>4</span> Approval Process
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={`${activeTab === 5 && "active-plan-tab"} plan-tab`}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 5 })}
                                                                onClick={() => {
                                                                    setactiveTab(5)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(5)}
                                                            >
                                                                <span className={`number ${activeTab === 5 && "active-plan-bg"} tab-circle`}>5</span> Complete
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <div className="content clearfix px-0">
                                                    <TabContent activeTab={activeTab} className="body">
                                                        <TabPane tabId={1}>
                                                            <AddMilestone />
                                                        </TabPane>
                                                        <TabPane tabId={2}>
                                                            <AddTask />
                                                        </TabPane>
                                                        <TabPane tabId={3}>
                                                            <DaysEstimation />
                                                        </TabPane>
                                                        <TabPane tabId={4}>
                                                            <ApprovalProcess />
                                                        </TabPane>
                                                        <TabPane tabId={5}>
                                                            <CompleteProcess />
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                                <div className="actions clearfix mt-4">
                                                    <ul>
                                                        <li
                                                            className={
                                                                activeTab === 1 ? "previous disabled" : "previous"
                                                            }
                                                        >
                                                            <Link
                                                                to="#"
                                                                onClick={() => {
                                                                    toggleTab(activeTab - 1)
                                                                }}
                                                                className='bg-purple'
                                                            >
                                                                <i className='fas fa-arrow-left me-2'></i>
                                                                Previous
                                                            </Link>
                                                        </li>
                                                        <li
                                                            className={activeTab === 5 ? "next disabled" : "next"}
                                                        >
                                                            <Link
                                                                to="#"
                                                                onClick={() => {
                                                                    toggleTab(activeTab + 1)
                                                                }}
                                                                className='bg-purple'
                                                            >
                                                                Next
                                                                <i className='fas fa-arrow-right ms-2'></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row >
                </div>
            </div>
        </React.Fragment>
    )
}

export default TaskDetails