import React, { useEffect, useState } from "react"
import { Container, Modal } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import currentImg from "../../../assets/images/credential/img-5.avif"
import "../../../assets/css/CurrentOpening/Style.css"

// common component
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import moment from "moment"

function TableContainer({ columns, data }) {
  const [fixedHeader, setFixedHeader] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  const [name_modal, setNameModal] = useState(false)
  const [action_modal, setActionModal] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_center() {
    setNameModal(false)
    removeBodyCss()
  }

  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          to={`${configure.appUrl}addroles`}
          className="btn btn-purple  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>CurrentOpening</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col sm={4}>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <Link
              to={`${configure.appUrl}addcurrentopening`}
              className=" btn btn-save btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add
              CurrentOpening
            </Link>
            <button
              onClick={() => navigate(-1)}
              className=" btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="row">
        <div
          className={`col-md-4 d-flex justify-content-center pt-4 ${
            !fixedContent && "px-4"
          }`}
        >
          <div className={`${fixedContent ? "currentImg " : ""}`}>
            <img src={currentImg} alt="currentImg" className="w-100" />
          </div>
        </div>
        <div className="col-md-8">
          <Table className="table table-bordered mt-4" {...getTableProps()}>
            <thead
              className={`${
                fixedContent ? "fix-header-opportunity" : ""
              } fix-databank-header`}
            >
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={index}
                      className={`t-col-${index + 1} t-head-${index + 1} ${
                        column.className
                      }`}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                      {column.isInfoIcon ? (
                        <i
                          title="info"
                          className="bx bx-info-circle ms-1 info-icon"
                        ></i>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody className="fix-databank-header" {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-center">
                    No data in table
                  </td>
                </tr>
              ) : (
                page
                  .map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr className="row-hover" {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className={`t-col-${index + 1} ${
                                cell.column.className
                              }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                  .reverse()
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Roles Name Modal */}

      {/* Acion Modal */}
    </div>
  )
}

const CurrentOpening = () => {
  //meta title
  document.title = "Current Opening | Zithas Crm"
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/roles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const data2 = [
    {
      id: 1,
      role_name: "test",
      Date: moment().format("DD-MM-YYYY"),
      Status: true,
      Featured: "test",
    },
    {
      id: 2,
      role_name: "test",
      Date: moment().format("DD-MM-YYYY"),
      Status: true,
      Featured: "test",
    },
    {
      id: 3,
      role_name: "test",
      Date: moment().format("DD-MM-YYYY"),
      Status: true,
      Featured: "test",
    },
    {
      id: 4,
      role_name: "test",
      Date: moment().format("DD-MM-YYYY"),
      Status: true,
      Featured: "test",
    },
    {
      id: 5,
      role_name: "test",
      Date: moment().format("DD-MM-YYYY"),
      Status: true,
      Featured: "test",
    },
    {
      id: 6,
      role_name: "test",
      Date: moment().format("DD-MM-YYYY"),
      Status: true,
      Featured: "test",
    },
    {
      id: 7,
      role_name: "test",
      Date: moment().format("DD-MM-YYYY"),
      Status: true,
      Featured: "test",
    },
  ]

  const navigate = useNavigate()
  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        className: "text-center",
      },
      {
        Header: "Position",
        accessor: "role_name",
        isInfoIcon: true,
        className: "",
      },
      {
        Header: "Date",
        accessor: "Date",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Status",
        accessor: "Status",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Featured",
        accessor: "Featured",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Action",
        accessor: "action",
        isInfoIcon: true,
        className: "text-center",
        Cell: cellProps => {
          const callBack = () => {
            navigate(`${configure.appUrl}editcurrentopening`)
          }

          const onDeleteRole = e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this Role !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                e.preventDefault()
                setLoading(true)
                const formData = new FormData()
                formData.append("role_id", cellProps.row.values.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/deleteRoles`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Roles Deleted Successfully",
                    }).then(data => {
                      setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }
          return (
            <div className="d-flex">
              <a
                onClick={callBack}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a
                onClick={onDeleteRole}
                className="btn btn-sm action-btn btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Current Opening"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data2} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CurrentOpening
