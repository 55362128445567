import React, { useMemo, useState } from "react"
import { Row, Col, Card, CardBody, Table } from "reactstrap"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import Pagination from "components/Common/Pagination"

function TableContainer({ columns, data }) {
  const [fixedHeader, setFixedHeader] = useState(false)

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  // Render the UI for your table
  return (
    <div>
      <Row className="mb-3 justify-content-between">
        <Col md={2}>
          <select
            className="border-radius form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <div>
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Table
        className="table table-bordered payment-table"
        {...getTableProps()}
      >
        <thead className={`${fixedHeader ? "fix-header" : ""}`}>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <i
                      title="info"
                      className="bx bx-info-circle ms-1 info-icon"
                    ></i>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          {data.length == 0 ? (
            <td
              colSpan="5"
              className="text-center"
              style={{ padding: "10px", border: "none" }}
            >
              No Contacts Available for this Customer
            </td>
          ) : null}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </div>
  )
}

const ViewContacts = ({ customer_contact }) => {
  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isInfoIcon: true,
      },
      {
        Header: "Email",
        accessor: "email",
        isInfoIcon: true,
      },
      {
        Header: "Position",
        accessor: "position",
        isInfoIcon: true,
      },
      {
        Header: "Phone",
        accessor: "phone",
        isInfoIcon: true,
      },
      {
        Header: "Primary Contact",
        accessor: "phones",
        isInfoIcon: true,
        Cell: cellProps => {

          return (
            <span className="form-switch form-switch-md me-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                checked={cellProps.row.original.primarycontact == 'on'}
                onChange={() => {}}
              />
            </span>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div>
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={customer_contact || []}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewContacts
