import React, { useState } from "react"
import { Container, Row, Col, Form } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"
import Loader from "pages/Separate/Loader"

const Education = ({ setverticalActiveTab }) => {
  // for education form
  const [formRows, setFormRows] = useState([{ id: 1 }])
  const [fixButtons, setFixButtons] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [staffId, setStaffId] = useState(localStorage.getItem("staffId"))
  const [inputFields_1, setInputFields1] = useState([{}])
  const [institute, setInstitute] = useState("")
  const [field, setField] = useState("")
  const [year, setYear] = useState("")
  const [percentage, setPercentage] = useState("")

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // dynamic form add
  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setFormRows(modifiedRows)
    setInputFields1([...inputFields_1, {}])
  }

  const onDeleteFormRow = id => {
    if (id !== 1) {
      var modifiedRows = [...formRows]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setFormRows(modifiedRows)
      setInputFields1(modifiedRows)
    }
  }

  const onInstituteName = (e, key) => {
    console.log(e.target.value, key)
    const values = [...inputFields_1]
    // console.log("values", values)
    values[key][e.target.name] = e.target.value
    let first_item
    setInstitute(
      values.map(item => {
        first_item = item.institute
        return first_item
      })
    )
  }
  const onFieldName = (e, key) => {
    const values = [...inputFields_1]
    values[key][e.target.name] = e.target.value
    let first_item
    setField(
      values.map(item => {
        first_item = item.field
        return first_item
      })
    )
  }
  const onYearName = (e, key) => {
    const values = [...inputFields_1]
    values[key][e.target.name] = e.target.value
    let first_item
    setYear(
      values.map(item => {
        first_item = item.year
        return first_item
      })
    )
  }
  const onPercentageName = (e, key) => {
    const values = [...inputFields_1]
    values[key][e.target.name] = e.target.value
    let first_item
    setPercentage(
      values.map(item => {
        first_item = item.percentage
        return first_item
      })
    )
  }
  // console.log("institute", institute)

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("staff_id", staffId)
    formData.append("board", institute?.toString())
    formData.append("field", field?.toString())
    formData.append("year", year?.toString())
    formData.append("class", percentage?.toString())

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staffedu`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log(response)
        setLoading(false)
        Swal.fire({
          icon: "success",
          text: "Staff Education details Added Successfully",
        }).then(data => {
          // setverticalActiveTab("5")
        })
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <>
      {/* fields */}
      {isLoading && <Loader />}
      <Form
        className="repeater"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <div
          className={`flex-column fixed-buttons pt-2 ${
            fixButtons ? "d-flex" : "d-none"
          }`}
        >
          <button className="btn btn-save fix-btn btn-label pe-0">
            <i className="fas fa-save me-2 label-icon"></i>
            <span>Save</span>
          </button>
          <a
            onClick={() => navigate(-1)}
            className="btn btn-red fix-btn btn-label pe-0"
          >
            <i className="dripicons-cross me-2 icon-center label-icon"></i>
            <span>Cancel</span>
          </a>
          <button
            onClick={e => {
              e.preventDefault()
              onAddFormRow()
            }}
            className="btn  btn-save fix-btn btn-label pe-0"
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>
            <span>Add</span>
          </button>
        </div>
        <div className="p-4 pb-0 bg-white shadow">
          <Row>
            <div className="col-md-6">
              <h4 className="text-dark">Education</h4>
            </div>
            <div className="col-md-6  text-md-end">
              <button
                type="button"
                onClick={e => {
                  e.preventDefault()
                  onAddFormRow()
                }}
                className="btn  btn-save me-2 btn-label"
              >
                <i className="fas fa-plus me-1 icon-size label-icon"></i>
                Add Education Details
              </button>
              <button className=" btn btn-save me-md-0 me-2 btn-label">
                <i className="fas fa-save me-2 label-icon"></i>SAVE
              </button>
              <button
                onClick={() => navigate(-1)}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
          </Row>
        </div>
        {(formRows || []).map((formRow, key) => (
          <div key={key} className="mb-3 p-4 bg-white shadow">
            <Row className="align-items-end ">
              <div className="col-md-5 mb-3">
                <label htmlFor="">Board/Institute</label>
                <input
                  type="text"
                  placeholder="Enter Board/Institute"
                  className="form-control "
                  id=""
                  name="institute"
                  onChange={e => onInstituteName(e, key)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Field</label>
                <input
                  type="text"
                  placeholder="Enter Field"
                  className="form-control "
                  id=""
                  name="field"
                  onChange={e => onFieldName(e, key)}
                />
              </div>
              <div className="col-md-3 mb-3">
                <Row>
                  <div className="col-6">
                    <label htmlFor="">Year</label>
                    <input
                      type="text"
                      className="form-control "
                      id=""
                      name="year"
                      onChange={e => onYearName(e, key)}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="">Percentage</label>
                    <input
                      type="text"
                      className="form-control "
                      id=""
                      name="percentage"
                      onChange={e => onPercentageName(e, key)}
                    />
                  </div>
                </Row>
              </div>
              <div
                className={`col-md-1 mb-4 ${key === 0 ? "d-none" : "d-block"}`}
              >
                <button
                  onClick={e => {
                    e.preventDefault()
                    onDeleteFormRow(formRow.id)
                  }}
                  className="btn btn-red"
                >
                  <i className="fas fa-trash-alt "></i>
                </button>
              </div>
            </Row>
          </div>
        ))}
      </Form>
    </>
  )
}

export default Education
