import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Row, Form, Modal } from "reactstrap"
import Select from "react-select"
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// assets
import "../../../../assets/css/Sales/Sales.css"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"

const Profile = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // small modal
  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const commissionStructure = [
    { label: "Percentage", value: "percentage" },
    { label: "Flat", value: "flat" },
  ]

  const [location_option, setLocationOption] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [country_option, setCountryOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])
  const [country, setCountry] = useState([])
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [zipcode, setZipCode] = useState("")
  const [source_option, setSourceOption] = useState([])
  const [assign_option, setAssignOption] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [location, setLocation] = useState([])
  const [source, setSource] = useState([])
  const [assigned, setAssigned] = useState([])
  const [status, setStatus] = useState([])
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [address, setAddress] = useState("")
  const [description, setDescription] = useState("")
  const [commission_structure, setCommissionStructure] = useState([])

  useEffect(() => {
    FetchProposalData()
  }, [])

  const params = useParams()

  const onChangeCountry = async e => {
    setLoading(true)
    setLocation(e)
    const fromData = new FormData()
    fromData.append("country_id", e.value)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/lead_belong`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setSourceOption(
          res.data.data.lead_source?.map(item => ({
            value: item.id,
            label: item.leadname,
          }))
        )
        setAssignOption(
          res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setStatusOption(
          res.data.data.lead_status.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const FetchProposalData = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("introducer_id", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/view_introducer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        // console.log("introducer", response)

        setName(response.data.data[0].company)
        setPhone(response.data.data[0].phone_number)
        setEmail(response.data.data[0].email_address)
        setWebsite(response.data.data[0].website)
        setAddress(response.data.data[0].address)
        setZipCode(response.data.data[0].zipcode)
        setDescription(response.data.data[0].description)
        setCommissionStructure(
          commissionStructure.filter(
            obj => obj.value == response.data.data[0].Commissionstructure
          )[0]
        )

        await axios({
          method: "get",
          url: `${configure.apiUrl}/locations`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data?.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
          setLocationOption(option)
          setLocation(
            option.filter(
              obj => obj.value == response.data.data[0].leadbelogto
            )[0]
          )
        })

        const formData = new FormData()
        formData.append("country_id", response.data.data[0].leadbelogto)
        const res = await axios({
          method: "post",
          url: `${configure.apiUrl}/lead_belong`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data.lead_source?.map(item => ({
            value: item.id,
            label: item.leadname,
          }))
          setSourceOption(option)
          setSource(
            option.filter(obj => obj.value == response.data.data[0].source)[0]
          )
          const options2 = res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
          setAssignOption(options2)
          setAssigned(
            options2.filter(
              obj => obj.value == response.data.data[0].assigned
            )[0]
          )

          const option3 = res.data.data.lead_status.map(item => ({
            value: item.id,
            label: item.name,
          }))
          setStatusOption(option3)
          setStatus(
            option3.filter(obj => obj.value == response.data.data[0].status)[0]
          )
        })

        await axios({
          method: "get",
          url: `${configure.apiUrl}/country`,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const option = res.data.data.country?.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
          setCountryOption(option)
          setCountry(
            option.filter(obj => obj.value == response.data.data[0].country)[0]
          )
        })

        const formDatas = new FormData()
        formDatas.append("country_id", response.data.data[0].country)
        const ress = await axios({
          method: "post",
          url: `${configure.apiUrl}/country_state`,
          data: formDatas,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const options = res.data.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
          setStateOption(options)
          setState(
            options.filter(obj => obj.value == response.data.data[0].state)[0]
          )
        })

        const formData2 = new FormData()
        formData2.append("state_id", response.data.data[0].state)
        const res2 = await axios({
          method: "post",
          url: `${configure.apiUrl}/state_city`,
          data: formData2,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          const options = res.data.data?.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
          setCityOption(options)
          setCity(
            options.filter(obj => obj.value == response.data.data[0].city)[0]
          )
        })

        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const getState = async e => {
    setLoading(true)
    setCountry(e)
    const formData = new FormData()
    formData.append("country_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/country_state`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setStateOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getCities = async e => {
    setLoading(true)
    setState(e)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/state_city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCityOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const EditIntroducer = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("introducer_id", params.id)
    formData.append("leadbelogto", location?.value)
    formData.append("source", source?.value)
    formData.append("assigned", assigned?.value)
    formData.append("status", status?.value)
    formData.append("company", name)
    formData.append("phone_number", phone)
    formData.append("email", email)
    formData.append("website", website)
    formData.append("address", address)
    formData.append("country", country?.value)
    formData.append("state", state?.value)
    formData.append("city", city?.value)
    formData.append("zipcode", zipcode)
    formData.append("description", description)
    formData.append("commissionstructure", commission_structure?.value)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/edit_introducer`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Introducer Edited Succesfully",
        }).then(data => {
          navigate(`${configure.appUrl}sales/introducer`)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
        <Form onSubmit={EditIntroducer}>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <button className="btn btn-save fix-btn btn-label pe-0">
              <i className="fas fa-save me-2 label-icon"></i>
              <span>Save</span>
            </button>
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <div className="col-md-12 mb-3 text-end">
              <button className=" btn ms-md-2 btn-save btn-label">
                <i className="fas fa-save me-1 label-icon"></i> save
              </button>
              <button
                onClick={e => {
                  e.preventDefault()
                  navigate(-1)
                }}
                className=" btn ms-md-2 btn-red btn-label"
              >
                <i className="dripicons-cross me-2 icon-center label-icon"></i>
                CANCEL
              </button>
            </div>
            <div className="d-flex">
              <div className="contact-column mb-3 mb-md-0">
                <label htmlFor="">
                  Location <span className="text-danger">*</span>{" "}
                </label>
                <Select
                  placeholder="Select Location"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={location_option}
                  value={location}
                  onChange={onChangeCountry}
                />
              </div>
              <div className="contact-column mb-3 mb-md-0">
                <label htmlFor="">
                  Source <span className="text-danger">*</span>{" "}
                </label>
                <Select
                  placeholder="Select Source"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={source_option}
                  value={source}
                  onChange={e => setSource(e)}
                />
              </div>
              <div className="contact-column mb-3 mb-md-0">
                <label htmlFor="">
                  Assigned <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder="Select Assigned"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={assign_option}
                  value={assigned}
                  onChange={e => setAssigned(e)}
                />
              </div>
              <div className="contact-column mb-3 mb-md-0">
                <label htmlFor="">
                  Status <span className="text-danger">*</span>
                </label>
                <Select
                  placeholder="Select Status"
                  className="basic-multi-select"
                  classNamePrefix=" select"
                  styles={colourStyles}
                  options={status_option}
                  value={status}
                  onChange={e => setStatus(e)}
                />
              </div>
            </div>
          </Row>

          {/* Introducer info */}
          <Row className="bg-white px-2 py-4 shadow mb-3">
            <h5 className="mb-3">Introducer Information</h5>

            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Name</label>
              <input
                type="text"
                placeholder="Enter Name"
                className="form-control "
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Phone</label>
              <input
                type="text"
                placeholder="Enter Phone"
                className="form-control "
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Email Address</label>
              <input
                type="text"
                placeholder="Enter Email Address"
                className="form-control "
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-3 mt-2 mb-3">
              <label htmlFor="">Website</label>
              <input
                type="text"
                placeholder="Enter Website"
                className="form-control "
                value={website}
                onChange={e => setWebsite(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Country</label>
              <Select
                placeholder="Select Location"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={country_option}
                value={country}
                onChange={getState}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">State</label>
              <Select
                placeholder="Select State"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={state_option}
                value={state}
                onChange={getCities}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">City </label>
              <Select
                placeholder="Select City"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={city_option}
                value={city}
                onChange={e => setCity(e)}
              />
            </div>

            <div className="col-md-3 mb-3">
              <label htmlFor="">Zipcode</label>
              <input
                type="text"
                placeholder="Enter Zipcode"
                className="form-control "
                value={zipcode}
                onChange={e => setZipCode(e.target.value)}
              />
            </div>

            <div className="col-md-9 mb-3">
              <label htmlFor="">Address</label>
              <input
                type="text"
                placeholder="Enter Address"
                className="form-control "
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Commission-Structure</label>
              <Select
                placeholder="Select"
                className="basic-multi-select"
                classNamePrefix=" select"
                styles={colourStyles}
                options={commissionStructure}
                value={commission_structure}
                onChange={e => setCommissionStructure(e)}
              />
            </div>
            <div className="col-12 mb-md-0 mb-3">
              <label htmlFor="">Description </label>
              <CKEditor
                editor={ClassicEditor}
                data={description != null ? description : ""}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setDescription(data)
                }}
              />
            </div>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default Profile
