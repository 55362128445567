import React, { useMemo, useState, useEffect } from "react"
import { Table, Row, Col, Card, CardBody, Modal, Form } from "reactstrap"
import { Link } from "react-router-dom"
import { useTable, useGlobalFilter, usePagination } from "react-table"
import { useNavigate } from "react-router-dom"
// css
import "../../../assets/css/Settings/Setting.css"
// common component
import Pagination from "components/Common/Pagination"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"

const Department = () => {
  const [add_modal, setAddModal] = useState(false)
  const [edit_modal, setEditModal] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [view_modal, setViewModal] = useState(false)
  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // Center Modal
  function tog_center() {
    setAddModal(false)
    setEditModal(false)
    removeBodyCss()
  }

  const [department, setDepartment] = useState("")
  const [department_id, setDepartmentId] = useState("")

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        className: "id-width-roles",
      },
      {
        Header: "Department Name",
        accessor: "department_name",
        isInfoIcon: true,
        className: "role-name-width",
      },
      {
        Header: "Action",
        accessor: "action",
        isInfoIcon: true,
        className: "action-width-roles",
        Cell: cellProps => {
          const onDeleteDepartment = async e => {
            swal({
              title: "Are you sure?",
              text: "You want to Delete this Department !",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                e.preventDefault()
                setLoading(true)
                const formData = new FormData()
                formData.append("id", cellProps.row.values.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: "https://api.zithas.com/api/deleteDepartments",
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Department Deleted Successfully",
                    }).then(data => {
                      setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          return (
            <div>
              <button
                onClick={() => {
                  setEditModal(true)
                  setDepartmentId(cellProps.row.original.id)
                  setDepartment(cellProps.row.original.department_name)
                }}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
              <a
                onClick={onDeleteDepartment}
                className="btn btn-sm action-btn btn-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: "https://api.zithas.com/api/departments",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        setDatas(response.data.data.department)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const onAddDepartment = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("department", department)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.zithas.com/api/addDepartments",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Department Added Successfully",
        }).then(data => {
          setDatas(response.data.data)
          setAddModal(false)
        })
        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
      swal({
        icon: "error",
        text: "Something Went Wrong",
      })
    }
  }

  const onEditDepartment = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("id", department_id)
    formData.append("department", department)
    try {
      const response = await axios({
        method: "post",
        url: "https://api.zithas.com/api/editDepartments",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        swal({
          icon: "success",
          text: "Department Edited Successfully",
        }).then(data => {
          setDatas(response.data.data)
          setEditModal(false)
        })
        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
      swal({
        icon: "error",
        text: "Something Went Wrong",
      })
    }
  }

  return (
    <>
      <div>
        {isLoading && <Loader />}
        <Row className="ms-0 pe-0">
          <Col lg={12} className="p-0">
            <Card>
              <CardBody>
                <div>
                  <div
                    className={`flex-column fixed-buttons ${
                      fixedContent ? "d-flex" : "d-none"
                    }`}
                  >
                    <button
                      onClick={() => {
                        setAddModal(true)
                      }}
                      className="btn btn-purple border-radius fix-btn"
                    >
                      <i className="fas fa-plus me-2 icon-size"></i>
                      <span>Add</span>
                    </button>
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-red border-radius fix-btn"
                    >
                      <i className="dripicons-cross me-2 icon-center"></i>
                      <span>Cancel</span>
                    </button>
                  </div>
                  <Row className="mb-2">
                    <Col>
                      <select
                        className="border-radius form-select"
                        style={{ width: "100px" }}
                        value={pageSize}
                        onChange={e => setPageSize(e.target.value)}
                      >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={8} className="d-flex justify-content-end">
                      <div className="me-4">
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => setGlobalFilter(e.target.value)}
                                id="search-bar-0"
                                type="text"
                                className="border-radius form-control rounded h-100"
                                placeholder={` Search...`}
                                value={globalFilter || ""}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <button
                          onClick={() => {
                            setAddModal(true)
                          }}
                          className="border-radius btn btn-purple"
                        >
                          <i className="fas fa-plus me-2 icon-size"></i>
                          Department
                        </button>
                        <button
                          onClick={() => navigate(-1)}
                          className="border-radius btn ms-md-2 btn-red"
                        >
                          <i className="dripicons-cross me-2 icon-center"></i>
                          CANCEL
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Table
                    className="table table-bordered mt-4"
                    {...getTableProps()}
                  >
                    <thead
                      className={`${
                        fixedContent ? "fixed-setting-header" : ""
                      }`}
                    >
                      {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, index) => (
                            <th
                              key={index}
                              className={column.className}
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                              {column.isInfoIcon ? (
                                <a
                                  onClick={() => {
                                    // if(column)
                                    if (column.Header == "Department Name") {
                                      setViewModal(true)
                                    } else if (column.Header == "Action") {
                                      setActionModal(true)
                                    }
                                  }}
                                >
                                  <i
                                    title="info"
                                    className="bx bx-info-circle ms-1 info-icon"
                                  ></i>
                                </a>
                              ) : null}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row)
                        return (
                          <tr
                            className="row-hover"
                            {...row.getRowProps()}
                            key={i}
                          >
                            {row.cells.map((cell, index) => {
                              return (
                                <td
                                  className={cell.column.className}
                                  key={index}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>

                  {/* Pagination */}
                  <Row className="justify-content-md-end justify-content-center align-items-center">
                    <Pagination
                      gotoPage={gotoPage}
                      previousPage={previousPage}
                      canPreviousPage={canPreviousPage}
                      nextPage={nextPage}
                      canNextPage={canNextPage}
                      pageIndex={pageIndex}
                      pageOptions={pageOptions}
                    />
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Add Department Modal */}
      <Modal
        isOpen={add_modal}
        toggle={() => {
          tog_center()
        }}
        centered
        // size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Department</h5>
          <button
            type="button"
            onClick={() => {
              setAddModal(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onAddDepartment}>
            <Row>
              <div className="col-11">
                <label htmlFor="">
                  Department Name
                  <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control border-radius"
                  required
                  onChange={e => setDepartment(e.target.value)}
                />
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className="btn btn-purple border-radius">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}

      {/* Edit Department Modal */}
      <Modal
        isOpen={edit_modal}
        toggle={() => {
          tog_center()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Department</h5>
          <button
            type="button"
            onClick={() => {
              setEditModal(false)
            }}
            className="btn-modal-close"
          >
            <span className="btn-modal-span">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={onEditDepartment}>
            <Row>
              <div className="col-10">
                <label htmlFor="">
                  Department Name<span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control border-radius"
                  required
                  defaultValue={department}
                  onChange={e => setDepartment(e.target.value)}
                />
              </div>
            </Row>
            <div className="border-bottom my-3"></div>
            <div className="text-end">
              <button className="btn btn-purple border-radius">Submit</button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* ------------ */}

      <Modal
        isOpen={view_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Department Name Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setViewModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Department
