import React, { useState } from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import {
    Row,
    Form
} from "reactstrap";
import Select from 'react-select';

// editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// assets
import '../../../assets/css/Sales/Sales.css';

const AddColdCalling = () => {
    const [fixButtons, setFixButtons] = useState(false);

    const navigate = useNavigate();

    document.title = "Add Coldcalling | Zithas Crm"

    document.addEventListener('scroll', () => {
        if (window.scrollY > 160) {
            setFixButtons(true);
        }
        else {
            setFixButtons(false);
        }
    })

    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white',
        }),
        menu: base => ({
            ...base,
            zIndex: 50
        })
    }

    const location = [
        { label: "India", value: "1" },
        { label: "United kingdom", value: "2" }
    ];

    const source = [
        { label: "Introducer", value: "1" },
        { label: "Customer", value: "2" },
        { label: "Existing", value: "3" },
        { label: "Reference", value: "4" },
        { label: "facebook", value: "5" }
    ];

    const status = [
        { label: "Test - 1", value: "1" },
        { label: "Meeting", value: "2" },
        { label: "Convert to Customer", value: "3" },
        { label: "Convert to Introducer", value: "4" },
        { label: "Convert to Project", value: "5" },
        { label: "Lost", value: "6" }
    ];

    const roles = [
        { label: "Key Account Manager", value: "1" },
        { label: "Project", value: "2" },
        { label: "Marketing", value: "3" },
        { label: "Developer", value: "4" },
        { label: "Finance", value: "5" },
        { label: "Managing Director", value: "6" },
        { label: "Coo Dashboard", value: "7" },
        { label: "The Staffguru", value: "8" },
        { label: "Default", value: "9" }
    ];

    return (
        <React.Fragment>
            <div className="page-content mb-3">
                <Container fluid>
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"Add Coldcalling"}
                    />
                    <Row className='px-1'>
                        <Form>
                            {/* Fixed Buttons */}
                            <div className={`flex-column fixed-buttons ${fixButtons ? 'd-flex' : 'd-none'}`}>
                                <button className='btn btn-purple border-radius fix-btn'>
                                    <i className='fas fa-save me-2'></i>
                                    <span>Save</span>
                                </button>
                                <a onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                                    <i className='dripicons-cross me-2 icon-center'></i>
                                    <span>Cancel</span>
                                </a>
                            </div>
                            <Row className='bg-white px-2 py-4 shadow mb-3'>
                                <div className="col-md-12 mb-3 text-end">
                                    <button className="border-radius btn ms-md-2 btn-purple">
                                        <i className='fas fa-save me-1'></i> save
                                    </button>
                                    <button onClick={(e) => {
                                        e.preventDefault()
                                        navigate(-1)
                                    }} className="border-radius btn ms-md-2 btn-red"><i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                                </div>
                                <Row className='pe-0'>
                                    <div className="col-md-3 mb-md-0 mb-3">
                                        <label htmlFor="">Source <span className='text-danger'>*</span> </label>
                                        <Select
                                            placeholder="Select Source"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={source} />
                                    </div>
                                    <div className="col-md-3 mb-md-0 mb-3">
                                        <label htmlFor="">Assigned <span className='text-danger'>*</span></label>
                                        <Select
                                            placeholder="Select Assigned"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={roles} />
                                    </div>
                                </Row>
                            </Row>

                            {/* Company info */}
                            <Row className='bg-white px-2 py-4 shadow mb-3'>
                                <h5 className="mb-3">Company Information</h5>

                                <div className='col-md-3 mt-2 mb-md-0 mb-3'>
                                    <label htmlFor="">Company</label>
                                    <input type="text" placeholder='Enter Company' className='form-control border-radius' />
                                </div>
                                <div className='col-md-3 mt-2 mb-md-0 mb-3'>
                                    <label htmlFor="">Website</label>
                                    <input type="text" placeholder='Enter Website' className='form-control border-radius' />
                                </div>
                                <div className='col-md-3 mt-2 mb-md-0 mb-3'>
                                    <label htmlFor="">Email Address</label>
                                    <input type="text" placeholder='Enter Email Address' className='form-control border-radius' />
                                </div>
                                <div className='col-md-3 mt-2 mb-md-0 mb-3'>
                                    <label htmlFor="">Phone</label>
                                    <input type="text" placeholder='Enter Phone' className='form-control border-radius' />
                                </div>
                            </Row>

                            {/* Address */}
                            <Row className='bg-white px-2 py-4 shadow mb-3'>
                                <h5 className="mb-3">Address</h5>

                                <div className="col-md-4 mb-3">
                                    <label htmlFor="">Country</label>
                                    <Select
                                        placeholder="Select Location"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={location} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="">State</label>
                                    <Select
                                        placeholder="Select State"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={location} />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="">City </label>
                                    <Select
                                        placeholder="Select City"
                                        className="basic-multi-select"
                                        classNamePrefix="border-radius select"
                                        styles={colourStyles}
                                        options={location} />
                                </div>
                                <div className='col-md-4 mb-3'>
                                    <label htmlFor="">Pincode</label>
                                    <input type="text" placeholder='Enter Pincode' className='form-control border-radius' />
                                </div>
                                <div className='col-md-8 mb-3'>
                                    <label htmlFor="">Address</label>
                                    <input type="text" placeholder='Enter Address' className='form-control border-radius' />
                                </div>
                                <div className="col-12 mb-md-0 mt-2 mb-3">
                                    <label htmlFor="">Description </label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data="<p></p>"
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                        }}
                                    />
                                </div>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddColdCalling