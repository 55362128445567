import React, { useEffect, useState } from "react"
import {
  Container,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import logImg from "../../../assets/images/EmailLogs.jpg"

// common component
import Pagination from "components/Common/Pagination"
import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import validate from "namor/dist/validate"

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  // Render the UI for your table
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className="border-radius me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <button
              onClick={() => navigate(-1)}
              className="border-radius btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>

      <div className="row">
        <div className="col-md-3 d-flex justify-content-center pt-4">
          <div className={`${fixedContent ? "logImg" : "pt-5"}`}>
            <img src={logImg} alt="oppImg" className="w-100" />
          </div>
        </div>
        <div className="col-md-9">
          <div className="position-relative">
            <Table
              className="table table-bordered mt-4 importLogs"
              {...getTableProps()}
            >
              <thead
                className={`${
                  fixedContent ? "fix-header-logs" : ""
                } fix-viewdatabank-logs`}
              >
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        key={index}
                        className={`t-col-${index + 1} t-col-${index + 1} ${
                          column.className
                        }`}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                        {column.isInfoIcon ? (
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        ) : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="fix-viewdatabank-logs" {...getTableBodyProps()}>
                {page.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No data in table
                    </td>
                  </tr>
                ) : (
                  page.map((row, i) => {
                    prepareRow(row)
                    return (
                      <tr className="row-hover" {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              key={index}
                              className={`t-col-${index + 1} ${
                                cell.column.className
                              }`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </div>
  )
}

const ImportLogs = () => {
  const [allIndustries, setAllIndustries] = useState([])
  const [loading, setLoading] = useState(false)
  const [viewValidMails, setValidMails] = useState(false)
  const [emailsType, setEmailsType] = useState("")
  const [emailsData, setEmailsData] = useState([])

  //meta title
  document.title = "View Opportunity | Zithas Crm"

  const { industry_id } = useParams()

  const toggleViewmail = () => {
    setEmailsData([])
    setValidMails(!viewValidMails)
  }

  const isValidEmails = async ({ status, fileId }) => {
    setValidMails(true)
    try {
      const formData = new FormData()
      formData.append("status", status)
      formData.append("file_id", fileId)
      setLoading(true)
      const response = await axios.post(
        `${configure.apiUrl}/logsData`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (response) {
        setLoading(false)
        const combinedData = [
          ...response.data.data.data1,
          ...response.data.data.data2,
        ]
        setEmailsData(combinedData)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllImportData()
  }, [])

  const statusOptions = [
    { label: "In Progress", value: "0" },
    { label: "Voicemail but not given", value: "1", isModal: true },
    { label: "Not interested", value: "2", isModal: true },
    { label: "No received", value: "3", isModal: false },
    { label: "Some wrong number", value: "4", isModal: false },
    {
      label: "Gave a specific time to talk( mention the time and date)",
      value: "5",
      isModal: true,
    },
    {
      label: "Call connected but some digital person was speaking",
      value: "6",
      isModal: false,
    },
    { label: "Call the head office", value: "7", isModal: true },
    { label: "Voicemail given", value: "8", isModal: false },
    { label: "No option to talk with anyone", value: "9", isModal: false },
    { label: "Call Later", value: "10", isModal: true },
    { label: "They don't need it", value: "11", isModal: true },
    { label: "Owner not available", value: "12", isModal: true },
    { label: "Number not in service", value: "13", isModal: false },
    { label: "They will call us back", value: "14", isModal: false },
    { label: "They have it", value: "15", isModal: true },
    { label: "Others", value: "16", isModal: true },
  ]

  const fetchAllImportData = async () => {
    setLoading(true)
    try {
      const res = await axios.get(`${configure.apiUrl}/importLogs`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      setAllIndustries(res.data.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        // isInfoIcon: false,
        className: "text-center",
        Cell: cellProps => <span>{cellProps.row.index + 1}</span>,
      },
      {
        Header: "Filename",
        accessor: "file_name",
        // isInfoIcon: true,
        Cell: cellProps => {
          return (
            <span
              title={cellProps.row.original.file_name}
              className="cursor-pointer"
            >
              {/* {cellProps.row.original.file_name !== null
                ? cellProps.row.original.file_name?.slice(0, 30) + "..."
                : ""} */}
              {cellProps.row.original.file_name !== null &&
                (cellProps.row.original.file_name.length > 15
                  ? cellProps.row.original.file_name.slice(0, 15) + "..."
                  : cellProps.row.original.file_name)}
            </span>
          )
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        // isInfoIcon: true,
        className: "text-center px-0",
        Cell: cellProps => (
          <span>
            {moment(cellProps.row.original.updated_at).format("DD-MM-YYYY")}
          </span>
        ),
      },
      {
        Header: "Total Emails",
        accessor: "ecount",
        // isInfoIcon: true,
        className: "text-center px-0",
      },
      {
        Header: "Valid Emails",
        accessor: "is_valid",
        // isInfoIcon: true,
        className: "text-center px-0",
      },
      {
        Header: "Invalid Emails",
        accessor: "not_valid",
        className: "text-center px-0",
        // isInfoIcon: true,
      },
      {
        Header: "Status",
        accessor: "",
        className: "text-center px-0",
        // isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {cellProps.row.original.dcount == 0 && (
                <span className="bg-danger badge bg-secondary">In Queue</span>
              )}
              {cellProps.row.original.dcount != 0 &&
                (cellProps.row.original.dcount ===
                  cellProps.row.original.row_count ||
                  cellProps.row.original.dcount >
                    cellProps.row.original.row_count) && (
                  <span className="bg-success badge bg-secondary">Done</span>
                )}
              {cellProps.row.original.dcount > 0 &&
                cellProps.row.original.dcount <
                  cellProps.row.original.row_count && (
                  <span className="bg-warning badge bg-secondary">
                    In Progress
                  </span>
                )}
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center px-0",
        Cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link
                    // to={`${configure.appUrl}sales/edit-lead/${cellProps.cell?.row?.original.id}`}
                    title="Valid Emails"
                    className="dropdown-item"
                    onClick={() => {
                      setEmailsType("Valid Emails")
                      isValidEmails({
                        status: 1,
                        fileId: cellProps.row.original.id,
                      })
                    }}
                  >
                    <i className="bi bi-eye-fill pr-10"></i> View Valid Emails
                  </Link>
                  <Link
                    // to={`${configure.appUrl}sales/edit-lead/${cellProps.cell?.row?.original.id}`}
                    title="Invalid Emails"
                    className="dropdown-item"
                    // onClick={() => setInValidMails(true)}
                    onClick={() => {
                      setEmailsType("Invalid Emails")
                      isValidEmails({
                        status: 0,
                        fileId: cellProps.row.original.id,
                      })
                    }}
                  >
                    <i className="bi bi-eye-fill pr-10"></i> View Invalid Emails
                  </Link>
                  <Link
                    // to={`${configure.appUrl}sales/edit-lead/${cellProps.cell?.row?.original.id}`}
                    title="Get Mautic Emails"
                    className="dropdown-item"
                    // onClick={() => setViewMauticMails(true)}
                    onClick={() => {
                      setEmailsType("Mautic Emails")
                      isValidEmails({
                        status: 1,
                        fileId: cellProps.row.original.id,
                      })
                    }}
                  >
                    <i className="bi bi-eye-fill pr-10"></i> View Get Mautic
                    Emails
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = useMemo(() => allIndustries, [allIndustries])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Import Logs"} />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer columns={columns} data={data} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={viewValidMails} centered id="ImportLogs">
        <div id="modalToggle" className="z-3">
          <button
            onClick={toggleViewmail}
            className="modalToggle btn shadow border-0 "
          >
            <i className="fas fa-times"></i>
          </button>
        </div>
        <ModalBody>
          <div>
            <h3>{emailsType}</h3>
          </div>
          <div
            className="position-relative overflowy-scroll"
            style={{ height: "80vh" }}
          >
            <div className="">
              <Table className="table table-bordered mt-4 ">
                <thead>
                  <tr>
                    <th className="">Company Name</th>
                    <th className="">Emails</th>
                  </tr>
                </thead>
                <tbody>
                  {emailsData?.map((ob, i) => {
                    return (
                      <tr key={i}>
                        <td
                          className={` ${ob.name == null ? "text-center" : ""}`}
                        >
                          {ob.name !== null ? ob.name : "-"}
                        </td>
                        <td className="">{ob.email}</td>
                      </tr>
                    )
                  })}
                  {emailsData.length == 0 && (
                    <tr>
                      <td colSpan={8} className="text-center">
                        No data in table
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ImportLogs
