import React, { useState } from "react"
import { Row, Form } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"

const ViewProfile = ({ introducer_profile }) => {
  return (
    <React.Fragment>
      <div className="mb-3" style={{ padding: "0 11px 0 12px" }}>
        <Form>
          <div className="bd-span-input">
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <div className="d-flex">
                <div className="contact-column mb-3 mb-md-0">
                  <label htmlFor="">Location </label>
                  <div className="input-span input">
                    {introducer_profile[0]?.location}
                  </div>
                </div>
                <div className="contact-column mb-3 mb-md-0">
                  <label htmlFor="">Source </label>
                  <div className="input-span input">
                    {introducer_profile[0]?.source_name || "-"}
                  </div>
                </div>
                <div className="contact-column mb-3 mb-md-0">
                  <label htmlFor="">Assigned </label>
                  <div className="input-span input">
                    {introducer_profile[0]?.assigned_name || "-"}
                  </div>
                </div>
                <div className="contact-column mb-3 mb-md-0">
                  <label htmlFor="">Status </label>
                  <div className="input-span input">
                    {introducer_profile[0]?.status_name || "-"}
                  </div>
                </div>
              </div>
            </Row>

            {/* Introducer info */}
            <Row className="bg-white px-2 py-4 shadow mb-3">
              <h5 className="mb-3">Introducer Information</h5>

              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Name</label>
                <div className="input-span input">
                  {introducer_profile[0]?.company}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Phone</label>
                <div className="input-span input">
                  {introducer_profile[0]?.phone_number}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Email Address</label>
                <div className="input-span input">
                  {introducer_profile[0]?.email_address}
                </div>
              </div>
              <div className="col-md-3 mt-2 mb-3">
                <label htmlFor="">Website</label>
                <div className="input-span input">
                  {introducer_profile[0]?.website}
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Country</label>
                <div className="input-span input">
                  {introducer_profile[0]?.country_name || "-"}
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">State</label>
                <div className="input-span input">
                  {introducer_profile[0]?.state_name || "-"}
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">City </label>
                <div className="input-span input">
                  {introducer_profile[0]?.city_name || "-"}
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">ZipCode </label>
                <div className="input-span input">
                  {introducer_profile[0]?.zipcode || "-"}
                </div>
              </div>
              <div className="col-md-9 mb-3">
                <label htmlFor="">Address</label>
                <div className="input-span input">
                  {introducer_profile[0]?.address}
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="">Commission-Structure</label>
                <div className="input-span input">Structure</div>
              </div>
              <div className="col-12 mb-md-0 mb-3">
                <label htmlFor="">Description </label>
                <div className="input-span" style={{ height: "100px" }}>
                  <div
                    className="Features"
                    dangerouslySetInnerHTML={{
                      __html: introducer_profile[0]?.description,
                    }}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default ViewProfile
