import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Staff/Staff.css"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import KnowledgeCategory from "./KnowledgeCategory"
import KnowledgeSubCategory from "./KnowledgeSubCategory"
import configure from "configure"
import axios from "axios"

// components

const KnowledgeIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  document.title = "Knowledge Base | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [fixedSideTab, setFixedSideTab] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 60) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  // const fatchData = async () => {
  //   setLoading(true)
  //   console.log("knowledgeCategory")
  //   try {
  //     const response = await axios({
  //       method: "get",
  //       url: `${configure.apiUrl}/knowledgeCategory`,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(response => {
  //       console.log("knowledgeCategory", response)
  //       setData(response?.data?.data)
  //       setLoading(false)
  //     })
  //   } catch (err) {
  //     console.log(err)
  //     setLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   fatchData()
  // }, [])

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Knowledge Base" />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white  ${
                  fixedSideTab ? "fixed-side-tab" : ""
                } h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Category
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Sub Category
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="pe-0">
              <div className="card p-4 h-100">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0 h-100"
                >
                  <TabPane tabId="1">
                    <KnowledgeCategory />
                  </TabPane>
                  <TabPane tabId="2">
                    <KnowledgeSubCategory
                      verticalActiveTab={verticalActiveTab}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default KnowledgeIndex
