import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// assets
import "../../../../../assets/css/Sales/Sales.css"
import axios from "axios"
import configure from "configure"
import moment from "moment"

const ActivityLog = props => {
  const [fixButtons, setFixButtons] = useState(false)
  const [activities, setActivities] = useState([])

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  // getting all followups
  useEffect(() => {
    const getActLog = async () => {
      try {
        const formData = new FormData()
        formData.append("opportunity_id", props.company_id)
        formData.append("user_id", localStorage.getItem("auth_id"))

        const response = await axios.post(
          `${configure.apiUrl}/opportunity_activity_log_data`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        setActivities(response.data.data.opportunity_activity)
      } catch (error) {
        console.log(error)
      }
    }
    if (props.verticalActiveTab == "5") {
      getActLog()
    }
  }, [props.verticalActiveTab])

  return (
    <React.Fragment>
      <div>
        <div>
          {/* Fixed Buttons */}
          <div
            className={`flex-column fixed-buttons pt-2 ${
              fixButtons ? "d-flex" : "d-none"
            }`}
          >
            <a
              onClick={() => navigate(-1)}
              className="btn btn-red  fix-btn btn-label pe-0"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              <span>Cancel</span>
            </a>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h5 className="text-dark">My Activity</h5>
                    <div className="text-end">
                      <a
                        onClick={() => navigate(-1)}
                        className=" btn ms-md-2 btn-red btn-label"
                      >
                        <i className="dripicons-cross me-2 icon-center label-icon"></i>
                        CANCEL
                      </a>
                    </div>
                  </div>
                  <div>
                    {activities.length > 0 && (
                      <ul className="verti-timeline list-unstyled verti-timeline-xxxl">
                        {/* Activities */}
                        {activities.map((status, key) => (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <i
                                className={`fas fa-arrow-alt-circle-right timeline-icon fs-4 ${
                                  status.id === 3 ? "bx-fade-right" : ""
                                }`}
                              />
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <div>
                                  <h5>{status.description}</h5>
                                  <p className="text-muted">
                                    {"At " +
                                      moment(status.created_at).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      ) +
                                      " By " +
                                      status.added_by}
                                  </p>
                                </div>
                              </div>
                              <p className="timing">
                                {moment(status.created_at).format("hh:mm")}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ActivityLog
