import React, { useState } from "react"
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Loader from "pages/Separate/Loader"
import { Link, useNavigate } from "react-router-dom"
import ReactSelect from "react-select"

const EditTestimonial = () => {
  document.title = "Add Testimonial | Zithas Crm"
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = [
    // { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "India", value: "India" },
    { label: "United kingdom", value: "United kingdom" },
  ]
  const type = [
    // { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "Textual", value: "Textual" },
    { label: "Video", value: "Video" },
  ]
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  return (
    <div>
      <div className="page-content">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Edit Testimonial"}
          />
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <Col className="d-flex justify-content-end">
                        <div className="me-4"></div>
                        <div>
                          <button
                            className="border-radius btn btn-purple"
                            type="submit"
                          >
                            {" "}
                            {/* <i className="fas fa-plus me-2 icon-size"></i> */}
                            Publish
                          </button>
                          <button
                            onClick={() => navigate(-1)}
                            className="border-radius btn ms-md-2 btn-red"
                          >
                            <i className="dripicons-cross me-2 icon-center"></i>
                            CANCEL
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <div className="pt-4">
                      <div>
                        <Row>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">
                                Location <span className="text-danger">*</span>
                              </label>
                              <ReactSelect
                                name=""
                                id=""
                                required
                                className=""
                                placeholder="--- Please Select ---"
                                styles={colourStyles}
                                options={location}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Type</label>
                              <ReactSelect
                                name=""
                                id=""
                                required
                                className=""
                                placeholder="--- Please Select ---"
                                styles={colourStyles}
                                options={type}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Name</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Name"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Designation</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Designation"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Company Name</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Company Name"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Service</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Preffered Skills"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Company Logo</label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Company Logo"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Review Link </label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Review Link"
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className=" mb-3 pr-row">
                              <label htmlFor="">Video Link </label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Video Link "
                                className="form-control border-radius"
                                name="bank"
                              />
                            </div>
                          </div>
                        </Row>
                        <Row className="pt-3">
                          <Col md={6}>
                            <div className=" mb-3 pr-row ">
                              <label htmlFor="">Description</label>
                              <textarea
                                placeholder="Enter Description"
                                className="form-control border-radius"
                                rows="6"
                                // cols="50"
                                // value={note}
                                // onChange={e => setNote(e.target.value)}
                              ></textarea>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default EditTestimonial
