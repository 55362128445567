import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Modal,
  Form,
  Input,
} from "reactstrap"
import classnames from "classnames"

// import Profile from "./Profile"
// import Notes from "./Notes"
// import FollowUp from "./FollowUp"
// import ActivityLog from "./ActivityLog"

import BasicProjectDetails from "./BasicProjectDetails"
import Milestones from "./Milestones"
import Tasks from "./Tasks"
import Notes from "./Notes"
import ActivityLog from "./ActivityLog"

import axios from "axios"
import Loader from "pages/Separate/Loader"
import { useParams } from "react-router-dom"
import configure from "configure"
import moment from "moment/moment"
import Select from "react-select"
import ReactSelect from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import AddTask from "pages/Work/ResourcePlan/AddTask"
import EditDTask from "../Dedicated Resources/EditDTask"
import Credential from "./Credential"
// components

const EditDedicatedProject = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [projectName, setProjectName] = useState("")

  // Created at
  const [created_at, setCreatedAt] = useState("")

  //edit data
  const [data, setDatas] = useState([])
  const [editData, setEditData] = useState()
  const [loading, setLoading] = useState(false)
  let url = window.location.toString()

  const { editId } = useParams()



  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const [lead_data, setLeadData] = useState([])
  const [note_data, setNoteData] = useState([])
  const [log_data, setLogData] = useState([])
  const [follow_data, setFollowData] = useState([])
  const [assigned_data, setAssignData] = useState([])
  const [industry_option, setIndustryOption] = useState([])
  const [editTask, setEditTask] = useState()
  const [addTask, setAddTask] = useState(false)
  const [formRows, setFormRows] = useState([{}])

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setAddTask(false)
  })

  const milestone = [
    { label: "Milestone 1", value: "Milestone 1" },
    { label: "Milestone 2", value: "Milestone 2" },
    { label: "Milestone 3", value: "Milestone 3" },
    { label: "Milestone 4", value: "Milestone 4" },
    { label: "Milestone 5", value: "Milestone 5" },
  ]
  const Assigned = [
    { label: "Rahul", value: "Rahul" },
    { label: "Divyansh", value: "Divyansh" },
    { label: "Manav", value: "Manav" },
  ]
  const Priority = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ]
  const Estimation_Hours = [
    { label: "1 hour", value: "1 hour" },
    { label: "2 Hours", value: "2 Hours" },
    { label: "3 Hours", value: "3 Hours" },
  ]

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  // console.log("setLeadData", lead_data[0].created_at)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Edit Dedicated Project  | Zithas Technologies"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const timeDifference = new Date() - new Date(lead_data[0]?.created_at)
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  // console.log("lead_data", lead_data)

  const onAddTask = () => {
    console.log("Add Task")
  }

  const onAddMember = () => {
    console.log("Add Memebr")
  }

  const fetchData5 = async () => {
    setLoading(true)
    try {
      const res = await fetch(`${configure.apiUrl}/projectView/${editId}`)
        .then(res => res.json())
        .then(async response => {
          setLoading(false)
          setProjectName(response?.data?.project2[0]?.project_name)
        })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData5()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"Work"} breadcrumbItem={"Edit Dedicated Project"} />
          <Row className="py-4 shadow mb-3 bg-white me-0 d-flex justify-space-between align-items-center">
            <div className="col-md-8">
              <h4>
                <b>Project Title :- </b> {projectName ? projectName : "-"}
              </h4>
            </div>
            <div className="col-md-4 text-end">
              <a
                // to={`${configure.appUrl}sales/add-proposal`}
                onClick={() => setAddTask(true)}
                className="s btn text-white me-2 btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Task
              </a>
            </div>
          </Row>
          <Row className="justify-content-between d-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Basic Project Details
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Milestones
                  </NavLink>
                </NavItem> */}
                
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Tasks
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Credential
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10 pe-0">
              <div className=" bg-transparent mb-0" id="edit-project">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <BasicProjectDetails editId={editId} />
                  </TabPane>

                  {/* <TabPane tabId="2">
                    <Milestones />
                  </TabPane> */}

                  <TabPane tabId="3">
                    <EditDTask />
                  </TabPane>

                  <TabPane tabId="4">
                    <Notes />
                  </TabPane>

                  <TabPane tabId="5">
                    <ActivityLog />
                  </TabPane>

                  <TabPane tabId="6">
                    <Credential editId={editId} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


          {/* Add Task  */}            
          <div
        className={`note-sticky-form ${
          addTask ? "d-block" : "d-none"
        }`}
      >
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Add Task</h5>
          <button
            type="button"
            onClick={() => {
              setAddTask(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form className="row d-flex justify-content-center align-items-center">
              <div className="my-2 text-end">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => setAddTask(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            <div className="col-md-12 d-flex flex-column new-task-modal ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-12  ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Title <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                {/* <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Milestone <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Milestone"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={milestone}
                  />
                </div> */}
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Estimation Hours <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Estimate time"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Estimation_Hours}
                  />
                </div>
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Probability (%) <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter Probability in (%)"
                  />
                </div>
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Priority <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Priority"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Priority}
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                <div className="col-md-12 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Assigned <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Staff"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    styles={colourStyles}
                    options={Assigned}
                    isMulti="true"
                  />
                </div>
                <div className="col-12 ps-2">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={editor => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                    }}
                  />
                </div>
              </div>
              <Row className="bg-white px-2 py-2 shadow mb-3">
                <div className="d-flex justify-content-end align-items-end px-0">
                  <div className="text-end mb-3">
                    <button
                      onClick={e => {
                        e.preventDefault()
                        onAddFormRow()
                      }}
                      className="btn btn-purple  "
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add Subtask
                    </button>
                  </div>
                </div>
                {formRows?.map((person, key) => {
                  return (
                    <div key={key} className="">
                      <div className="d-flex">
                        <div className="contact-form d-flex col-md-11">
                          <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                            <label htmlFor="">Subtask Name</label>
                            <input
                              type="text"
                              name="task_name"
                              placeholder="Enter Subtask Name"
                              className="form-control "
                            />
                          </div>
                        </div>
                        <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                          <div className="text-end">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                onDeleteFormRow(key)
                              }}
                              className="btn btn-red"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      {key !== formRows.length - 1 ? (
                        <div className="border-bottom my-3"></div>
                      ) : null}
                    </div>
                  )
                })}
              </Row>

           
            </div>
          </Form>
        </div>
          </ResizableBox>
        </Draggable>
      </div>
{/* //------------------------ */}

      {/* Add Task  */}
      {/* <Modal Modal isOpen={addTask} size="xl" className="newTaskModal" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Add Task</h5>
          <button
            type="button"
            onClick={() => {
              setAddTask(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form className="row d-flex justify-content-center align-items-center">
            <div className="col-md-12 d-flex flex-column new-task-modal my-2">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-12  ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Title <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Milestone <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Milestone"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={milestone}
                  />
                </div>
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Estimation Hours <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Estimate time"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Estimation_Hours}
                  />
                </div>
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Probability (%) <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter Probability in (%)"
                  />
                </div>
                <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Priority <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Priority"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={Priority}
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                <div className="col-md-12 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Assigned <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Staff"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    styles={colourStyles}
                    options={Assigned}
                    isMulti="true"
                  />
                </div>
                <div className="col-12 ps-2">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={editor => {}}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                    }}
                  />
                </div>
              </div>
              <Row className="bg-white px-2 py-2 shadow mb-3">
                <div className="d-flex justify-content-end align-items-end px-0">
                  <div className="text-end mb-3">
                    <button
                      onClick={e => {
                        e.preventDefault()
                        onAddFormRow()
                      }}
                      className="btn btn-purple  "
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add Subtask
                    </button>
                  </div>
                </div>
                {formRows?.map((person, key) => {
                  return (
                    <div key={key} className="">
                      <div className="d-flex">
                        <div className="contact-form d-flex col-md-11">
                          <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                            <label htmlFor="">Subtask Name</label>
                            <input
                              type="text"
                              name="task_name"
                              placeholder="Enter Subtask Name"
                              className="form-control "
                            />
                          </div>
                        </div>
                        <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                          <div className="text-end">
                            <button
                              onClick={e => {
                                e.preventDefault()
                                onDeleteFormRow(key)
                              }}
                              className="btn btn-red"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      {key !== formRows.length - 1 ? (
                        <div className="border-bottom my-3"></div>
                      ) : null}
                    </div>
                  )
                })}
              </Row>

              <div className="my-4 text-center">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  onClick={() => setAddTask(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal> */}

      {/* Edit Modal  */}
      <Modal Modal isOpen={editTask} size="xl" className="" centered>
        <div className="modal-header pe-4">
          <h5 className="modal-title mt-0">Edit Task</h5>
          <button
            type="button"
            onClick={() => {
              setEditTask(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>

        <div className="modal-body pe-4">
          <Form
            // onSubmit={handleEditSubmit}
            className="row d-flex justify-content-center align-items-center"
          >
            <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
              <div className="text-center">
                {/* <img src={addTask} alt="Addepe" style={{ width: "90%" }} /> */}
              </div>
            </div>

            <div className="col-md-7 d-flex flex-column ">
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-8  ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Title <span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <Input
                      type="text"
                      //   value={updatedDate}
                      //   onChange={e => setUpdatedDate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-4 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Milestone <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Milestone"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    // options={milestone}
                    // value={updatedLocation}
                    // onChange={selectedOption =>
                    //   setUpdatedLocation(selectedOption)
                    // }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-4 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Estimation Hours <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Payment Via"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    // options={Estimation_Hours}
                  />
                </div>
                <div className="col-md-4 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Probability (%) <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter Probability in (%)"
                  />
                </div>
                <div className="col-md-4 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Priority <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    // options={Priority}
                    // value={updatedLocation}
                    // onChange={selectedOption =>
                    //   setUpdatedLocation(selectedOption)
                    // }
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                <div className="col-md-12 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Assigned <span className="text-danger">*</span>
                  </Label>
                  <ReactSelect
                    placeholder="Select Staff"
                    className="basic-multi-select "
                    classNamePrefix="select"
                    // styles={colourStyles}
                    // options={Assigned}
                    // options={allUser}
                    isMulti="true"
                  />
                </div>
              </div>

              <div className="my-4 text-center">
                <button className=" btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Save
                </button>
                <a
                  //   onClick={() => setAddExpense(false)}
                  className=" btn ms-md-2 btn-red btn-label"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  CANCEL
                </a>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default EditDedicatedProject
