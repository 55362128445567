import React, { useEffect, useState } from "react"
import {
  Badge,
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import ReactSelect from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import NewPagination from "components/Common/Newpagination"
// import ProposalPdf from "./ProposalPdf"

// for pdf
// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Proposal.css"
import zithasLogo from "../../../assets/images/zithas-dark-logo.png"
import AccessDenied from "../../../assets/images/dribbble_1.gif"

// common component
import Pagination from "components/Common/Pagination"
import sideImg from "../../../assets/images/profile-img.png"
import InfoGif from "../../../assets/images/GIF/question-mark.gif"
// components
// import ViewProfile from "./ViewProposal/ViewProfile"
// import ViewActivity from "./ViewProposal/ViewActivity"
import axios from "axios"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
// import RevisionHistory from "./EditProposal/RevisionHistory"
// import ViewRevision from "./ViewProposal/ViewRevision"
import configure from "configure"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import "../../../assets/css/Work/projects.css"
import ViewBasicProjectinfo from "./ViewProject/ViewBasicProjectInfo"
import ViewMileStones from "./ViewProject/ViewMileStones"
import ViewTasks from "./ViewProject/ViewTasks"
import ViewNotes from "./ViewProject/ViewNotes"
import ViewActivityLogs from "./ViewProject/ViewActivityLogs"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import deadlineImg from "../../../assets/images/Project/deadline.jpg"
import Dropzone from "react-dropzone"
import TicektConfirmImg from "../../../assets/images/TicketConfirm.jpg"

const FixedProjectHeader = ({
  setProjectTypeChange,
  project,
  support,
  generalTask,
  formPopup,
  setFormPopup,
  projectType,
  setProjectType,
  columns,
  data,
  setData,
  modal_Access,
  setAccessDenied,
  setFilter,
  filter,
  notAnswered,
  open,
  closed,
  answered,
  allProjects,
  onHold,
  canceled,
  finished,
  notStarted,
  inProgress,
  testing,
  awaitingFeedback,
  complete,
  setProjectStatus,
  inProgressSupport,
  onHoldSupport,
  allData,
  globalFilter,
  setGlobalFilter,
  setHeaderDateTxt,
  setAllCol,
  selectToMove,
  setSelectToMove,
  setAddTask,
}) => {
  const [fixedContent, setFixedContent] = useState(false)
  const [addTicket, setAddTicket] = useState(false)
  const [boxWidth, setBoxWidth] = useState(0)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // popup upate width
  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.72)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setAddTicket(false)
    if (setAddTask) {
      setAddTask(false)
    }
  })
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize } = state
  useEffect(() => {
    setPageSize(50)
  }, [])

  const [name_modal, setNameModal] = useState(false)
  const [title, setTitle] = useState("")

  const [viewData, setViewData] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [relatedOptions, setRelatedOptions] = useState()
  const [salesOptions, setSalesOptions] = useState()
  const [leadsOptions, setLeadsOptions] = useState()
  const [workOptions, setWorkOptions] = useState()
  const [projectOptions, setProjectOptions] = useState()
  const [moveToClose, setMoveToClose] = useState()

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }
  const KeyAccountManager = [
    { label: "Rahul Ovhal", value: "1" },
    { label: "Zain", value: "2" },
    { label: "Manav", value: "3" },
  ]
  const status_option = [
    { label: "Not Answered", value: "Not Answered" },
    { label: "Open", value: "Open" },
    { label: "In Progress", value: "In Progress" },
    { label: "Answered", value: "Answered" },
    { label: "On Hold", value: "On Hold" },
    { label: "Closed", value: "Closed" },
  ]
  const project_option = [
    { label: "Earth Development", value: "Earth Development" },
    { label: "Mars Development", value: "Mars Development" },
    { label: "Jean Therapy", value: "Jean Therapy" },
    { label: "Prop User", value: "Prop User" },
  ]
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const locationOptions = [
    { label: "India", value: "India" },
    { label: "UK", value: "UK" },
  ]

  const assignee = [
    { label: "Rahul Ovhal", value: "1" },
    { label: "Zain", value: "2" },
    { label: "Manav", value: "3" },
    { label: "Divyansh", value: "4" },
    { label: "Nimesh", value: "5" },
  ]

  const priority = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ]
  const relatedTo = [
    { label: "Human Resource", value: "LHuman Resourceow" },
    { label: "Finance", value: "Finance" },
    { label: "Sales", value: "Sales" },
    { label: "Work", value: "Work" },
  ]
  const Type = [
    { label: "Bug", value: "Bug" },
    { label: "Suggestion", value: "Suggestion" },
  ]

  const related = [
    { label: "Project", value: "Project" },
    { label: "Contract", value: "Contract" },
  ]
  const project_type = [
    { label: "Earth Development", value: "Earth" },
    { label: "Mars", value: "Mars" },
  ]

  const handleClick = id => {
    if (setProjectTypeChange) {
      setProjectTypeChange(id)
    }
    if (allData) {
      setProjectType(id)
      if (id == "1") {
        if (setAllCol) {
          setAllCol(true)
        }
        setData(allData?.project2)
        setHeaderDateTxt("Due date")
      } else if (id == "2") {
        const filterData = allData?.project2?.filter(item => {
          return item.status == "Not Started"
        })
        setData(filterData)
      } else if (id == "3") {
        const filterData = allData?.project2?.filter(item => {
          return item.status == "In Progress"
        })
        setData(filterData)
      } else if (id == "4") {
        const filterData = allData?.project2?.filter(item => {
          return item.status == "On Hold"
        })
        if (setAllCol) {
          setAllCol(false)
        }
        setHeaderDateTxt("On Hold Date")
        setData(filterData)
      } else if (id == "5") {
        const filterData = allData?.project2?.filter(item => {
          return item.status == "Cancelled"
        })
        setHeaderDateTxt("Cancelled Date")
        setData(filterData)
      } else if (id == "6") {
        const filterData = allData?.project2?.filter(item => {
          return item.status == "Finished"
        })
        if (setAllCol) {
          setAllCol(false)
        }
        setHeaderDateTxt("Finished Date")
        setData(filterData)
      }
    }
  }

  const handleAddProject = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 25)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        if (response.data.data.india[3] == 1 || response.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}work/add-project`)
        } else {
          setAccessDenied(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddTask = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 8)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        if (response.data.data.india[3] == 1 || response.data.data.uk[3] == 1) {
          setAddTask(true)
        } else {
          setAccessDenied(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        {project == "true" && (
          <Link
            // to={`${configure.appUrl}work/add-project`}
            className="btn btn-save s fix-btn btn-label pe-0"
            onClick={() => handleAddProject()}
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>
            <span>Project</span>
          </Link>
        )}
        {support == "true" && (
          <Link
            onClick={() => setAddTicket(true)}
            className="btn btn-save s fix-btn btn-label pe-0"
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>
            <span>Ticket</span>
          </Link>
        )}
        {generalTask == "true" && (
          <Link
            onClick={() => handleAddTask()}
            className="btn btn-save s fix-btn btn-label pe-0"
          >
            <i className="fas fa-plus me-2 icon-size label-icon"></i>
            <span>Task</span>
          </Link>
        )}
        {selectToMove == "true" && (
          <>
            <Link
              onClick={() => setMoveToClose(true)}
              className="btn btn-save s fix-btn btn-label pe-0 my-2"
            >
              <i className="bi bi-sign-turn-right me-2 icon-size label-icon"></i>
              <span>Move To Close</span>
            </Link>
          </>
        )}
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red s fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>

      <Row className="mb-4 align-items-center">
        <Col md={9} className="pr-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              {notAnswered == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "7" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("7")}
                >
                  <h4 className="text-danger">
                    {
                      data.filter(obj => obj.status_name == "Not Answered")
                        ?.length
                    }
                  </h4>
                  <h4 className="text-danger">Not Answered</h4>
                </a>
              ) : (
                ""
              )}
              {open == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "8" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("8")}
                >
                  <h4 className="text-warning">
                    {data.filter(obj => obj.status_name == "Open")?.length}
                  </h4>
                  <h4 className="text-warning">Open</h4>
                </a>
              ) : (
                ""
              )}
              {allProjects == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "1" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => handleClick("1")}
                >
                  <h4>
                    {/* {data.filter(obj => obj.status_name == "All")?.length} */}
                    {allData ? allData?.counts : "0"}
                  </h4>
                  <h4>All</h4>
                </a>
              ) : (
                ""
              )}

              {notStarted == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "2" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => handleClick("2")}
                >
                  <h4> {allData ? allData?.notstart : "0"}</h4>
                  <h4>Not Started</h4>
                </a>
              ) : (
                ""
              )}
              {inProgress == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "3" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => handleClick("3")}
                >
                  <h4>{allData ? allData?.InProgress : "0"}</h4>
                  <h4>In Progress</h4>
                </a>
              ) : (
                ""
              )}
              {inProgressSupport == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "3" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("3")}
                >
                  <h4 className="text-primary">
                    {allData ? allData?.InProgress : "0"}
                  </h4>
                  <h4 className="text-primary">In Progress</h4>
                </a>
              ) : (
                ""
              )}
              {testing == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "11" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("11")}
                >
                  <h4>
                    {" "}
                    {data.filter(obj => obj.status_name == "testing")?.length}
                  </h4>
                  <h4>Testing</h4>
                </a>
              ) : (
                ""
              )}
              {awaitingFeedback == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "12" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("12")}
                >
                  <h4>
                    {" "}
                    {
                      data.filter(obj => obj.status_name == "awaitingFeedback")
                        ?.length
                    }
                  </h4>
                  <h4>Awaiting Feedback</h4>
                </a>
              ) : (
                ""
              )}

              {complete == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "13" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("13")}
                >
                  <h4>
                    {" "}
                    {data.filter(obj => obj.status_name == "complete")?.length}
                  </h4>
                  <h4>Complete</h4>
                </a>
              ) : (
                ""
              )}

              {answered == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "9" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("9")}
                >
                  <h4 className="text-success">
                    {" "}
                    {data.filter(obj => obj.status_name == "Answered")?.length}
                  </h4>
                  <h4 className="text-success">Answered</h4>
                </a>
              ) : (
                ""
              )}

              {onHold == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "4" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => handleClick("4")}
                >
                  <h4>{allData ? allData?.onhold : "0"}</h4>
                  <h4>On Hold</h4>
                </a>
              ) : (
                ""
              )}

              {onHoldSupport == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "4" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("4")}
                >
                  <h4 className="text-info">
                    {allData ? allData?.onhold : "0"}
                  </h4>
                  <h4 className="text-info">On Hold</h4>
                </a>
              ) : (
                ""
              )}
              {closed == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "10" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => setProjectType("10")}
                >
                  <h4 className="text-dark">
                    {" "}
                    {data.filter(obj => obj.status_name == "Closed")?.length}
                  </h4>
                  <h4 className="text-dark">Closed</h4>
                </a>
              ) : (
                ""
              )}

              {canceled == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "5" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => handleClick("5")}
                >
                  <h4>{allData ? allData?.Cancelled : "0"}</h4>
                  <h4>Cancelled</h4>
                </a>
              ) : (
                ""
              )}

              {finished == "true" ? (
                <a
                  href="#"
                  className={`"text-green pt-2 px-2 rounded proposal-status ${
                    projectType == "6" ? "activeProjectTab" : ""
                  }`}
                  onClick={() => handleClick("6")}
                >
                  <h4>{allData ? allData?.finished : "0"}</h4>
                  <h4>Finished</h4>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col
          md={3}
          className="d-flex align-items-center justify-content-end pl-0"
        >
          <div className="text-md-end">
            {project == "true" && (
              <Link
                // to={`${configure.appUrl}work/add-project`}
                className="s btn text-white me-2 btn-save btn-label"
                onClick={() => handleAddProject()}
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Project
              </Link>
            )}
            {support == "true" && (
              <Link
                onClick={() => setAddTicket(true)}
                className="s btn text-white me-2 btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Ticket
              </Link>
            )}
            {generalTask == "true" && (
              <Link
                onClick={() => handleAddTask()}
                className="s btn text-white me-2 btn-save btn-label"
              >
                <i className="fas fa-plus me-2 icon-size label-icon"></i>Task
              </Link>
            )}

            <button
              onClick={() => navigate(-1)}
              className="s btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>

      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <div className="col-md-2 d-flex justify-content-start align-items-center">
              <select
                className="s me-4 form-select"
                style={{ width: "100px" }}
                value={pageSize}
                onChange={e => setPageSize(e.target.value)}
              >
                {[10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            {selectToMove && (
              <div className="col-md-3 d-flex justify-content-start align-items-center">
                <button
                  onClick={() => setMoveToClose(true)}
                  className="s btn text-white me-2 bg-danger btn-label"
                >
                  <i className="bi bi-sign-turn-right me-2 icon-size label-icon"></i>
                  Move To Close
                </button>
              </div>
            )}
          </div>

          <div className="col-md-6 d-flex justify-content-end align-items-center">
            {support == "true" && (
              <div className="mb-2">
                <button
                  onClick={() => setFormPopup(!formPopup)}
                  className="btn btn-outline-secondary  me-3"
                >
                  <i className="fas fa-filter"></i>
                </button>
              </div>
            )}
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>

        {/* // Move to Close Ticket  */}
        <div
          className={`note-sticky-form ${moveToClose ? "d-block" : "d-none"}`}
        >
          <Draggable>
            <ResizableBox
              width={boxWidth}
              style={{
                position: "fixed",
                top: "16%",
                right: "16%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="modal-header pe-4 mb-3">
                <h5 className="modal-title mt-0">Close Ticket</h5>
                <button
                type="button"
                onClick={() => {
                  setMoveToClose(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
              </div>
              
              <div className="modal-body pe-4">
                <Form className="row d-flex flex-column justify-content-center align-items-center">
                  <div className="col-md-12 d-flex flex-column ">
                    <div className="col-md-12 d-flex mb-4 justify-content-center align-items-end">
                      <h3 className="text-danger text-center">Are you sure you want to close following : 6 tickets</h3>
                    </div>
                    <div className="col-md-12 d-flex mb-4 justify-content-center align-items-center gap-4">
                      <div className="col-4">
                        <img src={TicektConfirmImg} className="img-fluid" />
                      </div>
                      <div className=" col-7 table-responsive text-start ticket-close-box">
                        <table className="table mb-0 table table-bordered border-secondary ">
                          <thead className="table-light border-secondary">
                            <tr>
                              <th className=" text-center">Ticket ID</th>
                              <th className=" text-center">Title</th>
                              <th className=" text-center">Remove </th>
                            </tr>
                          </thead>
                          <tbody className="border-secondary">
                            <tr>
                              <th className=" text-center"> 3166 </th>
                              <td>
                                <p className="text-start">
                                  Create View Projects Task Section popups ,
                                  Days Remaining popup, Delete popup , View Task
                                  popup. and Changes in Edit and add Project
                                  design
                                </p>
                              </td>
                              <td>
                                <div className="text-center">
                                  <input type="checkbox" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className=" text-center"> 3167 </th>
                              <td>
                                <p className="text-start">
                                  Design of Resource plan Screen of Work Module.
                                </p>
                              </td>
                              <td>
                                <div className="text-center">
                                  <input type="checkbox" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className=" text-center"> 3168 </th>
                              <td>
                                <p className="text-start">
                                  Design of Resource plan Screen of Work Module.
                                </p>
                              </td>
                              <td>
                                <div className="text-center">
                                  <input type="checkbox" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className=" text-center"> 3168 </th>
                              <td>
                                <p className="text-start">
                                  Design of Resource plan Screen of Work Module.
                                </p>
                              </td>
                              <td>
                                <div className="text-center">
                                  <input type="checkbox" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className=" text-center"> 3168 </th>
                              <td>
                                <p className="text-start">
                                  Design of Resource plan Screen of Work Module.
                                </p>
                              </td>
                              <td>
                                <div className="text-center">
                                  <input type="checkbox" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className=" text-center"> 3168 </th>
                              <td>
                                <p className="text-start">
                                  Design of Resource plan Screen of Work Module.
                                </p>
                              </td>
                              <td>
                                <div className="text-center">
                                  <input type="checkbox" />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="my-2 text-end">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setMoveToClose(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                </Form>
              </div>
            </ResizableBox>
          </Draggable>
        </div>

        {/* // Support Sections Filter  */}
        {/* {formPopup ? (
          <div id="supportNew-filter">
            <div className="popup-form p-4">
              <Form>
                <Row className="align-items-end">
                  <Row className="d-flex pe-0 me-0" style={{ width: "100%" }}>
                    
                    <div className="col-md-3 mt-2">
                      <label htmlFor="">Project</label>
                      <Select
                        placeholder="Select Project"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={project_option}
                        closeMenuOnSelect={false}
                        isMulti
                        // onChange={e => setStatus(e)}
                      />
                    </div>
                    <div className="col-md-2 mt-2">
                      <label htmlFor="">Developer</label>
                      <ReactSelect
                        placeholder="Select Developers"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        options={KeyAccountManager}
                        isMulti="true"
                      />
                    </div>
                    <div className="col-md-2 mt-2">
                      <label htmlFor="">Status</label>
                      <Select
                        placeholder="Select Status"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={status_option}
                        closeMenuOnSelect={false}
                        isMulti
                        // onChange={e => setStatus(e)}
                      />
                    </div>
                 

                    <div className="col-md-3 mt-2">
                      <label htmlFor="">Save and Clear Filters</label>
                      <div>
                        <a
                          className="btn  btn-primary mr-10"
                          // onClick={onSaveFilter}
                          style={{
                            fontSize: "11px",
                            backgroundColor: "#93a6ff",
                            borderColor: "#93a6ff",
                            color: "black",
                          }}
                        >
                          Save Filters
                        </a>
                        <a
                          className="btn  btn-danger"
                          // onClick={onClearFilter}
                          style={{
                            fontSize: "11px",
                            backgroundColor: "#fed36d",
                            borderColor: "#fed36d",
                            color: "black",
                          }}
                        >
                          Clear Filters
                        </a>
                      </div>
                    </div>
                    <div className="d-flex col-md-2 mb-1 align-items-end justify-content-end">
                      <div className="mr-10">
                        <button className="btn  btn-purple">
                          <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                          Filter
                        </button>
                      </div>
                      <div className="">
                        <a
                          className="btn  btn-red"
                          onClick={e => {
                            e.preventDefault()
                            // setDatas(displayData)
                            setFormPopup(false)
                          }}
                        >
                          <i className="dripicons-cross me-1 icon-center"></i>{" "}
                          Close
                        </a>
                      </div>
                    </div>
                  </Row>
                </Row>
              </Form>
              <i className="bi bi-triangle-fill pop-icon"></i>
            </div>
          </div>
        ) : null} */}

        {/* // New Support Sections Filter  */}
        {formPopup ? (
          <Draggable>
            <ResizableBox
              width={boxWidth}
              style={{
                position: "fixed",
                top: "24%",
                right: "6%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div>
                <Form>
                  <Row className="align-items-end">
                    <Row
                      className="d-flex flex-wrap pe-0 me-0 row"
                      // style={{ width: "100%" }}
                    >
                      <div className="col-md-3 mt-2">
                        <label htmlFor="">Project</label>
                        <Select
                          placeholder="Select Project"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={project_option}
                          closeMenuOnSelect={false}
                          isMulti
                          // onChange={e => setStatus(e)}
                        />
                      </div>
                      <div className="col-md-2 mt-2">
                        <label htmlFor="">Developer</label>
                        <ReactSelect
                          placeholder="Select Developers"
                          className="basic-multi-select "
                          classNamePrefix="select"
                          options={KeyAccountManager}
                          isMulti="true"
                        />
                      </div>
                      <div className="col-md-2 mt-2">
                        <label htmlFor="">Status</label>
                        <Select
                          placeholder="Select Status"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          styles={colourStyles}
                          options={status_option}
                          closeMenuOnSelect={false}
                          isMulti
                          // onChange={e => setStatus(e)}
                        />
                      </div>

                      <div className="col-md-3 mt-2">
                        <label htmlFor="">Save and Clear Filters</label>
                        <div>
                          <a
                            className="btn  btn-primary mr-10"
                            // onClick={onSaveFilter}
                            style={{
                              fontSize: "11px",
                              backgroundColor: "#93a6ff",
                              borderColor: "#93a6ff",
                              color: "black",
                            }}
                          >
                            Save Filters
                          </a>
                          <a
                            className="btn  btn-danger"
                            // onClick={onClearFilter}
                            style={{
                              fontSize: "11px",
                              backgroundColor: "#fed36d",
                              borderColor: "#fed36d",
                              color: "black",
                            }}
                          >
                            Clear Filters
                          </a>
                        </div>
                      </div>
                      <div className="d-flex col-md-2 mb-1 align-items-end justify-content-end">
                        <div className="mr-10">
                          <button className="btn  btn-purple">
                            <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                            Filter
                          </button>
                        </div>
                        <div className="">
                          <a
                            className="btn  btn-red"
                            onClick={e => {
                              e.preventDefault()
                              // setDatas(displayData)
                              setFormPopup(false)
                            }}
                          >
                            <i className="dripicons-cross me-1 icon-center"></i>{" "}
                            Close
                          </a>
                        </div>
                      </div>
                    </Row>
                  </Row>
                </Form>
                {/* <i className="bi bi-triangle-fill pop-icon"></i> */}
              </div>
            </ResizableBox>
          </Draggable>
        ) : null}

        {/* // Add Ticket  */}
        <div className={`note-sticky-form ${addTicket ? "d-block" : "d-none"}`}>
          <Draggable>
            <ResizableBox
              width={boxWidth}
              style={{
                position: "fixed",
                top: "16%",
                right: "16%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="modal-header pe-4 mb-3">
                <h5 className="modal-title mt-0">Add Ticket</h5>
                {/* <button
                  type="button"
                  onClick={() => {
                    setAddTicket(false)
                  }}
                  className="btn-modal-close"
                >
                  <span aria-hidden="true" className="btn-modal-span">
                    &times;
                  </span>
                </button> */}
                <div className="my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => setAddTicket(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
              </div>

              <div className="modal-body pe-4">
                <Form className="row d-flex justify-content-center align-items-center">
                  {/* <div className="my-2 text-end">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setAddTicket(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div> */}
                  <div className="col-md-12 d-flex flex-column new-task-modal ticket-history-box">
                    <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                      <div className="col-sm-2 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Location <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select Location"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={locationOptions}
                        />
                      </div>
                      <div className="col-sm-10 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Title <span className="text-danger">*</span>
                        </Label>
                        <div className="d-flex justify-content-start align-items-center gap-2">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                      <div className="col-sm-2 ps-2">
                        <Label htmlFor="formrow-firstname-Input">Type</Label>
                        <Select
                          placeholder="Select Type"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={Type}
                        />
                      </div>
                      <div className="col-sm-2 ps-2">
                        <Label htmlFor="formrow-firstname-Input">Related</Label>
                        <Select
                          placeholder="Select Related"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={related}
                        />
                      </div>

                      <div className="col-sm-4 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Select Project
                        </Label>
                        <Select
                          placeholder="Select Project"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={project_type}
                        />
                      </div>
                      <div className="col-sm-2 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Assigned Ticket <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select Assignee"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={assignee}
                        />
                      </div>
                      <div className="col-sm-2 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Priority <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select Priority"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={priority}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                      <div className="col-12 ps-2">
                        <label htmlFor="">Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          onReady={editor => {}}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                          }}
                        />
                      </div>
                      <div className="col-md-12 d-flex justify-space-between align-items-center">
                        <div className="col-md-3 mt-3">
                          <label htmlFor="">Attachment</label>
                          {/* custom file upload */}
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles1(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                className="dz-message needsclick p-0"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="d-flex px-2 custom-file-upload align-items-center">
                                  <div className="">
                                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h5 className="mb-0 ms-2">Upload Files</h5>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {/* file display here */}
                        <div
                          className={`col-md-4 mt-3 dropzone-previews ${
                            selectedFiles1.length > 0 ? "d-block" : "d-none"
                          }`}
                          id="file-previews"
                        >
                          <label className="mb-1">Uploaded Files</label>
                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-1">
                              <Row className="align-items-center justify-content-evenly">
                                {selectedFiles1?.map((f, i) => {
                                  return (
                                    <Col key={i} className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                  )
                                })}
                              </Row>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </div>
                    {/* <Row className="bg-white px-2 py-2 shadow mb-3">
                      <div className="d-flex justify-content-end align-items-end px-0">
                        <div className="text-end mb-3">
                          <button
                            onClick={e => {
                              e.preventDefault()
                              onAddFormRow()
                            }}
                            className="btn btn-purple  "
                          >
                            <i className="fas fa-plus me-2"></i>
                            Add Subtask
                          </button>
                        </div>
                      </div>
                     
                    </Row> */}
                  </div>
                </Form>
              </div>
            </ResizableBox>
          </Draggable>
        </div>

        {/* // Add Task  */}
        {/* <div className={`note-sticky-form ${addTask ? "d-block" : "d-none"}`}>
          <Draggable>
            <ResizableBox
              width={900}
              style={{
                position: "fixed",
                top: "10%",
                right: "2%",
                zIndex: "1055",
                backgroundColor: "#fff",
              }}
            >
              <div className="modal-header pe-4">
                <h5 className="modal-title mt-0">Add Task</h5>
                <button
                  type="button"
                  onClick={() => {
                    setAddTask(false)
                  }}
                  className="btn-modal-close"
                >
                  <span aria-hidden="true" className="btn-modal-span">
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body pe-4">
                <Form className="row d-flex justify-content-center align-items-center">
                  <div className="my-2 text-end">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setAddTask(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                  <div className="col-md-12 d-flex flex-column new-task-modal ">
                    
                    <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                      <div className="col-sm-3 ps-2">
                          <Label htmlFor="formrow-firstname-Input">
                            Location{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            placeholder="Select Location"
                            className="basic-multi-select"
                            classNamePrefix=" select"
                            options = {locationOptions}
                          />
                      </div>
                      <div className="col-md-9 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Title <span className="text-danger">*</span>
                        </Label>
                        <div className="d-flex justify-content-start align-items-center gap-2">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                   
                      <div className="col-md-3 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                         Start Date                         
                        </Label>
                        <Input
                            type="date"
                            className="form-control"
                            placeholder="Select Date"
                          />
                      </div>
                      <div className="col-md-3 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                         End Date                         
                        </Label>
                        <Input
                            type="date"
                            className="form-control"
                            placeholder="Select Date"
                          />
                      </div>
                   
                      <div className="col-md-3 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select Priority"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={priority}
                        />
                      </div>

                      <div className="col-md-3 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                        Related <span className="text-danger">*</span>
                        </Label>
                        <Select
                          placeholder="Select Related"
                          className="basic-multi-select"
                          classNamePrefix=" select"
                          options={relatedTo}
                        />
                      </div>

                    </div>
                    <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">

                      <div className="col-md-9 ps-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Assigned <span className="text-danger">*</span>
                        </Label>
                        <ReactSelect
                          placeholder="Select Member"
                          className="basic-multi-select "
                          classNamePrefix="select"
                          options={assignee}
                          isMulti="true"
                        />
                      </div>
                    </div>
                  
                    <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                      
                      <div className="col-12 ps-2">
                        <label htmlFor="">Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          onReady={editor => {}}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                          }}
                        />
                      </div>
                  
                    </div>
                 
                  </div>
                </Form>
              </div>
            </ResizableBox>
          </Draggable>
        </div> */}
      </Row>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </>
  )
}

export default FixedProjectHeader
