import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"
import indiaFlag from "../../../assets/images/flags/india.png"
import UkFlag from "../../../assets/images/flags/uk.png"
// assets
import "../../../assets/css/Sales/Sales.css"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"

const AddCustomer = () => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.title = "Add Customer | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const source_option = [
    { label: "Customer", value: "0" },
    { label: "Introducer", value: "1" },
  ]

  const groups_option = [
    { label: "Regular", value: "1" },
    { label: "Monthly", value: "2" },
    { label: "Quarterly", value: "3" },
  ]

  const [isLoading, setLoading] = useState(false)
  const [country_option, setCountryOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])
  const [location_option, setLocationOption] = useState([])
  const [company_name, setCompany_name] = useState("")
  const [vat_number, setVatNumber] = useState("")
  const [location, setLocation] = useState([])
  const [source, setSource] = useState([])
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [country, setCountry] = useState([])
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [address, setAddress] = useState("")
  const [zip_code, setZipCode] = useState("")
  const [groups, setGroups] = useState("")
  const [currency_option, setCurrencyOption] = useState([])
  const [currency, setCurrency] = useState([])
  const [incorporation, setIncorporation] = useState("")
  const [customer_option, setCustomerOption] = useState([])
  const [customer_name, setCustomerName] = useState([])

  useEffect(() => {
    fetchLocation()
    fetchCountrys()
    fetchCurrency()
  }, [])

  const fetchLocation = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLocationOption(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchCountrys = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/country`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setCountryOption(
          res.data.data.country.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchCurrency = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/currency`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setCurrencyOption(
          res.data.data.currency.map(item => ({
            label: item.currency,
            value: item.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onChangeCountry = async e => {
    setLoading(true)
    setCountry(e)
    const formData = new FormData()
    formData.append("country_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/country_state`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setStateOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onChangeState = async e => {
    setLoading(true)
    setState(e)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/state_city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCityOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const [error, setError] = useState(null)

  const [save, setSave] = useState([])
  const onGetSource = async e => {
    setSource(e)
    setLoading(true)
    const formData = new FormData()
    formData.append("location", location)
    formData.append("source_name", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_source`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCustomerOption(
          res.data.data.map(items => ({
            value: items.id,
            label: items.company,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onAddProposal = async e => {
    e.preventDefault()
    if (
      company_name.length != 0 &&
      source.length != 0 &&
      location.length != 0 &&
      customer_name.length != 0
    ) {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("company", company_name)
      formData.append("vatnumber", vat_number)
      formData.append("phone", phone)
      formData.append("website", website)
      formData.append("address", address)
      formData.append("zipcode", zip_code)
      formData.append("country", country?.value)
      formData.append("state", state?.value)
      formData.append("city", city?.value)
      formData.append("groups", groups?.value)
      formData.append("currency", currency?.value)
      formData.append("location", location)
      formData.append("email", email)
      formData.append("corporationdate", incorporation)
      formData.append("source_name", customer_name.value)
      formData.append("source", source?.value)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/add_customer`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          if (save == "0") {
            swal({
              icon: "success",
              text: "Customer Added Succesfully",
            }).then(data => {
              navigate(`${configure.appUrl}sales/customer`)
            })
          } else {
            swal({
              icon: "success",
              text: "Customer Added Succesfully",
            }).then(data => {
              navigate(
                `${configure.appUrl}sales/add-contact/${res.data.data[0].id}`
              )
            })
          }
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      setError(true)
    }
  }

  // const onSaveProposal = async e => {
  //   e.preventDefault()
  //   setLoading(true)
  //   const formData = new FormData()
  //   formData.append("user_id", localStorage.getItem("auth_id"))
  //   formData.append("company", company_name)
  //   formData.append("vatnumber", vat_number)
  //   formData.append("phone", phone)
  //   formData.append("website", website)
  //   formData.append("address", address)
  //   formData.append("zipcode", zip_code)
  //   formData.append("country", country?.value)
  //   formData.append("state", state?.value)
  //   formData.append("city", city?.value)
  //   formData.append("groups", groups?.value)
  //   formData.append("currency", currency?.value)
  //   formData.append("location", location)
  //   formData.append("email", email)
  //   formData.append("corporationdate", incorporation)
  //   formData.append("source", customer_name.value)
  //   formData.append("source_name", source?.value)

  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "https://api.zithas.com/api/add_customer",
  //       data: formData,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(res => {
  //       setLoading(false)
  //       swal({
  //         icon: "success",
  //         text: "Customer Added Succesfully",
  //       }).then(data => {
  //         navigate("/sales/customer")
  //       })
  //     })
  //   } catch (error) {
  //     console.log(error)
  //     setLoading(false)
  //   }
  // }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Customer"} />
          <Row className="px-1">
            <Form onSubmit={onAddProposal}>
              {/* Fixed Buttons */}
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button className="btn btn-save fix-btn btn-label pe-0">
                  <i className="fas fa-save me-2 label-icon"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </a>
              </div>
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="col-md-6">
                  {/* <div className="col-md-6"> */}
                  {/* <label htmlFor="">
                      Location <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={location_option}
                      onChange={e => setLocation(e)}
                    /> */}
                  <div className="">
                    <h5 className="font-size-14 mb-4">Location</h5>
                    <div className="row " style={{ marginLeft: "0px" }}>
                      {location_option.map((item, i) => {
                        return (
                          <div className="form-check mb-2 col-md-2" key={i}>
                            <input
                              className="form-check-input input-location"
                              type="radio"
                              name="exampleRadios"
                              id={`exampleRadios${i}`}
                              value={item.value}
                              onChange={e => {
                                setLocation(e.target.value)
                                setSource([])
                              }}
                            />
                            <label
                              className="form-check-label pl-10"
                              htmlFor={`exampleRadios${i}`}
                            >
                              {item.value == 1 ? (
                                <img
                                  src={indiaFlag}
                                  alt="no img"
                                  width="28"
                                  height="18"
                                  style={{ marginTop: "2px" }}
                                />
                              ) : (
                                <img
                                  src={UkFlag}
                                  alt="no img"
                                  width="28"
                                  height="18"
                                  style={{ marginTop: "2px" }}
                                />
                              )}
                            </label>
                          </div>
                        )
                      })}
                      <span
                        className="text-danger"
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "7px",
                        }}
                      >
                        {(location.length == 0) == error
                          ? "Please select Location"
                          : null}
                      </span>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-md-6 text-end">
                  <button
                    type="submit"
                    className=" btn ms-md-2 btn-save btn-label"
                    onClick={() => setSave("0")}
                  >
                    <i className="fas fa-save me-1 label-icon"></i> save
                  </button>
                  <button
                    type="submit"
                    onClick={() => setSave("1")}
                    className=" btn ms-md-2 btn-save btn-label"
                  >
                    <i className="fas fa-save me-1 label-icon"></i> Save and
                    create contact
                  </button>

                  <a
                    onClick={e => {
                      navigate(-1)
                    }}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
              </Row>

              <Row className="bg-white px-2 py-4 shadow mb-3">
                <h5 className="mb-3">Company Information</h5>
                <div className="col-md-3 ">
                  <label htmlFor="">
                    Company <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Company"
                    className="form-control "
                    value={company_name}
                    onChange={e => setCompany_name(e.target.value)}
                  />
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      paddingTop: "7px",
                    }}
                  >
                    {(company_name.length == 0) == error
                      ? "Please select Location"
                      : null}
                  </span>
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">GST/VAT Number</label>
                  <input
                    type="text"
                    placeholder="Enter GST/VAT Number"
                    className="form-control "
                    value={vat_number}
                    onChange={e => setVatNumber(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3 ">
                  <label htmlFor="">
                    Source <span className="text-danger">*</span>{" "}
                  </label>
                  <Select
                    placeholder="Select Source"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={location.length != 0 ? source_option : []}
                    value={source}
                    onChange={e => onGetSource(e)}
                  />
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      paddingTop: "7px",
                    }}
                  >
                    {(source.length == 0) == error
                      ? "Please select Location"
                      : null}
                  </span>
                </div>
                <div className="col-md-3">
                  {source.length != 0 ? (
                    <>
                      <label htmlFor="">
                        Select {source.label}{" "}
                        <span className="text-danger">*</span>{" "}
                      </label>
                      <Select
                        placeholder={`Select ${source.label}`}
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        styles={colourStyles}
                        options={customer_option}
                        onChange={e => setCustomerName(e)}
                      />
                      <span
                        className="text-danger"
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "7px",
                        }}
                      >
                        {(customer_name.length == 0) == error
                          ? "Please select Location"
                          : null}
                      </span>
                    </>
                  ) : null}
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Phone</label>
                  <input
                    type="text"
                    placeholder="Enter Phone"
                    className="form-control "
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Email Address</label>
                  <input
                    type="text"
                    placeholder="Enter Email Address"
                    className="form-control "
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Website</label>
                  <input
                    type="text"
                    placeholder="Enter Website"
                    className="form-control "
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Incorporation Date</label>
                  <input
                    type="date"
                    className="form-control "
                    value={incorporation}
                    onChange={e => setIncorporation(e.target.value)}
                  />
                </div>
              </Row>

              <Row className="bg-white px-2 py-4 shadow mb-3">
                <h5 className="mb-3">Address</h5>
                <div className="col-md-3 mb-3 pe-md-0">
                  <label htmlFor="">Country</label>
                  <Select
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={country_option}
                    onChange={e => onChangeCountry(e)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">State</label>
                  <Select
                    placeholder="Select State"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={state_option}
                    onChange={e => onChangeState(e)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">City </label>
                  <Select
                    placeholder="Select City"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={city_option}
                    onChange={e => setCity(e)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Zip Code</label>
                  <input
                    type="text"
                    placeholder="Enter Zip Code"
                    className="form-control "
                    value={zip_code}
                    onChange={e => setZipCode(e.target.value)}
                  />
                </div>
                <div className="col-md-6 mb-3 pe-md-0">
                  <label htmlFor="">Address</label>
                  <input
                    type="text"
                    placeholder="Enter Address"
                    className="form-control "
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Groups </label>
                  <Select
                    placeholder="Select Groups"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={groups_option}
                    onChange={e => setGroups(e)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Currency </label>
                  <Select
                    placeholder="Select Currency"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={currency_option}
                    onChange={e => setCurrency(e)}
                  />
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddCustomer
