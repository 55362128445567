import React, { useEffect, useState } from "react"
import { Container, Modal } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
import roleImg from "../../assets/images/role-3.avif"

// common component
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

function TableContainer({ columns, data, setAccessDenied, setLoading }) {
  const [fixedHeader, setFixedHeader] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  const [name_modal, setNameModal] = useState(false)
  const [action_modal, setActionModal] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_center() {
    setNameModal(false)
    removeBodyCss()
  }
  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 17)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}addroles`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // Render the UI for your table

  return (
    <div id="Roles">
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          to={`${configure.appUrl}addroles`}
          className="btn btn-save  fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Roles</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div>
            <button
              // to={`${configure.appUrl}addroles`}
              onClick={onAddPermission}
              className=" btn btn-save btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Role
            </button>
            {/* <Link
              to={`${configure.appUrl}addroles`}
              className=" btn btn-save btn-label"
            >
              {" "}
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Add Role
            </Link> */}
            <button
              onClick={() => navigate(-1)}
              className=" btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="row">
        <div className="col-md-4 d-flex justify-content-center">
          <div className={`${fixedHeader ? "rolesIndexImg" : ""}`}>
            <img src={roleImg} alt="roleImg" className="w-100" />
          </div>
        </div>
        <div className="col-md-8 pt-4">
          <Table
            id="roles-table"
            className="table table-bordered shadow-sm"
            {...getTableProps()}
          >
            <thead className={`${fixedHeader ? "fix-header-roles-table" : ""}`}>
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      className={`t-head-${index + 1} ${column.className}`}
                      key={index}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                      {column.isInfoIcon ? (
                        <a
                          onClick={() => {
                            // if(column)
                            if (column.Header == "Role Name") {
                              setNameModal(true)
                            } else if (column.Header == "Action") {
                              setActionModal(true)
                            }
                          }}
                        >
                          <i
                            title="info"
                            className="bx bx-info-circle ms-1 info-icon"
                          ></i>
                        </a>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr className="row-hover" {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className={cell.column.className}
                          key={index}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Roles Name Modal */}
      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Roles Name Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i>It show All
                  Roles Name having particular Permission that has been given
                  while adding Role.This Roles is added as per their Location.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Acion Modal */}
      <Modal
        isOpen={action_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Action Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i>This column
                  has an Edit Button for editing roles as needed, as well as a
                  Delete Button for Soft deleting roles that are no longer
                  needed.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setActionModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Roles = () => {
  //meta title
  document.title = "Roles & Permission  | Zithas Crm"
  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)

  const fetchRoles = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/roles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log("roles", response)
        setDatas(response.data.data?.reverse())
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchRoles()
  }, [])

  const navigate = useNavigate()
  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        className: "id-width-roles",
      },
      {
        Header: "Role Name",
        accessor: "role_name",
        isInfoIcon: true,
        className: "role-name-width",
      },
      {
        Header: "Count",
        accessor: "count",
        isInfoIcon: true,
        className: "role-status-width text-center",
        // Cell: cellProps => {
        //   return (
        //     <div className="form-switch form-switch-md">
        //       <input
        //         type="checkbox"
        //         className="form-check-input"
        //         id="customSwitchsizemd"
        //         defaultChecked={cellProps.row.original.status == "1"}
        //       />
        //     </div>
        //   )
        // },
      },
      {
        Header: "Action",
        accessor: "action",
        isInfoIcon: true,
        className: "action-width-roles",
        Cell: cellProps => {
          const callBack = () => {
            navigate(
              `${configure.appUrl}editroles/${cellProps.row.values.id}`,
              { state: { roleId: cellProps.row.values.id } }
            )
          }

          const onDeleteRole = e => {
            e.preventDefault()
            Swal.fire({
              title: "Are you sure?",
              text: "You want to Delete this Role !",
              icon: "warning",
              showCancelButton: true,
            }).then(async willDelete => {
              if (willDelete.isConfirmed) {
                setLoading(true)
                const formData = new FormData()
                formData.append("role_id", cellProps.row.values.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/deleteRoles`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      text: "Roles Deleted Successfully",
                    }).then(data => {
                      setDatas(response?.data?.data?.reverse())
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }
          const onPermission = async (e, id) => {
            e.preventDefault()
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 17)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  onDeleteRole(e)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 17)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  callBack(id)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          return (
            <div>
              <a
                onClick={() => onEditPermission(cellProps.row.values.id)}
                // onClick={() => callBack(cellProps.row.values.id)}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </a>
              <a
                // onClick={onDeleteRole}
                onClick={onPermission}
                className="btn btn-sm action-btn btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && <Loader />}
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Roles & Permission"}
          />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default Roles
