import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import moment from "moment"

const ViewNotes = ({ customer_Note }) => {
  return (
    <React.Fragment>
      <div>
        <div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div>
                    <ul className="verti-timeline list-unstyled notes-box">
                      {/* Activities */}
                      {customer_Note.map((note, key) => {

                        return (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <img
                                src={`https://mars.zithas.com/application/public/${note.avatar}`}
                                className="userImg me-3 "
                                alt=""
                              />
                            </div>
                            <div className="d-flex">
                              <div className="note flex-grow-1 ms-4 py-2 rounded ps-4">
                                <div className=" d-flex justify-content-between ">
                                  <div>
                                    <p className="text-muted mb-4">
                                      <h6 className="d-inline me-2">
                                        By {note.added_by}
                                      </h6>{" "}
                                      {moment(note.created_at).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                      <span className="pl-15">
                                        Got in touch with this lead at{" "}
                                        {moment(note.date).format("DD-MM-YYYY")}
                                      </span>
                                    </p>
                                    <i className="bi bi-triangle-fill note-icon"></i>
                                  </div>

                                </div>
                                <div
                                  className="Features"
                                  dangerouslySetInnerHTML={{
                                    __html: note.description,
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                    {customer_Note.length == 0 ? (
                      <p>No Notes is Added for this Customer</p>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewNotes
