import React, { useEffect, useState } from "react"

import Breadcrumb from "components/Common/Breadcrumb"
import "../../assets/css/Staff/Staff.css"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import KnowledgeBaseReact from "./Categories/KnowledgeBaseReact"
import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

// components

const KnowledgeBase = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [loading, setLoading] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState({})
  const [navItemData, setNavItemData] = useState([])
  const [topicData, setTopicData] = useState([])
  const [cateId, setCateID] = useState("")
  const [subCateId, setSubCateId] = useState("")
  const [isDropdown, setIsDropdown] = useState(null)
  const [row, setRow] = useState(1)
  const [modal_Access, setAccessDenied] = useState(false)
  const [topicName, setTopicName] = useState(null)

  document.title = "Knowledge Base | Zithas Crm"

  const toggleCollapse = id => {
    setCollapseOpen(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  useEffect(() => {
    if (navItemData.length > 0) {
      const firstItem = navItemData[0]
      setCateID(firstItem.id)
      setverticalActiveTab(`${firstItem.id}`)
      if (navItemData[0]?.subcategories.length >= 0) {
        setIsDropdown(false)
      } else {
        setIsDropdown(true)
      }
    }
  }, [navItemData, setverticalActiveTab])

  const [fixedSideTab, setFixedSideTab] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 30) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  const fatchAllTabData = async () => {
    setLoading(true)
    const roleId = localStorage.getItem("auth_role")
    const authId = localStorage.getItem("auth_id")
    const formData = new FormData()
    formData.append("role_id", roleId)
    formData.append("user_id", authId)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/knowledgeTopics`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        setNavItemData(response?.data?.data?.category)
        setTopicData(response?.data?.data?.topics)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    fatchAllTabData()
  }, [])

  const currentCategory = navItemData?.find(cat => cat.id === cateId)
  // Find current subcategory
  const currentSubcategory = currentCategory?.subcategories?.find(
    sub => sub.id === subCateId
  )

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content mb-4" id="knowledgeBase">
        <Container fluid>
          {/* <Breadcrumb title="Dashboard" breadcrumbItem="Knowledge Base" /> */}
          <Breadcrumb
            KnowledgeBase={true}
            title="knowledgeBase"
            breadcrumbItem={`${
              currentCategory ? currentCategory?.category : ""
            } ${currentSubcategory ? "/" : ""}
                  ${currentSubcategory ? currentSubcategory?.subcategory : ""}
                  ${topicName ? "/" : ""}
                  ${topicName ? topicName : ""}
                  `
                }
          />
          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col
              md="2"
              className="px-0 side-tab"
              style={{ overflowY: "scroll", minHeight: "65vh" }}
            >
              <div className="position-relative">
                <Nav
                  pills
                  className={`position-absolute w-100  flex-column px-2 py-3 ${
                    fixedSideTab ? "" : ""
                  } `}
                >
                  {navItemData?.map(items => {
                    const isSubItemActive = items.subcategories?.some(
                      subItem => verticalActiveTab === `${subItem.id}`
                    )
                    if (items.subcategories.length == 0) {
                      return (
                        <NavItem key={items.id}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === `${items.id}`,
                            })}
                            onClick={() => {
                              setRow(1)
                              setTopicName(null)
                              setCateID(items.id)
                              setSubCateId("")
                              setIsDropdown(false)
                              toggleVertical(`${items.id}`)
                            }}
                          >
                            {items.category}
                          </NavLink>
                        </NavItem>
                      )
                    } else {
                      return (
                        <NavItem key={items.id}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active:
                                verticalActiveTab === `${items.id}` ||
                                isSubItemActive,
                              // active: isParentActive,
                            })}
                            onClick={() => {
                              setRow(1)
                              setTopicName(null)
                              setCateID(items.id)
                              setSubCateId("")
                              setIsDropdown(false)
                              toggleVertical(`${items.id}`)
                              toggleCollapse(`${items.id}`)
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center w-100">
                              {items.category}
                              {""}
                              <i
                                className={`bi ${
                                  collapseOpen[items.id]
                                    ? "bi-chevron-up"
                                    : "bi-chevron-down"
                                }`}
                              ></i>
                            </div>
                          </NavLink>
                          <Collapse isOpen={collapseOpen[`${items.id}`]}>
                            {items.subcategories?.map(subItems => {
                              return (
                                <NavLink
                                  key={subItems.id}
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    "mb-2 subcategory": true,
                                    active:
                                      verticalActiveTab === `${subItems.id}`,
                                  })}
                                  onClick={() => {
                                    setCateID(subItems.parent)
                                    setSubCateId(subItems.id)
                                    setIsDropdown(true)
                                    toggleVertical(`${subItems.id}`)
                                    setRow(1)
                                    setTopicName(null)
                                  }}
                                >
                                  {subItems.subcategory}
                                </NavLink>
                              )
                            })}
                          </Collapse>
                        </NavItem>
                      )
                    }
                  })}
                </Nav>
              </div>
            </Col>
            <Col md="10" className="pe-0">
              <div className="card p-4 h-100">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0 h-100"
                >
                  <TabPane tabId={verticalActiveTab}>
                    <KnowledgeBaseReact
                      verticalActiveTab={verticalActiveTab}
                      topicData={topicData}
                      cateId={cateId}
                      subCateId={subCateId}
                      setTopicData={setTopicData}
                      isDropdown={isDropdown}
                      row={row}
                      setRow={setRow}
                      navItemData={navItemData}
                      setTopicName={setTopicName}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default KnowledgeBase
