import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Container,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
} from "reactstrap"
import classnames from "classnames"
import configure from "configure"

// components
import Credentials from "./Credentials"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import Swal from "sweetalert2"

const GeneralSettingIndex = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)
  const [swalconfirm, setSwalconfirm] = useState(false)
  const [testingRows, setTestingRows] = useState([])
  const [isShowCred, setIsShowCred] = useState(null)

  const [inputData, setInputData] = useState([])

  const [currency, setCurrency] = useState([])

  const [dynamicObj, setDynamicObj] = useState({})
  const [isLoading, setLoading] = useState(false)

  const [technology_id, setTechnoId] = useState(null)
  const [globalFilter, setGlobalFilter] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const [rowData, setRowData] = useState([])
  // const [pageSize, setPageSize] = useState(10)

  const navigate = useNavigate()

  useEffect(() => {
    fetchCurrency()
  }, [])

  useEffect(() => {
    setInputData(inputData?.map((item, index) => ({ ...item, id: index + 1 })))
    // console.log("checking", inputData)
  }, [testingRows])

  const fetchCurrency = async () => {
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/currency`,

        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        // console.log("currency", res.data)
        setCurrency(res.data.data.currency)

        // =============
        const temp = {}
        res.data.data.currency.forEach(obj => {
          temp[obj.currency] = ""
        })

        setDynamicObj(temp)
        setInputData([{ id: 1, Technology: "", ...temp }])
        setTestingRows([{ id: 1 }])

        // call show api
        showTechnologiesData()
      })
    } catch (error) {}
  }

  const showTechnologiesData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/show_technologies`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(async response => {
        // console.log('data', response)
        if (response.data.data.length > 0) {
          const datas = JSON.parse(response.data.data[0].currency_value)
          console.log(datas)
          if (datas) {
            setRowData(datas)
            let tempRows = []
            datas.forEach(item => {
              tempRows.push({ id: item.id })
            })
            setTestingRows(tempRows)
            setInputData(datas)
            setTechnoId(response.data.data[0].id)
          }
        }
        setLoading(false)
        // response.data.data[0].currency_value
      })
    } catch (err) {
      console.log(err)
    }
  }

  // form repeater
  const onAddRow = () => {
    const modifiedRows = [...testingRows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setTestingRows(modifiedRows)

    setInputData([
      ...inputData,
      {
        id: modifiedRows.length,
        Technology: "",
        ...dynamicObj,
      },
    ])
  }

  const onDeleteRow = id => {
    Swal.fire({
      icon: "warning",
      title: "Are You Sure ?",
      text: "You want to Delete this Technologies !",
      showCancelButton: true,
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        Swal.fire({
          icon: "success",
          text: "Technologies deleted successfully !",
        }).then(() => {
          var modifiedRows = [...testingRows]
          modifiedRows = modifiedRows.filter(x => x["id"] !== id)
          setTestingRows(
            modifiedRows.map((item, index) => ({ ...item, id: index + 1 }))
          )
          setInputData(prevForms => {
            const updatedForms = [...prevForms]
            return updatedForms.filter((serv, i) => serv.id !== id)
          })
        })
      }
    })
  }
  // ==================================

  // collect Technologies data
  const handleFormChange = (index, field, value) => {
    // Update the value of a specific field in a form at the specified index
    setInputData(prevForms => {
      const updatedForms = [...prevForms]
      updatedForms[index][field] = value
      return updatedForms
    })
  }
  // ==================================

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })
  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "General Settings  | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  // Add /Edit Technologies api integration
  const addEditTechnologies = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()

    if (technology_id) {
      formData.append("technologies_id", technology_id)
    }
    formData.append("currency_value", JSON.stringify(inputData))

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_technologies`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })

      // console.log("add", response)

      if (response) {
        setSwalconfirm(true)
        setLoading(false)
        const datas = JSON.parse(response.data.data[0].currency_value)
        let tempRows = []
        datas.forEach(item => {
          tempRows.push({ id: item.id })
        })
        setTestingRows(tempRows)
        setInputData(datas)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const filterGlobel = () => {
    const filteredDatas = rowData?.filter(
      item =>
        item.Technology &&
        item.Technology.toLowerCase().includes(searchTerm.toLowerCase())
    )
    let tempRows = []
    filteredDatas.forEach(item => {
      tempRows.push({ id: item.id })
    })
    setTestingRows(tempRows)
    setInputData(filteredDatas)
  }
  useEffect(() => {
    filterGlobel()
  }, [searchTerm])
  // console.log(testingRows)

  // table


  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"General Settings"}
          />

          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Credentials
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Gitlab
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Technologies
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10">
              <div className="bg-transparent mb-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <Credentials />
                  </TabPane>
                  <TabPane tabId="2">
                    <Form>
                      <div
                        className={`flex-column fixed-buttons pt-2 ${
                          fixedContent ? "d-flex" : "d-none"
                        }`}
                      >
                        <button
                          onClick={() => {
                            tog_center()
                          }}
                          className="btn btn-save btn-label pe-0  fix-btn "
                        >
                          <i className="fas fa-plus me-2 icon-size label-icon"></i>
                          <span>Add</span>
                        </button>
                        <button
                          onClick={() => navigate(-1)}
                          className="btn btn-red  fix-btn btn-label pe-0"
                        >
                          <i className="dripicons-cross me-2 icon-center label-icon"></i>
                          <span>Cancel</span>
                        </button>
                      </div>
                      <div className="p-4 bg-white shadow">
                        <div className="row">
                          <div className="col-md-9">
                            <h5 className="text-dark">Gitlab</h5>
                          </div>
                          <div className="col-md-3">
                            <button
                              onClick={() => {
                                tog_center()
                              }}
                              className=" btn btn-save btn-label"
                            >
                              {" "}
                              <i className="fas fa-save me-2 icon-size label-icon"></i>
                              Save
                            </button>
                            <a
                              onClick={() => navigate(-1)}
                              className=" btn ms-md-2 btn-red btn-label"
                            >
                              <i className="dripicons-cross me-2 icon-center label-icon"></i>
                              CANCEL
                            </a>
                          </div>
                        </div>

                        <Row className="">
                          <div className="mt-4 col-md-6">
                            <label htmlFor="">Token</label>
                            <input
                              type="text"
                              placeholder="Enter Token"
                              className="form-control "
                            />
                          </div>
                          <div className="mt-4 col-md-6">
                            <label htmlFor="">User Id</label>
                            <input
                              type="text"
                              placeholder="Enter User Id"
                              className="form-control "
                            />
                          </div>
                        </Row>
                      </div>
                    </Form>
                  </TabPane>
                  <TabPane tabId="3">
                    <Form onSubmit={addEditTechnologies}>
                      <div className=" p-4 bg-white shadow">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="pt-2 text-dark fw-bold">
                              Technologies
                            </h5>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div
                              className={`flex-column fixed-buttons ${
                                fixedContent ? "d-flex" : "d-none"
                              }`}
                            >
                              <button
                                onClick={() => onAddRow()}
                                className="btn btn-save fix-btn"
                              >
                                <i className="fas fa-plus me-2 icon-size"></i>
                                <span>Add</span>
                              </button>
                              <button
                                type="submit"
                                className="btn btn-save fix-btn"
                              >
                                <i className="fas fa-save me-2"></i>
                                <span> Save</span>
                              </button>
                            </div>
                            <div>
                              <div className="text-end d-flex">
                                <div className="">
                                  <div className="me-4">
                                    <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                                      <div className="position-relative">
                                        <label
                                          htmlFor="search-bar-0"
                                          className="search-label"
                                        >
                                          <span
                                            id="search-bar-0-label"
                                            className="sr-only"
                                          >
                                            Search this table
                                          </span>
                                          <input
                                            onChange={e => {
                                              setSearchTerm(e.target.value)
                                              filterGlobel()
                                            }}
                                            id="search-bar-0"
                                            type="text"
                                            className=" form-control rounded h-100"
                                            placeholder={` Search...`}
                                            value={searchTerm}
                                          />
                                        </label>
                                        <i className="bx bx-search-alt search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a
                                    onClick={() => onAddRow()}
                                    className="btn btn-purple  mr-3 btn-label"
                                  >
                                    <i className="fas fa-plus me-2 icon-size label-icon"></i>{" "}
                                    Add Technologies
                                  </a>
                                </div>
                                <div>
                                  <button
                                    type="submit"
                                    className="btn btn-save btn-label"
                                  >
                                    <i className="fas fa-save me-2 label-icon"></i>
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="responsive-table table-responsive">
                          <Table className="table table-bordered mb-0 table-striped mt-3 table-hover">
                            <thead>
                              <tr>
                                <th className="text-center tech-price">Id</th>
                                <th className="tech-price1">Technologies</th>
                                {currency?.map((curry, i) => (
                                  <th key={i} className="tech-price">
                                    {curry.currency}
                                  </th>
                                ))}
                                <th className="text-center tech-price"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {(testingRows || [])
                                ?.map((testingRow, key) => (
                                  <tr key={testingRow.id}>
                                    <td className="text-center">{key + 1}</td>
                                    <td className="">
                                      <input
                                        type="text"
                                        className="form-control "
                                        value={inputData[key]?.Technology}
                                        onChange={e =>
                                          handleFormChange(
                                            key,
                                            "Technology",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    {currency?.map((curry, index) => {
                                      return (
                                        <td className="" key={index}>
                                          <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={
                                              testingRow.currency_value
                                            }
                                            value={
                                              inputData[key][curry.currency]
                                            }
                                            onChange={e =>
                                              handleFormChange(
                                                key,
                                                curry.currency,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                      )
                                    })}
                                    <td className="text-center">
                                      {key !== 0 ? (
                                        <a
                                          onClick={() =>
                                            onDeleteRow(testingRow.id)
                                          }
                                          className="btn btn-outline-danger"
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                        </a>
                                      ) : (
                                        "--"
                                      )}
                                    </td>
                                  </tr>
                                ))
                                .reverse()}

                              {testingRows.length === 0 && (
                                <tr>
                                  <td colSpan={8} className="text-center">
                                    No data found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/*  */}

      {swalconfirm && (
        <div className="swal-confirm-popup-genral-setting">
          <div className="bg-white pb-3 pt-2 px-4 -swal-22">
            <div
              className="swal2-icon swal2-success swal2-icon-show"
              style={{ display: "flex" }}
            >
              <div
                className="swal2-success-circular-line-left"
                style={{ backgroundColor: "rgb(255, 255, 255)" }}
              ></div>
              <span className="swal2-success-line-tip"></span>
              <span className="swal2-success-line-long"></span>
              <div className="swal2-success-ring"></div>
              <div
                className="swal2-success-fix"
                style={{ backgroundColor: "rgb(255, 255, 255)" }}
              ></div>
              <div
                className="swal2-success-circular-line-right"
                style={{ backgroundColor: "rgb(255, 255, 255)" }}
              ></div>
            </div>
            <h2
              className="swal22-title "
              id="swal2-title"
              style={{ display: "block" }}
            >
              Technologies Saved Successfully
            </h2>
            <div className="d-flex justify-content-center py-3">
              <button
                type="button"
                className="py-2 px-3"
                aria-label=""
                style={{ display: "inline-block" }}
                onClick={() => setSwalconfirm(false)}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default GeneralSettingIndex
