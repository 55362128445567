import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Modal
} from "reactstrap";

// assets
import '../../../assets/css/Finance/Finance.css';

import indiaFlag from '../../../assets/images/flags/india.png'
import ukFlag from '../../../assets/images/flags/uk.png'

import { CSVLink } from "react-csv";

// common component
import Pagination from 'components/Common/Pagination';

function TableContainer({ columns, data }) {
    const [formPopup, setFormPopup] = useState(false);
    const [fixedContent, setFixedContent] = useState(false);
    const [uploadbill_modal, setuploadbill_modal] = useState(false);
    const [view_modal, setview_modal] = useState(false);

    const navigate = useNavigate();

    document.addEventListener('scroll', () => {
        if (window.scrollY > 245) {
            setFixedContent(true);
        }
        else {
            setFixedContent(false);
        }
    })

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        prepareRow
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, pageSize, globalFilter } = state;

    useEffect(() => setPageSize(50), [])

    // Functionality to export table data to CSV //
    const excelColumns = []
    columns.forEach(column => {
        excelColumns.push(column.Header)
    });

    const csvData = [
        excelColumns,
        ...data.map(({ id, location, date, type, title, category, amount }) => [
            id,
            location,
            date,
            type,
            title,
            category,
            amount
        ]),
    ];
    // ======================================================= //


    // Render the UI for your table
    return (
        <>
            {/* fixed buttons */}
            <div className={`flex-column fixed-buttons ${fixedContent ? 'd-flex' : 'd-none'}`}>
                <button onClick={() => setview_modal(true)} className='btn btn-purple border-radius fix-btn'>
                    <i className="fas fa-eye me-2 icon-size"></i>
                    <span>View</span>
                </button>
                <button onClick={() => setuploadbill_modal(true)} className='btn btn-primary border-radius fix-btn'>
                    <i className="fas fa-cloud-upload-alt me-2 icon-size"></i>
                    <span>Upload</span>
                </button>
                <CSVLink
                    className='btn btn-success border-radius fix-btn'
                    filename="bank_accounts.csv"
                    data={csvData}>
                    <i className='fas fa-download me-2 icon-center'></i>
                    <span>Export</span>
                </CSVLink>
                <button onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                    <i className='dripicons-cross me-2 icon-center'></i>
                    <span>Cancel</span>
                </button>
            </div>
            <Row className="align-items-center">

                <Col md={12} className='mb-3'>
                    <div className='d-flex justify-content-between '>
                        {/* Amounts Records */}
                        <div className='d-flex'>
                            <a href="#" className='rounded payment-amounts mb-0'>
                                <p className='text-dark mb-2'>Current Month Balance </p>
                                <h4 className='text-secondary'>₹ 0.00</h4>
                            </a>

                            <a href="#" className='rounded payment-amounts mb-0'>
                                <p className='text-dark mb-2'> Last Month Balance </p>
                                <h4 className='text-secondary'>₹ 0.00</h4>
                            </a>

                            <a href="#" className='rounded payment-amounts mb-0'>
                                <p className='text-dark mb-2'>Last 12 Month Avg. Balance </p>
                                <h4 className='text-secondary'>₹ 1,291.67</h4>
                            </a>
                        </div>

                        <div>
                            <button
                                onClick={() => setview_modal(true)}
                                className='border-radius btn text-white me-2 btn-purple'>
                                <i className="fas fa-eye me-2 icon-size"></i>View Bill
                            </button>
                            <button
                                onClick={() => setuploadbill_modal(true)}
                                className='border-radius btn text-white btn-primary'>
                                <i className="fas fa-cloud-upload-alt me-2 icon-size"></i>Upload Bill
                            </button>
                        </div>
                    </div>
                </Col>
                <Col md={3}>
                    <select
                        className="border-radius form-select me-3"
                        style={{ width: '100px' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(e.target.value)}
                    >
                        {[10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col md={9} className='d-flex align-items-center justify-content-end'>
                    <div className='me-3 mt-1'>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label mb-0">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="border-radius form-control rounded h-100"
                                        placeholder={` Search...`}
                                        value={globalFilter || ''}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setFormPopup(!formPopup)} className='btn btn-outline-secondary border-radius me-3'><i className='fas fa-filter'></i></button>
                    </div>
                    <div className='text-md-end'>
                        <CSVLink
                            className='border-radius btn text-white me-2 btn-success'
                            filename="credit_cards.csv"
                            data={csvData}>
                            <i className="fas fa-download me-2 icon-size"></i> Export CSV
                        </CSVLink>

                        <button onClick={() => navigate(-1)} className="border-radius btn btn-red"><i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                    </div>
                </Col>

            </Row>
            <div className='position-relative mt-4'>
                <Table className='table table-bordered' {...getTableProps()}>
                    <thead className={`${fixedContent ? 'fix-header' : ''} fix-bank-header`}>
                        {
                            headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column, index) => (
                                            <th key={index} className={`t-col-${index + 1} ${column.className}`} {...column.getHeaderProps()}>{column.render("Header")}
                                                {
                                                    column.isInfoIcon ?
                                                        <i title='info' className='bx bx-info-circle ms-1 info-icon'></i> : null}
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody className="fix-bank-header" {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='row-hover' {...row.getRowProps()} key={i}>
                                        {
                                            row.cells.map((cell, index) => {
                                                return <td key={index}
                                                    className={`t-col-${index + 1} ${cell.column.className}`} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                {
                    formPopup ?
                        <div className='credit-popup-form popup-form p-4'>
                            <Form>
                                <Row className='align-items-end'>
                                    <div className="col-md-4">
                                        <label htmlFor="">Start Date</label>
                                        <input type="date" className='form-control border-radius' />
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="">End Date</label>
                                        <input type="date" className='form-control border-radius' />
                                    </div>

                                    <div className='text-center col-md-4'>
                                        <button className='btn border-radius btn-purple me-3'>
                                            <i className="fas fa-search me-2"></i>
                                            Search
                                        </button>
                                        <button className='btn border-radius btn-red'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setFormPopup(!formPopup)
                                            }}
                                        ><i className="dripicons-cross me-1 icon-center"></i> Clear</button>
                                    </div>
                                </Row>
                            </Form>
                            <i className='bi bi-triangle-fill pop-icon'></i>
                        </div> : null
                }
            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Pagination
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                />
            </Row>


            {/* View Bill Modal */}
            <Modal
                isOpen={view_modal}
                size='md'
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">View Bill</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setview_modal(false);
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Table className="table-bordered"  >
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th className='col-2'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Recharge</td>
                                <td>
                                    <a href="#" className='btn btn-sm btn-outline-primary'>
                                        <i className='fas fa-download'></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>2</td>
                                <td>Food</td>
                                <td>
                                    <a href="#" className='btn btn-sm btn-outline-primary'>
                                        <i className='fas fa-download'></i>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <td>3</td>
                                <td>Shopping</td>
                                <td>
                                    <a href="#" className='btn btn-sm btn-outline-primary'>
                                        <i className='fas fa-download'></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </div>
            </Modal>
            {/* ------------ */}

            {/* Upload Bill Modal */}
            <Modal
                isOpen={uploadbill_modal}
                size='md'
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Upload Bill</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setuploadbill_modal(false);
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Form>
                        <Row>
                            <div className="col-12 mb-3">
                                <label htmlFor="">Name </label>
                                <input type="text" placeholder='Enter Name' className='form-control border-radius' required />
                            </div>
                            <div className='col-12 mb-3'>
                                <label htmlFor="">Upload File</label>
                                <input type="file" className='form-control border-radius' required />
                            </div>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius'>Submit</button>
                        </div>
                    </Form>

                </div>
            </Modal>
            {/* ------------ */}

        </>
    );
}

const CreditCard = () => {

    useEffect(() => window.scrollTo(0, 0), []);

    //meta title
    document.title = "Credit Card | Zithas Crm";

    // table columns
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                isInfoIcon: false,
                className: 'text-center'
            },
            {
                Header: "Location",
                accessor: "location",
                className: 'text-center',
                Cell: cellProps => {
                    const location = cellProps.data[cellProps.row.id].location
                    return (
                        <div>
                            <img src={location === "India" ? indiaFlag : ukFlag} value={location} className='location-img' alt="flag" />
                        </div>
                    )
                },
                isInfoIcon: true
            },
            {
                Header: "Date",
                accessor: "date",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Type",
                accessor: "type",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Title",
                accessor: "title",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Category",
                accessor: "category",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Amount",
                accessor: "amount",
                isInfoIcon: true,
                className: 'text-center'
            }
        ],
        []
    );

    const data = [
        {
            id: 1,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "UK",
            amount: 3000,
        },
        {
            id: 2,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "India",
            amount: 12000,
        },
        {
            id: 3,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "India",
            amount: 13000,
        },
        {
            id: 4,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "India",
            amount: 23000,
        },
        {
            id: 5,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "UK",
            amount: 3000,
        },
        {
            id: 6,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "UK",
            amount: 3000,
        },
        {
            id: 4,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "India",
            amount: 3000,
        },
        {
            id: 5,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "India",
            amount: 3000,
        },
        {
            id: 6,
            category: 'Phone Bill',
            date: '28-08-2023 12:17:43',
            title: 'test the general task notification',
            type: "Expense",
            location: "India",
            amount: 3000,
        },

    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"Credit Card"}
                    />

                    <Row>
                        <Col lg={12} className='p-0'>
                            <Card>
                                <CardBody>
                                    <div className="responsive-table">
                                        <TableContainer columns={columns} data={data} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default CreditCard