import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle, Modal, Form } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import moment from "moment"
import configure from "configure"
import Swal from "sweetalert2"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"

const ViewNotes = ({ notesData, view_assign, viewId, verticalActiveTab }) => {
  const [loading, setLoading] = useState(false)

  const [viewFollowUp, setViewFollowUp] = useState(false)
  const [notes, setNotes] = useState([])
  const [assign, setAssigned] = useState("")

  const [allData, setAllData] = useState([])
  const [data, setDatas] = useState([])
  const [boxWidth, setBoxWidth] = useState(0)
  const [formPopup, setFormPopup] = useState(false)
  const [filterForm, setFilterForm] = useState(false)
  const [visibleCount, setVisibleCount] = useState(5)
  const [fStartDate, setFStartDate] = useState("")
  const [fendDate, setFendDate] = useState("")
  const [fDescription, setFDescription] = useState("")
  const minVisibleCount = 15

  const onGetAssign = note => {
    setViewFollowUp(true)
    setNotes(note?.lead_followup[0])
    const idArrays = note?.lead_followup[0].assign_user?.split(",").map(Number)
    const filteredArray = view_assign.filter(item =>
      idArrays.includes(item.value)
    )
    const resultString = filteredArray.map(item => item.label).join(", ")
    setAssigned(resultString)
  }

  useEffect(() => {
    setDatas(notesData)
    setAllData(notesData)
  }, [notesData])

  useEffect(() => {
    const updateWidth = () => {
      const viewportWidth = window.innerWidth
      setBoxWidth(viewportWidth * 0.6)
    }
    updateWidth()
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  useEffect(() => {
    if (verticalActiveTab !== "2") {
      setFilterForm(false)
      setFStartDate("")
      setFendDate("")
      setFDescription("")
      setDatas(notesData)
      setAllData(notesData)
      setFormPopup(false)
    }
  }, [verticalActiveTab])

  const onClearFilter = () => {
    if (fStartDate || fendDate || fDescription) {
      setFilterForm(false)
      setFStartDate("")
      setFendDate("")
      setFDescription("")
      setDatas(notesData)
      setAllData(notesData)
      Swal.fire({
        icon: "success",
        text: "Filters cleared successfully!",
      })
    } else {
      Swal.fire({
        icon: "info",
        text: "No filters to clear.",
      }).then(() => {
        setDatas(notesData)
      })
    }
  }

  const handleClose = () => {
    if (fStartDate || fendDate || fDescription) {
      setFilterForm(false)
      setFStartDate("")
      setFendDate("")
      setFDescription("")
      setDatas(notesData)
      setFormPopup(false)
      Swal.fire({
        icon: "success",
        text: "Filter cleared and popup closed.",
      })
    } else {
      setFormPopup(false)
    }
  }

  const normalizeString = str => {
    return str?.replace(/\s+/g, "").toLowerCase() || ""
  }

  const onFilter = e => {
    e.preventDefault()
    // if(fStartDate || fendDate)
    if (fStartDate <= fendDate || fendDate == "" || fStartDate == "") {
      try {
        if (fStartDate || fendDate || fDescription) {
          const filteredByStartDate = fStartDate
            ? allData?.filter(item =>
                moment(item.created_at).isAfter(moment(fStartDate))
              )
            : allData
          const filteredByEndDate = fendDate
            ? filteredByStartDate.filter(item =>
                moment(item.created_at).isBefore(moment(fendDate))
              )
            : filteredByStartDate

          const filteredByDescription = fDescription
            ? filteredByEndDate.filter(item => {
                const normalizedDescription = normalizeString(item.description)
                const normalizedSearchTerm = normalizeString(fDescription)
                return normalizedDescription.includes(normalizedSearchTerm)
              })
            : filteredByEndDate

          // Swal.fire({
          //   icon: "success",
          //   text: "Filter applied successfully!",
          // }).then(() => {
          setFilterForm(true)
          setVisibleCount(15)
          const ascending = filteredByDescription?.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          )
          setDatas(ascending)
          // setFormPopup(!formPopup)
          // })
        } else {
          Swal.fire({
            icon: "warning",
            text: "Please enter at least one filter criterion to proceed.",
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid Date Range",
        text: "End date must be after the start date.",
      })
    }
  }
  const handleSeeMore = () => {
    setVisibleCount(prevCount => {
      const newCount = prevCount + 15
      return Math.min(newCount, data.length)
    })
  }
  const handleSeeLess = () => {
    setVisibleCount(prevCount => {
      const newCount = prevCount - 15
      return Math.max(newCount, minVisibleCount)
    })
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <Row>
          <Col lg="12">
            <Card>
              <CardBody className="note-filter">
                <CardTitle className="mb-5 d-flex justify-content-between">
                  <div>Notes</div>
                  <div className="d-flex gap-3">
                    <button
                      onClick={() => setFormPopup(!formPopup)}
                      className="btn btn-outline-secondary"
                    >
                      <i className="fas fa-filter"></i>
                    </button>
                    <a
                      className="btn btn-save  me-2 btn-label"
                      type="button"
                      // onClick={() => {
                      //   redirectCalender()
                      // }}
                      href={`${configure.appUrl}calender/lead-${viewId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-users me-2 label-icon"></i>
                      Add Meeting
                    </a>
                  </div>
                </CardTitle>
                {formPopup ? (
                  <Draggable>
                    <ResizableBox
                      width={boxWidth}
                      style={{
                        position: "fixed",
                        top: "4%",
                        right: "15%",
                        zIndex: "1055",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div>
                        <Form onSubmit={onFilter}>
                          <Row className="align-items-end">
                            <Row
                              className="d-flex flex-wrap pe-0 me-0"
                              style={{ width: "100%" }}
                            >
                              <div className="col-md-2 mb-2">
                                <label htmlFor="startDate">From Date</label>
                                <input
                                  required={fendDate ? true : false}
                                  type="date"
                                  id="startDate"
                                  value={fStartDate}
                                  onChange={e => setFStartDate(e.target.value)}
                                  className="form-control"
                                />
                              </div>

                              <div className="col-md-2 mb-2">
                                <label htmlFor="endDate">To Date</label>
                                <input
                                  type="date"
                                  id="endDate"
                                  value={fendDate}
                                  onChange={e => setFendDate(e.target.value)}
                                  className="form-control"
                                />
                              </div>

                              <div className="col-md-4 mb-2">
                                <label htmlFor="">Description</label>
                                <input
                                  type="text"
                                  id="endDate"
                                  value={fDescription}
                                  onChange={e =>
                                    setFDescription(e.target.value)
                                  }
                                  className="form-control"
                                  placeholder="Enter Description"
                                />
                              </div>

                              <div className="col-md-4 mb-2 d-flex justify-content-end">
                                <div className="d-flex align-item-center mt-3">
                                  {/* <label htmlFor="">Clear Filters</label> */}
                                  <div className="">
                                    <button className="btn  btn-purple mr-10 my-1">
                                      <i className="fas fa-search search-icon me-1 icon-center"></i>{" "}
                                      Search
                                    </button>
                                    <a
                                      className="btn  btn-danger mr-10 my-1"
                                      onClick={onClearFilter}
                                      style={{
                                        fontSize: "11px",
                                        backgroundColor: "#fed36d",
                                        borderColor: "#fed36d",
                                        color: "black",
                                      }}
                                    >
                                      Clear Filters
                                    </a>

                                    <a
                                      className="btn  btn-red my-1"
                                      onClick={e => {
                                        handleClose()
                                      }}
                                    >
                                      <i className="dripicons-cross me-1 icon-center"></i>{" "}
                                      Close
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </Row>
                        </Form>
                        <i className="bi bi-triangle-fill pop-icon"></i>
                      </div>
                    </ResizableBox>
                  </Draggable>
                ) : null}
                <div className="mt-4">
                  {filterForm ? (
                    <ul className="verti-timeline list-unstyled notes-box">
                      {data?.slice(0, visibleCount)?.map((note, key) => {
                        return (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <img
                                src={`https://mars.zithas.com/application/public/${note.addedby_avatar}`}
                                className="userImg me-3 "
                                alt=""
                              />
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                                <div className=" d-flex justify-content-between ">
                                  <div>
                                    <p className="text-muted ">
                                      <h6 className="d-inline me-2">
                                        By {note.added_by}
                                      </h6>{" "}
                                      {moment(note.created_at).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                      <span className="pl-15">
                                        Got in touch with this lead at{" "}
                                        {moment(note.date).format(
                                          "DD-MM-YYYY hh:mm a"
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="me-4">
                                    {note.type == 1 ? (
                                      <span
                                        onClick={() => onGetAssign(note)}
                                        className="me-2 cursor-pointer badge-soft-success badge"
                                      >
                                        follow up
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <i className="bi bi-triangle-fill note-icon"></i>

                                <div
                                  className="Features"
                                  dangerouslySetInnerHTML={{
                                    __html: note.description,
                                  }}
                                />
                                <p className="mb-1">Amount : {note.amount}</p>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                      <div className="d-flex justify-content-center mt-3 gap-3">
                        {visibleCount < data.length && (
                          <button
                            onClick={handleSeeMore}
                            className="btn btn-outline-primary"
                          >
                            See More <i className="bi bi-arrow-down"></i>
                          </button>
                        )}
                        {visibleCount > minVisibleCount && (
                          <button
                            onClick={handleSeeLess}
                            className="btn btn-outline-secondary"
                          >
                            See Less <i className="bi bi-arrow-up"></i>
                          </button>
                        )}
                      </div>
                    </ul>
                  ) : (
                    <ul className="verti-timeline list-unstyled notes-box">
                      {data?.map((note, key) => {
                        return (
                          <li
                            key={key}
                            className="event-list position-relative"
                          >
                            <div className="event-timeline-dot">
                              <img
                                src={`https://mars.zithas.com/application/public/${note.addedby_avatar}`}
                                className="userImg me-3 "
                                alt=""
                              />
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                                <div className=" d-flex justify-content-between ">
                                  <div>
                                    <p className="text-muted ">
                                      <h6 className="d-inline me-2">
                                        By {note.added_by}
                                      </h6>{" "}
                                      {moment(note.created_at).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                      <span className="pl-15">
                                        Got in touch with this lead at{" "}
                                        {moment(note.date).format(
                                          "DD-MM-YYYY hh:mm a"
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="me-4">
                                    {note.type == 1 ? (
                                      <span
                                        onClick={() => onGetAssign(note)}
                                        className="me-2 cursor-pointer badge-soft-success badge"
                                      >
                                        follow up
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <i className="bi bi-triangle-fill note-icon"></i>

                                <div
                                  className="Features"
                                  dangerouslySetInnerHTML={{
                                    __html: note.description,
                                  }}
                                />
                                <p className="mb-1">Amount : {note.amount}</p>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                  {data.length == 0 && filterForm == false ? (
                    <p>Note is Not Addded</p>
                  ) : null}
                  {data.length == 0 && filterForm == true ? (
                    <p>Note is Not found</p>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {/* View Follow Up Modal */}
      <Modal
        isOpen={viewFollowUp}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setViewFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row bd-span-input">
            <>
              <div className="mb-3 col-12">
                <label htmlFor="formrow-firstname-Input">Date</label>
                <div className="input-span">{notes.date_time}</div>
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Purpose</label>
                <div className="input-span">{notes.description}</div>
              </div>
              <div className="mb-3 col-12">
                <label htmlFor="">Assigned</label>
                <div className="input-span">{assign}</div>
              </div>
            </>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default ViewNotes
