import React, { useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
    Container,
    Table,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import TaskDetails from './TaskDetails';

// components


const ResourcePlanIndex = () => {

    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const [fixedSideTab, setFixedSideTab] = useState(false);

    document.addEventListener('scroll', () => {
        if (window.scrollY > 68) {
            setFixedSideTab(true);
        }
        else {
            setFixedSideTab(false);
        }
    })

    document.title = "Resource Planning | Zithas Crm";

    const toggleVertical = (tab) => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content mb-4">
                <Container fluid >
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"Resource Planning"}
                    />

                    <Row className='gx-4 justify-content-between di-flex align-items-top'>
                        <Col md="2" className='px-0'>
                            <Nav pills className={`side-tab flex-column px-2 py-3 
                            ${fixedSideTab ? 'fixed-side-tab' : ''} h-100`}>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleVertical("1");
                                        }}
                                    >
                                        Glamour Decor - Website Development
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleVertical("2");
                                        }}
                                    >
                                        Itelfs - Website Development
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            "mb-2": true,
                                            active: verticalActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleVertical("3");
                                        }}
                                    >
                                        Mccoy Usa Development
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md="10 pe-0">
                            <div className=' bg-transparent mb-0'>
                                <TabContent
                                    activeTab={verticalActiveTab}
                                    className="text-muted mt-4 mt-md-0"
                                >
                                    <TabPane tabId="1">
                                        <TaskDetails />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <TaskDetails />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <TaskDetails />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default ResourcePlanIndex;