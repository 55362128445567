import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody, Form, Modal } from "reactstrap"

// assets
// common component
import Pagination from "components/Common/Pagination"

import configure from "configure"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"

function TableContainer({ columns, data, setMoticData }) {
  const [fixedContent, setFixedContent] = useState(false)
  const [addCallingModal, setAddCallingModal] = useState(false)

  const [locationOptions, setLocationOptions] = useState([])
  const [industryOptions, setIndustryOption] = useState([])
  const [countryOptions, setCountryOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])

  const [location, setLocation] = useState({ label: "", value: "" })
  const [country, setCountry] = useState({ label: "", value: "" })
  const [city, setCity] = useState({ label: "", value: "" })
  const [statee, setState] = useState({ label: "", value: "" })
  const [industry, setIndustry] = useState({ label: "", value: "" })
  const [status, setStatus] = useState({ label: "", value: "" })
  const [no_of_contacts, setNoOfContacts] = useState("")

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  // Render the UI for your table
  return (
    <div>
      {loading && <Loader />}
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red  fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col>
          <select
            className=" me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className=" form-control rounded h-100"
                    placeholder={`Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <button
              onClick={() => navigate(-1)}
              className=" btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`${fixedContent ? "fix-header" : ""} fix-calling-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={` ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-calling-header  " {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={` ${cell.column.className}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr>
                <td colSpan={8} className="text-center">
                  {" "}
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </div>
  )
}

const MoticBlocks = ({ company_id }) => {
  const [moticData, setMoticData] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  //meta title
  document.title = "Mautic Logs| Zithas Crm"

  // get all cold-calling data
  useEffect(() => {
    setLoading(true)
    const loadData = async () => {
      const formData = new FormData()
      formData.append("opportunity_id", company_id)
      formData.append("user_id", localStorage.getItem("auth_id"))
      try {
        const response = await axios.post(
          `${configure.apiUrl}/mauticlogs`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response) {
          if (response?.data?.data) {
            setMoticData(response?.data?.data)
          }
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
    loadData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Event",
        accessor: "event",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Event Type",
        accessor: "event_type",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Event lable",
        accessor: "event_label",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Date & Time",
        accessor: "date_time",
        className: "text-center",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div className="">
              {moment(cellProps.row.original.booking_date).format("DD-MM-YYYY")}
              &nbsp;|&nbsp;
              {moment(cellProps.row.original.booking_date).format("HH:mm")}
            </div>
          )
        },
      },

      //   {
      //     Header: "Action",
      //     accessor: "action",
      //     Cell: cellProps => {
      //       return (
      //         <div>
      //           <button
      //             title="View"
      //             className="btn btn-sm me-1 btn-outline-secondary"
      //             onClick={() => {
      //               navigate(`${configure.appUrl}marketing/view-coldcalling`, {
      //                 state: {
      //                   data: {
      //                     id: cellProps.row.original.id,
      //                     country: cellProps.row.original.country,
      //                     state: cellProps.row.original.state,
      //                     city: cellProps.row.original.city,
      //                     status: cellProps.row.original.status,
      //                     nocontact: cellProps.row.original.nocontact,
      //                     industry: cellProps.row.original.industry,
      //                   },
      //                 },
      //               })
      //             }}
      //           >
      //             <i className="fas fa-eye"></i>
      //           </button>
      //           <button
      //             onClick={() => onDelete(cellProps.row.original.id)}
      //             title="delete"
      //             className="btn btn-sm  btn-danger"
      //           >
      //             <i className="fas fa-trash-alt"></i>
      //           </button>
      //         </div>
      //       )
      //     },
      //   },
    ],
    []
  )

  const data = useMemo(() => moticData, [moticData])
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div>
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setMoticData={setMoticData}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MoticBlocks
