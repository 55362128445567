import Breadcrumb from "components/Common/Breadcrumb"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import Select from "react-select"

// Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditTemplate = () => {
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const countries = [
    { label: "India", value: "1" },
    { label: "United Kingdom", value: "2" },
    { label: "Algeria", value: "3" },
    { label: "American samoa", value: "4" },
  ]
  const [fixButtons, setFixButtons] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const [edit_status, setEditStatus] = useState(false)
  const navigate = useNavigate()

  return (
    <div>
      <React.Fragment>
        <div className="page-content mb-4">
          <Container fluid>
            <Breadcrumb
              title="Dashboard"
              breadcrumbItem="Introducer Contract Template"
            />
            <Row className="gx-4 justify-content-between di-flex align-items-top">
              <div
                className={`flex-column fixed-buttons ${fixButtons ? "d-flex" : "d-none"
                  }`}
              >
                <button className="btn btn-purple border-radius fix-btn">
                  <i className="fas fa-save me-2"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red border-radius fix-btn"
                >
                  <i className="dripicons-cross me-2 icon-center"></i>
                  <span>Cancel</span>
                </a>
              </div>

              <Card>
                <CardBody>
                  <div className="mb-20">
                    <Row>
                      <div className="col-md-6">
                        <h4>Edit Contract Template</h4>
                      </div>
                      <div className="col-md-6  text-md-end">
                        <button className="border-radius btn text-white me-md-0 me-2 btn-purple">
                          <i className="fas fa-save me-2"></i>SAVE
                        </button>
                        <a
                          onClick={() => navigate(-1)}
                          className="border-radius btn text-white ms-md-2 btn-red"
                        >
                          <i className="dripicons-cross me-2 icon-center"></i>
                          CANCEL
                        </a>
                      </div>
                    </Row>
                  </div>
                  <Form className="row align-items-end">
                    <div className="mb-3 col-md-3">
                      <Label htmlFor="formrow-firstname-Input">Name</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-firstname-Input"
                        placeholder="Enter Name"
                        defaultValue="Test Template"
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <Label htmlFor="formrow-firstname-Input">Type </Label>
                      <Select
                        placeholder="Select Country"
                        className="basic-multi-select border-radius"
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={countries}
                        defaultValue={{ label: "Test" }}
                      />
                    </div>
                    <div className="mb-3 col-md-3">
                      <a
                        className="border-radius btn text-white me-md-0 me-2 btn-purple"
                        onClick={() => {
                          setEditStatus(true)
                        }}
                      >
                        {/* <i className="fas fa-save me-2"></i> */}
                        Fields
                      </a>
                    </div>
                    <div className="mb-3 col-md-12 ">
                      <Label htmlFor="formrow-firstname-Input">Attachment </Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data="<p>Hello from CKEditor 5!</p>"
                        onReady={editor => {
                          // You can store the "editor" and use when it is needed.
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                        }}
                      />
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={edit_status}
          size="sm"
          className="bd-custom-md-2"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Dynamic fields</h5>
            <button
              type="button"
              onClick={() => {
                setEditStatus(false)
              }}
              className="btn-modal-close"
            >
              <span aria-hidden="true" className="btn-modal-span">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <table
              className="table table-striped table-bordered mb-0 centered"
              id="myTable"
            >
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Field Name</th>
                  <th scope="col">Short Code</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Company Name</td>
                  <td>$company</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Country</td>
                  <td>$country</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>State</td>
                  <td>$state</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>City</td>
                  <td>$city</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Address</td>
                  <td>$address</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Email</td>
                  <td>$email</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>Phone Number</td>
                  <td>$phone</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Location</td>
                  <td>$location</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  )
}

export default EditTemplate
