import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import { useParams } from "react-router-dom"
import JsPDF from "jspdf"
import { Row, Modal, Form } from "reactstrap"

// canvas
import SignatureCanvas from "react-signature-canvas"

// images
import zithasLogo from "../../../assets/images/zithas-dark-logo.png"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"
import Swal from "sweetalert2"

const GenerateLinkContract = () => {
  const [fixedRightSec, setFixedRightSec] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [attachmentModal, setAttachmentModal] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 48) {
      setFixedRightSec(true)
    } else {
      setFixedRightSec(false)
    }
  })

  const printContract = () => {
    let printContents = document.getElementById("zithasInvoicePrint").innerHTML
    let originalContents = document.body.innerHTML
    document.body.innerHTML = printContents
    window.print()
    document.body.innerHTML = originalContents
  }

  // const generatePDF = async () => {

  //   if (signature == null || approve == 0) {
  //     swal("", "Cannot Download Unsigned OR Unapprove Contract!", "warning")
  //   } else {
  //     setLoading(true);

  //     // Select the content to be converted to PDF
  //     const content = document.querySelector("#zithasInvoicePrint");

  //     const opt = {
  //       margin: 0.38,
  //       filename: 'zithasContract.pdf',
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
  //       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
  //     };

  //     setTimeout(() => setLoading(false), 2000);
  //     html2pdf().from(content).set(opt).save();

  //   }

  // };

  const generatePDF = () => {
    const contract = new JsPDF("portrait", "pt", "a3")
    contract.html(document.querySelector("#zithasInvoicePrint")).then(() => {
      contract.save("zithasContract.pdf")
    })
  }
  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  const [description, setDescription] = useState("")
  const [date, setDate] = useState("")
  const [company_name, setCompnayName] = useState("")
  const [expire, setExpire] = useState()
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [note, setNote] = useState("")
  const [symbol, setCurrencySymbol] = useState("")
  const [contract_type, setContractType] = useState([])
  const [contract_value, setContractValue] = useState("")

  const [attachment, setAttachment] = useState("")

  useEffect(() => {
    fetchContract()
  }, [])

  const fetchContract = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("junk_link", params.id)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/show_contract`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data.success == true) {
          setExpire(false)
          setDescription(response.data.data.contract[0]?.template_formate)
          setDate(response.data.data.contract[0]?.created_at)
          setContractId(response.data.data.contract[0]?.id)
          setCompnayName(response.data.data.contract[0]?.company_name)
          setCurrencySymbol(response.data.data.contract[0]?.currency_symbol)
          setContractValue(response.data.data.contract[0]?.value)
          setStartDate(response.data.data.contract[0]?.startdate)
          setEndDate(response.data.data.contract[0]?.enddate)
          setContractType(response.data.data.contract[0]?.contract_type)
          setNote(response.data.data.contract[0]?.note)
        } else {
          setExpire(true)
        }
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [renew, setRenew] = useState("")
  const [contract_id, setContractId] = useState("")

  const signatureRef = useRef(null)
  const [signature, setSignature] = useState("")
  const [sign_name, setSignName] = useState("")

  const handleSave = async e => {
    e.preventDefault()
    const signatureImage = signatureRef.current.toDataURL()
    setLoading(true)
    const formData = new FormData()
    formData.append("contract_id", contract_id)
    formData.append("name", name)
    formData.append("email", email)
    formData.append("signature", signatureImage)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/store_signature`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log("response",response)
        if (response.data.success == true) {
          Swal.fire({
            icon: "success",
            text: "Thanks For signining Contract",
          }).then(data => {
            setmodal_center(false)
            // setSignature(response.data.data[0].signature)
            // setSignName(response.data.data[0].name)
            window.location.reload()
          })
        } else {
          if (response.data.message == "error") {
            Swal.fire({
              icon: "warning",
              text: "Please Enter Same Name and Email Address",
            }).then(data => {})
          } else if (response.data.message == "authorized") {
            Swal.fire({
              icon: "warning",
              text: "You are not authorized to sign contracts.",
            }).then(data => {})
          }

          // if (response.data.message == "matched") {
          //   swal({
          //     icon: "warning",
          //     text: "Please Enter Same Name and Email Address",
          //   }).then(data => { })
          // } else if (response.data.message == "authorized") {
          //   swal({
          //     icon: "warning",
          //     text: "You are not authorized to sign contracts.",
          //   }).then(data => { })
          // }
        }

        setLoading(false)
      })
    } catch (err) {
      setLoading(false)
    }
  }
  const handleClear = () => {
    signatureRef.current.clear()
  }

  const handleAttachmentSave = async e => {
    e.preventDefault()
    if (attachment?.length > 0) {
      try {
        setLoading(true)
        const formData = new FormData()
        formData.append("contract_id", contract_id)
        formData.append("name", name)
        formData.append("email", email)
        formData.append("signature", attachment)

        const response = await axios.post(
          `${configure.apiUrl}/contract/save_contract`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        setLoading(false)
        if (response.data.success == true) {
          Swal.fire("", "Thanks For signining Contract", "success")
          setName("")
          setEmail("")
          setAttachmentModal(false)
        }

        // console.log("response", response.data)
      } catch (error) {
        console.log(error)
        setLoading(false)
        Swal.fire("", "Something went wrong!", "error")
      }
    }
  }

  const handleToBase24 = e => {
    if (
      e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png"
    ) {
      const image_data = new FileReader()
      image_data.addEventListener("load", () => {
        setAttachment(image_data.result)
      })
      image_data.readAsDataURL(e.target.files[0])
    } else {
      Swal.fire("", "Only JPG and PNG images can upload!", "warning")
    }
  }

  if (expire == false) {
    return (
      <React.Fragment>
        {isLoading && <Loader />}
        <div className="page-content mb-2" style={{ paddingTop: "60px" }}>
          <Container fluid>
            <Row>
              <div className="col-md-9 col-12 ps-md-0 order-md-1 order-2">
                <div
                  id="zithasInvoicePrint"
                  className="bg-white p-3 shadow document"
                >
                  <div className="pb-2 text-center">
                    <img src={zithasLogo} height="60" alt="zithasLogo" />
                  </div>
                  <div>
                    <h5 className="text-center mt-4 mb-4 fw-bold">
                      {/* {template_name} */}
                    </h5>

                    <div>
                      <div
                        style={{ textAlign: "justify" }}
                        className="Features"
                        dangerouslySetInnerHTML={{
                          __html: description
                            .replaceAll("{", "")
                            .replaceAll("}", ""),
                        }}
                      />
                    </div>

                    <h5 className="fw-bold text-decoration-underline">
                      Date : {moment(date).format("DD-MM-YYYY")}
                    </h5>

                    <Row className="mt-4 justify-space-between">
                      <div className="col-md-4">
                        <div className="sign-box">Signature</div>
                        <h5 className="mb-0 mt-3">Julius Gohil</h5>
                        <p className="mb-0">Managing Director</p>
                        <p>For Zithas Technologies</p>
                      </div>
                      {/* <div className="col-4">
                        {signature?.length == 0 ? (
                          <div className="sign-box">Signature</div>
                        ) : (
                          <img src={signature} height="50" className="mb-2" />
                        )}
                        <h5 className="mt-3">{sign_name}</h5>
                        <p>For {company_name}</p>
                      </div> */}
                    </Row>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-12 pe-md-0 order-md-2 order-1 mb-md-0 mb-3">
                <div
                  className={`${
                    fixedRightSec ? "fixed-right-section top-0 " : ""
                  }`}
                >
                  {/* buttons */}
                  <div className="col-12 text-md-end text-center">
                    <button
                      onClick={() => printContract()}
                      className="btn btn-sm btn-success border-radius me-1"
                    >
                      <i className="fas fa-print me-1"></i> Print
                    </button>
                    <button
                      onClick={() => setmodal_center(true)}
                      className="btn btn-sm btn-primary border-radius me-1"
                    >
                      <i className="fas fa-file-signature me-1"></i> Signature
                    </button>

                    <button
                      onClick={generatePDF}
                      className="btn btn-sm btn-secondary border-radius"
                    >
                      <i className="fas fa-file-download me-1"></i> Downloads
                    </button>
                  </div>

                  {/* contract data */}
                  <div className="bg-white p-3 mt-4 shadow">
                    <h5 className="mb-4 text-dark">
                      Contract Value : {symbol} {contract_value}
                    </h5>

                    <h6 className="mb-3">
                      Start Date: {moment(start_date).format("DD-MM-YYYY")}
                    </h6>
                    <h6 className="mb-3">
                      End Date: {moment(end_date).format("DD-MM-YYYY")}
                    </h6>
                    <h6 className="mb-3">Contract Type: {contract_type}</h6>
                    <h6>Note: {note}</h6>
                  </div>

                  <div className="bg-white p-3 mt-4 shadow ">
                    <p className="text-danger">
                      * If you encounter any issues while signing the contract
                      please sign on a piece of paper and scan the signature
                      then upload the scan here.
                    </p>

                    <div>
                      <label htmlFor="">Attachment</label>

                      <div>
                        <button
                          onClick={() => setAttachmentModal(true)}
                          className="btn btn-sm btn-primary border-radius py-2 w-100"
                        >
                          <i className="fas fa-file-signature me-1"></i> Attach
                          Your Signature
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>

        {/* Signature Modal */}
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center()
          }}
          centered
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Signature & Confirmation Of Identity
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSave}>
              <Row>
                <div className="col-md-5 mb-3">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="form-control "
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-5 mb-3">
                  <label htmlFor="">Email Address </label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control"
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="">Signature</label>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      // width: 760,
                      // height: 300,
                      className: "sigCanvas border w-100 signatureHeight",
                    }}
                    ref={signatureRef}
                  />
                </div>
              </Row>
              <div className="border-bottom my-3"></div>
              <div className="text-end">
                <a className="btn btn-red btn-label me-2" onClick={handleClear}>
                  <i className="bi bi-x-circle label-icon"></i>
                  Clear Signature
                </a>
                <button className="btn btn-save btn-label">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </Modal>
        {/* ------------ */}

        {/* Attachment Modal */}
        <Modal isOpen={attachmentModal} centered size="md">
          <div className="modal-header">
            <h5 className="modal-title mt-0">Attach Your Signature</h5>
            <button
              type="button"
              onClick={() => {
                setAttachmentModal(false)
              }}
              className="btn-modal-close"
            >
              <span className="btn-modal-span">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleAttachmentSave}>
              <Row>
                <div className="col-md-6 mb-3">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="form-control"
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="">Email Address </label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="form-control"
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="">Signature Attachment</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleToBase24}
                  />
                </div>

                {attachment && (
                  <div>
                    <img src={attachment} alt="" height={"80px"} />
                  </div>
                )}
              </Row>
              <div className="border-bottom my-3"></div>
              <div className="text-end">
                <button className="btn btn-save btn-label" type="submit">
                  <i className="fas fa-save me-2 label-icon"></i>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </Modal>
        {/* ------------ */}
      </React.Fragment>
    )
  } else if (expire == true) {
    return (
      <section className="my-5 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center col">
              <div className="home-wrapper">
                <div className="mb-5">
                  <a
                    className="d-block auth-logo"
                    href={`${configure.appUrl}dashboard`}
                  ></a>
                </div>
                <div className="justify-content-center row">
                  <div className="col-sm-4">
                    <div className="maintenance-img">
                      <img
                        src="/static/media/maintenance.938d28ce46a03edf5d9303e67f1d2028.svg"
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <img src={zithasLogo} className="" height="100" />
                </div>
                <h1 className="pt-5">Thanks For signining Contract</h1>
                <h5 className="mt-2">We'll get in contact with you shortly </h5>

                <div className="mt-5">
                  <button
                    className="border-radius btn text-white me-2 btn-purple"
                    onClick={() => window.close()}
                  >
                    <i className="dripicons-cross me-2 icon-center"></i>Close
                    Browser Tab
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return <Loader />
  }
}

export default GenerateLinkContract
