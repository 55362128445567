import React, { useEffect, useState } from "react"
import {
  Badge,
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import ReactSelect from "react-select"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import NewPagination from "components/Common/Newpagination"
// import ProposalPdf from "./ProposalPdf"

// for pdf
// assets
import "../../../assets/css/Sales/Sales.css"
import "../../../assets/css/Sales/Proposal.css"

import InfoGif from "../../../assets/images/GIF/question-mark.gif"
// components
// import ViewProfile from "./ViewProposal/ViewProfile"
// import ViewActivity from "./ViewProposal/ViewActivity"
import axios from "axios"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
import configure from "configure"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import "../../../assets/css/Work/projects.css"
import ViewBasicProjectinfo from "./ViewProject/ViewBasicProjectInfo"
import ViewNotes from "./ViewProject/ViewNotes"
import ViewActivityLogs from "./ViewProject/ViewActivityLogs"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import FixedProjectHeader from "./FixedProjectHeader"
// import Support from "./Maintenance/Support"
import EditMTask from "./Maintenance/EditMTask"
import renewal from "../../../assets/images/renewable.png"
import renewImg from "../../../assets/images/Project/renewImg.jpg"
import Swal from "sweetalert2"
import ViewCredential from "./ViewProject/ViewCredential"

function TableContainer({
  setProjectTypeChange,
  columns,
  data,
  setData,
  allData,
  setAllCol,
  setHeaderDateTxt,
  setAccessDenied,
  setFilter,
  filter,
  projectType,
  setProjectType,
}) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  const [name_modal, setNameModal] = useState(false)
  const [title, setTitle] = useState("")

  const info = [
    {
      title: "Assigned",
      mean: " <p> <strong>Meaning :</strong> In the Mars System's Lead Module, the <strong>Assigned </strong> column designates the specific team member or individual responsible for overseeing and taking action on a lead or potential business opportunity. </p>",
      purpose:
        " <p> <strong>Purpose :</strong> The purpose of the <strong>Assigned</strong> column in the Lead Module is to assign clear accountability for each lead. By identifying who is responsible for nurturing and converting leads into customers, it streamlines lead management, ensures follow-up, and enhances the chances of successful lead conversion. This fosters a more efficient and organized approach to lead handling within the Mars System.</p>",
    },
    {
      title: "Name",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Name</strong> column represents the name of the customer or organization for whom the proposal is being prepared.</p>",
      purpose:
        "<p> <strong>Purpose :</strong> The purpose of the <strong>Name</strong>  column is to provide specific identification of the recipient of the proposal. It ensures that each proposal is customized and addressed to the correct customer or organization, creating a personalized and professional impression. This column is crucial for accurate proposal documentation and effective communication within the Mars System, helping to build strong customer relationships and tailor proposals to individual needs.</p>",
    },
    {
      title: "Type",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Type</strong>  column categorizes proposals into distinct types, such as <strong>General</strong>, <strong>Website</strong>, <strong>SEO </strong>, <strong>Digital Marketing</strong>, or <strong>Dedicated Resource.</strong> </p>",
      purpose:
        "</strong> <p> <strong>Purpose :</strong> The purpose of the <strong>Type</strong> column is to provide specific information about the person linked to the lead. It allows for personalized communication and relationship-building with leads. By knowing the names of potential clients or contacts, the Mars System's users can establish a more personal and targeted approach to lead engagement, which can significantly improve lead conversion rates and customer satisfaction</p>",
    },
    {
      title: "Currency",
      mean: "<p><strong>Meaning :</strong> In the Mars System's Proposal Module, the  <strong>Currency</strong> column specifies the currency in which the proposal's pricing and financial details are presented.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Currency</strong> column is to provide clarity and transparency regarding the currency in which the proposal's costs and pricing are expressed. This ensures that both your team and the client understand the financial terms of the proposal. By clearly indicating the currency, you facilitate accurate pricing and financial communication, especially in international or multi-currency transactions within the Mars System.</p>",
    },
    {
      title: "Price",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong>Price</strong> column denotes the total cost or pricing details associated with the proposal, which may include itemized costs for services, products, or any other expenses.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Price</strong>  column is to provide a clear and itemized breakdown of the costs associated with the proposal. It allows both your team and the client to understand the financial aspects of the proposal, making it easier to review and negotiate terms. This column ensures transparency in pricing and supports informed decision-making within the Mars System, helping to reach agreements that align with both parties' expectations.</p>",
    },
    {
      title: "Created At",
      mean: " <p><strong>Meaning :</strong> In the Mars System's Proposal Module, the <strong>Created At</strong> column records the date and time when the proposal was initially created or entered into the system.</p>",
      purpose:
        "<p><strong>Purpose :</strong> The purpose of the <strong>Created At</strong> column is to track and document the proposal's creation timeline. It provides valuable information about the proposal's age and origin, allowing users to gauge its recency and relevance. This column aids in organizing proposals chronologically, assessing the efficiency of the proposal creation process, and ensuring that proposals are up-to-date and timely managed within the Mars System.</p>",
    },
    {
      title: "Open Till",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong> Open Till</strong>column signifies the date until which the proposal remains valid and open for consideration by the client.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong> Open Till</strong> column is to establish a clear expiration date for the proposal's validity. It ensures that both your team and the client are aware of the timeframe within which the proposal can be accepted or negotiated. This column helps manage proposal timelines, avoid misunderstandings, and maintain accurate records of proposal status within the Mars System.</p>",
    },
    {
      title: "Status",
      mean: "<p><strong>Meaning :</strong>  In the Mars System's Proposal Module, the <strong>Status</strong> column categorizes proposals into different stages, including <strong>In Progress </strong>, <strong>On Hold </strong>, <strong>Lost </strong>, or <strong>Won </strong>.</p>",
      purpose:
        "<p><strong>Purpose :</strong>  The purpose of the  <strong>Status</strong> column is to provide a quick overview of the current state of each proposal. It helps users track and manage the progression of proposals, from creation to closure. By categorizing proposals into these stages, the Mars System facilitates efficient proposal management, allowing teams to focus their efforts on active proposals, prioritize follow-ups, and accurately report on the status of each proposal. This column aids in effective decision-making and resource allocation.</p>",
    },
  ]

  const [viewData, setViewData] = useState(false)
  const [isLoading, setLoading] = useState(false)

  return (
    <div>
      {isLoading && <Loader />}
      {/* fixed buttons */}

      <FixedProjectHeader
        setProjectTypeChange={setProjectTypeChange}
        columns={filter == "all" ? columns : "None"}
        data={data}
        setData={setData}
        allData={allData}
        setAllCol={setAllCol}
        setHeaderDateTxt={setHeaderDateTxt}
        setAccessDenied={setAccessDenied}
        setFilter={setFilter}
        filter={filter}
        project="true"
        allProjects="true"
        onHold="true"
        canceled="false"
        finished="true"
        notStarted="false"
        inProgress="false"
        projectType={projectType}
        setProjectType={setProjectType}
      />

      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-DR-project-header" : "fix-table-header"
            } `}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      // {...column.getHeaderProps()}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div
                        className={`position-relative d-flex ${
                          column.isShortIcon
                            ? "justify-content-between"
                            : "justify-content-center"
                        }`}
                      >
                        {index === 0 && <span></span>}
                        <span
                          style={{
                            position: index === 0 ? "absolute" : "",
                            left: index === 0 ? "45%" : "",
                          }}
                        >
                          {column.render("Header")}
                          {column.isInfoIcon ? (
                            <a
                              onClick={() => {
                                setNameModal(true)
                                setTitle(column.Header)
                              }}
                            >
                              <i
                                title="info"
                                className="bx bx-info-circle ms-1 info-icon"
                              ></i>
                            </a>
                          ) : null}
                        </span>
                        {/* // Info Icon  */}

                        {column.isShortIcon ? (
                          <span
                            style={{
                              position: index != 0 ? "absolute" : "",
                              right: index != 0 ? "0" : "",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody className="fix-table" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const { did_col: did, pwd_col: pwd } = row.original
              const status = row.original.status_name

              let backgroundStyle = {}

              if (did <= 0 && pwd <= 0) {
                backgroundStyle = {
                  background:
                    "linear-gradient(90deg, rgba(190,49,8,1) 100%, rgba(242,222,9,1) 0%)",
                }
              } else if (did <= 0 && pwd === 0) {
                backgroundStyle = {
                  background:
                    "linear-gradient(90deg, rgba(190,49,8,1) 75%, rgba(242,222,9,1) 25%)",
                }
              } else if (did === 0 && pwd >= 0) {
                backgroundStyle = {
                  background:
                    "linear-gradient(90deg, rgba(190,49,8,1) 50%, rgba(242,222,9,1) 50%)",
                }
              } else if (did >= 0 && pwd >= 0) {
                backgroundStyle = {
                  background:
                    "linear-gradient(90deg, rgba(190,49,8,1) 25%, rgba(242,222,9,1) 75%)",
                }
              } else {
                backgroundStyle = {}
              }

              return (
                <tr {...row.getRowProps()} key={i} style={backgroundStyle}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      className={`t-col-${index + 1} ${cell.column.className}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr style={{ backgroundColor: "unset" }}>
                <td className="text-center" colSpan={10}>
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            {title}
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                {info.map((item, i) => {
                  if (item.title == title) {
                    return (
                      <div key={i}>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.mean }}
                          ></span>
                        </span>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.purpose }}
                        ></div>
                      </div>
                    )
                  }
                })}
                <div></div>
                <div className="text-center mt-70">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal size="md" centered isOpen={viewData} className="activity-log">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Log Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewData(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th>Currency</th>
                  <th>All</th>
                  <th>Sent</th>
                  <th>Lost</th>
                  <th>Won</th>
                </tr>
              </thead>
              <tbody>
                {/* {viewData.map((items, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{items}</th>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: oldValue[index],
                          }}
                        />
                      </td>
                      <td>

                      </td>
                    </tr>
                  )
                })} */}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const MaintenanceProject = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [viewId, setViewId] = useState("")
  const [daysRemaining, setDaysRemaining] = useState(false)
  const [formRows, setFormRows] = useState([{}])
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])

  const [filter, setFilter] = useState("all")
  const [allCol, setAllCol] = useState(true)
  const [headerDateTxt, setHeaderDateTxt] = useState("")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setmodal_fullscreen(false), setAddTask(false), setDaysRemaining(false)
  })

  //meta title
  document.title = "Dedicated Resources | Zithas Technologies"

  const [view_proposal, setViewProposal] = useState([])
  const [isLoading, setLoading] = useState(false)

  const [proposal_activity, setProposalActivity] = useState([])
  const [proposal_revision, setProposalRevision] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const [addTask, setAddTask] = useState(false)
  const [reNewProject, setRenewProject] = useState(false)

  const SelectStatus = [
    { id: 1, value: "Not Started" },
    { id: 2, value: "In Progress" },
    { id: 3, value: "On Hold" },
    { id: 4, value: "Cancelled" },
    { id: 5, value: "Finished" },
  ]

  const milestone = [
    { label: "Milestone 1", value: "Milestone 1" },
    { label: "Milestone 2", value: "Milestone 2" },
    { label: "Milestone 3", value: "Milestone 3" },
    { label: "Milestone 4", value: "Milestone 4" },
    { label: "Milestone 5", value: "Milestone 5" },
  ]
  const Assigned = [
    { label: "Rahul", value: "Rahul" },
    { label: "Divyansh", value: "Divyansh" },
    { label: "Manav", value: "Manav" },
  ]
  const Priority = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ]
  const Estimation_Hours = [
    { label: "1 hour", value: "1 hour" },
    { label: "2 Hours", value: "2 Hours" },
    { label: "3 Hours", value: "3 Hours" },
  ]

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }
  const navigate = useNavigate()

  const [status, setStatus] = useState("")
  const [isPaused, setIsPaused] = useState(false)
  const [projectType, setProjectType] = useState("1")

  const [projectTypeChange, setProjectTypeChange] = useState("1")

  const handleChangeStatus = async (e, id, pro) => {
    const status = e.target.value
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 25)
    try {
      const responseP = await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (responseP) {
        setLoading(false)
        if (
          responseP?.data?.data?.india[4] == 1 ||
          responseP?.data?.data?.uk[4] == 1
        ) {
          Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You want to change status.",
            showCancelButton: true,
          }).then(async result => {
            if (result.isConfirmed) {
              try {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("role_id", localStorage.getItem("auth_role"))
                formData.append("project_id", id)
                formData.append("status", status)

                const response = await axios.post(
                  `${configure.apiUrl}/changeProjectStatus`,
                  formData,
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                if (response) {
                  const filterTabData = response?.data?.data?.project2?.filter(
                    item => item.project_type === 3
                  )

                  let filteredData
                  let headerDateTxt

                  switch (pro) {
                    // case "2":
                    //   filteredData = filterTabData.filter(
                    //     item => item.status === "Not Started"
                    //   )
                    //   break
                    // case "3":
                    //   filteredData = filterTabData.filter(
                    //     item => item.status === "In Progress"
                    //   )
                    //   break
                    case "4":
                      filteredData = filterTabData.filter(
                        item => item.status === "On Hold"
                      )
                      headerDateTxt = "On Hold Date"
                      break
                    // case "5":
                    //   filteredData = filterTabData.filter(
                    //     item => item.status === "Cancelled"
                    //   )
                    //   headerDateTxt = "Cancelled Date"
                    //   break
                    case "6":
                      filteredData = filterTabData.filter(
                        item => item.status === "Finished"
                      )
                      headerDateTxt = "Finished Date"
                      break
                    case "1":
                      filteredData = filterTabData
                      headerDateTxt = "Due date"
                      break
                    default:
                      filteredData = []
                  }

                  setData(filteredData)
                  setHeaderDateTxt(headerDateTxt || "")
                  setAllData({
                    ...response?.data?.data,
                    counts: filterTabData.length,
                    onhold: filterTabData.filter(
                      item => item.status === "On Hold"
                    ).length,
                    notstart: filterTabData.filter(
                      item => item.status === "Not Started"
                    ).length,
                    Cancelled: filterTabData.filter(
                      item => item.status === "Cancelled"
                    ).length,
                    finished: filterTabData.filter(
                      item => item.status === "Finished"
                    ).length,
                    InProgress: filterTabData.filter(
                      item => item.status === "In Progress"
                    ).length,
                    maintenance: filterTabData.filter(
                      item => item.status === "Maintenance"
                    ).length,
                    project2: filterTabData,
                  })
                  Swal.fire({
                    icon: "success",
                    title: "Status has been changed successfully!",
                  })
                }
              } catch (error) {
                console.error(error)
              } finally {
                setLoading(false)
              }
            }
          })
        } else {
          setAccessDenied(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns1 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center project_id",
      },
      {
        Header: "Project Name",
        title: "Project Name",
        accessor: "project_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_name_box",
      },
      {
        Header: "Customer",
        title: "Customer",
        accessor: "customer",
        isInfoIcon: false,
        isShortIcon: true,
        className: "customer_name_box",
      },
      {
        Header: "Start Date",
        title: "Start Date",
        accessor: "start_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: "start_date_box text-center",
        Cell: cellProps => {
          return (
            <>{moment(cellProps.row.original.startdate).format("DD-MM-YYYY")}</>
          )
        },
      },
      {
        Header: headerDateTxt,
        title: "End Date",
        accessor: "end_date",
        isInfoIcon: false,
        isShortIcon: true,
        className: `start_date_box text-center ${
          allCol == true ? "d-none" : ""
        }`,
        Cell: cellProps => {
          return (
            <>
              {cellProps.row.original.enddate
                ? moment(cellProps.row.original.enddate).format("DD-MM-YYYY")
                : ""}
            </>
          )
        },
      },
      // {
      //   Header: "P.W.D",
      //   title: "Pending Working Days",
      //   accessor: "pwd_col",
      //   isInfoIcon: false,
      //   className: "pwd_box text-center",
      // },
      {
        Header: "Days",
        title: "Last Updated Days",
        accessor: "days",
        isInfoIcon: false,
        isShortIcon: true,
        className: `projects_days_box text-center ${
          allCol == false ? "d-none" : ""
        }`,
      },
      {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "project_status_box text-center",
        Cell: cellProps => {
          return (
            <div className="custom-select-wrapper" style={{ width: "100%" }}>
              <select
                className={`form-select custom-select ${
                  status === "inprogress"
                    ? "status_inprogress"
                    : status === "awaitingfeedback"
                    ? "status_await"
                    : status === "complete"
                    ? "status_complete"
                    : ""
                }`}
                value={cellProps.row.original.status}
                onChange={e =>
                  handleChangeStatus(
                    e,
                    cellProps.row.original.id,
                    projectTypeChange
                  )
                }
              >
                <option value="" className="" selected>
                  - Select -
                </option>
                <option value="On Hold" className="" selected>
                  On Hold
                </option>
                {/* <option value="In Progress" className="">
                  In Progress
                </option>
                <option value="Awaiting Feedback" className="">
                  Awaiting Feedback
                </option> */}
                <option value="Finished" className="">
                  Finished
                </option>
                {/* <option value="Complete" className="">
                  Complete
                </option> */}
              </select>
            </div>
          )
        },
      },
      // {
      //   Header: "D.I.D",
      //   title: "Days Remaining For Project Delivery",
      //   accessor: "did_col",
      //   isInfoIcon: false,
      //   className: "did_box text-center",
      // },

      {
        Header: "Action",
        accessor: "action",
        className: "project_action text-center",
        Cell: cellProps => {
          const onDelete = async e => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 25)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to Delete this Project !",
                    icon: "warning",
                    showCancelButton: true,
                  }).then(async willDelete => {
                    if (willDelete.isConfirmed) {
                      setLoading(true)
                      const formData = new FormData()
                      formData.append(
                        "user_id",
                        localStorage.getItem("auth_id")
                      )
                      formData.append(
                        "role_id",
                        localStorage.getItem("auth_role")
                      )
                      formData.append("project_id", cellProps.row.original.id)
                      try {
                        const response = await axios({
                          method: "post",
                          url: `${configure.apiUrl}/delete_project`,
                          data: formData,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        }).then(response => {
                          Swal.fire({
                            icon: "success",
                            text: "Project Deleted Successfully",
                          }).then(data => {
                            setLoading(false)
                            const filterTabData =
                              response?.data?.data?.project2?.filter(item => {
                                return item.project_type == 3
                              })
                            setAllData(response?.data?.data)
                            setData(filterTabData)
                          })
                          setLoading(false)
                        })
                      } catch (err) {
                        Swal.fire({
                          icon: "error",
                          text: "SomeThing Went Wrong",
                        })
                        setLoading(false)
                      }
                    }
                  })
                } else {
                  setAccessDenied(true)
                }
              })
            } catch (error) {
              console.log(error)
            }
          }

          const onEditPermission = async () => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 25)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                setLoading(false)
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  navigate(
                    `${configure.appUrl}work/edit-maintenance-project/${cellProps.row.original.id}`
                  )
                } else {
                  setAccessDenied(true)
                }
              })
            } catch (error) {
              setLoading(false)
              console.log(error)
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-sm btn-outline-secondary me-1"
                title="View Project"
                onClick={() => {
                  setViewId(cellProps.row.original.id)
                  setmodal_fullscreen(true)
                }}
              >
                <i className="fas fa-eye "></i>
              </a>

              <a
                onClick={onEditPermission}
                className="btn btn-sm btn-outline-secondary me-1"
                title="Edit Project"
              >
                <i className="fas fa-pen "></i>
              </a>

              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link
                    title="Add Task"
                    className="dropdown-item"
                    onClick={() => setAddTask(true)}
                  >
                    <i className="bi bi-list-check pr-10"></i> Add Task
                  </Link>

                  <Link
                    title="Renewal Project"
                    className="dropdown-item"
                    onClick={() => setRenewProject(true)}
                  >
                    {/* <i className="bi bi-list-check pr-10"></i> Renewal Project  */}
                    <img
                      src={renewal}
                      alt=""
                      className="pr-10"
                      style={{ color: "black", width: "17px" }}
                    />{" "}
                    Renewal Project
                  </Link>

                  {/* <Link
                    title="Add Task"
                    className="dropdown-item"
                    onClick={() => setDaysRemaining(true)}
                  >
                    <i className="bi bi-people-fill pr-10"></i> Days Remaining
                  </Link> */}
                  {isPaused ? (
                    <a
                      title="Play Project"
                      className="dropdown-item"
                      onClick={() => setIsPaused(false)}
                    >
                      <i className="bi bi-play-circle-fill pr-10"></i> Play
                      Project
                    </a>
                  ) : (
                    <a
                      title="Pause Project"
                      className="dropdown-item"
                      onClick={() => setIsPaused(true)}
                    >
                      <i className="bi bi-pause-circle-fill pr-10"></i> Pause
                      Project
                    </a>
                  )}

                  <a
                    title="Delete Project"
                    className="dropdown-item"
                    onClick={() => onDelete()}
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Project
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [status, isPaused, headerDateTxt, projectTypeChange]
  )

  const staff = [
    { label: "Rahul", value: "Rahul" },
    { label: "Divyansh", value: "Divyansh" },
    { label: "Mohit", value: "Mohit" },
  ]

  const fatchAllTabData = async () => {
    // knowledgeTopics
    setLoading(true)
    // role_id,user_id
    const roleId = localStorage.getItem("auth_role")
    const authId = localStorage.getItem("auth_id")
    const formData = new FormData()
    formData.append("role_id", roleId)
    formData.append("user_id", authId)

    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/projects`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (response) {
        setLoading(false)
        const filterTabData = response?.data?.data?.project2?.filter(item => {
          return item.project_type == 3
        })
        setAllData({
          ...response?.data?.data,
          counts: filterTabData.length,
          onhold: filterTabData.filter(item => item.status === "On Hold")
            .length,
          notstart: filterTabData.filter(item => item.status === "Not Started")
            .length,
          Cancelled: filterTabData.filter(item => item.status === "Cancelled")
            .length,
          finished: filterTabData.filter(item => item.status === "Finished")
            .length,
          InProgress: filterTabData.filter(
            item => item.status === "In Progress"
          ).length,
          maintenance: filterTabData.filter(
            item => item.status === "Maintenance"
          ).length,
          project2: filterTabData,
        })
        setData(filterTabData)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    fatchAllTabData()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      setProjectTypeChange={setProjectTypeChange}
                      columns={columns1}
                      data={data}
                      setData={setData}
                      allData={allData}
                      setAccessDenied={setAccessDenied}
                      setFilter={setFilter}
                      filter={filter}
                      projectType={projectType}
                      setProjectType={setProjectType}
                      setAllCol={setAllCol}
                      setHeaderDateTxt={setHeaderDateTxt}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Task  */}
      <div className={`note-sticky-form ${addTask ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Add Task</h5>
              <button
                type="button"
                onClick={() => {
                  setAddTask(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pe-4">
              <Form className="row d-flex justify-content-center align-items-center">
                <div className="my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => setAddTask(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12  ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    {/* <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Milestone <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Milestone"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={milestone}
                      />
                    </div> */}
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={Estimation_Hours}
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability (%) <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Priority"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={Priority}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-md-12 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Assigned <span className="text-danger">*</span>
                      </Label>
                      <ReactSelect
                        placeholder="Select Staff"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={Assigned}
                        isMulti="true"
                      />
                    </div>
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={editor => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                        }}
                      />
                    </div>
                  </div>
                  <Row className="bg-white px-2 py-2 shadow mb-3">
                    <div className="d-flex justify-content-end align-items-end px-0">
                      <div className="text-end mb-3">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            onAddFormRow()
                          }}
                          className="btn btn-purple  "
                        >
                          <i className="fas fa-plus me-2"></i>
                          Add Subtask
                        </button>
                      </div>
                    </div>
                    {formRows?.map((person, key) => {
                      return (
                        <div key={key} className="">
                          <div className="d-flex">
                            <div className="contact-form d-flex col-md-11">
                              <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                <label htmlFor="">Subtask Name</label>
                                <input
                                  type="text"
                                  name="task_name"
                                  placeholder="Enter Subtask Name"
                                  className="form-control "
                                />
                              </div>
                            </div>
                            <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                              <div className="text-end">
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    onDeleteFormRow(key)
                                  }}
                                  className="btn btn-red"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          {key !== formRows.length - 1 ? (
                            <div className="border-bottom my-3"></div>
                          ) : null}
                        </div>
                      )
                    })}
                  </Row>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Renew Project  */}
      <div
        className={`note-sticky-form ${reNewProject ? "d-block" : "d-none"}`}
      >
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "50%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Renew Project</h5>
            </div>

            <div className="modal-body pe-4">
              <Form
                // onSubmit={handleEditSubmit}
                className="row d-flex justify-content-center align-items-center"
              >
                <div className="col-md-4 d-flex justify-content-end align-items-center ps-3">
                  <div className="text-center">
                    <img src={renewImg} alt="Addepe" style={{ width: "90%" }} />
                  </div>
                </div>

                <div className="col-md-7 d-flex flex-column ">
                  <div className="col-12 d-flex gap-4 mb-3 flex-wrap">
                    <div className="">
                      <label htmlFor="">Start Date</label>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="">
                      <label htmlFor="">End Date</label>
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Select Developers</label>
                    <ReactSelect
                      isMulti="true"
                      placeholder="Select Developers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={staff}
                      menuPlacement="top"
                    />
                  </div>

                  <div className="my-4 text-end">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setRenewProject(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      {/* // View Project Modal  */}
      <Modal size="xl" isOpen={modal_fullscreen} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="col-6 d-flex align-items-center">
              <h5 className=" modal-title mt-0">View Project Details</h5>
            </div>
          </div>

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab h-100 py-3 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Basic Project Details
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Milestones
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Support
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Notes
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Activity Log
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Credential
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="px-4 h-100 pl-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <ViewBasicProjectinfo viewId={viewId} />
                  </TabPane>
                  {/* <TabPane tabId="2">
                    <ViewMileStones />
                  </TabPane> */}
                  {/* <TabPane tabId="3">
                    <ViewTasks />
                  </TabPane> */}
                  <TabPane tabId="3">
                    <EditMTask />
                  </TabPane>
                  <TabPane tabId="4">
                    <ViewNotes />
                  </TabPane>
                  <TabPane tabId="5">
                    <ViewActivityLogs />
                  </TabPane>
                  <TabPane tabId="6">
                    <ViewCredential viewId={viewId} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default MaintenanceProject
