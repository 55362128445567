import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useMemo } from "react"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import sideImg from "../../../../assets/images/profile-img.png"
// assets
import "../../../../assets/css/Sales/Sales.css"
import "../../../../assets/css/Sales/Contract.css"
import assign from "../../../../assets/images/users/avatar-1.jpg"

// common component
import Pagination from "components/Common/Pagination"
import ProfileDetails from "components/Common/ProfileDetails"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"

// components

function TableContainer({ columns, data }) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state
  useEffect(() => setPageSize(50), [])

  // Render the UI for your table
  return (
    <div>
      <div
        className={`flex-column fixed-buttons ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn"
        >
          <i className="dripicons-cross me-2 icon-center"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col md={3}>
          <select
            className="border-radius me-4 form-select"
            style={{ width: "100px" }}
            value={pageSize}
            onChange={e => setPageSize(e.target.value)}
          >
            {[10, 25, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        <Col md={9} className="d-flex justify-content-end">
          <div className="">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="position-relative">
        <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${fixedContent ? "fixed-header" : ""} contract-colums`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`proj-t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="contract-colums" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        onClick={() =>
                          cell.column.Header === "Company"
                            ? navigate(`"${configure.appUrl}sales/edit-contract`)
                            : ""
                        }
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        } ${
                          cell.column.Header === "Signature"
                            ? cell.value === "Not-Signed"
                              ? "text-danger"
                              : "text-success"
                            : ""
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {data.length == 0 ? (
              <td
                colSpan="10"
                className="text-center"
                style={{ padding: "10px", border: "none" }}
              >
                No Project Available for this Customer
              </td>
            ) : null}
          </tbody>
        </Table>
      </div>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>
    </div>
  )
}

const ViewSupport = ({ customer_support }) => {
  //meta title
  document.title = "Contract | Zithas Crm"



  const params = useParams()
  const [isLoading, setLoading] = useState(false)

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isInfoIcon: false,
        className: "text-center",
      },
      {
        Header: "Assigned",
        accessor: "assign",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex align-items-center">
              <div className="profileImg-box">
                <img
                  src={`https://mars.zithas.com/application/public/${cellProps.row.original.assigned_avatar}`}
                  alt=""
                  className="assigned-img mx-1"
                  height="20"
                  width="20"
                />
                <div className="profileImg-detail">
                  <div className="overflow-hidden mb-0 card">
                    <div className="bg-primary bg-soft">
                      <div className="row">
                        <div className="col-8">
                          <div className="text-primary text-start p-3">
                            <h5 className="text-primary ">
                              {cellProps.row.original.assigned}
                            </h5>
                            <p style={{ width: "200px" }}>
                              {cellProps.row.original.assigned_designation}
                            </p>
                          </div>
                        </div>
                        <div className="align-self-end col-4">
                          <img src={sideImg} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="pt-0 mb-0 card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="avatar-md profile-user-wid mb-2">
                            <img
                              src={`https://mars.zithas.com/application/public/${cellProps.row.original.assigned_avatar}`}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className=" text-start">
                            <div className="row">
                              <div className="pe-1 col-5">
                                <h5 className="font-size-12 mb-1">Join Date</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {moment(
                                    cellProps.row.original
                                      ?.assigned_joining_date
                                  ).format("DD-MM-YYYY")}
                                </p>
                              </div>
                              <div className="ps-0 col-7">
                                <h5 className="font-size-12 mb-1">Email</h5>
                                <p className=" font-size-12 text-muted mb-0">
                                  {cellProps.row.original.assigned_email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cellProps.row.original.country == "1" ? (
                <img
                  src="https://mars.ztpl.net/assets/images/india.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              ) : (
                <img
                  src="https://mars.ztpl.net/assets/images/uk.png"
                  className=" mx-1 location-img"
                  alt="flag"
                  height="15"
                  width="20"
                />
              )}
            </div>
          )
        },
        isInfoIcon: true,
        className: "support-assign-width",
      },
      {
        Header: "Subject",
        accessor: "subject",
        isInfoIcon: true,
        className: "cursor-pointer",
      },
      {
        Header: "Project",
        accessor: "project_name",
        isInfoIcon: true,
      },

      {
        Header: "Priority",
        accessor: "priority",
        isInfoIcon: true,
        className: "text-center",
      },
      {
        Header: "Status",
        accessor: "status",
        isInfoIcon: true,
        Cell: cellProps => {
          let status
          if (cellProps.row.original.status == "Closed") {
            status = (
              <span className="bg-success badge">
                {cellProps.row.original.status}
              </span>
            )
          } else if (cellProps.row.original.status == "Not answered") {
            status = (
              <span className="bg-danger badge">
                {cellProps.row.original.status}
              </span>
            )
          } else if (cellProps.row.original.status == "In Progress") {
            status = (
              <span className="bg-info badge">
                {cellProps.row.original.status}
              </span>
            )
          } else {
            status = (
              <span className="bg-warning badge">
                {cellProps.row.original.status}
              </span>
            )
          }

          return <div>{status}</div>
        },
      },
      {
        Header: "Last Reply",
        accessor: "updated_at",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {moment(cellProps.row.original.updated_at).format("DD-MM-YYYY")}
            </div>
          )
        },
      },
      {
        Header: "Created",
        accessor: "created_at",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              {moment(cellProps.row.original.created_at).format("DD-MM-YYYY")}
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <a
                className="btn btn-sm btn-outline-secondary me-1"
                title="View Proposal"
              >
                <i className="fas fa-eye "></i>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Row className="mx-1">
        <Col lg={12} className="p-0">
          <Card>
            <CardBody>
              <div className="responsive-table">
                <TableContainer columns={columns} data={customer_support} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ViewSupport
