import React, { useMemo, useEffect, useState } from 'react'
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Link, useNavigate } from 'react-router-dom';
import {
    Table,
    Row,
    Col,
    Modal
} from "reactstrap";

import Pagination from 'components/Common/Pagination';

// editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const DaysEstimation = () => {

    const [viewModal, setViewModal] = useState(false)
    const [viewTaskModal, setViewTaskModal] = useState(false)

    const navigate = useNavigate();

    // table columns
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                isInfoIcon: false,
                className: 'text-center'
            },
            {
                Header: "Title",
                accessor: "title",
                isInfoIcon: false,
                Cell: cellProps => {
                    return <a onClick={() => setViewTaskModal(true)} className="text-purple">{cellProps.cell.value}</a>
                },
            },
            {
                Header: "Milestone",
                accessor: "milestone",
                isInfoIcon: false,
                Cell: cellProps => {
                    return <a onClick={() => setViewTaskModal(true)} className="text-purple">{cellProps.cell.value}</a>
                },
            },
            {
                Header: "Assign",
                accessor: "assign",
                isInfoIcon: false,
            },
            {
                Header: "KAM Hrs",
                accessor: "kamHours",
                isInfoIcon: false,
                className: "col-1 text-center"
            },
            {
                Header: "Estimation Hrs",
                accessor: "estHours",
                isInfoIcon: false,
                Cell: cellProps => {
                    return <div>
                        <input type="text" className='form-control border-radius w-75 mx-auto' />
                    </div>
                },
                className: "prob-hrs-width text-center"
            },
            {
                Header: "Probability (%)",
                accessor: "probability",
                isInfoIcon: false,
                Cell: cellProps => {
                    return <div>
                        <input type="text" className='form-control border-radius w-75 mx-auto' />
                    </div>
                },
                className: "prob-hrs-width text-center"
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: cellProps => {
                    return <div>
                        <button className='btn py-1 btn-purple text-lowercase btn-sm border-radius'>
                            <i className='bx bx-save bx- me-1 align-middle'></i>
                            Save
                        </button>
                    </div>
                },
                className: "prob-hrs-width"
            }
        ],
        []
    );

    const data = useMemo(() => [
        {
            id: 1,
            milestone: "Milestone 1",
            title: "Login Screen Changes",
            assign: "Zain marchawala",
            kamHours: "4"
        },
        {
            id: 2,
            milestone: "Milestone 2",
            title: "Design Dashboard Screen Changes",
            assign: "Zain marchawala",
            kamHours: "3"
        },
        {
            id: 3,
            milestone: "Milestone 3",
            title: "Add Client Form",
            assign: "Zain marchawala",
            kamHours: "1"
        },
        {
            id: 4,
            milestone: "Milestone 4",
            title: "Estimate in your time...",
            assign: "Zain marchawala",
            kamHours: "5"
        },
    ], [])

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        prepareRow
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, pageSize, globalFilter } = state;
    useEffect(() => setPageSize(50), [])


    const onApprove = () => {
        swal({
            title: "Are you sure?",
            text: "Do you really want to Approve",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    swal("Successfull Sent for Approval", {
                        icon: "success",
                    });
                }
            });
    }

    return (
        <>
            <div className='moveUp'>

                <Row>
                    <Col >
                        <select
                            className="border-radius me-4 form-select"
                            style={{ width: '100px' }}
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                        >
                            {[10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col className='d-flex justify-content-end align-items-start col-md-8'>
                        <div>
                            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                                <div className="position-relative">
                                    <label htmlFor="search-bar-0" className="search-label">
                                        <span id="search-bar-0-label" className="sr-only">
                                            Search this table
                                        </span>
                                        <input
                                            onChange={(e) => setGlobalFilter(e.target.value)}
                                            id="search-bar-0"
                                            type="text"
                                            className="border-radius form-control rounded h-100"
                                            placeholder={`Search...`}
                                            value={globalFilter || ''}
                                        />
                                    </label>
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </div>
                        <button onClick={onApprove} className='btn btn-success border-radius ms-3'>
                            <i className='bx bx-send me-2'></i>
                            Send For Approval
                        </button>
                    </Col>
                </Row>
                <div className='position-relative'>
                    <Table className='table table-bordered mt-3' {...getTableProps()}>
                        <thead >
                            {
                                headerGroups.map((headerGroup, i) => (
                                    <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column, index) => (
                                                <th key={index} className={`t-col-${index + 1} ${column.className}`} {...column.getHeaderProps()}>{column.render("Header")}
                                                    {
                                                        column.isInfoIcon ?
                                                            <i title='info' className='bx bx-info-circle ms-1 info-icon'></i> : null}
                                                </th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </thead>

                        <tbody {...getTableBodyProps()}>
                            {
                                page.map((row, i) => {
                                    prepareRow(row);
                                    return (
                                        <tr className='row-hover' {...row.getRowProps()} key={i}>
                                            {
                                                row.cells.map((cell, index) => {
                                                    return <td key={index}
                                                        className={`t-col-${index + 1} ${cell.column.className}`} {...cell.getCellProps()}>{cell.render("Cell")}</td>;

                                                })
                                            }
                                        </tr>
                                    );
                                })
                            }
                            {page.length === 0 && <tr><td colSpan={8} className='text-center'>No Record Found</td></tr>}
                        </tbody>
                    </Table>
                </div>

                {/* Pagination */}
                <Row className="justify-content-md-end justify-content-center align-items-center">
                    <Pagination
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        canPreviousPage={canPreviousPage}
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                    />
                </Row>
            </div>

            <Modal
                size="lg"
                isOpen={viewModal}
                toggle={() => {
                    tog_small();
                }}
                centered
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="mySmallModalLabel"
                    >
                        Information
                    </h5>
                    <button
                        onClick={() => {
                            setViewModal(false);
                        }}
                        type="button"
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body bd-span-input">
                    <Row>
                        <div className="col-md-4">
                            <label htmlFor="">Title</label>
                            {/* <div className='input-span'>input-span</div> */}
                            <input type="text" className='form-control border-radius'
                                defaultValue="Any Title"
                                disabled placeholder='Enter Title' />
                        </div>

                        <div className="col-md-2">
                            <label htmlFor="">Days</label>
                            <input type="text"
                                className='form-control border-radius'
                                defaultValue="4"
                                disabled placeholder='Enter Title' />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="">Members</label>
                            <input type="text"
                                className='form-control border-radius'
                                defaultValue="2"
                                disabled placeholder='Enter Title' />
                        </div>

                        <div className="col-12 mt-4">
                            <CKEditor
                                editor={ClassicEditor}
                                data="<div>
                                        <h4>Account Settings (View Profile)</h4>
                                        <ol>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                        </ol>
                                    </div>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                }}
                            />
                        </div>
                    </Row>
                    {/* <div className='border-bottom mb-3'>
                    </div>
                    <div className='text-end'>
                        <button className='btn btn-purple border-radius'>Save</button>
                    </div> */}
                </div>
            </Modal>

            {/* view task modal */}
            <Modal
                size="lg"
                isOpen={viewTaskModal}
                centered
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="mySmallModalLabel"
                    >
                        Task Details
                    </h5>
                    <button
                        onClick={() => {
                            setViewTaskModal(false);
                        }}
                        type="button"
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body bd-span-input">
                    <Row>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="">Hours</label>
                            {/* <div className='input-span'>input-span</div> */}
                            <input type="text" className='form-control border-radius'
                                defaultValue="4"
                                disabled placeholder='Enter Hours' />
                        </div>
                        <div className="col-12 for-task">
                            <CKEditor
                                editor={ClassicEditor}
                                data="<div>
                                        <h4>Design Login Page</h4>
                                        <ol>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                        </ol>
                                    </div>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                }}
                            />
                        </div>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default DaysEstimation