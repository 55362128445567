import React, { useState } from "react"
import { Container, Table } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../assets/css/Sales/Sales.css"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"

const AddContact = () => {
  const [fixButtons, setFixButtons] = useState(false)
  const [createLogin, setCreateLogin] = useState(false)

  const navigate = useNavigate()

  document.title = "Add Contact | Zithas Crm"

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }
  const params = useParams()

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [position, setPosition] = useState("")
  const [email, setEmail] = useState("")
  const [dob, setDoB] = useState("")
  const [aniversary_date, setAniversaryDate] = useState("")
  const [login, setLogin] = useState("no")
  const [primary_contact, setPrimaryContact] = useState("")
  const [password, setPassword] = useState("")
  const [per_proposal, setPerProposal] = useState("0")
  const [per_contract, setPerContract] = useState("0")
  const [per_signature, setPerSignature] = useState("0")
  const [per_support, setPerSuport] = useState("0")
  const [per_project, setPerProject] = useState("0")

  const [not_proposal, setNotProposal] = useState("0")
  const [note_contract, setNoteContract] = useState("0")
  const [note_signature, setNoteSignature] = useState("0")
  const [note_support, setNoteSuport] = useState("0")
  const [note_project, setNoteProject] = useState("0")
  const [isLoading, setLoading] = useState(false)

  const onAddContact = async e => {
    e.preventDefault()
    let primarycontact
    if (primary_contact == true) {
      primarycontact = "on"
    } else {
      primarycontact = "off"
    }
    const notification = [
      not_proposal,
      note_contract,
      note_signature,
      note_support,
      note_project,
    ]
    setLoading(true)
    const formData = new FormData()
    formData.append("customer_id", params.id)
    formData.append("name", name)
    formData.append("phone", phone)
    formData.append("position", position)
    formData.append("email", email)
    formData.append("birthdate", dob)
    formData.append("anniversary_date", aniversary_date)
    formData.append("login", login)
    formData.append("primarycontact", primarycontact)
    formData.append("password", password)
    formData.append("proposal", per_proposal)
    formData.append("contract", per_contract)
    formData.append("contact_signature", per_signature)
    formData.append("support", per_support)
    formData.append("project", per_contract)
    formData.append("notification", JSON.stringify(notification))
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/add_contact`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        swal({
          icon: "success",
          text: "Contact Added Succesfully",
        }).then(data => {
          navigate(`${configure.appUrl}sales/edit-customer/${params.id}`)
        })
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Contact"} />
          <Row className="px-1">
            <Form onSubmit={onAddContact}>
              {/* Fixed Buttons */}
              <div
                className={`flex-column fixed-buttons ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button className="btn btn-purple  fix-btn">
                  <i className="fas fa-save me-2"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn"
                >
                  <i className="dripicons-cross me-2 icon-center"></i>
                  <span>Cancel</span>
                </a>
              </div>

              <Row>
                <div className="col-md-6 pe-md-4">
                  <Row className="bg-white px-2 py-4 shadow mb-3">
                    <div className="col-md-12 mb-3 text-end">
                      <button className=" btn ms-md-2 btn-purple">
                        <i className="fas fa-save me-1"></i> save
                      </button>
                      <button
                        onClick={e => {
                          e.preventDefault()
                          navigate(-1)
                        }}
                        className=" btn ms-md-2 btn-red"
                      >
                        <i className="dripicons-cross me-2 icon-center"></i>
                        CANCEL
                      </button>
                    </div>
                    <Row className="pe-0">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">
                          Name <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          className="form-control "
                          required
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 pe-md-0">
                        <label htmlFor="">Phone </label>
                        <input
                          type="number"
                          placeholder="Enter Phone no."
                          className="form-control "
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">
                          Position <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Position"
                          className="form-control "
                          required
                          value={position}
                          onChange={e => setPosition(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 pe-md-0">
                        <label htmlFor="">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          placeholder="Enter Email"
                          className="form-control "
                          required
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="">
                          Birth Date
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <input
                          type="date"
                          className="form-control "
                          value={dob}
                          onChange={e => setDoB(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 mb-3 pe-md-0">
                        <label htmlFor="">Anniversary Date</label>
                        <input
                          type="date"
                          className="form-control "
                          value={aniversary_date}
                          onChange={e => setAniversaryDate(e.target.value)}
                        />
                      </div>
                      <div className="create-login-btn mb-md-0 mb-3">
                        <label htmlFor="">Create Login?</label>
                        <div className="form-switch form-switch-md me-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            onChange={e => {
                              if (e.target.checked == true) {
                                setLogin("yes")
                              } else {
                                setLogin("no")
                              }
                            }}
                          />
                        </div>
                      </div>
                      {login == "yes" ? (
                        <div className="password-field ps-md-0 mb-md-0 mb-3">
                          <label htmlFor="">
                            Password <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control "
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                      ) : null}
                      <div className="col-md-4 ps-md-3 d-flex align-items-center mt-md-3 notes">
                        <input
                          type="checkbox"
                          id="primary-contact"
                          className="form-check-input mt-0 big-checkbox"
                          onChange={e => setPrimaryContact(e.target.checked)}
                        />
                        <label htmlFor="primary-contact" className="mb-0 ms-2">
                          Primary Contact
                        </label>
                      </div>
                    </Row>
                  </Row>
                </div>

                <div className="col-md-6 ps-md-4">
                  <Row className="bg-white px-3 align-items-start pt-4 shadow mb-3">
                    <p className="ps-0 pb-0 text-danger">
                      {
                        "[Make sure to set appropriate permissions for this contact]"
                      }
                    </p>
                    <Table className="table-bordered">
                      <thead>
                        <tr>
                          <th className="col-6" colSpan={2}>
                            Permission
                          </th>
                          <th className="col-6" colSpan={2}>
                            Email Notifications
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Proposal</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setPerProposal("1")
                                  } else {
                                    setPerProposal("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td>Proposal</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setNotProposal("1")
                                  } else {
                                    setNotProposal("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Contract</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setPerContract("1")
                                  } else {
                                    setPerContract("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td>Contract</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setNoteContract("1")
                                  } else {
                                    setNoteContract("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Contact Signature</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setPerSignature("1")
                                  } else {
                                    setPerSignature("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td>Contact Signature</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setNoteSignature("1")
                                  } else {
                                    setNoteSignature("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Support</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setPerSuport("1")
                                  } else {
                                    setPerSuport("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td>Support</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setNoteSuport("1")
                                  } else {
                                    setNoteSuport("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>Project</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setPerProject("1")
                                  } else {
                                    setPerProject("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td>Project</td>
                          <td>
                            <div className="form-switch form-switch-md ms-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={e => {
                                  if (e.target.checked) {
                                    setNoteProject("1")
                                  } else {
                                    setNoteProject("0")
                                  }
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddContact
