import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Row, Form } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../assets/css/Sales/Sales.css"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import configure from "configure"
import Swal from "sweetalert2"

const AddIntroducer = () => {
  const [fixButtons, setFixButtons] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }
  const commissionStructure = [
    { label: "Percentage", value: "percentage" },
    { label: "Flat", value: "flat" },
  ]

  const [location_option, setLocationOption] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [country_option, setCountryOption] = useState([])
  const [state_option, setStateOption] = useState([])
  const [city_option, setCityOption] = useState([])
  const [country, setCountry] = useState([])
  const [state, setState] = useState([])
  const [city, setCity] = useState([])
  const [zipcode, setZipCode] = useState("")
  const [source_option, setSourceOption] = useState([])
  const [assign_option, setAssignOption] = useState([])
  const [status_option, setStatusOption] = useState([])
  const [location, setLocation] = useState([])
  const [source, setSource] = useState([])
  const [assigned, setAssigned] = useState([])
  const [status, setStatus] = useState([])
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [address, setAddress] = useState("")
  const [description, setDescription] = useState("")
  const [commission, setCommission] = useState([])
  useEffect(() => {
    fetchLocation()
    fetchCountry()
  }, [])

  const fetchLocation = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLocationOption(
          res.data.data.map(obj => ({
            label: obj.location,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchCountry = async () => {
    setLoading(true)
    try {
      await axios({
        method: "get",
        url: `${configure.apiUrl}/country`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setCountryOption(
          res.data.data.country.map(obj => ({
            label: obj.name,
            value: obj.id,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getState = async e => {
    setLoading(true)
    setCountry(e.value)
    const formData = new FormData()
    formData.append("country_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/country_state`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setStateOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getCities = async e => {
    setLoading(true)
    setState(e.value)
    const formData = new FormData()
    formData.append("state_id", e.value)
    try {
      const res = await axios({
        method: "post",
        url: `${configure.apiUrl}/state_city`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setLoading(false)
        setCityOption(
          res.data.data.map(obj => ({
            value: obj.id,
            label: obj.name,
          }))
        )
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onChangeCountry = async e => {
    setLoading(true)
    setLocation(e.value)
    const fromData = new FormData()
    fromData.append("country_id", e.value)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/lead_belong`,
        data: fromData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        setSourceOption(
          res.data.data.lead_source?.map(item => ({
            value: item.id,
            label: item.leadname,
          }))
        )
        setAssignOption(
          res.data.data.assigned?.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setStatusOption(
          res.data.data.lead_status.map(item => ({
            value: item.id,
            label: item.name,
          }))
        )
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const [error, setError] = useState(null)

  const saveIntroducer = async e => {
    e.preventDefault()
    if (
      location?.length != 0 &&
      source?.length != 0 &&
      assigned?.length != 0 &&
      status?.length != 0 &&
      description?.length != 0
    ) {
      setLoading(true)
      const formData = new FormData()
      formData.append("user_id", localStorage.getItem("auth_id"))
      formData.append("leadbelogto", location)
      formData.append("source", source)
      formData.append("assigned", assigned)
      formData.append("status", status)
      formData.append("company", name)
      formData.append("phone_number", phone)
      formData.append("email", email)
      formData.append("website", website)
      formData.append("address", address)
      formData.append("country", country)
      formData.append("state", state)
      formData.append("city", city)
      formData.append("zipcode", zipcode)
      formData.append("description", description)
      formData.append("commissionstructure", commission?.value)
      try {
        const response = await axios({
          method: "post",
          url: `${configure.apiUrl}/add_introducer`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then(res => {
          setLoading(false)
          Swal.fire({
            icon: "success",
            text: "Introducer Added Succesfully",
          }).then(data => {
            navigate(`${configure.appUrl}sales/introducer`)
          })
        })
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    } else {
      setError(true)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content mb-3">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Add Introducer"} />
          <Row className="px-1">
            <Form onSubmit={saveIntroducer}>
              <div
                className={`flex-column fixed-buttons pt-2 ${
                  fixButtons ? "d-flex" : "d-none"
                }`}
              >
                <button className="btn btn-save btn-label pe-0 fix-btn">
                  <i className="fas fa-save me-2 label-icon"></i>
                  <span>Save</span>
                </button>
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-red  fix-btn btn-label pe-0"
                >
                  <i className="dripicons-cross me-2 icon-center label-icon"></i>
                  <span>Cancel</span>
                </a>
              </div>
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <div className="col-md-12 mb-3 text-end">
                  <button className=" btn ms-md-2 btn-purple btn-label">
                    <i className="fas fa-save me-1 label-icon"></i> save
                  </button>
                  <button
                    onClick={e => {
                      e.preventDefault()
                      navigate(-1)
                    }}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </button>
                </div>
                <Row className="pe-0">
                  <div className="col-md-3 mb-md-0 mb-3">
                    <label htmlFor="">
                      Location <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      placeholder="Select Location"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={location_option}
                      onChange={onChangeCountry}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(location?.length == 0) == error
                        ? "Please select Location"
                        : null}
                    </span>
                  </div>
                  <div className="col-md-3 mb-md-0 mb-3">
                    <label htmlFor="">
                      Source <span className="text-danger">*</span>{" "}
                    </label>
                    <Select
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={source_option}
                      onChange={e => setSource(e.value)}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(source?.length == 0) == error
                        ? "Please select Source"
                        : null}
                    </span>
                  </div>
                  <div className="col-md-3 mb-md-0 mb-3">
                    <label htmlFor="">
                      Assigned <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Assigned"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={assign_option}
                      onChange={e => setAssigned(e.value)}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(assigned?.length == 0) == error
                        ? "Please select Assigned"
                        : null}
                    </span>
                  </div>
                  <div className="col-md-3 mb-md-0 mb-3 pe-0 mb-md-0">
                    <label htmlFor="">
                      Status <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Status"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={status_option}
                      onChange={e => setStatus(e.value)}
                    />
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(status?.length == 0) == error
                        ? "Please select Status"
                        : null}
                    </span>
                  </div>
                </Row>
              </Row>

              {/* Introducer info */}
              <Row className="bg-white px-2 py-4 shadow mb-3">
                <h5 className="mb-3">Introducer Information</h5>

                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className="form-control "
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">Phone</label>
                  <input
                    type="text"
                    placeholder="Enter Phone"
                    className="form-control "
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">Email Address</label>
                  <input
                    type="text"
                    placeholder="Enter Email Address"
                    className="form-control "
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mt-2 mb-3">
                  <label htmlFor="">Website</label>
                  <input
                    type="text"
                    placeholder="Enter Website"
                    className="form-control "
                    value={website}
                    onChange={e => setWebsite(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Country</label>
                  <Select
                    placeholder="Select Location"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={country_option}
                    onChange={getState}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">State</label>
                  <Select
                    placeholder="Select State"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={state_option}
                    onChange={getCities}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">City </label>
                  <Select
                    placeholder="Select City"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={city_option}
                    onChange={e => setCity(e.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Zipcode</label>
                  <input
                    type="text"
                    placeholder="Enter Zipcode"
                    className="form-control "
                    value={zipcode}
                    onChange={e => setZipCode(e.target.value)}
                  />
                </div>
                <div className="col-md-9 mb-3">
                  <label htmlFor="">Address</label>
                  <input
                    type="text"
                    placeholder="Enter Address"
                    className="form-control "
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="">Commission Structure</label>
                  <Select
                    placeholder="Select Commission Structure"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    styles={colourStyles}
                    options={commissionStructure}
                    onChange={e => setCommission(e)}
                  />
                </div>
                <div className="col-12 mb-md-0 mb-3">
                  <div className="d-flex justify-space-between">
                    <label htmlFor="">
                      Description <span className="text-danger">*</span>
                    </label>
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "7px",
                      }}
                    >
                      {(description?.length == 0) == error
                        ? "Please add description"
                        : null}
                    </span>
                  </div>
                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setDescription(data)
                    }}
                  />
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddIntroducer
