import React, { useState } from "react"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/css/Staff/Staff.css"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

// components
import Education from "./Education"
import WorkExperience from "./WorkExperience"
import ZithasCtc from "./ZithasCtc"
import Documents from "./Documents"
import ZithasCredential from "./ZithasCredential"
import Permission from "./Permission"
import PersonalDetails from "./PersonalDetails"
import { useParams } from "react-router-dom"
import GenerateDoc from "./GenerateDoc"
import RolesAndpermission from "./RolesAndperm"

const EditStaff = () => {
  // const tabs = window.location.href.split("#").slice(-1).toString()
  const [verticalActiveTab, setverticalActiveTab] = useState("tab1")
  const [fixedSideTab, setFixedSideTab] = useState(false)
  const params = useParams()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Edit Staff | Zithas Crm"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content mb-4">
        <Container fluid>
          <Breadcrumbs title={"dashboard"} breadcrumbItem={"Edit Staff"} />
          <Row className="gx-4 justify-content-between di-flex align-items-top">
            <Col md="2" className="px-0">
              <Nav
                pills
                className={`side-tab flex-column px-2 py-3 bg-white
                            ${fixedSideTab ? "fixed-side-tab" : ""} h-100`}
              >
                <NavItem>
                  <NavLink
                    // href="#tab1"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab1",
                    })}
                    onClick={() => {
                      toggleVertical("tab1")
                    }}
                  >
                    Personal Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="#tab2"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab2",
                    })}
                    onClick={() => {
                      toggleVertical("tab2")
                    }}
                  >
                    Zithas CTC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="#tab2"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab9",
                    })}
                    onClick={() => {
                      toggleVertical("tab9")
                    }}
                  >
                    Roles & Responsibility
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="#tab3"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab3",
                    })}
                    onClick={() => {
                      toggleVertical("tab3")
                    }}
                  >
                    Work Experience
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="#tab4"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab4",
                    })}
                    onClick={() => {
                      toggleVertical("tab4")
                    }}
                  >
                    Education
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="#tab5"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab5",
                    })}
                    onClick={() => {
                      toggleVertical("tab5")
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="#tab8"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab8",
                    })}
                    onClick={() => {
                      toggleVertical("tab8")
                    }}
                  >
                    Generate Documents
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    // href="#tab6"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab6",
                    })}
                    onClick={() => {
                      toggleVertical("tab6")
                    }}
                  >
                    Zithas Credential
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    // href="#tab7"
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "tab7",
                    })}
                    onClick={() => {
                      toggleVertical("tab7")
                    }}
                  >
                    Permission
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="pe-0">
              <div className="card bg-transparent mb-0">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId="tab1">
                    <PersonalDetails id={params.id} />
                  </TabPane>
                  <TabPane tabId="tab2">
                    <ZithasCtc id={params.id} />
                  </TabPane>
                  <TabPane tabId="tab3">
                    <WorkExperience id={params.id} />
                  </TabPane>
                  <TabPane tabId="tab4">
                    <Education id={params.id} />
                  </TabPane>
                  <TabPane tabId="tab5">
                    <Documents id={params.id} />
                  </TabPane>
                  <TabPane tabId="tab6">
                    <ZithasCredential id={params.id} />
                  </TabPane>
                  <TabPane tabId="tab7">
                    <Permission
                      id={params.id}
                      verticalActiveTab={verticalActiveTab}
                      setverticalActiveTab={setverticalActiveTab}
                    />
                  </TabPane>
                  <TabPane tabId="tab8">
                    <GenerateDoc id={params.id} />
                  </TabPane>
                  <TabPane tabId="tab9">
                    <RolesAndpermission id={params.id} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditStaff
