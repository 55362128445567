import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// assets
import "../../../../assets/css/Sales/Sales.css"
import userImg from "../../../../assets/images/users/avatar-1.jpg"
import moment from "moment"

const ViewFollowUp = ({ followData, view_assign }) => {
  return (
    <React.Fragment>
      <div className="mb-3">
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-5">Follow Up</CardTitle>
                <div className="mt-4">
                  <ul className="verti-timeline list-unstyled notes-box">
                    {followData?.map((note, key) => {
                      const idArrays = note?.assign_user?.split(",").map(Number)
                      const filteredArray = view_assign.filter(item =>
                        idArrays?.includes(item.value)
                      )
                      const resultString = filteredArray
                        .map(item => item.label)
                        .join(", ")
                      return (
                        <li key={key} className="event-list position-relative">
                          <div className="event-timeline-dot">
                            <img
                              src={`https://mars.zithas.com/application/public/${note.user_avatar}`}
                              className="userImg me-3 "
                              alt=""
                            />
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1 ms-4 note py-2 rounded ps-3 pr-10">
                              <div className=" d-flex justify-content-between ">
                                <div>
                                  <p className="text-muted ">
                                    <h6 className="d-inline me-2">
                                      By {note.added_by}
                                    </h6>{" "}
                                    {moment(note.created_at).format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    FollowUp at{" "}
                                    {moment(note.date_time).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <i className="bi bi-triangle-fill note-icon"></i>

                              <div
                                className="Features"
                                dangerouslySetInnerHTML={{
                                  __html: note.description,
                                }}
                              />
                              <p className="mb-1">
                                Assigned user : {resultString}
                              </p>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  {followData.length == 0 ? (
                    <p>Follow Up is Not Addded</p>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ViewFollowUp
