import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Modal
} from "reactstrap";
import Select from 'react-select';
import swal from 'sweetalert';

// assets
import '../../../assets/css/Finance/Finance.css';

// common component
import Pagination from 'components/Common/Pagination';


const colourStyles = {
    menuList: styles => ({
        ...styles,
        background: 'white',
    }),
    menu: base => ({
        ...base,
        zIndex: 1200
    })
}

const location = [
    { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "India", value: "India" },
    { label: "United kingdom", value: "India" }
];

const paymentVia = [
    { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "Bank Account", value: "Bank Account" },
    { label: "Credit Card", value: "Credit Card" },
    { label: "Others", value: "Others" }
];

const projects = [
    { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "Test Project 1", value: "Test Project 1" },
    { label: "Test Project 2", value: "Test Project 2" },
    { label: "Test Project 3", value: "Test Project 3" }
]

const categories = [
    { label: "Nothing Selected", value: "Nothing Selected" },
    { label: "Vendor", value: "Vendor" },
    {
        label: "Freelancer",
        value: [
            { label: "JackSmith", value: "JackSmith" },
            { label: "NancyWilliams", value: "NancyWilliams" }
        ]
    },

    {
        label: "Introducer",
        value: [
            { label: "Zenith Tins", value: "Zenith Tins" },
            { label: "Introducer 2", value: "Introducer 2" },
            { label: "Introducer 3", value: "Introducer 3" },
        ]
    },
    { label: "Other", value: "Other" }
];

function TableContainer({ columns, data }) {
    const [formPopup, setFormPopup] = useState(false);
    const [fixedContent, setFixedContent] = useState(false);
    const [add_modal, setadd_modal] = useState(false);

    const [category, setCategory] = useState(null)

    const onChangeCategory = (categry) => {
        if (categry.label === "Vendor" || categry.label === "Other" || categry.label === "Nothing Selected") {
            setCategory(null)

        } else {
            setCategory(categry)
        }
    }

    const navigate = useNavigate();

    document.addEventListener('scroll', () => {
        if (window.scrollY > 275) {
            setFixedContent(true);
        }
        else {
            setFixedContent(false);
        }
    })

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        prepareRow
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, pageSize, globalFilter } = state;

    useEffect(() => setPageSize(50), [])

    // Render the UI for your table
    return (
        <>
            {/* fixed buttons */}
            <div className={`flex-column fixed-buttons ${fixedContent ? 'd-flex' : 'd-none'}`}>
                <button onClick={() => setadd_modal(true)} className='btn btn-purple border-radius fix-btn'>
                    <i className="fas fa-plus me-2 icon-size"></i>
                    <span>Payment</span>
                </button>
                <button onClick={() => navigate(-1)} className='btn btn-red border-radius fix-btn' >
                    <i className='dripicons-cross me-2 icon-center'></i>
                    <span>Cancel</span>
                </button>
            </div>
            <Row>
                <Col md={12}>
                    <div className='d-flex justify-content-between align-items-start'>
                        {/* Amounts Records */}
                        <div className='d-flex justify-content-between w-100'>
                            <div className='d-flex'>
                                <a href="#" className='rounded payment-amounts'>
                                    <p className='text-dark'>Vendor Balance Amount </p>
                                    <h4 className='text-secondary'>₹ 35,400.00</h4>
                                </a>
                                <a href="#" className='rounded payment-amounts'>
                                    <p className='text-dark'>Expense Balance Amount </p>
                                    <h4 className='text-secondary'>₹ 8,600.00</h4>
                                </a>
                                <a href="#" className='rounded payment-amounts'>
                                    <p className='text-dark'>Total Available Balance</p>
                                    <h4 className='text-secondary'>₹ 8,600.00</h4>
                                </a>
                            </div>

                            <div className='d-flex'>
                                <a href="#" className='rounded payment-amounts'>
                                    <p className='text-dark'>Bank Balance</p>
                                    <h4 className='text-secondary'>₹ 182,618.52</h4>
                                </a><a href="#" className='me-0 rounded payment-amounts'>
                                    <p className='text-dark'>Credit Card Balance </p>
                                    <h4 className='text-secondary'>₹ 0.00</h4>
                                </a>
                            </div>
                        </div>

                    </div>
                </Col>

                <Col md={4}>
                    <div className='d-flex'>
                        <select
                            className="border-radius form-select"
                            style={{ width: '100px' }}
                            value={pageSize}
                            onChange={(e) => setPageSize(e.target.value)}
                        >
                            {[10, 25, 50, 100].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={8} className='d-flex justify-content-end'>
                    <div className='me-4'>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="border-radius form-control rounded h-100"
                                        placeholder={` Search...`}
                                        value={globalFilter || ''}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => setFormPopup(!formPopup)} className='btn btn-outline-secondary border-radius me-3'><i className='fas fa-filter'></i></button>
                    </div>
                    <div className='text-md-end'>
                        <button onClick={() => setadd_modal(true)}
                            className='border-radius btn text-white me-2 btn-purple'>
                            <i className="fas fa-plus me-2 icon-size"></i>Payment
                        </button>

                        <button onClick={() => navigate(-1)} className="border-radius btn btn-red"><i className='dripicons-cross me-2 icon-center'></i>CANCEL</button>
                    </div>
                </Col>
            </Row>
            <div className='position-relative'>
                <Table className='table table-bordered mt-3' {...getTableProps()}>
                    <thead className={`${fixedContent ? 'fix-header' : ''} fix-vendorpay-header`}>
                        {
                            headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column, index) => (
                                            <th key={index} className={`t-col-${index + 1} ${column.className}`} {...column.getHeaderProps()}>{column.render("Header")}
                                                {
                                                    column.isInfoIcon ?
                                                        <i title='info' className='bx bx-info-circle ms-1 info-icon'></i> : null}
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>

                    <tbody className="fix-vendorpay-header" {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='row-hover' {...row.getRowProps()} key={i}>
                                        {
                                            row.cells.map((cell, index) => {
                                                return <td key={index}
                                                    className={`t-col-${index + 1} ${cell.column.className}`} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                {
                    formPopup ?
                        <div className='payment-popup-form popup-form p-4'>
                            <Form>
                                <Row className='align-items-end'>
                                    <div className="col-md-4">
                                        <label htmlFor="">Start Date</label>
                                        <input type="date" className='form-control border-radius' />
                                    </div>

                                    <div className="col-md-4">
                                        <label htmlFor="">End Date</label>
                                        <input type="date" className='form-control border-radius' />
                                    </div>

                                    <div className='text-center col-md-4'>
                                        <button className='btn border-radius btn-purple me-3'>
                                            <i className="fas fa-search me-2"></i>
                                            Search
                                        </button>
                                        <button className='btn border-radius btn-red'
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setFormPopup(!formPopup)
                                            }}
                                        ><i className="dripicons-cross me-1 icon-center"></i> Clear</button>
                                    </div>
                                </Row>
                            </Form>
                            <i className='bi bi-triangle-fill pop-icon'></i>
                        </div> : null
                }
            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Pagination
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                />
            </Row>


            {/* Add Payment Modal */}
            <Modal
                isOpen={add_modal}
                size='lg'
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Add Expense</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setadd_modal(false);
                            setCategory(null)
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Form>
                        <Row>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="">Location <span className='text-danger'>*</span> </label>
                                <Select
                                    placeholder="Select Location"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    styles={colourStyles}
                                    options={location} required />
                            </div>

                            <div className="col-md-4 mb-3">
                                <label htmlFor="">Category <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Category"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    styles={colourStyles}
                                    onChange={onChangeCategory}
                                    options={categories} required />
                            </div>

                            {
                                category ?
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="">{category.label} <span className='text-danger'>*</span></label>
                                        <Select
                                            placeholder="Select Category"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={category.value} required />
                                    </div> : null
                            }

                            <div className="col-md-6 mb-3">
                                <label htmlFor="">Project <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Project"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    styles={colourStyles}
                                    options={projects} required />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="">Payment Via <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Payment Via"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    styles={colourStyles}
                                    options={paymentVia} required />
                            </div>

                            <div className='col-6 mb-3'>
                                <label htmlFor="">Cost <span className='text-danger'>*</span></label>
                                <input type="text" placeholder='Enter Cost' className='form-control border-radius' required />
                            </div>

                            <div className='col-6'>
                                <label htmlFor="">GST</label>
                                <input type="text" placeholder='Enter GST' className='form-control border-radius' />
                            </div>

                            <div className='col-6'>
                                <label htmlFor="">TDS</label>
                                <input type="text" placeholder='Enter TDS' className='form-control border-radius' />
                            </div>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius'>Submit</button>
                        </div>
                    </Form>

                </div>
            </Modal>
            {/* ------------ */}

        </>
    );
}

const VendorPay = () => {

    const [edit_modal, setedit_modal] = useState(false);
    const [approve_modal, setapprove_modal] = useState(false);
    const [view_modal, setview_modal] = useState(false);
    const [paid_modal, setpaid_modal] = useState(false);

    const [category, setCategory] = useState(null)

    useEffect(() => window.scrollTo(0, 0), []);

    const onChangeCategory = (categry) => {
        if (categry.label === "Vendor" || categry.label === "Other" || categry.label === "Nothing Selected") {
            setCategory(null)
        } else {
            setCategory(categry)
        }
    }

    //meta title
    document.title = "Payment | Zithas Crm";

    const onDelete = () => {
        swal({
            title: "Are you sure?",
            text: "Do you really want to delete this expense!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    return;
                }
            });
    }

    // table columns
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                isInfoIcon: false,
                className: 'text-center'
            },
            {
                Header: "Location",
                accessor: "location",
                className: 'text-center',
                Cell: cellProps => {
                    return (
                        <div>
                            <img src="https://mars.ztpl.net/assets/images/india.png" className='location-img' alt="flag" />
                        </div>
                    )
                },
                isInfoIcon: true
            },
            {
                Header: "Category",
                accessor: "category",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Project Name",
                accessor: "projectName",
                isInfoIcon: true
            },
            {
                Header: "Cost",
                accessor: "cost",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Balance Amt",
                accessor: "balAmount",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Approve Amt",
                accessor: "appAmount",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "GST",
                accessor: "gst",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "TDS",
                accessor: "tds",
                isInfoIcon: true,
                className: 'text-center'
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: cellProps => {
                    return <div>
                        <button onClick={() => setview_modal(true)} title='view' className='btn btn-sm btn-outline-secondary me-1'>
                            <i className="fas fa-eye "></i>
                        </button>
                        <button onClick={() => setedit_modal(true)}
                            title='edit' className='btn btn-sm me-1 btn-outline-secondary'>
                            <i className="fas fa-pencil-alt"></i>
                        </button>
                        <button onClick={() => setapprove_modal(true)} title='Bank Account' className='btn btn-sm btn-outline-secondary me-1'>
                            <i className="bx bxs-bank"></i>
                        </button>
                        <button onClick={() => setpaid_modal(true)} title='Paid' className='btn btn-sm btn-success me-1'>
                            <i className="fas fa-dollar-sign"></i>
                        </button>
                        <button onClick={onDelete} title='delete' className='btn btn-sm btn-danger'>
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </div>
                },
            }
        ],
        []
    );

    const data = [
        {
            id: 1,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 2,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 3,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 4,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 5,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 6,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 4,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 5,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },
        {
            id: 6,
            category: 'Phone Bill',
            projectName: 'Web development',
            cost: '3000.00',
            tds: 11,
            balAmount: "1,200.00",
            appAmount: "0.98",
            gst: 12
        },

    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={"Dashboard"}
                        breadcrumbItem={"Payment"}
                    />

                    <Row>
                        <Col lg={12} className='p-0'>
                            <Card>
                                <CardBody>
                                    <div className="responsive-table">
                                        <TableContainer columns={columns} data={data} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* =========================== Modals ===========================*/}
            {/* View Modal */}
            <Modal
                isOpen={view_modal}
                size='lg'
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Paid Expense List</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setview_modal(false);
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Table className="table-bordered"  >
                        <thead>
                            <tr>
                                <th>Payment Mode</th>
                                <th>Amount</th>
                                <th>Receipt</th>
                                <th>Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Debit Card</td>
                                <td>1000.00</td>
                                <td><a href="#">Receipt Link</a></td>
                                <td>2023-03-15 18:45:05</td>
                            </tr>

                            <tr>
                                <td>Credit Card</td>
                                <td>1200.00</td>
                                <td><a href="#">Receipt Link</a></td>
                                <td>2023-03-15 18:45:05</td>
                            </tr>

                            <tr>
                                <td>Bank Account</td>
                                <td>1400.00</td>
                                <td><a href="#">Receipt Link</a></td>
                                <td>2023-03-15 18:45:05</td>
                            </tr>
                        </tbody>
                    </Table>

                </div>
            </Modal>
            {/* ------------ */}

            {/* Approve Modal */}
            <Modal
                isOpen={approve_modal}
                size='md'
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Amount for Approve</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setapprove_modal(false);
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Form>
                        <Row>
                            <div className='col-12 mb-3'>
                                <label htmlFor="">Amount <span className='text-danger'>*</span></label>
                                <input type="text" placeholder='Enter Amount' className='form-control border-radius' required />
                            </div>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius'>Approve</button>
                        </div>
                    </Form>

                </div>
            </Modal>
            {/* ------------ */}

            {/* Payment Mode Modal */}
            <Modal
                isOpen={paid_modal}
                size='md'
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Payment Mode</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setpaid_modal(false);
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Form>
                        <Row>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="">Payment Mode</label>
                                <Select
                                    placeholder="Select Payment Mode"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    styles={colourStyles}
                                    options={paymentVia} required />
                            </div>

                            <div className='col-6 mb-3'>
                                <label htmlFor="">Amount</label>
                                <input type="text"
                                    placeholder='Enter Amount'
                                    className='form-control border-radius'
                                    required />
                            </div>

                            <div className='col-12'>
                                <label htmlFor="">Upload File</label>
                                <input type="file"
                                    className='form-control border-radius' />
                            </div>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius'>Submit</button>
                        </div>
                    </Form>

                </div>
            </Modal>
            {/* ------------ */}

            {/* Edit Payment Modal */}
            <Modal
                isOpen={edit_modal}
                size='lg'
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Edit Expense</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setedit_modal(false);
                            setCategory(null)
                        }}
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Form>
                        <Row>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="">Location <span className='text-danger'>*</span> </label>
                                <Select
                                    placeholder="Select Location"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    styles={colourStyles}
                                    defaultValue={{ label: "India", value: "India" }}
                                    options={location} required />
                            </div>

                            <div className="col-md-4 mb-3">
                                <label htmlFor="">Category <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Category"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    styles={colourStyles}
                                    onChange={onChangeCategory}
                                    options={categories} required />
                            </div>

                            {
                                category ?
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="">{category.label} <span className='text-danger'>*</span></label>
                                        <Select
                                            placeholder="Select Category"
                                            className="basic-multi-select"
                                            classNamePrefix="border-radius select"
                                            styles={colourStyles}
                                            options={category.value} required />
                                    </div> : null
                            }

                            <div className="col-md-6 mb-3">
                                <label htmlFor="">Project <span className='text-danger'>*</span></label>
                                <Select
                                    placeholder="Select Project"
                                    className="basic-multi-select"
                                    classNamePrefix="border-radius select"
                                    defaultValue={{ label: "Test Project 1", value: "Test Project 1" }}
                                    styles={colourStyles}
                                    options={projects} required />
                            </div>

                            <div className='col-6 mb-3'>
                                <label htmlFor="">Cost <span className='text-danger'>*</span></label>
                                <input type="text"
                                    placeholder='Enter Cost'
                                    defaultValue={3000}
                                    className='form-control border-radius' required />
                            </div>

                            <div className='col-6'>
                                <label htmlFor="">GST <span className='text-danger'>*</span></label>
                                <input type="text"
                                    placeholder='Enter GST'
                                    defaultValue={18}
                                    className='form-control border-radius' />
                            </div>

                            <div className='col-6'>
                                <label htmlFor="">TDS <span className='text-danger'>*</span></label>
                                <input type="text"
                                    placeholder='Enter TDS'
                                    defaultValue={12}
                                    className='form-control border-radius' />
                            </div>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius'>Submit</button>
                        </div>
                    </Form>

                </div>
            </Modal>
            {/* ------------ */}

        </React.Fragment>
    )
}

export default VendorPay