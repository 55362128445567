import React, { useMemo, useState, useEffect } from 'react'
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Pagination from 'components/Common/Pagination';
import {
    Table,
    Row,
    Col,
    Modal
} from "reactstrap";

// editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import swal from 'sweetalert';

const TaskMilestone = () => {

    const [viewModal, setViewModal] = useState(false)
    const [viewTaskModal, setViewTaskModal] = useState(false)
    const [taskSidebar, setTaskSidebar] = useState(false)
    const [editTaskSidebar, setEditTaskSidebar] = useState(false)

    const [isTaskOn, setIsTaskOn] = useState(false)

    const onTaskAction = () => {
        if (isTaskOn) {

            swal({
                title: "Are you sure?",
                text: "You want to turn off task?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    setTimeout(() => setIsTaskOn(false), [600])
                }
            })

        }
        else
            setTimeout(() => setIsTaskOn(true), [600])

    }

    const onDelete = () => {
        swal({
            title: "Are you sure?",
            text: "Do you really want to delete this Task",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    return;
                }
            });
    }

    // table columns
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                isInfoIcon: false,
                className: 'text-center'
            },
            {
                Header: "Title",
                accessor: "title",
                isInfoIcon: false,
                className: "col-4",
                Cell: cellProps => {
                    // console.log()
                    return <a onClick={() => setViewTaskModal(true)} className="text-purple">{cellProps.cell.value}</a>
                },
            },
            {
                Header: "Assign",
                accessor: "assign",
                isInfoIcon: false,
                className: "col-4"
            },
            {
                Header: "KAM Hrs",
                accessor: "hours",
                isInfoIcon: false,
                className: "text-center"
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: cellProps => {
                    return <div>
                        <button onClick={onTaskAction} className={`btn btn-sm me-2 btn-outline-${isTaskOn ? 'danger' : 'success'}`}>
                            <i className="fas fa-clock"></i>
                        </button>
                        <button className='btn btn-sm btn-outline-secondary' onClick={() => setEditTaskSidebar(true)} title='Edit'>
                            <i className="fas fa-pen "></i>
                        </button>
                        <button onClick={onDelete} className='btn btn-sm btn-danger ms-2' title='Delete'>
                            <i className="fas fa-trash-alt "></i>
                        </button>
                    </div>
                },
            }
        ],
        [isTaskOn]
    );

    const data = useMemo(() => [
        {
            id: 1,
            title: "Estiamte in your time on task",
            assign: "Zainul marchawala",
            hours: "4",
        },
        // {
        //     id: 2,
        //     title: "Estiamte in your time on task",
        //     assign: "Zainul marchawala",
        //     hours: "2",
        // }
    ], [])

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        state,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        setPageSize,
        pageOptions,
        setGlobalFilter,
        prepareRow
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, pageSize, globalFilter } = state;
    useEffect(() => setPageSize(50), [])

    return (
        <div>
            <Row>
                <Col >
                    <select
                        className="border-radius me-4 form-select"
                        style={{ width: '100px' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(e.target.value)}
                    >
                        {[10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </Col>
                <Col className='d-flex col-md-8 justify-content-end align-items-start'>
                    <div>
                        <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                                <label htmlFor="search-bar-0" className="search-label">
                                    <span id="search-bar-0-label" className="sr-only">
                                        Search this table
                                    </span>
                                    <input
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                        id="search-bar-0"
                                        type="text"
                                        className="border-radius form-control rounded h-100"
                                        placeholder={`Search...`}
                                        value={globalFilter || ''}
                                    />
                                </label>
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => setViewModal(true)} className='btn btn-outline-secondary border-radius ms-3'>
                        <i className='fas fa-eye me-2'></i>
                        View
                    </button>
                    <button onClick={() => setTaskSidebar(true)} className='btn btn-purple border-radius ms-3'>
                        <i className='fas fa-plus me-2'></i>
                        Add Task
                    </button>
                </Col>
            </Row>
            <div className='position-relative'>
                <Table className='table table-bordered mt-3' {...getTableProps()}>
                    <thead >
                        {
                            headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column, index) => (
                                            <th key={index} className={`t-col-${index + 1} ${column.className}`} {...column.getHeaderProps()}>{column.render("Header")}
                                                {
                                                    column.isInfoIcon ?
                                                        <i title='info' className='bx bx-info-circle ms-1 info-icon'></i> : null}
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>


                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='row-hover' {...row.getRowProps()} key={i}>
                                        {
                                            row.cells.map((cell, index) => {
                                                return <td key={index}
                                                    className={`t-col-${index + 1} ${cell.column.className}`} {...cell.getCellProps()}>{cell.render("Cell")}</td>;

                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                        {page.length === 0 && <tr><td colSpan={5} className='text-center'>No Record Found</td></tr>}
                    </tbody>
                </Table>
            </div>

            {/* Pagination */}
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Pagination
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                />
            </Row>

            {/* Add Task */}
            <div className={`notes-sidebar miles-sidebar ${taskSidebar ? 'show-notes-sidebar' : ''}`} >
                <Row className='align-items-center justify-content-between'>
                    <div className='col-12 d-flex justify-content-between mb-4'>
                        <h5>Add Task</h5>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius me-2'>
                                <i className='fas fa-plus me-2'></i>Add Task
                            </button>
                            <a onClick={() => setTaskSidebar(false)} className='btn btn-secondary text-uppercase border-radius'>
                                <i className='dripicons-cross me-1 icon-center'></i>Close
                            </a>
                        </div>
                    </div>

                    <div className='col-md-8 mb-3'>
                        <label htmlFor=''>
                            Task Title <span className="text-danger">*</span>
                        </label>
                        <input type="text" placeholder='Enter Task Title' className='form-control border-radius' />
                    </div>

                    <div className="col-12">
                        <Row>
                            <div className='col-md-4 mb-3'>
                                <label htmlFor=''>
                                    Assign Developer <span className="text-danger">*</span>
                                </label>
                                <select className='form-select border-radius'>
                                    <option value="">Nothing Selected</option>
                                </select>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label htmlFor=''>
                                    Priority <span className="text-danger">*</span>
                                </label>
                                <select className='form-select border-radius'>
                                    <option value="">Nothing Selected</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Urgent">Urgent</option>
                                </select>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label htmlFor=''>
                                    Estimation Hours <span className="text-danger">*</span>
                                </label>
                                <input type="text" placeholder='Enter hrs' disabled className='form-control border-radius' />
                            </div>
                        </Row>
                    </div>

                    <div className='col-12'>
                        <label htmlFor="">Description <span className='text-danger'>*</span></label>
                        <CKEditor
                            editor={ClassicEditor}
                            data="<p></p>"
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                            }}
                        />
                    </div>
                </Row>
            </div>

            {/* Edit Task */}
            <div className={`notes-sidebar miles-sidebar ${editTaskSidebar ? 'show-notes-sidebar' : ''}`} >
                <Row className='align-items-center justify-content-between'>
                    <div className='col-12 d-flex justify-content-between mb-4'>
                        <h5>Edit Task</h5>
                        <div className='text-end'>
                            <button className='btn btn-purple border-radius me-2'>
                                <i className='fas fa-plus me-2'></i>Edit Task
                            </button>
                            <a onClick={() => setEditTaskSidebar(false)} className='btn btn-secondary text-uppercase border-radius'>
                                <i className='dripicons-cross me-1 icon-center'></i>Close
                            </a>
                        </div>
                    </div>

                    <div className='col-md-8 mb-3'>
                        <label htmlFor=''>
                            Task Title <span className="text-danger">*</span>
                        </label>
                        <input type="text" placeholder='Enter Task Title' className='form-control border-radius' />
                    </div>

                    <div className="col-12">
                        <Row>
                            <div className='col-md-4 mb-3'>
                                <label htmlFor=''>
                                    Assign Developer <span className="text-danger">*</span>
                                </label>
                                <select className='form-select border-radius'>
                                    <option value="">Nothing Selected</option>
                                </select>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label htmlFor=''>
                                    Priority <span className="text-danger">*</span>
                                </label>
                                <select className='form-select border-radius'>
                                    <option value="">Nothing Selected</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Urgent">Urgent</option>
                                </select>
                            </div>
                            <div className='col-md-4 mb-3'>
                                <label htmlFor=''>
                                    Estimation Hours <span className="text-danger">*</span>
                                </label>
                                <input type="text" placeholder='Enter hrs' disabled className='form-control border-radius' />
                            </div>
                        </Row>
                    </div>

                    <div className='col-12 '>
                        <label htmlFor="">Description <span className='text-danger'>*</span></label>
                        <CKEditor
                            editor={ClassicEditor}
                            data="<p></p>"
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                            }}
                        />
                    </div>
                </Row>
            </div>

            {/* view modal main */}
            <Modal
                size="lg"
                isOpen={viewModal}
                centered
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="mySmallModalLabel"
                    >
                        Information
                    </h5>
                    <button
                        onClick={() => {
                            setViewModal(false);
                        }}
                        type="button"
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body bd-span-input">
                    <Row>
                        <div className="col-md-4">
                            <label htmlFor="">Title</label>
                            {/* <div className='input-span'>input-span</div> */}
                            <input type="text" className='form-control border-radius'
                                defaultValue="Any Title"
                                disabled placeholder='Enter Title' />
                        </div>

                        <div className="col-md-2">
                            <label htmlFor="">Days</label>
                            <input type="text"
                                className='form-control border-radius'
                                defaultValue="4"
                                disabled placeholder='Enter Title' />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="">Members</label>
                            <input type="text"
                                className='form-control border-radius'
                                defaultValue="2"
                                disabled placeholder='Enter Title' />
                        </div>

                        <div className="col-12 mt-4 for-task">
                            <CKEditor
                                editor={ClassicEditor}
                                data="<div>
                                        <h4>Account Settings (View Profile)</h4>
                                        <ol>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                        </ol>
                                    </div>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                }}
                            />
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* view task modal */}
            <Modal
                size="lg"
                isOpen={viewTaskModal}
                centered
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="mySmallModalLabel"
                    >
                        Task Details
                    </h5>
                    <button
                        onClick={() => {
                            setViewTaskModal(false);
                        }}
                        type="button"
                        className="btn-modal-close"
                    >
                        <span className='btn-modal-span'>&times;</span>
                    </button>
                </div>
                <div className="modal-body bd-span-input">
                    <Row>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="">Hours</label>
                            {/* <div className='input-span'>input-span</div> */}
                            <input type="text" className='form-control border-radius'
                                defaultValue="4"
                                disabled placeholder='Enter Hours' />
                        </div>

                        <div className="col-12 for-task">
                            <label htmlFor="">Description</label>
                            <CKEditor
                                editor={ClassicEditor}
                                data="<div>
                                        <h4>Design Login Page</h4>
                                        <ol>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                            <li>My Profile</li>
                                        </ol>
                                    </div>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                }}
                            />
                        </div>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default TaskMilestone