import React, { useState, useEffect, useMemo } from "react"
import { Col, Container, Row, Table } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Import Components
import WelcomeComp from "./WelcomeComp"
import { Link } from "react-router-dom"

//redux
import { getChartsData as onGetChartsData } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { setApiData } from "../../store/Roles/actions"
import Pagination from "components/Common/Pagination"
//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "pages/Separate/Loader"
import moment from "moment"
import configure from "configure"
import IndiaFlag from "../../assets/images/flags/india.png"
import UkFlag from "../../assets/images/flags/uk.png"
import creditSafeImg from "../../assets/images/creditsafe/icon-2.png"
import CanadaFlag from "../../assets/images/flags/canadian_flag.jpg"
// import { useGlobalFilter, usePagination, useTable } from "react-table"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import swal from "sweetalert"
import FavoriteLead from "./FavoriteLead"
import Swal from "sweetalert2"
import Pagi2 from "components/Common/Pagi2"
import NewPagination from "components/Common/Newpagination"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

const Dashboard = props => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Zithas Technologies"

  const [isLoading, setLoading] = useState(false)
  const [isLeadAccess, setLeadAccess] = useState(false)
  const [isLeadEdit, setLeadEdit] = useState(false)
  const [modal_Access, setAccessDenied] = useState(false)

  useEffect(() => {
    RolesPermission()
    Notedata()
    LeadData()
  }, [])

  const RolesPermission = async e => {
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/staff_roles`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        dispatch(setApiData(response.data.data))
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [follow_data, setFollowUp] = useState([])
  const [followdataall, setFollowUpall] = useState([])
  const [leadType, setLeadType] = useState("currentleads")
  const [potential_data, setPotentialData] = useState([])
  const [fixedContent, setFixedContent] = useState(false)

  const Notedata = async e => {
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${configure.apiUrl}/followup_data`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        const currentDate = moment().format("YYYY-MM-DD")
        const shortedData = response.data.data.sort(
          (a, b) => new Date(a.date_time) - new Date(b.date_time)
        )
        const filterData = shortedData.filter(item => {
          return item.date_time == currentDate
        })
        setFollowUpall(shortedData)
        setFollowUp(filterData)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const LeadData = async e => {
    const formData = new FormData()
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/get_lead_potential`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      }).then(response => {
        setPotentialData(response.data.data)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  const changeStatus = async ({ user_id, followup_id }) => {
    if (isLeadEdit) {
      Swal.fire({
        title: "Are You sure ?",
        text: "The follow-up is completed.",
        icon: "warning",
        showCancelButton: true,
      }).then(async willOkay => {
        if (willOkay.isConfirmed) {
          const formData = new FormData()
          formData.append("user_id", user_id)
          formData.append("followup_id", followup_id)
          setLoading(true)
          try {
            const response = await axios({
              method: "post",
              data: formData,
              url: `${configure.apiUrl}/followup_status`,
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            if (response.data.success) {
              setLoading(false)
              Swal.fire({
                text: "FollowUp is completed",
                icon: "success",
              }).then(() => {
                const shortedData = response.data.data.sort(
                  (a, b) => new Date(a.date_time) - new Date(b.date_time)
                )
                setFollowUp(shortedData)
                setFollowUpall(shortedData)
              })
            }
          } catch (error) {
            setLoading(false)
            console.log(error)
          }
        }
      })
    } else {
      setAccessDenied(true)
    }
  }
  const [shortType, setShortType] = useState("1")

  const handleDark = () => {
    setShortType(prevType => (prevType === "1" ? "2" : "1"))
  }
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        // Header: "Sr no.",
        accessor: "id",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center col-1",
        // Cell: ({ row: { index } }) => {
        //   return <>{index + 1}</>
        // },
      },
      {
        Header: "Date",
        accessor: "date_time",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center col-1",
        Cell: cellProps => {
          return (
            <th scope="row">
              {moment(cellProps.row.original.date_time).format("DD-MM-YYYY")}
            </th>
          )
        },
      },
      {
        Header: "Client",
        accessor: "lead_name",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center",
      },
      {
        Header: "Purpose",
        accessor: "Purpose",
        isInfoIcon: true,
        isShortIcon: true,
        className: "text-center",
        Cell: cellProps => {
          return (
            <div
              className="Features no-padding-desc"
              dangerouslySetInnerHTML={{
                __html: cellProps.row.original.description,
              }}
            />
          )
        },
      },

      {
        Header: "Status",
        accessor: "status",
        className: "text-center",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <div>
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() =>
                  changeStatus({
                    user_id: cellProps.row.original.user_id,
                    followup_id: cellProps.row.original.id,
                  })
                }
              >
                <i className="fas fa-check"></i>
              </button>
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-center ",
        Cell: ({ row }) => {
          const handlePermission = () => {
            setAccessDenied(true)
          }
          return (
            <>
            {isLeadEdit ? (
              <Link
                to={`${configure.appUrl}sales/edit-lead/${row.original.lead_id}`}
                title="view lead"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-arrow-right"></i>
              </Link>
            ) : (
              <Link
                onClick={handlePermission}
                title="view lead"
                className="btn btn-sm btn-outline-secondary me-1"
              >
                <i className="fas fa-arrow-right"></i>
              </Link>
            )}
          </>
          )
        },
      },
    ],
    [isLeadEdit, isLeadAccess]
  )
  // const data = useMemo(() => follow_data.reverse(), [follow_data])
  const data = useMemo(() => {
    const dataCopy = [...follow_data]
    if (shortType === "1") {
      return dataCopy.sort(
        (a, b) => new Date(b.date_time) - new Date(a.date_time)
      )
    } else {
      return dataCopy.sort(
        (a, b) => new Date(a.date_time) - new Date(b.date_time)
      )
    }
  }, [follow_data, shortType])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )
  const { pageIndex, pageSize, globalFilter } = state

  // filter follow up

  const handleFilterFollowUp = e => {
    setLeadType(e.target.value)
  }
  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD")
    let tempData
    if (leadType == "currentleads") {
      const filterData = followdataall.filter(item => {
        return item.date_time == currentDate
      })
      tempData = filterData
    } else if (leadType == "pastleads") {
      const filterData = followdataall.filter(item => {
        return item.date_time < currentDate
      })
      tempData = filterData
    } else if (leadType == "futureleads") {
      const filterData = followdataall.filter(item => {
        return item.date_time > currentDate
      })
      tempData = filterData
    } else if (leadType == "allleads") {
      tempData = followdataall
    }
    const shortedData = tempData.sort(
      (a, b) => new Date(a.date_time) - new Date(b.date_time)
    )
    setFollowUp(shortedData)
  }, [leadType])

  useEffect(() => setPageSize(50), [])

  const findLeadAccess = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 3)
    const response = await axios({
      method: "post",
      url: `${configure.apiUrl}/user_permission`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    if (response) {
      setLoading(false)
      if (response.data.data.india[2] == 1 || response.data.data.uk[2] == 1) {
        setLeadAccess(true)
      }
      if (response.data.data.india[4] == 1 || response.data.data.uk[4] == 1) {
        setLeadEdit(true)
      }
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    findLeadAccess()
  }, [])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row className="">
            <Col xl="4">
              <WelcomeComp />
              {isLeadAccess && (
                <FavoriteLead setLoading={setLoading} isLeadEdit={isLeadEdit} />
              )}
              {/* <FavoriteLead setLoading={setLoading} /> */}
            </Col>

            {isLeadAccess && (
              <Col xl="8">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <NewPagination
                        gotoPage={gotoPage}
                        canPreviousPag={canPreviousPage}
                        canNextPage={canNextPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        page={page}
                        data={data}
                      />
                    </div>
                    <div className="mb-3 card-title">Follow Up</div>

                    <Row className="mb-2">
                      <Col>
                        <select
                          className="border-radius me-4 form-select"
                          style={{ width: "100px" }}
                          value={pageSize}
                          onChange={e => setPageSize(e.target.value)}
                        >
                          {[10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="d-flex justify-content-end gap-3">
                        <div>
                          <select
                            className="me-4 form-select"
                            style={{ width: "100%", fontSize: "14px" }}
                            value={leadType}
                            onChange={e => handleFilterFollowUp(e)}
                          >
                            {/* <option key={pageSize} value={pageSize}> */}
                            <option value="allleads">All FollowUps</option>
                            <option value="currentleads">
                              Today's FollowUps
                            </option>
                            <option value="futureleads">
                              Future FollowUps
                            </option>
                            <option value="pastleads">Past FollowUps</option>
                          </select>
                        </div>
                        <div className="me-4">
                          <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
                            <div className="position-relative">
                              <label
                                htmlFor="search-bar-0"
                                className="search-label"
                              >
                                <span
                                  id="search-bar-0-label"
                                  className="sr-only"
                                >
                                  Search this table
                                </span>
                                <input
                                  onChange={e =>
                                    setGlobalFilter(e.target.value)
                                  }
                                  id="search-bar-0"
                                  type="text"
                                  className="border-radius form-control rounded h-100"
                                  placeholder={`Search...`}
                                  value={globalFilter || ""}
                                />
                              </label>
                              <i className="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </div>
                        {/* <div className="text-md-end">
                        <button
                          onClick={() => setAddFilterModal(true)}
                          className="border-radius btn btn-purple me-2"
                        >
                          <i className="fas fa-plus me-2 icon-size"></i>Add
                        </button>
                        <button
                          onClick={() => navigate(-1)}
                          className="border-radius btn btn-red"
                        >
                          <i className="dripicons-cross me-2 icon-center"></i>
                          CANCEL
                        </button>
                      </div> */}
                      </Col>
                    </Row>
                    <div className="position-relative">
                      <Table
                        className="table table-bordered mt-4"
                        {...getTableProps()}
                      >
                        <thead
                          className={`${
                            fixedContent ? "fix-header" : ""
                          } fix-cold-header`}
                        >
                          {headerGroups.map((headerGroup, i) => (
                            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column, index) => (
                                <th
                                  key={index}
                                  className={`pe-1 ${column.className}`}
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  <div className="position-relative">
                                    {column.render("Header")}
                                    {column.isShortIcon ? (
                                      // <span onClick={() => handleDark()}>
                                      <span
                                        onClick={() =>
                                          column
                                            .getSortByToggleProps()
                                            .onClick()
                                        }
                                        className="position-absolute end-0"
                                      >
                                        <i
                                          title="Ascending"
                                          className={`bi bi-arrow-up ms-1 info-icon ${
                                            column.isSorted &&
                                            !column.isSortedDesc
                                              ? "text-dark"
                                              : ""
                                          }`}
                                        ></i>
                                        <i
                                          title="Descending"
                                          className={`bi bi-arrow-down info-icon ${
                                            column.isSorted &&
                                            column.isSortedDesc
                                              ? "text-dark"
                                              : ""
                                          }`}
                                        ></i>
                                      </span>
                                    ) : null}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>

                        <tbody
                          className="fix-cold-header"
                          {...getTableBodyProps()}
                        >
                          {
                            page.map((row, i) => {
                              prepareRow(row)
                              const currentDate = moment().format("YYYY-MM-DD")
                              let isPast = row.original.date_time < currentDate
                              return (
                                <tr
                                  className={`row-hover ${isPast && "bd-hot"}`}
                                  {...row.getRowProps()}
                                  key={i}
                                >
                                  {row.cells.map((cell, index) => {
                                    return (
                                      <td
                                        key={index}
                                        className={`${cell.column.className}`}
                                        {...cell.getCellProps()}
                                      >
                                        {/* {index == 0
                                        ? i + 1
                                        : cell.render("Cell")} */}
                                        {cell.render("Cell")}
                                      </td>
                                    )
                                  })}
                                </tr>
                              )
                            })
                            // .reverse()
                          }
                          {page.length == 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                {" "}
                                No Record Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    {/* Pagination */}

                    <Row className="justify-content-md-end justify-content-center align-items-center">
                      <NewPagination
                        gotoPage={gotoPage}
                        canPreviousPag={canPreviousPage}
                        canNextPage={canNextPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        page={page}
                        data={data}
                      />
                    </Row>
                  </div>
                </div>
              </Col>
            )}
          </Row>

          {/* Lead Potentials section */}

          <section>
            {localStorage.getItem("auth_id") == 32 ||
            localStorage.getItem("auth_id") == 4 ||
            // localStorage.getItem("auth_id") == 1 ||
            localStorage.getItem("auth_id") == 140 ? (
              <div className="mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3 card-title">Lead Potentials</div>
                    <div className="table-responsive">
                      <table className="table table-bordered mb-0 table">
                        <thead>
                          <tr>
                            <th>Lead From</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th style={{ width: "3%" }}>Mobile No.</th>
                            <th>Industry</th>
                            <th>Service</th>
                            <th style={{ width: "13%" }}>Message</th>
                            <th style={{ width: "5%" }}>Date</th>
                            <th style={{ width: "5%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {potential_data?.map((items, i) => {
                            let backColor
                            if (items.lead_type == "Hot") {
                              backColor = "bd-hot-follow"
                            } else if (items.lead_type == "Cold") {
                              backColor = "bd-cold"
                            } else if (items.lead_type == "Warm") {
                              backColor = "bd-warm"
                            }

                            const onChangeStatus = async e => {
                              swal({
                                title: "Are you sure?",
                                text: "You Want to Remove Lead from list !",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then(async willDelete => {
                                if (willDelete) {
                                  setLoading(true)
                                  const formData = new FormData()
                                  formData.append("client_id", items.id)
                                  try {
                                    const response = await axios({
                                      method: "post",
                                      url: `${configure.apiUrl}/change_status`,
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "multipart/form-data",
                                      },
                                      data: formData,
                                    }).then(response => {
                                      setPotentialData(response.data.data)
                                      setLoading(false)
                                    })
                                  } catch (err) {
                                    console.log(err)
                                    setLoading(false)
                                  }
                                }
                              })
                            }

                            let LeadFrom
                            if (items.location == 1) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={IndiaFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 2) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={UkFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 3) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={CanadaFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 11) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <i
                                    className="fas fa-utensils text-success me-2"
                                    style={{ fontSize: "17px" }}
                                  ></i>
                                  <i
                                    className="fas fa-globe"
                                    style={{
                                      fontSize: "17px",
                                      color: "black",
                                    }}
                                  ></i>
                                </div>
                              )
                            } else if (items.location == 12) {
                              LeadFrom = (
                                <div className="d-flex align-items-center justify-content-center">
                                  <img
                                    src={UkFlag}
                                    alt="No Img"
                                    className="me-2"
                                    width="20"
                                    height="15"
                                  />
                                  <img
                                    src={creditSafeImg}
                                    title="creditSafe"
                                    alt="No Img"
                                    className=""
                                    width="20"
                                    height="15"
                                  />
                                </div>
                              )
                            }

                            if (localStorage.getItem("auth_id") == 140) {
                              if (items.location == 11) {
                                return (
                                  <tr key={i} className={backColor}>
                                    <td>{LeadFrom}</td>
                                    <td scope="row">{items.name}</td>
                                    <td>{items.email}</td>
                                    <td>{items.contact}</td>
                                    <td>
                                      {items.industry != null
                                        ? items.industry
                                        : "-"}
                                    </td>
                                    <td>
                                      {items.service != null
                                        ? items.service
                                        : "-"}
                                    </td>
                                    <td>
                                      {items.message != null
                                        ? items.message
                                        : "-"}
                                    </td>
                                    <td>
                                      {moment(items.created_at).format(
                                        "DD-MM-YYYY hh:mm:ss"
                                      )}
                                    </td>
                                    <td className="text-center">
                                      <a
                                        title="view lead"
                                        className="btn btn-sm btn-outline-secondary me-1"
                                        onClick={onChangeStatus}
                                      >
                                        <i className="fas fa-check"></i>
                                      </a>
                                    </td>
                                  </tr>
                                )
                              }
                            } else {
                              return (
                                <tr key={i} className={backColor}>
                                  <td>{LeadFrom}</td>
                                  <td scope="row">{items.name}</td>
                                  <td>{items.email}</td>
                                  <td>{items.contact}</td>
                                  <td>
                                    {items.industry != null
                                      ? items.industry
                                      : "-"}
                                  </td>
                                  <td>
                                    {items.service != null
                                      ? items.service
                                      : "-"}
                                  </td>
                                  <td>
                                    {items.message != null
                                      ? items.message
                                      : "-"}
                                  </td>
                                  <td>
                                    {moment(items.created_at).format(
                                      "DD-MM-YYYY hh:mm:ss"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <a
                                      title="view lead"
                                      className="btn btn-sm btn-outline-secondary me-1"
                                      onClick={onChangeStatus}
                                    >
                                      <i className="fas fa-check"></i>
                                    </a>
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </section>
          {/* <Row className="">
            
          </Row> */}

          {/* <div className="card mb-0 mt-20" style={{ height: "314px" }}>
            <div className="card-body">
              <div className="mb-3 card-title"> Today's Follow Up</div>
              <ul className="list-group">
                {follow_data.map((items, i) => {
                  let backColor
                  if (items.lead_type == "Hot") {
                    backColor = (
                      <i
                        className="bx bxs-hot"
                        style={{
                          fontSize: "18px",
                          lineHeight: "0",
                          color: "#FF5733",
                        }}
                      ></i>
                    )
                  } else if (items.lead_type == "Cold") {
                    backColor = (
                      <i
                        className="bx bxs-flame"
                        style={{
                          fontSize: "20px",
                          lineHeight: "0",
                          color: "#fdab99",
                        }}
                      ></i>
                    )
                  } else if (items.lead_type == "Warm") {
                    backColor = (
                      <i
                        className="bx bxs-sun"
                        style={{
                          fontSize: "20px",
                          lineHeight: "0",
                          color: "#4ab3ff",
                        }}
                      ></i>
                    )
                  }

                  return (
                    <li
                      className="list-group-item border-0"
                      style={{ paddingLeft: "5px" }}
                      key={i}
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <div
                            className=""
                            style={{ width: "25px", height: "25px" }}
                          >
                            <img
                              className="avatar-title rounded-circle bg-light w-100"
                              src={`https://mars.zithas.com/application/public/${items.avatar}`}
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1 ">
                          <div className="d-flex align-items-center">
                            <h5 className="font-size-14 mb-0">
                              {items.lead_name}
                            </h5>
                            <div className="ml-20">
                              <p className="text-muted mb-0">
                                <i className="mdi mdi-account me-1"></i>
                                Added By {items.added_by}
                              </p>
                            </div>
                            <div className="ml-15">
                              {backColor}
                            </div>
                          </div>

                          <p className="text-muted mt-1">
                            <div
                              className="Features"
                              dangerouslySetInnerHTML={{
                                __html: items.description,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div> */}
        </Container>
      </div>
      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
