import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table"
import "../../assets/css/Staff/Staff.css"
import Documents from "./Documents"
import classnames from "classnames"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import EducationView from "./ViewStaff/Education"
import PermissionView from "./ViewStaff/Permisions"
import InfoGif from "../../assets/images/GIF/question-mark.gif"
// common component
import Pagination from "components/Common/Pagination"
import Loader from "pages/Separate/Loader"
import axios from "axios"
import configure from "configure"
import moment from "moment"
import Swal from "sweetalert2"
import AccessDeniedPopup from "routes/AccessDeniedPopup"

function TableContainer({ columns, data, setAccessDenied, setLoading }) {
  const [fixedHeader, setFixedHeader] = useState(false)
  const [fixedContent, setFixedContent] = useState(false)

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  const navigate = useNavigate()

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  const generateSortingIndicator = column => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px" }}
          ></i>
        </>
      ) : (
        <>
          <i
            className="fas fa-long-arrow-alt-down"
            style={{ paddingLeft: "5px", fontSize: "11px" }}
          ></i>
          <i
            className="fas fa-long-arrow-alt-up "
            style={{ fontSize: "11px", color: "#00000040" }}
          ></i>
        </>
      )
    ) : (
      <>
        <i
          className="fas fa-long-arrow-alt-down"
          style={{ paddingLeft: "5px", fontSize: "11px", color: "#00000040" }}
        ></i>
        <i
          className="fas fa-long-arrow-alt-up "
          style={{ fontSize: "11px", color: "#00000040" }}
        ></i>
      </>
    )
  }

  useEffect(() => setPageSize(50), [])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 165) {
      setFixedHeader(true)
    } else {
      setFixedHeader(false)
    }
  })

  const [name_modal, setNameModal] = useState(false)
  const [designation_modal, setDesignationModal] = useState(false)
  const [phone_modal, setPhoneModal] = useState(false)
  const [email_modal, setEmailModal] = useState(false)
  const [joining_modal, setJoiningModal] = useState(false)
  const [status_modal, setStatusModal] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  // Center Modal
  function tog_center() {
    setNameModal(false)
    setDesignationModal(false)
    setPhoneModal(false)
    setEmailModal(false)
    removeBodyCss()
  }

  // Render the UI for your table

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 13)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}addstaff`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  return (
    <div>
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          onClick={() => onAddPermission()}
          className="btn btn-purple border-radius fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Staff</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red border-radius fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-2">
        <Col md={3}>
          <div>
            <select
              className="border-radius form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col md={9} className="d-flex justify-content-end">
          <div className="me-4">
            <div className="search-box me-xxl-2 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="border-radius form-control rounded h-100"
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <div className="text-md-end">
            <Link
              onClick={() => onAddPermission()}
              // to={`${configure.appUrl}addstaff`}
              className="border-radius btn text-white text-uppercase me-2 btn-save btn-label"
            >
              <i className="fas fa-plus me-2 icon-size label-icon"></i>Staff
            </Link>
            <Link
              to={`${configure.appUrl}`}
              className="border-radius btn text-white text-uppercase  btn-save btn-label"
            >
              <i className="fas fa-recycle me-2 icon-size label-icon"></i>clear
              login
            </Link>
            <button
              onClick={() => navigate(-1)}
              className="border-radius btn ms-md-2 btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <Table className="table table-bordered mt-4 shadow" {...getTableProps()}>
        <thead
          className={`${fixedHeader ? "fix-header" : ""} fix-staff-header`}
        >
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={`t-col-${index + 1} ${column.className}`}
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  {column.isInfoIcon ? (
                    <a
                      onClick={() => {
                        if (column.Header == "Name") {
                          setNameModal(true)
                        } else if (column.Header == "Designation") {
                          setDesignationModal(true)
                        } else if (column.Header == "Phone no") {
                          setPhoneModal(true)
                        } else if (column.Header == "Email Id") {
                          setEmailModal(true)
                        } else if (column.Header == "Joining Date") {
                          setJoiningModal(true)
                        } else if (column.Header == "Status") {
                          setStatusModal(true)
                        }
                      }}
                    >
                      <i
                        title="info"
                        className="bx bx-info-circle ms-1 info-icon"
                      ></i>
                    </a>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody className={`fix-staff-header`} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="row-hover" {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      className={`t-col-${index + 1}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      {/* Pagination */}
      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Pagination
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
        />
      </Row>

      {/* Name Modal */}
      <Modal
        isOpen={name_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Staff Name Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setNameModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Designation Modal*/}
      <Modal
        isOpen={designation_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Designation Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setDesignationModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Phone Number Modal*/}
      <Modal
        isOpen={phone_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Contact Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setPhoneModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Email Modal*/}
      <Modal
        isOpen={email_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Email Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setEmailModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Joining Date Modal*/}
      <Modal
        isOpen={joining_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Joining Date Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setJoiningModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Status Modal*/}
      <Modal
        isOpen={status_modal}
        toggle={() => {
          tog_center()
        }}
        className="bd-info-popup"
        centered
      >
        <div className="modal-header bd-head-info">
          <h5 className="modal-title mt-0">
            <i className="dripicons-information bd-transform-icon pr-10 text-red"></i>
            Joining Date Info
          </h5>
        </div>
        <div className="modal-body pb-0">
          <div className="d-flex">
            <div className="col-5 pr-30">
              <div>
                <img src={InfoGif} className="w-100" />
              </div>
            </div>
            <div className="mt-20 ">
              <div>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Cras
                  mattis consectetur purus sit amet fermentum. Cras justo odio,
                  dapibus ac facilisis in, egestas eget quam. Morbi leo risus,
                  porta ac consectetur ac, vestibulum at eros.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <p>
                  <i className="fas fa-angle-double-right pr-10"></i> Praesent
                  commodo cursus magna, vel scelerisque nisl consectetur et.
                  Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
                  auctor.
                </p>
                <div className="text-center mt-40">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => setStatusModal(false)}
                  >
                    I Understood
                    <i className="dripicons-thumbs-up pl-10"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Staff = () => {
  const [modal_Access, setAccessDenied] = useState(false)
  // useEffect(() => {
  //   Swal.fire({
  //     title: "Good job!",
  //     text: "You clicked the button!",
  //     icon: "success"
  //   });
  // }, [])
  //meta title
  document.title = "Staff | Zithas Crm"

  const [modal_fullscreen, setmodal_fullscreen] = useState(false)

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false)
  })

  const [staff_id, setStaffId] = useState("")
  const navigate = useNavigate()

  const onEditPermission = async ({ id }) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 13)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
          navigate(`${configure.appUrl}edit-staff/${id}`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const onDeletePermission = async ({ id }) => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 13)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[5] == 1 || res.data.data.uk[5] == 1) {
          Swal.fire({
            title: "Are you sure?",
            text: "You want to Delete this Role !",
            icon: "warning",
            showCancelButton: true,
          }).then(async willDelete => {
            if (willDelete.isConfirmed) {
              setLoading(true)
              const formData = new FormData()
              formData.append("staff_id", id)
              try {
                const response = await axios({
                  method: "post",
                  url: `${configure.apiUrl}/delete_staff`,
                  data: formData,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                }).then(response => {
                  Swal.fire({
                    icon: "success",
                    text: "Staff Deleted Successfully",
                  }).then(data => {
                    setDatas(response?.data?.data?.reverse())
                  })
                  setLoading(false)
                })
              } catch (err) {
                swal({
                  icon: "error",
                  text: "SomeThing Went Wrong",
                })
                setLoading(false)
              }
            } else {
              setAccessDenied(true)
            }
          })
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  // table columns
  const columns = useMemo(
    () => [
      {
        Header: "Emply Id",
        accessor: "employee_code",
        isInfoIcon: false,
      },
      {
        Header: "Name",
        accessor: "name",
        isInfoIcon: true,
      },
      {
        Header: "Designation",
        accessor: "designation",
        isInfoIcon: true,
      },
      {
        Header: "Phone no",
        accessor: "phone_number",
        isInfoIcon: true,
      },
      {
        Header: "Email Id",
        accessor: "email",
        isInfoIcon: true,
      },
      {
        Header: "Joining Date",
        accessor: "date_of_joining",
        isInfoIcon: true,
        Cell: cellProps => {
          return (
            <span>
              {moment(cellProps.row.original.date_of_joining).format(
                "DD-MM-YYYY"
              )}
            </span>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: cellProps => {
          const statusChange = async (e, id, status) => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 13)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[4] == 1 || res.data.data.uk[4] == 1) {
                  Swal.fire({
                    text: `Are you sure you want to ${
                      status == 1 ? "enable" : "disable"
                    } this staff?`,
                    icon: "warning",
                    showCancelButton: true,
                  }).then(async Active => {
                    if (Active.isConfirmed) {
                      const formData = new FormData()
                      formData.append("staff_id", id)
                      formData.append("status", status)
                      setLoading(true)
                      try {
                        const response = await axios({
                          method: "post",
                          data: formData,
                          url: `${configure.apiUrl}/staffStatus`,
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                          },
                        })
                        if (response) {
                          Swal.fire({
                            text: `Staff status ${
                              status == 1 ? "enable" : "disable"
                            } successfully`,
                            icon: "success",
                          })
                          setLoading(false)
                        }
                      } catch (error) {
                        setLoading(false)
                        console.log(error)
                      }
                    } else {
                      e.target.checked = !e.target.checked
                    }
                  })
                } else {
                  setAccessDenied(true)
                  e.target.checked = !e.target.checked
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }
          return (
            <div className="form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                defaultChecked={cellProps.row.original.status == "1"}
                onChange={e =>
                  statusChange(
                    e,
                    cellProps.row.original.id,
                    e.target.checked ? 1 : 0
                  )
                }
              />
            </div>
          )
        },
        isInfoIcon: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cellProps => {
          return (
            <div>
              <a
                className="btn btn-sm action-btn btn-outline-secondary me-1"
                onClick={() => {
                  setmodal_fullscreen(true)
                  setStaffId(cellProps.row.original.id)
                }}
              >
                <i className="fas fa-eye "></i>
              </a>
              <Link
                onClick={() =>
                  onEditPermission({
                    id: cellProps.row.original.id,
                    isEditType: 1,
                  })
                }
                // to={`${configure.appUrl}edit-staff/${cellProps.row.original.id}#tab1`}
                className="btn action-btn btn-sm me-1 btn-outline-secondary"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <Link
                onClick={() =>
                  onDeletePermission({ id: cellProps.row.original.id })
                }
                className="btn btn-sm action-btn btn-outline-danger"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [data, setDatas] = useState([])
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    fetch()
  }, [])
  const fetch = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "get",
        url: `${configure.apiUrl}/staff`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // console.log("staff", response)
        setDatas(response.data.data)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Staff"} />

          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setLoading={setLoading}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* View Modal */}
      <Modal
        size="xl"
        isOpen={modal_fullscreen}
        toggle={() => {
          tog_fullscreen()
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header mb-0">
          {data.map((item, index) => {
            if (item.id == staff_id) {
              return (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={index}
                  style={{ width: "100%" }}
                >
                  <h5 className="col-6 modal-title mt-0">Staff Details</h5>
                  <div className="col-6 mr-0 d-flex justify-content-end align-items-center">
                    <img
                      src={`${configure.imgUrl}assets${item.avatar}`}
                      className="profileImg"
                      alt="no img"
                    />
                    <p className="fw-bold mb-0 ms-2">{item.name}</p>
                  </div>
                </div>
              )
            }
          })}

          <button
            type="button"
            onClick={() => {
              setmodal_fullscreen(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <Row className="gx-4 justify-content-between">
            <Col md="2" className="side-tab py-3 h-100 bd-fixed-sidetab">
              <Nav pills className="flex-column">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleVertical("1")
                    }}
                  >
                    Personal Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleVertical("2")
                    }}
                  >
                    Zithas CTC
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleVertical("3")
                    }}
                  >
                    Work Experience
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleVertical("4")
                    }}
                  >
                    Education
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleVertical("5")
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "6",
                    })}
                    onClick={() => {
                      toggleVertical("6")
                    }}
                  >
                    Zithas Credential
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      "mb-2": true,
                      active: verticalActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleVertical("7")
                    }}
                  >
                    Permission
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="10" className="bd-right-side-tab">
              <div className="p-4 h-100 pl-0">
                {data.map((item, index) => {
                  if (item.id == staff_id) {
                    const renderDataField = field => {
                      return field != null ? field : "NA"
                    }
                    return (
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                        key={index}
                      >
                        <TabPane tabId="1">
                          <div className="repeater">
                            <div className="bd-back-grey">
                              <div className="display-flex justify-space-between align-item-center">
                                <h5 className="mb-4 col-md-3 text-dark">
                                  General Info
                                </h5>
                              </div>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Role Name</label>
                                  <div>
                                    <div className="input-span input">
                                      {item.role_name}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Department</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.department)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Designation</label>
                                  <div>
                                    <div className="input-span input">
                                      {item.designation}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">
                                    Employee Id{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {item.employee_code}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Punch Id</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.punchid)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Date Of Joining</label>
                                  <div>
                                    <div className="input-span input">
                                      {item.date_of_joining}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">
                                    Date of Resignation / Date of Termination
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {item.date_of_joining}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Email Id</label>
                                  <div>
                                    <div className="input-span input">
                                      {item.email}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Password</label>
                                  <div>
                                    <div className="input-span input">
                                      123456
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-white">
                              <h5 className="mb-4 text-dark">
                                Personal Details
                              </h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">
                                    Employee Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {item.name}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">
                                    Phone Number{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {item.phone_number}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <Row>
                                    <div className="col-6">
                                      <label htmlFor="">
                                        Gender{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div>
                                        <div className="input-span input">
                                          {item.gender}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <label htmlFor="">Birth Date</label>
                                      <div>
                                        <div className="input-span input">
                                          {renderDataField(item.dob)}
                                        </div>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Marital Status</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.marital_status)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Religion</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.religion)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">LinkedIn</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.linkedin)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-grey">
                              <h5 className="mb-4 text-dark">
                                Address Details
                              </h5>
                              <Row className="bd-span-input">
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Address Line 1</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.address)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Address Line 2</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.address_line_2)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Country</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.country)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">State</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.state)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">City</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.city)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Pin Code</label>
                                  <div>
                                    <div className="input-span input">
                                      390015
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-white">
                              <h5 className="mb-4 text-dark">KYC Details</h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">PF Account Number </label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Aadhar Card Number </label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">PAN Card Number </label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Passport Number </label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-grey">
                              <div className="d-flex justify-content-between mb-4">
                                <h5 className="mb-4 text-dark">
                                  Family Details
                                </h5>
                              </div>

                              <Row className="bd-span-input">
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Memeber Name</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Relationship</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Occupation</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Contact No</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-5 bd-back-white">
                              <div className="d-flex justify-content-between mb-4">
                                <h5 className="mb-4 text-dark">Bank Details</h5>
                              </div>
                              <Row className="bd-span-input">
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Bank Name</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Branch Name</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">Bank Account Number</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                  <label htmlFor="">IFSC Code</label>
                                  <div>
                                    <div className="input-span input">NA</div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <div className=" bd-back-grey">
                              <h5 className="mb-4 text-dark">Earnings</h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Basic Pay</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.basic_pay)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Dearness Allowance</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.dearness_allowance)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Medical Allowance</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.medical_allowance)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">House Rent Allowance</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(
                                        item.house_rent_allowance
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Conveyance Allowance</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(
                                        item.conveyance_allowance
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-white">
                              <h5 className="mb-4 text-dark">Deductions</h5>
                              <Row className="bd-span-input ">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">
                                    Employee State Insurance
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(
                                        item.employee_state_insurance
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Provident Fund</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.provident_fund)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Professional Tax</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.professional_tax)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">
                                    Canteen/Other Deduction
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.other_deduction)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-grey">
                              <h5 className="mb-4 text-dark">Per Hour Rate</h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Per Hour Rate</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.per_rate_hour)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div className="repeater  bd-back-grey">
                            <div className="col-md-6 pb-20">
                              <h5 className="text-dark">Work Experience</h5>
                            </div>
                            <Row className="justify-content-between align-items-center bd-span-input">
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Company Name</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.company_name)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">From Date</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.from_date)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">To Date</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.to_date)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Position</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.position)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Salary</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.salary)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Reason For Leave</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.reason_for_leave)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Job Description</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.job_description)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Contact Person</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.contact_person)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Email Id</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.email)}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <label htmlFor="">Phone Number</label>
                                <div>
                                  <div className="input-span input">
                                    {renderDataField(item.phone_number)}
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div className="repeater  bd-back-grey">
                            <div className="col-md-6 mb-20">
                              <h5 className="text-dark">Education</h5>
                            </div>
                            <Row className="align-items-end bd-span-input">
                              <div className="col-md-5 mb-4">
                                <label htmlFor="">Board/Institute</label>
                                <div>
                                  <div className="input-span input">
                                    MS University
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 mb-4">
                                <label htmlFor="">Field</label>
                                <div>
                                  <div className="input-span input">
                                    Computer Engineering
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 mb-4">
                                <Row>
                                  <div className="col-6">
                                    <label htmlFor="">Year</label>
                                    <div>
                                      <div className="input-span input">
                                        2023
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <label htmlFor="">Percentage</label>
                                    <div>
                                      <div className="input-span input">
                                        80%
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </Row>
                          </div>
                        </TabPane>
                        <TabPane tabId="5">
                          <Documents />
                        </TabPane>
                        <TabPane tabId="6">
                          <div>
                            <div className="bd-back-grey">
                              <div className="col-md-6 mb-20">
                                <h4 className="text-dark">ZTPL Credential</h4>
                              </div>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">
                                    Email Id{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.email)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label htmlFor="">
                                    Password{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.password)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-white">
                              <h5 className="mb-4 text-dark">Zoho Mail</h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Email Id</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.zoho_email)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Password</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.zoho_password)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-grey">
                              <h5 className="mb-4 text-dark">Microsoft 365</h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Email Id</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.email)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Password</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.email)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-white">
                              <h5 className="mb-4 text-dark">Skype</h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Email Id</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.skype_email)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Password</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.skype_password)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>

                            <div className="mt-4 bd-back-grey">
                              <h5 className="mb-4 text-dark">
                                Git Credencials
                              </h5>
                              <Row className="bd-span-input">
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Username</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.git_username)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Password</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.git_password)}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                  <label htmlFor="">Secret Id</label>
                                  <div>
                                    <div className="input-span input">
                                      {renderDataField(item.git_secret_id)}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <PermissionView />
                        </TabPane>
                      </TabContent>
                    )
                  }
                })}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Access Denied */}

      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />

      {/* <Modal size="xl" isOpen={modal_Access} className="modal-fullscreen">
        <div className="modal-header mb-0">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <h5 className="col-6 modal-title mt-0">Access Denied</h5>
          </div>

          <button
            type="button"
            onClick={() => {
              setAccessDenied(false)
            }}
            className="ms-4"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <span
              aria-hidden="true"
              style={{ color: "#bf1e30", fontSize: "28px", lineHeight: "1px" }}
            >
              {" "}
              &times;{" "}
            </span>
          </button>
        </div>

        <div className="modal-body hide-bottom-scrollbar ps-3 pe-0 pt-0">
          <div className="text-center">
            <img src={AccessDenied} className="" height="500" />
            <div style={{ marginTop: "-100px" }}>
              <h1>Access Denied ...</h1>
              <p>You dont have Permisions to access this page.</p>
              <div>
                {" "}
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => setAccessDenied(false)}
                >
                  I Understood
                  <i className="dripicons-thumbs-up pl-10"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </React.Fragment>
  )
}

export default Staff
