import React, { useState } from "react"
import { Container, Modal } from "reactstrap"
import AccessDenieds from "../assets/images/dribbble_1.gif"
import { useNavigate } from "react-router-dom"
import HorizontalLayout from "components/HorizontalLayout"
import configure from "configure"

const AccessDenied = () => {
  const [modal_Access, setAccessDenied] = useState(true)
  document.title = "Access Denied"

  const navigate = useNavigate()
  return (
    <div>
      <div>
        <HorizontalLayout>
          <div
            className="page-content"
            style={{
              backgroundColor: "white",
              paddingTop: "0px",
              marginTop: "10px",
            }}
          >
            <Container fluid>
              <div className="text-center ">
                <img src={AccessDenieds} className="" height="530" />
                <div style={{ marginTop: "-100px" }}>
                  <h1>Access Denied ...</h1>
                  <p>You dont have Permisions to access this page.</p>
                  <div className="pb-4">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => navigate(`${configure.appUrl}`)}
                    >
                      I Understood
                      <i className="dripicons-thumbs-up pl-10"></i>
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </HorizontalLayout>
      </div>
    </div>
  )
}

export default AccessDenied
