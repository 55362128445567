import React, { useEffect, useState } from "react"
import {
  Badge,
  Container,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  Label,
} from "reactstrap"

// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"

import { Link, useNavigate } from "react-router-dom"
import { useMemo } from "react"
// import { useTable, usePagination, useGlobalFilter } from "react-table"
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
// import sideImg from "../../../../assets/images/profile.png"
import sideImg from "../../../../assets/images/users/avatar-1.jpg"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
// import ProposalPdf from "./ProposalPdf"

// for pdf
// assets
// import "../../../assets/css/Sales/Sales.css"
import "../../../../assets/css/Sales/Sales.css"
// import "../../../assets/css/Sales/Proposal.css"
import "../../../../assets/css/Sales/Proposal.css"
// import zithasLogo from "../../../assets/images/zithas-dark-logo.png"
// import AccessDenied from "../../../assets/images/dribbble_1.gif"

// common component
import Pagination from "components/Common/Pagination"
// import sideImg from "../../../assets/images/profile-img.png"
// import InfoGif from "../../../assets/images/GIF/question-mark.gif"
// components
// import ViewProfile from "./ViewProposal/ViewProfile"
// import ViewActivity from "./ViewProposal/ViewActivity"
import axios from "axios"
import moment from "moment"
import Loader from "pages/Separate/Loader"
import swal from "sweetalert"
// import RevisionHistory from "./EditProposal/RevisionHistory"
// import ViewRevision from "./ViewProposal/ViewRevision"
import configure from "configure"
import AccessDeniedPopup from "routes/AccessDeniedPopup"
import Select from "react-select"
// import "../../../assets/css/Work/projects.css"
import "../../../../assets/css/Work/projects.css"
import addTask from "../../../../assets/images/Project/addTask.jpg"
import ReactSelect from "react-select"
import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import { CKEditor } from "@ckeditor/ckeditor5-react"

import { select } from "redux-saga/effects"
import NewPagination from "components/Common/Newpagination"

function TableContainer({
  columns,
  data,
  setAccessDenied,
  setEditTask,
  setProjectType,
  projectType,
  setFormPopup,
  formPopup,
  setViewTiketPopup,
  viewTiketPopup,
}) {
  const [fixedContent, setFixedContent] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 300) {
      setFixedContent(true)
    } else {
      setFixedContent(false)
    }
  })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    setGlobalFilter,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "id", // Set the ID of the first column for default sorting
            desc: true, // `true` for descending, `false` for ascending
          },
        ],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize, globalFilter } = state

  useEffect(() => setPageSize(50), [])

  const [name_modal, setNameModal] = useState(false)
  const [title, setTitle] = useState("")

  const status_option = [
    { label: "Not Answered", value: "Not Answered" },
    { label: "Open", value: "Open" },
    { label: "In Progress", value: "In Progress" },
    { label: "Answered", value: "Answered" },
    { label: "On Hold", value: "On Hold" },
    { label: "Closed", value: "Closed" },
  ]
  const KeyAccountManager = [
    { label: "Rahul Ovhal", value: "1" },
    { label: "Zain", value: "2" },
    { label: "Manav", value: "3" },
  ]

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const [viewData, setViewData] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [currency_data, setCurencyData] = useState([])

  // const onProposalData = async e => {
  //   setViewData(true)
  //   setLoading(true)
  //   try {
  //     const response = await axios({
  //       method: "get",
  //       url: "https://mars.ztpl.net/api/proposal_currency_count",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "multipart/form-data",
  //       },
  //     }).then(response => {
  //        console.log(response)
  //       setCurencyData(response.data.data)
  //       setLoading(false)
  //     })
  //   } catch (err) {
  //     console.log(err)
  //     setLoading(false)
  //   }
  // }
  // console.log(currency_data)

  const onAddPermission = async id => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    formData.append("module_id", 4)
    try {
      await axios({
        method: "post",
        url: `${configure.apiUrl}/user_permission`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(res => {
        if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
          navigate(`${configure.appUrl}work/add-project`)
        } else {
          setAccessDenied(true)
        }
        setLoading(false)
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setProjectType("1")
  }, [])

  // Render the UI for your table
  return (
    <div>
      {isLoading && <Loader />}
      {/* fixed buttons */}
      <div
        className={`flex-column fixed-buttons pt-2 ${
          fixedContent ? "d-flex" : "d-none"
        }`}
      >
        <Link
          // to={`${configure.appUrl}sales/add-proposal`}
          onClick={() => onAddPermission()}
          className="btn btn-save s fix-btn btn-label pe-0"
        >
          <i className="fas fa-plus me-2 icon-size label-icon"></i>
          <span>Project</span>
        </Link>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-red s fix-btn btn-label pe-0"
        >
          <i className="dripicons-cross me-2 icon-center label-icon"></i>
          <span>Cancel</span>
        </button>
      </div>
      <Row className="mb-4 align-items-center">
        <Col md={10} className="pr-0">
          <div className="d-flex justify-content-between align-items-center">
            {/* counts */}
            <div className="d-flex justify-content-start w-100 text-center align-items-center gap-3">
              <a
                href="#"
                className={`"text-green pt-2 px-2 rounded proposal-status ${
                  projectType == "1" ? "activeProjectTab" : ""
                }`}
                onClick={() => setProjectType("1")}
              >
                <h4>0</h4>
                <h4>Not Answered</h4>
              </a>
              <a
                href="#"
                className={`"text-green pt-2 px-2 rounded proposal-status ${
                  projectType == "2" ? "activeProjectTab" : ""
                }`}
                onClick={() => setProjectType("2")}
              >
                <h4>0</h4>
                <h4>Open</h4>
              </a>
              <a
                href="#"
                onClick={() => setProjectType("3")}
                className={`"text-green pt-2 px-2 rounded proposal-status ${
                  projectType == "3" ? "activeProjectTab" : ""
                }`}
              >
                <h4>3</h4>
                <h4>In Progress</h4>
              </a>
              <a
                href="#"
                className={`"text-green pt-2 px-2 rounded proposal-status ${
                  projectType == "4" ? "activeProjectTab" : ""
                }`}
                onClick={() => setProjectType("4")}
              >
                <h4>0</h4>
                <h4>Answered</h4>
              </a>
              <a
                href="#"
                className={`"text-green pt-2 px-2 rounded proposal-status ${
                  projectType == "5" ? "activeProjectTab" : ""
                }`}
                onClick={() => setProjectType("5")}
              >
                <h4>0</h4>
                <h4>On Hold</h4>
              </a>
              <a
                href="#"
                className={`"text-green pt-2 px-2 rounded proposal-status ${
                  projectType == "6" ? "activeProjectTab" : ""
                }`}
                onClick={() => setProjectType("6")}
              >
                <h4>0</h4>
                <h4>Closed</h4>
              </a>
            </div>
          </div>
        </Col>
        <Col
          md={2}
          className="d-flex align-items-center justify-content-end pl-0"
        >
          {/* <div>
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="me-4 mt-10">
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div> */}
          <div className="text-md-end">
            <button
              onClick={() => navigate(-1)}
              className="s btn btn-red btn-label"
            >
              <i className="dripicons-cross me-2 icon-center label-icon"></i>
              CANCEL
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="col-md-12 my-4 d-flex">
          <div className="col-md-3 d-flex justify-content-start align-items-center">
            <select
              className="s me-4 form-select"
              style={{ width: "100px" }}
              value={pageSize}
              onChange={e => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-9 d-flex justify-content-end align-items-center">
            <div className="mb-2">
              <button
                onClick={() => setFormPopup(!formPopup)}
                className="btn btn-outline-secondary  me-3"
              >
                <i className="fas fa-filter"></i>
              </button>
            </div>
            <div className="search-box">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    onChange={e => setGlobalFilter(e.target.value)}
                    id="search-bar-0"
                    type="text"
                    className="s form-control rounded "
                    placeholder={` Search...`}
                    value={globalFilter || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="position-relative">
        {/* <Table className="table table-bordered" {...getTableProps()}>
          <thead
            className={`${
              fixedContent ? "fix-mtask-header" : ""
            } fix-project-header`}
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    className={`t-col-${index + 1} ${column.className}`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                    {column.isInfoIcon ? (
                      <a
                        onClick={() => {
                          setNameModal(true)
                          setTitle(column.Header)
                        }}
                      >
                        
                      </a>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="fix-project-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              let rowColor
              switch (row.original.status_name) {
                case "In Progress":
                  rowColor = "bg-green"
                  break
                case "Awaiting Feedback":
                  rowColor = "bg-red"
                  break
                case "Complete":
                  rowColor = "bg-yellow"
                  break
              }
              return (
                <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                  title="View Tiket"
                  style={{cursor:"pointer"}}
                  onClick={()=> setViewTiketPopup(true)}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table> */}
        <Table className="table table-bordered mt-4" {...getTableProps()}>
          <thead
            className={`position-relative ${
              fixedContent ? `fix-mtask-header  ` : ""
            } fix-project-header`}
            // className="fixed-setting-header-cred"
          >
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const classNames = column.className
                  return (
                    <th
                      key={index}
                      className={` col-t-${index + 1} ${classNames}`}
                      // {...column.getHeaderProps()}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div
                        className={`position-relative d-flex ${
                          column.isShortIcon
                            ? "justify-content-between"
                            : "justify-content-center"
                        }`}
                      >
                        {index === 0 && <span></span>}
                        <span
                          style={{
                            position: index === 0 ? "absolute" : "",
                            left: index === 0 ? "45%" : "",
                          }}
                        >
                          {column.render("Header")}
                        </span>
                        {column.isShortIcon ? (
                          <span
                            style={{
                              position: index != 0 ? "absolute" : "",
                              right: index != 0 ? "0" : "",
                            }}
                            onClick={() =>
                              column.getSortByToggleProps().onClick()
                            }
                          >
                            <i
                              title="Ascending"
                              className={`bi bi-arrow-up ms-1 info-icon ${
                                column.isSorted && !column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                            <i
                              title="Descending"
                              className={`bi bi-arrow-down info-icon ${
                                column.isSorted && column.isSortedDesc
                                  ? "text-dark"
                                  : ""
                              }`}
                            ></i>
                          </span>
                        ) : null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          {/* <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr className="row-hover" {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, index) => {
                    const column = row.cells[index].column
                    const isLink =
                      cell.value &&
                      typeof cell.value === "string" &&
                      (cell.value.includes("https://") ||
                        cell.value.includes("http://"))
                 
                    const cellId = `${row.id}-${cell.column.id}`
                    return (
                      <>
                        <td
                          className={`special-class-${
                            row.cells.length
                          }-length-t-${index + 1} col-hover col-t-${
                            index + 1
                          } ${cell.classNames}`}
                          key={index}
                          {...cell.getCellProps()}
                          style={{ wordWrap: "anywhere" }}
                        >
                      
                        </td>
                      </>
                    )
                  })}
                </tr>
              )
            })}
            {page.length == 0 && (
              <tr className="border border-1">
                <td colSpan={8} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody> */}

          <tbody className="fix-project-header" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              let rowColor
              switch (row.original.status_name) {
                case "In Progress":
                  rowColor = "bg-green"
                  break
                case "Awaiting Feedback":
                  rowColor = "bg-red"
                  break
                case "Complete":
                  rowColor = "bg-yellow"
                  break
              }

              //   const handleViewTicket = () => {
              //     const lastIndex = page.length - 1;
              //     if (i !== 4 && i !== lastIndex) {
              //         setViewTiketPopup(true);
              //     }
              // };
              const handleViewTicket = event => {
                // const targetCell = event.target.closest("td")
                // console.log("i", i)
                setViewTiketPopup(true)
               
             
              }
              // console.log("row.cells.column.isViewModel",row.cells.column.isViewModel)
              const column = row.cells[i].column
              return (
                <>
                {column?.isViewModel == true ? (
                  <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                  title="View Tiket"
                  style={{ cursor: "pointer" }}
                  onClick={() => setViewTiketPopup(true)}
                >
                  {row.cells.map((cell, index) => {
                    const priority = row.original.priority_col
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        } ${priority == "High" ? "highPriority" : ""}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
                ) : (
                  <tr
                  className={`row-hover ${rowColor}`}
                  {...row.getRowProps()}
                  key={i}
                  title="View Tiket ssssyhs"
                  style={{ cursor: "pointer" }}
                  // onClick={() => setViewTiketPopup(false)}

                >
                  {row.cells.map((cell, index) => {
                    // console.log("cell", cell.value)
                    const priority = row.original.priority_col
                    return (
                      <td
                        key={index}
                        className={`t-col-${index + 1} ${
                          cell.column.className
                        } ${priority == "High" ? "highPriority" : ""}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
                )}
                
                </>
                
              )
            })}
          </tbody>
        </Table>
      </div>
      {formPopup ? (
        <div id="support-filter">
          <div className="popup-form p-4">
            <Form>
              <Row className="align-items-end">
                <Row className="d-flex pe-0 me-0" style={{ width: "100%" }}>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="">Developer</label>
                    <ReactSelect
                      placeholder="Select Developers"
                      className="basic-multi-select "
                      classNamePrefix="select"
                      options={KeyAccountManager}
                      isMulti="true"
                    />
                  </div>
                  <div className="col-md-3 mt-2">
                    <label htmlFor="">Status</label>
                    <Select
                      placeholder="Select Status"
                      className="basic-multi-select"
                      classNamePrefix=" select"
                      styles={colourStyles}
                      options={status_option}
                      closeMenuOnSelect={false}
                      isMulti
                      // onChange={e => setStatus(e)}
                    />
                  </div>

                  <div className="col-md-3 mt-2">
                    <label htmlFor="">Save and Clear Filters</label>
                    <div>
                      <a
                        className="btn  btn-primary mr-10"
                        // onClick={onSaveFilter}
                        style={{
                          fontSize: "11px",
                          backgroundColor: "#93a6ff",
                          borderColor: "#93a6ff",
                          color: "black",
                        }}
                      >
                        Save Filters
                      </a>
                      <a
                        className="btn  btn-danger"
                        // onClick={onClearFilter}
                        style={{
                          fontSize: "11px",
                          backgroundColor: "#fed36d",
                          borderColor: "#fed36d",
                          color: "black",
                        }}
                      >
                        Clear Filters
                      </a>
                    </div>
                  </div>
                  <div className="d-flex col-md-2 align-items-end justify-content-end">
                    <div className="mr-10">
                      <button className="btn  btn-purple">
                        <i className="fas fa-filter search-icon me-1 icon-center"></i>{" "}
                        Filter
                      </button>
                    </div>
                    <div className="">
                      <a
                        className="btn  btn-red"
                        onClick={e => {
                          e.preventDefault()
                          // setDatas(displayData)
                          setFormPopup(!formPopup)
                        }}
                      >
                        <i className="dripicons-cross me-1 icon-center"></i>{" "}
                        Close
                      </a>
                    </div>
                  </div>
                </Row>
              </Row>
            </Form>
            <i className="bi bi-triangle-fill pop-icon"></i>
          </div>
        </div>
      ) : null}
      {/* Pagination */}
      <Row className="justify-content-md-end justify-space-between align-items-center">
        <NewPagination
          gotoPage={gotoPage}
          canPreviousPag={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          page={page}
          data={data}
        />
      </Row>

      <Modal size="md" centered isOpen={viewData} className="activity-log">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Log Details</h5>
          <button
            type="button"
            onClick={() => {
              setViewData(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="table-responsive">
            <table className="table mb-0 table">
              <thead className="table-light">
                <tr>
                  <th>In Progress</th>
                  <th>Awaiting Feedback</th>
                  <th>Complete</th>
                </tr>
              </thead>
              <tbody>
                {/* {viewData.map((items, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{items}</th>
                      <td>
                        <div
                          className="no-padding-desc"
                          dangerouslySetInnerHTML={{
                            __html: oldValue[index],
                          }}
                        />
                      </td>
                      <td>

                      </td>
                    </tr>
                  )
                })} */}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const EditMTaskNew = () => {
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [formRows, setFormRows] = useState([{}])
  const inpRow = [{ name: "", file: "" }]
  const [inputFields, setinputFields] = useState(inpRow)
  const [selectedFiles1, setselectedFiles1] = useState([])
  const [formPopup, setFormPopup] = useState(false)

  function handleAddFields() {
    const item1 = { name: "", file: "" }
    setinputFields([...inputFields, item1])
  }

  const onAddFormRow = () => {
    setFormRows([...formRows, {}])
  }

  const onDeleteFormRow = index => {
    const newInputs = [...formRows]
    newInputs.splice(index, 1)
    setFormRows(newInputs)
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function handleAcceptedFiles1(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles1(files)
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  document.addEventListener("keydown", e => {
    if (e.key === "Escape") setmodal_fullscreen(false), setFormPopup(false)
  })

  //meta title
  document.title = "Projects | Zithas Technologies"

  //   const [data, setDatas] = useState([])
  const [view_proposal, setViewProposal] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("user_id", localStorage.getItem("auth_id"))
    try {
      const response = await axios({
        method: "post",
        data: formData,
        url: `${configure.apiUrl}/proposal`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        // setDatas(response.data.data.proposal)
        setLoading(false)
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  const [proposal_activity, setProposalActivity] = useState([])
  const [proposal_revision, setProposalRevision] = useState([])
  const [modal_Access, setAccessDenied] = useState(false)
  const [timerStatus, setTimerStatus] = useState(false)

  const [editTask, setEditTask] = useState(false)
  const [replyTiket, setReplyTiket] = useState(false)
  const [viewTiketPopup, setViewTiketPopup] = useState(false)

  const navigate = useNavigate()

  document.addEventListener("keydown", e => {
    if (e.key === "Escape")
      setEditTask(false), setTimerStatus(false), setViewTiketPopup(false)
  })

  const SelectStatus = [
    { id: 1, value: "In Progress" },
    { id: 2, value: "Awaiting Feedback" },
    { id: 3, value: "Complete" },
  ]

  const [selectStatus, setSelectStatus] = useState(false)

  // const data = [
  //   {
  //       id: "1",
  //       assign_name: "Divyansh",
  //       type_name: "Bug",
  //       customer : "Global Bio Tech Private Ltd.",
  //       task_name: "Task 1",
  //       priority_col: "High",
  //       last_reply_col : "10-08-2024",
  //       created_at : "12-08-2024",
  //       time_taken_col : " 2 days",
  //   },
  //   {
  //       id: "2",
  //       assign_name: "Divyansh",
  //       type_name: "Suggestion",
  //       customer : "Global Bio Tech Private Ltd.",
  //       task_name: "Task 1",
  //       priority_col: "High",
  //       last_reply_col : "10-08-2024",
  //       created_at : "12-08-2024",
  //       time_taken_col : " 2 days",
  //   },
  //   {
  //       id: "3",
  //       assign_name: "Divyansh",
  //       type_name: "Task",
  //       customer : "Global Bio Tech Private Ltd.",
  //       task_name: "Task 1",
  //       priority_col: "High",
  //       last_reply_col : "10-08-2024",
  //       created_at : "12-08-2024",
  //       time_taken_col : " 2 days",
  //   },

  // ]

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 1200,
    }),
  }

  const milestone = [
    { label: "Milestone 1", value: "Milestone 1" },
    { label: "Milestone 2", value: "Milestone 2" },
    { label: "Milestone 3", value: "Milestone 3" },
    { label: "Milestone 4", value: "Milestone 4" },
    { label: "Milestone 5", value: "Milestone 5" },
  ]
  const Assigned = [
    { label: "Rahul", value: "Rahul" },
    { label: "Divyansh", value: "Divyansh" },
    { label: "Manav", value: "Manav" },
    { label: "Zain", value: "Zain" },
    { label: "Yasir", value: "Yasir" },
    { label: "Nimesh", value: "Nimesh" },
  ]
  const Priority = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ]
  const Estimation_Hours = [
    { label: "1 hour", value: "1 hour" },
    { label: "2 Hours", value: "2 Hours" },
    { label: "3 Hours", value: "3 Hours" },
  ]
  const ProjectType = [
    { label: "Bug", value: "Bug" },
    { label: "Suggestion", value: "Suggestion" },
    { label: "New Devlopment", value: "New Devlopment" },
  ]

  const [projectType, setProjectType] = useState("")

  const columns1 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center m_id",
        isViewModel: true,
      },
      {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "assignedm_name_box text-center",
        isViewModel: true,

        Cell: cellProps => {
          return (
            <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
              <img
                src={sideImg}
                alt=""
                className="img-fluid"
                style={{ width: "30%", borderRadius: "100%" }}
                title="User Name"
              />
            </div>
          )
        },
      },
      {
        Header: "Type",
        title: "type_name",
        accessor: "type_name",
        isInfoIcon: false,
        isShortIcon: true,
        isViewModel: true,

        className: "mtype_name_box text-center",
      },

      {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtask_name_box",
        isViewModel: true,
      },

      {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        isViewModel : false,
        className: "mstatus_box text-center",
        Cell: cellProps => {
          return (
            <div className="custom-select-wrapper" style={{ width: "100%" }}>
              <select
                className={`form-select custom-select ${
                  selectStatus == "inprogress"
                    ? "status_inprogress"
                    : selectStatus == "awaitingfeedback"
                    ? "status_await"
                    : selectStatus == "complete"
                    ? "status_complete"
                    : ""
                }`}
                onSelect={e => setSelectStatus(e.target.value)}
              >
                <option value="" className="">
                  - Select -
                </option>
                <option value="notanswered" className="" selected>
                  Not Answered
                </option>
                <option value="open" className="">
                  Open
                </option>
                <option value="inprogress" className="">
                  In Progress
                </option>
                <option value="answered" className="">
                  Answered
                </option>
                <option value="onhol`d" className="">
                  on Hold
                </option>
                <option value="Closed" className="">
                  Closed
                </option>
              </select>
            </div>
          )
        },
      },
      {
        Header: "Priority",
        title: "Priority",
        accessor: "priority_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "priority_col_box text-center px-2",
        isViewModel: true,
      },
      {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "last_reply_col-box text-center px-2",
        isViewModel: true,
      },
      {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "created_at_col-box text-center px-1",
        isViewModel: true,
      },
      {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "time_taken_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "time_taken_box text-center px-2",
        isViewModel: true,
      },

      {
        Header: "Action",
        accessor: "action",
        className: "mtask_action text-center",
        isViewModel : false,
        Cell: cellProps => {
          const onDeleteLead = e => {
            swal({
              title: "Delete Task ?",
              text: "Are you sure you want to delete task ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("proposal_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_proposal`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      title: "Task",
                      text: "Task Deleted Successfully",
                      buttons: true,
                    }).then(data => {
                      //   setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 4)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
                  navigate(`${configure.appUrl}work/edit-project`)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onTimerStart = async e => {
            if (timerStatus == true) {
              swal({
                title: "Stop Timer ?",
                text: "Are you sure you want to stop timer ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async willDelete => {
                if (willDelete) {
                  swal({
                    icon: "success",
                    text: "Timer Stoped",
                  })
                  setTimerStatus(false)
                }
              })
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-sm btn-outline-success me-1"
                title="Start / Stop Timer"
                onClick={onTimerStart}
                // onClick={()=> setTimerStatus(!timerStatus)}
              >
                <i className="fa fa-stopwatch"></i>
              </a>
              <a
                onClick={() => setEditTask(true)}
                className="btn btn-sm btn-outline-secondary me-1"
                title="Edit Task"
              >
                <i className="fas fa-pen "></i>
              </a>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link title="Task History" className="dropdown-item">
                    <i className="bi bi-clock-history pr-10"></i> Task History
                  </Link>

                  <Link
                    title="Reply Task"
                    className="dropdown-item"
                    onClick={() => setReplyTiket(true)}
                  >
                    <i className="bi bi-reply-fill pr-10"></i> Reply Task
                  </Link>

                  <a
                    className="dropdown-item"
                    title="Delete Task"
                    onClick={onDeleteLead}
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Task
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [selectStatus]
  )

  const columns2 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center m_id",
      },
      {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "assignedm_name_box text-center",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
              <img
                src={sideImg}
                alt=""
                className="img-fluid"
                style={{ width: "30%", borderRadius: "100%" }}
                title="User Name"
              />
            </div>
          )
        },
      },
      {
        Header: "Type",
        title: "type_name",
        accessor: "type_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtype_name_box text-center",
      },

      {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtask_name_box",
      },

      {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mstatus_box text-center",
        Cell: cellProps => {
          return (
            <div className="custom-select-wrapper" style={{ width: "100%" }}>
              <select
                className={`form-select custom-select ${
                  selectStatus == "inprogress"
                    ? "status_inprogress"
                    : selectStatus == "awaitingfeedback"
                    ? "status_await"
                    : selectStatus == "complete"
                    ? "status_complete"
                    : ""
                }`}
                onSelect={e => setSelectStatus(e.target.value)}
              >
                <option value="" className="">
                  - Select -
                </option>
                <option value="notanswered" className="">
                  Not Answered
                </option>
                <option value="open" className="" selected>
                  Open
                </option>
                <option value="inprogress" className="">
                  In Progress
                </option>
                <option value="answered" className="">
                  Answered
                </option>
                <option value="onhol`d" className="">
                  on Hold
                </option>
                <option value="Closed" className="">
                  Closed
                </option>
              </select>
            </div>
          )
        },
      },
      {
        Header: "Priority",
        title: "Priority",
        accessor: "priority_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "priority_col_box text-center",
      },
      {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "last_reply_col-box text-center",
      },
      {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "created_at_col-box text-center",
      },
      {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "time_taken_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "time_taken_box text-center",
      },

      {
        Header: "Action",
        accessor: "action",
        className: "mtask_action text-center",
        Cell: cellProps => {
          const onDeleteLead = e => {
            swal({
              title: "Delete Task ?",
              text: "Are you sure you want to delete task ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("proposal_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_proposal`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      title: "Task",
                      text: "Task Deleted Successfully",
                      buttons: true,
                    }).then(data => {
                      //   setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 4)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
                  navigate(`${configure.appUrl}work/edit-project`)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onTimerStart = async e => {
            if (timerStatus == true) {
              swal({
                title: "Stop Timer ?",
                text: "Are you sure you want to stop timer ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async willDelete => {
                if (willDelete) {
                  swal({
                    icon: "success",
                    text: "Timer Stoped",
                  })
                  setTimerStatus(false)
                }
              })
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-sm btn-outline-success me-1"
                title="Start / Stop Timer"
                onClick={onTimerStart}
                // onClick={()=> setTimerStatus(!timerStatus)}
              >
                <i className="fa fa-stopwatch"></i>
              </a>
              <a
                onClick={() => setEditTask(true)}
                className="btn btn-sm btn-outline-secondary me-1"
                title="Edit Task"
              >
                <i className="fas fa-pen "></i>
              </a>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link title="Task History" className="dropdown-item">
                    <i className="bi bi-clock-history pr-10"></i> Task History
                  </Link>

                  <Link
                    title="Reply Task"
                    className="dropdown-item"
                    onClick={() => setReplyTiket(true)}
                  >
                    <i className="bi bi-reply-fill pr-10"></i> Reply Task
                  </Link>

                  <a
                    className="dropdown-item"
                    title="Delete Task"
                    onClick={onDeleteLead}
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Task
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [selectStatus]
  )

  const columns3 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center m_id",
      },
      {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "assignedm_name_box text-center",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
              <img
                src={sideImg}
                alt=""
                className="img-fluid"
                style={{ width: "30%", borderRadius: "100%" }}
                title="User Name"
              />
            </div>
          )
        },
      },
      {
        Header: "Type",
        title: "type_name",
        accessor: "type_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtype_name_box text-center",
      },

      {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtask_name_box",
      },

      {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mstatus_box text-center",
        Cell: cellProps => {
          return (
            <div className="custom-select-wrapper" style={{ width: "100%" }}>
              <select
                className={`form-select custom-select ${
                  selectStatus == "inprogress"
                    ? "status_inprogress"
                    : selectStatus == "awaitingfeedback"
                    ? "status_await"
                    : selectStatus == "complete"
                    ? "status_complete"
                    : ""
                }`}
                onSelect={e => setSelectStatus(e.target.value)}
              >
                <option value="" className="">
                  - Select -
                </option>
                <option value="notanswered" className="">
                  Not Answered
                </option>
                <option value="open" className="">
                  Open
                </option>
                <option value="inprogress" className="" selected>
                  In Progress
                </option>
                <option value="answered" className="">
                  Answered
                </option>
                <option value="onhol`d" className="">
                  on Hold
                </option>
                <option value="Closed" className="">
                  Closed
                </option>
              </select>
            </div>
          )
        },
      },
      {
        Header: "Priority",
        title: "Priority",
        accessor: "priority_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "priority_col_box text-center",
      },
      {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "last_reply_col-box text-center",
      },
      {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "created_at_col-box text-center",
      },
      {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "time_taken_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "time_taken_box text-center",
      },

      {
        Header: "Action",
        accessor: "action",
        className: "mtask_action text-center",
        Cell: cellProps => {
          const onDeleteLead = e => {
            swal({
              title: "Delete Task ?",
              text: "Are you sure you want to delete task ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("proposal_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_proposal`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      title: "Task",
                      text: "Task Deleted Successfully",
                      buttons: true,
                    }).then(data => {
                      //   setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 4)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
                  navigate(`${configure.appUrl}work/edit-project`)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onTimerStart = async e => {
            if (timerStatus == true) {
              swal({
                title: "Stop Timer ?",
                text: "Are you sure you want to stop timer ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async willDelete => {
                if (willDelete) {
                  swal({
                    icon: "success",
                    text: "Timer Stoped",
                  })
                  setTimerStatus(false)
                }
              })
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-sm btn-outline-success me-1"
                title="Start / Stop Timer"
                onClick={onTimerStart}
                // onClick={()=> setTimerStatus(!timerStatus)}
              >
                <i className="fa fa-stopwatch"></i>
              </a>
              <a
                onClick={() => setEditTask(true)}
                className="btn btn-sm btn-outline-secondary me-1"
                title="Edit Task"
              >
                <i className="fas fa-pen "></i>
              </a>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link title="Task History" className="dropdown-item">
                    <i className="bi bi-clock-history pr-10"></i> Task History
                  </Link>

                  <Link
                    title="Reply Task"
                    className="dropdown-item"
                    onClick={() => setReplyTiket(true)}
                  >
                    <i className="bi bi-reply-fill pr-10"></i> Reply Task
                  </Link>

                  <a
                    className="dropdown-item"
                    title="Delete Task"
                    onClick={onDeleteLead}
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Task
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [selectStatus]
  )

  const columns4 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center m_id",
      },
      {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "assignedm_name_box text-center",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
              <img
                src={sideImg}
                alt=""
                className="img-fluid"
                style={{ width: "30%", borderRadius: "100%" }}
                title="User Name"
              />
            </div>
          )
        },
      },
      {
        Header: "Type",
        title: "type_name",
        accessor: "type_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtype_name_box text-center",
      },

      {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtask_name_box",
      },

      {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mstatus_box text-center",
        Cell: cellProps => {
          return (
            <div className="custom-select-wrapper" style={{ width: "100%" }}>
              <select
                className={`form-select custom-select ${
                  selectStatus == "inprogress"
                    ? "status_inprogress"
                    : selectStatus == "awaitingfeedback"
                    ? "status_await"
                    : selectStatus == "complete"
                    ? "status_complete"
                    : ""
                }`}
                onSelect={e => setSelectStatus(e.target.value)}
              >
                <option value="" className="">
                  - Select -
                </option>
                <option value="notanswered" className="">
                  Not Answered
                </option>
                <option value="open" className="">
                  Open
                </option>
                <option value="inprogress" className="">
                  In Progress
                </option>
                <option value="answered" className="" selected>
                  Answered
                </option>
                <option value="onhol`d" className="">
                  on Hold
                </option>
                <option value="Closed" className="">
                  Closed
                </option>
              </select>
            </div>
          )
        },
      },
      {
        Header: "Priority",
        title: "Priority",
        accessor: "priority_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "priority_col_box text-center",
      },
      {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "last_reply_col-box text-center",
      },
      {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "created_at_col-box text-center",
      },
      {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "time_taken_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "time_taken_box text-center",
      },

      {
        Header: "Action",
        accessor: "action",
        className: "mtask_action text-center",
        Cell: cellProps => {
          const onDeleteLead = e => {
            swal({
              title: "Delete Task ?",
              text: "Are you sure you want to delete task ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("proposal_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_proposal`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      title: "Task",
                      text: "Task Deleted Successfully",
                      buttons: true,
                    }).then(data => {
                      //   setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 4)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
                  navigate(`${configure.appUrl}work/edit-project`)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onTimerStart = async e => {
            if (timerStatus == true) {
              swal({
                title: "Stop Timer ?",
                text: "Are you sure you want to stop timer ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async willDelete => {
                if (willDelete) {
                  swal({
                    icon: "success",
                    text: "Timer Stoped",
                  })
                  setTimerStatus(false)
                }
              })
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-sm btn-outline-success me-1"
                title="Start / Stop Timer"
                onClick={onTimerStart}
                // onClick={()=> setTimerStatus(!timerStatus)}
              >
                <i className="fa fa-stopwatch"></i>
              </a>
              <a
                onClick={() => setEditTask(true)}
                className="btn btn-sm btn-outline-secondary me-1"
                title="Edit Task"
              >
                <i className="fas fa-pen "></i>
              </a>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link title="Task History" className="dropdown-item">
                    <i className="bi bi-clock-history pr-10"></i> Task History
                  </Link>

                  <Link
                    title="Reply Task"
                    className="dropdown-item"
                    onClick={() => setReplyTiket(true)}
                  >
                    <i className="bi bi-reply-fill pr-10"></i> Reply Task
                  </Link>

                  <a
                    className="dropdown-item"
                    title="Delete Task"
                    onClick={onDeleteLead}
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Task
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [selectStatus]
  )

  const columns5 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center m_id",
      },
      {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "assignedm_name_box text-center",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
              <img
                src={sideImg}
                alt=""
                className="img-fluid"
                style={{ width: "30%", borderRadius: "100%" }}
                title="User Name"
              />
            </div>
          )
        },
      },
      {
        Header: "Type",
        title: "type_name",
        accessor: "type_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtype_name_box text-center",
      },

      {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtask_name_box",
      },

      {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mstatus_box text-center",
        Cell: cellProps => {
          return (
            <div className="custom-select-wrapper" style={{ width: "100%" }}>
              <select
                className={`form-select custom-select ${
                  selectStatus == "inprogress"
                    ? "status_inprogress"
                    : selectStatus == "awaitingfeedback"
                    ? "status_await"
                    : selectStatus == "complete"
                    ? "status_complete"
                    : ""
                }`}
                onSelect={e => setSelectStatus(e.target.value)}
              >
                <option value="" className="">
                  - Select -
                </option>
                <option value="notanswered" className="">
                  Not Answered
                </option>
                <option value="open" className="">
                  Open
                </option>
                <option value="inprogress" className="">
                  In Progress
                </option>
                <option value="answered" className="">
                  Answered
                </option>
                <option value="onhol`d" className="" selected>
                  on Hold
                </option>
                <option value="Closed" className="">
                  Closed
                </option>
              </select>
            </div>
          )
        },
      },
      {
        Header: "Priority",
        title: "Priority",
        accessor: "priority_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "priority_col_box text-center",
      },
      {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "last_reply_col-box text-center",
      },
      {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "created_at_col-box text-center",
      },
      {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "time_taken_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "time_taken_box text-center",
      },

      {
        Header: "Action",
        accessor: "action",
        className: "mtask_action text-center",
        Cell: cellProps => {
          const onDeleteLead = e => {
            swal({
              title: "Delete Task ?",
              text: "Are you sure you want to delete task ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("proposal_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_proposal`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      title: "Task",
                      text: "Task Deleted Successfully",
                      buttons: true,
                    }).then(data => {
                      //   setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 4)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
                  navigate(`${configure.appUrl}work/edit-project`)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onTimerStart = async e => {
            if (timerStatus == true) {
              swal({
                title: "Stop Timer ?",
                text: "Are you sure you want to stop timer ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async willDelete => {
                if (willDelete) {
                  swal({
                    icon: "success",
                    text: "Timer Stoped",
                  })
                  setTimerStatus(false)
                }
              })
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-sm btn-outline-success me-1"
                title="Start / Stop Timer"
                onClick={onTimerStart}
                // onClick={()=> setTimerStatus(!timerStatus)}
              >
                <i className="fa fa-stopwatch"></i>
              </a>
              <a
                onClick={() => setEditTask(true)}
                className="btn btn-sm btn-outline-secondary me-1"
                title="Edit Task"
              >
                <i className="fas fa-pen "></i>
              </a>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link title="Task History" className="dropdown-item">
                    <i className="bi bi-clock-history pr-10"></i> Task History
                  </Link>

                  <Link
                    title="Reply Task"
                    className="dropdown-item"
                    onClick={() => setReplyTiket(true)}
                  >
                    <i className="bi bi-reply-fill pr-10"></i> Reply Task
                  </Link>

                  <a
                    className="dropdown-item"
                    title="Delete Task"
                    onClick={onDeleteLead}
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Task
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [selectStatus]
  )

  const columns6 = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        title: "ID",
        isInfoIcon: false,
        isShortIcon: true,
        className: "text-center m_id",
      },
      {
        Header: "Assign",
        title: "assign",
        accessor: "assign_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "assignedm_name_box text-center",
        Cell: cellProps => {
          return (
            <div className="text-center d-flex gap-2 flex-wrap justify-content-center align-items-center">
              <img
                src={sideImg}
                alt=""
                className="img-fluid"
                style={{ width: "30%", borderRadius: "100%" }}
                title="User Name"
              />
            </div>
          )
        },
      },
      {
        Header: "Type",
        title: "type_name",
        accessor: "type_name",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mtype_name_box text-center",
      },

      {
        Header: "Task Name",
        title: "Task Name",
        accessor: "task_name",
        isInfoIcon: false,

        className: "mtask_name_box",
      },

      {
        Header: "Status",
        title: "Status",
        accessor: "status_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "mstatus_box text-center",
        Cell: cellProps => {
          return (
            <div className="custom-select-wrapper" style={{ width: "100%" }}>
              <select
                className={`form-select custom-select ${
                  selectStatus == "inprogress"
                    ? "status_inprogress"
                    : selectStatus == "awaitingfeedback"
                    ? "status_await"
                    : selectStatus == "complete"
                    ? "status_complete"
                    : ""
                }`}
                onSelect={e => setSelectStatus(e.target.value)}
              >
                <option value="" className="">
                  - Select -
                </option>
                <option value="notanswered" className="">
                  Not Answered
                </option>
                <option value="open" className="">
                  Open
                </option>
                <option value="inprogress" className="">
                  In Progress
                </option>
                <option value="answered" className="">
                  Answered
                </option>
                <option value="onhol`d" className="">
                  on Hold
                </option>
                <option value="Closed" className="" selected>
                  Closed
                </option>
              </select>
            </div>
          )
        },
      },
      {
        Header: "Priority",
        title: "Priority",
        accessor: "priority_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "priority_col_box text-center",
      },
      {
        Header: "Last Reply",
        title: "Last Reply",
        accessor: "last_reply_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "last_reply_col-box text-center",
      },
      {
        Header: "Created At",
        title: "created at",
        accessor: "created_at",
        isInfoIcon: false,
        isShortIcon: true,
        className: "created_at_col-box text-center",
      },
      {
        Header: "Time Taken",
        title: "Last Updated Days",
        accessor: "time_taken_col",
        isInfoIcon: false,
        isShortIcon: true,
        className: "time_taken_box text-center",
      },

      {
        Header: "Action",
        accessor: "action",
        className: "mtask_action text-center",
        Cell: cellProps => {
          const onDeleteLead = e => {
            swal({
              title: "Delete Task ?",
              text: "Are you sure you want to delete task ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async willDelete => {
              if (willDelete) {
                setLoading(true)
                const formData = new FormData()
                formData.append("user_id", localStorage.getItem("auth_id"))
                formData.append("proposal_id", cellProps.row.original.id)
                try {
                  const response = await axios({
                    method: "post",
                    url: `${configure.apiUrl}/delete_proposal`,
                    data: formData,
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                    },
                  }).then(response => {
                    swal({
                      icon: "success",
                      title: "Task",
                      text: "Task Deleted Successfully",
                      buttons: true,
                    }).then(data => {
                      //   setDatas(response.data.data)
                    })
                    setLoading(false)
                  })
                } catch (err) {
                  swal({
                    icon: "error",
                    text: "SomeThing Went Wrong",
                  })
                  setLoading(false)
                }
              }
            })
          }

          const onEditPermission = async id => {
            setLoading(true)
            const formData = new FormData()
            formData.append("user_id", localStorage.getItem("auth_id"))
            formData.append("module_id", 4)
            try {
              await axios({
                method: "post",
                url: `${configure.apiUrl}/user_permission`,
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then(res => {
                if (res.data.data.india[3] == 1 || res.data.data.uk[3] == 1) {
                  navigate(`${configure.appUrl}work/edit-project`)
                } else {
                  setAccessDenied(true)
                }
                setLoading(false)
              })
            } catch (error) {
              console.log(error)
              setLoading(false)
            }
          }

          const onTimerStart = async e => {
            if (timerStatus == true) {
              swal({
                title: "Stop Timer ?",
                text: "Are you sure you want to stop timer ?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async willDelete => {
                if (willDelete) {
                  swal({
                    icon: "success",
                    text: "Timer Stoped",
                  })
                  setTimerStatus(false)
                }
              })
            }
          }

          return (
            <div className="d-flex justify-content-center">
              <a
                className="btn btn-sm btn-outline-success me-1"
                title="Start / Stop Timer"
                onClick={onTimerStart}
                // onClick={()=> setTimerStatus(!timerStatus)}
              >
                <i className="fa fa-stopwatch"></i>
              </a>
              <a
                onClick={() => setEditTask(true)}
                className="btn btn-sm btn-outline-secondary me-1"
                title="Edit Task"
              >
                <i className="fas fa-pen "></i>
              </a>
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link title="Task History" className="dropdown-item">
                    <i className="bi bi-clock-history pr-10"></i> Task History
                  </Link>

                  <Link
                    title="Reply Task"
                    className="dropdown-item"
                    onClick={() => setReplyTiket(true)}
                  >
                    <i className="bi bi-reply-fill pr-10"></i> Reply Task
                  </Link>

                  <a
                    className="dropdown-item"
                    title="Delete Task"
                    onClick={onDeleteLead}
                  >
                    <i className="fas fa-trash-alt text-danger pr-10"></i>{" "}
                    Delete Task
                  </a>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [selectStatus]
  )

  const defaultColumns = columns1
  // const [column, setColumns] = useState(defaultColumns);

  const columns = useMemo(() => {
    switch (projectType) {
      case "1":
        return columns1
      case "2":
        return columns2
      case "3":
        return columns3
      case "4":
        return columns4
      case "5":
        return columns5
      case "6":
        return columns6
      default:
        return defaultColumns // or an empty array
    }
  }, [projectType])



  const data1 = [
    {
      id: "1",
      assign_name: "Divyansh",
      type_name: "Bug",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "2",
      assign_name: "Divyansh",
      type_name: "Suggestion",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "Low",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "3",
      assign_name: "Divyansh",
      type_name: "Task",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "Medium",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
  ]

  const data2 = [
    {
      id: "1",
      assign_name: "Divyansh",
      type_name: "Bug",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "2",
      assign_name: "Divyansh",
      type_name: "Suggestion",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "3",
      assign_name: "Divyansh",
      type_name: "Task",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
  ]

  const data3 = [
    {
      id: "1",
      assign_name: "Divyansh",
      type_name: "Bug",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "2",
      assign_name: "Divyansh",
      type_name: "Suggestion",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "3",
      assign_name: "Divyansh",
      type_name: "Task",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
  ]

  const data4 = [
    {
      id: "1",
      assign_name: "Divyansh",
      type_name: "Bug",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "2",
      assign_name: "Divyansh",
      type_name: "Suggestion",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "3",
      assign_name: "Divyansh",
      type_name: "Task",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
  ]

  const data5 = [
    {
      id: "1",
      assign_name: "Divyansh",
      type_name: "Bug",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "2",
      assign_name: "Divyansh",
      type_name: "Suggestion",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "3",
      assign_name: "Divyansh",
      type_name: "Task",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
  ]

  const data6 = [
    {
      id: "1",
      assign_name: "Divyansh",
      type_name: "Bug",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "2",
      assign_name: "Divyansh",
      type_name: "Suggestion",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "3",
      assign_name: "Divyansh",
      type_name: "Task",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
  ]

  const data7 = [
    {
      id: "1",
      assign_name: "Divyansh",
      type_name: "Bug",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "2",
      assign_name: "Divyansh",
      type_name: "Suggestion",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
    {
      id: "3",
      assign_name: "Divyansh",
      type_name: "Task",
      customer: "Global Bio Tech Private Ltd.",
      task_name: "Task 1",
      priority_col: "High",
      last_reply_col: "10-08-2024",
      created_at: "12-08-2024",
      time_taken_col: " 2 days",
    },
  ]

  const defaultData = data1

  const data = useMemo(() => {
    switch (projectType) {
      case "1":
        return data1
      case "2":
        return data2
      case "3":
        return data3
      case "4":
        return data4
      case "5":
        return data5
      case "6":
        return data6
      default:
        return defaultData // or an empty array
    }
  }, [projectType])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div className="">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <Card>
                <CardBody>
                  <div className="responsive-table">
                    <TableContainer
                      columns={columns}
                      data={data}
                      setAccessDenied={setAccessDenied}
                      setEditTask={setEditTask}
                      projectType={projectType}
                      setProjectType={setProjectType}
                      setFormPopup={setFormPopup}
                      formPopup={formPopup}
                      setViewTiketPopup={setViewTiketPopup}
                      viewTiketPopup={viewTiketPopup}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Edit Modal  */}
      <div className={`note-sticky-form ${editTask ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Edit Task</h5>
              <button
                type="button"
                onClick={() => {
                  setEditTask(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pe-4">
              <Form className="row d-flex justify-content-center align-items-center">
                <div className="my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => setEditTask(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12  ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    {/* <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Milestone <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Milestone"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={milestone}
                  />
                </div> */}
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={Estimation_Hours}
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability (%) <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Priority"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={Priority}
                      />
                    </div>

                    {/* <div className="col-md-3 ps-2 d-flex flex-column justify-content-start align-item-center gap-3">
                      <Label htmlFor="formrow-firstname-Input mb-0">
                        Image
                      </Label>
        
                      <span className="text-primary pb-2">fileName.jpg</span>
                    </div> */}

                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        placeholder="Select Type"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={ProjectType}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                    <div className="col-md-7 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Assigned <span className="text-danger">*</span>
                      </Label>
                      <ReactSelect
                        placeholder="Select Member"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={Assigned}
                        isMulti="true"
                      />
                    </div>
                    <div className="col-md-5 ps-2 d-flex flex-column gap-2">
                      <Label htmlFor="formrow-firstname-Input mb-0">
                        Image
                      </Label>
                      <div className="d-flex text-start gap-2 flex-wrap pb-2">
                        <span
                          className="text-light badge bg-primary"
                          title="Click To Open"
                          style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        >
                          Click here
                          <i
                            className="fas fa-times text-danger ps-1"
                            title="Remove"
                          ></i>
                        </span>
                        <span
                          className="text-light badge bg-primary"
                          title="Click To Open"
                          style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        >
                          Click here
                          <i
                            className="fas fa-times text-danger ps-1"
                            title="Remove"
                          ></i>
                        </span>
                        <span
                          className="text-light badge bg-primary"
                          title="Click To Open"
                          style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        >
                          Click here{" "}
                          <i
                            className="fas fa-times text-danger ps-1"
                            title="Remove"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                    <div className="col-md-12 ps-2 d-flex flex-column gap-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Image
                      </Label>
                      <div className= "d-flex text-start gap-3 flex-wrap">
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={editor => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                        }}
                      />
                    </div>
                  </div>
                  <Row className="bg-white px-2 py-2 shadow mb-3">
                    <div className="d-flex justify-content-end align-items-end px-0">
                      <div className="text-end mb-3">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            onAddFormRow()
                          }}
                          className="btn btn-purple  "
                        >
                          <i className="fas fa-plus me-2"></i>
                          Add Subtask
                        </button>
                      </div>
                    </div>
                    {formRows?.map((person, key) => {
                      return (
                        <div key={key} className="">
                          <div className="d-flex">
                            <div className="contact-form d-flex col-md-11">
                              <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                <label htmlFor="">Subtask Name</label>
                                <input
                                  type="text"
                                  name="task_name"
                                  placeholder="Enter Subtask Name"
                                  className="form-control "
                                />
                              </div>
                            </div>
                            <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                              <div className="text-end">
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    onDeleteFormRow(key)
                                  }}
                                  className="btn btn-red"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          {key !== formRows.length - 1 ? (
                            <div className="border-bottom my-3"></div>
                          ) : null}
                        </div>
                      )
                    })}
                  </Row>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      {/* // View Tiket Popup  */}
      <div
        className={`note-sticky-form ${viewTiketPopup ? "d-block" : "d-none"}`}
      >
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">View Task</h5>
              <button
                type="button"
                onClick={() => {
                  setViewTiketPopup(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pe-4">
              <Form className="row d-flex justify-content-center align-items-center">
                <div className="col-md-12 my-2 d-flex">
                  <div className="col-md-8 mt-2">
                    <h5>
                      {" "}
                      Ticket Reopen : <span className="text-danger">
                        2
                      </span>{" "}
                      times
                    </h5>
                  </div>

                  <div className="col-md-4 text-end mt-2">
                    <button className=" btn btn-save btn-label">
                      <i className="fas fa-save me-2 label-icon"></i>
                      Save
                    </button>
                    <a
                      onClick={() => setViewTiketPopup(false)}
                      className=" btn ms-md-2 btn-red btn-label"
                    >
                      <i className="dripicons-cross me-2 icon-center label-icon"></i>
                      CANCEL
                    </a>
                  </div>
                </div>
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    <div className="col-md-12  ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-start align-items-center gap-2">
                        <Input
                          disabled
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end">
                    {/* <div className="col-md-3 ps-2">
                  <Label htmlFor="formrow-firstname-Input">
                    Milestone <span className="text-danger">*</span>
                  </Label>
                  <Select
                    placeholder="Select Milestone"
                    className="basic-multi-select"
                    classNamePrefix=" select"
                    options={milestone}
                  />
                </div> */}
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Estimation Hours <span className="text-danger">*</span>
                      </Label>
                      <Select
                        isDisabled="true"
                        placeholder="Select Estimate time"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={Estimation_Hours}
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Probability (%) <span className="text-danger">*</span>
                      </Label>
                      <Input
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter Probability in (%)"
                      />
                    </div>
                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        isDisabled="true"
                        placeholder="Select Priority"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={Priority}
                      />
                    </div>

                    {/* <div className="col-md-3 ps-2 d-flex flex-column justify-content-start align-item-center gap-3">
                      <Label htmlFor="formrow-firstname-Input mb-0">
                        Image
                      </Label>
        
                      <span className="text-primary pb-2">fileName.jpg</span>
                    </div> */}

                    <div className="col-md-3 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Type <span className="text-danger">*</span>
                      </Label>
                      <Select
                        isDisabled="true"
                        placeholder="Select Type"
                        className="basic-multi-select"
                        classNamePrefix=" select"
                        options={ProjectType}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                    <div className="col-md-8 ps-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Assigned <span className="text-danger">*</span>
                      </Label>
                      <ReactSelect
                        isDisabled="true"
                        placeholder="Select Staff"
                        className="basic-multi-select "
                        classNamePrefix="select"
                        styles={colourStyles}
                        options={Assigned}
                        isMulti="true"
                      />
                    </div>
                    <div className="col-md-4 ps-2 d-flex flex-column gap-2">
                      <Label htmlFor="formrow-firstname-Input mb-0">
                        Image
                      </Label>
                      <div className="d-flex text-start gap-2 flex-wrap pb-2">
                        <span
                          className="text-light badge bg-primary"
                          title="Click To Open"
                          style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        >
                          Click here
                        </span>
                        <span
                          className="text-light badge bg-primary"
                          title="Click To Open"
                          style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        >
                          Click here
                        </span>
                        <span
                          className="text-light badge bg-primary"
                          title="Click To Open"
                          style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        >
                          Click here
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end gap-2">
                    <div className="col-md-12 ps-2 d-flex flex-column gap-2">
                      <Label htmlFor="formrow-firstname-Input">
                        Image
                      </Label>
                      <div className= "d-flex text-start gap-3 flex-wrap">
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                        <span className="text-primary" title="Click To Open" style={{cursor:"pointer"}}>Click here</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <textarea
                        className="form-control no-resize"
                        rows="4"
                        cols="30"
                        disabled
                      ></textarea>
                    </div>
                  </div>
                  <Row className="bg-white px-2 py-2 shadow mb-3 d-flex flex-column gap-3">
                    <div className="contact-column edit-contact-column mb-md-0 col-md-12 ">
                      <label htmlFor="">Subtask Name</label>
                      <input
                        disabled
                        type="text"
                        name="task_name"
                        placeholder="Subtask Name"
                        className="form-control "
                      />
                    </div>
                    <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                      <label htmlFor="">Subtask Name</label>
                      <input
                        disabled
                        type="text"
                        name="task_name"
                        placeholder="Subtask Name"
                        className="form-control "
                      />
                    </div>
                    {/* {formRows?.map((person, key) => {
                      return (
                        <div key={key} className="">
                          <div className="d-flex">
                            <div className="contact-form d-flex col-md-11">
                              <div className="contact-column edit-contact-column mb-md-0 col-md-12">
                                <label htmlFor="">Subtask Name</label>
                                <input
                                  type="text"
                                  name="task_name"
                                  placeholder="Enter Subtask Name"
                                  className="form-control "
                                />
                              </div>
                            </div>
                            <div className="col-md-1 contact-btns ml-10 d-flex justify-content-end align-items-end mb-md-0">
                              <div className="text-end">
                                <button
                                  onClick={e => {
                                    e.preventDefault()
                                    onDeleteFormRow(key)
                                  }}
                                  className="btn btn-red"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          {key !== formRows.length - 1 ? (
                            <div className="border-bottom my-3"></div>
                          ) : null}
                        </div>
                      )
                    })} */}
                  </Row>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Reply Tiket Modal  */}
      <div className={`note-sticky-form ${replyTiket ? "d-block" : "d-none"}`}>
        <Draggable>
          <ResizableBox
            width={900}
            style={{
              position: "fixed",
              top: "10%",
              right: "2%",
              zIndex: "1055",
              backgroundColor: "#fff",
            }}
          >
            <div className="modal-header pe-4">
              <h5 className="modal-title mt-0">Reply Ticket </h5>
              <button
                type="button"
                onClick={() => {
                  setReplyTiket(false)
                }}
                className="btn-modal-close"
              >
                <span aria-hidden="true" className="btn-modal-span">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body pe-4">
              <Form className="row d-flex justify-content-center align-items-center">
                <div className="my-2 text-end">
                  <button className=" btn btn-save btn-label">
                    <i className="fas fa-save me-2 label-icon"></i>
                    Save
                  </button>
                  <a
                    onClick={() => setReplyTiket(false)}
                    className=" btn ms-md-2 btn-red btn-label"
                  >
                    <i className="dripicons-cross me-2 icon-center label-icon"></i>
                    CANCEL
                  </a>
                </div>
                <div className="col-md-12 d-flex flex-column new-task-modal ">
                  <div className="col-md-12 d-flex mb-4 justify-content-start align-items-end flex-column gap-4">
                    <div className="col-12 ps-2">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={editor => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                        }}
                      />
                    </div>
                    <div className="col-md-12 d-flex justify-space-between align-items-center">
                      <div className="col-md-3 mt-3">
                        <label htmlFor="">Attachment</label>
                        {/* custom file upload */}
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles1(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className="dz-message needsclick p-0"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="d-flex px-2 custom-file-upload align-items-center">
                                <div className="">
                                  <i className="display-6 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h5 className="mb-0 ms-2">Upload Files</h5>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                      {/* file display here */}
                      <div
                        className={`col-md-4 mt-3 dropzone-previews ${
                          selectedFiles1.length > 0 ? "d-block" : "d-none"
                        }`}
                        id="file-previews"
                      >
                        <label className="mb-1">Uploaded Files</label>
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-1">
                            <Row className="align-items-center justify-content-evenly">
                              {selectedFiles1?.map((f, i) => {
                                return (
                                  <Col key={i} className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                )
                              })}
                            </Row>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      {/* Access Denied */}
      <AccessDeniedPopup
        modal_Access={modal_Access}
        setAccessDenied={setAccessDenied}
      />
    </React.Fragment>
  )
}

export default EditMTaskNew
